import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import SecurityIcon from "@mui/icons-material/Security";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import SignalWifiStatusbar4BarIcon from "@mui/icons-material/SignalWifiStatusbar4Bar";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import StorageIcon from "@mui/icons-material/Storage";
import DvrIcon from "@mui/icons-material/Dvr";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

export const IconMapping = {
  success: { icon: CheckCircleIcon, color: "success" },
  ok: { icon: CheckCircleIcon, color: "success" },
  active: { icon: CheckCircleIcon, color: "success" },
  finished: { icon: CheckCircleIcon, color: "success" },
  license_failed: { icon: CancelIcon, color: "error" },
  error: { icon: CancelIcon, color: "error" },
  crash: { icon: CancelIcon, color: "error" },
  failed: { icon: CancelIcon, color: "error" },
  unprotect: { icon: RemoveModeratorIcon, color: "error" },
  protect: { icon: SecurityIcon, color: "success" },
  online: { icon: SignalWifiStatusbar4BarIcon, color: "success" },
  upgrading: { icon: ArrowCircleUpIcon, color: "success" },
  offline: { icon: SignalWifiOffIcon, color: "error" },
  information: {
    icon: InfoOutlinedIcon,
    color: "info"
  },
  out_of_sync: { icon: ErrorIcon, color: "warning" },
  warning: { icon: ErrorIcon, color: "warning" },
  skipped: { icon: ErrorIcon, color: "warning" },
  missed: { icon: ErrorIcon, color: "warning" },
  incomplete: { icon: ErrorIcon, color: "warning" },
  waiting: { icon: ErrorIcon, color: "warning" },
  canceled: { icon: ErrorIcon, color: "warning" },
  download: { icon: DownloadIcon, color: "success" },
  agentless_vm: { icon: DynamicFeedIcon, style: { opacity: "50%" } },
  machine: { icon: DesktopWindowsIcon, style: { opacity: "50%" } },
  shared_folder: { icon: FolderOpenIcon, style: { opacity: "50%" } },
  delete: { icon: DeleteIcon, style: { cursor: "pointer" } },
  save: { icon: SaveIcon, style: { cursor: "pointer" } },
  saveDisabled: { icon: SaveIcon, style: { opacity: "50%" } },
  udp_oracle: { icon: StorageIcon, style: { opacity: "50%" } },
  windows_oracle_host: { icon: DvrIcon, style: { opacity: "50%" } },
  linux_oracle_host: { icon: DvrIcon, style: { opacity: "50%" } }
};

/* eslint-disable camelcase */
import store from "../state/store";

export const DARK_MODE = "dark";
export const LIGHT_MODE = "light";

export const DARK_THEME_PRIMARY_COLOR = "#BB86FC";
export const DARK_THEME_SECONDARY_COLOR = "#00ADDC";
export const LIGHT_THEME_DEDFAULT_PRIMARY_COLOR = "#6200EE";
export const LIGHT_THEME_DEDFAULT_SECONDARY_COLOR = "#6200EE";

const commonThemeProperties = {
  typography: {
    fontFamily: "Sans Source Pro",
    fontWeight: 400,
    fontSize: 13,
    font_9_reg: {
      fontFamily: "Sans Source Pro",
      fontWeight: 400,
      fontSize: 9
    },
    font_10_reg: {
      fontFamily: "Sans Source Pro",
      fontWeight: 400,
      fontSize: 10
    },
    font_11_reg: {
      fontFamily: "Sans Source Pro",
      fontWeight: 400,
      fontSize: 11
    },
    font_12_reg: {
      fontFamily: "Sans Source Pro",
      fontWeight: 400,
      fontSize: 12
    },
    font_13_reg: {
      fontFamily: "Sans Source Pro",
      fontWeight: 400,
      fontSize: 13
    },
    font_14_reg: {
      fontFamily: "Sans Source Pro",
      fontWeight: 400,
      fontSize: 14
    },
    font_15_reg: {
      fontFamily: "Sans Source Pro",
      fontWeight: 400,
      fontSize: 15
    },
    font_16_reg: {
      fontFamily: "Sans Source Pro",
      fontWeight: 400,
      fontSize: 16
    },
    font_17_reg: {
      fontFamily: "Sans Source Pro",
      fontWeight: 400,
      fontSize: 17
    },
    font_18_reg: {
      fontFamily: "Sans Source Pro",
      fontWeight: 400,
      fontSize: 18
    },
    font_24_reg: {
      fontFamily: "Sans Source Pro",
      fontWeight: 400,
      fontSize: 24
    },
    font_9_med: {
      fontFamily: "Sans Source Pro Medium",
      fontWeight: 550,
      fontSize: 9
    },
    font_10_med: {
      fontFamily: "Sans Source Pro Medium",
      fontWeight: 550,
      fontSize: 10
    },
    font_11_med: {
      fontFamily: "Sans Source Pro Medium",
      fontWeight: 550,
      fontSize: 11
    },
    font_12_med: {
      fontFamily: "Sans Source Pro Medium",
      fontWeight: 550,
      fontSize: 12
    },
    font_13_med: {
      fontFamily: "Sans Source Pro Medium",
      fontWeight: 550,
      fontSize: 13
    },
    font_14_med: {
      fontFamily: "Sans Source Pro Medium",
      fontWeight: 550,
      fontSize: 14
    },
    font_15_med: {
      fontFamily: "Sans Source Pro Medium",
      fontWeight: 550,
      fontSize: 15
    },
    font_16_med: {
      fontFamily: "Sans Source Pro Medium",
      fontWeight: 550,
      fontSize: 16
    },
    font_17_med: {
      fontFamily: "Sans Source Pro Medium",
      fontWeight: 550,
      fontSize: 17
    },
    font_18_med: {
      fontFamily: "Sans Source Pro Medium",
      fontWeight: 550,
      fontSize: 18
    },
    font_24_med: {
      fontFamily: "Sans Source Pro Medium",
      fontWeight: 550,
      fontSize: 24
    }
  }
};

export const setThemePrimaryAndSecColors = (theme, brandObj) => {
  const themeValue = { ...theme };
  if (themeValue?.palette?.mode === LIGHT_MODE && brandObj) {
    themeValue.palette.primary.main = brandObj.primary_color
      ? brandObj.primary_color
      : LIGHT_THEME_DEDFAULT_PRIMARY_COLOR;
    themeValue.palette.secondary.main = brandObj.secondary_color
      ? brandObj.secondary_color
      : LIGHT_THEME_DEDFAULT_SECONDARY_COLOR;
  } else if (themeValue?.palette?.mode === DARK_MODE) {
    themeValue.palette.primary.main = DARK_THEME_PRIMARY_COLOR;
    themeValue.palette.secondary.main = DARK_THEME_SECONDARY_COLOR;
  }
  return themeValue;
};

export const LIGHT_THEME = {
  palette: {
    mode: LIGHT_MODE,
    primary: {
      main: LIGHT_THEME_DEDFAULT_PRIMARY_COLOR
    },
    secondary: {
      main: LIGHT_THEME_DEDFAULT_SECONDARY_COLOR,
      hoverText: "#fff"
    },
    success: {
      main: "#12DD99"
    },
    info: {
      main: "#2196F3"
    },
    warning: {
      main: "#FFB800"
    },
    error: {
      main: "#EE6A5D"
    },
    backgroundColor: {
      backgroundColor: "rgb(255, 255, 255)"
    },
    action: {
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabled: "rgba(0, 0, 0, 0.26)"
    },
    label: {
      color: "#444444"
    }
  },
  ...commonThemeProperties
};

export const DARK_THEME = {
  palette: {
    mode: DARK_MODE,
    primary: {
      main: DARK_THEME_PRIMARY_COLOR
    },
    secondary: {
      main: DARK_THEME_SECONDARY_COLOR,
      hoverText: "#fff"
    },
    success: {
      main: "#12DD99"
    },
    info: {
      main: "#2196F3"
    },
    warning: {
      main: "#FFB800"
    },
    error: {
      main: "#EE6A5D"
    },
    action: {
      disabledBackground: "rgba(0,0,0,0.12)",
      disabled: "rgba(255,255,255,0.38)"
    },
    text: {
      primary: "rgb(250,250, 250, 0.87)"
    },
    label: {
      color: "rgba(250,250,250, 0.87)"
    },
    loadViewContent: {
      color: "rgba (0,0,0,.12)"
    }
  },
  ...commonThemeProperties
};

export const MUI_INITIAL_THEME = () => {
  const reduxStore = store?.getState();
  const { theme } = reduxStore.reducer;
  if (theme?.palette?.mode === DARK_MODE) {
    return DARK_THEME;
  }
  return LIGHT_THEME;
};

// https://material-ui.com/customization/default-theme/#default-theme
export const theme = () => {
  const reduxStore = store?.getState();
  return reduxStore?.reducer?.theme || MUI_INITIAL_THEME();
};

// this can be modified as necessary or create new styles object for the component if required
export const styles = () => ({
  // secondary: {
  //   color: `${theme().palette.secondary ? theme().palette.secondary.contrastText : '#6200EE'}`,
  //   backgroundColor: `${theme().palette.secondary ? theme().palette.secondary.main : '#6200EE'}`,
  //   textTransform: "capitalize",
  //   "&:hover": {
  //     backgroundColor: `${theme().palette.secondary && theme().palette.secondary.dark}`,
  //     color: `${theme().palette.secondary.hoverText}`
  //   }
  // },
  // primary: {
  //   color: `${theme().palette.primary.contrastText}`,
  //   backgroundColor: `${theme().palette.primary.main}`,
  //   textTransform: "capitalize",
  //   "&:hover": {
  //     backgroundColor: `${theme().palette.primary.dark}`,
  //     color: `${theme().palette.primary.contrastText}`
  //   }
  // },
  // chip: {
  //   backgroundColor: "transparent !important",
  //   fontSize: "13px !important"
  // },
  // ButtonLarge: {
  //   fontFamily: "Sans Source Pro Medium !important",
  //   fontSize: "15px !important"
  // },
  // ButtonSmall: {
  //   fontFamily: "Sans Source Pro Medium !important",
  //   fontSize: "13px !important"
  // },
  // ButtonMedium: {
  //   fontFamily: "Sans Source Pro Medium !important",
  //   fontSize: "14px !important"
  // },
  // tags: {
  //   background: "transparent",
  //   color: "#444",
  //   fontFamily: `Sans Source Pro !important`,
  //   fontSize: "12px",
  //   "&:hover": {
  //     background: "transparent"
  //   },
  //   "&:active": {
  //     background: "transparent"
  //   },
  //   "&:focus": {
  //     background: "transparent"
  //   }
  // },
  // savedSearches: {
  //   background: "#f0f0f0 !important",
  //   borderRadius: "5px !important",
  //   color: "#8f8f8f !important",
  //   fontFamily: `Sans Source Pro !important`,
  //   fontSize: "13px !important",
  //   margin: "0 12px 10px 0 !important",
  //   "&:hover": {
  //     background: "#f0f0f0 !important"
  //   },
  //   "&:active": {
  //     background: "#f0f0f0 !important"
  //   },
  //   "&:focus": {
  //     background: "#f0f0f0 !important"
  //   }
  // }
});

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import {
  updateSearchText,
  clearAllSearchTags,
  getSelectedSavedSearch
} from "../../../state/actions/actions";
import {
  makeSavedSearchDataState,
  makeAdvancedSearchOptionsState,
  makeSearchTextState,
  makeSelectedSavedSearchState
} from "../../../state/selectors/CommonSelectors";
import Button from "components/common/button";
import FormattedMessage from "components/common/formatted-message";

const SavedSearchList = ({
  clearAllSearchTags,
  getSelectedSavedSearch,
  filterType,
  selectedSavedSearch,

  ...props
}) => {
  useEffect(() => {
    let userSearchData = props.userSearchData || [];
    if (userSearchData && userSearchData.length > 0) {
      dispatchGetSearchDataAction(
        userSearchData[0].filter_id,
        userSearchData[0].filter_name
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * get the selected search data from back end after clicking a saved search
   */
  const dispatchGetSearchDataAction = (filterId, filterName) => {
    clearAllSearchTags();
    getSelectedSavedSearch({
      filterId,
      filterName,
      filterType
    });
  };

  let userSearchData =
    props.userSearchData && props.userSearchData.length > 0
      ? props.userSearchData
      : [];

  return (
    <div>
      <div>
        {Array.isArray(userSearchData) &&
          userSearchData.map((obj, i) => {
            let keyIndex = i;
            return (
              <Button
                key={keyIndex}
                className={
                  selectedSavedSearch &&
                  selectedSavedSearch.filter_name === obj.filter_name
                    ? "active"
                    : ""
                }
                onClick={() =>
                  dispatchGetSearchDataAction(obj.filter_id, obj.filter_name)
                }
                content={
                  <FormattedMessage
                    id={obj.filter_id}
                    defaultMessage={obj.filter_name}
                  />
                }
              ></Button>
            );
          })}
      </div>
    </div>
  );
};

SavedSearchList.defaultProps = {
  selectedSavedSearch: {},
  filterType: ""
};

SavedSearchList.propTypes = {
  userSearchData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  clearAllSearchTags: PropTypes.func.isRequired,
  getSelectedSavedSearch: PropTypes.func.isRequired,
  selectedSavedSearch: PropTypes.shape({
    filter_name: PropTypes.string
  }),
  filterType: PropTypes.string
};

/**
 * Using makeMapStateToProps to memoize a selectorFunction for reusability
 * This will create a new instance of selector function
 * every time it is needed for proper memoization in multiple components.
 */
const makeMapStateToProps = () => {
  /**
   * create a new instance of selector function
   * This makes it independent of any other selector function
   * and it will properly memoize across multiple mounted & connected components.
   */
  const getSavedSearchDataState = makeSavedSearchDataState();
  const getAdvancedOptionsState = makeAdvancedSearchOptionsState();
  const getSearchTextState = makeSearchTextState();
  const getSelectedSavedSearchState = makeSelectedSavedSearchState();

  const mapStateToProps = (state, props) => {
    return {
      /**
       * Use selector function instance to update Props.
       */
      userSearchData: getSavedSearchDataState(state, props),
      options: getAdvancedOptionsState(state, props),
      searchText: getSearchTextState(state, props),
      selectedSavedSearch: getSelectedSavedSearchState(state, props)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSelectedSavedSearch,
      clearAllSearchTags,
      updateSearchText
    },
    dispatch
  );

// export default Source;
export default React.memo(
  connect(makeMapStateToProps, mapDispatchToProps)(SavedSearchList)
);

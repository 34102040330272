import {
  SHOW_CLOUD_ACCOUNTS_LIST,
  UPDATE_COLUMN_CONFIG_DATA,
  SHOW_DETAILS_VIEW,
  CLEAR_DETAILS_VIEW,
  UPDATE_VM_DETAILS,
  SHOW_ARCSERVE_CLOUD_DETAILS,
  UPDATE_ARCSERVE_CLOUD_FILTER,
  SHOW_ARCSERVE_CLOUD_VOLUME_DETAILS,
  UPDATE_USERS,
  UPDATE_USERS_V2,
  DELETE_UPDATE_USER_ACCOUNTS,
  DELETE_UPDATE_USER_ACCOUNTS_V2,
  SHOW_ADD_USER,
  SHOW_DIRECT_USER_PERMISSION,
  SHOW_MSP_USER_PERMISSION,
  UPDATE_USER_ROLES,
  ADD_UPDATE_USERS,
  ADD_UPDATE_USERS_V2,
  SET_SELECTED_USER,
  SET_ADD_MODE,
  SET_SELECTED_ROLE,
  SHOW_ROLE_DETAILS,
  SHOW_LICENSE_DETAILS,
  SHOW_HIDE_ACTIVATE_ORDER_MODAL,
  CLEAR_LICENSE_DETAILS_VIEW,
  CLEAR_STATE_USER_ROLES,
  UPDATE_CREATE_GROUP_FLAG,
  UPDATE_SOURCES,
  DELETE_UPDATE_SOURCES,
  SET_SELECTED_SOURCE_GROUP,
  UPDATE_BRAND_LOGO,
  SHOW_BRANDING_DETAILS,
  SHOW_EMAILER_DETAILS,
  CHANGE_URL,
  GET_BRANDING_IMG_URL,
  GET_HYPERVISORS_LIST,
  SHOW_NETWORK_CONFIG_VIEW,
  GET_NETWORK_CONFIG_VIEW,
  UPDATE_IS_BRANDING_LOGO,
  RESET_PASSWORD,
  RESEND_VERIFICATION_EMAIL,
  CLEAR_SOURCE_GROUPS,
  GET_CUSTOMER_LIST,
  UPDATE_CUSTOMER_LIST,
  UPDATE_MSP_CHILD_ACCOUNTS,
  REMOVE_ASSIGNED_CUSTOMER,
  CLEAR_ADD_USER_STATE,
  UPDATE_ASSIGNED_CUSTOMERS,
  GET_CONFIGURE_COUNT,
  UPDATE_CONFIGURE_COUNT,
  ADD_CUSTOMERS_TO_USER,
  GET_HYPERVISOR_DETAIL,
  SHOW_HYPERVISOR_DETAILS,
  DOWNLOAD_AGENTS,
  REFRESH_VMS,
  GET_DESTINATIONS_FOR_DROPDOWNS,
  UPDATE_DESTINATIONS_FOR_DROPDOWNS,
  GET_CUSTOMERS_FOR_USER,
  RESET_IMAGE_UPLOAD_SUCCESS_FLAG,
  SHOW_NETWORK_PORT_FORWARD,
  GET_NETWORK_PORT_FORWARD,
  EDIT_NETWORK_CONFIG_LIST,
  NETWORK_CONFIG_LIST,
  UPDATE_DELETED_NETWORK_CONFIG_LIST,
  DELETE_NETWORK_CONFIG_LIST,
  SHOW_ADDED_NETWORK_CONFIG_LIST,
  ADD_NETWORK_CONFIG_LIST,
  NEW_RULE_NETWORK_CONFIG_LIST,
  DOWNLOAD_NETWORK_SESSION_LOGS,
  GET_DOWNLOAD_NETWORK_SESSION_LOGS,
  SHOW_LOGIN_TAB,
  UPDATE_VPN_DETAILS,
  FETCH_VPN_DETAILS,
  STATE_DELETE_NETWORK_CONFIG_LIST,
  DELETE_HYPERVISOR,
  DELETE_STORAGE_ARRAY,
  LOAD_DEFAULT_DEPLOYMENT,
  LOAD_DEFAULT_DEPLOYMENT_SUCCESS,
  UPDATE_DEFAULT_DEPLOYMENT,
  UPDATE_DEFAULT_DEPLOYMENT_SUCCESS,
  UPDATE_VM_INFORMATION,
  LOAD_SOURCE_DISCOVERY_SETTINGS,
  LOAD_SOURCE_DISCOVERY_SETTINGS_SUCCESS,
  SAVE_SOURCE_DISCOVERY_SETTINGS_SUCCESS,
  SAVE_SOURCE_DISCOVERY_SETTINGS,
  FETCH_SOURCE_DISCOVERY_LIST_SUCCESS,
  FETCH_SOURCE_DISCOVERY_LIST,
  ADD_DISCOVER_FROM_AD,
  SOURCE_DISCOVERY_RUN_NOW,
  ADD_DISCOVER_FROM_HYPERV,
  ADD_DISCOVER_FROM_VMWARE,
  DELETE_SOURCE_DISCOVERY,
  UPDATE_HYPER_V_OR_VMWARE,
  GET_STORAGE_ARRAYS,
  SHOW_STORAGE_ARRAYS,
  UPDATE_STORAGE_ARRAY,
  GET_PROXIES_DATA,
  UPDATE_PROXY,
  LOAD_CONFIGURE_PROXIES,
  REMOVE_PROXY,
  EDIT_ORGANIZATION,
  TWO_FACTOR_USER_DISABLE,
  UPGRADE_SITE,
  GET_HYPERVISORS_OF_TYPE,
  ADD_HYPERVISOR,
  GET_MSP_CHILD_ACCOUNTS
} from "../actions/actionTypes";

export const showCloudAccountsList = data => ({
  token: null,
  type: SHOW_CLOUD_ACCOUNTS_LIST,
  data
});

export const updateArcserveCloudFilter = data => ({
  type: UPDATE_ARCSERVE_CLOUD_FILTER,
  data
});

export const showArcserveCloudDetails = data => ({
  type: SHOW_ARCSERVE_CLOUD_DETAILS,
  data
});

export const showArcserveCloudVolumeDetails = data => ({
  type: SHOW_ARCSERVE_CLOUD_VOLUME_DETAILS,
  data
});

export const showDetailsViewForData = data => ({
  type: SHOW_DETAILS_VIEW,
  data
});

export const updateVMDetailsViewForData = data => ({
  type: UPDATE_VM_DETAILS,
  data
});

export const clearDetailsView = () => ({
  type: CLEAR_DETAILS_VIEW
});

export const clearStateForUserRoles = () => ({
  type: CLEAR_STATE_USER_ROLES
});

/**
 * update users list after fetching users from back end
 */
export const updateUsers = data => ({
  type: UPDATE_USERS,
  data
});

export const updateUsersV2 = data => ({
  type: UPDATE_USERS_V2,
  data
});

/**
 * update users list after successful deletion of a user
 */
export const deleteUpdateUsers = data => ({
  type: DELETE_UPDATE_USER_ACCOUNTS,
  data
});

export const deleteUpdateUsersV2 = data => ({
  type: DELETE_UPDATE_USER_ACCOUNTS_V2,
  data
});

/**
 * to update the flag used to show/hide 'Add User' modal form in  Configure -> User Accounts
 */
export const showAddUser = data => ({
  type: SHOW_ADD_USER,
  data
});

/**
 * to update the flag used to show/hide 'User Permission' modal form in  Configure -> User Accounts
 */
export const showDirectUserPermission = data => ({
  type: SHOW_DIRECT_USER_PERMISSION,
  data
});

/*
 * to update the flag used to show/hide 'MSP User Permission' modal form in  MSP -> Configure -> User Accounts
 */
export const showMSPUserPermission = data => ({
  type: SHOW_MSP_USER_PERMISSION,
  data
});

export const showRoleDetails = data => ({
  type: SHOW_ROLE_DETAILS,
  data
});

/**
 * update user roles after fetching roles from back end api
 */
export const updateUserRoles = data => ({
  type: UPDATE_USER_ROLES,
  data
});

/**
 * update list of users after a new user has been created
 */
export const addUpdateUsers = data => ({
  type: ADD_UPDATE_USERS,
  data
});

export const addUpdateUsersV2 = data => ({
  type: ADD_UPDATE_USERS_V2,
  data
});

/**
 * to set the selected user in state
 */
export const setSelectedUser = data => ({
  type: SET_SELECTED_USER,
  data
});

/**
 * set Action Type for form
 * isAddMode: true - add mode
 * isAddMode: false - edit mode
 */
export const setAddMode = data => ({
  type: SET_ADD_MODE,
  isAddMode: data
});

/**
 * update the selected role
 */
export const setSelectedRole = data => ({
  type: SET_SELECTED_ROLE,
  data
});

/**
 * Action to show license details
 * @param data
 * @returns {{type, data: *}}
 */
export const showLicenseDetails = data => ({
  type: SHOW_LICENSE_DETAILS,
  data
});

// export const ADVANCE_SEARCH_LOADED = () => ({
//   type: "ADVANCE_SEARCH_LOADED"
// });

/**
 * Action to clear license details
 * @returns {{type}}
 */
export const clearLicenseDetails = () => ({
  type: CLEAR_LICENSE_DETAILS_VIEW
});

/**
 * Action to show hide activate order modal
 * @param data
 * @returns {{type, data: *}}
 */
export const showHideActivateOrderPopup = data => ({
  type: SHOW_HIDE_ACTIVATE_ORDER_MODAL,
  data
});

/**
 * to update the flag to show/hide 'Create Group' modal
 */
export const updateCreateGroupFlag = data => ({
  type: UPDATE_CREATE_GROUP_FLAG,
  data
});

/**
 * Action to show brand logo modal
 */
export const showUpdateBrandLogo = data => ({
  type: UPDATE_BRAND_LOGO,
  data
});

/**
 * update sources
 */
export const updateSources = data => ({
  type: UPDATE_SOURCES,
  data
});

/**
 * update source list after source/s are deleted from back end for selected source group
 */
export const deleteUpdateSource = data => ({
  type: DELETE_UPDATE_SOURCES,
  sourceIdArray: data
});
/**
 * set the selected source group info
 */
export const setSelectedSourceGroup = data => ({
  type: SET_SELECTED_SOURCE_GROUP,
  data
});

/**
 * Action to show brand details to configure branding
 * @param data
 * @returns {{type, data: *}}
 */
export const showBrandingDetails = data => ({
  type: SHOW_BRANDING_DETAILS,
  data
});

/**
 * Action to show emailer details
 * @param data
 * @returns {{type, data: *}}
 */
export const showEmailerDetails = data => ({
  type: SHOW_EMAILER_DETAILS,
  data
});

/* change url flag
 * @returns {{type: string}}
 */
export const changeURLFlag = data => ({
  type: CHANGE_URL,
  data
});

/**
 *
 * @param {*} data
 * get URL for logo image
 */
export const getLogoImageUrl = (imageBlob, trigger) => ({
  type: GET_BRANDING_IMG_URL,
  imageBlob,
  trigger
});

/**
 * clear source groups data from state
 */
export const clearSourceGroups = () => ({
  type: CLEAR_SOURCE_GROUPS
});

export const getHypervisorsList = data => ({
  type: GET_HYPERVISORS_LIST,
  data
});

export const getStorageArrays = data => ({
  type: GET_STORAGE_ARRAYS,
  data
});

export const showStorageArrays = data => ({
  type: SHOW_STORAGE_ARRAYS,
  data
});

export const showNetworkConfigList = data => ({
  type: SHOW_NETWORK_CONFIG_VIEW,
  data
});

export const getNetworkConfigList = data => ({
  type: GET_NETWORK_CONFIG_VIEW,
  data
});

export const updateIsBrandingLogo = data => ({
  type: UPDATE_IS_BRANDING_LOGO,
  data
});

export const getHypervisorsOfType = (product_type, hyperv_type, siteId) => ({
  type: GET_HYPERVISORS_OF_TYPE,
  product_type,
  hyperv_type,
  siteId
});

/**
 * to reset user password
 * @param id
 */
export const resetPassword = id => ({
  type: RESET_PASSWORD,
  id
});

/**
 * to resend user verification mail
 * @param id
 */
export const resendVerificationEmail = id => ({
  type: RESEND_VERIFICATION_EMAIL,
  id
});

export const getCustomerList = () => ({
  type: GET_CUSTOMER_LIST
});

export const getMSPChildAccounts = () => ({
  type: GET_MSP_CHILD_ACCOUNTS
});

export const updateCustomerList = data => ({
  type: UPDATE_CUSTOMER_LIST,
  data
});

export const updateMSPChildAccounts = data => ({
  type: UPDATE_MSP_CHILD_ACCOUNTS,
  data
});

export const removeAssignedCustomer = id => ({
  type: REMOVE_ASSIGNED_CUSTOMER,
  id
});

export const clearAddUserState = () => ({
  type: CLEAR_ADD_USER_STATE
});

export const assignCustomersToUser = data => ({
  type: UPDATE_ASSIGNED_CUSTOMERS,
  data
});

/**
 * to get counts for Source groups, User accounts and Roles
 */
export const getConfigureCount = () => ({
  type: GET_CONFIGURE_COUNT
});

export const updateConfigColumnData = (data, colunmType) => ({
  type: UPDATE_COLUMN_CONFIG_DATA,
  data,
  colunmType
});

/**
 * to update the source groups, users and roles count fetched from back end in the state
 */
export const updateConfigureCount = (
  data,
  stateMenuData,
  statePrimaryNavigation
) => ({
  type: UPDATE_CONFIGURE_COUNT,
  data,
  stateMenuData,
  statePrimaryNavigation
});

/**
 * to assign customers to user
 */
export const addCustomersToUser = (data, userId) => ({
  type: ADD_CUSTOMERS_TO_USER,
  data,
  userId
});

export const getHypervisorDetails = id => ({
  type: GET_HYPERVISOR_DETAIL,
  id
});

export const showHypervisorDetails = data => ({
  type: SHOW_HYPERVISOR_DETAILS,
  data
});

export const downloadApplianceForVMware = () => ({
  type: DOWNLOAD_AGENTS
});

export const refreshVMs = id => ({
  type: REFRESH_VMS,
  id
});

export const getDestinationsForDropdowns = () => ({
  type: GET_DESTINATIONS_FOR_DROPDOWNS
});

export const updatedDestinationsForDropdown = data => ({
  type: UPDATE_DESTINATIONS_FOR_DROPDOWNS,
  data
});

/**
 * fetch customer accounts for selected user
 */
export const getCustomersForUser = userId => ({
  type: GET_CUSTOMERS_FOR_USER,
  userId
});

export const resetImageUploadSuccessFlag = () => ({
  type: RESET_IMAGE_UPLOAD_SUCCESS_FLAG
});

/**
 * to show network configuration port forwarding
 */

export const showNetworkPortForwardDetails = data => ({
  type: SHOW_NETWORK_PORT_FORWARD,
  data
});

/**
 * to fetch network configuration port forwarding
 */

export const getNetworkPortForwardDetails = data => ({
  type: GET_NETWORK_PORT_FORWARD,
  data
});

/**
 * to update network configuration port forwarding grid list
 */

export const updateNetworkConfigGrid = data => ({
  type: EDIT_NETWORK_CONFIG_LIST,
  data
});

/**
 * to store network configuration port forwarding grid list
 */

export const networkConfigGridList = data => ({
  type: NETWORK_CONFIG_LIST,
  data
});

/**
 * to show deleted network configuration port forwarding grid list
 */

export const deleteUpdateNetworkConfigGridList = data => ({
  type: UPDATE_DELETED_NETWORK_CONFIG_LIST,
  data
});

/**
 * to delete network configuration port forwarding grid list
 */

export const deleteNetworkConfigGridList = data => ({
  type: DELETE_NETWORK_CONFIG_LIST,
  data
});

/**
 * to delete network configuration port forwarding grid list
 */

export const stateDeleteNetworkConfigGridList = data => ({
  type: STATE_DELETE_NETWORK_CONFIG_LIST,
  data
});

/**
 * to add network configuration port forwarding grid list
 */

export const addNetworkPortForwardDetails = data => ({
  type: ADD_NETWORK_CONFIG_LIST,
  data
});

export const showAddedNetworkPortForwardDetails = data => ({
  type: SHOW_ADDED_NETWORK_CONFIG_LIST,
  data
});

export const newRuleNetworkConfigList = data => ({
  type: NEW_RULE_NETWORK_CONFIG_LIST,
  data
});

/**
 * to download session logs for network configuration
 */

export const downloadNetworkSessionLogs = data => ({
  type: DOWNLOAD_NETWORK_SESSION_LOGS,
  data
});

/**
 * to show downloaded session logs for network configuration
 */

export const getDownloadedNetworkSessionLogs = data => ({
  type: GET_DOWNLOAD_NETWORK_SESSION_LOGS,
  data
});

/**
 * to show/hide login tab depending on portal URL value
 */

export const toggleLoginTab = data => ({
  type: SHOW_LOGIN_TAB,
  data
});

export const getVPNDetails = () => ({
  type: FETCH_VPN_DETAILS
});

export const updateVPNDetails = data => ({
  type: UPDATE_VPN_DETAILS,
  data
});

export const deleteHypervisorByID = id => ({
  type: DELETE_HYPERVISOR,
  id
});

export const deleteStorageArrayByID = id => ({
  type: DELETE_STORAGE_ARRAY,
  id
});

/**
 * Settings > Default Deployment actions
 */

export const loadDefaultDeployment = () => ({
  type: LOAD_DEFAULT_DEPLOYMENT
});

export const loadDefaultDeploymentSuccess = data => ({
  type: LOAD_DEFAULT_DEPLOYMENT_SUCCESS,
  data
});

export const updateDefaultDeployment = () => ({
  type: UPDATE_DEFAULT_DEPLOYMENT
});

export const updateDefaultDeploymentSuccess = data => ({
  type: UPDATE_DEFAULT_DEPLOYMENT_SUCCESS,
  data
});

/**
 * Settings > Source Configuration
 */
export const loadSourceDiscoverySettings = () => ({
  type: LOAD_SOURCE_DISCOVERY_SETTINGS
});

export const loadSourceDiscoverySettingsSuccess = data => ({
  type: LOAD_SOURCE_DISCOVERY_SETTINGS_SUCCESS,
  data
});

export const fetchSourceDiscoveryList = () => ({
  type: FETCH_SOURCE_DISCOVERY_LIST
});

export const fetchSourceDiscoveryListSuccess = data => ({
  type: FETCH_SOURCE_DISCOVERY_LIST_SUCCESS,
  data
});

export const saveSourceDiscoverySettings = () => ({
  type: SAVE_SOURCE_DISCOVERY_SETTINGS
});

export const saveSourceDiscoverySettingsSuccess = data => ({
  type: SAVE_SOURCE_DISCOVERY_SETTINGS_SUCCESS,
  data
});

export const addDiscoverFromAD = () => ({
  type: ADD_DISCOVER_FROM_AD
});

export const addDiscoverFromHyperV = () => ({
  type: ADD_DISCOVER_FROM_HYPERV
});

export const addDiscoverFromVmware = () => ({
  type: ADD_DISCOVER_FROM_VMWARE
});

export const sourceDiscoveryRunNow = () => ({
  type: SOURCE_DISCOVERY_RUN_NOW
});

export const deleteSourceDiscovery = listOfIds => ({
  type: DELETE_SOURCE_DISCOVERY,
  data: listOfIds
});

export const updateVMInformationByID = id => ({
  type: UPDATE_VM_INFORMATION,
  id
});

export const updateStorageArrayByID = data => ({
  type: UPDATE_STORAGE_ARRAY,
  data
});

export const updateHyperVOrVMware = (id, data) => ({
  type: UPDATE_HYPER_V_OR_VMWARE,
  id,
  data
});

export const loadProxies = (page = 1, pageSize = 25) => ({
  type: LOAD_CONFIGURE_PROXIES,
  page,
  pageSize
});

export const getProxiesData = data => ({
  type: GET_PROXIES_DATA,
  data
});

export const updateProxy = (id, data) => ({
  type: UPDATE_PROXY,
  id,
  data
});

export const deleteProxyById = (data, action) => ({
  type: REMOVE_PROXY,
  data,
  action
});

export const editOrganization = data => ({
  type: EDIT_ORGANIZATION,
  data
});

export const twoFactorUserDisable = id => ({
  type: TWO_FACTOR_USER_DISABLE,
  id
});

/**
 * Upgrade Site action
 * @param {Object} data
 * @returns {null}
 */
export const upgradeSite = data => ({
  type: UPGRADE_SITE,
  data
});

export const addHypervisor = () => ({
  type: ADD_HYPERVISOR
});

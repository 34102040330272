import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment from "moment";
import { ID_PREFIX } from "configs/IdConfig";

// const optionsHours = new Array(24)
//   .fill(0)
//   .map((h, i) => (h + i).toString().padStart(2, "0"));
// const optionsMinutes = new Array(60)
//   .fill(0)
//   .map((m, i) => (m + i).toString().padStart(2, "0"));

const optionsHours = (showFutureTimeOnly, selectedDate) => {
  let array = new Array(24)
    .fill(0)
    .map((h, i) => (h + i).toString().padStart(2, "0"));

  const todaysDate = moment().format("DD/MM/YYYY");
  const selectedDateFormatted = moment(selectedDate).format("DD/MM/YYYY");

  if (
    showFutureTimeOnly &&
    selectedDate &&
    todaysDate === selectedDateFormatted
  ) {
    ///If today's date is selected
    let currentTime = moment().format("HH");
    for (let i = array.length - 1; i >= 0; i--) {
      if (Number(array[i]) < Number(currentTime)) {
        array.splice(i, 1);
      }
    }
  }

  return array;
};

const optionsMinutes = (showFutureTimeOnly, selectedDate, scheduleTime) => {
  let array = new Array(60)
    .fill(0)
    .map((m, i) => (m + i).toString().padStart(2, "0"));

  const todaysDate = moment().format("DD/MM/YYYY");
  const selectedDateFormatted = moment(selectedDate).format("DD/MM/YYYY");
  const currentTimeinHrs = moment().format("HH");
  const currentTimeinMin = moment().format("mm");
  const selectedHour = scheduleTime.split(":")[0];

  if (
    showFutureTimeOnly &&
    selectedDate &&
    scheduleTime &&
    todaysDate === selectedDateFormatted &&
    selectedHour === currentTimeinHrs
  ) {
    ///If today's date and current hour is selected
    for (let i = array.length - 1; i >= 0; i--) {
      if (Number(array[i]) < Number(currentTimeinMin)) {
        array.splice(i, 1);
      }
    }
  }
  return array;
};

const optionsSeconds = new Array(60)
  .fill(0)
  .map((m, i) => (m + i).toString().padStart(2, "0"));

const TimePicker = ({
  value,
  input,
  disabled: isDisabled,
  onlyMinutes,
  variant, //["filled","outlined","standard"]
  classes,
  customStyle,
  onMenuItemClick,
  onChange,
  label,
  isSecondsVisible,
  customMenuClassName = "",
  showFutureTimeOnly,
  selectedDate,
  scheduletime,
  defaultValue,
  id,
  testId
}) => {
  const [hoursList, setHoursList] = useState([]);
  const [minutesList, setMinutesList] = useState([]);

  useEffect(() => {
    setHoursList(optionsHours(showFutureTimeOnly, selectedDate));
    setMinutesList(
      optionsMinutes(showFutureTimeOnly, selectedDate, scheduletime)
    );
    if (!value || value === "") {
      // isSecondsVisible ? changeTime("00", "00", "00") : changeTime("00", "00");
      const todaysDate = moment().format("DD/MM/YYYY");
      const selectedDateFormatted = moment(selectedDate).format("DD/MM/YYYY");
      const currentTimeinHrs = moment().format("HH");
      const currentTimeinMin = moment().format("mm");
      const currentTimeinSec = moment().format("ss");

      if (
        showFutureTimeOnly &&
        selectedDate &&
        todaysDate === selectedDateFormatted
      ) {
        isSecondsVisible
          ? changeTime(currentTimeinHrs, currentTimeinMin, currentTimeinSec)
          : changeTime(currentTimeinHrs, currentTimeinMin);
      } else {
        isSecondsVisible
          ? changeTime("00", "00", "00")
          : changeTime("00", "00");
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (scheduletime && showFutureTimeOnly) {
      setMinutesList(
        optionsMinutes(showFutureTimeOnly, selectedDate, scheduletime)
      );
    }
  }, [scheduletime]);

  useEffect(() => {
    if (selectedDate && showFutureTimeOnly) {
      setHoursList(optionsHours(showFutureTimeOnly, selectedDate));
      setMinutesList(
        optionsMinutes(showFutureTimeOnly, selectedDate, scheduletime)
      );
    }
  }, [selectedDate]);

  const changeTime = (hours, minutes, seconds) => {
    const newVal = isSecondsVisible
      ? `${hours.padStart(2, "0")}:${minutes}:${seconds}`
      : `${hours.padStart(2, "0")}:${minutes}`;
    onChange && onChange(newVal);
  };

  const handleHour = event => {
    onMenuItemClick({
      key: event.target.value,
      value: event.target.value,
      field: "hour"
    });
    const newVal = isSecondsVisible
      ? `${event.target.value.padStart(2, "0")}:${minute}:${second}`
      : `${event.target.value.padStart(2, "0")}:${minute}`;
    onChange && onChange(newVal);
  };

  const handleMin = event => {
    onMenuItemClick({
      key: event.target.value,
      value: event.target.value,
      field: "minute"
    });
    const newVal = isSecondsVisible
      ? `${hour}:${event.target.value}:${second}`
      : `${hour}:${event.target.value}`;
    onChange && onChange(newVal);
  };

  const handleSec = event => {
    onMenuItemClick({ key: event.target.value, value: event.target.value });
    const newVal = `${hour}:${minute}:${event.target.value}`;
    onChange && onChange(newVal);
  };

  const minutesId = id
    ? `${id}_minutes`
    : testId
    ? `${ID_PREFIX}${testId}_minutes`
    : "";
  const secondsId = id
    ? `${id}_minutes`
    : testId
    ? `${ID_PREFIX}${testId}_seconds`
    : "";
  const hoursId = id
    ? `${id}_hours`
    : testId
    ? `${ID_PREFIX}${testId}_hours`
    : "";

  let hour = value ? value.split(":")?.[0]?.padStart(2, "0") : "00";
  let minute = value ? value.split(":")?.[1]?.padStart(2, "0") : "00";
  let second = value ? value.split(":")?.[2]?.padStart(2, "0") : "00";

  return (
    <div>
      <div className="time-wrapper-left hoursAndMinutes">
        <FormControl className={classes?.formControl}>
          <Select
            title={onlyMinutes ? "*" : hour}
            disabled={isDisabled || onlyMinutes}
            variant={variant || "outlined"}
            value={hour}
            label={null}
            className={`${classes?.dropdownStyle} mui-action-select-dropdown`}
            onChange={handleHour}
            inputProps={{
              name: { label },
              id: `outlined-age-native-simple-${hoursId}`,
              "aria-label": "Without label"
            }}
            MenuProps={{
              PaperProps: { className: customMenuClassName }
            }}
          >
            {hoursList?.length > 0 &&
              hoursList.map(h => (
                <MenuItem
                  key={h}
                  value={h}
                  onSelect={() =>
                    isSecondsVisible
                      ? changeTime(h, minute, second)
                      : changeTime(h, minute)
                  }
                >
                  <span>{h}</span>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <span className="colonBetweenTime">:</span>
        <FormControl className={classes?.formControl}>
          <Select
            title={minute}
            disabled={isDisabled}
            variant={variant || "outlined"}
            value={minute}
            label={null}
            className={`${classes?.dropdownStyle} mui-action-select-dropdown`}
            onChange={handleMin}
            inputProps={{
              name: { label },
              id: `outlined-age-native-simple-${minutesId}`,
              "aria-label": "Without label"
            }}
            MenuProps={{
              PaperProps: { className: customMenuClassName }
            }}
          >
            {minutesList?.length > 0 &&
              minutesList.map(m => (
                <MenuItem
                  key={m}
                  value={m}
                  onSelect={() =>
                    isSecondsVisible
                      ? changeTime(hour, m, second)
                      : changeTime(hour, m)
                  }
                >
                  <span>{m}</span>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {isSecondsVisible && (
          <>
            <span className="colonBetweenTime">:</span>
            <FormControl className={classes.formControl}>
              <Select
                title={second}
                disabled={isDisabled}
                variant={variant || "outlined"}
                value={second}
                label={null}
                className={`${classes.dropdownStyle} mui-action-select-dropdown`}
                onChange={handleSec}
                inputProps={{
                  name: { label },
                  id: `outlined-age-native-simple-${secondsId}`,
                  "aria-label": "Without label"
                }}
                MenuProps={{
                  PaperProps: { className: customMenuClassName }
                }}
              >
                {optionsSeconds.map(s => (
                  <MenuItem
                    key={s}
                    value={s}
                    onSelect={() => changeTime(hour, minute, s)}
                  >
                    <span>{s}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </div>
    </div>
  );
};

TimePicker.propTypes = {
  disabled: PropTypes.bool,
  onlyMinutes: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

TimePicker.defaultProps = {
  disabled: false,
  onlyMinutes: false,
  classes: {},
  isSecondsVisible: false,
  showFutureTimeOnly: false,
  selectedDate: "",
  scheduletime: "",
  defaultValue: ""
};

export default React.memo(TimePicker);

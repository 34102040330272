/*
 *   file for API Methods
 *
 */
import { call, put, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import moment from "moment";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { addNotifications } from "state/actions/csr";
import * as ccToastActions from "state/actions/ccToast";
import { OCTA_REFRESH_TOKEN_URL, REFRESH_TOKEN_URL } from "./apiUrlConstants";
import { parseJwt } from "../../utils/authorizationUtil";
import { setJWTToken, logoutRequest, addAPIresponse } from "../actions/actions";
import { setOCTAToken } from "../actions/actions";
import { getFilename } from "../../utils/SpogDataGridUtil";
import { OCTA_LOGOUT } from "state/api/apiUrlConstants";
import * as constant from "utils/appConstants";
import Cookies from "universal-cookie";

const getLogin = state => state.login;
// const getCookieValue = name =>
//   document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

function* handleErrors(response, showCommonError = false, errorDismissTime) {
  if (response.errors && response.errors.length > 0) {
    // Cloud Console
    yield put(addNotifications(response.errors.map(err => err.message)));
    if (showCommonError) {
      let toastData = response.errors[0].message;
      if (showCommonError.showCommonError && showCommonError.testId) {
        toastData = {
          messageId: response.errors[0].message,
          testId: `${showCommonError.testId}_${response.status}`
        };
      }
      yield put(
        ccToastActions.addErrorNotification(toastData, "", "", errorDismissTime)
      );
    }
  } else if (
    (response.status === constant.HTTP_STATUS.INTERNAL_SERVER_ERROR ||
      response.status === constant.HTTP_STATUS.NOT_FOUND ||
      response.status === constant.HTTP_STATUS.BAD_REQUEST) &&
    response.error
  ) {
    let toastData = "toast-errors.internal-error";
    if (showCommonError.showCommonError && showCommonError.testId) {
      toastData = {
        messageId: "toast-errors.internal-error",
        testId: `${showCommonError.testId}_${response.status}`
      };
    }
    yield put(
      ccToastActions.addErrorNotification(toastData, "", "", errorDismissTime)
    );
  } else if (response.status === constant.HTTP_STATUS.UNAUTHORIZED) {
    let toastData = "error.spog.403";
    if (showCommonError.showCommonError && showCommonError.testId) {
      toastData = {
        messageId: "error.spog.403",
        testId: `${showCommonError.testId}_${response.status}`
      };
    }
    yield put(
      ccToastActions.addErrorNotification(toastData, "", "", errorDismissTime)
    );
  } else if (response.status === "ERROR" && response.message) {
    // Cloud Direct
    yield put(addNotifications([response.message]));
  }
}

export function* getData(
  url,
  queryString = "",
  token = "",
  pollingAction = false,
  showCommonError = false,
  showLoader = true
) {
  try {
    if (showLoader) {
      yield put(showLoading());
    }
    if (!pollingAction && shouldRefreshToken(token)) {
      yield call(refreshToken);
      if (url.includes(process.env.REACT_APP_CLOUDVOLUME_HOST_URL)) {
        yield call(getRefreshTokenDetails);
      }
      let tokenDetails = yield select(getLogin);
      token = tokenDetails.token;
    }
    let response = yield call(getAPIRequest, url, queryString, token);
    if (
      response &&
      response.status === 401 &&
      response.errors[0] &&
      response.errors[0].code === "00900006"
    ) {
      if (pollingAction) {
        yield put(addAPIresponse(response));
        yield call(delay, 5000);
        yield put(logoutRequest(response));

        return;
      } else if (yield call(refreshToken)) {
        const stateLoginPostRefresh = yield select(getLogin);
        response = yield call(
          getAPIRequest,
          url,
          queryString,
          stateLoginPostRefresh.token
        );
      } else {
        return;
      }
    }
    yield call(handleErrors, response, showCommonError);
    return response;
  } catch (e) {
    window.console.log(e);
  } finally {
    if (showLoader) {
      yield put(hideLoading());
    }
  }
}

export const getAPIRequest = async (url, queryString, token) => {
  let requestGetHeaders = {
    origin: "*",
    Authorization: `Bearer ${token}`
  };

  let fullUrl = url;
  if (queryString !== "") {
    fullUrl = `${url}?${queryString}`;
  }
  try {
    return fetch(fullUrl, {
      headers: requestGetHeaders
    })
      .then(response => response.json()) //On successful response get the JSON Object.
      .then(function(response) {
        // on error display error message.
        return response;
      });
  } catch (e) {
    window.console.log(e);
  }
};

/*
 *   get plain text data method
 *
 */
export const getPlainTextData = async (url, queryString, token) => {
  let requestGetHeaders = {
    origin: "*",
    Authorization: `Bearer ${token}`
  };

  let fullUrl = url;
  if (queryString !== "") {
    fullUrl = `${url}?${queryString}`;
  }
  try {
    return fetch(fullUrl, {
      headers: requestGetHeaders
    })
      .then(response => response.text()) //On successful response get the JSON Object.
      .then(function(response) {
        // on error display error message.

        return response;
      });
  } catch (e) {
    window.console.log(e);
  }
};

/*
 *   file for API Methods
 *
 */

export function* getCSVData(
  url,
  queryString = "",
  token = "",
  showCommonError = false
) {
  yield put(showLoading());
  let response = yield call(getCSVDataAPIRequest, url, queryString, token);
  if (
    response &&
    response.status === 401 &&
    response.errors[0] &&
    response.errors[0].code === "00900006"
  ) {
    if (yield call(refreshToken)) {
      const stateLoginPostRefresh = yield select(getLogin);
      response = yield call(
        getCSVDataAPIRequest,
        url,
        queryString,
        stateLoginPostRefresh.token
      );
    } else {
      yield put(hideLoading());
      return;
    }
  }
  yield call(handleErrors, response, showCommonError);
  yield put(hideLoading());
  return response;
}

/*
 *   file for API Methods
 *
 */

export const getCSVDataAPIRequest = async (url, queryString, token) => {
  const requestGetHeaders = {
    origin: "*",
    Authorization: `Bearer ${token}`
  };

  let fullUrl = url;
  if (queryString !== "") {
    fullUrl = `${url}?${queryString}`;
  }
  try {
    return fetch(fullUrl, {
      headers: requestGetHeaders
    }).then(response => {
      let filename = getFilename(response.headers.get("Content-Disposition"));
      return response
        .blob() //On successful response get the Blob Object.
        .then(function(response) {
          // on error display error message.
          return { response, filename };
        });
    });
  } catch (e) {
    window.console.log(e);
  }
};

export function* postData(
  url,
  data = {},
  token = "",
  showCommonError = false,
  skipRefresh = false,
  errorDismissTime
) {
  try {
    yield put(showLoading());
    if (!skipRefresh && shouldRefreshToken(token)) {
      yield call(refreshToken);
      if (url.includes(process.env.REACT_APP_CLOUDVOLUME_HOST_URL)) {
        yield call(getRefreshTokenDetails);
      }
      //token = yield select(getLogin).refresh_token;
      let tokenDetails = yield select(getLogin);
      token = tokenDetails.token;
    }
    let response = yield call(postDataAPIRequest, url, data, token);
    if (
      response &&
      response.status === 401 &&
      response.errors[0] &&
      response.errors[0].code === "00900006" &&
      !skipRefresh
    ) {
      if (yield call(refreshToken)) {
        const stateLoginPostRefresh = yield select(getLogin);
        response = yield call(
          postDataAPIRequest,
          url,
          data,
          stateLoginPostRefresh.token
        );
      } else {
        return;
      }
    }
    yield call(handleErrors, response, showCommonError, errorDismissTime);
    return response;
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideLoading());
  }
}

export const postDataAPIRequest = async (
  url,
  data,
  token,
  noAuthorizationHeader
) => {
  try {
    let header = !noAuthorizationHeader
      ? {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      : {
          Accept: "application/json",
          "Content-Type": "application/json"
        };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...header
      },
      body: JSON.stringify(data)
    }).then(response => response.json());

    return response;
  } catch (e) {
    window.console.log(e);
  }
};

export function* postCookieData(
  url,
  data = {},
  token = "",
  showCommonError = false,
  showloading = true
) {
  if (showloading) yield put(showLoading());
  let response = yield call(postCookieDataAPIRequest, url, data, token);
  if (
    response &&
    response.status === 401 &&
    response.errors[0] &&
    response.errors[0].code === "00900006"
  ) {
    if (yield call(refreshToken)) {
      const stateLoginPostRefresh = yield select(getLogin);
      response = yield call(
        postCookieDataAPIRequest,
        url,
        data,
        stateLoginPostRefresh.token
      );
    } else {
      if (showloading) yield put(hideLoading());
      return;
    }
  }
  yield call(handleErrors, response, showCommonError);
  if (showloading) yield put(hideLoading());
  return response;
}

export const postCookieDataAPIRequest = async (url, data, token) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify(data)
    }).then(response => response.json());

    return response;
  } catch (e) {
    window.console.log(e);
  }
};

export function* putData(url, data = {}, token = "", showCommonError = false) {
  yield put(showLoading());
  if (shouldRefreshToken(token)) {
    yield call(refreshToken);
    if (url.includes(process.env.REACT_APP_CLOUDVOLUME_HOST_URL)) {
      yield call(getRefreshTokenDetails);
    }
    //token = yield select(getLogin).refresh_token;
    let tokenDetails = yield select(getLogin);
    token = tokenDetails.token;
  }
  let response = yield call(putDataAPIRequest, url, data, token);
  if (
    response &&
    response.status === 401 &&
    response.errors[0] &&
    response.errors[0].code === "00900006"
  ) {
    if (yield call(refreshToken)) {
      const stateLoginPostRefresh = yield select(getLogin);
      response = yield call(
        putDataAPIRequest,
        url,
        data,
        stateLoginPostRefresh.token
      );
    } else {
      yield put(hideLoading());
      return;
    }
  }
  yield call(handleErrors, response, showCommonError);
  yield put(hideLoading());
  return response;
}

export const putDataAPIRequest = async (url, data, token) => {
  try {
    const requestPutHeaders = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    });
    const response = await fetch(url, {
      method: "PUT",
      headers: requestPutHeaders,
      body: JSON.stringify(data)
    }).then(response => response.json());

    return response;
  } catch (e) {
    window.console.log(e);
  }
};

export function* deleteData(url, token = "", data, showCommonError = false) {
  yield put(showLoading());
  let response = yield call(deleteDataAPIRequest, url, token, data);
  if (
    response &&
    response.status === 401 &&
    response.errors[0] &&
    response.errors[0].code === "00900006"
  ) {
    if (yield call(refreshToken)) {
      const stateLoginPostRefresh = yield select(getLogin);
      response = yield call(
        deleteDataAPIRequest,
        url,
        data,
        stateLoginPostRefresh.token
      );
    } else {
      yield put(hideLoading());
      return;
    }
  }
  yield call(handleErrors, response, showCommonError);
  yield put(hideLoading());
  return response;
}

export const deleteDataAPIRequest = async (url, token, data) => {
  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    const responseData = await response.json();

    return responseData;
  } catch (e) {
    window.console.log(e);
  }
};

export function* postDataMultipart(
  url,
  data = {},
  token = "",
  showCommonError = false
) {
  yield put(showLoading());
  let response = yield call(postDataMultipartAPIRequest, url, data, token);
  if (
    response &&
    response.status === 401 &&
    response.errors[0] &&
    response.errors[0].code === "00900006"
  ) {
    if (yield call(refreshToken)) {
      const stateLoginPostRefresh = yield select(getLogin);
      response = yield call(
        postDataMultipartAPIRequest,
        url,
        data,
        stateLoginPostRefresh.token
      );
    } else {
      yield put(hideLoading());
      return;
    }
  }
  yield call(handleErrors, response, showCommonError);
  yield put(hideLoading());
  return response;
}

export const postDataMultipartAPIRequest = async (url, data, token) => {
  try {
    const fd = new FormData();
    let timeStamp = new Date().getTime();
    fd.append("picture", data, timeStamp + "_profile.png");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: fd
    }).then(response => response.json());

    return response;
  } catch (e) {
    window.console.log(e);
  }
};
export function shouldRefreshToken(token) {
  try {
    if (token) {
      const parsedToken = parseJwt(token);
      // console.log(
      //   "tokenexpirytime",
      //   moment(parsedToken.exp * 1000).diff(moment(), "minutes")
      // );
      // return (
      //   moment(parsedToken.exp * 1000 - 3240000
      //     ).diff(moment(), "minutes") < 5
      // );
      return moment(parsedToken.exp * 1000).diff(moment(), "minutes") < 5;
    }
    return;
  } catch (e) {
    window.console.log(e);
  }
}
export function* refreshToken() {
  yield put(showLoading());
  const stateLogin = yield select(getLogin);

  try {
    const refreshTokenResponse = yield call(
      postDataAPIRequest,
      OCTA_REFRESH_TOKEN_URL,
      { refresh_token: stateLogin.refresh_token },
      "",
      true
      //,
      //stateLogin.refreshToken || stateLogin.refresh_token
      //stateLogin.token || stateLogin.token
    );
    if (refreshTokenResponse.status === 200) {
      let jwtToken;
      let refreshToken;
      jwtToken = refreshTokenResponse.data.access_token;
      refreshToken = refreshTokenResponse.data.refresh_token;
      //Set token in state
      const parsedToken = parseJwt(jwtToken);
      yield put(setOCTAToken({ token: jwtToken, refreshToken }));
      yield put(setJWTToken({ jwtToken, parsedToken, refreshToken }));
      return true;
    } else {
      // stop persistenting state
      window.stopSavingState();

      // clear state from local storage
      sessionStorage.removeItem("token");
      localStorage.removeItem("state");

      // refresh app
      //window.location.reload();
      //return false;

      // let token = localStorage.getItem("idtoken");
      // localStorage.removeItem("idtoken");
      // window.location.href = `${OCTA_LOGOUT}?id_token_hint=${token}`;
      return false;
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideLoading());
  }
}

export function* getRefreshTokenDetails() {
  yield put(showLoading());
  const stateLogin = yield select(getLogin);

  try {
    const refreshTokenResponse = yield call(
      postDataAPIRequest,
      REFRESH_TOKEN_URL,
      {},
      //stateLogin.refreshToken || stateLogin.refresh_token
      stateLogin.token || stateLogin.token
    );
    if (refreshTokenResponse.status === 200) {
      let jwtToken;
      let refreshToken;
      jwtToken = refreshTokenResponse.data.token;
      refreshToken = refreshTokenResponse.data.refresh_token;
      //Set token in state
      const parsedToken = parseJwt(jwtToken);
      yield put(setJWTToken({ jwtToken, parsedToken, refreshToken }));
      return parsedToken;
    } else {
      // stop persistenting state
      window.stopSavingState();

      // clear state from local storage
      sessionStorage.removeItem("token");
      localStorage.removeItem("state");
      //let token = getCookieValue("idtoken");
      let token = localStorage.getItem("idtoken");
      //localStorage.removeItem("idtoken");
      const cookies = new Cookies();
      //cookies.set("isLoggedOut", true, { path: "/", domain: ".arcserve.com" });
      cookies.remove("isSignedIn", { path: "/", domain: ".arcserve.com" });
      cookies.remove("cc", { path: "/", domain: ".arcserve.com" });
      window.location.href = `${OCTA_LOGOUT}?id_token_hint=${token}`;
      return false;
      // refresh app
      //window.location.reload();
      //return false;
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideLoading());
  }
}

export function* testConnectionData(
  url,
  token = "",
  data,
  showCommonError = false
) {
  yield put(showLoading());
  let response = yield call(postDataAPIRequest, url, data, token);
  if (
    response &&
    response.status === 401 &&
    response.errors[0] &&
    response.errors[0].code === "00900006"
  ) {
    if (yield call(refreshToken)) {
      const stateLoginPostRefresh = yield select(getLogin);
      response = yield call(
        postDataAPIRequest,
        url,
        data,
        stateLoginPostRefresh.token
      );
    } else {
      yield put(hideLoading());
      return;
    }
  }
  yield call(handleErrors, response, showCommonError);
  yield put(hideLoading());
  return response;
}

export function* postFileDataMultipart(
  url,
  data = {},
  token = "",
  showCommonError = false
) {
  yield put(showLoading());
  let response = yield call(postFileDataMultipartAPIRequest, url, data, token);
  if (
    response &&
    response.status === 401 &&
    response.errors[0] &&
    response.errors[0].code === "00900006"
  ) {
    if (yield call(refreshToken)) {
      const stateLoginPostRefresh = yield select(getLogin);
      response = yield call(
        postFileDataMultipartAPIRequest,
        url,
        data,
        stateLoginPostRefresh.token
      );
    } else {
      yield put(hideLoading());
      return;
    }
  }
  yield call(handleErrors, response, showCommonError);
  yield put(hideLoading());
  return response;
}

export const postFileDataMultipartAPIRequest = async (url, data, token) => {
  try {
    const fd = new FormData();
    let timeStamp = new Date().getTime();
    fd.append("csvFile", data, timeStamp + ".csv");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: fd
    }).then(response => response.json());

    return response;
  } catch (e) {
    window.console.log(e);
  }
};

export function* putFileDataMultipart(
  url,
  data = {},
  token = "",
  showCommonError = false
) {
  yield put(showLoading());
  let response = yield call(putFileDataMultipartAPIRequest, url, data, token);
  let responseCheck =
    response &&
    response.status === 401 &&
    response.errors &&
    response.errors[0].code === "00900006";

  if (responseCheck) {
    if (yield call(refreshToken)) {
      const stateLoginPostRefresh = yield select(getLogin);
      response = yield call(
        putFileDataMultipartAPIRequest,
        url,
        data,
        stateLoginPostRefresh.token
      );
    } else {
      yield put(hideLoading());
      return;
    }
  }
  yield call(handleErrors, response, showCommonError);
  yield put(hideLoading());
  return response;
}

export const putFileDataMultipartAPIRequest = async (url, data, token) => {
  try {
    const fd = new FormData();
    let timeStamp = new Date().getTime();
    fd.append("csvFile", data, timeStamp + ".csv");
    let response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: fd
    }).then(response => response.json());
    return response;
  } catch (e) {
    window.console.log(e);
  }
};

import React from "react";
import { injectIntl } from "react-intl";
import { CCGRAPH_IDS } from "utils/appConstants";
import Paper from "@mui/material/Paper";
import { convertGBtoTB } from "../utils";

const TooltipComponent = ({ active, payload, label, ...props }) => {
  let widthAdjust =
    props.chartConfig.ccGraphId === CCGRAPH_IDS.DASHBOARD_TOP_SOURCES ||
    props.chartConfig.ccGraphId === CCGRAPH_IDS.REPORTS_BACKUP_JOBS_TOP_SOURCES
      ? //    ||
        // props.chartConfig.ccGraphId ===
        //   CCGRAPH_IDS.REPORTS_DATA_TRANSFER_TOP_SOURCES ||
        // props.chartConfig.ccGraphId ===
        //   CCGRAPH_IDS.REPORTS_POLICY_TASKS_TOP_SOURCES ||
        // props.chartConfig.ccGraphId ===
        //   CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_TOP_SOURCES ||
        // props.chartConfig.ccGraphId === CCGRAPH_IDS.DASHBOARD_TOP_POLICIES
        190
      : 250;
  let right =
    widthAdjust + props.coordinate.x > props.myWidth
      ? props.coordinate.x - widthAdjust - 20
      : props.coordinate.x;
  if (active && payload && payload.length) {
    return (
      <Paper
        square
        elevation={0}
        className={`CustomTooltip ${props.className}`}
        style={
          props.chartConfig.ccGraphId === CCGRAPH_IDS.DASHBOARD_TOP_CUSTOMERS ||
          props.chartConfig.ccGraphId === CCGRAPH_IDS.DASHBOARD_TOP_SOURCES ||
          // props.chartConfig.ccGraphId ===
          //   CCGRAPH_IDS.REPORTS_DATA_TRANSFER_TOP_SOURCES ||
          // props.chartConfig.ccGraphId === CCGRAPH_IDS.DASHBOARD_TOP_POLICIES ||
          // props.chartConfig.ccGraphId ===
          //   CCGRAPH_IDS.REPORTS_POLICY_TASKS_TOP_SOURCES ||
          // props.chartConfig.ccGraphId ===
          //   CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_TOP_SOURCES ||
          props.chartConfig.ccGraphId ===
            CCGRAPH_IDS.REPORTS_BACKUP_JOBS_TOP_SOURCES
            ? { transform: `translate(${right}px, ${props.coordinate.y}px)` }
            : { transform: `translate(${right}px)` }
        }
      >
        <p className="RechartsTooltipHeader">
          {payload[0].payload.tooltipHeader}
          {(props.chartConfig.ccGraphId ===
            CCGRAPH_IDS.REPORTS_BACKUP_JOBS_TOP_SOURCES ||
            // CCGRAPH_IDS.REPORTS_DATA_TRANSFER_TOP_SOURCES ||
            // props.chartConfig.ccGraphId ===
            //   CCGRAPH_IDS.REPORTS_POLICY_TASKS_TOP_SOURCES ||
            // props.chartConfig.ccGraphId ===
            //   CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_TOP_SOURCES ||
            // props.chartConfig.ccGraphId ===
            //   CCGRAPH_IDS.DASHBOARD_TOP_POLICIES ||
            props.chartConfig.ccGraphId ===
              CCGRAPH_IDS.DASHBOARD_TOP_SOURCES) &&
            payload[0].payload.name}
        </p>
        <p
          style={{ width: "100%", borderTop: "1px solid rgb(233,234,238)" }}
        ></p>
        {payload.map((ele, index) => {
          let tooltipLabel = "";
          let tooltipValue = "";
          //eslint-disable-next-line default-case
          switch (props.chartConfig.ccGraphId) {
            case CCGRAPH_IDS.DASHBOARD_TOP_CUSTOMERS:
              tooltipLabel = ele.payload.toolTipContent;
              tooltipValue =
                ele.value >= 1024
                  ? `${convertGBtoTB(ele.value)} TB`
                  : `${ele.value} GB`;
              break;
            case CCGRAPH_IDS.MSP_CLOUD_DIRECT_USAGE:
            case CCGRAPH_IDS.CLOUD_DIRECT_USAGE:
            case CCGRAPH_IDS.REPORTS_CLOUD_DIRECT_USAGE:
            case CCGRAPH_IDS.REPORT_STORED_DATA:
            case CCGRAPH_IDS.REPORTS_MSP_CLOUD_DIRECT_USAGE:
            case CCGRAPH_IDS.DATA_STORES_USAGE:
            case CCGRAPH_IDS.MSP_DATA_STORES_USAGE:
            case CCGRAPH_IDS.REPORTS_BACKUP_JOBS_SUMMARY:
            // case CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_SUMMARY:
            // case CCGRAPH_IDS.REPORTS_POLICY_TASKS_SUMMARY:
            case CCGRAPH_IDS.CUSTOMER_DATA_STORES_USAGE:
            case CCGRAPH_IDS.CUSTOMER_METRICS_CD_USAGE:
            case CCGRAPH_IDS.DESTINATIONS_METRICS_CD_USAGE:
            case CCGRAPH_IDS.DESTINATIONS_METRICS_CH_USAGE:
            case CCGRAPH_IDS.BACKUP_JOBS_TREND:
            case CCGRAPH_IDS.REPORTS_CLOUD_HYBRID_USAGE:
            case CCGRAPH_IDS.CLOUD_HYBRID_USAGE:
            case CCGRAPH_IDS.CUSTOMER_METRICS_CH_USAGE:
            case CCGRAPH_IDS.REPORTS_DATA_TRANSFER_SUMMARY:
            case CCGRAPH_IDS.DATA_TRANSFER_CLOUD_DIRECT_VOLUME:
            case CCGRAPH_IDS.DASHBOARD_DEDUPE_SAVINGS_CLOUD_HYBRID:
              tooltipLabel = ele.name;
              if (props.order && props.order.length > 0)
                tooltipLabel = props.intl.formatMessage({
                  id: ele.name
                });
              tooltipValue =
                ele.value >= 1024
                  ? `${convertGBtoTB(ele.value)} TB`
                  : `${ele.value} GB`;
              break;
            case CCGRAPH_IDS.REPORTS_BACKUP_JOBS_TOP_SOURCES:
            // case CCGRAPH_IDS.REPORTS_DATA_TRANSFER_TOP_SOURCES:
            // case CCGRAPH_IDS.DASHBOARD_TOP_POLICIES:
            // case CCGRAPH_IDS.REPORTS_POLICY_TASKS_TOP_SOURCES:
            // case CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_TOP_SOURCES:
            case CCGRAPH_IDS.DASHBOARD_TOP_SOURCES:
              tooltipLabel = props.intl.formatMessage({
                id: `ccGraph.stacks.${ele.name}`
              });
              let tooltipFormattedValue =
                ele.name === "data_transferred"
                  ? ele.value >= 1024
                    ? `${convertGBtoTB(ele.value)} TB`
                    : `${ele.value} GB`
                  : ele.value;
              tooltipValue = tooltipFormattedValue;
              break;
          }
          return (
            <div className="rechartToolTipContent" key={index}>
              <p className="rechartTooltipItem">
                <span style={{}}>
                  <svg height="20" width="20">
                    <circle
                      cx="10"
                      cy="10"
                      r="5"
                      fill={props.color || ele.color}
                    />
                  </svg>{" "}
                </span>
                <span className="rechartTooltipItemContent">
                  <span className="rechartTooltipLeftContent">
                    {tooltipLabel}
                  </span>
                  <span className="recharts-tooltip-value">{tooltipValue}</span>
                </span>
              </p>
            </div>
          );
        })}
      </Paper>
    );
  }

  return null;
};

export default injectIntl(TooltipComponent);

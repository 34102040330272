import DataGridConstants from "components/common/data-grid/DataGridConstants";

const DataGridColumnCellTypes = DataGridConstants.GridColumnCellTypes;
const DataGridColumnHeaderTypes = DataGridConstants.GridColumnHeaderTypes;

export const DestinationDetailsTab = {
  information: {
    label: "information",
    tabKey: "information"
  },
  recovery_points: {
    label: "recovery-points",
    tabKey: "recovery_points"
  },
  metrics: {
    label: "metrics",
    tabKey: "metrics"
  }
};

/**
 * Holdes constants definitions for Sources
 */
export const DestinationAttributes = {
  DESTINATION_PROP_ID: "destination_id",
  DESTINATION_PROP_TYPE: "destination_type",
  DESTINATION_PROP_NAME: "destination_name",
  DESTINATION_PROP_DATA_CENTER_LOCATION: "datacenter_location",
  DESTINATION_PROP_RETENTION: "retention_name",
  DESTINATION_PROP_PRIMARY_USAGE: "primary_usage",
  DESTINATION_PROP_LAST_JOB: "last_job",
  DESTINATION_PROP_PROTECTION_POLICY: "policy",
  DESTINATION_PROP_ORGANIZATION_NAME: "organization_name",
  DESTINATION_PROP_OCCUPIED_SPACE: "occupied_space",
  DESTINATION_PROP_STORAGE_USAGE: "storage_usage",
  DESTINATION_PROP_ACTIONS: "available_actions",
  DESTINATION_PROP_PROTECTED_DATA: "protectedData",
  DESTINATION_PROP_CLOUD_ACCOUNT_NAME: "cloud_account_name",
  DESTINATION_PROP_SITE_NAME: "site_name",
  DESTINATION_PROP_DATA_CENTER_REGION: "datacenter_region",
  DESTINATION_PROP_SOURCES_PROTECTED: "sourcesProtected",
  DESTINATION_PROP_LATEST_JOB: "latestJob",
  DESTINATION_PROP_PARENT: "parent",
  DESTINATION_PROP_REGION: "region",
  DESTINATION_SOURCE_COUNT: "source_count"
};
const SortAttributes = {
  DESTINATION_PROP_LATEST_JOB_NAME: "name",
  DESTINATION_PROP_LATEST_JOB_STATUS: "status",
  DESTINATION_PROP_LATEST_JOB_DATE: "date"
};

export const DestinationConstants = {
  /**
   * Contextual actions for destinations
   */
  CONTEXTUAL_ACTION: {
    EDIT: "edit",
    RECOVERY_POINT: "view_recovery_point",
    DELETE: "delete",
    START: "start",
    RPS_START: "rps_jumpstart"
  }
};

/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier => {
  return "protect.destination.".concat(identifier);
};

/**
 * Additional Grid properties :
 * CLICKABLE_CELL_TO_VIEW_DETAILS : On click of cell the details will show
 * @type {{CLICKABLE_CELL_TO_VIEW_DETAILS: string}}
 */
export const GRID_PROPS = {
  CLICKABLE_CELL_TO_VIEW_DETAILS: DestinationAttributes.DESTINATION_PROP_NAME
};

/**
 * Column definition for the Destination table
 */
export const COLUMN_DEFS = [
  {
    accessor: DestinationAttributes.DESTINATION_PROP_PRIMARY_USAGE,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_PRIMARY_USAGE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_OCCUPIED_SPACE,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_OCCUPIED_SPACE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_TYPE,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_TYPE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_NAME,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_STORAGE_USAGE,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_STORAGE_USAGE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.BYTE_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_ORGANIZATION_NAME,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_ORGANIZATION_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_CLOUD_ACCOUNT_NAME,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_CLOUD_ACCOUNT_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_DATA_CENTER_REGION,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_DATA_CENTER_REGION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_DATA_CENTER_LOCATION,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_DATA_CENTER_LOCATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_SITE_NAME,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_SITE_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_PROTECTED_DATA,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_PROTECTED_DATA
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 220 }
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_RETENTION,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_RETENTION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_REGION,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_REGION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_PROTECTION_POLICY,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_PROTECTION_POLICY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.RENDER_POLICY_OVERLAY_CELL_MUI
    },
    settings: { width: 200 }
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_ACTIONS,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_ACTIONS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { width: 80 }
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_SOURCES_PROTECTED,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_SOURCES_PROTECTED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_LATEST_JOB,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_LATEST_JOB
    ),
    renderHeader: {
      type: DataGridColumnHeaderTypes.HEADER_TEXT_SELECT_MUI,
      customSort: true,
      additionOptions: [
        SortAttributes.DESTINATION_PROP_LATEST_JOB_NAME,
        SortAttributes.DESTINATION_PROP_LATEST_JOB_STATUS,
        SortAttributes.DESTINATION_PROP_LATEST_JOB_DATE
      ]
    },
    renderRecord: { type: DataGridColumnCellTypes.MULTILINE_TEXT_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_LAST_JOB,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_LAST_JOB
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LATEST_JOB_LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: DestinationAttributes.DESTINATION_PROP_PARENT,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_PROP_PARENT
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: DestinationAttributes.DESTINATION_SOURCE_COUNT,
    displayIdentifier: getDisplayIdentifier(
      DestinationAttributes.DESTINATION_SOURCE_COUNT
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 150 }
  }
];

export const ADD_RPS_CONSTANTS = {
  DEFAULT_PORT_RPS: 8014,
  DEFAULT_INSTALLATION_PATH: "%ProgramFiles%\\Arcserve\\Unified Data Protection"
};

export const CLOUD_TYPES = {
  WASABI: "wasabi_hot_cloud_storage",
  GCP: "google_cloud",
  AWS: "amazon_s3"
};

export const GOOGLE_CLOUD_ENDPOINT = "storage.googleapis.com";

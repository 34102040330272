import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { List } from "react-virtualized";
import { ID_PREFIX } from "configs/IdConfig";

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 40;
  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={250}
          width={250}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={props => {
            return React.cloneElement(children[props.index], {
              style: props.style
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

export default function VirtualizedSelectDropdown({
  options,
  label,
  onChange,
  customStyle,
  placeholder,
  id,
  testId
}) {
  let virtualizedSelectDropdownDropdownId = testId
    ? `${ID_PREFIX}${testId}`
    : id
    ? id
    : "";
  const textFieldClassname = label
    ? "multiselectDropdown"
    : "multiselectDropdown spog-mui-textfield";

  return (
    <Autocomplete
      id={virtualizedSelectDropdownDropdownId || "virtualize-demo"}
      style={customStyle}
      disableListWrap
      ListboxComponent={ListboxComponent}
      options={options}
      onChange={onChange}
      openText={null}
      closeText={null}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          className={textFieldClassname}
          placeholder={placeholder}
        />
      )}
    />
  );
}

import { v4 as uuidv4 } from "uuid";
import {
  LOAD_SHARED_FOLDER_LIST_SUCCESS,
  DELETE_SHARED_FOLDER_SUCCESS,
  UPDATE_SHARED_FOLDER
} from "../../actions/actionTypes";

const initialState = {
  sharedFolderList: [],
  errorMessageSf: "",
  isVisible: false
};

const SharedFolder = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SHARED_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        sharedFolderList: setsharedFolderList(action.data),
        sharedFolderListPagination: action.pagination
      };
    case DELETE_SHARED_FOLDER_SUCCESS:
      return {
        ...state,
        sharedFolderList: deletesharedFolderList(
          state.sharedFolderList,
          action.id
        ),
        sharedFolderListPagination: deleteTotal(
          state.sharedFolderListPagination
        )
      };
    case UPDATE_SHARED_FOLDER:
      return {
        ...state,
        errorMessageSf: ""
      };
    default:
      return state;
  }
};

const setsharedFolderList = data => {
  const available_actions = ["delete", "update"];
  return data.map(rc => {
    return {
      ...rc,
      id: uuidv4(),
      available_actions: available_actions
    };
  });
};

const deletesharedFolderList = (data, id) => {
  return data.filter(sf => sf.share_folder_id !== id);
};

const deleteTotal = data => {
  return {
    ...data,
    total_size: data.total_size - 1
  };
};

export default SharedFolder;

/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React from "react";
import { injectIntl, FormattedDate, FormattedTime } from "react-intl";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import SpogModal from "components/common/modal-mui";
import { SECONDARY_NAVIGATION_TYPE, PAGE_ROUTE } from "utils/appConstants";
import {
  deleteAlertById,
  deleteAlertsListDetails,
  selectMenuAction,
  updateSelectedResource,
  selectFilterAction,
  fromNavigationApi,
  getAdvancedSearchOptions,
  updateJobLogJobData
} from "state/actions/actions";
import FormattedMessage from "components/common/formatted-message";
import {
  makeAlertDetailsModalObjState,
  makeShowModalState
} from "state/selectors/CommonSelectors";
import Button from "components/common/button";
import * as util from "../../../../utils/SpogDataGridUtil";
import { JobNameNLSKey, JobNameNLSKeyHTML } from "../../jobs/JobsConstants";

const UNNECESSARY_DETAIL_KEYS = [
  "job_type",
  "source_id",
  "destination_ids",
  "site_id"
];
const FORMAT_BYTES_DETAIL_KEYS = [
  "storage_capacity",
  "storage_usage",
  "usage",
  "threshold"
];

/**
 * Parent component that loads all critical alerts
 */
const AlertDetails = ({ handleSelectFilter, history, intl }) => {
  // React-Redux Hooks
  const dispatch = useDispatch();
  const showAlertsModal = useSelector(makeAlertDetailsModalObjState());
  const showModal = useSelector(makeShowModalState());

  /**
   * to close alert details modal
   */
  const handleCloseModalEvent = () => {
    dispatch(deleteAlertsListDetails());
  };

  /**
   * Returns 'id' of nls string to be used to display job name
   * @argument job
   */
  const getJobNameKey = (job, flag) => {
    const flagSource = job.source_name && job.source_name.length > 0;
    const flagDestination =
      job.destination_name && job.destination_name.length > 0;

    if (flagSource && flagDestination) {
      return flag ? JobNameNLSKeyHTML.COMPLETE : JobNameNLSKey.COMPLETE;
    }
    if (!flagSource && flagDestination) {
      return flag
        ? JobNameNLSKeyHTML.DESTINATION_ONLY
        : JobNameNLSKey.DESTINATION_ONLY;
    }
    if (flagSource && !flagDestination) {
      if (job.job_type === "deploy_policy_to_udp") {
        return flag
          ? JobNameNLSKeyHTML.FOR_SOURCE_ONLY
          : JobNameNLSKey.FOR_SOURCE_ONLY;
      }
      return flag ? JobNameNLSKeyHTML.SOURCE_ONLY : JobNameNLSKey.SOURCE_ONLY;
    }
    return flag ? JobNameNLSKeyHTML.NAME_ONLY : JobNameNLSKey.NAME_ONLY;
  };
  /**
   * to handle view log click and navigate to source details
   */
  const handleModalViewClick = item => {
    handleCloseModalEvent();
    dispatch(fromNavigationApi(true));
    const selectedJob = {
      source_name: item.details.source_name,
      destination_name:
        item.details.destination_names &&
        item.details.destination_names.join(", "),
      job_type: item.details.job_type
    };
    const job_name = intl.formatMessage(
      { id: getJobNameKey(selectedJob, false) },
      {
        name: selectedJob.job_type
          ? intl.formatMessage({
              id: selectedJob.job_type
            })
          : "Job",
        source: selectedJob.source_name,
        destination: selectedJob.destination_name
      }
    );
    dispatch(updateSelectedResource({ ...item, job_name }));

    dispatch(updateJobLogJobData({ ...item, job_name }));
    dispatch(
      selectMenuAction({
        selectedMenu: "Analyze",
        selectedMenuKey: "Analyze"
      })
    );
    setTimeout(() => {
      dispatch(
        selectFilterAction({
          selectedFilter: SECONDARY_NAVIGATION_TYPE.LOG,
          selectedFilterKey: "Log"
        })
      );
    }, 0);
    history.push(
      `${PAGE_ROUTE.ANALYZE}/${SECONDARY_NAVIGATION_TYPE.JOBS}/all/${item.job_id}/${SECONDARY_NAVIGATION_TYPE.LOG}`
    );

    const filter = SECONDARY_NAVIGATION_TYPE.JOBLOG;
    dispatch(
      getAdvancedSearchOptions({
        filter
      })
    );
  };

  /**
   * To get source object from details
   * @param obj
   * @returns {*}
   */
  // const getSourceId = obj => {
  //   if (obj.details && obj.details["source"]) {
  //     return JSON.parse(obj.details["source"]).source_id;
  //   }
  //   return undefined;
  // };

  /**
   * to handle acknowledge dropdown click
   */
  const handleAcknowledgeClick = id => {
    handleCloseModalEvent();
    dispatch(deleteAlertById(id));
  };

  const alertObj = showAlertsModal && showAlertsModal.data;
  if (!alertObj) {
    return <div />;
  }
  if (alertObj.type === "cloud_sync_failed") {
    alertObj?.details?.datastore_id && delete alertObj.details.datastore_id;
  }

  return (
    <SpogModal
      className="alert-details"
      show={showModal}
      handleCloseModalEvent={handleCloseModalEvent}
      title="alerts.alert.title"
      size="small"
    >
      <div className="content">
        <div className="content-item">
          <Col md={12} className="text-left p-0">
            <Col md={12}>
              <span className="content-item-title">
                <FormattedMessage
                  id="alerts.label.alert"
                  defaultMessage="Alert"
                />
              </span>
            </Col>
            <Col md={12}>
              <span className="content-item-option align-left">
                {alertObj.title}
              </span>
            </Col>
          </Col>
        </div>
        <div className="content-item">
          <Col md={12} className="text-left p-0">
            <Col md={12}>
              <span className="content-item-title">
                <FormattedMessage
                  id="alerts.alert.date"
                  defaultMessage="Alert Date"
                />
              </span>
            </Col>
            <Col md={12}>
              <span className="content-item-option align-left">
                <FormattedDate value={new Date(alertObj.create_ts)} />
                &nbsp;
                <FormattedTime value={new Date(alertObj.create_ts)} />
              </span>
            </Col>
          </Col>
        </div>
        <div className="content-item">
          <Col md={12} className="text-left p-0">
            <Col md={12}>
              <span className="content-item-title">
                <FormattedMessage
                  id="alerts.severity"
                  defaultMessage="Severity"
                />
              </span>
            </Col>
            <Col md={12}>
              <span className="content-item-option align-left">
                <FormattedMessage
                  id={alertObj.severity}
                  defaultMessage={alertObj.severity}
                />
              </span>
            </Col>
          </Col>
        </div>
        {alertObj.details &&
          Object.keys(alertObj.details).map(key => {
            // remove unwanted key object
            if (UNNECESSARY_DETAIL_KEYS.indexOf(key) !== -1) return "";
            if (alertObj.details[key] === "") return "";
            let show = ["source"].indexOf(key) === -1;
            const time = ["time", "ts"].indexOf(key.split("_").pop()) !== -1;
            const date =
              ["start", "end", "date"].indexOf(key.split("_").pop()) !== -1;
            let value = alertObj.details[key];
            if (
              key === "backup_duration" ||
              key === "ivm_duration" ||
              key === "mount_duration"
            ) {
              value = isNaN(value)
                ? value
                    .trim()
                    .split(" ")
                    .map(val => intl.formatMessage({ id: val }))
                    .join(" ")
                : value;
            } else if (key === "destination_names") {
              value = value.join(", ");
            }

            if (FORMAT_BYTES_DETAIL_KEYS.indexOf(key) !== -1) {
              value = util.formatBytes(value);
            }

            if (time || date) show = false;
            return (
              <div key={key} className="content-item">
                <Col md={12} className="text-left p-0">
                  <Col md={12}>
                    <span className="content-item-title">
                      <FormattedMessage
                        id={`alerts.details.${key}`}
                        defaultMessage={key}
                      />
                    </span>
                  </Col>
                  <Col md={12}>
                    <span className="content-item-option align-left">
                      {key === "source" && (value.source_name || value)}
                      {time && value === "" ? (
                        <span>N/A</span>
                      ) : time && value !== 0 ? (
                        <span>
                          <FormattedDate value={new Date(value * 1000)} />
                          &nbsp;
                          <FormattedTime value={new Date(value * 1000)} />
                        </span>
                      ) : null}
                      {date && value && (
                        <FormattedDate value={new Date(value * 1000)} />
                      )}
                      {time && value === 0 && value}
                      {show && value}
                    </span>
                  </Col>
                </Col>
              </div>
            );
          })}
        <div className="content-item">
          <Col md={12} className="text-left p-0">
            <Col md={12}>
              <span className="content-item-title">
                <FormattedMessage
                  id="alerts.label.decription"
                  defaultMessage="Description"
                />
              </span>
            </Col>
            <Col md={12}>
              <span className="content-item-option align-left">
                {alertObj.description}
              </span>
              <span className="content-item-option align-left content-item-address">
                {alertObj.contact_email}
                {alertObj.contact_phone && (
                  <div>
                    {`${alertObj.contact_phone} `}
                    <br />
                  </div>
                )}
              </span>
            </Col>
          </Col>
        </div>
      </div>
      <div
        className="footer d-flex justify-content-end"
        style={{ position: "absolute", justifyContent: "flex-end" }}
      >
        {/* View Log button */}
        {alertObj.allowed_actions.indexOf("viewlogs") !== -1 && (
          <Button
            // className="btn btn-primary"
            color="primary"
            type="button"
            variant="contained"
            style={{ margin: "5px" }}
            onClick={() => {
              handleModalViewClick(alertObj);
            }}
            content={
              <FormattedMessage
                id="alerts.button.viewlog"
                defaultMessage="View Logs"
              />
            }
          />
        )}
        {/* Acknowledge button */}
        {alertObj.allowed_actions.indexOf("acknowledge") !== -1 && (
          <Button
            // className="btn btn-secondary"
            type="button"
            variant="contained"
            color="secondary"
            style={{ margin: "5px" }}
            onClick={() => {
              const id = alertObj.alert_id;
              handleAcknowledgeClick(id);
            }}
            content={
              <FormattedMessage
                id="alerts.button.acknowledge"
                defaultMessage="Acknowledge"
              />
            }
          />
        )}
        {/* Cancel button */}
        <Button
          // className="btn btn-secondary"
          type="button"
          variant="contained"
          color="secondary"
          style={{ margin: "5px" }}
          onClick={handleCloseModalEvent}
          content={
            <FormattedMessage
              id="alerts.button.cancel"
              defaultMessage="Cancel"
            />
          }
        />
      </div>
    </SpogModal>
  );
};

/**
 * Default Properties of component
 * @type {{showAlertsModal: * }}
 */
AlertDetails.defaultProps = {
  showAlertsModal: {}
};

/**
 * Properties of component
 * @type {{showAlertsModal: *, showAlertDetailsModal: * }}
 */
AlertDetails.propTypes = {
  showAlertsModal: PropTypes.shape({}),
  handleSelectFilter: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired
};

export default injectIntl(AlertDetails);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  usePagination,
  useSortBy,
  useRowSelect
} from "react-table";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import { injectIntl } from "react-intl";
import { DARK_MODE } from "utils/theme";
import GridFooter from "./GridFooter";
import { GridLoader, GridNoDataText } from "./utils.js";
import {
  makeSelectedRowsState,
  makeCheckboxSelectionState,
  makeCheckboxSelectedFromAllPagesState
} from "../../../state/selectors/CommonSelectors";
import {
  getSelectedRows,
  selectunselectRow,
  getSelectedRowsFromAllPages,
  setColumnWidth,
  setSQLServerWriter
} from "../../../state/actions/actions";
import { ID_PREFIX } from "configs/IdConfig";
import { DATAGRID_IDS } from "utils/appConstants";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, prop1, ...rest }, ref) => {
    rest?.title && delete rest.title;
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox
          size="small"
          color="primary"
          ref={resolvedRef}
          style={{ color: "grey", border: "1px solid grey" }}
          disabled={prop1.checkboxDisabled ? true : false}
          {...rest}
        />
      </>
    );
  }
);

/**
 * Re-usable data grid with customizable columns, records view
 * and a pagination footer.
 */

function DataGrid(props) {
  const dispatch = useDispatch();
  const [resetSelectedRows, setResetSelectedRows] = useState(false);
  const selectionData = useSelector(makeCheckboxSelectionState()) || [];
  const showGridLoader = useSelector(state =>
    state.reducer.gridLoaderById.hasOwnProperty(props.testId)
      ? state.reducer.gridLoaderById[props.testId]
      : state.reducer.gridLoader
  );
  const selectedRowsFromAllPages =
    useSelector(makeCheckboxSelectedFromAllPagesState()) || [];
  const [prevSortedColumn, setPrevSortedColumn] = useState(null);
  const gridcolumn = React.useMemo(() => [...props.columns], [props.columns]);
  const gridData = React.useMemo(() => [...props.data], [props.data]);
  let testId = props && props.testId ? `${ID_PREFIX}${props.testId}` : "";
  let SubComponent = props && props.SubComponent;
  const theme = useSelector(state => state.reducer.theme);

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    selectedFlatRows,
    toggleAllPageRowsSelected,
    state: { pageIndex, pageSize, sortBy, selectedRowIds, columnResizing }
  } = useTable(
    {
      columns: gridcolumn,
      data: gridData,
      id: testId,
      keyAsTestId: props.keyAsTestId,
      stateReducer: (newState, action) => {
        if (
          props.isSQLServerWriterChecked &&
          action.type === "toggleRowSelected" &&
          action.value === false
        ) {
          dispatch(setSQLServerWriter(false));
        }

        if (props.triggerCheckboxSelection) {
          newState.selectedRowIds = { ...props.triggerCheckboxSelection };
          props.setCheckboxTriggerFalse(false);
        }

        if (props.isSingleSelect && props.resetColumnSelection) {
          newState.selectedRowIds = {};
          if (props.resetColumnSelection) {
            props.setResetColumnSelection(false);
          }
        }

        if (props.isSingleSelect && action.type === "toggleRowSelected") {
          let selection = [];

          if (
            selectionData &&
            selectionData.length &&
            gridData &&
            gridData.length &&
            selectionData[0].id === gridData[action.id].id
          ) {
            newState.selectedRowIds = {
              [action.id]: false
            };
          } else {
            newState.selectedRowIds = {
              [action.id]: true
            };
            selection.push(gridData[action.id]);
          }

          dispatch(getSelectedRows(selection.length));
          dispatch(selectunselectRow({ selection }));
        }

        return newState;
      },
      autoResetResize: false,
      autoResetSortBy: false,
      autoResetSelectedRows: props.selectionFromAllPages
        ? false
        : resetSelectedRows,
      getRowId: (row, index) =>
        props.selectionFromAllPages && row.id ? row.id : index,
      manualSortBy: props && props.usePaginationWithApi,
      initialState: {
        pageIndex: 0,
        pageSize: props.defaultGridPageSize ? props.defaultGridPageSize : 25,
        selectedRowIds: props.defaultSelectedRowsData
          ? props.defaultSelectedRowsData
          : {}
      }
    },
    useSortBy,
    useFlexLayout,
    useResizeColumns,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          width: "68px",
          maxWidth: "70px",
          minWidth: "60px",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) =>
            props.isSingleSelect ? (
              <div
                style={{
                  height: 29,
                  width: 38.8
                }}
              />
            ) : (
              <div
                style={{
                  height: 29,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                  prop1={{ checkboxDisabled: props.checkboxDisabled }}
                />
              </div>
            ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div
              id={`${testId}_tr_${row.id}_${`selection`}`}
              style={{
                height: 29,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                prop1={{ checkboxDisabled: props.checkboxDisabled }}
              />
            </div>
          )
        },
        props.SubComponent
          ? {
              id: "expand",
              width: "35px",
              maxWidth: "35px",
              minWidth: "35px",
              Header: () =>
                props.SubComponent && (
                  <div
                    style={{
                      width: 35
                    }}
                  />
                )
            }
          : {},
        ...columns
      ]);
    }
  );

  let gridRows = props.usePaginationWithApi ? [...rows] : [...page];

  useEffect(() => {
    if (props.isSQLServerWriterChecked) {
      toggleAllPageRowsSelected(true);
    }
  }, [props.isSQLServerWriterChecked, gridRows.length, pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prevSortedColumn && !sortBy.length) {
      props.onSortChange && props.onSortChange("");
    } else {
      props.onSortChange && sortBy.length && props.onSortChange(sortBy);
    }
    sortBy.length && setPrevSortedColumn({ ...sortBy[0] });
  }, [sortBy]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.noSelectionDataUpdate) {
      //if  we do not want to update selection data in redux store
      if (!props.selectionFromAllPages) {
        resetSelectedRows && setResetSelectedRows(false);
      }
      let selection = [...selectionData];
      if (!selectedFlatRows || !selectedFlatRows.length) {
        selection = [];
      }
      const isSingleSelect = props.isSingleSelect;

      if (!isSingleSelect) {
        selection = selectedFlatRows.map(item => {
          return { ...item.original };
        });
        dispatch(getSelectedRows(selection.length));
        dispatch(selectunselectRow({ selection }));

        if (props.selectionFromAllPages) {
          let allRows = [...selectedRowsFromAllPages, ...selection];
          let uniqueRows = allRows.reduce((unique, o) => {
            if (!unique.some(obj => obj.id === o.id)) {
              unique.push(o);
            }
            return unique;
          }, []);
          /// "selectedRowIds" will have ids of all the item selected from all pages
          let selectedIds = Object.keys(selectedRowIds);
          let selectedRows = uniqueRows.filter(item =>
            selectedIds.includes(item.id)
          );
          dispatch(getSelectedRowsFromAllPages(selectedRows));
        }
      }
    }
  }, [selectedFlatRows.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.selectedRows === 0 &&
      selectedFlatRows.length &&
      setResetSelectedRows(true);
  }, [props.selectedRows]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.showPagination === false) setPageSize(props.defaultGridPageSize);
  }, [props.defaultGridPageSize]);

  useEffect(() => {
    if (
      columnResizing &&
      columnResizing.columnWidths &&
      !isEmpty(columnResizing.columnWidths)
    ) {
      let newResized = [];
      let colWidths = columnResizing.columnWidths;
      let keys = Object.keys(columnResizing.columnWidths);
      for (let i = 0; i < keys.length; i++) {
        newResized.push({ id: keys[i], value: colWidths[keys[i]] });
      }
      if (
        !columnResizing.isResizingColumn &&
        !props.disableColumnWidthDispatch
      ) {
        //Removing columnwidth dispatch for oracle hosts
        dispatch(setColumnWidth({ newResized }));
      }
    }
  }, [columnResizing]); // eslint-disable-line react-hooks/exhaustive-deps

  const [open, setOpen] = useState({});

  // Render the UI for your table
  return (
    <Paper
      elevation={0}
      className={`spog-datagrid-paper ${
        !props.showPagination ? "no-pagination" : ""
      }`}
    >
      <TableContainer
        className={`spog-datagrid-container ${theme?.palette?.mode ===
          DARK_MODE && "dark-theme-table-container"}`}
      >
        <MaUTable stickyHeader {...getTableProps()} size="small" id={testId}>
          {props.withoutHeader ? null : (
            <TableHead id={`${testId}_th`} style={{ zIndex: 2 }}>
              {//eslint-disable-next-line array-callback-return
              headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                    //eslint-disable-next-line eqeqeq
                    if (column.id == undefined) {
                      //eslint-disable-next-line array-callback-return
                      return;
                    }
                    if (props.hideCheckbox && column.id === "selection") {
                      return;
                    }
                    let sortArrow =
                      column.disableSortBy ||
                      column.id === "selection" ||
                      column.id === "icon" ||
                      column.id === "expand" ||
                      column.id === "showErrorIcon" ? null : (
                        <TableSortLabel
                          active={true}
                          direction={"desc"}
                          className="mui-table-sort-default"
                        ></TableSortLabel>
                      );
                    return (
                      <TableCell
                        {...column.getHeaderProps()}
                        id={`${testId}_th_${column.id}`}
                      >
                        <div
                          {...column.getSortByToggleProps({ title: undefined })}
                          style={{ display: "flex" }}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <TableSortLabel
                                  active={true}
                                  direction={"desc"}
                                ></TableSortLabel>
                              ) : (
                                <TableSortLabel
                                  active={true}
                                  direction={"asc"}
                                ></TableSortLabel>
                              )
                            ) : (
                              sortArrow
                            )}
                          </span>
                        </div>
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? "isResizing" : ""
                          }`}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
          )}
          <TableBody id={`${testId}_tbody`}>
            {gridRows.length > 0 ? (
              gridRows.map((row, i) => {
                prepareRow(row);
                return (
                  <React.Fragment>
                    <TableRow
                      {...row.getRowProps()}
                      className={`${row.original.is_deleted ? "ghost" : ""} ${
                        row && row.isSelected ? "dg_row_selected" : ""
                      } dg_row`}
                    >
                      {props.SubComponent != null && (
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen({ [i]: !open[i] })}
                          >
                            {open[i] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      )}
                      {//eslint-disable-next-line array-callback-return
                      row.cells.map(cell => {
                        if (
                          cell.column.id === "expand" ||
                          //eslint-disable-next-line eqeqeq
                          cell.column.id == undefined
                        ) {
                          //eslint-disable-next-line array-callback-return
                          return;
                        }
                        if (
                          props.hideCheckbox &&
                          cell.column.id === "selection"
                        ) {
                          //eslint-disable-next-line array-callback-return
                          return;
                        }
                        return (
                          <TableCell
                            className={
                              cell && cell.column && cell.column.noFlex
                                ? "dg-no-flex-column"
                                : ""
                            }
                            {...cell.getCellProps({
                              onClick: e => {
                                props.onRowCellClick(
                                  { id: cell.column.id },
                                  cell.row.original,
                                  e
                                );
                              }
                            })}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {props.SubComponent != null && (
                      <Collapse in={open[i]} timeout="auto" unmountOnExit>
                        <TableRow>
                          <TableCell>
                            <SubComponent
                              data={row.original}
                              getData={props.getData}
                              subComponentDetails={props.subComponentDetails}
                            />
                          </TableCell>
                        </TableRow>
                      </Collapse>
                    )}
                  </React.Fragment>
                );
              })
            ) : showGridLoader ? (
              <GridLoader />
            ) : (
              <GridNoDataText noDataText={props.noDataText} />
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
      {props.showPagination ? (
        <GridFooter
          {...props}
          pageIndex={pageIndex}
          gridPageSize={pageSize}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      ) : null}
    </Paper>
  );
}

DataGrid.defaultProps = {
  keyAsTestId: true,
  noDataText: "No rows found",
  noRowsFound: false,
  defaultPageSize: 25,
  defaultGridPageSize: 25,
  showPagination: true,
  usePaginationWithApi: true,
  hideCheckbox: false,
  allSelected: false,
  SubComponent: () => {},
  subComponentDetails: [],
  getData: () => {},
  clearState: () => {},
  onRowCellClick: () => {},
  onRowClick: () => {},
  onHeaderCellClick: () => {},
  totalSize: 0,
  page: 1,
  pages: 0,
  pageSize: 25,
  canNext: false,
  canPrevious: false,
  onSortChange: () => {},
  isSingleSelect: false,
  withoutHeader: false,
  triggerCheckboxSelection: false
};

DataGrid.propTypes = {
  keyAsTestId: PropTypes.bool,
  noDataText: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultPageSize: PropTypes.number,
  defaultGridPageSize: PropTypes.number,
  showPagination: PropTypes.bool,
  onRowClick: PropTypes.func,
  onRowCellClick: PropTypes.func,
  onHeaderCellClick: PropTypes.func,
  onSortChange: PropTypes.func,
  defaultSortedColumn: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  canPrevious: PropTypes.bool,
  canNext: PropTypes.bool,
  totalSize: PropTypes.number.isRequired,
  handlePaginationApi: PropTypes.func,
  usePaginationWithApi: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
  SubComponent: PropTypes.func,
  subComponentDetails: PropTypes.arrayOf(PropTypes.shape({})),
  getData: PropTypes.func,
  clearState: PropTypes.func,
  isSingleSelect: PropTypes.bool,
  withoutHeader: PropTypes.bool
};

const makeMapStateToProps = () => {
  const getSelectedRowsState = makeSelectedRowsState();

  const mapStateToProps = (state, props) => {
    return {
      selectedRows: getSelectedRowsState(state, props)
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(injectIntl(DataGrid));

import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { List } from "react-virtualized";

const filter = createFilterOptions();

// const LoadingDots = testId => {
//   return (
//     <div id={testId}>
//       {" "}
//       &nbsp;
//       <div className="state-loading-bar" />
//       <div className="state-loading-bar" />
//       <div className="state-loading-bar" />
//     </div>
//   );
// };

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 40;
  const height =
    Array.isArray(children) && children.length < 6 ? children.length * 40 : 250;
  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={height}
          width={250}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={props => {
            return React.cloneElement(children[props.index], {
              style: props.style
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

export default function VirtualizedCustomizableDropdown({
  options,
  label,
  onChange,
  customStyle,
  placeholder,
  id,
  value,
  selectedOption
}) {
  const textFieldClassname = label
    ? "multiselectDropdown"
    : "multiselectDropdown spog-mui-textfield";
  return (
    <React.Fragment>
      <Autocomplete
        id={id || "virtualize-demo"}
        value={value}
        style={customStyle}
        disableListWrap
        ListboxComponent={ListboxComponent}
        options={options}
        onChange={onChange}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            className={textFieldClassname}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(
            option => inputValue === option.label
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              label: `Add "${inputValue}"`
            });
          }
          return filtered;
        }}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.label;
        }}
      />
    </React.Fragment>
  );
}

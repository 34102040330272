import moment from "moment";
// import enTimeFormat from "assets/i18n/locales/time-format/en.json"; // defaults to this
import jaTimeFormat from "assets/i18n/locales/time-format/ja.json";

// used to display datetime on x-axis
export const lookupResolutionFormat = (resolution, locale) => {
  const map = {
    hour: "%X",
    day: "%x",
    month: "%b %y"
  };

  switch (locale) {
    case "ja":
      //eslint-disable-next-line no-lone-blocks
      {
        map.hour = jaTimeFormat.time;
        map.day = jaTimeFormat.date;
        map.month = jaTimeFormat.month;
      }
      break;
    /* ToDo - Import various time format for different languages*/
    // case "pt": {
    //   map.hour = ptTimeFormat.time;
    //   map.day = ptTimeFormat.date;
    //   map.month = ptTimeFormat.month;
    // }
    // break;
    // case "de": {
    //   map.hour = deTimeFormat.time;
    //   map.day = deTimeFormat.date;
    //   map.month = deTimeFormat.month;
    // }
    // break;
    // case "fr": {
    //   map.hour = frTimeFormat.time;
    //   map.day = frTimeFormat.date;
    //   map.month = frTimeFormat.month;
    // }
    // break;
    // case "it": {
    //   map.hour = itTimeFormat.time;
    //   map.day = itTimeFormat.date;
    //   map.month = itTimeFormat.month;
    // }
    // break;
    // case "es": {
    //   map.hour = esTimeFormat.time;
    //   map.day = esTimeFormat.date;
    //   map.month = esTimeFormat.month;
    // }
    // break;
    default:
      break;
  }

  return map.hasOwnProperty(resolution) ? map[resolution] : map.day;
};

export const convertGBtoTB = value => (value / 1024).toFixed(2);

export const lookupResolutionXaxisFormat = (resolution, locale, value) => {
  switch (locale) {
    case "ja":
    case "pt":
    case "de":
    case "fr":
    case "it":
    case "es": {
      moment.locale(locale);
      switch (resolution) {
        case "hour":
          return moment(value, "LLL").format("HH:mm");
        case "day":
          return moment(value, "LLL").format("l");
        case "month":
          return moment(value, "LLL").format("MM/YY");
        default:
          return moment(value, "LLL").format("l");
      }
    }
    case "en": {
      switch (resolution) {
        case "hour":
          return moment(new Date(value), "LLL").format("LTS");
        case "day":
          return moment(new Date(value), "LLL").format("l");
        case "month":
          return moment(new Date(value), "LLL").format("MMM YY");
        default:
          return moment(new Date(value), "LLL").format("l");
      }
    }
    default:
      return moment(value, "LLL").format("l"); //default
  }
};

export const setIntervalTicks = (x, y) => {
  // For BUG_1113328 Added this condition(Y===0) resolution is month on initial payload, After changing a date, resolutions become hour.
  // Have to check this and change the condition later.
  if (x <= y || y === 0) return 0;
  let i = 1;
  while (true) {
    if (x / i <= y) return i;
    i += 1;
  }
};

export default {};

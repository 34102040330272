import { unionBy, cloneDeep } from "lodash";
import * as actionTypes from "../actions/actionTypes";
import initialState from "../initialState";
import * as constant from "../../utils/appConstants";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ORGANIZATION_TYPE:
      return {
        ...state,
        organization_type: action.data.organization_type
      };

    case actionTypes.SET_THEME: {
      return {
        ...state,
        theme: action.data
      };
    }
    case actionTypes.IMPERSONATE_FROM_CSR: {
      return {
        ...state,
        isImpersonateFromCsr: true,
        impersonateUserType: action.impersonateUserType
      };
    }

    case actionTypes.GET_HYPERVISORS_SUCCESS: {
      return {
        ...state,
        hypervisorsList: action.data,
        isHypervPresent:
          action.data && Array.isArray(action.data) && action.data.length
            ? true
            : false
      };
    }
    case actionTypes.GET_SELECTED_ROWS_FROM_ALL_PAGES: {
      return {
        ...state,
        selectedRowsFromAllPages: action.data
      };
    }
    case actionTypes.GET_HYPERVISORS_FAILED: {
      return {
        ...state,
        hypervisorsList: [],
        isHypervPresent: false
      };
    }
    case actionTypes.RESET_HYPERVISORS_LIST: {
      return {
        ...state,
        hypervisorsList: []
      };
    }
    case actionTypes.UPDATE_SUBMIT_BTN_STATE: {
      return { ...state, submitBtnDisabled: action.flag };
    }

    case actionTypes.SET_CURRENT_TAB:
      return { ...state, currentTab: action.data };

    case actionTypes.SHOW_LOG_LIST:
      return {
        ...state,
        logData: action.data,
        disableLogExport:
          action.data && action.data.data && action.data.data.length > 0
            ? false
            : true
      };
    case actionTypes.SET_GO_BACK_NAVIGATION:
      return {
        ...state,
        hasGoBackNavigation: action.data
      };
    case actionTypes.SHOW_JOBS_LIST:
      return {
        ...state,
        jobsData: action.data
      };
    case actionTypes.RECOVERY_FNF_CONNECT_STATUS:
      return {
        ...state,
        connectStatus: action.data
      };

    case actionTypes.DISABLE_SAVE_POLCIY_BUTTON:
      return { ...state, disableSavePolicyButton: action.data };

    case actionTypes.SHOW_JOB_DETAILS:
      return { ...state, jobDetails: action.data };

    case actionTypes.CLEAR_JOB_DETAILS:
      return { ...state, jobDetails: null };

    case actionTypes.UPDATE_MONITOR_JOB_DETAILS:
      return { ...state, jobMonitorDetails: action.data };

    case actionTypes.CLEAR_MONITOR_JOB_DETAILS:
      return { ...state, jobMonitorDetails: {} };

    case actionTypes.UPDATE_SELECTED_RESOURCE:
      return { ...state, selectedResource: action.data };

    case actionTypes.CLEAR_SELECTED_RESOURCE:
      return { ...state, selectedResource: null };
    // handle fetch menu
    case actionTypes.FETCH_MENU:
      return Object.assign({}, state, { menu: action.payload });

    case actionTypes.COLLAPSE_SIDEBAR:
      return {
        ...state,
        collapseSideBar: !state.collapseSideBar
      };

    case actionTypes.SELECT_MENU: {
      return {
        ...state,
        primaryNavigation: action.selectedMenu,
        primaryKey: action.selectedMenuKey,
        secondaryNavigation: null,
        secondaryKey: null,
        showCurrentSearch:
          state.pageReloaded &&
          state.optionsForTags &&
          Object.keys(state.optionsForTags).length > 0
            ? true
            : false,
        currentSearchOptions: !state.pageReloaded
          ? null
          : state.currentSearchOptions,
        searchText: "",
        // searchOption: {
        //   ...state.searchOption,
        //   selectedOption: ""
        // },
        showAdvancedSearch: false,
        resourceName: null
        // menu: newStateObj
      };
    }

    case actionTypes.SELECT_FILTER:
      return {
        ...state,
        secondaryNavigation: action.selectedFilter,
        secondaryKey: action.selectedFilterKey,
        resourceName: null,
        gridLoader: true
      };
    case actionTypes.SHOW_GRID_LOADER:
      return {
        ...state,
        gridLoader: action.isLoading
      };
    case actionTypes.DATA_GRID:
      return Object.assign({}, state, { grid: action.payload });
    case actionTypes.DETECT_WINDOW_DIMENSIONS:
      return {
        ...state,
        containerWidth: window.innerWidth,
        containerHeight: window.innerHeight
      };
    // default return existing state

    case actionTypes.UPDATE_PROTECT_LIST_COUNT: {
      let prevStateMenuData = JSON.parse(
        JSON.stringify(Object.assign([], action.stateMenuData))
      );
      let protectCountsData = action.data;
      let newStateObj = updateProtectCount(
        prevStateMenuData,
        protectCountsData,
        state
      );

      return { ...state, menu: newStateObj };
    }

    case actionTypes.UPDATE_SOURCE_LIST_COUNT: {
      const sourceCount = action.data;
      return { ...state, sourceCounts: sourceCount };
    }

    case actionTypes.UPDATE_INFRASTRUCTURE_LIST_COUNT: {
      let prevState = action.stateMenuData;
      let countValues = action.data;

      let newStateObj = prevState.map(obj => {
        if (obj.name === state.primaryNavigation) {
          obj.subMenu.forEach(insideObj => {
            if ("amount" in insideObj) {
              insideObj.amount = countValues[insideObj.countKey];
            }
            return insideObj;
          });
        }
        return obj;
      });

      return { ...state, menu: newStateObj };
    }

    case actionTypes.UPDATE_ANALYZE_LIST_COUNT: {
      let prevState = action.stateMenuData;
      let countValues = action.data;
      let newStateObj = prevState.map(obj => {
        if (obj.name === state.primaryNavigation) {
          obj.subMenu.forEach(insideObj => {
            if (constant.AMOUNT in insideObj) {
              insideObj.amount = countValues[insideObj.countKey];
            }
            return insideObj;
          });
        }
        return obj;
      });
      return { ...state, menu: newStateObj };
    }

    /**
     * update the source group, user and role count in state
     */
    case actionTypes.UPDATE_CONFIGURE_COUNT: {
      let prevState = action.stateMenuData;
      let countValues = action.data;
      let newStateObj = prevState.map(obj => {
        if (obj.name === state.primaryNavigation) {
          obj.subMenu.forEach(insideObj => {
            if ("amount" in insideObj) {
              insideObj.amount = countValues[insideObj.countKey];
            }
            return insideObj;
          });
        }
        return obj;
      });
      return { ...state, menu: newStateObj };
    }

    case actionTypes.TOGGLE_ADVANCED_SEARCH:
      return {
        ...state,
        showAdvancedSearch: !state.showAdvancedSearch
      };

    case actionTypes.ADVANCED_SEARCH_CLICKED:
      return {
        ...state,
        advancedSearchParam: undefined,
        showCurrentSearch: true,
        showAdvancedSearch: false,
        currentSearchOptions: state.options
      };

    case actionTypes.ADVANCED_SEARCH_SET:
      return {
        ...state,
        advancedSearchParam: undefined,
        showCurrentSearch: true,
        showAdvancedSearch: false,
        currentSearchOptions: action.data
      };

    case actionTypes.SHOW_CUSTOMER_ACCOUNTS_LIST:
      return {
        ...state,
        advancedSearchParam: undefined
      };

    case actionTypes.UPDATE_ADVANCED_SEARCH: {
      return {
        ...state,
        options: updateObjectInArray(state.options, action),
        selectedSavedSearch: updateSelectedSavedSearch(
          state.selectedSavedSearch,
          state.options,
          action
        )
      };
    }
    case actionTypes.UPDATE_ADVANCED_SEARCH_OPTIONS:
      return {
        ...state,
        options: action.data,
        resetDateTimeRangePicker: true
      };

    case actionTypes.UPDATE_ADVANCED_SEARCH_OPTIONS_INNER_TAB:
      return {
        ...state,
        advOptionsInnerTabs: action.data
      };

    case actionTypes.REMOVE_SEARCH_TAGS:
      return {
        ...state,
        options: removeObjectInArray(state.options, action.data),
        searchOption: removeSearchOption(state.searchOption, action.data.data)
      };

    case actionTypes.REMOVE_SEARCH_TEXT_TAG:
      return {
        ...state,
        searchText: "",
        searchOption: {
          ...state.searchOption,
          selectedOption: ""
        }
      };

    case actionTypes.CLEAR_ALL_SEARCH_TAGS:
      return {
        ...state,
        showCurrentSearch: action.showCurrentSearch
          ? action.showCurrentSearch
          : false,
        searchText: "",
        searchOption: {
          ...state.searchOption,
          selectedOption: ""
        },
        options: clearAllSelectedOptions(state.options),
        currentSearchOptions: clearAllSelectedOptions(state.options),
        resetDateTimeRangePicker: true
      };

    case actionTypes.UPDATE_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.data,
        searchOption: {
          ...state.searchOption,
          selectedOption: ""
        }
      };

    case actionTypes.SEARCH_ENTITY:
      if (action.data.navigation === "instant_vms_rr") {
        return {
          ...state,
          searchText: "",
          searchOption: {
            name: "name",
            selectedOption: action.data.polling
              ? state.searchOption.selectedOption
              : action.data.searchText
          }
        };
      }
      return {
        ...state,
        searchText: "",
        searchOption: {
          ...state.searchOption,
          selectedOption: action.data.searchText
        }
      };
    case actionTypes.UPDATE_SELECTED_OPTION:
      return {
        ...state,
        searchText: "",
        searchOption: {
          ...state.searchOption,
          selectedOption: action.searchText
        }
      };

    case actionTypes.GET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.data
      };

    case actionTypes.CLEAR_STATE_SELECTED_ROWS:
    case actionTypes.CLEAR_IVM:
      return {
        ...state,
        selectedRows: 0,
        selectAll: false,
        selection: []
      };

    case actionTypes.CLEAR_STATE_ADVANCED_PARAMS:
      return {
        ...state,
        advancedSearchParam: {}
      };

    case actionTypes.GRID_DEFAULT_SELECTION:
      return {
        ...state,
        selectedRows: action.data.length,
        selectAll: false,
        selection: action.data
      };

    case actionTypes.UNSELECT_ALL_CHECKBOXES:
      return {
        ...state,
        selectAll: action.data.selectAll,
        selection: action.data.selection
      };

    case actionTypes.SELECT_CHECKBOX:
      return {
        ...state,
        selectAll: false,
        selection: action.data.selection
      };

    case actionTypes.UPDATE_PAGINATION_DROPDOWN:
      return {
        ...state,
        paginationDropdownValue: action.data
      };

    case actionTypes.UPDATE_CURRENT_SEARCH_FROM_SAVED:
      return {
        ...state,
        searchOption: {
          ...state.searchOption,
          selectedOption: action.payload.searchString
        },
        options: updateSelectedOptionsInArray(
          state.options,
          action.payload.selectedOptions
        )
      };

    case actionTypes.SHOW_MANAGE_SEARCH_MODAL:
      return {
        ...state,
        showManagedSearchModal: action.data
      };

    case actionTypes.SHOW_ADD_DESTINATION:
      return {
        ...state,
        showDestinationModal: action.data
      };

    case actionTypes.SHOW_CONFIRMATION:
      return {
        ...state,
        showConfirmationModal: action.data
      };

    case actionTypes.SHOW_RECOVERY_POINT:
      return {
        ...state,
        showRecoveryPointModal: action.data
      };

    case actionTypes.SHOW_UPDATE_PHOTO:
      return {
        ...state,
        showUpdatePhotoModal: action.data
      };

    case actionTypes.SHOW_SAVE_SEARCH_MODAL:
      return {
        ...state,
        showSaveSearchModal: action.data
      };

    case actionTypes.SHOW_SAVEDSEARCH_CLEARALL_LINK:
      return {
        ...state,
        showSaveSearchClearAllOnDefault: action.data
      };

    case actionTypes.UPDATE_CURRENT_SAVEDSEARCH_NAME:
      return {
        ...state,
        selectedSavedSearchName: action.savedSearchName,
        selectedSavedSearchId: action.savedSearchID,
        selectedSavedSearch: {
          ...state.selectedSavedSearch,
          filter_name: action.savedSearchName,
          filter_id: action.savedSearchID
        }
      };

    case actionTypes.UPDATE_MANAGE_SAVEDSEARCH_NAME:
      return {
        ...state,
        selectedSavedSearch: {
          ...state.selectedSavedSearch,
          filter_name: action.savedSearchName
        }
      };

    /**
     * update the source_name field for the selected saved search
     */
    case actionTypes.UPDATE_CURRENT_SAVEDSEARCH_TEXT:
      switch (state.secondaryNavigation) {
        case constant.SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS:
          return {
            ...state,
            selectedSavedSearch: {
              ...state.selectedSavedSearch,
              organization_name: action.savedSearchText
            }
          };
        default:
          return {
            ...state,
            selectedSavedSearch: {
              ...state.selectedSavedSearch,
              source_name: action.savedSearchText,
              destination_name: action.savedSearchText,
              job_name: action.savedSearchText,
              policy_name: action.savedSearchText,
              search_string: action.savedSearchText,
              recoveredresource_name: action.savedSearchText,
              report_name: action.savedSearchText,
              group_name: action.savedSearchText,
              organization_name: action.savedSearchText
            }
          };
      }

    /**
     * update the is_default field for the selected saved search
     */
    case actionTypes.UPDATE_CURRENT_SAVEDSEARCH_MARK_DEF:
      return {
        ...state,
        selectedSavedSearch: {
          ...state.selectedSavedSearch,
          is_default: action.is_default
        }
      };

    /**
     * clear the selected saved search
     */
    case actionTypes.FLUSH_CURRENT_SAVEDSEARCH:
      return {
        ...state,
        selectedSavedSearch: { ...{} },
        optionsForTags: { ...{} }
      };

    case actionTypes.REMOVE_FROM_SELECTED_SAVED_SEARCH:
      return {
        ...state,
        selectedSavedSearch: removeFromObjectByKey(
          state.selectedSavedSearch,
          action.key
        ),
        optionsForTags: removeFromObjectByKey(state.optionsForTags, action.key)
      };

    /**
     * update current saved search
     */
    case actionTypes.UPDATE_DEFAULT_SAVEDSEARCH:
      return {
        ...state,
        selectedSavedSearch:
          action.data && action.data.data ? action.data.data : {},
        selectedSavedSearchId:
          action.data && action.data.data && action.data.data.filter_id
            ? action.data.data.filter_id
            : ""
      };

    /**
     * update current saved search
     */
    case actionTypes.UPDATE_CURRENT_SAVEDSEARCH:
      return {
        ...state,
        options: updateOptions(
          state.options,
          action,
          state.organization_type,
          state.isImpersonationView
        ),
        selectedSavedSearch:
          action.data && action.data.data ? action.data.data : {}
      };

    case actionTypes.UPDATE_IMG_URL:
      return {
        ...state,
        imageURL: action.data
      };

    case actionTypes.CROPPED_IMG_URL:
      return {
        ...state,
        croppedImageURL: action.data
      };

    case actionTypes.SHOW_INFORMATION:
      return {
        ...state,
        showInformationModal: action.data
      };

    case actionTypes.SET_COLUMN_WIDTH: {
      const updatedColData = action.updatedColumnData.newResized;
      return {
        ...state,
        updatedColData: updatedColData
      };
    }

    case actionTypes.UPDATE_COLUMN_DATA: {
      const columnReducerData = action.columnData;
      const columnStateData = action.stateColumnData;
      const columnReducerDataFiltered =
        columnReducerData && columnReducerData.columnData
          ? getReorderColumns(
              columnReducerData.columnData,
              columnReducerData.name
            )
          : [];

      return {
        ...state,
        columnData: columnReducerDataFiltered,
        userColumnId: action.columnData && action.columnData.userColumnId,
        stateColumnData: columnStateData
      };
    }

    case actionTypes.UPDATE_COLUMN_DATA_FROM_STATE: {
      let columnStateData = JSON.parse(
        JSON.stringify(Object.assign([], action.stateData))
      );
      if (!Array.isArray(columnStateData)) {
        let columnDataShowHideFiltered = columnStateData.map(obj => {
          if (obj.name) {
            obj.columnData.map(insideobj => {
              if (insideobj.identifier === action.data.identifier) {
                insideobj.show = !insideobj.show;
              }
              return insideobj;
            });
          }
          obj.columnData = getReorderColumns(obj.columnData);
          return obj;
        });
        return {
          ...state,
          columnData: columnDataShowHideFiltered
        };
      } else {
        columnStateData = columnStateData.map(insideobj => {
          if (insideobj.identifier === action.data.identifier) {
            insideobj.show = !insideobj.show;
          }
          return insideobj;
        });
        let columnDataShowHideFiltered = getReorderColumns(columnStateData);
        return {
          ...state,
          columnData: columnDataShowHideFiltered
        };
      }
    }
    case actionTypes.UPDATE_REORDER_COLUMN_DATA: {
      let emptyObj = [];
      let stateData = emptyObj.concat(action.data);
      let tempObj = stateData.splice(action.oldItem.y + 2, 1); // first element removed
      stateData.splice(action.newItem.y + 2, 0, tempObj[0]);
      let columnDataConfig = stateData;
      return { ...state, columnData: columnDataConfig };
    }
    case actionTypes.UPDATE_BREADCRUMB_LIST: {
      let updatedBreadcrumbList = [];
      return { ...state, breadcrumbItems: updatedBreadcrumbList };
    }

    case actionTypes.HANDLE_GRID_CONTEXTUAL_ACTIONS: {
      let showConfirmation = action.showConfirmation;
      if (showConfirmation === undefined && action.contextualAction === null) {
        showConfirmation = false;
      }
      return {
        ...state,
        showConfirmationModal:
          showConfirmation === false ? showConfirmation : true,
        contextualAction: {
          type: action.contextualAction,
          additionalProperties: action.data,
          showConfirmation: action.showConfirmation
        }
      };
    }
    case actionTypes.SHOW_BULK_ACTION: {
      return {
        ...state,
        contextualBulkAction: {
          type: action.action,
          data: action.data,
          resourceType: action.resourceType,
          showConfirmation: true
        }
      };
    }
    case actionTypes.CLOSE_BULK_ACTION: {
      return {
        ...state,
        contextualBulkAction: undefined
      };
    }

    case actionTypes.API_RESPONSE: {
      return {
        ...state,
        apiResponse: action.data,
        successSaveSearch: action.data.successSaveSearch,
        showAlertMessage: true
      };
    }

    case actionTypes.DISMISS_ALERT: {
      return {
        ...state,
        apiResponse: undefined,
        showAlertMessage: action.data
      };
    }

    case actionTypes.CLEAR_STATE:
      return {
        ...state,
        logData: {},
        jobsData: {},
        columnData: [],
        sortedColumns: [],
        userSearchData: [],
        options: [],
        optionsForTags: {},
        stateColumnData: [],
        systemSearchData: [],
        selectedSavedSearch: {},
        selectedFilterOptions: [],
        selectedSavedSearchId: "",
        selectedSavedSearchName: "",
        gridCurrentPage: 1,
        gridPageSize: 25,
        resetDateTimeRangePicker: false,
        currentSearchOptions: null,
        showAdvancedSearch: false
      };

    case actionTypes.CLEAR_STATE_WITHOUT_COLUMN_DATA:
      return {
        ...state,
        logData: {},
        jobsData: {},
        sortedColumns: [],
        userSearchData: [],
        options: [],
        optionsForTags: {},
        stateColumnData: [],
        systemSearchData: [],
        selectedSavedSearch: {},
        selectedFilterOptions: [],
        selectedSavedSearchId: "",
        selectedSavedSearchName: "",
        gridCurrentPage: 1,
        gridPageSize: 25,
        resetDateTimeRangePicker: false,
        currentSearchOptions: null,
        showAdvancedSearch: false
      };

    case actionTypes.CLEAR_STATE_RESOURCE_ID:
      return {
        ...state,
        resourceId: ""
      };

    case actionTypes.SET_RESET_FLAG:
      return {
        ...state,
        resetDateTimeRangePicker: false
      };

    case actionTypes.UPDATE_DEFAULT_SEARCH:
      return {
        ...state,
        userSearchData: action.data.userSearchList,
        systemSearchData:
          state.secondaryNavigation === "reports"
            ? []
            : action.data.systemSearchList
      };

    case actionTypes.UPDATE_SAVED_SEARCH:
      return {
        ...state,
        userSearchData: action.data
      };
    case actionTypes.UPDATE_MENU_MSP_MONITOR:
      return {
        ...state,
        menu: state.menu.filter(menuItem => menuItem.key !== "Configure")
      };
    case actionTypes.UPDATE_MENU_DIRECT_MONITOR:
      return {
        ...state,
        menu: state.menu.filter(
          menuItem => menuItem.key !== "Protect" && menuItem.key !== "Configure"
        )
      };
    case actionTypes.LOGIN_REQUESTING:
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.SET_JWT_TOKEN:
    case actionTypes.LOG_OUT: {
      let organization_type =
        action && action.payload && action.payload.parsedToken
          ? action.payload.parsedToken.organization_type
          : "";
      return {
        ...state,
        organization_type: organization_type,
        apiResponse: undefined
      };
    }
    case actionTypes.DELETE_UPDATE_MANAGE_SAVE_SEARCH: {
      return {
        ...state,
        userSearchData: removeItem(state.userSearchData, action.data),
        selectedSavedSearchName: "",
        selectedSavedSearchId: ""
      };
    }

    /**
     * update the selected saved search after an edit/PUT request is successful
     */
    case actionTypes.UPDATE_MANAGE_SAVE_SEARCH: {
      return {
        ...state,
        selectedSavedSearch: {
          ...action.data
        },
        userSearchData: updateFilterInList(state.userSearchData, action.data)
      };
    }

    case actionTypes.INSERT_MANAGE_SAVE_SEARCH: {
      return {
        ...state,
        userSearchData: addObjectInArray(state.userSearchData, action.data)
      };
    }
    case actionTypes.SHOW_BROWSE_RECOVERY_POINT:
      return {
        ...state,
        showBrowseRecoveryPointModal: action.data
      };
    case actionTypes.SWITCH_RECOVERY_TABS:
      return {
        ...state,
        tabIndex: action.data
      };
    case actionTypes.DOWNLOAD_RECOVERY_POINT:
      return {
        ...state,
        showDownloadRecoveryPointModal: action.data
      };

    case actionTypes.UPDATE_JOB_RESOURCE_IN_GRID:
      return {
        ...state,
        jobsData: {
          data: updateObjectInArray(state.jobsData.data, action.data)
        }
      };

    case actionTypes.SET_BROWSE_URL:
      return {
        ...state,
        browseURL: action.data
      };

    case actionTypes.SET_RESOURCE_NAME:
      return {
        ...state,
        resourceName: action.resourceName,
        resourceId: action.resourceId
      };

    case actionTypes.IMPERSONATE_CUSTOMER:
      return {
        ...state,
        isImpersonationView: true,
        customerOrgId: action.customerData.customerOrgId,
        customerOrgName: action.customerData.customerOrgName,
        customerOrgType: action.customerData.customerOrgType,
        isCustomerSubMSP: action.customerData.subMSP,
        imccOrganization: action.customerData.imccOrganization
      };

    case actionTypes.IMPERSONATE_SOURCE:
      return {
        ...state,
        isImpersonationView: true,
        sourceName: action.sourceData.sourceName,
        customerOrgId: action.sourceData.customerOrgId,
        customerOrgName: action.sourceData.customerOrgName,
        customerOrgType: action.sourceData.customerOrgType
      };

    case actionTypes.IMPERSONATE_POLICY:
      return {
        ...state,
        isImpersonationView: true,
        policyName: action.policyData.policyName,
        customerOrgId: action.policyData.customerOrgId,
        customerOrgName: action.policyData.customerOrgName,
        customerOrgType: action.policyData.customerOrgType
      };

    case actionTypes.IMPERSONATE_JOB:
      return {
        ...state,
        isImpersonationView: true,
        jobId: action.analyzeData.jobId,
        jobName: action.analyzeData.jobName,
        customerOrgId: action.analyzeData.customerOrgId,
        customerOrgName: action.analyzeData.customerOrgName,
        customerOrgType: action.analyzeData.customerOrgType
      };

    case actionTypes.IMPERSONATE_RECOVERY_POINTS:
      return {
        ...state,
        isImpersonationView: true,
        source_id: action.recoveryPointsData.source_id,
        customerOrgId: action.recoveryPointsData.customerOrgId,
        customerOrgName: action.recoveryPointsData.customerOrgName,
        customerOrgType: action.recoveryPointsData.customerOrgType
      };

    case actionTypes.RESET_IMPERSONATE_CUSTOMER:
      return {
        ...state,
        isImpersonationView: false,
        customerOrgId: null,
        customerOrgName: null
      };

    case actionTypes.CLEAR_CONTEXTUAL_ACTION: {
      return {
        ...state,
        showConfirmationModal: false,
        contextualAction: {
          type: undefined,
          additionalProperties: {},
          showConfirmation: false
        }
      };
    }

    case actionTypes.SET_SORTED_DATA: {
      return {
        ...state,
        sortedColumns: action.data
      };
    }

    case actionTypes.CLEAR_SORTED_DATA: {
      return {
        ...state,
        sortedColumns: []
      };
    }

    case actionTypes.FROM_TO_FILTER: {
      return {
        ...state,
        filterFrom: action.data.filterFrom,
        filterTo: action.data.filterTo
      };
    }

    case actionTypes.SET_CHART_FILTER: {
      return {
        ...state,
        chartFilter: action.data
      };
    }

    /**
     * to show/hide date filter
     */
    case actionTypes.SHOW_DATE_FILTER_HANDLER: {
      return {
        ...state,
        showDateFilter: action.data
      };
    }

    case actionTypes.TOGGLE_ALERTS_PANEL:
      return {
        ...state,
        toggleAlertsList: !state.toggleAlertsList
      };

    case actionTypes.HIDE_ALERTS_PANEL:
      return {
        ...state,
        toggleAlertsList: false
      };

    /**
     * to show/hide date filter for CH chart in customer accounts -> metrics tab
     */
    case actionTypes.SHOW_DATE_FILTER_CH_HANDLER: {
      return {
        ...state,
        showCHDateFilter: action.data
      };
    }

    case actionTypes.SHOW_DATE_FILTER_CU_HANDLER: {
      return {
        ...state,
        showCUDateFilter: action.data
      };
    }
    case actionTypes.UPDATE_SOURCE_GROUPS_FOR_ADVANCE_SEARCH: {
      return {
        ...state,
        advanceSearchSourceGroupsData: action.data
      };
    }

    case actionTypes.UPDATE_SITES_FOR_ADVANCE_SEARCH: {
      return {
        ...state,
        advanceSearchSitesData: action.data
      };
    }

    case actionTypes.UPDATE_DESTINATIONS_FOR_ADVANCE_SEARCH: {
      return {
        ...state,
        advanceSearchDestinationsData: action.data
      };
    }

    case actionTypes.UPDATE_POLICIES_FOR_ADVANCE_SEARCH: {
      return {
        ...state,
        advanceSearchPoliciesData: action.data
      };
    }

    case actionTypes.UPDATE_GENERATED_FROM_ADVANCE_SEARCH: {
      return {
        ...state,
        advanceSearchGeneratedFromData: action.data
      };
    }

    case actionTypes.UPDATE_ORGANIZATIONS_FOR_ADVANCE_SEARCH: {
      return {
        ...state,
        advanceSearchOrganizationData: action.data
      };
    }

    case actionTypes.ADD_ADVANCED_SEARCH_PARAM: {
      return { ...state, advancedSearchParam: action.data };
    }

    case actionTypes.FROM_NAVIGATION_API: {
      return { ...state, fromNavigationApi: action.data };
    }

    /*
     * for keep advance search data policy keep up to date
     */
    case actionTypes.DELETE_POLICY_FROM_GRID: {
      return {
        ...state,
        advanceSearchPoliciesData: [],
        advanceSearchSourceGroupsData: []
      };
    }
    /*
     * for keep advance search data policy keep up to date
     */
    case actionTypes.SHOW_POLICIES_LIST: {
      return {
        ...state,
        advanceSearchPoliciesData: [],
        advanceSearchSourceGroupsData: []
      };
    }

    case actionTypes.SHOW_POLICIES_LOCAL_LIST: {
      return {
        ...state,
        advanceSearchPoliciesData: [],
        advanceSearchSourceGroupsData: []
      };
    }

    /**
     * for keep advance search data source group keep up to date
     */
    case actionTypes.DELETE_UPDATE_SOURCE_GROUP: {
      return {
        ...state,
        advanceSearchSourceGroupsData: [],
        advanceSearchPoliciesData: []
      };
    }
    /**
     * for keep advance search data source group keep up to date
     */
    case actionTypes.ADD_SOURCE_GROUP_IN_LIST: {
      return {
        ...state,
        advanceSearchSourceGroupsData: [],
        advanceSearchPoliciesData: []
      };
    }

    case actionTypes.START_FAILBACK_LOADING:
      return {
        ...state,
        startFailback: {
          isLoading: action.data,
          error: null
        }
      };

    case actionTypes.START_FAILBACK_FAILURE:
      return {
        ...state,
        startFailback: {
          isLoading: false,
          error: action.data
        }
      };

    case actionTypes.START_FAILBACK_SUCCESS:
      return {
        ...state,
        startFailback: {
          isLoading: false,
          error: null
        }
      };

    case actionTypes.BROWSE_REMOTE_CONSOLE_LOADING:
      return {
        ...state,
        browseRemoteConsole: {
          isLoading: true,
          data: null
        }
      };

    case actionTypes.CLEAR_BROWSE_REMOTE_CONSOLE:
      return {
        ...state,
        browseRemoteConsole: {
          isLoading: false,
          data: null,
          errors: null
        }
      };

    case actionTypes.BROWSE_REMOTE_CONSOLE_FAILURE:
      return {
        ...state,
        browseRemoteConsole: {
          isLoading: false,
          data: { ...action.data }
        }
      };

    case actionTypes.BROWSE_REMOTE_CONSOLE_SUCCESS:
      return {
        ...state,
        browseRemoteConsole: {
          isLoading: false,
          data: { ...action.data }
        }
      };

    case actionTypes.BROWSE_RESOURCE_POOL_SUCCESS: {
      return { ...state, browseResourcePool: action.data };
    }

    case actionTypes.FETCH_USER_SUPPORT_DATA: {
      return { ...state, userSupportData: action.data };
    }

    case actionTypes.UPDATED_DESTINATION_LIST: {
      return {
        ...state,
        advanceSearchDestinationsData: []
      };
    }

    case actionTypes.DELETED_DESTINATION_LIST: {
      return {
        ...state,
        advanceSearchDestinationsData: []
      };
    }

    case actionTypes.UPDATE_EXPORT_LOG: {
      return {
        ...state,
        exportLogData: action.data
      };
    }

    case actionTypes.REMOVE_SINGLE_ADVANCESEARCH_OPTION: {
      return {
        ...state,
        options: removeSingleAdvanceSearchOptionFromArray(
          state.options,
          action.data.item
        )
      };
    }

    case actionTypes.UPDATE_SELECTED_FILTER_OPTIONS: {
      return {
        ...state,
        selectedFilterOptions: action.data
      };
    }

    case actionTypes.UPDATE_GRID_PAGE_NUMBER:
      return {
        ...state,
        gridCurrentPage: action.data
      };

    case actionTypes.ADVANCED_SEARCH_TRIGGERED:
      return {
        ...state,
        gridCurrentPage:
          action.payload && action.payload.gridCurrentPage
            ? action.payload.gridCurrentPage
            : state.gridCurrentPage,
        gridPageSize:
          action.payload && action.payload.gridPageSize
            ? action.payload.gridPageSize
            : state.gridPageSize
      };
    case actionTypes.UPDATE_GRID_PAGE_SIZE:
      return {
        ...state,
        gridPageSize: action.data
      };

    case actionTypes.UPDATE_GRID_PAGINATION: //true or false
      return {
        ...state,
        gridPagination: action.data
      };

    case actionTypes.SHOW_CURRENT_SEARCH:
      return {
        ...state,
        showCurrentSearch: action.data
      };

    case actionTypes.CLEAR_ALL_MENU_COUNTS: {
      let actionType = action.data.type;
      let stateMenu = Object.assign([], state.menu);
      return {
        ...state,
        menu: updateCountsToZeroMenu(stateMenu, actionType)
      };
    }

    case actionTypes.UPDATE_MENU_COUNTS: {
      let actionType = action.data.type;
      let actionUpdateMenuKeys = action.data.updateMenuKeys;
      let stateMenu = Object.assign([], state.menu);
      let actionCountToBeRemove = action.data.count;
      return {
        ...state,
        menu: updateCountsOfMenusOnAddRemoveActions(
          stateMenu,
          actionType,
          actionUpdateMenuKeys,
          actionCountToBeRemove
        )
      };
    }

    case actionTypes.UPDATE_TAGS_CURRENT_SEARCH: {
      return {
        ...state,
        showCurrentSearch: true,
        optionsForTags: getOptionsForTags(state.options)
      };
    }

    case actionTypes.UPDATE_TAGS_CURRENT_SEARCH_VALUE: {
      return {
        ...state,
        showCurrentSearch: true,
        optionsForTags: {
          ...state.optionsForTags,
          ...action.data
        }
      };
    }

    case actionTypes.UPDATE_JOBLOG_JOBDATA:
      return {
        ...state,
        jobLogJobData: action.data
      };

    case actionTypes.STOP_LOADER:
      return {
        ...state,
        gridLoader: false
      };

    case actionTypes.START_LOADER:
      return {
        ...state,
        gridLoader: true
      };

    case actionTypes.START_GRID_LOADER_BY_ID:
      return {
        ...state,
        gridLoaderById: { ...state.gridLoaderById, [action.data]: true }
      };

    case actionTypes.STOP_GRID_LOADER_BY_ID: {
      return {
        ...state,
        gridLoaderById: { ...state.gridLoaderById, [action.data]: false }
      };
    }

    case actionTypes.UPDATE_SECONDARY_NAVIGATION: {
      return {
        ...state,
        secondaryNavigation: action.data
      };
    }

    case actionTypes.ADVANCED_SEARCH_LOADING: {
      return {
        ...state,
        advancedSearchLoaded: false
      };
    }

    case actionTypes.ADVANCED_SEARCH_LOADED: {
      return {
        ...state,
        advancedSearchLoaded: true
      };
    }

    case actionTypes.DEFAULT_SEARCH_CONFIGURED: {
      return {
        ...state,
        defaultSearchConfigured: action.data
      };
    }
    case actionTypes.SET_HYPER_LINK: {
      return {
        ...state,
        hyperLink: action.data
      };
    }
    case actionTypes.SET_ADVANCE_SEARCH_DATA: {
      return {
        ...state,
        advanceSearchData: action.data,
        fromMonitorPage: true
      };
    }
    case actionTypes.RESET_ADVANCE_SEARCH_DATA: {
      return {
        ...state,
        advanceSearchData: {},
        fromMonitorPage: false
      };
    }

    case actionTypes.SET_FROM_MONITOR_PAGE: {
      return {
        ...state,
        fromMonitorPage: action.data
      };
    }

    case actionTypes.SET_FILTER_FROM_MONITOR_WIDGET: {
      return {
        ...state,
        filterFromMonitorWidget: true,
        filterFromMonitorWidgetData: action.data
      };
    }
    case actionTypes.RESET_FILTER_FROM_MONITOR_WIDGET: {
      return {
        ...state,
        filterFromMonitorWidget: false,
        filterFromMonitorWidgetData: {}
      };
    }
    case actionTypes.DO_NOT_INITIALIZE_GRAPH: {
      return {
        ...state,
        doNotInitializeGraph: action.data
      };
    }

    case actionTypes.INGRAM_ORG_FAILURE: {
      return {
        ...state,
        ingramOrgFailure: action.data
      };
    }

    case actionTypes.SHOW_INGRAM_ORG_MODAL: {
      return {
        ...state,
        showOrgModal: action.data
      };
    }

    case actionTypes.UPDATE_UI_VERSION: {
      return {
        ...state,
        uiVersion: action.payload.uiVersion
      };
    }

    case actionTypes.UPDATE_API_VERSION: {
      return {
        ...state,
        apiVersion: action.payload.apiVersion
      };
    }

    case actionTypes.PAGE_RELOADED: {
      return {
        ...state,
        pageReloaded: action.payload
      };
    }

    case actionTypes.RESET_EXCLUSION_CHECKBOX: {
      return {
        ...state,
        resetExclusionCheckbox: action.data
      };
    }

    case actionTypes.RESET_POLICY_PASSWORD: {
      return {
        ...state,
        resetPolicyPassword: action.data
      };
    }

    case actionTypes.RESET_SELECTED_NETWORK_FOR_BACKUP_CHECKBOX: {
      return {
        ...state,
        resetSelectNetworkForbackupCheckboxVal: action.data
      };
    }

    default:
      return state;
  }
};

/**
 * this function will update type of menu to submenu on add remove actions
 */
function updateCountsOfMenusOnAddRemoveActions(
  menu,
  typeMenu,
  actionTypeUpdateMenuKeys,
  actionCountToBeRemove
) {
  if (menu) {
    return menu.map(option => {
      if (typeMenu === option.key) {
        option.subMenu = updateSubmenusCountOnAddRemoveActions(
          option.subMenu,
          actionTypeUpdateMenuKeys,
          actionCountToBeRemove
        );
      }
      return option;
    });
  }
}

/**
 * this function will update submenus on add subtract
 */

function updateSubmenusCountOnAddRemoveActions(
  subMenus,
  actionTypeUpdateSubmenu,
  actionCountToBeRemove
) {
  //eslint-disable-next-line array-callback-return
  return subMenus.map(subMenusOptions => {
    // if (subMenus.countKey === actionTypeUpdateSubmenu[subMenus.countKey]) {
    //eslint-disable-next-line array-callback-return
    actionTypeUpdateSubmenu.types.map(actiontypes => {
      if (actiontypes === subMenusOptions.key) {
        if (
          subMenusOptions.amount !== undefined &&
          subMenusOptions.amount !== null
        ) {
          if (actionTypeUpdateSubmenu.action !== "remove") {
            subMenusOptions.amount =
              subMenusOptions.amount + actionCountToBeRemove;
          } else {
            subMenusOptions.amount =
              subMenusOptions.amount - actionCountToBeRemove < 0
                ? 0
                : subMenusOptions.amount - actionCountToBeRemove;
          }
        }
      }
    });

    // }
    return subMenusOptions;
  });
}

/**
 * this function will update type of menu to submenu
 */
function updateCountsToZeroMenu(menu, typeMenu) {
  if (menu) {
    return menu.map(option => {
      if (typeMenu === option.key) {
        option.subMenu = updateCountsToZeroInSubmenu(option.subMenu);
      }
      return option;
    });
  }
}

/**
 * this function will update submenus to 0
 */
function updateCountsToZeroInSubmenu(subMenus) {
  return subMenus.map(subMenusOptions => {
    if (subMenusOptions.amount) {
      subMenusOptions.amount = 0;
    }
    return subMenusOptions;
  });
}

/**
 * this function will remove single option from array
 */
function removeSingleAdvanceSearchOptionFromArray(array, item) {
  let newOptions = [];
  let oldOptions = Object.assign([], array);
  newOptions = oldOptions.filter(function(element) {
    return element.key !== item;
  });

  return newOptions;
}
/**
 * this function will update the options for the selected Saved Search
 */
function updateOptions(
  options,
  action,
  organization_type,
  isImpersonationView
) {
  if (options) {
    return options.map(option => {
      if (action.data.data && action.data.data[option.key] !== undefined) {
        return {
          ...option,
          selectedOptions: updateSelectedOptions(
            option,
            action,
            organization_type,
            isImpersonationView
          ),
          orignalOptions: updateSelectedOptions(
            option,
            action,
            organization_type,
            isImpersonationView
          )
        };
      }
      return option;
    });
  }
}

//here there is no separate case for sub_organization_id because we want that
//option for sub_organization_id should be Organization only
function getOptionsForTags(data) {
  let objToReturn = {};

  data &&
    data.forEach(item => {
      switch (item.type) {
        case "date-picker":
          if (
            item.selectedOptions &&
            Object.keys(item.selectedOptions).length
          ) {
            objToReturn[item.key] = item.selectedOptions.start;
          }
          break;

        case "date-range-picker":
          if (
            item.selectedOptions &&
            Object.keys(item.selectedOptions).length
          ) {
            objToReturn[item.key] = item.selectedOptions;
          }
          break;
        default:
          if (item.selectedOptions && item.selectedOptions.length) {
            objToReturn[item.key] = [];
            objToReturn[item.key] = item.selectedOptions.map(
              option => option.value && option.label
            );
          }
          break;
      }
    });

  if (objToReturn.date_range && objToReturn.date_range[0] === "custom") {
    delete objToReturn.date_range;
  }

  return objToReturn;
}

/**
 * this function will update the selected options for the selected Saved Search
 */
function updateSelectedOptions(
  option,
  action,
  organization_type,
  isImpersonationView
) {
  let selectedOptions = cloneDeep(option.selectedOptions);
  let type = option.type;

  switch (type) {
    case "date-picker":
    case "date-range-picker":
      if (
        action.data &&
        action.data.data &&
        action.data.data.hasOwnProperty(option.key)
      ) {
        return action.data.data[option.key];
      }
      return {};

    case "single-select": {
      let arrayToReturn = [];
      if (
        action.data &&
        action.data.data &&
        action.data.data.hasOwnProperty(option.key)
      ) {
        let selectedOption = option.options.filter(
          item => item.value === action.data.data[option.key].toString()
        )[0];
        if (selectedOption && selectedOption.label === "direct_admin") {
          if (organization_type !== "msp" || isImpersonationView) {
            selectedOption.label = "admin";
          }
        }
        arrayToReturn.push(selectedOption);
      }
      return arrayToReturn;
    }
    case constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.TYPE:
      if (
        action.data &&
        action.data.data &&
        action.data.data.hasOwnProperty(option.type)
      ) {
        let data;
        if (!Array.isArray(action.data.data[option.type])) {
          data = [action.data.data[option.type]];
        } else {
          data = cloneDeep(action.data.data[option.type]);
        }
        return data.map(selOpt => {
          for (let index = 0; index < option.options.length; index++) {
            if (
              option.options[index].id &&
              selOpt === option.options[index].id
            ) {
              selOpt = option.options[index].value;
            }
          }
          return {
            ...selectedOptions,
            label: selOpt,
            value: selOpt
          };
        });
      }
      return [];
    default:
      if (
        action.data &&
        action.data.data &&
        action.data.data.hasOwnProperty(option.key)
      ) {
        let data;
        if (!Array.isArray(action.data.data[option.key])) {
          data = [action.data.data[option.key]];
        } else {
          data = cloneDeep(action.data.data[option.key]);
        }
        return data.map(selOpt => {
          for (let index = 0; index < option.options.length; index++) {
            if (
              option.options[index].id &&
              selOpt === option.options[index].id
            ) {
              selOpt = option.options[index].value;
            }
          }
          let labelSelOpt = selOpt;
          let valueSelOpt = selOpt;
          if (selOpt === "direct_admin") {
            if (organization_type !== "msp" || isImpersonationView) {
              labelSelOpt = "admin";
            }
          }

          return {
            ...selectedOptions,
            label: labelSelOpt,
            value: valueSelOpt
          };
        });
      }
      return [];
  }
}

function updateSelectedOptionsInArray(stateArray, actionArray) {
  if (stateArray) {
    //eslint-disable-next-line array-callback-return
    return stateArray.map(stateItem => {
      //eslint-disable-next-line array-callback-return
      return {
        ...stateItem,
        selectedOptions: updateSelectedOptionInArray(actionArray, stateItem)
      };
    });
  }
}

function updateSelectedOptionInArray(array, action) {
  let tempSelectedOption = [];
  if (array && array.length) {
    //eslint-disable-next-line array-callback-return
    tempSelectedOption = array.map(item => {
      if (item.key === action.key) {
        return item.selectedOptions;
      }
    });
  }
  tempSelectedOption = tempSelectedOption.filter(function(element) {
    return element !== undefined;
  });
  if (tempSelectedOption[0] !== undefined) {
    return tempSelectedOption[0];
  }
  return tempSelectedOption;
}

function updateFilterInList(array, obj) {
  return unionBy([obj], array, "filter_id");
}

function updateObjectInArray(array, action) {
  return array.map((item, index) => {
    if (index !== action.data.index) {
      // This isn't the item we care about - keep it as-is
      return item;
    }
    switch (item.type) {
      case "date-picker":
        return {
          ...item,
          selectedOptions: action.data.item.value
        };

      case "date-range-picker":
        return {
          ...item,
          selectedOptions: action.data.item
        };

      case "single-select":
        return {
          ...item,
          selectedOptions: action.data.item
        };

      default:
        action.data &&
          action.data.item.forEach(actionEle => {
            item.options.forEach(optionEle => {
              if (optionEle.value === actionEle.value) {
                if (optionEle.id) {
                  actionEle.id = optionEle.id;
                }
              }
            });
            return actionEle;
          });
        // Otherwise, this is the one we want to be updated - return an updated value
        return {
          ...item,
          selectedOptions: action.data.item
        };
    }
  });
}

/**
 * to update the selected saved search data based on changes made in Manage Saved Search by user
 */
function updateSelectedSavedSearch(selectedSavedSearch, options, action) {
  const key = options[action.data.index].key;
  const type = options[action.data.index].type;
  let obj = { ...selectedSavedSearch };
  switch (type) {
    case "date-picker":
      obj[key] = action.data.item.value;
      break;

    case "date-range-picker":
      obj[key] = action.data.item.type ? action.data.item : {};
      break;
    case constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.TYPE:
      {
        let arr = [];
        action.data.item.forEach(ele => {
          arr.push(ele.id ? ele.id : ele.value);
        });
        obj[type] = arr;
      }
      break;
    default: {
      let arr = [];
      action.data.item.forEach(ele => {
        arr.push(ele.id ? ele.id : ele.value);
      });
      obj[key] = arr;
      break;
    }
  }
  return obj;
}

function removeObjectInArray(array, action) {
  if (!array) {
    return [];
  }
  return array.map(item => {
    if (item.key !== action.data.index) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    switch (item.type) {
      case "date-picker":
      case "date-range-picker":
        return {
          ...item,
          selectedOptions: {}
        };

      default:
        return {
          ...item,
          selectedOptions: []
        };
    }
    // Otherwise, this is the one we want - return an updated value
  });
}

function removeItem(array, id) {
  return array.slice().filter(item => item.filter_id !== id);
}

function removeSearchOption(object, itemToRemove) {
  if (itemToRemove.index === "name") {
    return {
      name: "name",
      selectedOption: ""
    };
  } else {
    return object;
  }
}

function clearAllSelectedOptions(array) {
  if (array) {
    return array.map(item => {
      // Otherwise, this is the one we want - return an updated value
      switch (item.type) {
        case "date-picker":
        case "date-range-picker":
          return {
            ...item,
            selectedOptions: {},
            orignalOptions: {}
          };

        default:
          return {
            ...item,
            selectedOptions: [],
            orignalOptions: []
          };
      }
    });
  }
}

/**
 * Handle processing of reorder columns for showing in settings
 *
 * @param {array} columns
 */
function getReorderColumns(columns, name) {
  let showData = [];
  let hideData = [];
  let columnDataSorted = [];
  let available_actions = [];
  if (name === "customer-accounts") {
    columns.unshift({
      identifier: "customer_view",
      show: true
    });
  }
  columns.forEach(obj => {
    if (
      obj.identifier === constant.SPOG_GRID_PROPERTIES.ACTION_KEY.ACTION ||
      obj.identifier ===
        constant.SPOG_GRID_PROPERTIES.ACTION_KEY.AVAILABLE_ACTIONS
    ) {
      available_actions.push(obj);
    } else {
      if (obj.show === true) {
        showData.push(obj);
      } else {
        hideData.push(obj);
      }
    }
  });
  columnDataSorted = [...available_actions, ...showData, ...hideData];
  return columnDataSorted;
}

/**
 * Handle insert/add array
 *
 * @param {array} stateArray
 * @param {array} actionArray current inserted item
 */
function addObjectInArray(array, action) {
  return [...array.slice(0, action.id), action, ...array.slice(action.id)];
}

function removeFromObjectByKey(object, key) {
  let objToReturn = cloneDeep(object);
  delete objToReturn[key];
  return objToReturn;
}

function updateProtectCount(prevStateMenuData, protectCountsData, state) {
  return prevStateMenuData.map(obj => {
    let sumOfAllSources = 0;
    protectCountsData.sourcesCount.data.forEach(obj => {
      if (
        obj.source_type !==
          constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBYS &&
        obj.source_type !== constant.SECONDARY_NAVIGATION_TYPE.INSTANT_VMS
      ) {
        sumOfAllSources = sumOfAllSources + obj.amount;
      }
    });
    let sumOfAllRecoveredResources = 0;
    protectCountsData.recoveredResourcesCount.data.forEach(obj => {
      if (
        obj.recoveredresource_type !==
        constant.SECONDARY_NAVIGATION_TYPE.MOUNTED_RECOVERY_POINTS
      ) {
        sumOfAllRecoveredResources = sumOfAllRecoveredResources + obj.amount;
      }
    });
    // let sumOfAllDestinations = 0;
    if (obj.name === state.primaryNavigation) {
      obj.subMenu.forEach(insideObj => {
        if ("countKey" in insideObj) {
          if (insideObj.isFilterType) {
            protectCountsData.sourcesCount.data.forEach(sourceobj => {
              if (insideObj.countKey === sourceobj.source_type) {
                insideObj.amount = sourceobj.amount;
                //sumOfAllSources = sumOfAllSources + sourceobj.amount;
              }
            });

            // count for Physical Machines
            if (
              insideObj.key ===
              constant.SECONDARY_NAVIGATION_KEYS.PHYSICAL_MACHINES
            ) {
              let machineCount = 0;
              protectCountsData.sourcesCount.data.forEach(sourceobj => {
                if (insideObj.countKey === sourceobj.source_type) {
                  machineCount = machineCount + sourceobj.amount;
                }
                if (
                  sourceobj.source_type === constant.SOURCE_TYPE_UDP_WINDOWS ||
                  sourceobj.source_type === constant.SOURCE_TYPE_UDP_LINUX ||
                  sourceobj.source_type ===
                    constant.SOURCE_TYPE_LINUX_BACKUP_SERVER
                ) {
                  machineCount = machineCount + sourceobj.amount;
                }
              });
              insideObj.amount = machineCount;
            }

            // count for Unc Nfs Paths
            if (
              insideObj.key === constant.SECONDARY_NAVIGATION_KEYS.UNC_NFS_PATHS
            ) {
              protectCountsData.sourcesCount.data.forEach(sourceobj => {
                if (
                  insideObj.countKey === sourceobj.source_type &&
                  insideObj.parentKey ===
                    constant.SECONDARY_NAVIGATION_KEYS.SOURCES
                ) {
                  insideObj.amount = sourceobj.amount;
                }
              });
            }

            // count for all Disaster Recovery
            protectCountsData.recoveredResourcesCount.data.forEach(
              recoveredresourceobj => {
                if (
                  insideObj.countKey ===
                  recoveredresourceobj.recoveredresource_type
                ) {
                  insideObj.amount = recoveredresourceobj.amount;
                }

                if (
                  insideObj.countKey ===
                    constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBY &&
                  recoveredresourceobj.recoveredresource_type ===
                    constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBYS
                ) {
                  insideObj.amount = recoveredresourceobj.amount;
                }
              }
            );

            // count for Arcserve Cloud
            // Displaying count by adding 'Cloud_direct_volume' & 'Cloud_hybrid_stores'
            if (
              insideObj.countKey ===
              constant.SECONDARY_NAVIGATION_KEYS.ARCSERVE_CLOUD
            ) {
              let sumOfAllDestinations = 0;
              if (protectCountsData.destinationCount.data.length > 0) {
                protectCountsData.destinationCount.data.forEach(sourceobj => {
                  if (
                    sourceobj.id ===
                      constant.DESTINATION_TYPE.CLOUD_DIRECT_VOLUME ||
                    sourceobj.id ===
                      constant.DESTINATION_TYPE.CLOUD_DEDUPE_STORE
                  ) {
                    sumOfAllDestinations =
                      sumOfAllDestinations + sourceobj.count;
                  }
                });
              }
              insideObj.amount = sumOfAllDestinations;
            }

            // Count for Cloud Account
            if (
              insideObj.countKey ===
              constant.SECONDARY_NAVIGATION_KEYS.CLOUD_ACCOUNT
            ) {
              let sumOfAllDestinations = 0;
              if (protectCountsData.destinationCount.data.length > 0) {
                protectCountsData.destinationCount.data.forEach(sourceobj => {
                  if (
                    sourceobj.id ===
                    constant.SECONDARY_NAVIGATION_KEYS.CLOUD_ACCOUNT
                  ) {
                    sumOfAllDestinations =
                      sumOfAllDestinations + sourceobj.count;
                  }
                });
              }
              insideObj.amount = sumOfAllDestinations;
            }
          }
        }
        //eslint-disable-next-line default-case
        switch (insideObj.countKey) {
          // count for data stores
          case constant.SECONDARY_NAVIGATION_KEYS.DATA_STORE: {
            const destinationsCount = protectCountsData.destinationCount;
            const dataStoreObj = destinationsCount.data.find(
              item => item.id === constant.DESTINATION_COUNT_KEYS.DATA_STORE
            );
            insideObj.amount = dataStoreObj.count;
            break;
          }

          // count for recovery point servers
          case constant.SECONDARY_NAVIGATION_KEYS.RECOVERY_POINT_SERVER: {
            const destinationsCount = protectCountsData.destinationCount;
            const rpsObj = destinationsCount.data.find(
              item =>
                item.id ===
                constant.DESTINATION_COUNT_KEYS.RECOVERY_POINT_SERVER
            );
            insideObj.amount = rpsObj.count;
            break;
          }

          // count for Shared Folders
          case constant.SECONDARY_NAVIGATION_KEYS.SHARED_FOLDER: {
            if (
              insideObj.parentKey ===
              constant.SECONDARY_NAVIGATION_KEYS.DESTINATIONS
            ) {
              const destinationsCount = protectCountsData.destinationCount;
              const sharedFoldersObj = destinationsCount.data.find(
                item =>
                  item.id === constant.DESTINATION_COUNT_KEYS.SHARED_FOLDER
              );
              insideObj.amount = sharedFoldersObj.count;
            }
            break;
          }

          // count for Sites
          case constant.SECONDARY_NAVIGATION_KEYS.SITES: {
            insideObj.amount = protectCountsData[insideObj.countKey];
            break;
          }

          // count for Virtual Machines
          case constant.SECONDARY_NAVIGATION_KEYS.VIRTUAL_MACHINES: {
            insideObj.amount = 0;
            break;
          }
        }
        return insideObj;
      });

      obj.subMenu.forEach(insideObj => {
        //Todo: To Be Remove
        if (insideObj.isFilterType !== true) {
          switch (insideObj.countKey) {
            case constant.SECONDARY_NAVIGATION_KEYS.SOURCES:
              insideObj.amount = sumOfAllSources;
              break;
            case constant.SECONDARY_NAVIGATION_KEYS.RECOVERED_RESOURCES:
              insideObj.amount = sumOfAllRecoveredResources;
              break;
            case constant.SECONDARY_NAVIGATION_KEYS.POLICIES:
              insideObj.amount = protectCountsData.policies;
              break;
            default:
              break;
          }
        }
        return insideObj;
      });
    }
    return obj;
  });
}

export default reducer;

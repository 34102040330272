import { combineReducers } from "redux";
import {
  GET_IVM_HYPERV_LIST,
  CREATE_VMWARE_FOR_IVM_SUCCESS,
  CREATE_HYPERV_FOR_IVM_SUCCESS,
  CLEAR_VM_LOCATION_DETAILS,
  CLEAR_CREATE_VMWARE_FOR_IVM_SUCCESS,
  CLEAR_CREATE_HYPERV_FOR_IVM_SUCCESS,
  GET_HYPERV_CONFIGURATION_SUCCESS,
  GET_VMWARE_CONFIGURATION_SUCCESS,
  SAVE_VMWARE_CONFIG_PAYLOAD,
  CLEAR_VMWARE_CONFIG_RESP,
  SAVE_VMWARE_FINAL_PAYLOAD_INFO,
  CLEAR_VMWARE_FINAL_PAYLOAD_INFO,
  SET_SELECTED_TYPE_IN_VM_TREE,
  STORE_VALIDATE_CONFIG_DATA,
  INITIALIZE_APP,
  SAVE_RECOVERY_SELECTED_DESTINATION_DATA_IVM,
  CLEAR_IVM,
  SET_SELECTED_SOURCE_OS
} from "state/actions/actionTypes";

function hypervisorsList(state = null, action) {
  switch (action.type) {
    case GET_IVM_HYPERV_LIST:
      return action.data;
    case CLEAR_VM_LOCATION_DETAILS:
      return null;
    default:
      return state;
  }
}

function createIVMVmwareResp(state = null, action) {
  switch (action.type) {
    case CREATE_VMWARE_FOR_IVM_SUCCESS:
      return action.data;
    case CLEAR_CREATE_VMWARE_FOR_IVM_SUCCESS:
      return null;
    case CLEAR_VM_LOCATION_DETAILS:
      return null;
    default:
      return state;
  }
}

function createIVMHyperVResp(state = null, action) {
  switch (action.type) {
    case CREATE_HYPERV_FOR_IVM_SUCCESS:
      return action.data;
    case CLEAR_CREATE_HYPERV_FOR_IVM_SUCCESS:
      return null;
    case CLEAR_VM_LOCATION_DETAILS:
      return null;
    default:
      return state;
  }
}

function configResp(state = null, action) {
  switch (action.type) {
    case GET_HYPERV_CONFIGURATION_SUCCESS:
      return action.data || null;
    case GET_VMWARE_CONFIGURATION_SUCCESS:
      return action.data || null;
    case CLEAR_VMWARE_CONFIG_RESP:
      return null;
    default:
      return state;
  }
}

function validateConfigResp(state = null, action) {
  switch (action.type) {
    case STORE_VALIDATE_CONFIG_DATA:
      return action.data || null;
    case INITIALIZE_APP:
      return null;
    default:
      return state;
  }
}

function vmWareConfigPayload(state = null, action) {
  switch (action.type) {
    case SAVE_VMWARE_CONFIG_PAYLOAD:
      return action.data || null;
    default:
      return state;
  }
}

function vmWareFinalPayloadInfo(state = null, action) {
  switch (action.type) {
    case SAVE_VMWARE_FINAL_PAYLOAD_INFO:
      return action.data || null;
    case CLEAR_VMWARE_FINAL_PAYLOAD_INFO:
      return null;
    default:
      return state;
  }
}
function isSelectedTypeVCenterOrDatacenter(state = false, action) {
  switch (action.type) {
    case SET_SELECTED_TYPE_IN_VM_TREE:
      return action.data || false;
    default:
      return state;
  }
}

function selectedSourceOS(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_SOURCE_OS:
      return action.data || null;
    default:
      return state;
  }
}

function selectedDestinationDataIVM(state = {}, action) {
  switch (action.type) {
    case SAVE_RECOVERY_SELECTED_DESTINATION_DATA_IVM:
      return action.data;
    case INITIALIZE_APP:
    case CLEAR_IVM:
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  hypervisorsList,
  createIVMVmwareResp,
  createIVMHyperVResp,
  configResp,
  validateConfigResp,
  vmWareConfigPayload,
  vmWareFinalPayloadInfo,
  isSelectedTypeVCenterOrDatacenter,
  selectedDestinationDataIVM,
  selectedSourceOS
});

import {
  select,
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from "redux-saga/effects";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getLogin } from "state/selectors";
import * as actionType from "../actions/actionTypes";
import {
  updateDashboardSummary,
  showAlertList,
  showAlertDetailsModal,
  addAPIresponse,
  stopLoader,
  setOrganizationType,
  storeOrganization,
  showGridLoader,
  stopGridLoaderById,
  startGridLoaderById
} from "../actions/actions";
import { monitorApi, commonApi } from "../api";
import * as API_URL from "../api/apiUrlConstants";
import * as util from "../../utils/SpogDataGridUtil";
import * as monitorConst from "../../components/monitor/dashboard/DashboardConstants";
import {
  MSP_ORG_TYPE,
  HTTP_STATUS,
  CHART_DATA_TYPE,
  API_RESPONSE_CONTEXT,
  DATAGRID_IDS
} from "../../utils/appConstants";
import {
  addSuccessNotification,
  addErrorNotification
} from "state/actions/ccToast";
import * as _ from "lodash";
import { makeToastData } from "./sagautils";

const getReducer = state => state.reducer;
let queryParams = "";

/**
 * To form query params
 * */
function getQueryParams(data, stateReducer) {
  let stateReducerOptions = [];
  let stateReducersearchText = "";
  if (data.payload && data.payload.searchText) {
    stateReducersearchText = data.payload.searchText;
  } else if (stateReducer) {
    stateReducersearchText = stateReducer.searchText;
  }
  let stateReducersearchOption = "";
  if (stateReducer && stateReducer.searchOption.selectedOption) {
    stateReducersearchOption = stateReducer.searchOption.selectedOption;
  }
  if (data.payload && data.payload.advancedSearchOptions) {
    stateReducerOptions = data.payload.advancedSearchOptions;
  } else {
    stateReducerOptions = stateReducer.currentSearchOptions;
  }

  let queryString = "";
  let makeQueryString = util.getQueryStringByAdvanceSearchOptions(
    queryString,
    stateReducerOptions
  );
  queryParams = util.getQueryStringBySearchNameText(
    makeQueryString,
    stateReducersearchText,
    stateReducersearchOption
  );
}

export function* updateWidgetsOrder(payload) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(showLoading());

    const widgets = payload.data.widgets;
    let widgetsArr = [];
    for (let i = 0; i < widgets.length; i++) {
      let widget = {};
      widget.widget_id = widgets[i].dashboard_widget_id;
      widget.visible = true;
      widget.is_expanded = util.isWidgetExpanded(payload.data.layout, i);
      widget.order_id = util.getWidgetOrder(payload.data.layout, i);
      if (widget.widget_id !== "000" && widget.widget_id !== "001")
        widgetsArr.push(widget);
    }

    //Sorting the array of obj based on orderid then map to remove spaces in layout
    let widgetsPayload = widgetsArr
      .sort(function(a, b) {
        return a.order_id - b.order_id;
      })
      .map(function(widget, index) {
        widget.order_id = index + 1;
        return widget;
      });

    let payloadData = {
      widgets: widgetsPayload
    };

    let apiResponse = yield call(
      monitorApi.updateWidgetsOrder,
      stateLogin.user_id,
      payloadData,
      stateLogin.token
    );

    if (apiResponse && apiResponse.status === HTTP_STATUS.OK) {
      if (payload.afterReload) {
        yield put({
          type: actionType.GET_WIDGETS,
          data: payload.data.widgets
        });
        // yield put(getWidgetsList());
      }
      yield put(hideLoading());
    }
  } catch (e) {
    window.console.error(e);
  }
}

export function* fetchWidgetsList(data) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(showLoading());

    let apiResponse = yield call(
      monitorApi.fetchWidgetsList,
      stateLogin.user_id,
      stateLogin.token,
      data.pollingAction
    );
    if (apiResponse && apiResponse.status === HTTP_STATUS.OK) {
      const widgetConstants = monitorConst.WidgetsList;

      const mergedWidgets = [];
      if (
        stateLogin.organization_id === "78506751-cc21-4989-a1f4-d7019df22a9f"
      ) {
        apiResponse.data.push(
          ...[
            {
              dashboard_widget_id: "000",
              is_expanded: false,
              key: "stc_change_rate",
              long_label: "STC Change Rate",
              order_id: -2,
              short_label: "STC Change Rate",
              visible: true
            },
            {
              dashboard_widget_id: "001",
              is_expanded: false,
              key: "stc_usage",
              long_label: "STC Usage",
              order_id: -1,
              short_label: "STC Usage",
              visible: true
            }
          ]
        );

        widgetConstants.forEach(x => {
          apiResponse.data.forEach(y => {
            if (x.key === y.key) {
              mergedWidgets.push({ ...x, ...y, order_id: y.order_id + 2 });
            }
          });
        });
      } else {
        widgetConstants.forEach(x => {
          apiResponse.data.forEach(y => {
            if (x.key === y.key) {
              mergedWidgets.push({ ...x, ...y });
            }
          });
        });
      }
      yield put(hideLoading());
      yield put({
        type: actionType.GET_WIDGETS,
        data: mergedWidgets
      });
    }
  } catch (e) {
    window.console.error(e);
  }
}

export function* receiveAlertsList(data) {
  try {
    const stateLogin = yield select(getLogin);
    const commonState = yield select(getReducer);

    let organization_id = commonState.isImpersonationView
      ? commonState.customerOrgId
      : stateLogin.organization_id;

    let apiResponse = yield call(
      monitorApi.fetchAlertsList,
      organization_id,
      stateLogin.token,
      data.pollingAction
    );

    if (apiResponse && apiResponse.status === HTTP_STATUS.OK)
      yield put(showAlertList(apiResponse));
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * getOrganization fetches the organization detail without organization_id
 */
export function* getOrganization(data) {
  try {
    const stateLogin = yield select(getLogin);
    const apiResponse = yield call(commonApi.getOrganization, stateLogin.token);
    if (HTTP_STATUS.OK === apiResponse.status) {
      yield put(setOrganizationType(apiResponse.data));
      yield put(storeOrganization(apiResponse.data, false));
    }
  } catch (e) {
    window.console.error(e);
  }
}

export function* configureAccountType(data) {
  try {
    const stateLogin = yield select(getLogin);
    let apiResponse = yield call(
      commonApi.updateOrganizationType,
      data.data,
      stateLogin.token
    );
    if (HTTP_STATUS.OK === apiResponse.status) {
      yield put(setOrganizationType(apiResponse.data));
      yield put(storeOrganization(apiResponse.data, false));
      yield put(
        addSuccessNotification(
          makeToastData("monitor.configure_account.success.message", "0sfr42")
        )
      );
      window.location.reload();
    } else {
      yield put(
        addErrorNotification(
          makeToastData(
            _.get(apiResponse, "errors[0].message"),
            `EXUreH_${apiResponse?.status}`
          )
        )
      );
      //window.location.reload();
      // yield put(getBrandingDetails());
      // yield put(fetchEntitlementsProducts());
      // yield put(getUserDetails());
    }
  } catch (e) {
    window.console.error(e);
  }
}

export function* receiveAlertDetails(action) {
  try {
    const stateLogin = yield select(getLogin);
    //  const commonState = yield select(getReducer);
    // let organization_id = commonState.isImpersonationView
    //   ? commonState.customerOrgId
    //   : stateLogin.organization_id;

    let list = [];
    list = yield call(
      monitorApi.fetchAlertsDetail,
      action.data.organization_id,
      stateLogin.token,
      action.data.alert_id
    );

    yield put(showAlertDetailsModal(list));
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * To delete alert by id
 */
export function* deleteAlertList(action) {
  try {
    const stateLogin = yield select(getLogin);
    let organization_id = stateLogin.organization_id;

    let apiResponse = yield call(
      monitorApi.deleteAlert,
      organization_id,
      stateLogin.token,
      action.data,
      { showCommonError: true, testId: `powqmK` }
    );
    if (apiResponse && apiResponse.status === HTTP_STATUS.OK) {
      yield put({
        type: actionType.GET_ALERT_LIST
      });
      const toastData = {
        messageId: API_RESPONSE_CONTEXT.ALERT_ACKNOWLEDGE_SUCCESS,
        testId: `dkjnao`
      };
      // apiResponse.context = API_RESPONSE_CONTEXT.ALERT_ACKNOWLEDGE_SUCCESS;
      yield put(addSuccessNotification(toastData));
    }
    // else if (
    //   apiResponse &&
    //   apiResponse.errors &&
    //   apiResponse.errors.length > 0
    // ) {
    //   const toastData = {
    //     messageId: _.get(apiResponse, "errors[0].message", ""),
    //     testId: `powqmK_${apiResponse.status}`
    //   };
    //   yield put(addErrorNotification(toastData));
    // }
    // yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * To delete alert by id
 */
export function* deleteAllAlerts(action) {
  try {
    const stateLogin = yield select(getLogin);
    let apiResponse = yield call(
      monitorApi.acknowledgeAllAlert,
      stateLogin.organization_id,
      action.data,
      stateLogin.token
    );
    if (apiResponse && apiResponse.status === HTTP_STATUS.OK) {
      yield put({
        type: actionType.GET_ALERT_LIST
      });
      const toastData = {
        messageId: API_RESPONSE_CONTEXT.ALERT_ACKNOWLEDGE_ALL_SUCCESS,
        testId: `SDSpSi`
      };
      // apiResponse.context = API_RESPONSE_CONTEXT.ALERT_ACKNOWLEDGE_ALL_SUCCESS;
      yield put(addSuccessNotification(toastData));
    } else if (
      apiResponse &&
      apiResponse.errors &&
      apiResponse.errors.length > 0
    ) {
      const toastData = {
        messageId: _.get(apiResponse, "errors[0].message", ""),
        testId: `9sakps_${apiResponse.status}`
      };
      yield put(addErrorNotification(toastData));
    }
    // yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * To fetch top customers data
 * @param data
 */
function* fetchTopCustomersData(data) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    getQueryParams(data, stateReducer);
    yield put(showLoading());

    const organizationId = stateLogin.organization_id;
    queryParams.length ? (queryParams += "&") : void 0;
    queryParams = `organization_id=${organizationId}`;
    const response = yield call(
      monitorApi.loadWidgets,
      `${API_URL.FETCH_DASHBOARD_TOP_CUSTOMERS}`,
      queryParams,
      stateLogin.token
    );
    yield put(hideLoading());
    const dataList = {
      key: monitorConst.TOP_10_CUSTOMERS,
      analysis: response.data.customers,
      filter: CHART_DATA_TYPE.BYTES
    };

    // To update reducer
    yield put({
      type: actionType.UPDATE_WIDGETS,
      data: dataList
    });
  } catch (e) {
    window.console.error(e);
  }
}
/**
 * To filter top sources data
 * @param value
 */
function* filterTopSourceData(value) {
  try {
    const stateLogin = yield select(getLogin);
    let queryParams = `filter_type=${value.payload}`;
    yield put(showLoading());
    const organizationId = stateLogin.organization_id;

    queryParams += `&organization_id=${organizationId}`;

    const response = yield call(
      monitorApi.loadWidgets,
      `${API_URL.FETCH_DASHBOARD_TOP_SOURCES}`,
      queryParams,
      stateLogin.token
    );
    yield put(hideLoading());
    const dataLists = {
      key: monitorConst.TOP_PROBLEMATIC_SOURCES,
      analysis: response.data.sources,
      filter: value.payload
    };
    // To update reducer
    yield put({
      type: actionType.UPDATE_WIDGETS,
      data: dataLists
    });
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * To fetch top policies data
 * @param data
 */
function* fetchTopPoliciesData(data) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    getQueryParams(data, stateReducer);
    yield put(showLoading());
    const organizationId = stateLogin.organization_id;
    queryParams.length ? (queryParams += "&") : void 0;
    queryParams = `organization_id=${organizationId}`;
    const response = yield call(
      monitorApi.loadWidgets,
      `${API_URL.FETCH_DASHBOARD_TOP_POLICIES}`,
      queryParams,
      stateLogin.token
    );
    yield put(hideLoading());
    const dataList = {
      key: monitorConst.TOP_10_POLICIES,
      analysis: response.data.policies
    };

    // To update reducer
    yield put({
      type: actionType.UPDATE_WIDGETS,
      data: dataList
    });
  } catch (e) {
    window.console.error(e);
  }
}
/**
 * To fetch data transfer summary data
 * @param data
 */
function* fetchDataTransferSummary(data) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    getQueryParams(data, stateReducer);
    yield put(showLoading());
    const organizationId = stateLogin.organization_id;
    queryParams.length ? (queryParams += "&") : void 0;
    queryParams = `organization_id=${organizationId}&time_zone=${util.getTimezone()}`;
    const response = yield call(
      monitorApi.loadWidgets,
      `${API_URL.FETCH_REPORTS_DATA_TRANSFER_SUMMARY}`,
      queryParams,
      stateLogin.token
    );
    yield put(hideLoading());
    const dataList = {
      key: monitorConst.DATA_TRANSFER_SUMMARY_FOR_CLOUD_DIRECT_VOLUMES,
      analysis: response.data,
      arrayKey: monitorConst.data_transfer_summary,
      filter: CHART_DATA_TYPE.BYTES
    };

    // To update reducer
    yield put({
      type: actionType.UPDATE_SUMMARY_WIDGETS,
      data: dataList
    });
  } catch (e) {
    window.console.error(e);
  }
}
/**
 * To fetch capacity usage trend data
 * @param data
 */
function* fetchCapacityUsageTrendData(data) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    getQueryParams(data, stateReducer);
    yield put(showLoading());

    const organizationId = stateLogin.organization_id;
    queryParams.length ? (queryParams += "&") : void 0;
    queryParams = `organization_id=${organizationId}`;
    const response = yield call(
      monitorApi.loadWidgets,
      `${API_URL.FETCH_CAPACITY_USAGE_TREND}`,
      queryParams,
      stateLogin.token
    );
    yield put(hideLoading());
    const dataList = {
      key: monitorConst.CAPACITY_USAGE_TREND_CLOUD_HYBRID_STORES,
      analysis: response.data,
      arrayKey: monitorConst.dedupe_savings_summary,
      filter: CHART_DATA_TYPE.BYTES
    };

    // To update reducer
    yield put({
      type: actionType.UPDATE_SUMMARY_WIDGETS,
      data: dataList
    });
  } catch (e) {
    window.console.error(e);
  }
}
/**
 * To fetch usage trend for CD volumes data
 * @param data
 */
function* fetchUsageTrendCDVolumesData(data) {
  // try {
  //   const stateReducer = yield select(getReducer);
  //   const stateLogin = yield select(getLogin);
  //
  //   if (stateLogin.organization_type !== "msp") {
  //     return;
  //   }
  //
  //   getQueryParams(data, stateReducer);
  //   yield put(showLoading());
  //   if (stateReducer.isImpersonationView) {
  //     queryParams.length ? (queryParams += "&") : void 0;
  //     queryParams = `organization_id=${stateReducer.customerOrgId}`;
  //   }
  //
  //   const response =
  //     stateLogin.organization_type === "msp"
  //       ? yield call(
  //           monitorApi.loadWidgets,
  //           `${API_URL.FETCH_MSP_CAPACITY_USAGE_CD_TREND_USAGE}`,
  //           queryParams,
  //           stateLogin.token
  //         )
  //       : yield call(
  //           monitorApi.loadWidgets,
  //           `${API_URL.FETCH_CAPACITY_USAGE_CD_TREND_USAGE}`,
  //           queryParams,
  //           stateLogin.token
  //         );
  //
  //   yield put(hideLoading());
  //   const dataList = {
  //     key: monitorConst.USAGE_TRENDS_FOR_CLOUD_DIRECT_VOLUMES,
  //     analysis: response.data,
  //     filter: CHART_DATA_TYPE.BYTES
  //   };
  //
  //   // To update reducer
  //   yield put({
  //     type: actionType.UPDATE_WIDGETS,
  //     data: dataList
  //   });
  // } catch (e) {
  //   window.console.error(e);
  // }
}
/**
 * To fetch usage trend for CH stores data
 * @param data
 */
function* fetchUsageTrendCHStoresData(data) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    getQueryParams(data, stateReducer);
    yield put(showLoading());
    const organizationId = stateLogin.organization_id;
    queryParams.length ? (queryParams += "&") : void 0;
    queryParams = `organization_id=${organizationId}`;
    const response = yield call(
      monitorApi.loadWidgets,
      `${API_URL.FETCH_DASHBOARD_CAPACITY_USAGE}`,
      queryParams,
      stateLogin.token
    );
    yield put(hideLoading());
    const dataList = {
      key: monitorConst.USAGE_TRENDS_FOR_CLOUD_HYBRID_STORES,
      analysis: response.data,
      filter: CHART_DATA_TYPE.BYTES
    };

    // To update reducer
    yield put({
      type: actionType.UPDATE_WIDGETS,
      data: dataList
    });
  } catch (e) {
    window.console.error(e);
  }
}

export function* cancelRecentJobByID(action) {
  try {
    const loginReducer = yield select(getLogin);
    const reducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      commonApi.cancelJobById,
      action.data,
      loginReducer.token,
      { showCommonError: true, testId: "IUBCSp" }
    );
    yield put(hideLoading());
    if (HTTP_STATUS.OK === apiResponse.status) {
      if (action.actionType === "cancelrecovery") {
        const toastData = {
          messageId: `common.jobs.cancelRecovery`,
          testId: `vfdthd`
        };
        yield put(
          addSuccessNotification(toastData, {
            name: reducer.selectedResource.source_name
          })
        );
      } else {
        const toastData = {
          messageId: `common.jobs.cancelJob`,
          testId: `fwefyb`
        };
        yield put(
          addSuccessNotification(toastData, {
            name: reducer.selectedResource.source_name
          })
        );
      }

      yield* fetchRecentTenJobsInProgressData({});
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}
export function* deleteRecentJobByID(action) {
  try {
    const loginReducer = yield select(getLogin);
    const reducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      commonApi.deleteJobById,
      action.data,
      loginReducer.token,
      { showCommonError: true, testId: "93kjkj" }
    );
    yield put(hideLoading());
    if (HTTP_STATUS.OK === apiResponse.status) {
      if (action.actionType === "deletejob") {
        const toastData = {
          messageId: `common.jobs.deleteJob`,
          testId: `vskjds`
        };
        yield put(
          addSuccessNotification(toastData, {
            name: reducer.selectedResource.source_name
          })
        );
      }
      yield* fetchRecentTenJobsInProgressData({});
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * To fetch recent 10 jobs in progress grid data
 */

function* fetchRecentTenJobsInProgressData(data) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    getQueryParams(data, stateReducer);
    yield put(showLoading());
    yield put(startGridLoaderById(DATAGRID_IDS["recent_jobs_inprogress_grid"]));
    const organizationId = stateLogin.organization_id;
    queryParams.length ? (queryParams += "&") : void 0;
    queryParams = `${monitorConst.job_status_active}&organization_id=${organizationId}&sort=-start_time_ts&page=1&page_size=10`;

    let apiResponse = yield call(
      monitorApi.loadWidgets,
      `${API_URL.FETCH_JOBS}`,
      queryParams,
      stateLogin.token
    );

    // apiResponse = {
    //   status: 200,
    //   data: [
    //     {
    //       job_id: "3d347974-ce35-4f38-b940-b21199f1b6c5",
    //       job_name: "Backup from SBCCD1 to vol_ztst-10198.zetta.net",
    //       job_type: "rps_replicate_in_bound",
    //       job_status: "active",
    //       severity: "success",
    //       start_time_ts: 1664780419,
    //       duration: 1663,
    //       server_id: "3fa57e31-da50-4b3d-bf48-9563f0c2597f",
    //       source_id: "3fa57e31-da50-4b3d-bf48-9563f0c2597f",
    //       source_name: "SBCCD1",
    //       site: {
    //         site_id: "fc62f9b7-0615-4bc0-be29-b24e7d88444c",
    //         site_name: "Arcserve Cloud"
    //       },
    //       organization: {
    //         organization_id: "03f5be58-4ae1-4791-a24b-362f5fcdf740",
    //         organization_name: "Sachin",
    //         organization_type: "msp_child"
    //       },
    //       policy: {
    //         policy_id: "7eaeaf10-095e-494d-865b-bbb1fa95d521",
    //         policy_name: "Cloud Direct Full System",
    //         policy_type: "cloud_direct_baas"
    //       },
    //       available_actions: ["viewlogs", "canceljob"],
    //       percent_complete: 37,
    //       product_type: "cloud_direct",
    //       destinations: [
    //         {
    //           destination_id: "12539267-80e6-4862-b239-44051702da8f",
    //           destination_name: "vol_ztst-10198.zetta.net",
    //           destination_type: "cloud_direct_volume"
    //         }
    //       ],
    //       job_name_metadata: {
    //         source_name: "SBCCD1",
    //         destination_name: "vol_ztst-10198.zetta.net",
    //         job_type: "backup_incremental"
    //       }
    //     },
    //     {
    //       job_id: "144c62f0-63c5-44b3-a3f6-6efcb700bb97",
    //       job_name: "Backup from WIN-K2IFD0EDQBM to cdbassvolume",
    //       job_type: "backup_incremental",
    //       job_status: "active",
    //       severity: "success",
    //       start_time_ts: 1664780415,
    //       duration: 1667,
    //       server_id: "de703018-dc9a-4201-a5d4-8433295e764a",
    //       source_id: "de703018-dc9a-4201-a5d4-8433295e764a",
    //       source_name: "WIN-K2IFD0EDQBM Linux X209 Ps-1 ",
    //       site: {
    //         site_id: "fc62f9b7-0615-4bc0-be29-b24e7d88444c",
    //         site_name: "Arcserve Cloud"
    //       },
    //       organization: {
    //         organization_id: "abd8bc8a-af52-4d40-ac66-69b7cad1183c",
    //         organization_name: "reddy_bcc",
    //         organization_type: "msp_child"
    //       },
    //       policy: {
    //         policy_id: "f9c5084b-82dd-4781-9eb2-9aad822d0e5d",
    //         policy_name: "fvolumebackup",
    //         policy_type: "cloud_direct_baas"
    //       },
    //       available_actions: ["viewlogs", "canceljob"],
    //       percent_complete: 30,
    //       product_type: "cloud_direct",
    //       destinations: [
    //         {
    //           destination_id: "c1014e21-94a5-4980-80c9-cfe5a4e3d328",
    //           destination_name: "cdbassvolume",
    //           destination_type: "cloud_direct_volume"
    //         }
    //       ],
    //       job_name_metadata: {
    //         source_name: "WIN-K2IFD0EDQBM",
    //         destination_name: "cdbassvolume",
    //         job_type: "backup_incremental"
    //       }
    //     },
    //     {
    //       job_id: "e1024ed5-f004-4c2e-ba5b-bd161a8524c5",
    //       job_name: "Backup from Avi-cd-src to Disaster Recovery",
    //       job_type: "backup_incremental",
    //       job_status: "active",
    //       severity: "success",
    //       start_time_ts: 1664773217,
    //       duration: 8865,
    //       server_id: "3c7888f4-c519-47bb-9cfb-3bd2539d5339",
    //       source_id: "3c7888f4-c519-47bb-9cfb-3bd2539d5339",
    //       source_name: "Avi-cd-src",
    //       site: {
    //         site_id: "fc62f9b7-0615-4bc0-be29-b24e7d88444c",
    //         site_name: "Arcserve Cloud"
    //       },
    //       organization: {
    //         organization_id: "22d4c486-3b77-41e1-a47b-5890b201af34",
    //         organization_name: "AvinashK",
    //         organization_type: "msp_child"
    //       },
    //       policy: {
    //         policy_id: "13847b78-085d-4459-b1a8-da542cf26962",
    //         policy_name: "mspaccdraas",
    //         policy_type: "cloud_direct_draas"
    //       },
    //       available_actions: ["viewlogs", "canceljob"],
    //       percent_complete: 80,
    //       product_type: "cloud_direct",
    //       destinations: [
    //         {
    //           destination_id: "41d59f38-27e3-4186-9236-2643beab3b31",
    //           destination_name: "Disaster Recovery",
    //           destination_type: "cloud_direct_volume"
    //         }
    //       ],
    //       job_name_metadata: {
    //         source_name: "Avi-cd-src",
    //         destination_name: "Disaster Recovery",
    //         job_type: "backup_incremental"
    //       }
    //     },
    //     {
    //       job_id: "3d347974-ce35-4f38-b940-b21199f1b6c5",
    //       job_name: "Backup from SBCCD1 to vol_ztst-10198.zetta.net",
    //       job_type: "backup_incremental",
    //       job_status: "active",
    //       severity: "success",
    //       start_time_ts: 1664780419,
    //       duration: 1663,
    //       server_id: "3fa57e31-da50-4b3d-bf48-9563f0c2597f",
    //       source_id: "3fa57e31-da50-4b3d-bf48-9563f0c2597f",
    //       source_name: "SBCCD1",
    //       site: {
    //         site_id: "fc62f9b7-0615-4bc0-be29-b24e7d88444c",
    //         site_name: "Arcserve Cloud"
    //       },
    //       organization: {
    //         organization_id: "03f5be58-4ae1-4791-a24b-362f5fcdf740",
    //         organization_name: "Sachin",
    //         organization_type: "msp_child"
    //       },
    //       policy: {
    //         policy_id: "7eaeaf10-095e-494d-865b-bbb1fa95d521",
    //         policy_name: "Cloud Direct Full System",
    //         policy_type: "cloud_direct_baas"
    //       },
    //       available_actions: ["viewlogs", "canceljob"],
    //       percent_complete: 37,
    //       product_type: "cloud_direct",
    //       destinations: [
    //         {
    //           destination_id: "12539267-80e6-4862-b239-44051702da8f",
    //           destination_name: "vol_ztst-10198.zetta.net",
    //           destination_type: "cloud_direct_volume"
    //         }
    //       ],
    //       job_name_metadata: {
    //         source_name: "SBCCD1",
    //         destination_name: "vol_ztst-10198.zetta.net",
    //         job_type: "backup_incremental"
    //       }
    //     },
    //     {
    //       job_id: "144c62f0-63c5-44b3-a3f6-6efcb700bb97",
    //       job_name: "Backup from WIN-K2IFD0EDQBM to cdbassvolume",
    //       job_type: "backup_incremental",
    //       job_status: "active",
    //       severity: "success",
    //       start_time_ts: 1664780415,
    //       duration: 1667,
    //       server_id: "de703018-dc9a-4201-a5d4-8433295e764a",
    //       source_id: "de703018-dc9a-4201-a5d4-8433295e764a",
    //       source_name: "WIN-K2IFD0EDQBM Linux X209 Ps-1 ",
    //       site: {
    //         site_id: "fc62f9b7-0615-4bc0-be29-b24e7d88444c",
    //         site_name: "Arcserve Cloud"
    //       },
    //       organization: {
    //         organization_id: "abd8bc8a-af52-4d40-ac66-69b7cad1183c",
    //         organization_name: "reddy_bcc",
    //         organization_type: "msp_child"
    //       },
    //       policy: {
    //         policy_id: "f9c5084b-82dd-4781-9eb2-9aad822d0e5d",
    //         policy_name: "fvolumebackup",
    //         policy_type: "cloud_direct_baas"
    //       },
    //       available_actions: ["viewlogs", "canceljob"],
    //       percent_complete: 30,
    //       product_type: "cloud_direct",
    //       destinations: [
    //         {
    //           destination_id: "c1014e21-94a5-4980-80c9-cfe5a4e3d328",
    //           destination_name: "cdbassvolume",
    //           destination_type: "cloud_direct_volume"
    //         }
    //       ],
    //       job_name_metadata: {
    //         source_name: "WIN-K2IFD0EDQBM",
    //         destination_name: "cdbassvolume",
    //         job_type: "backup_incremental"
    //       }
    //     },
    //     {
    //       job_id: "e1024ed5-f004-4c2e-ba5b-bd161a8524c5",
    //       job_name: "Backup from Avi-cd-src to Disaster Recovery",
    //       job_type: "backup_incremental",
    //       job_status: "active",
    //       severity: "success",
    //       start_time_ts: 1664773217,
    //       duration: 8865,
    //       server_id: "3c7888f4-c519-47bb-9cfb-3bd2539d5339",
    //       source_id: "3c7888f4-c519-47bb-9cfb-3bd2539d5339",
    //       source_name: "Avi-cd-src",
    //       site: {
    //         site_id: "fc62f9b7-0615-4bc0-be29-b24e7d88444c",
    //         site_name: "Arcserve Cloud"
    //       },
    //       organization: {
    //         organization_id: "22d4c486-3b77-41e1-a47b-5890b201af34",
    //         organization_name: "AvinashK",
    //         organization_type: "msp_child"
    //       },
    //       policy: {
    //         policy_id: "13847b78-085d-4459-b1a8-da542cf26962",
    //         policy_name: "mspaccdraas",
    //         policy_type: "cloud_direct_draas"
    //       },
    //       available_actions: ["viewlogs", "canceljob"],
    //       percent_complete: 80,
    //       product_type: "cloud_direct",
    //       destinations: [
    //         {
    //           destination_id: "41d59f38-27e3-4186-9236-2643beab3b31",
    //           destination_name: "Disaster Recovery",
    //           destination_type: "cloud_direct_volume"
    //         }
    //       ],
    //       job_name_metadata: {
    //         source_name: "Avi-cd-src",
    //         destination_name: "Disaster Recovery",
    //         job_type: "backup_incremental"
    //       }
    //     }
    //     // {
    //     //   job_id: "144c62f0-63c5-44b3-a3f6-6efcb700bb97",
    //     //   job_name: "Backup from WIN-K2IFD0EDQBM to cdbassvolume",
    //     //   job_type: "backup_incremental",
    //     //   job_status: "active",
    //     //   severity: "success",
    //     //   start_time_ts: 1664780415,
    //     //   duration: 1667,
    //     //   server_id: "de703018-dc9a-4201-a5d4-8433295e764a",
    //     //   source_id: "de703018-dc9a-4201-a5d4-8433295e764a",
    //     //   source_name: "WIN-K2IFD0EDQBM Linux X209 Ps-1 ",
    //     //   site: {
    //     //     site_id: "fc62f9b7-0615-4bc0-be29-b24e7d88444c",
    //     //     site_name: "Arcserve Cloud"
    //     //   },
    //     //   organization: {
    //     //     organization_id: "abd8bc8a-af52-4d40-ac66-69b7cad1183c",
    //     //     organization_name: "reddy_bcc",
    //     //     organization_type: "msp_child"
    //     //   },
    //     //   policy: {
    //     //     policy_id: "f9c5084b-82dd-4781-9eb2-9aad822d0e5d",
    //     //     policy_name: "fvolumebackup",
    //     //     policy_type: "cloud_direct_baas"
    //     //   },
    //     //   available_actions: ["viewlogs", "canceljob"],
    //     //   percent_complete: 30,
    //     //   product_type: "cloud_direct",
    //     //   destinations: [
    //     //     {
    //     //       destination_id: "c1014e21-94a5-4980-80c9-cfe5a4e3d328",
    //     //       destination_name: "cdbassvolume",
    //     //       destination_type: "cloud_direct_volume"
    //     //     }
    //     //   ],
    //     //   job_name_metadata: {
    //     //     source_name: "WIN-K2IFD0EDQBM",
    //     //     destination_name: "cdbassvolume",
    //     //     job_type: "backup_incremental"
    //     //   }
    //     // },
    //     // {
    //     //   job_id: "e1024ed5-f004-4c2e-ba5b-bd161a8524c5",
    //     //   job_name: "Backup from Avi-cd-src to Disaster Recovery",
    //     //   job_type: "backup_incremental",
    //     //   job_status: "active",
    //     //   severity: "success",
    //     //   start_time_ts: 1664773217,
    //     //   duration: 8865,
    //     //   server_id: "3c7888f4-c519-47bb-9cfb-3bd2539d5339",
    //     //   source_id: "3c7888f4-c519-47bb-9cfb-3bd2539d5339",
    //     //   source_name: "Avi-cd-src",
    //     //   site: {
    //     //     site_id: "fc62f9b7-0615-4bc0-be29-b24e7d88444c",
    //     //     site_name: "Arcserve Cloud"
    //     //   },
    //     //   organization: {
    //     //     organization_id: "22d4c486-3b77-41e1-a47b-5890b201af34",
    //     //     organization_name: "AvinashK",
    //     //     organization_type: "msp_child"
    //     //   },
    //     //   policy: {
    //     //     policy_id: "13847b78-085d-4459-b1a8-da542cf26962",
    //     //     policy_name: "mspaccdraas",
    //     //     policy_type: "cloud_direct_draas"
    //     //   },
    //     //   available_actions: ["viewlogs", "canceljob"],
    //     //   percent_complete: 80,
    //     //   product_type: "cloud_direct",
    //     //   destinations: [
    //     //     {
    //     //       destination_id: "41d59f38-27e3-4186-9236-2643beab3b31",
    //     //       destination_name: "Disaster Recovery",
    //     //       destination_type: "cloud_direct_volume"
    //     //     }
    //     //   ],
    //     //   job_name_metadata: {
    //     //     source_name: "Avi-cd-src",
    //     //     destination_name: "Disaster Recovery",
    //     //     job_type: "backup_incremental"
    //     //   }
    //     // },
    //     // {
    //     //   job_id: "144c62f0-63c5-44b3-a3f6-6efcb700bb97",
    //     //   job_name: "Backup from WIN-K2IFD0EDQBM to cdbassvolume",
    //     //   job_type: "backup_incremental",
    //     //   job_status: "active",
    //     //   severity: "success",
    //     //   start_time_ts: 1664780415,
    //     //   duration: 1667,
    //     //   server_id: "de703018-dc9a-4201-a5d4-8433295e764a",
    //     //   source_id: "de703018-dc9a-4201-a5d4-8433295e764a",
    //     //   source_name: "WIN-K2IFD0EDQBM Linux X209 Ps-1 ",
    //     //   site: {
    //     //     site_id: "fc62f9b7-0615-4bc0-be29-b24e7d88444c",
    //     //     site_name: "Arcserve Cloud"
    //     //   },
    //     //   organization: {
    //     //     organization_id: "abd8bc8a-af52-4d40-ac66-69b7cad1183c",
    //     //     organization_name: "reddy_bcc",
    //     //     organization_type: "msp_child"
    //     //   },
    //     //   policy: {
    //     //     policy_id: "f9c5084b-82dd-4781-9eb2-9aad822d0e5d",
    //     //     policy_name: "fvolumebackup",
    //     //     policy_type: "cloud_direct_baas"
    //     //   },
    //     //   available_actions: ["viewlogs", "canceljob"],
    //     //   percent_complete: 30,
    //     //   product_type: "cloud_direct",
    //     //   destinations: [
    //     //     {
    //     //       destination_id: "c1014e21-94a5-4980-80c9-cfe5a4e3d328",
    //     //       destination_name: "cdbassvolume",
    //     //       destination_type: "cloud_direct_volume"
    //     //     }
    //     //   ],
    //     //   job_name_metadata: {
    //     //     source_name: "WIN-K2IFD0EDQBM",
    //     //     destination_name: "cdbassvolume",
    //     //     job_type: "backup_incremental"
    //     //   }
    //     // },
    //     // {
    //     //   job_id: "e1024ed5-f004-4c2e-ba5b-bd161a8524c5",
    //     //   job_name: "Backup from Avi-cd-src to Disaster Recovery",
    //     //   job_type: "backup_incremental",
    //     //   job_status: "active",
    //     //   severity: "success",
    //     //   start_time_ts: 1664773217,
    //     //   duration: 8865,
    //     //   server_id: "3c7888f4-c519-47bb-9cfb-3bd2539d5339",
    //     //   source_id: "3c7888f4-c519-47bb-9cfb-3bd2539d5339",
    //     //   source_name: "Avi-cd-src",
    //     //   site: {
    //     //     site_id: "fc62f9b7-0615-4bc0-be29-b24e7d88444c",
    //     //     site_name: "Arcserve Cloud"
    //     //   },
    //     //   organization: {
    //     //     organization_id: "22d4c486-3b77-41e1-a47b-5890b201af34",
    //     //     organization_name: "AvinashK",
    //     //     organization_type: "msp_child"
    //     //   },
    //     //   policy: {
    //     //     policy_id: "13847b78-085d-4459-b1a8-da542cf26962",
    //     //     policy_name: "mspaccdraas",
    //     //     policy_type: "cloud_direct_draas"
    //     //   },
    //     //   available_actions: ["viewlogs", "canceljob"],
    //     //   percent_complete: 80,
    //     //   product_type: "cloud_direct",
    //     //   destinations: [
    //     //     {
    //     //       destination_id: "41d59f38-27e3-4186-9236-2643beab3b31",
    //     //       destination_name: "Disaster Recovery",
    //     //       destination_type: "cloud_direct_volume"
    //     //     }
    //     //   ],
    //     //   job_name_metadata: {
    //     //     source_name: "Avi-cd-src",
    //     //     destination_name: "Disaster Recovery",
    //     //     job_type: "backup_incremental"
    //     //   }
    //     // }
    //   ],
    //   pagination: {
    //     curr_page: 1,
    //     total_page: 1,
    //     page_size: 10,
    //     has_prev: false,
    //     has_next: false,
    //     total_size: 3
    //   },
    //   errors: []
    // };
    yield put(hideLoading());
    let dataList = {};
    if (HTTP_STATUS.OK === apiResponse.status) {
      dataList = {
        key: monitorConst.RECENT_10_JOBS_IN_PROGRESS,
        analysis: apiResponse.data
      };
    }

    // To update reducer
    yield put({
      type: actionType.UPDATE_WIDGETS,
      data: dataList
    });
    yield put(stopLoader());
    yield put(stopGridLoaderById(DATAGRID_IDS["recent_jobs_inprogress_grid"]));
  } catch (e) {
    yield put(stopGridLoaderById(DATAGRID_IDS["recent_jobs_inprogress_grid"]));
    yield put(stopLoader());
    window.console.error(e);
  }
}

/**
 * To get summary for dashboard tiles
 */
export function* getDashboardSummary() {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    //  if isImpersonationView, get organizationId of Customer or get parent organizationId
    const organizationId = loginReducer.organization_id;
    yield put(showLoading());
    let sourceResponse = yield call(
      monitorApi.fetchSourceProtectionSummary,
      `organization_id=${organizationId}`,
      loginReducer.token
    );

    let usageResponse = yield call(
      monitorApi.fetchUsageSummary,
      loginReducer.organization_id,
      `organization_id=${organizationId}`,
      loginReducer.token
    );

    let customerResponse = {},
      policyResponse = {},
      jobStatus = {};
    if (loginReducer.organization_type === MSP_ORG_TYPE) {
      jobStatus = yield call(
        monitorApi.fetchJobStatus,
        `organization_id=${organizationId}`,
        loginReducer.token
      );
      customerResponse = yield call(
        monitorApi.fetchCustomerSummary,
        loginReducer.organization_id,
        loginReducer.token
      );
    } else {
      policyResponse = yield call(
        monitorApi.fetchPolicySummary,
        `organization_id=${organizationId}`,
        loginReducer.token
      );
    }
    yield put(hideLoading());
    yield put(
      updateDashboardSummary({
        sourceSummary: sourceResponse.data,
        policySummary: policyResponse.data,
        usageSummary: usageResponse.data,
        customerSummary: customerResponse.data,
        jobStatus: jobStatus.data
      })
    );
    yield put(hideLoading());
  } catch (e) {
    window.console.error(e);
  }
}

export default function* monitorSaga() {
  yield all([
    takeEvery(actionType.GET_WIDGETS_LIST, fetchWidgetsList),
    takeEvery(actionType.UPDATE_WIDGETS_ORDER, updateWidgetsOrder),
    takeEvery(actionType.GET_ALERT_LIST, receiveAlertsList),
    takeLatest(actionType.GET_ORGANIZATION_WITHOUT_ID, getOrganization),
    takeLatest(actionType.CONFIGURE_ACCOUNT_TYPE, configureAccountType),
    takeEvery(actionType.ACKNOWLEDGE_ALL_ALERTS, deleteAllAlerts),
    takeEvery(actionType.REMOVE_ALERT, deleteAlertList),
    takeEvery(
      actionType.LOAD_DASHBOARD_TOP_CUSTOMERS_WIDGET,
      fetchTopCustomersData
    ),
    takeEvery(
      actionType.LOAD_DASHBOARD_TOP_POLICIES_WIDGET,
      fetchTopPoliciesData
    ),
    takeEvery(
      actionType.LOAD_DATA_TRANSFER_SUMMARY_WIDGET,
      fetchDataTransferSummary
    ),
    takeEvery(
      actionType.LOAD_CAPACITY_USAGE_TREND_WIDGET,
      fetchCapacityUsageTrendData
    ),
    takeEvery(
      actionType.FILTER_DASHBOARD_TOP_SOURCES_WIDGET_DATA,
      filterTopSourceData
    ),
    takeEvery(actionType.GET_DASHBOARD_SUMMARY, getDashboardSummary),
    takeEvery(actionType.GET_ALERT_LIST_DETAIL, receiveAlertDetails),
    takeEvery(
      actionType.LOAD_USAGE_TREND_CD_VOLUMES_WIDGET,
      fetchUsageTrendCDVolumesData
    ),
    takeEvery(
      actionType.LOAD_USAGE_TREND_CH_STORES_WIDGET,
      fetchUsageTrendCHStoresData
    ),
    takeEvery(
      actionType.LOAD_RECENT_JOBS_IN_PROGRESS_WIDGET,
      fetchRecentTenJobsInProgressData
    ),
    takeLatest(actionType.CANCEL_RECENT_JOB_BY_ID, cancelRecentJobByID),
    takeLatest(actionType.DELETE_RECENT_JOB_BY_ID, deleteRecentJobByID)
  ]);
}

import { createSelector } from "reselect";
import { getActionId } from "components/protect/destination/data-store/datagrid/DataStoresGridColumns";

export const getDataStores = state =>
  state.destinations.dataStores.dataStoresResponse
    ? state.destinations.dataStores.dataStoresResponse.data
    : [];

const prepareIconObject = status => {
  let iconClass = "";
  switch (status) {
    case "running":
      iconClass = "success";
      break;
    case "starting":
    case "creating":
      iconClass = "warning";
      break;
    case "loading":
      iconClass = "loading-status";
      break;
    default:
      iconClass = "error";
  }
  return {
    text: status,
    iconClass
  };
};

export const getDataStoresGridData = createSelector(
  [getDataStores],
  dataStores =>
    dataStores.map(dataStore => ({
      id: dataStore.datastore_id,
      available_actions: dataStore.available_actions
        ? dataStore.available_actions.map(getActionId)
        : [],
      rps_server_id: dataStore.rps_server_id,
      name: dataStore.datastore_name,
      rps: dataStore.server_name,
      datastore_name: dataStore.datastore_name,
      server_name: dataStore.server_name,
      statusVal: dataStore.status,
      status: prepareIconObject(dataStore.status),
      policies: dataStore.policies,
      site_name: dataStore && dataStore.site ? dataStore.site.site_name : "",
      site_id: dataStore && dataStore.site ? dataStore.site.site_id : "",
      stored_data: dataStore.datastore_properties.source_data_size,
      deduplication: `${dataStore.datastore_properties.deduplication_percentage} %`,
      compression: `${dataStore.datastore_properties.compression_percentage} %`,
      data_reduction: `${dataStore.datastore_properties.overall_data_reduction} %`,
      space_occupied: dataStore.datastore_properties.space_occupied
    }))
);

export const getDataStoresPagination = state =>
  state.destinations.dataStores.dataStoresResponse
    ? state.destinations.dataStores.dataStoresResponse.pagination
    : {};

export const getCurrentDataStoreId = state =>
  state.destinations.dataStores.currentDataStoreId;

export const getCurrentDataStore = state =>
  state.destinations.dataStores.currentDataStore;

export const getImportDataStoreTempData = state =>
  state.destinations.dataStores.importDataStoreResponse
    ? {
        ...state.destinations.dataStores.importDataStoreResponse,
        ...state.destinations.dataStores.importDataStoreResponse
          .datastore_properties,
        ...state.destinations.dataStores.importDataStoreResponse
          .datastore_properties.deduplication
      }
    : {};

export const getImportDataStoreTempFullData = state =>
  state.destinations.dataStores.importDataStoreResponse
    ? {
        ...state.destinations.dataStores.importDataStoreResponse
      }
    : {};

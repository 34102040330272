import React from "react";
import PropTypes from "prop-types";
import ActionDropdown from "../action-dropdown/index";
import FormattedMessage from "../formatted-message";

/**
 * Re-usable component that enables additional column header
 * options for a specific column.
 */
class HeaderDropdown extends React.Component {
  /**
   * Default constructor
   * @param {*} props
   * @override
   */

  /**
   * Handle main rendering logic for the component.
   * @override
   */
  render() {
    return (
      <div
        className="header-dropdown-wrapper d-flex align-items-center"
        id="grid-header-dropdown-wrapper"
      >
        <div className="header-dropdown-title">
          <FormattedMessage
            id={this.props.displayIdentifier}
            defaultMessage={this.props.defaultDisplayMessage}
          />
        </div>
        <ActionDropdown
          onMenuItemClick={this.props.onMenuItemClick}
          options={this.props.options}
        />
      </div>
    );
  }
}

HeaderDropdown.defaultProps = {
  defaultDisplayMessage: "",
  options: [],
  onMenuItemClick: () => {}
};

HeaderDropdown.propTypes = {
  displayIdentifier: PropTypes.string.isRequired,
  defaultDisplayMessage: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onMenuItemClick: PropTypes.func
};

export default HeaderDropdown;

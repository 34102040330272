import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormattedBrandMessage from "components/common/FormattedBrandMessage";
import Icon from "../../../assets/icons/icons";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import MuiDialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconMUI from "components/common/icon/IconMUI";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { ID_PREFIX } from "configs/IdConfig";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide id="transition-slide" direction="up" ref={ref} {...props} />;
});

const DialogTitle = props => {
  const { type, children, classes, onClose, id, ...other } = props;
  return (
    <MuiDialogTitle
      id={id ? `${id}_container` : "customized-dialog-title"}
      className={`spog-modal-title 
        ${
          type === "danger" || type === "error"
            ? "spog-modal-title-danger"
            : " "
        } 
        ${type === "information" ? "spog-modal-title-information" : " "} 
        ${type === "warning" ? "spog-modal-title-warning" : " "}`}
      {...other}
    >
      <Typography id={id} className="spog-modal-title-font">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          id={`${id}_close_button`}
          aria-label="close"
          className="spog-modal-close-button"
          sx={{ color: theme => theme.palette.grey[500] }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const IconMapping = {
  alert: { icon: WarningRoundedIcon, color: "error" },
  question: { icon: HelpOutlineOutlinedIcon, color: "disabled" }
};

const SpogModal = ({
  handleCloseModalEvent,
  onClose,
  className,
  id,
  testId,
  show,
  size,
  type,
  icon,
  title,
  children,
  footer,
  hideTitle,
  footerStyle,
  customDialogContentStyle
}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [maxWidth, setMaxWidth] = useState(false);
  const theme = useSelector(state => state.reducer.theme);

  useEffect(() => {
    !size && setFullScreen(true);
    size &&
      (size === "xs" || size === "extrasmall"
        ? setMaxWidth("xs")
        : size === "sm" || size === "small"
        ? setMaxWidth("sm")
        : size === "md" || size === "medium"
        ? setMaxWidth("md")
        : size === "lg" || size === "large"
        ? setMaxWidth("lg")
        : (size === "xl" || size === "extralarge") && setMaxWidth("xl"));
  }, [size]);

  let modalId = testId ? `${ID_PREFIX}${testId}` : id ? id : "";

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={maxWidth}
      open={show}
      onClose={onClose}
      TransitionComponent={Transition}
      aria-labelledby={
        modalId ? `${modalId}_title_container` : "customized-dialog-title"
      } //Check and remove this when MUI is upgraded to higher version
      className={className}
      id={modalId}
      PaperProps={{
        elevation: 0,
        sx: {
          border: theme => {
            const mode = theme?.palette?.mode || "light";
            if (mode === "dark") {
              return "1px solid rgba(255,255,255,0.2)";
            }
          }
        }
      }}
    >
      {!hideTitle ? (
        <DialogTitle
          id={modalId ? `${modalId}_title` : "customized-dialog-title"}
          onClose={handleCloseModalEvent ? handleCloseModalEvent : onClose}
          type={type}
        >
          {icon &&
            (IconMapping[icon.type] ? (
              <IconMUI
                id={
                  modalId
                    ? `${modalId}_title_icon`
                    : "customized-dialog-title-icon"
                }
                style={{
                  height: "30px",
                  width: "30px"
                }}
                icon={IconMapping[icon.type]["icon"]}
                color={IconMapping[icon.type]["color"]}
              />
            ) : (
              <Icon
                id={
                  modalId
                    ? `${modalId}_title_icon`
                    : "customized-dialog-title-icon"
                }
                icon={icon.type}
                color={icon.color}
                width="27"
                height="20"
                viewBox={icon.viewBox}
              />
            ))}
          &nbsp;&nbsp;
          {typeof title === "string" && title !== "" ? (
            <FormattedBrandMessage id={title} defaultMessage={title} />
          ) : (
            title
          )}
        </DialogTitle>
      ) : null}
      <DialogContent
        id={modalId ? `${modalId}_content` : "customized-dialog-content"}
        dividers
        style={customDialogContentStyle}
        sx={{ padding: theme => (footer ? theme.spacing(2) : "0px") }}
        footer={footer}
      >
        {children}
      </DialogContent>
      {footer ? (
        <DialogActions
          id={modalId ? `${modalId}_actions` : "customized-dialog-actions"}
          style={{
            display: !footerStyle && "block"
          }}
          sx={{ padding: theme => theme.spacing(1), margin: 0 }}
        >
          {footer}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

SpogModal.defaultProps = {
  showFooter: true,
  hideClose: false
};

SpogModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.any.isRequired,
  showFooter: PropTypes.bool,
  footer: PropTypes.any,
  className: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string,
  hideClose: PropTypes.bool,
  type: PropTypes.string,
  icon: PropTypes.shape({
    type: PropTypes.string,
    color: PropTypes.string
  })
};

export default SpogModal;

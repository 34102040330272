/* eslint-disable import/prefer-default-export */
import * as DCs from "../../../../policy/create-policy/destinations/DestinationConstants";

export const RecoveryTypes = {
  NOT_SELECTED: "NOT_SELECTED",
  VIRTUAL_MACHINE: "VIRTUAL_MACHINE",
  VIRTUAL_DRIVE: "VIRTUAL_DRIVE",
  FILES_AND_FOLDERS: "FILES_AND_FOLDERS",
  SQL_DATABASES: "SQL_DATABASES",
  VOLUME: "VOLUME",
  ENTIRE_SYSTEM: "ENTIRE_SYSTEM",
  ORACLE_DATABASES: "ORACLE_DATABASES"
};

export const ORACLE_RESTORE_TYPES = {
  RESTORE_DATABASE: "database_restore",
  EXPORT_TO_DISK: "export_to_disk"
};
export const RecoveryTypeDetails = [
  {
    id: RecoveryTypes.VIRTUAL_MACHINE,
    show: true,
    disabled: false,
    title:
      "protect.destination.recovery.recovery_method.title.recover_virtual_machine",
    description:
      "protect.destination.recovery.recovery_method.description.recover_virtual_machine",
    icon: DCs.RECOVERY_METHOD_ICONS.machine,
    type: "agentless_vm"
  },
  {
    id: RecoveryTypes.VIRTUAL_DRIVE,
    show: true,
    disabled: false,
    title:
      "protect.destination.recovery.recovery_method.title.recover_via_virtual_drive",
    description:
      "protect.destination.recovery.recovery_method.description.recover_via_virtual_drive",
    icon: DCs.RECOVERY_METHOD_ICONS.drive
  },
  {
    id: RecoveryTypes.FILES_AND_FOLDERS,
    show: true,
    disabled: false,
    title:
      "protect.destination.recovery.recovery_method.title.recover_files_and_folders",
    description:
      "protect.destination.recovery.recovery_method.description.recover_files_and_folders",
    icon: DCs.RECOVERY_METHOD_ICONS.folder
  },
  {
    id: RecoveryTypes.SQL_DATABASES,
    show: true,
    disabled: false,
    title:
      "protect.destination.recovery.recovery_method.title.recover_sql_databases",
    description:
      "protect.destination.recovery.recovery_method.description.recover_sql_databases",
    icon: DCs.RECOVERY_METHOD_ICONS.db
  },
  {
    id: RecoveryTypes.VOLUME,
    show: true,
    disabled: false,
    title: "protect.destination.recovery.recovery_method.title.recover_volume",
    description:
      "protect.destination.recovery.recovery_method.description.recover_volume",
    icon: DCs.RECOVERY_METHOD_ICONS.drive
  },
  {
    id: RecoveryTypes.ORACLE_DATABASES,
    show: true,
    disabled: false,
    title: "protect.destination.recovery.recovery_method.title.recover_oracle",
    description:
      "protect.destination.recovery.recovery_method.description.recover_oracle",
    icon: DCs.RECOVERY_METHOD_ICONS.db
  }
  // {
  //   id: RecoveryTypes.ENTIRE_SYSTEM,
  //   show: true,
  //   disabled: true,
  //   title:
  //     "protect.destination.recovery.recovery_method.title.recover_entire_system",
  //   description:
  //     "protect.destination.recovery.recovery_method.description.recover_entire_system",
  //   icon: DCs.RECOVERY_METHOD_ICONS.drive
  // }
];

export const hypervVirtualDiskTypes = (intl = null) => [
  {
    key: "0",
    value: intl
      ? intl.formatMessage({
          id: "dynamically_expanding",
          defaultMessage: "Dynamically Expanding"
        })
      : "Dynamically Expanding"
  },
  {
    key: "1",
    value: intl
      ? intl.formatMessage({
          id: "fixed_size",
          defaultMessage: "Fixed Size"
        })
      : "Fixed Size"
  },
  {
    key: "2",
    value: intl
      ? intl.formatMessage({
          id: "fixed_size_quick",
          defaultMessage: "Fixed Size(Quick)"
        })
      : "Fixed Size(Quick)"
  },
  {
    key: "3",
    value: intl
      ? intl.formatMessage({
          id: "keep_same_source_disk",
          defaultMessage: "Keep same as source disk"
        })
      : "Keep same as source disk"
  }
];

export const vmWareVirtualDiskTypes = (intl = null) => [
  {
    key: "0",
    value: intl
      ? intl.formatMessage({
          id: "thick_lazy_zeroed",
          defaultMessage: "Thick Lazy Zeroed"
        })
      : "Thick Lazy Zeroed"
  },
  {
    key: "1",
    value: intl
      ? intl.formatMessage({
          id: "thin",
          defaultMessage: "Thin"
        })
      : "Thin"
  },
  {
    key: "2",
    value: intl
      ? intl.formatMessage({
          id: "thick_eager_zeroed",
          defaultMessage: "Thick Eager Zeroed"
        })
      : "Thick Eager Zeroed"
  }
];

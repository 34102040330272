import _ from "lodash";
import * as constant from "utils/appConstants";
import * as Util from "utils/SpogDataGridUtil";
import SourceConstants from "components/protect/source/SourceConstants";
import { recoveredResourcesAttributes } from "components/protect/recoveredResources/RecoveredResourcesConstant";
import {
  SHOW_SOURCE_LIST,
  SHOW_SOURCE_LOCAL_LIST,
  SHOW_DESTINATION_LIST,
  SHOW_POLICIES_LIST,
  SHOW_POLICIES_LOCAL_LIST,
  UPDATE_SAVED_SEARCH,
  UPDATE_SAVED_SEARCH_DATA,
  UPDATE_MANAGE_SAVE_SEARCH,
  INSERT_MANAGE_SAVE_SEARCH,
  CLEAR_STATE,
  SHOW_DESTINATION_OVERLAY,
  SHOW_POLICY_DETAILS,
  SHOW_SELECT_SOURCE_GRID_VIEW,
  CUSTOM_SORT,
  SHOW_DESTINATION_DETAILS,
  UPDATED_DESTINATION_DETAILS,
  SHOW_POLICY_NAME,
  UPDATE_DESTINATION_OVERLAY_DETAILS,
  SHOW_SCHEDULE_MODAL,
  SHOW_EVENT_DETAILS_OVERLAY,
  UPDATED_RECOVERY_DETAILS,
  FILTERED_RECOVERY_POINTS,
  REMOVE_DESTINATION,
  SHOW_HYPERVISOR_LIST,
  SHOW_VMS_LIST,
  SET_RPS_HASHMEMORY,
  SET_RPS_HASHMEMORY_ERROR,
  ADD_NEW_SOURCE,
  SHOW_SOURCE_DETAILS,
  GET_SOURCE_DETAILS,
  GET_DESTINATION_DETAILS,
  SHOW_DATA_CENTERS,
  UPDATE_RESOURCE_IN_GRID,
  UPDATE_ORACLE_HOSTS_IN_GRID,
  UPDATE_SOURCE_GROUP,
  UPDATED_DESTINATION_LIST,
  DELETED_DESTINATION_LIST,
  ADD_SOURCE_GROUP_IN_LIST,
  SHOW_HIDE_CREATE_SOURCE_GROUP,
  FILTERED_SOURCE_GROUP,
  DELETED_CUSTOMER_ACCOUNT_DATA,
  UPDATE_CUSTOMER_ACCOUNT_DETAILS,
  SHOW_RECOVERED_RESOURCES,
  SHOW_RESOURCE_DETAILS,
  SHOW_ADD_CUSTOMER_ACCOUNT_MODAL,
  UPDATE_ADDED_CUSTOMER_ACCOUNT_DATA,
  UPDATE_METRICS_CHECKBOX_FILTER,
  SHOW_SET_USAGE_THRESHOLD_MODAL,
  UPDATE_SET_USAGE_THRESHOLD_MODAL,
  LOAD_CLOUD_ACCOUNT_LIST_BY_RPS_ID,
  LOAD_END_POINT_LIST,
  SHOW_HIDE_ASSIGN_USERS_MODAL,
  UPDATE_ADMIN_DETAILS_TO_ASSIGN,
  SHOW_DESTINATION_LIST_MOCK,
  SHOW_PROTECT_CUSTOMER_LIST,
  EDIT_UPDATE_SOURCE_CONFIGURATION,
  UPDATE_ADMIN_DROPDOWN_OPTIONS,
  ADD_ADMIN_TO_SHOW_TAGS,
  REMOVE_ADMIN_ASSIGN_TAGS,
  UPDATE_ASSIGNED_ADMIN_USER_LIST,
  REMOVE_UPDATE_UNASSIGN_ADMIN_FROM_CUSTOMER,
  SHOW_CUSTOMER_ACCOUNTS_LIST,
  FILTER_POLICY_SCHEDULES,
  DELETE_POLICY_FROM_GRID,
  CLEAR_POLICY_DETAILS_STATE,
  REDIRECT_TO_POLICY_GRID,
  DELETE_UPDATE_SOURCE_GROUP,
  GET_RECOVERY_POINTS_DEATILS,
  UPDATED_RECOVERY_SUBCOMPONENT_DETAILS,
  CLEAR_SOURCE_GROUPS,
  UPDATE_SELECTED_RESOURCE,
  CLEAR_SELECTED_RESOURCE,
  SHOW_SELECTED_USAGE_THRESHOLD,
  UPDATE_FILE_BROWSER_DATA,
  UPDATE_FILE_BROWSER_GRID_DATA,
  UPDATE_FILE_BROWSER_GRID_PAGINATION_DATA,
  UPDATE_RECOVERY_POINT_DETAILS,
  // UPDATE_RESTORE_COOKIE_FLAG
  UPDATE_RECOVERED_VMS,
  UPDATE_MOUNTED_RPS,
  UPDATE_SELECTED_RECOVERED_VM,
  UPDATE_FILE_BROWSER_NODE_DATA,
  UPDATE_FILE_BROWSER_NODE_DATA_FOR_PAGINATION,
  UPDATE_CUSTOMERS_TO_ASSIGN,
  UPDATE_RECOVERY_IMAGE_FORMATS,
  UPDATE_PARTICULAR_RECOVERY_POINT_DETAILS_DATA,
  UPDATE_RECOVERY_TARGETS_DATA,
  UPDATE_SELECTED_RECOVERY_IMAGE_FORMAT_DATA,
  UPDATE_RESTORE_RECOVER_PATH,
  UPDATE_RESTORE_RECOVER_ZFT_DATA,
  SET_MACHINE_DETAILS,
  UPDATE_RECOVER_TARGET_SOURCE_ID,
  UPDATE_RECOVER_TARGET_VMWARE_DATASTORE_NAME,
  UPDATE_RECOVER_TARGET_VMWARE_VM_NAME,
  SWITCH_RECOVERY_POINTS_GROUP,
  SWITCH_RECOVERY_TARGET_MACHINE,
  CLEAR_RECOVERY_POINTS_STATE,
  UPDATE_DESTINATION_CD,
  SET_SELECTED_DATES,
  UPDATE_CUSTOMER_ACCOUNT_CD,
  CLEAR_SELECTED_DATES,
  CLEAR_SELECTED_DATES_CH,
  CLEAR_SELECTED_DATES_CU,
  UPDATE_ASSIGN_POLICY_SOURCE,
  UPDATE_SELECTED_ASSIGN_POLICY_SOURCE,
  SET_RETENTION_DURATION,
  SET_SELECTED_HYPERVISOR,
  UPDATE_NEW_TREE_BROWSER_PATH,
  UNMOUNT_FILE_BROWSER_DATA,
  UPDATE_DESTINATION_CH,
  UPDATE_CUSTOMER_ACCOUNT_CH,
  SHOW_HIDE_PROTECTED_PASSWORD_FORM,
  POST_PROTECTED_PASSWORD_DATA,
  UPDATE_RESTORE_RECOVER_SOURCE_TO_PATH,
  SET_SELECTED_DATES_CH,
  SET_SELECTED_DATES_CU,
  DELETE_SOURCE_FROM_GRID,
  DELETE_ORACLE_HOSTS_FROM_GRID,
  SHOW_SELECTED_SOURCE_DETAILS,
  UPDATE_LICENSES,
  UPDATE_SELECTED_POLICY_TYPE,
  UPDATE_RECOVERED_VMS_STATE,
  UPDATE_REMOTE_POLICIES,
  UPDATE_REMOTE_POLICIES_ERROR,
  CLEAR_REMOTE_POLICIES,
  CLEAR_REMOTE_POLICIES_ERROR,
  DELETE_RECOVERED_VM_FROM_GRID,
  GET_SITE_NAME,
  SET_SELECTED_SITE,
  ERROR_MESSAGE_CONNECT,
  GET_DISCOVER_SOURCE_NAME,
  ADD_NEW_SITE,
  SWITCH_ADD_SITE_TAB,
  RESTORE_NEW_SITE_VALUE,
  UPDATE_ADD_CLOUD_ACCOUNT_FLAG,
  UPDATE_SOURCE_DETAILS_IN_STORE,
  LOAD_REGISTERED_SITES_SUCCESS,
  LOAD_BACKUP_PROXY_SUCCESS,
  FETCH_DESTINATION_PATH_REQUEST,
  FETCH_DESTINATION_PATH_SUCCESS,
  FETCH_DESTINATION_PATH_FAILURE,
  SET_SHARED_FOLDER_ID,
  LOAD_LBS_SUCCESS,
  LOAD_LINUX_LBS_SUCCESS,
  LOAD_LINUX_SUCCESS,
  ADD_BACKUP_PROXY_FLAG,
  ADD_LINUX_BACKUP_FLAG,
  GET_SELECTED_PROXY,
  GET_SELECTED_LBS,
  LOAD_RECOVERY_POINT_SERVERS_SUCCESS,
  LOAD_RPS_DATA_STORES_SUCCESS,
  LOAD_BACKUP_TRAFFIC_USING_RPS_SUCCESS,
  CLEAR_BACKUP_TRAFFIC_USING_RPS,
  DESTROY_POLICY,
  CREATING_NEW_POLICY,
  FETCH_REMOTE_POLICIES_SUCCESSFUL,
  GET_SHARED_POLICY_DETAILS_SUCCESS,
  FETCH_SELECTED_NETWORK_BACKUP_PROXY_SUCCESS,
  LOAD_SELECTED_LBS_NETWORK_SUCCESS,
  CLEAR_SELECTED_NETWORK_BACKUP_PROXY,
  CLEAR_SHARED_POLICY_DETAILS,
  LIST_HYPERVISORS_SUCCESS,
  GET_ALL_HYPERVISORS_SUCCESS,
  GET_ALL_PROXIES_FOR_POLICY_SUCCESS,
  GET_HYPERVISOR_CONFIGURATION_SUCCESS,
  SWITCH_ADD_IVM_TAB,
  RESTORE_NEW_IVM_VALUE,
  UPDATE_RECOVERED_RESOURCES,
  GET_PENDING_SESSIONS,
  FnF_LINUX_FETCH_SCRIPTS,
  GET_STANDBY_VM_SNAPSHOT,
  GET_IS_NETWORK_CONFIG,
  UPDATE_INSTANT_VMS,
  MOUNTPOINT_MODAL,
  STORE_MOUNTPOINT_INFO,
  GET_NETWORK_DATA,
  SET_BACKUP_PASSWORDS_DATA,
  LOAD_DNS_DATA,
  SET_INITIAL_PATH,
  SET_ORGANIZATION_ID_FOR_SOURCE_CSR,
  GET_HYPERV_OF_TYPE_SUCCESS,
  GET_HYPERV_OF_TYPE_FAILED,
  RESET_HYPERV_LIST,
  SET_CONTAINER_POLICIES_DATA,
  SET_RPS_HASHMEMORY_LOADER,
  GET_REGIONS_FOR_CLOUD_ACCOUNT_SUCCESS_V3,
  CLEAR_CLOUD_ACCOUNTS_REGIONS_V3,
  GET_BUCKETS_FOR_CLOUD_ACCOUNT_SUCCESS,
  CLEAR_CLOUD_ACCOUNTS_BUCKETS,
  CREATE_VMWARE_FOR_POLICY_SUCCESS,
  CLEAR_VMWARE_FOR_POLICY,
  CREATE_HYPERV_FOR_POLICY_SUCCESS,
  CLEAR_HYPERV_FOR_POLICY,
  GET_TRANSACTION_DETAILS_SUCCESS,
  SOURCE_ART_DATA_GET_SUCCESS,
  GET_SOURCE_DATA_BY_ID,
  CLEAR_SOURCE_ART_LOADER,
  CLEAR_SOURCE_ART_DETAILS,
  CLEAR_HYPERVISOR_CONFIGURATION_DATA,
  HYPERV_TYPE_AR_CHANGED,
  RESET_HYPERV_TYPE_AR_CHANGED_VAL,
  SHOW_ORACLE_SOURCES,
  CLEAR_ORACLE_SOURCES,
  ADDED_ORACLE_DB_LIST,
  CLEAR_ORACLE_DB_LIST,
  CLEAR_BACKUP_PASSWORDS
} from "../actions/actionTypes";
import { POLICY_TYPES } from "../../components/protect/policy/create-policy/destinations/DestinationConstants";

export const initialState = {
  // localData: {
  //   data: [],
  //   pagination: {
  //     curr_page: 1,
  //     total_page: 1,
  //     page_size: 50,
  //     has_prev: false,
  //     has_next: true,
  //     total_size: 50
  //   }
  // },
  data: {
    data: [],
    pagination: {
      curr_page: 1,
      total_page: 1,
      page_size: 25,
      has_prev: false,
      has_next: true,
      total_size: 25
    }
  },
  localData: {
    data: [],
    pagination: {
      curr_page: 1,
      total_page: 1,
      page_size: 25,
      has_prev: false,
      has_next: true,
      total_size: 25
    }
  },
  oracleSource: {
    data: [],
    pagination: {
      curr_page: 1,
      total_page: 1,
      page_size: 25,
      has_prev: false,
      has_next: true,
      total_size: 25
    }
  },
  containerPolicies: [],
  loadCloudAccByRPSData: "",
  loadEndPointsListData: "",
  allSourceGroups: {
    data: []
  },
  initialPath: "",
  sites: [],
  registeredSites: [],
  addNewSite: {
    tabIndex: 1,
    siteName: "",
    gatewayAuthorizationCode: ""
  },
  sourceGroups: [],
  sources: {},
  createNewpolicy: false,
  showDestinationOverlay: false,
  showSelectSourceGridView: false,
  showScheduleModal: false,
  showEventOverlay: false,
  hashMemoryResponse: {},
  hashMemoryLoader: false,
  hashMemoryError: false,
  editDropdowns: {
    retentionDropdown: [
      "7D",
      "14D",
      "1M",
      "2M",
      "3M",
      "6M",
      "1Y",
      "2Y",
      "3Y",
      "7Y",
      "10Y",
      "forever",
      "Custom"
    ],
    blockSize: ["16 KB", "40 KB", "200 KB", "30 KB"],
    rpsNames: ["RPS_Name"],
    dataStores: ["Store_Name"],
    imageFormats: [".jpeg", ".png"]
  },
  dataCenters: [],
  showAddCustomerAccountModal: false,
  showSetUsageThresholdModal: false,
  showHideAssignUserModal: false,
  customerCDUsageFilter: {
    checkbox: [],
    date: "1Y"
  },
  customerCHUsageFilter: {
    checkbox: [],
    date: "1Y"
  },
  customerCapacityUsageFilter: {
    checkbox: [],
    date: "1Y"
  },
  redirectToPolicy: false,
  adminDataToAssign: { options: [], selectedOptions: [] },
  showTagsForAddedAssignee: [],
  protectCustomerAccounts: [],
  recoveryPoints: [],
  recoveryPointDetails: [],
  transactionDetails: [],
  threshold: {
    cd_threshold_usage: 0,
    ch_threshold_usage: 0,
    cd_threshold_unit: "TB",
    ch_threshold_unit: "TB"
  },
  fileBrowsertreeData: [],
  customerAccountCH: [],
  customerAccountCD: [],
  fileBrowsergridData: [],
  fileBrowsergridPaginationData: {},
  destinationData: {},
  sourceData: {
    data: {}
  },
  selectedFileBrowserNodeId: "",
  selectedFileBrowserUrl: "",
  fileBrowserUrlCurrentVolGuid: "",
  fileBrowserUrlCurrentFullPath: "",
  fileBrowserUrlCurrentDisplayFullPath: "",
  recoveryImageFormats: [],
  recoveryPointsDetailsdata: {},
  selectedRecoverImageFormat: "",
  remotePoliciesDropdown: [],
  restoreRecoverPath: "",
  restoreRecoverSnapshot: "",
  restoreRecoverHost: "",
  targetSourceId: "",
  recoveryPointsGroup: "false",
  recoveryTargetSwitch: "another",
  fileBrowserTreePaths: [],
  showProtectedPasswordModal: false,
  chProtectedPassword: "",
  selectedPolicyForAssignPolicy: {
    key: "",
    value: ""
  },
  destinationCD: {},
  destinationCH: {},
  customerGridData: {
    data: [],
    pagination: {
      curr_page: 1,
      total_page: 1,
      page_size: 25,
      has_prev: false,
      has_next: true,
      total_size: 25
    }
  },
  customerData: {},
  remotePolicies: [],
  remotePolicyError: null,
  rpError: false,
  showAddCloudAccountModal: false,
  showAddBackupProxyModal: false,
  showAddLinuxBackupModal: false,
  getProxyDetail: [],
  getLBSDetail: [],
  recoveryPointServers: [],
  backupProxyList: [],
  lbsList: [],
  selectedNetworkBackupTraffic: [],
  selectedLBSNetworks: [],
  rpsDataStores: [],
  networkBackupTrafficRPS: [],
  policyHypervisorList: [],
  policyProxiesList: [],
  allProxiesList: [],
  excludeFolderFlag: false,
  sharedEmail: [],
  createNewIVM: {
    tabIndex: 1
  },
  mountedRecoveryPoints: [],
  mountPointInfo: {},
  sourceHypervisorList: [],
  sharedFolderId: null,
  destinationPath: [],
  getLinuxScripts: "",
  vmwareForPolicy: [],
  hypervForPolicy: [],
  addedOracleDBlist: []
};

const protectReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_RESOURCE:
      return { ...state, selectedResource: action.data };
    case CLEAR_SELECTED_RESOURCE:
      return { ...state, selectedResource: null };
    case SHOW_VMS_LIST:
      return { ...state, dataVMs: action.data };
    case SHOW_HYPERVISOR_LIST:
      return { ...state, dataHypervisor: action.data };
    case LIST_HYPERVISORS_SUCCESS:
      return { ...state, hypervisorsList: action.data };
    case CUSTOM_SORT:
      return {
        ...state,
        data: { data: action.data }
      };
    case CLEAR_STATE:
      return {
        ...state,
        data: { data: [] },
        oracleSource: { data: [] },
        dataVMs: {},
        dataHypervisor: [],
        hypervisorsList: [],
        policyProxiesList: [],
        selectedSavedSearch: {},
        selectedSavedSearchId: "",
        selectedSavedSearchName: "",
        selectedHypervisor: null,
        hypervisorConfigDetail: null,
        hypervTypeChanged: false,
        policyHypervisorList: [],
        sourceHypervisorList: [],
        customerGridData: {
          data: [],
          pagination: {
            curr_page: 1,
            total_page: 1,
            page_size: 25,
            has_prev: false,
            has_next: true,
            total_size: 25
          }
        },
        recoveredResources: [],
        recoveredResourcesPagination: [],
        recoveredVMS: [],
        instantVMs: [],
        instantVMsPagination: []
      };
    // Set the requesting flag and append a message to be shown
    case SHOW_SOURCE_LIST:
      return { ...state, data: action.data };
    case SHOW_SOURCE_LOCAL_LIST:
      return { ...state, localData: action.data };
    case SHOW_ORACLE_SOURCES:
      return { ...state, oracleSource: action.data };
    case CLEAR_ORACLE_SOURCES:
      return { ...state, oracleSource: { data: [] } };
    case SHOW_SOURCE_DETAILS:
      return {
        ...state,
        sourceData: action.data.details,
        jobsList: action.data.jobsList,
        logList: action.data.logList,
        recoveryPoints: action.data.recoveryPoints
      };

    case CREATING_NEW_POLICY: {
      return {
        ...state,
        createNewpolicy: action.createNewpolicy
      };
    }
    case SHOW_SELECTED_SOURCE_DETAILS:
      return {
        ...state,
        sourceData: action.data.details
      };
    case SET_INITIAL_PATH:
      return {
        ...state,
        initialPath: action.data
      };
    case GET_SOURCE_DETAILS:
      return {
        ...state,
        sourceData: undefined,
        jobsList: [],
        logList: [],
        recoveryPoints: []
      };

    case UPDATE_ADD_CLOUD_ACCOUNT_FLAG: {
      return {
        ...state,
        showAddCloudAccountModal: action.data
      };
    }

    case SHOW_POLICIES_LIST:
      return { ...state, data: action.data, policyDetails: {} };

    case SHOW_POLICIES_LOCAL_LIST:
      return { ...state, localData: action.data, policyDetails: {} };

    case REDIRECT_TO_POLICY_GRID: {
      return { ...state, redirectToPolicy: true };
    }

    case SET_SELECTED_HYPERVISOR: {
      return { ...state, selectedHypervisor: action.data };
    }

    case DELETE_POLICY_FROM_GRID: {
      let policies = state.data;

      let updatedRecords = policies.data.filter(
        item => item.policy_id !== action.id
      );
      policies.pagination.total_size--;

      let policiesLocalData = state.localData;
      let updatedLocalData = policiesLocalData.data.filter(
        item => item.policy_id !== action.id
      );
      policiesLocalData.pagination.total_size--;
      return {
        ...state,
        data: {
          data: updatedRecords,
          pagination: policies.pagination
        },
        localData: {
          status: 200,
          data: updatedLocalData,
          pagination: policiesLocalData.pagination,
          errors: []
        }
      };
    }

    case DELETE_SOURCE_FROM_GRID: {
      let sources = state.data;

      let updatedRecords = sources.data.filter(
        item =>
          item[SourceConstants.SourceAttributes.SOURCE_PROP_ID] !== action.id
      );
      sources.pagination.total_size--;

      return {
        ...state,
        data: {
          data: updatedRecords,
          pagination: sources.pagination
        }
      };
    }

    case DELETE_ORACLE_HOSTS_FROM_GRID: {
      let sources = state.oracleSource;

      let updatedRecords = sources.data.filter(
        item =>
          item[SourceConstants.SourceAttributes.SOURCE_PROP_ID] !== action.id
      );
      sources.pagination.total_size--;

      return {
        ...state,
        oracleSource: {
          data: updatedRecords,
          pagination: sources.pagination
        }
      };
    }

    case DELETE_RECOVERED_VM_FROM_GRID: {
      let recoveredVMs = state.recoveredVMS;
      let updatedRecords = recoveredVMs.data.filter(
        item => item[recoveredResourcesAttributes.ID] !== action.data
      );
      recoveredVMs.pagination.total_size--;

      return {
        ...state,
        recoveredVMS: {
          data: updatedRecords,
          pagination: recoveredVMs.pagination
        }
      };
    }
    case SHOW_DESTINATION_LIST: {
      return { ...state, data: action.data };
    }
    case GET_DESTINATION_DETAILS: {
      return { ...state, destinationData: undefined };
    }
    case SHOW_DESTINATION_DETAILS:
      return {
        ...state,
        destinationData: action.data.details,
        recoveryPoints: action.data.recoveryPoints
      };
    case SHOW_RESOURCE_DETAILS:
      return {
        ...state,
        recoveryPoints: action.data.recoveryPoints
      };
    case UPDATED_DESTINATION_DETAILS:
      return {
        ...state,
        destinationData: updateObject(state.destinationData, action.data)
      };
    case UPDATED_RECOVERY_DETAILS:
      return {
        ...state,
        recoveryPoints: updatePinUnpinInRecoveryPoints(
          state.recoveryPoints,
          action.data
        )
      };
    case UPDATE_RESOURCE_IN_GRID: {
      let rowData = _.cloneDeep(state.data);
      rowData.data = updateObjectInArray(rowData.data, action.data, action.key);
      return {
        ...state,
        data: rowData
      };
    }
    case UPDATE_ORACLE_HOSTS_IN_GRID: {
      let rowData = _.cloneDeep(state.oracleSource);
      rowData.data = updateObjectInArray(rowData.data, action.data, action.key);
      return {
        ...state,
        oracleSource: rowData
      };
    }
    case FILTERED_RECOVERY_POINTS:
      return {
        ...state,
        recoveryPoints: action.data
      };

    case SHOW_DESTINATION_OVERLAY: {
      return {
        ...state,
        showDestinationOverlay: action.data.show,
        getDestinationOverlayDetails: action.data.obj
      };
    }
    case UPDATE_DESTINATION_OVERLAY_DETAILS: {
      return {
        ...state,
        policyDetails: updateObjectInDestinationArray(
          state.policyDetails,
          action.data
        )
      };
    }

    case SHOW_SCHEDULE_MODAL: {
      return {
        ...state,
        showScheduleModal: action.data.show,
        modalData: action.data.obj
      };
    }
    case SHOW_EVENT_DETAILS_OVERLAY: {
      return {
        ...state,
        showEventOverlay: !state.showEventOverlay
      };
    }

    case REMOVE_DESTINATION: {
      return {
        ...state,
        policyDetails: removeObjectInDestinationArray(
          state.policyDetails,
          action.data
        )
      };
    }

    case UPDATE_SAVED_SEARCH:
      return { ...state, userSearchData: filterSavedSearchData(action.data) };

    /**
     * update saved search data after a saved search is updated at back end
     * this will update the saved search list
     */
    case UPDATE_SAVED_SEARCH_DATA:
      return {
        ...state,
        userSearchData: updateSavedSearch(state.userSearchData, action.data)
      };

    /**
     * update the selected saved search after an edit/PUT request is successful
     */
    case UPDATE_MANAGE_SAVE_SEARCH: {
      return {
        ...state,
        selectedSavedSearch: { ...action.data }
      };
    }

    case INSERT_MANAGE_SAVE_SEARCH: {
      return {
        ...state,
        userSearchData: addObjectInArray(state.userSearchData, action.data)
      };
    }
    case SHOW_POLICY_DETAILS: {
      let policyDetails = action.data.policydetails;

      let destinationFilters = _.uniq(
        _.map(policyDetails.schedules, "destination_name")
      );

      return {
        ...state,
        showPolicyNameTitle: policyDetails.policy_name,
        policyDetails,
        basic: action.data.basic,
        dropdownOptions: { ...action.data.dropdownOptions, destinationFilters },
        desitnationDropdownData: action.data.desitnationDropdownData
      };
    }
    case CLEAR_POLICY_DETAILS_STATE: {
      return {
        ...state,
        showPolicyNameTitle: undefined,
        policyDetails: undefined,
        basic: undefined,
        dropdownOptions: undefined,
        redirectToPolicy: undefined
      };
    }

    case FILTER_POLICY_SCHEDULES: {
      return {
        ...state,
        scheduleFilter: action.payload.selected
      };
    }

    case SHOW_SELECT_SOURCE_GRID_VIEW: {
      return {
        ...state,
        showSelectSourceGridView: action.data
      };
    }
    case SHOW_POLICY_NAME: {
      return {
        ...state,
        showPolicyNameTitle: action.data
      };
    }

    case SET_CONTAINER_POLICIES_DATA: {
      return {
        ...state,
        containerPolicies: action.data
      };
    }

    case UPDATE_SOURCE_GROUP: {
      return {
        ...state,
        allSourceGroups: { ...action.data },
        sourceGroups: [...action.data.data]
      };
    }

    case UPDATE_ADDED_CUSTOMER_ACCOUNT_DATA: {
      return {
        ...state,
        data: { data: insertItem(state.data.data, action.data.data) }
      };
    }

    case LOAD_CLOUD_ACCOUNT_LIST_BY_RPS_ID: {
      return {
        ...state,
        loadCloudAccByRPSData: { ...action.data }
      };
    }

    case LOAD_END_POINT_LIST: {
      return {
        ...state,
        loadEndPointsListData: { ...action.data }
      };
    }

    case ADD_SOURCE_GROUP_IN_LIST: {
      let allSourceGroups = JSON.parse(
        JSON.stringify(Object.assign({}, state.allSourceGroups))
      );
      allSourceGroups.data = allSourceGroups.data || [];
      if (action.checkPagination) {
        if (
          allSourceGroups.data.length < allSourceGroups.pagination.page_size
        ) {
          allSourceGroups.data.push(action.data);
        }
      } else {
        allSourceGroups.data.push(action.data);
      }
      allSourceGroups.pagination.total_size += 1;
      return {
        ...state,
        allSourceGroups,
        sourceGroups: allSourceGroups.data
      };
    }
    case FILTERED_SOURCE_GROUP: {
      return {
        ...state,
        sourceGroups: action.data
      };
    }

    case SHOW_SET_USAGE_THRESHOLD_MODAL:
      return {
        ...state,
        showSetUsageThresholdModal: action.data
      };

    case SHOW_PROTECT_CUSTOMER_LIST:
      return {
        ...state,
        protectCustomerAccounts: action.payload
      };

    case SHOW_HIDE_CREATE_SOURCE_GROUP: {
      return {
        ...state,
        showCreateSourceGroup: action.data
      };
    }

    case ADD_NEW_SOURCE: {
      return {
        ...state
      };
    }
    case SHOW_DATA_CENTERS: {
      return {
        ...state,
        dataCenters: action.data
      };
    }
    case UPDATED_DESTINATION_LIST:
      return {
        ...state,
        data: addDestination(state.data, action.data)
      };
    case DELETED_DESTINATION_LIST:
      return {
        ...state,
        data: removeDestination(state.data, action.data)
      };
    case SHOW_RECOVERED_RESOURCES:
      return {
        ...state,
        data: action.data
      };

    case DELETED_CUSTOMER_ACCOUNT_DATA:
      return {
        ...state,
        data: removeItemByTypeKey(
          state.data,
          action.data.data,
          action.data.keyType
        )
      };

    case UPDATE_CUSTOMER_ACCOUNT_DETAILS:
      return {
        ...state,
        customerData: action.data
      };

    case SHOW_ADD_CUSTOMER_ACCOUNT_MODAL:
      return {
        ...state,
        showAddCustomerAccountModal: action.data
      };

    case UPDATE_METRICS_CHECKBOX_FILTER: {
      let cdData, chData, cuData;
      if (action.data.filterType === "cloud_direct") {
        cdData = action.data;
        chData = state.customerCHUsageFilter;
        cuData = state.customerCapacityUsageFilter;
      } else if (action.data.filterType === "cloud_hybrid") {
        cdData = state.customerCDUsageFilter;
        chData = action.data;
        cuData = state.customerCapacityUsageFilter;
      } else if (action.data.filterType === "capacity_usage") {
        cdData = state.customerCDUsageFilter;
        chData = state.customerCHUsageFilter;
        cuData = action.data;
      }
      return {
        ...state,
        customerCDUsageFilter: cdData,
        customerCHUsageFilter: chData,
        customerCapacityUsageFilter: cuData
      };
    }

    case SHOW_DESTINATION_LIST_MOCK:
      return {
        ...state,
        destinationMock: action.payload
      };

    case SHOW_HIDE_ASSIGN_USERS_MODAL:
      return {
        ...state,
        showHideAssignUserModal: action.data,
        adminDataToAssign: { options: [], selectedOptions: [] },
        showTagsForAddedAssignee: []
      };

    case UPDATE_ADMIN_DETAILS_TO_ASSIGN: {
      let adminUsers = action.data;
      let adminDataSet = { options: [], selectedOptions: [] };
      //eslint-disable-next-line array-callback-return
      adminUsers.map(userDetails => {
        adminDataSet.options.push({
          userDetails,
          firstName: userDetails.first_name,
          lastName: userDetails.last_name,
          email: userDetails.email,
          display: `${userDetails.first_name} ${userDetails.last_name} <${userDetails.email}>`,
          label: `${userDetails.first_name} ${userDetails.last_name} <${userDetails.email}>`,
          value: `${userDetails.first_name} ${userDetails.last_name} <${userDetails.user_id}>`,
          id: userDetails.user_id,
          user_id: userDetails.user_id
        });
      });
      return {
        ...state,
        adminDataToAssign: adminDataSet
      };
    }

    case FETCH_REMOTE_POLICIES_SUCCESSFUL: {
      return {
        ...state,
        remotePoliciesDropdown: action.payload
      };
    }

    case UPDATE_ADMIN_DROPDOWN_OPTIONS: {
      return {
        ...state,
        adminDataToAssign: {
          options: state.adminDataToAssign.options,
          selectedOptions: updateSelectedAdminInArray(
            state.adminDataToAssign.options,
            action
          )
        }
      };
    }
    case ADD_ADMIN_TO_SHOW_TAGS: {
      let selectedOptions = JSON.parse(
        JSON.stringify(
          Object.assign([], state.adminDataToAssign.selectedOptions)
        )
      );
      return {
        ...state,
        showTagsForAddedAssignee: selectedOptions.concat(
          state.showTagsForAddedAssignee
        ),
        adminDataToAssign: {
          options: state.adminDataToAssign.options,
          selectedOptions: []
        }
      };
    }

    /**
     * update the customer account -> usage data after usage threshold has been added in the back end
     */
    case UPDATE_SET_USAGE_THRESHOLD_MODAL: {
      return {
        ...state,
        data: updateCustomerAccount(state.data, action)
      };
    }

    case REMOVE_ADMIN_ASSIGN_TAGS: {
      return {
        ...state,
        showTagsForAddedAssignee: state.showTagsForAddedAssignee.filter(
          item => item.value !== action.data
        )
      };
    }

    case UPDATE_ASSIGNED_ADMIN_USER_LIST: {
      return {
        ...state,
        protectCustomerAccounts: addObjectInArray(
          state.protectCustomerAccounts,
          action.data
        ),
        adminDataToAssign: { options: [], selectedOptions: [] },
        showTagsForAddedAssignee: []
      };
    }

    case REMOVE_UPDATE_UNASSIGN_ADMIN_FROM_CUSTOMER:
      return {
        ...state,
        protectCustomerAccounts: removeItemFromArrayWithoutData(
          state.protectCustomerAccounts,
          action.data.data.id,
          action.data.keyType
        ).data
      };
    case SHOW_CUSTOMER_ACCOUNTS_LIST:
      return { ...state, customerGridData: action.data };

    /**
     * update the customer account -> usage data after usage threshold has been added in the back end
     */
    case EDIT_UPDATE_SOURCE_CONFIGURATION: {
      return {
        ...state,
        sourceConfiguration: action.data
      };
    }

    /**
     * update the source groups after deleting one in the back end
     */
    case DELETE_UPDATE_SOURCE_GROUP: {
      return {
        ...state,
        sourceGroups: removeItemByKey(
          state.sourceGroups,
          action.id,
          "group_id"
        ),
        allSourceGroups: {
          data: removeItemByKey(
            state.allSourceGroups.data,
            action.id,
            "group_id"
          ),
          pagination: {
            ...state.allSourceGroups.pagination,
            total_size: state.allSourceGroups.pagination.total_size - 1
          }
        }
      };
    }
    /**
     * update hyperv dropdown when add source from hypervisor
     */
    case GET_HYPERV_OF_TYPE_SUCCESS: {
      return {
        ...state,
        sourceHypervisorList: action.data
      };
    }

    case GET_HYPERV_OF_TYPE_FAILED: {
      return {
        ...state,
        sourceHypervisorList: []
      };
    }
    case RESET_HYPERV_LIST: {
      return {
        ...state,
        sourceHypervisorList: []
      };
    }
    /**
     * update the details
     */
    case GET_RECOVERY_POINTS_DEATILS: {
      let filterData = updateRecoveryPointDetails(
        state.recoveryPoints,
        action.data.id.id
      );
      return {
        ...state,
        recoveryPointDetails: (filterData || {}).data
      };
    }

    case UPDATED_RECOVERY_SUBCOMPONENT_DETAILS:
      return {
        ...state,
        recoveryPointDetails: updatePinUnpinInRecoveryPoints(
          state.recoveryPointDetails,
          action.data
        )
      };

    case CLEAR_SOURCE_GROUPS: {
      return {
        ...state,
        allSourceGroups: { data: [] },
        sourceGroups: []
      };
    }

    /**
     * set threshold to show selected
     */
    case SHOW_SELECTED_USAGE_THRESHOLD: {
      return {
        ...state,
        threshold: action.data
      };
    }

    /**
     * update recovered vms
     */
    case UPDATE_RECOVERED_VMS: {
      return {
        ...state,
        recoveredVMS: action.data
      };
    }
    /**
     * update mounted rps
     */
    case UPDATE_MOUNTED_RPS: {
      return {
        ...state,
        mountedRecoveryPoints: action.data
      };
    }
    /**
     * set tree data for file browser data
     */
    case UPDATE_NEW_TREE_BROWSER_PATH: {
      return {
        ...state,
        fileBrowserTreePaths: state.fileBrowserTreePaths.concat(action.data)
      };
    }

    /**
     * unmount file browser data
     */
    case UNMOUNT_FILE_BROWSER_DATA:
      return {
        ...state,
        selectedFileBrowserNodeId: "",
        selectedFileBrowserUrl: "",
        fileBrowserTreePaths: [],
        fileBrowsertreeData: [],
        fileBrowsergridData: [],
        chProtectedPassword: "",
        fileBrowsergridPaginationData: {},
        fileBrowserUrlCurrentVolGuid: "",
        fileBrowserUrlCurrentFullPath: "",
        fileBrowserUrlCurrentDisplayFullPath: ""
      };
    /**
     * set tree data for file browser data
     */
    case UPDATE_FILE_BROWSER_DATA: {
      let actionData = action.data;
      return {
        ...state,
        fileBrowsertreeData: actionData
      };
    }

    /**
     * update selected recovered vm
     */
    case UPDATE_SELECTED_RECOVERED_VM: {
      return {
        ...state,
        selectedRecoveredVM: action.data
      };
    }

    /**
     * set grid data for file browser data
     */
    case UPDATE_FILE_BROWSER_GRID_DATA: {
      return {
        ...state,
        fileBrowsergridData: action.data
      };
    }
    /**
     * set grid data for file browser data
     */
    case UPDATE_FILE_BROWSER_GRID_PAGINATION_DATA: {
      return {
        ...state,
        fileBrowsergridPaginationData: action.data
      };
    }

    /**
     * update recovery point details
     */
    case UPDATE_RECOVERY_POINT_DETAILS: {
      return {
        ...state,
        recoveryPointDetails: action.data
      };
    }

    case UPDATE_FILE_BROWSER_NODE_DATA:
      return {
        ...state,
        selectedFileBrowserNodeId: action.data.selectedFileBrowserNodeId,
        selectedFileBrowserUrl: `${action.data.selectedFileBrowserUrl}`
      };
    case UPDATE_FILE_BROWSER_NODE_DATA_FOR_PAGINATION:
      return {
        ...state,
        fileBrowserUrlCurrentVolGuid: `${action.data.fileBrowserUrlCurrentVolGuid}`,
        fileBrowserUrlCurrentFullPath: `${action.data.fileBrowserUrlCurrentFullPath}`,
        fileBrowserUrlCurrentDisplayFullPath: `${action.data.fileBrowserUrlCurrentDisplayFullPath}`
      };

    /**
     * update customers in state, used in dropdown
     */
    case UPDATE_CUSTOMERS_TO_ASSIGN: {
      //ARC001-2211: removed action.data.data as the same was not working.
      let customers = action.data || [];
      let customerDataSet = { options: [], selectedOptions: [] };
      customers &&
        //eslint-disable-next-line array-callback-return
        customers.map(cust => {
          customerDataSet.options.push({
            cust,
            display: `${cust.organization_name}`,
            label: `${cust.organization_name}`,
            value: `${cust.organization_name}`,
            id: cust.organization_id,
            customer_id: cust.organization_id
          });
        });
      return {
        ...state,
        adminDataToAssign: customerDataSet
      };
    }

    /**
     * update recovery Image Formats
     */
    case UPDATE_RECOVERY_IMAGE_FORMATS: {
      return {
        ...state,
        recoveryImageFormats: action.data
      };
    }

    /*
     * set user's machine details - OS and system type
     */
    case SET_MACHINE_DETAILS: {
      return {
        ...state,
        machineDetails: action.data
      };
    }

    /**
     * update recovery points details data
     */
    case UPDATE_PARTICULAR_RECOVERY_POINT_DETAILS_DATA: {
      return {
        ...state,
        recoveryPointsDetailsdata: action.data
      };
    }
    /**
     * update recovery targets
     */
    case UPDATE_RECOVERY_TARGETS_DATA:
      return { ...state, data: action.data };
    /**
     * update selected recovery image format
     */
    case UPDATE_SELECTED_RECOVERY_IMAGE_FORMAT_DATA:
      return { ...state, selectedRecoverImageFormat: action.data };

    /**
     * update restore recover path
     */
    case UPDATE_RESTORE_RECOVER_PATH:
      return { ...state, restoreRecoverPath: action.data };

    /**
     *  update zft file browser required data
     */
    case UPDATE_RESTORE_RECOVER_ZFT_DATA: {
      return {
        ...state,
        restoreRecoverPath: action.data.rp_restore,
        restoreRecoverSnapshot: action.data.rp_snapshot,
        restoreRecoverHost: action.data.snapshot_host
      };
    }

    //  set target source id in reducer
    case UPDATE_RECOVER_TARGET_SOURCE_ID: {
      return {
        ...state,
        targetSourceId: action.data
      };
    }

    //  set target VMware datastore in reducer
    case UPDATE_RECOVER_TARGET_VMWARE_DATASTORE_NAME: {
      return {
        ...state,
        targetVMwareDatastoreName: action.data
      };
    }

    //  set target VMware vm in reducer
    case UPDATE_RECOVER_TARGET_VMWARE_VM_NAME: {
      return {
        ...state,
        targetVMwareVMName: action.data
      };
    }

    //  set rp group ungroup in reducer
    case SWITCH_RECOVERY_POINTS_GROUP: {
      return {
        ...state,
        recoveryPointsGroup: action.data
      };
    }

    //  set switch recovery target machine in reducer
    case SWITCH_RECOVERY_TARGET_MACHINE: {
      return {
        ...state,
        recoveryTargetSwitch: action.data
      };
    }

    //  recocver points clear state
    case CLEAR_RECOVERY_POINTS_STATE: {
      return {
        ...state,
        recoveryPoints: [],
        recoveryPointDetails: []
      };
    }

    /**
     * update Destination CD details for chart
     */
    case UPDATE_DESTINATION_CD: {
      return {
        ...state,
        destinationCD: action.data
      };
    }

    /**
     * set selected dates for destination CD chart
     */
    case SET_SELECTED_DATES: {
      return {
        ...state,
        selectedDates: action.data
      };
    }

    /**
     * set selected dates for destination CH chart
     */
    case SET_SELECTED_DATES_CH: {
      return {
        ...state,
        selectedDatesCH: action.data
      };
    }
    case SET_SELECTED_DATES_CU: {
      return {
        ...state,
        selectedDatesCU: action.data
      };
    }

    /**
     * update policy data for assign data
     */
    case UPDATE_ASSIGN_POLICY_SOURCE: {
      let data = action.data ? action.data : "";
      return {
        ...state,
        policyList: transformPolicListForAssignPolicy(data)
      };
    }
    case UPDATE_SELECTED_ASSIGN_POLICY_SOURCE: {
      return {
        ...state,
        selectedPolicyForAssignPolicy: action.data
      };
    }

    /**
     * clear selected dates for destination CD chart
     */
    case CLEAR_SELECTED_DATES: {
      return {
        ...state,
        selectedDates: []
      };
    }

    /**
     * clear selected dates for destination CD chart
     */
    case CLEAR_SELECTED_DATES_CH: {
      return {
        ...state,
        selectedDatesCH: []
      };
    }

    case CLEAR_SELECTED_DATES_CU: {
      return {
        ...state,
        selectedDatesCU: []
      };
    }
    /**
     * update Customer Account CD details for chart
     */
    case UPDATE_CUSTOMER_ACCOUNT_CD: {
      return {
        ...state,
        customerAccountCD: action.data
      };
    }

    /**
     * update the retention duration for cloud voulme - details
     */
    case SET_RETENTION_DURATION: {
      return {
        ...state,
        retentionDuration: action.data
      };
    }

    /**
     * update Destination CD details for chart
     */
    case UPDATE_DESTINATION_CH: {
      return {
        ...state,
        destinationCH: action.data
      };
    }
    /**
     * show hide protected password form
     */
    case SHOW_HIDE_PROTECTED_PASSWORD_FORM: {
      return {
        ...state,
        showProtectedPasswordModal: action.data
      };
    }

    /**
     * keep ch protected pwd in state
     */
    case POST_PROTECTED_PASSWORD_DATA: {
      return {
        ...state,
        chProtectedPassword: action.data.protected_password
      };
    }

    case SET_RPS_HASHMEMORY: {
      return {
        ...state,
        hashMemoryResponse: action.data
      };
    }

    case SET_RPS_HASHMEMORY_LOADER: {
      return {
        ...state,
        hashMemoryLoader: action.data
      };
    }

    case SET_RPS_HASHMEMORY_ERROR: {
      return {
        ...state,
        hashMemoryError: action.data
      };
    }

    /**
     * update restore recover destination/to_path
     */
    case UPDATE_RESTORE_RECOVER_SOURCE_TO_PATH:
      return {
        ...state,
        recoveryPointsDetailsdata: updateSourceToPathRecoveryPointDetailsData(
          state.recoveryPointsDetailsdata,
          action.data
        )
      };

    /**
     * update Customer Account CD details for chart
     */
    case UPDATE_CUSTOMER_ACCOUNT_CH: {
      return {
        ...state,
        customerAccountCH: action.data
      };
    }

    case UPDATE_LICENSES: {
      return {
        ...state,
        sourceLicenses: action.data
      };
    }

    /**
     * update selected policy type
     */
    case UPDATE_SELECTED_POLICY_TYPE: {
      return {
        ...state,
        selectedPolicyType: action.data ? action.data : ""
      };
    }

    /**
     * update recovered vms state
     */
    case UPDATE_RECOVERED_VMS_STATE: {
      let recoveredVMStateData = Object.assign({}, state.recoveredVMS);
      recoveredVMStateData = {
        data: updateObjectInArray(
          recoveredVMStateData.data,
          action.data,
          "recoveredresource_id"
        )
      };
      return {
        ...state,
        recoveredVMS: recoveredVMStateData
      };
    }

    /**
     * update remote policies
     */
    case UPDATE_REMOTE_POLICIES: {
      return {
        ...state,
        remotePolicies: action.data ? action.data.data : [],
        dropdownRemotePolicies: action.data ? action.data.dropdowndata : [],
        rpError: false
      };
    }

    case UPDATE_REMOTE_POLICIES_ERROR: {
      return {
        ...state,
        remotePolicyError: action.data,
        remotePolicies: [],
        dropdownRemotePolicies: [],
        rpError: true
      };
    }

    case CLEAR_REMOTE_POLICIES_ERROR: {
      return {
        ...state,
        remotePolicyError: null,
        rpError: true
      };
    }

    case CLEAR_REMOTE_POLICIES: {
      return {
        ...state,
        remotePolicies: [],
        dropdownRemotePolicies: []
      };
    }
    case GET_SITE_NAME: {
      return {
        ...state,
        sites: action.payload
      };
    }
    case LOAD_REGISTERED_SITES_SUCCESS: {
      return {
        ...state,
        registeredSites: action.data
      };
    }
    case SET_SELECTED_SITE: {
      return {
        ...state,
        site: action.data
      };
    }
    case ERROR_MESSAGE_CONNECT: {
      return {
        ...state,
        showErrorMessage: action.data
      };
    }
    case GET_DISCOVER_SOURCE_NAME: {
      return {
        ...state,
        discoverSourceName: action.data
      };
    }
    case ADD_NEW_SITE: {
      return {
        ...state,
        addNewSite: { ...state.addNewSite, ...action.data }
      };
    }

    case SWITCH_ADD_SITE_TAB: {
      return {
        ...state,
        addNewSite: { ...state.addNewSite, tabIndex: action.data }
      };
    }

    case RESTORE_NEW_SITE_VALUE: {
      return {
        ...state,
        addNewSite: {
          tabIndex: 1,
          siteName: "",
          gatewayAuthorizationCode: "",
          siteId: ""
        }
      };
    }

    case LOAD_RECOVERY_POINT_SERVERS_SUCCESS: {
      return {
        ...state,
        recoveryPointServers: action.data
      };
    }

    case DESTROY_POLICY: {
      return {
        ...state,
        recoveryPointServers: [],
        rpsDataStores: []
      };
    }

    case LOAD_BACKUP_TRAFFIC_USING_RPS_SUCCESS: {
      return {
        ...state,
        networkBackupTrafficRPS: action.data
      };
    }

    case GET_ALL_HYPERVISORS_SUCCESS: {
      return {
        ...state,
        policyHypervisorList: action.data
      };
    }

    case GET_HYPERVISOR_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        hypervisorConfigDetail: action.data
      };
    }

    case CLEAR_HYPERVISOR_CONFIGURATION_DATA: {
      return {
        ...state,
        hypervisorConfigDetail: null
      };
    }
    case HYPERV_TYPE_AR_CHANGED: {
      return {
        ...state,
        hypervTypeChanged: true
      };
    }
    case RESET_HYPERV_TYPE_AR_CHANGED_VAL: {
      return {
        ...state,
        hypervTypeChanged: false
      };
    }

    case GET_ALL_PROXIES_FOR_POLICY_SUCCESS: {
      return {
        ...state,
        policyProxiesList: action.data,
        allProxiesList: action.data
      };
    }

    case CLEAR_BACKUP_TRAFFIC_USING_RPS: {
      return {
        ...state,
        networkBackupTrafficRPS: []
      };
    }

    case LOAD_BACKUP_PROXY_SUCCESS: {
      return {
        ...state,
        backupProxyList: action.data.data
      };
    }

    case FETCH_DESTINATION_PATH_REQUEST: {
      return {
        ...state,
        destinationPath: []
      };
    }

    case FETCH_DESTINATION_PATH_SUCCESS: {
      return {
        ...state,
        destinationPath: action.payload
      };
    }

    case FETCH_DESTINATION_PATH_FAILURE: {
      return {
        ...state,
        destinationPath: []
      };
    }

    case SET_SHARED_FOLDER_ID: {
      return {
        ...state,
        sharedFolderId: action.data
      };
    }

    case LOAD_LBS_SUCCESS: {
      return {
        ...state,
        lbsList: action.data.data
      };
    }
    case LOAD_LINUX_LBS_SUCCESS: {
      return {
        ...state,
        linuxLbsList: action.data.data
      };
    }
    case LOAD_LINUX_SUCCESS: {
      return {
        ...state,
        linuxList: action.data.data
      };
    }

    case FETCH_SELECTED_NETWORK_BACKUP_PROXY_SUCCESS: {
      return {
        ...state,
        selectedNetworkBackupTraffic: action.data.data
      };
    }

    case LOAD_SELECTED_LBS_NETWORK_SUCCESS: {
      return {
        ...state,
        selectedLBSNetworks: action.data.data
      };
    }

    case CLEAR_SELECTED_NETWORK_BACKUP_PROXY: {
      return {
        ...state,
        selectedNetworkBackupTraffic: []
      };
    }

    case ADD_BACKUP_PROXY_FLAG: {
      return {
        ...state,
        showAddBackupProxyModal: action.data
      };
    }
    case ADD_LINUX_BACKUP_FLAG: {
      return {
        ...state,
        showAddLinuxBackupModal: action.data
      };
    }
    case GET_SELECTED_PROXY: {
      return {
        ...state,
        getProxyDetail: action.data
      };
    }
    case GET_SELECTED_LBS: {
      return {
        ...state,
        getLBSDetail: action.data
      };
    }

    case LOAD_RPS_DATA_STORES_SUCCESS: {
      if (action.rpsId === "arcserve_cloud") {
        return {
          ...state,
          rpsDataStores: { ...state.rpsDataStores, arcserve_cloud: action.data }
        };
      } else {
        return {
          ...state,
          rpsDataStores: { ...state.rpsDataStores, [action.rpsId]: action.data }
        };
      }
    }

    case UPDATE_SOURCE_DETAILS_IN_STORE: {
      return {
        ...state,
        data: {
          ...state.data,
          data: updateSourceList(state.data.data, action.id, action.data)
        }
      };
    }
    case GET_SHARED_POLICY_DETAILS_SUCCESS: {
      return {
        ...state,
        sharedEmail: action.data
      };
    }
    case CLEAR_SHARED_POLICY_DETAILS: {
      return {
        ...state,
        sharedEmail: []
      };
    }
    case SWITCH_ADD_IVM_TAB: {
      return {
        ...state,
        createNewIVM: { ...state.createNewIVM, tabIndex: action.data }
      };
    }
    case RESTORE_NEW_IVM_VALUE: {
      return {
        ...state,
        createNewIVM: {
          tabIndex: 1
        }
      };
    }
    /**
     * update recovered resources
     */
    case UPDATE_RECOVERED_RESOURCES: {
      return {
        ...state,
        recoveredResources: action.data,
        recoveredResourcesPagination: action.data.pagination
      };
    }
    case GET_PENDING_SESSIONS:
      return {
        ...state,
        pendingSessions: action.data
      };

    case FnF_LINUX_FETCH_SCRIPTS: {
      return {
        ...state,
        getLinuxScripts: action.data
      };
    }
    case GET_NETWORK_DATA:
      return {
        ...state,
        getNetworkData: action.data
      };
    case SET_BACKUP_PASSWORDS_DATA:
      return {
        ...state,
        backupPasswordsData: action.data
      };
    case LOAD_DNS_DATA:
      return {
        ...state,
        getDNSData: action.data
      };
    case GET_STANDBY_VM_SNAPSHOT:
      return {
        ...state,
        snapshotList: action.data
      };
    case GET_IS_NETWORK_CONFIG:
      return {
        ...state,
        getIsNetworkConfigDetail: action.data
      };
    /**
     * update instant VMs
     */
    case UPDATE_INSTANT_VMS: {
      return {
        ...state,
        instantVMs: action.data.data,
        instantVMsPagination: action.data.pagination
      };
    }
    case MOUNTPOINT_MODAL: {
      return {
        ...state,
        showMountPointModal: action.data
      };
    }
    case STORE_MOUNTPOINT_INFO: {
      return {
        ...state,
        mountPointInfo: action.data.mount_point
      };
    }
    case SET_ORGANIZATION_ID_FOR_SOURCE_CSR:
      return {
        ...state,
        orgIdForSourceCSR: action.data.organization_id
      };
    case GET_REGIONS_FOR_CLOUD_ACCOUNT_SUCCESS_V3:
      return {
        ...state,
        selectedCloudAccountRegion: action.data
      };
    case GET_BUCKETS_FOR_CLOUD_ACCOUNT_SUCCESS:
      return {
        ...state,
        selectedCloudAccountBuckets: action.data
      };
    case GET_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        transactionDetails: action.data
      };
    case SOURCE_ART_DATA_GET_SUCCESS: {
      return {
        ...state,
        sourceARTTaskDetails: action.data,
        loadingSourceARTTaskDetails: false
      };
    }
    case GET_SOURCE_DATA_BY_ID: {
      return {
        ...state,
        loadingSourceARTTaskDetails: true
      };
    }
    case CLEAR_BACKUP_PASSWORDS: {
      return {
        ...state,
        backupPasswordsData: []
      };
    }
    case CLEAR_SOURCE_ART_LOADER: {
      return {
        ...state,
        loadingSourceARTTaskDetails: false
      };
    }
    case CLEAR_SOURCE_ART_DETAILS: {
      return {
        ...state,
        sourceARTTaskDetails: []
      };
    }
    case CLEAR_CLOUD_ACCOUNTS_REGIONS_V3:
      return {
        ...state,
        selectedCloudAccountRegions: []
      };
    case CLEAR_CLOUD_ACCOUNTS_BUCKETS:
      return {
        ...state,
        selectedCloudAccountBuckets: []
      };
    case CREATE_VMWARE_FOR_POLICY_SUCCESS:
      return {
        ...state,
        vmwareForPolicy: action.data
      };
    case CLEAR_VMWARE_FOR_POLICY:
      return {
        ...state,
        vmwareForPolicy: []
      };
    case CREATE_HYPERV_FOR_POLICY_SUCCESS:
      return {
        ...state,
        hypervForPolicy: action.data
      };
    case CLEAR_HYPERV_FOR_POLICY:
      return {
        ...state,
        hypervForPolicy: []
      };
    case ADDED_ORACLE_DB_LIST:
      return {
        ...state,
        addedOracleDBlist: action.data
      };
    case CLEAR_ORACLE_DB_LIST:
      return {
        ...state,
        addedOracleDBlist: []
      };
    default:
      return state;
  }
};

/**
 * Update one item in the list and return the new list
 * @param array list
 * @param string id
 * @param object sourceNew
 */
function updateSourceList(list, id, sourceNew) {
  return list.map(source => {
    if (id === source.source_id) {
      return sourceNew;
    }
    return source;
  });
}

/**
 * get update recover to_path in recoveryPointsDetailsdata
 * put results in reducer
 */
function updateSourceToPathRecoveryPointDetailsData(object, actionDataPath) {
  return {
    ...object,
    to_path: actionDataPath
  };
}

/**
 * get filtered by id and
 * put results in reducer
 */
function updateRecoveryPointDetails(array, id) {
  let data = array.slice();
  const updatedData = data.filter(item => item.id === id);
  if (updatedData.length) {
    return {
      data: updatedData[0].details
    };
  }
}

/**
 * remove unassign admin from customer admins list
 */
function removeItemFromArrayWithoutData(array, action, type) {
  let data = array.slice();
  const updatedData = data.filter(item => item[type] !== action);
  return {
    ...array,
    data: updatedData
  };
}

/**
 * update dropdown values for selected admins in assign modal
 *
 * @param {array} stateArray  current admins data options in dropdown
 * @param {array} actionData
 */
function updateSelectedAdminInArray(array, action) {
  let itemArr = [];
  // let lastIndex = action.data.item.length - 1;
  //eslint-disable-next-line array-callback-return
  array.map(item => {
    //eslint-disable-next-line array-callback-return
    action.data.item.map(actionItem => {
      if (item.id === actionItem.id) {
        itemArr.push(item);
      }
    });
  });
  return itemArr;
}

/**
 * Insert item as generic function for data.data as array
 */
function insertItem(array, action) {
  return [...array.slice(0, action.id), action, ...array.slice(action.id)];
}

/**
 * filter the data based on the source_type
 * secondaryKey is the source_type saved in the state
 */
function filterSavedSearchData(data) {
  let userSearchData =
    data.defaultSearchList && data.defaultSearchList.data
      ? data.defaultSearchList.data
      : [];
  let secondaryKey = data.secondaryKey;
  if (
    secondaryKey === null ||
    secondaryKey === undefined ||
    secondaryKey === "sources"
  ) {
    secondaryKey = "all";
  }
  //eslint-disable-next-line array-callback-return
  const filteredSavedSearchData = userSearchData.filter(item => {
    if (item.source_type === secondaryKey) {
      return item;
    }
  });
  return { data: filteredSavedSearchData };
}

/**
 * update the saved search data with the new - 'selected saved search data' fetched from back end
 * data here is the modified selected saved search
 */
function updateSavedSearch(userSearchData, data) {
  const arrayIndex = userSearchData.data.findIndex(savedSearch => {
    return savedSearch.filter_id === data.filter_id;
  });
  userSearchData.data[arrayIndex] = { ...data };
  return { ...userSearchData };
}

// function removeItem(array, action) {
//   let data = array.data.slice();
//   const updatedData = data.filter(item => item.filter_id !== action);
//   return {
//     ...array,
//     data: updatedData
//   };
// }

/**
 * generic method for deleting
 */
function removeItemByKey(array, action, type) {
  let data = array.slice();
  const updatedData = data.filter(item => item[type] !== action);
  return [...updatedData];
}

/**
 * generic method for deleting by its diff keys
 */
function removeItemByTypeKey(array, action, type) {
  let data = array.data.slice();
  const updatedData = data.filter(item => item[type] !== action);
  return {
    ...array,
    data: updatedData
  };
}

/**
 * Handle update array
 *
 * @param {array} stateArray
 * @param {array} actionArray current pinned unpinned in reducer state
 */
function updatePinUnpinInRecoveryPoints(array, action) {
  return (
    array &&
    array.map(item => {
      if (item.recovery_point_id !== action.recovery_point_id) {
        // This isn't the item we care about - keep it as-is
        return item;
      }
      // Otherwise, this is the one we want - return an updated value
      if (action.pinned) {
        action.pinned = false;
        action.available_actions[1] = "pin";
      } else {
        action.pinned = true;
        action.available_actions[1] = "unpin";
      }

      return {
        ...item,
        ...action
      };
    })
  );
}

/**
 * Handle update array
 *
 * @param {array} stateArray
 * @param {array} actionArray current options in reducer state
 * @param {array} key identifier key
 */
function updateObjectInArray(array, action, key) {
  return array.map(item => {
    if (item[key ? key : "id"] !== action[key ? key : "id"]) {
      // This isn't the item we care about - keep it as-is
      return item;
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action
    };
  });
}

/**
 * Handle update array
 *
 * @param {array} stateArray
 * @param {array} actionArray current options in reducer state
 */
function updateObjectInDestinationArray(policy, action) {
  let policyData = Object.assign({}, policy && policy);
  let destinations =
    policyData &&
    policyData.destination &&
    policyData.destination.map(item => {
      if (item.id === action.id) {
        // This isn't the item we care about - keep it as-is
        item[action.type] = action;
      }
      return item;
    });
  return {
    ...policyData,
    destination: destinations
  };
}

/**
 * Handle remove Object from array
 *
 * @param {array} stateArray
 * @param {array} actionArray current options in reducer state
 */
function removeObjectInDestinationArray(array, action) {
  let policyData = Object.assign({}, array && array);
  //eslint-disable-next-line array-callback-return
  let destinations =
    policyData &&
    policyData.destination &&
    policyData.destination.filter(item => {
      if (item.id !== action.id) {
        return item;
      }
      if (
        item.id === action.id &&
        action.type === constant.DESTINATION_TYPE.SECONDARY
      ) {
        delete item[constant.DESTINATION_TYPE.SECONDARY];
        return item;
      }
    });
  return {
    ...policyData,
    destination: destinations
  };
}

/**
 * Handle insert/add array
 *
 * @param {array} stateArray
 * @param {array} actionArray current inserted item
 */
function addObjectInArray(array, action) {
  return (
    array && [...array.slice(0, action.id), action, ...array.slice(action.id)]
  );
}

/**
 * Handle update Object
 *
 * @param {any} stateObject
 * @param {any} actionObject current options in reducer state
 */
function updateObject(item, action) {
  if ((item && item.id) !== action.id) {
    // This isn't the item we care about - keep it as-is
    return item;
  }
  // Otherwise, this is the one we want - return an updated value
  return {
    ...item,
    ...action
  };
}

/**
 * Handle remove destination
 *
 * @param {array} stateArray
 * @param {string} action deleted destination id
 */
function removeDestination(array, action) {
  let destinations = Object.assign([], array.data);
  for (let i = 0; i < destinations.length; i++) {
    if (destinations[i].destination_id === action) {
      destinations.splice(i, 1);
    }
  }
  return {
    ...array,
    data: destinations
  };
}

/**
 * Handle add destination
 *
 * @param {array} stateArray
 * @param {array} actionArray newly added destination
 */
function addDestination(array, action) {
  let destinations = Object.assign([], array && array.data);
  destinations.unshift(action);

  return {
    ...array,
    data: destinations
  };
}

function updateCustomerAccount(customerAccounts, action) {
  let index = customerAccounts.data.findIndex(customer => {
    return customer.id === action.data.id;
  });
  let customerAccountsData = Object.assign([], customerAccounts.data);
  customerAccountsData[index] = action.data;
  return {
    ...customerAccounts,
    data: customerAccountsData
  };
}

function transformPolicListForAssignPolicy(action) {
  let payload = Object.assign([], action.data);
  //eslint-disable-next-line array-callback-return
  var filteredPayload = payload.filter(item => {
    if (item.policy_type !== POLICY_TYPES.HYPERVISOR) {
      return item;
    }
  });
  let options = Util.dropdownValues(
    filteredPayload,
    "policy_id",
    "policy_name"
  );
  return options;
}

export default protectReducer;

import DataGridConstants from "components/common/data-grid/DataGridConstants";

const DataGridColumnCellTypes = DataGridConstants.GridColumnCellTypes;
const DataGridColumnHeaderTypes = DataGridConstants.GridColumnHeaderTypes;

/**
 * Enumrates list of source attributes known to the UI.
 */
export const PoliciesAttributes = {
  POLICIES_PROP_ID: "policy_id",
  POLICIES_PROP_NAME: "policy_name",
  POLICIES_PROP_STATUS: "policy_status",
  POLICIES_PROP_TYPE: "policy_type",
  POLICIES_PROP_PROTECTED_SOURCES: "protected_sources",
  POLICIES_PROP_UNPROTECTED_SOURCES: "unprotected_sources",
  POLICIES_PROP_SOURCES_GROUP: "source_group",
  POLICIES_PROP_LATEST_JOB: "last_job",
  POLICIES_PROP_DESCRIPTION: "policy_description",
  POLICIES_PROP_ACTION: "available_actions"
};

/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier => {
  return "protect.policies.".concat(identifier);
};

/**
 * Additional Grid properties :
 * CLICKABLE_CELL_TO_VIEW_DETAILS : On click of cell the details will show
 * @type {{CLICKABLE_CELL_TO_VIEW_DETAILS: string}}
 */
export const GRID_PROPS = {
  CLICKABLE_CELL_TO_VIEW_DETAILS: PoliciesAttributes.POLICIES_PROP_NAME
};

export const COLUMN_DEFS = [
  {
    accessor: PoliciesAttributes.POLICIES_PROP_NAME,
    displayIdentifier: getDisplayIdentifier(
      PoliciesAttributes.POLICIES_PROP_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: {}
  },
  {
    accessor: PoliciesAttributes.POLICIES_PROP_STATUS,
    displayIdentifier: getDisplayIdentifier(
      PoliciesAttributes.POLICIES_PROP_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: PoliciesAttributes.POLICIES_PROP_PROTECTED_SOURCES,
    displayIdentifier: getDisplayIdentifier(
      PoliciesAttributes.POLICIES_PROP_PROTECTED_SOURCES
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL },
    settings: { width: 200, minWidth: 150 }
  },
  {
    accessor: PoliciesAttributes.POLICIES_PROP_UNPROTECTED_SOURCES,
    displayIdentifier: getDisplayIdentifier(
      PoliciesAttributes.POLICIES_PROP_UNPROTECTED_SOURCES
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL },
    settings: { width: 180 }
  },
  {
    accessor: PoliciesAttributes.POLICIES_PROP_TYPE,
    displayIdentifier: getDisplayIdentifier(
      PoliciesAttributes.POLICIES_PROP_TYPE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 180 }
  },
  {
    accessor: PoliciesAttributes.POLICIES_PROP_SOURCES_GROUP,
    displayIdentifier: getDisplayIdentifier(
      PoliciesAttributes.POLICIES_PROP_SOURCES_GROUP
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.OVERLAY_CELL },
    settings: { width: 200 }
  },
  // {
  //   accessor: PoliciesAttributes.POLICIES_PROP_ACTION,
  //   displayIdentifier: getDisplayIdentifier(
  //     PoliciesAttributes.POLICIES_PROP_ACTION
  //   ),
  //   renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
  //   renderRecord: {
  //     type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
  //     actionDropdown: true
  //   },
  //   settings: { width: 80 }
  // },
  {
    accessor: PoliciesAttributes.POLICIES_PROP_DESCRIPTION,
    displayIdentifier: getDisplayIdentifier(
      PoliciesAttributes.POLICIES_PROP_DESCRIPTION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.HOVER_CELL },
    settings: {}
  },
  {
    accessor: PoliciesAttributes.POLICIES_PROP_LATEST_JOB,
    displayIdentifier: getDisplayIdentifier(
      PoliciesAttributes.POLICIES_PROP_LATEST_JOB
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LATEST_JOB_LINK_CELL_MUI },
    settings: { width: 300 }
  }
];

/**
 * Contextual actions for policies
 */
export const CONTEXTUAL_ACTION = {
  REMOVE: "delete",
  MODIFY: "modify",
  DEPLOY: "deploy",
  ENABLE: "enable",
  DISABLE: "disable",
  REFRESH: "refresh",
  SHARE: "share",
  BACKUP: "backup",
  REPLICATION: "replication",
  RUN_ASSURED_RECOVERY_TEST: "start_ar_test"
};

export const USAGE_STATUS = {
  ENABLED: "enabled",
  DISABLED: "disabled",
  CSR_DISABLED: "csr_disabled",
  MSP_DISABLED: "msp_disabled"
};

import { SET_LOADING, RESET_LOADING } from "../actionTypes";

export const Loader = {
  ADD_FROM_HYPERVISOR_GET_SERVER_TYPE: "ADD_FROM_HYPERVISOR_GET_SERVER_TYPE",
  ADD_FROM_HYPERVISOR_CONNECT_BTN: "ADD_FROM_HYPERVISOR_CONNECT_BTN",
  ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN: "ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN",
  ADD_SOURCE_FROM_HYPERVISOR_NEXT_BUTTON:
    "ADD_SOURCE_FROM_HYPERVISOR_NEXT_BUTTON",
  ADD_SOURCE_FROM_HYPERVISOR_PREVIOUS_BUTTON:
    "ADD_SOURCE_FROM_HYPERVISOR_PREVIOUS_BUTTON",
  LINUX_SOURCE_SAVE_TO_LIST: "LINUX_SOURCE_SAVE_TO_LIST",
  ORACLE_DB_SOURCE_OPTIONS: "ORACLE_DB_SOURCE_OPTIONS",
  WINDOWS_SOURCE_SAVE_TO_LIST: "WINDOWS_SOURCE_SAVE_TO_LIST",
  ORACLE_SOURCE_SAVE_TO_LIST: "ORACLE_SOURCE_SAVE_TO_LIST",
  ORACLE_WINDOWS_DB_OPTIONS: "ORACLE_WINDOWS_DB_OPTIONS",
  ORACLE_DB_SOURCE_OPTIONS: "ORACLE_DB_SOURCE_OPTIONS",
  ORACLE_WINDOWS_DB_SAVE_TO_LIST: "ORACLE_WINDOWS_DB_SAVE_TO_LIST",
  ORACLE_WINDOWS_ADD_DATABASE: "ORACLE_WINDOWS_ADD_DATABASE",
  LINUX_ADD_SOURCES: "LINUX_ADD_SOURCES",
  ADD_ACTIVE_DIRECTORY: "ADD_ACTIVE_DIRECTORY",
  ADD_ACTIVE_DIRECTORY_SOURCES: "ADD_ACTIVE_DIRECTORY_SOURCES",
  ACTIVE_DIRECTORY_SOURCES_AUTHENTICATION:
    "ACTIVE_DIRECTORY_SOURCES_AUTHENTICATION",
  ADD_LBS_SAVE_TO_LIST: "ADD_LBS_SAVE_TO_LIST",
  ADD_LBS_ADD_SOURCE: "ADD_LBS_ADD_SOURCE",
  ADD_SITE: "ADD_SITE",
  ADD_RPS: "ADD_RPS",
  UPDATE_SITE_NAME: "UPDATE_SITE_NAME",
  UPDATE_STORAGE_ARRAY: "UPDATE_STORAGE_ARRAY",
  DELETE_SITE: "DELETE_SITE",
  ADD_REMOTE_CONSOLE: "ADD_REMOTE_CONSOLE",
  DELETE_RPS: "DELETE_RPS",
  UPDATE_RPS: "UPDATE_RPS",
  UPDATE_SHARED_FOLDER: "UPDATE_SHARED_FOLDER",
  DELETE_DATA_STORE: "DELETE_DATA_STORE",
  DELETE_DATA_STORE_BULK: "DELETE_DATA_STORE_BULK",
  STOP_DATA_STORE: "STOP_DATA_STORE",
  PURGE_DATA_STORE: "PURGE_DATA_STORE",
  PURGE_DATA_STORE_BULK: "PURGE_DATA_STORE_BULK",
  STOP_DATA_STORE_BULK: "STOP_DATA_STORE_BULK",
  IMPORT_DATA_STORE_STEP_ONE: "IMPORT_DATA_STORE_STEP_ONE",
  IMPORT_DATA_STORE_STEP_TWO: "IMPORT_DATA_STORE_STEP_TWO",
  IMPORT_DATA_STORE_OVERRIDE: "IMPORT_DATA_STORE_OVERRIDE",
  CREATE_CLOUD_ACCOUNT: "CREATE_CLOUD_ACCOUNT",
  UPDATE_CLOUD_ACCOUNT: "UPDATE_CLOUD_ACCOUNT",
  ADD_DATA_STORE: "ADD_DATA_STORE",
  ADD_CLOUD_DATA_STORE: "ADD_CLOUD_DATA_STORE",
  ADD_DATA_STORE_CONNECT: "ADD_DATA_STORE_CONNECT",
  SAVE_JUMPSTART: "SAVE_JUMPSTART",
  IMPORT_CLOUD_DATA_STORE: "IMPORT_CLOUD_DATA_STORE",
  IMPORT_CLOUD_DATA_STORE_STEP_TWO: "IMPORT_CLOUD_DATA_STORE_STEP_TWO",
  MODIFY_DATA_STORE_WARNING: "MODIFY_DATA_STORE_WARNING",
  DOWNLOAD_CSV_CONTENT: "DOWNLOAD_CSV_CONTENT",
  POST_VSB_SETTINGS: "POST_VSB_SETTINGS",
  ADD_SOURCE_SAVE_TO_LIST_VMWARE: "ADD_SOURCE_SAVE_TO_LIST_VMWARE",
  ADD_SOURCE_SAVE_TO_LIST_HYPERV: "ADD_SOURCE_SAVE_TO_LIST_HYPERV",
  UNC_OR_NFS_PATH_SAVE_TO_LIST: "UNC_OR_NFS_PATH_SAVE_TO_LIST",
  CANCEL_JOB: "CANCEL_JOB",
  UPDATE_SOURCE: "UPDATE_SOURCE",
  CSR_ORG_DATACENTER: "CSR_ORG_DATACENTER",
  SHARE_POLICY_BTN: "SHARE_POLICY_BTN",
  MOUNT_RP_BTN: "MOUNT_RP_BTN",
  REPORTS_DOWNLOAD: "REPORTS_DOWNLOAD",
  ADD_DISCOVER_FROM_AD: "ADD_DISCOVER_FROM_AD",
  ADD_DISCOVER_FROM_HYPERV: "ADD_DISCOVER_FROM_HYPERV",
  ADD_DISCOVER_FROM_VMWARE: "ADD_DISCOVER_FROM_VMWARE",
  DELETE_AD_SOURCE: "DELETE_AD_SOURCE",
  DISMOUNT_VOLUME_CONFIRM: "DISMOUNT_VOLUME_CONFIRM",
  DISMOUNT_RPS_CONFIRM: "DISMOUNT_RPS_CONFIRM",
  UPDATE_HYPERVISORS: "UPDATE_HYPERVISORS",
  GET_DESTINATION_BY_SOURCEID: "GET_DESTINATION_BY_SOURCEID",
  DELETE_SOURCE_DISCOVERY: "DELETE_SOURCE_DISCOVERY",
  AVAILABLE_VOLUMES: "AVAILABLE_VOLUMES",
  ADD_WINDOWS_SOURCES: "ADD_WINDOWS_SOURCES",
  ADD_UNC_NFS_SOURCES: "ADD_UNC_NFS_SOURCES",
  ADD_ORACLE_SOURCES: "ADD_ORACLE_SOURCES",
  ADD_ORACLE_DB: "ADD_ORACLE_DB",
  ADD_DESTINATION_HOST: "ADD_DESTINATION_HOST",
  ADD_DESTINATION_HOST_LINUX: "ADD_DESTINATION_HOST_LINUX",
  RECOVER_VOLUME_CONFIRM: "RECOVER_VOLUME_CONFIRM",
  UPDATE_PROXY: "UPDATE_PROXY",
  CREATE_POLICY: "CREATE_POLICY",
  AR_ADD_PROXY: "AR_ADD_PROXY",
  ADD_RPA: "ADD_RPA",
  ADD_VMWARE: "ADD_VMWARE",
  ADD_HYPERV: "ADD_HYPERV",
  AR_LINUX_ADD_PROXY: "AR_LINUX_ADD_PROXY",
  POLICY_SHARED_FOLDER_DESTINATION_CONNECT_DIALOG:
    "POLICY_SHARED_FOLDER_DESTINATION_CONNECT_DIALOG",
  POLICY_VSB_CONNECT_DIALOG: "POLICY_VSB_CONNECT_DIALOG",
  INSTANT_VMS_RR: "INSTANT_VMS_RR",
  VALIDATE_VM_DIALOG: "VALIDATE_VM_DIALOG",
  VALIDATE_LINUX_VM_DIALOG: "VALIDATE_LINUX_VM_DIALOG",
  START_RECOVERY_BTN: "START_RECOVERY_BTN",
  RECOVER_NEXT_BTN: "RECOVER_NEXT_BTN",
  VERIFY_PASSWORD_DIALOG: "VERIFY_PASSWORD_DIALOG",
  RECOVERY_POINT_LOADER: "RECOVERY_POINT_LOADER",
  MOUNTPOINT_INFO_LOADER: "MOUNTPOINT_INFO_LOADER",
  STANDBY_SHUTDOWN_VM: "STANDBY_SHUTDOWN_VM",
  STANDBY_POWER_ON: "STANDBY_POWER_ON",
  VIRTUAL_STANDBY_RESUME: "VIRTUAL_STANDBY_RESUME",
  TWO_FACTOR_ENABLE: "TWO_FACTOR_ENABLE",
  HELP_LINK_CSV: "HELP_LINK_CSV",
  CONNECT_TO_ESX_VCENTER_SERVER: "CONNECT_TO_ESX_VCENTER_SERVER",
  ADD_IVM: "ADD_IVM",
  CREATE_VMWARE_HYPERV_IVM: "CREATE_VMWARE_HYPERV_IVM",
  REFRESH_HYPERVISOR: "REFRESH_HYPERVISOR",
  FINISH_IVM: "FINISH_IVM",
  // CANCEL_RECOVERY: "CANCEL_RECOVERY",
  RECOVERY_FREE_SPACE_WARNING: "RECOVERY_FREE_SPACE_WARNING",
  SHOW_SQL_ADDITIONAL_DATABASE_OPTIONS_MODAL:
    "SHOW_SQL_ADDITIONAL_DATABASE_OPTIONS_MODAL",
  CANCEL_RECOVERY: "CANCEL_IVM",
  ADD_HYPERVISOR: "ADD_HYPERVISOR",
  CANCEL_RPS_JUMP_START: "CANCEL_RPS_JUMP_START",
  CD_REFRESH_HYPERV: "CD_REFRESH_HYPERV",
  RP_MERGE: "RP_MERGE",
  COLLECT_DIAGNOSTIC_DATA: "COLLECT_DIAGNOSTIC_DATA",
  BACKUP_PASSWORDS_DATA: "BACKUP_PASSWORDS_DATA",
  CONNECT_TO_WIN: "CONNECT_TO_WIN",
  COLLECT_DIAGNOSTIC_BROWSE: "COLLECT_DIAGNOSTIC_BROWSE",
  POLICY_SHARED_FOLDER_DESTINATION_AUTHENTICATE:
    "POLICY_SHARED_FOLDER_DESTINATION_AUTHENTICATE",
  POLICY_FETCH_SHARED_FOLDER_DESTINATION_PATH:
    "POLICY_FETCH_SHARED_FOLDER_DESTINATION_PATH",
  MANAGE_MSP_USER_PERMISSIONS: "MANAGE_MSP_USER_PERMISSIONS",
  RUN_ASSURED_RECOVERY_TEST_BTN: "RUN_ASSURED_RECOVERY_TEST_BTN",
  SOURCE_RUN_ASSURED_RECOVERY_TEST_BTN: "SOURCE_RUN_ASSURED_RECOVERY_TEST_BTN",
  CONFIRM_ORACLE_RECOVERY: "CONFIRM_ORACLE_RECOVERY",
  NUTANIX_STORAGE_LOADER: "NUTANIX_STORAGE_LOADER",
  NUTANIX_NETWORK_LOADER: "NUTANIX_NETWORK_LOADER",
  ORACLE_RECOVERY_BROWSE_BUTTON: "ORACLE_RECOVERY_BROWSE_BUTTON"
};

export const setLoading = name => ({
  type: SET_LOADING,
  name
});

export const resetLoading = name => ({
  type: RESET_LOADING,
  name
});

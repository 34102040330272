import _ from "lodash";
import { call } from "redux-saga/effects";
import * as API_URL from "./apiUrlConstants";
import {
  getData,
  postData,
  putData,
  deleteData,
  testConnectionData
} from "./apiMethods";

// eslint-disable-next-line import/prefer-default-export
export function* getRemoteConsoleList(token, queryString) {
  try {
    const data = yield call(
      getData,
      API_URL.BE_HOST_URL + API_URL.REMOTE_CONSOLE_LIST,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* getSharedFolderList(token, queryString) {
  try {
    const data = yield call(
      getData,
      API_URL.BE_HOST_URL + API_URL.SHARED_FOLDER_LIST,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteRemoteConsole(token, id) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.BE_HOST_URL}${API_URL.REMOTE_CONSOLE_DELETE}/${id}`,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* authenticateSharedFolder(token, payload) {
  try {
    const res = yield call(
      postData,
      `${API_URL.BE_HOST_URL}${API_URL.SHARED_FOLDERS_AUTHENTICATE}`,
      payload,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteSharedFolder(token, id) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.BE_HOST_URL}${API_URL.SHARED_FOLDER_LIST}/${id}`,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* updateSharedFolder(updateData, token, id) {
  try {
    const data = yield call(
      putData,
      `${API_URL.BE_HOST_URL}${API_URL.SHARED_FOLDER_LIST}/${id}`,
      updateData,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
function advancedFilterToQueryString(advancedFilter) {
  let queryString = "";
  advancedFilter.forEach(filter => {
    if (!Array.isArray(filter.selectedOptions)) return;
    let optionsStr = filter.selectedOptions
      .map(item => item.id || item.value)
      .join("%7C");
    if (optionsStr.length > 0) {
      queryString += `&${filter.key}=${optionsStr}`;
    }
  });
  return queryString;
}

export function* getRPSList(
  org_id,
  page,
  pageSize,
  token,
  additionalQueryParam = ""
) {
  try {
    let queryString = `organization_id=${org_id}&page=${page}&page_size=${pageSize}`;
    if (additionalQueryParam !== "") {
      queryString += `&${additionalQueryParam}`;
    }
    const res = yield call(getData, API_URL.GET_RPS_LIST, queryString, token);
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getAllRPSList(org_id, token, additionalQueryParam = "") {
  try {
    let queryString = `organization_id=${org_id}&pagination=false`;
    if (additionalQueryParam !== "") {
      queryString += `&${additionalQueryParam}`;
    }
    const res = yield call(getData, API_URL.GET_RPS_LIST, queryString, token);
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRPS(id, token) {
  try {
    const res = yield call(getData, API_URL.GET_RPS + "/" + id, "", token);
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getDataStore(id, token) {
  try {
    const res = yield call(
      getData,
      API_URL.GET_DATA_STORES + "/" + id,
      "",
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getDataStores(
  org_id,
  page,
  pageSize,
  token,
  searchText = "",
  advancedFilter = [],
  sort = null,
  additionalQueryParam = ""
) {
  let queryString = `organization_id=${org_id}`;
  if (page && pageSize) {
    queryString += `&page=${page}&page_size=${pageSize}`;
  } else {
    queryString += `&pagination=false`;
  }
  if (searchText) {
    queryString += `&datastore_name=${searchText}`;
  }
  if (additionalQueryParam !== "") {
    queryString += `&${additionalQueryParam}`;
  }
  if (advancedFilter.length > 0) {
    queryString += advancedFilterToQueryString(advancedFilter);
  }
  if (sort) {
    //eslint-disable-next-line no-useless-concat
    queryString += "&sort" + "=" + sort;
  }
  try {
    const res = yield call(
      getData,
      API_URL.GET_DATA_STORES,
      queryString,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getAllDataStores(org_id, token, additionalQueryParam = "") {
  let queryString = `organization_id=${org_id}&pagination=false`;

  if (additionalQueryParam !== "") {
    queryString += `&${additionalQueryParam}`;
  }
  try {
    const res = yield call(
      getData,
      API_URL.GET_DATA_STORES,
      queryString,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getPolicies(
  org_id,
  page,
  pageSize,
  token,
  searchText = "",
  advancedFilter = []
) {
  try {
    const res = yield call(
      getData,
      API_URL.GET_POLICIES,
      `organization_id=${org_id}&page=${page}&page_size=${pageSize}&policy_name=${searchText}${advancedFilterToQueryString(
        advancedFilter
      )}`,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteRps(id, token) {
  try {
    const res = yield call(
      deleteData,
      API_URL.DELETE_RPS_LIST + "/" + id,
      token
    );
    return res;
  } catch (e) {
    window.consle.log(e);
  }
}

export function* deleteRpsBulk(id, token) {
  try {
    const res = yield call(deleteData, API_URL.DELETE_RPS_LIST, token, id);
    return res;
  } catch (e) {
    window.consle.log(e);
  }
}

export function* updateRps(id, data, token, showCommonError) {
  try {
    const res = yield call(
      putData,
      API_URL.PUT_RPS + "/" + id,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRpsDataStore(id, queryString, token) {
  try {
    const res = yield call(
      getData,
      API_URL.BE_HOST_URL + API_URL.RPS_DATASTORE_LIST.replace("id", id),
      queryString,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRpsHashMemory(id, data, token) {
  try {
    const res = yield call(
      postData,
      API_URL.RPS_HASHMEMORY.replace("id", id),
      data,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}
export function* addRPSData(token, rpsData, showCommonError = false) {
  try {
    const res = yield call(
      postData,
      API_URL.GET_RPS_LIST,
      rpsData,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* importDataStoreStepOne(token, dataStoreObject) {
  try {
    const res = yield call(
      postData,
      API_URL.IMPORT_DATA_STORE_STEP_ONE,
      dataStoreObject,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* importCloudDataStoreStepOne(token, dataStoreObject) {
  try {
    const res = yield call(
      postData,
      API_URL.IMPORT_CLOUD_DATA_STORE_STEP_ONE,
      dataStoreObject,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* importCloudDataStoreStepTwo(token, dataStoreObject) {
  try {
    const res = yield call(
      postData,
      API_URL.IMPORT_CLOUD_DATA_STORE_STEP_TWO,
      dataStoreObject,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRpsDataStoreSources(id, data, token) {
  try {
    const res = yield call(
      getData,
      API_URL.BE_HOST_URL + API_URL.RPS_DATASTORE_SOURCE_LIST.replace("id", id),
      "",
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getEndPointList(token, req) {
  try {
    const composedURL = API_URL.END_POINTS_LIST.replace(
      "{id}",
      req.cloud_account_id
    );
    const data = yield call(
      getData,
      API_URL.BE_HOST_URL + composedURL,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getEndPointListByRegion(token, req) {
  try {
    const composedURL = API_URL.END_POINTS_LIST_BY_REGION.replace(
      "{id}",
      req.cloud_account_id
    );

    let payload = {
      region_name: req?.region
    };

    const data = yield call(
      postData,
      API_URL.BE_HOST_URL + composedURL,
      payload,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getCloudAccountList(token, queryString) {
  try {
    const data = yield call(
      getData,
      API_URL.BE_HOST_URL + API_URL.CLOUD_ACCOUNT_LIST,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getShadowXafeDestinations(token, queryString) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_STORAGE_DESTINATIONS,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* cloudAccountListByType(token, queryString) {
  try {
    const data = yield call(
      getData,
      API_URL.BE_HOST_URL + API_URL.CLOUD_ACCOUNT_LIST,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRegionsForCloudAccount(token, data) {
  try {
    const resp = yield call(
      getData,
      `${API_URL.BE_HOST_URL}${API_URL.CLOUD_ACCOUNT_LIST}/${data}/${API_URL.REGIONS}`,
      "",
      token
    );
    return resp;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRegionsForCloudAccountV3(token, data) {
  try {
    const resp = yield call(
      getData,
      `${API_URL.BE_HOST_URL}/v3${API_URL.CLOUD_ACCOUNT_LIST}/${data}/${API_URL.REGIONS}`,
      "",
      token
    );
    return resp;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getBucketsForCloudAccount(token, data) {
  try {
    const resp = yield call(
      getData,
      `${API_URL.BE_HOST_URL}${API_URL.CLOUD_ACCOUNT_LIST}/${data}/${API_URL.BUCKETS}`,
      "",
      token
    );
    return resp;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getResouceGroupForCloudAccount(token, data) {
  try {
    const resp = yield call(
      getData,
      `${API_URL.BE_HOST_URL}${API_URL.CLOUD_ACCOUNT_LIST}/${data}/${API_URL.RESOURCE_GROUPS}`,
      "",
      token
    );
    return resp;
  } catch (e) {
    window.console.log(e);
  }
}

export function* connectSharedFolder(data, token) {
  try {
    const res = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.CONNECT_SHARED_FOLDER_SOURCE,
      data,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* onSessionPasswordApply(data, token) {
  try {
    const res = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.ON_SESSION_PASSWORD,
      data,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}
export function* addDataStoreData(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.ADD_DATA_STORE}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* addCloudDataStoreData(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.ADD_CLOUD_DATA_STORE}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* saveJumpStart(id, data, token) {
  try {
    const res = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.JUMP_START_SAVE.replace("id", id),
      data,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* importDataStoreStepTwo(token, dataStoreObject) {
  try {
    const res = yield call(
      postData,
      API_URL.IMPORT_DATA_STORE_STEP_TWO,
      dataStoreObject,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getPolicyListForJumpstart(token, id) {
  try {
    const res = yield call(
      getData,
      API_URL.BE_HOST_URL + API_URL.POLICY_LIST_JUMPSTART.replace("id", id),
      "",
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteCloudAccount(token, id, showCommonError = true) {
  try {
    const delData = yield call(
      deleteData,
      `${API_URL.BE_HOST_URL}${API_URL.CLOUD_ACCOUNT_DELETE}/${id}`,
      token,
      null,
      showCommonError
    );
    return delData;
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteAllCloudAccount(token, data) {
  try {
    const delData = yield call(
      deleteData,
      `${API_URL.BE_HOST_URL}${API_URL.CLOUD_ACCOUNT_DELETE}`,
      token,
      data,
      true /* showCommonError */
    );
    return delData;
  } catch (e) {
    window.console.log(e);
  }
}

export function* testCloudAccountConnection(token, id, showCommonError = true) {
  try {
    const testData = yield call(
      testConnectionData,
      `${API_URL.BE_HOST_URL}${API_URL.CLOUD_ACCOUNT_LIST}/${id}${API_URL.CLOUD_ACCOUNT_TEST_CONNECTION}`,
      token,
      null,
      showCommonError
    );
    return testData;
  } catch (e) {
    window.console.log(e);
  }
}

export function* modifyDataStore(id, data, token) {
  try {
    const result = yield call(
      putData,
      `${API_URL.GET_DATA_STORES}/${id}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* modifyCloudDataStore(id, data, token) {
  try {
    const result = yield call(
      putData,
      `${API_URL.UPDATE_CLOUD_DATA_STORE}/${id}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteDataStoreByID(token, ids) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.BE_HOST_URL}${API_URL.DATA_STORE_BASE}`,
      token,
      ids
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* removeDataStoreByID(token, id, forceDelete) {
  try {
    const data = yield call(
      postData,
      `${API_URL.BE_HOST_URL}${API_URL.DATA_STORE_BASE}/${id[0]}`,
      { force_delete: forceDelete },
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* startDataStoreByID(token, ids) {
  try {
    const data = yield call(
      postData,
      `${API_URL.BE_HOST_URL}${API_URL.DATA_STORE_BASE}/${API_URL.DATA_STORE_START}`,
      ids,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* stopDataStoreByID(token, ids, showCommonError = true) {
  try {
    const data = yield call(
      postData,
      `${API_URL.BE_HOST_URL}${API_URL.DATA_STORE_BASE}/${API_URL.DATA_STORE_STOP}`,
      ids,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getDataStoreByID(token, id) {
  try {
    const data = yield call(
      getData,
      `${API_URL.BE_HOST_URL}${API_URL.DATA_STORE_BASE}/${id}`,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* installDeployRps(token, data) {
  try {
    const res = yield call(postData, API_URL.DEPLOY_SINGLE_RPS, data, token);
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* installDeployRpsBulk(token, data) {
  try {
    const res = yield call(postData, API_URL.DEPLOY_BULK_RPS, data, token);
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getBrowseRecoveryPointDSList({ datastoreID }, token) {
  const composedURL = API_URL.BROWSE_RECOVERY_POINTS_DS_URL.replace(
    "{id}",
    datastoreID
  );

  try {
    return yield call(getData, composedURL, "", token);
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteSource(token, id) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.BROWSE_RECOVERY_POINTS_DS_DELETE.replace("{id}", id)}`,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* refreshSource(token, id) {
  try {
    const data = yield call(
      postData,
      `${API_URL.BROWSE_RECOVERY_POINTS_DS_REFERSH_URL.replace("{id}", id)}`,
      id,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* refreshRecoveryPoint(token, sourceId, dataStoreId) {
  try {
    const data = yield call(
      getData,
      `${API_URL.REFRESH_RECOVERY_POINTS_DS_URL}${dataStoreId}/sources?source_id=${sourceId}`,
      "",
      token,
      "",
      true
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* mergeSource(token, id) {
  try {
    const data = yield call(
      postData,
      `${API_URL.BROWSE_RECOVERY_POINTS_DS_MERGE_NOW.replace("{id}", id)}`,
      id,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRetention(token, payload) {
  try {
    const data = yield call(
      postData,
      `${API_URL.BROWSE_RECOVERY_POINTS_DS_GET_RETENTION.replace(
        "{id}",
        payload[0].id
      )}`,
      payload,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* mergeNow(token, payload) {
  try {
    const data = yield call(
      postData,
      `${API_URL.BROWSE_RECOVERY_POINTS_DS_MERGE_NOW.replace(
        "{id}",
        payload[0].id
      )}`,
      payload,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* purgeDataStore(token, id, payload) {
  try {
    const data = yield call(
      postData,
      `${API_URL.BROWSE_RECOVERY_POINTS_DS_PURGE.replace("{id}", id)}`,
      payload,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getPolicyData({ policyId }, token) {
  const composedURL = API_URL.GET_POLICY_BY_ID.replace("{id}", policyId);
  try {
    return yield call(getData, composedURL, policyId, token);
  } catch (e) {
    window.console.log(e);
  }
}

export function* postAvailableVolumes(data, token) {
  try {
    return yield call(
      postData,
      API_URL.RECOVERY_AVAILABLE_VOLUMES,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}
export function* getAvailableDrivesFromSource(
  token,
  payload,
  showCommonError = true
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.AVAILABLE_DRIVE_LETTERS}`,
      payload,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getDestinationsDataBySourceId({ data: sourceId }, token) {
  const composedURL = API_URL.GET_DESTINATIONS_BY_SOURCE_ID.replace(
    "{id}",
    sourceId
  );
  try {
    return yield call(getData, composedURL, sourceId, token);
  } catch (e) {
    window.console.log(e);
  }
}

export function* postMountedVolumes(data, token) {
  try {
    return yield call(postData, API_URL.RECOVERY_MOUNTED_VOLUMES, data, token);
  } catch (e) {
    window.console.log(e);
  }
}

export function* postDismountVolume(data, token) {
  try {
    return yield call(postData, API_URL.RECOVERY_DISMOUNT_VOLUME, data, token);
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSourcesWithWindowsFilter(token, queryParams) {
  try {
    const data = yield call(
      getData,
      `${API_URL.GET_SOURCES}`,
      queryParams,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRecoveryPointItems(
  { data },
  token,
  showCommonError = false
) {
  try {
    const res = yield call(
      postData,
      API_URL.GET_RECOVERY_POINT_ITEMS,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRecoveryPointItemsBySourceId(
  { data },
  token,
  showCommonError = false
) {
  try {
    const composedURL = API_URL.GET_RECOVERY_POINT_ITEMS_BY_SOURCE_ID.replace(
      "{id}",
      _.get(data, "mount_server.server_id", "")
    );
    const res = yield call(postData, composedURL, data, token, showCommonError);
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRecoveryPointOracleDatabaseConfigurations(
  { data },
  token,
  showCommonError = true
) {
  try {
    const composedURL = API_URL.GET_RECOVERY_ORACLE_DATABASE_CONFIGURATIONS.replace(
      "{id}",
      _.get(data, "source_id", "")
    );
    const res = yield call(postData, composedURL, data, token, showCommonError);
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postMountRecoveryPoint(token, payload) {
  try {
    const data = yield call(
      postData,
      `${API_URL.MOUNT_RECOVERY_POINT}`,
      payload,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchCloudAccountsConfigurations(
  token,
  data,
  id,
  showCommonError = true
) {
  try {
    const res = yield call(
      postData,
      API_URL.FETCH_CLOUD_CONFIGURATIONS.replace("{id}", id),
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchCloudAccountSubnet(
  token,
  data,
  id,
  showCommonError = true
) {
  try {
    const res = yield call(
      postData,
      API_URL.FETCH_CLOUD_SUBNET.replace("{id}", id),
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* ivmValidateNFS(token, data, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.IVM_VALIDATE_NFS,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* ivmValidateHyperV(token, data, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.IVM_VALIDATE_HYPERV,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateOraclePath(token, id, data, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.VALIDATE_ORACLE_PATH.replace("{id}", id),
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Holdes constants definitions for Sources
 */
const SourceConstants = {
  /**
   * Enumrates list of source attributes known to the UI.
   */
  SourceAttributes: {
    SOURCE_PROP_ID: "source_id",
    SOURCE_PROP_TYPE: "source_type",
    SOURCE_PROP_NAME: "source_name",
    SOURCE_PROP_PROTECTION: "protection_status",
    SOURCE_PROP_CONNECTION: "connection_status",
    SOURCE_PROP_POLICY: "policy",
    SOURCE_PROP_POLICY_ORIGINAL: "_policy",
    SOURCE_PROP_LAST_RECOVERY_POINT: "last_recovery_point_ts",
    SOURCE_PROP_LAST_JOB: "last_job",
    SOURCE_PROP_LAST_JOB_BY_NAME: "lastJobByName",
    SOURCE_PROP_LAST_JOB_BY_STATUS: "lastJobByStatus",
    SOURCE_PROP_LAST_JOB_BY_DATETIME: "lastJobByDatetime",
    SOURCE_PROP_SOURCE_GROUP: "source_group",
    SOURCE_PROP_SOURCE_GROUP_ORIGINAL: "_source_group",
    SOURCE_PROP_NEXT_SCHEDULE_JOB: "nextScheduledJob",
    SOURCE_PROP_AGENT_OBJECT: "agent",
    SOURCE_PROP_AGENT: "agent.agent_name",
    SOURCE_PROP_HYPERVISOR: "hypervisor",
    SOURCE_PROP_HYPERVISOR_NAME: "hypervisor.hypervisor_name",
    SOURCE_PROP_OS_NAME: "operating_system.os_name",
    SOURCE_PROP_OS: "operating_system",
    SOURCE_PROP_OS_MAJOR: "os_major",
    //SOURCE_PROP_SITE: "site.site_name",
    SOURCE_PROP_SITE: "site",
    SOURCE_PROP_SITE_NAME: "site_name",
    SOURCE_PROP_LATEST_JOB: "latestJob",
    SOURCE_PROP_LATEST_JOB_NAME: "name",
    SOURCE_PROP_LATEST_JOB_STATUS: "status",
    SOURCE_PROP_LATEST_JOB_DATE: "date",
    SOURCE_VM_NAME: "vm_name",
    SOURCE_PROP_ACTIONS: "available_actions",
    SOURCE_PROP_ORG_NAME: "organization_name",
    SOURCE_PROP_ADD_HYPERVISOR: "add-hypervisor",
    SOURCE_PROP_ADD_O365_SOURCE: "add-o365-source",
    SOURCE_PROP_ADD_WINDOW_SOURCE: "add-window-source",
    SOURCE_PROP_ADD_LINUX_SOURCE: "add-linux-source",
    SOURCE_PROP_ADD_LINUX_BACKUP_SERVER_SOURCE: "add-linuxbackup-source",
    SOURCE_PROP_DISCOVER_DIRECTORY: "discover-directory",
    SOURCE_PROP_ADD_UNC_OR_NFS_PATH_SOURCE: "add-unc-nfs-path-source",
    SOURCE_PROP_ADD_ORACLE_SOURCE: "add-oracle-source",
    SOURCE_PROP_ADD_ORACLE_DATABASE: "add-oracle-database",
    SOURCE_PROP_EXCLUSIONS: "exclusions",
    SOURCE_PROP_INVENTORY: "inventory",
    SOURCE_PROP_VIRTUAL_DISK_EXCLUSION: "virtual_disk_exclusion",
    SOURCE_PROP_CREATE_TS: "create_ts"
  },

  /**
   * Returns an i18n supported key for the identifier
   * @param {string} identifier
   */
  getDisplayIdentifier(identifier) {
    return "protect.sources.".concat(identifier);
  },

  /**
   * Contextual actions for sources
   */
  CONTEXTUAL_ACTION: {
    UPGRADE_AGENT: "upgradeagent",
    INSTALL_AGENT: "install_agent",
    DELETE: "delete",
    REMOVE: "remove",
    RESTORE: "startrecovery",
    CANCEL_RECOVERY: "cancelrecovery",
    START_BACKUP: "startbackup",
    STOP_BACKUP: "stopbackup",
    CANCEL_BACKUP: "cancelbackup",
    CANCEL_JOB: "canceljob",
    DELETE_JOB: "deletejob",
    SOURCE_GROUP: "assigngroup",
    CREATE_VM: "create_instant_vm",
    DOWNLOAD_AGENT: "downloadagent",
    ASSIGN_POLICY: "assignpolicy",
    REMOVE_POLICY: "unassignpolicy",
    PROVISION: "provision",
    CANCEL_REPLICATION: "cancelrepliationin",
    CANCEL_REPLICATION_OUT: "cancelrepliationout",
    START_REPLICATION_OUT: "startrepliationout",
    REPLICATION: "replication",
    BACKUP_VMS: "convert_to_agentless",
    DEPLOY: "deploy_policy",
    LAUNCH_UDP_AGENT_UI: "launch_udp_agent_ui",
    UPDATE: "update",
    HEARTBEAT_PAUSE: "heart_beat_pause",
    HEARTBEAT_RESUME: "heart_beat_resume",
    STANDBY_VM: "standbyvm",
    STANDBY_VM_NETWORK_CONFIGURATION: "standbyvmnetworkconfiguration",
    VIRTUAL_STANDBY_PAUSE: "vsb_pause",
    VIRTUAL_STANDBY_RESUME: "vsb_resume",
    VIEW_SOURCE: "view_source",
    COLLECT_DIAGNOSTIC_DATA: "collect_diagnostic_data",
    START_AR_TEST: "start_ar_test",
    SET_BACKUP_PASSWORDS: "set_backup_passwords"
  },
  SUCCESS_MSG: {
    cancelrepliationin: "Canceled Replication",
    cancelrepliationout: "Canceled replication(Out) success",
    startrepliationout: "Replication started success",
    provision: "Provision"
  },
  ORACLE_DB_SOURCE_CHANNELS_OPTIONS: [
    {
      key: 1,
      value: 1
    },
    {
      key: 2,
      value: 2
    },
    {
      key: 3,
      value: 3
    },
    {
      key: 4,
      value: 4
    },
    {
      key: 5,
      value: 5
    },
    {
      key: 6,
      value: 6
    },
    {
      key: 7,
      value: 7
    },
    {
      key: 8,
      value: 8
    },
    {
      key: 9,
      value: 9
    },
    {
      key: 10,
      value: 10
    }
  ]
};

export default SourceConstants;

/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import _ from "lodash";
import { CLOUD_TYPES } from "components/protect/destination/DestinationConstants";
import * as DCs from "components/protect/policy/create-policy/destinations/DestinationConstants";
import {
  VALIDATION_REGEX,
  RESERVED_IPV4_VALUES,
  COPY_TO_DS,
  RP_MIN_SIZE_FOR_ORACLE_POLICY,
  RP_MAX_SIZE_FOR_ORACLE_POLICY
} from "../../../utils/appConstants";
import { CustomError } from "../error";

export const notNull = value =>
  value !== null ? undefined : CustomError("custom-input-error.required");
// For chacking if the activity type is valid for some secondary task.
export const notValidType = (value, formValue) =>
  checkActivityType(value, formValue)
    ? CustomError("custom-input-error.notValid")
    : undefined;

export const required = value =>
  value ? undefined : CustomError("custom-input-error.required");

export const scheduleRepeatIntervalMinValidation = value =>
  value > 0
    ? undefined
    : CustomError("custom-input-error.min-repeat-interval-limit");

export const scheduleMinuteRepeatIntervalMinValidation = value =>
  value >= 15
    ? undefined
    : CustomError("custom-input-error.minute.min-repeat-interval-limit");

export const isBoolean = value =>
  value === true || value === false
    ? undefined
    : CustomError("custom-input-error.required");

export const validateIp = value => {
  if (value) {
    if (
      VALIDATION_REGEX.IPV4_VALIDATOR.test(value) ||
      VALIDATION_REGEX.IPV6_VALIDATOR.test(value)
    ) {
      if (RESERVED_IPV4_VALUES.indexOf(value) < 0) {
        return undefined;
      }
      return CustomError("custom-input-error.invalid_ip");
    }
    return CustomError("custom-input-error.invalid_ip");
  }
  return undefined;
};
export const validateIpBoolean = value => {
  if (value) {
    if (
      VALIDATION_REGEX.IPV4_VALIDATOR.test(value) ||
      VALIDATION_REGEX.IPV6_VALIDATOR.test(value)
    ) {
      if (RESERVED_IPV4_VALUES.indexOf(value) < 0) {
        return true;
      }
      return false;
    }
    return false;
  }
  return false;
};

export const validateIpAddress = value => {
  if (value) {
    if (
      VALIDATION_REGEX.IPV4_VALIDATOR.test(value) ||
      VALIDATION_REGEX.IPV6_VALIDATOR.test(value)
    ) {
      if (RESERVED_IPV4_VALUES.indexOf(value) < 0) {
        return undefined;
      }
      return CustomError("custom-input-error.invalid_ipaddress");
    }
    return CustomError("custom-input-error.invalid_ipaddress");
  }
  return undefined;
};

export const validateHostNameIP = value => {
  // Validated the host name for valid ipv4 or Fully Qualified Domain Name
  if (
    value &&
    (VALIDATION_REGEX.IPV4_VALIDATOR.test(value) ||
      VALIDATION_REGEX.IPV6_VALIDATOR.test(value))
  ) {
    if (RESERVED_IPV4_VALUES.indexOf(value) < 0) {
      return undefined;
    }
    return CustomError("custom-input-error.invalid_hostname_or_ip");
  }
  if (value && VALIDATION_REGEX.FQDN_VALIDATOR.test(value)) {
    return undefined;
  }
  if (value && VALIDATION_REGEX.RFC1123_HOST_NAME_VALIDATOR.test(value)) {
    return undefined;
  }
  return CustomError("custom-input-error.invalid_hostname_or_ip");
};

export const validateHostNameIPWithPort = value => {
  // Validated the host name for valid ipv4 or Fully Qualified Domain Name
  if (
    value &&
    (VALIDATION_REGEX.IPV4_VALIDATOR.test(value) ||
      VALIDATION_REGEX.IPV6_VALIDATOR.test(value))
  ) {
    if (RESERVED_IPV4_VALUES.indexOf(value) < 0) {
      return undefined;
    }
    return CustomError("custom-input-error.invalid_hostname_or_ip");
  }
  if (value && VALIDATION_REGEX.FQDN_VALIDATOR.test(value)) {
    return undefined;
  }
  if (
    value &&
    VALIDATION_REGEX.RFC1123_HOST_NAME_VALIDATOR_WITH_PORT.test(value)
  ) {
    return undefined;
  }
  return CustomError("custom-input-error.invalid_hostname_or_ip");
};

export const validateSourceNameIP = value => {
  // Validated the source name for valid ipv4 or Fully Qualified Domain Name
  if (
    value &&
    (VALIDATION_REGEX.IPV4_VALIDATOR.test(value) ||
      VALIDATION_REGEX.IPV6_VALIDATOR.test(value))
  ) {
    if (RESERVED_IPV4_VALUES.indexOf(value) < 0) {
      return undefined;
    }
    return CustomError("custom-input-error.invalid_sourcename_or_ip");
  }
  if (value && VALIDATION_REGEX.FQDN_VALIDATOR.test(value)) {
    return undefined;
  }
  if (value && VALIDATION_REGEX.RFC1123_HOST_NAME_VALIDATOR.test(value)) {
    return undefined;
  }
  return CustomError("custom-input-error.invalid_sourcename_or_ip");
};

export const validateHostName = value => {
  if (
    value &&
    VALIDATION_REGEX.RFC1123_HOST_NAME_VALIDATOR.test(value) &&
    value.indexOf(".") === -1
  ) {
    return undefined;
  }
  return CustomError("custom-input-error.invalid_hostname");
};

export const validateHostNameAsPerUDP = value => {
  if (value && VALIDATION_REGEX.HOST_NAME_VALIDATOR_AS_PER_UDP.test(value)) {
    return undefined;
  }
  return CustomError("custom-input-error.invalid_hostname");
};

export const maxLength = max => value =>
  value && value.length > max
    ? CustomError("custom-input-error.max-character", { max })
    : undefined;
export const minLength = min => value =>
  value && value.length < min
    ? CustomError("custom-input-error.min-character", { min })
    : undefined;

export const hexCode = value =>
  (value && !VALIDATION_REGEX.BRANDING_COLOR_CODE.test(value)) || value === ""
    ? CustomError("branding.invalid-hex")
    : undefined;

export const isValidAlphaNumeric = value =>
  (value && !VALIDATION_REGEX.POLICY_ALPHANUMERIC_NAME.test(value)) ||
  value === ""
    ? CustomError("custom-input-error.alpha-numeric")
    : undefined;

export const isValidAlphaNumericPath = value =>
  (value && !VALIDATION_REGEX.ALLOWED_ALPHANUMERIC_PATH.test(value)) ||
  value === ""
    ? CustomError("custom-input-error.path")
    : undefined;

export const websiteAddress = value =>
  value && !VALIDATION_REGEX.WEBSITE_VALIDATOR.test(value)
    ? CustomError("invalid_website")
    : undefined;

export const isValidPhoneNumber = value =>
  value && !VALIDATION_REGEX.PHONE_NUMBER_VALIDATOR.test(value)
    ? CustomError("invalid_phone_number")
    : undefined;

export const isValidSKU = value => {
  if (value) {
    const sku = JSON.parse(value);
    return (sku.NACDICMPSLWAADS01 > 0 || sku.NACDICMPSLWAIPS01 > 0) &&
      (sku.NACDICMPSLWC01S01 === undefined || sku.NACDICMPSLWC01S01 === 0)
      ? CustomError("custom-input-error.sku")
      : undefined;
  }
  return undefined;
};

export const isValidDayOfMonth = value =>
  value && value >= 1 && value <= 28 ? undefined : CustomError("invalid_date");

export const isValidDatabaseName = value => {
  return value && !VALIDATION_REGEX.VALID_DATABASE_NAME.test(value)
    ? CustomError("invalid_database_name")
    : undefined;
};

export const isValidDatabaseNamePassword = value => {
  return value && !VALIDATION_REGEX.VALID_DATABASE_PASSWORD.test(value)
    ? CustomError("invalid_database_password")
    : undefined;
};
export const isValidPortForOracleDB = value =>
  !isNaN(value) && value >= 1024 && value <= 49151
    ? undefined
    : CustomError("invalid_oracle_db_port_name");

export const validSchedulesCount = (scheduleType, formValues) => {
  let schedules = _.get(formValues, "schedules", []) || [];
  const taskId = _.get(formValues, "selectedTaskId");
  schedules = Object.keys(schedules)
    .map(i => ({ idx: i, value: schedules[i] }))
    .filter(s => _.get(s, "value.task_id", null) === taskId);
  const list = returnSchedules(schedules);
  const frequencyinlist = _.countBy(list, ele => ele);
  const scheduleTypeExists =
    scheduleType === "custom" ? false : frequencyinlist[scheduleType] > 1;
  // eslint-disable-next-line eqeqeq
  return scheduleTypeExists == false
    ? undefined
    : CustomError("custom-input-error.uniqueSchedule");
};

export const returnSchedules = schedules => {
  // returns the list of schedules used to find duplicate schedules and also in adding next schedule's schedule type
  const listOfSchedules = [];
  // eslint-disable-next-line array-callback-return
  schedules.map(ele => {
    const schedule = ele.value;
    const scheduleType = schedule.schedule_type;
    if (scheduleType) {
      listOfSchedules.push(scheduleType);
    }
  });
  return listOfSchedules;
};

export const customScheduleMatches = (schedules, currentSchedule) => {
  // used in finding overlap of custom schedules
  const currentScheduleStartTime = currentSchedule.start_time;
  const currentScheduleBackupType =
    currentSchedule.schedule.custom_schedule.backup_type;
  const currentScheduleDaysofWeek =
    currentSchedule.schedule.custom_schedule.days_of_week;
  if (!currentScheduleDaysofWeek) return false;
  const filteredSchedules = schedules.filter(ele => {
    const schedule = ele.value;
    const scheduleType = schedule.schedule_type;
    return scheduleType === "custom";
  });
  const SchedulesArray = filteredSchedules.map(ele => ele.value);
  // frequency count
  const commonDays = {
    "0": 0,
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0,
    "6": 0
  };
  // commonDaysArray keep track of  all repeating schedules days
  const commonDaysArray = [];
  // doesErrorExist keep track of matching of all 3 backuptype time and schedule days for current schedule
  // but the liens above teh return take note of all schedules
  const doesErrorExist = SchedulesArray.filter(ele => {
    try {
      const arr = ele.schedule.custom_schedule.days_of_week.split(",");
      currentScheduleDaysofWeek.split(",").forEach(dayOfWeek => {
        if (arr.includes(dayOfWeek)) commonDays[dayOfWeek] += 1;
      });
      for (const key1 in commonDays) {
        if (commonDays[key1] > 1) commonDaysArray.push(key1);
      }
      return (
        ele.start_time === currentScheduleStartTime &&
        ele.schedule.custom_schedule.backup_type ===
          currentScheduleBackupType &&
        arr.some(r => currentScheduleDaysofWeek.includes(r))
      );
    } catch (e) {
      return false;
    }
  });
  const filterFunc = (value, index, self) => self.indexOf(value) === index;
  const FilteredCommonDays = commonDaysArray.filter(filterFunc);
  if (doesErrorExist.length > 1) return FilteredCommonDays.sort();
  return [];
};

export const validCustomSchedule = (value, formValues, props, name) => {
  let index = null;
  const checkRegex = name.match(/\[(.*?)\]/);
  if (checkRegex) {
    // eslint-disable-next-line prefer-destructuring
    index = checkRegex[1];
  }
  if (index == null) return "Error";
  let schedules = _.get(formValues, "schedules", []) || [];
  const taskId = _.get(formValues, "selectedTaskId");
  schedules = Object.keys(schedules)
    .map(i => ({ idx: i, value: schedules[i] }))
    .filter(s => _.get(s, "value.task_id", null) === taskId);
  let customSchedule = [];
  if (schedules[index] && schedules[index].value.schedule_type === "custom")
    customSchedule = customScheduleMatches(schedules, schedules[index].value);
  // eslint-disable-next-line  no-mixed-operators
  return customSchedule.length > 0 === false
    ? undefined
    : CustomError("custom-input-error.uniqueSchedule");
};

export const validIntervalValue = (interval, intervalUnit) => {
  if (intervalUnit === "hours") {
    return interval < 11 && interval > 0
      ? undefined
      : CustomError("custom-input-error.interval");
  }
  if (intervalUnit === "minutes") {
    return interval <= 600 && interval >= 15
      ? undefined
      : CustomError("custom-input-error.interval");
  }
  return undefined;
};

export const isValidScheduleDays = value =>
  value && value !== "" ? undefined : CustomError("invalid");

export const notEmpty = value => {
  if (Array.isArray(value) && value.length > 0) {
    return undefined;
  }
  if (typeof value === "string" && value.length > 0) {
    return undefined;
  }

  return CustomError("custom-input-error.empty");
};

export const isValidRPSizeLimit = value => {
  if (value) {
    if (
      value >= RP_MIN_SIZE_FOR_ORACLE_POLICY &&
      value <= RP_MAX_SIZE_FOR_ORACLE_POLICY
    ) {
      return undefined;
    }
    if (value < RP_MIN_SIZE_FOR_ORACLE_POLICY) {
      return CustomError("custom-input-error.min-rps-limit", {
        min: RP_MIN_SIZE_FOR_ORACLE_POLICY
      });
    }
    if (value > RP_MAX_SIZE_FOR_ORACLE_POLICY) {
      return CustomError("custom-input-error.max-rps-limit", {
        max: RP_MAX_SIZE_FOR_ORACLE_POLICY
      });
    }
  }
};

export const retryMinutes = value =>
  value && value >= 1 && value <= 60
    ? undefined
    : CustomError("custom-input-error.retry-mins");

export const retryTimes = value =>
  value && value >= 1 && value <= 99
    ? undefined
    : CustomError("custom-input-error.retry-times");

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? CustomError("custom-input-error.email")
    : undefined;

export const requiredTerms = value =>
  value ? undefined : CustomError("custom-input-error.required-terms");

export const minMaxLimitsForNumberInput = (min, max) => value => {
  const newValue = parseInt(value);
  return newValue && !(newValue >= min && newValue <= max)
    ? CustomError("custom-input-error.min_max_for_integer_input", {
        min,
        max
      })
    : newValue === 0 && min > 0
    ? CustomError("custom-input-error.min_max_for_integer_input", {
        min,
        max
      })
    : undefined;
};
// export const isValidAnniversaryDate = value => {
//   value = parseInt(value);
//   return value && !(value >= 1 && value <= 31)
//     ? CustomError("custom-input-error.anniversary-validation")
//     : undefined;
// };

export const isNumber = value =>
  !isNaN(value) ? undefined : CustomError("custom-input-error.number");

export const equalTenDigits = value =>
  value && value.length !== 10
    ? CustomError("custom-input-error.10digits")
    : undefined;

export const isNatural = value =>
  !isNaN(value) && Number.isInteger(+value) && +value > 0
    ? undefined
    : CustomError("custom-input-error.positive-number");

export const isPositiveNumber = value =>
  !value || (!isNaN(value) && Number.isInteger(+value) && +value >= 0)
    ? undefined
    : CustomError("custom-input-error.positive-number");

// Added japanese characters to the Regex
// 一-龠 is Kanji
// ぁ-ゔ is hiragana
// ァ-ヴー is katakana

export const isWindowsPath = value =>
  /^[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z]:\\/.test(value)
    ? undefined
    : CustomError("custom-input-error.window-path");

// refer
// https://www.webdeveloper.com/forum/d/145392-file-path-format-validation
// https://stackoverflow.com/questions/28989672/regex-to-tell-if-a-string-contains-a-linux-file-path-or-if-a-linux-file-path-as
export const isLinuxPath = value =>
  /^((\/[^(/\0)]+))|^[/]$/.test(value)
    ? undefined
    : CustomError("custom-input-error.linux-path");

// “_” or “-“ or “.”
// https://stackoverflow.com/questions/28167080/what-is-a-minimal-valid-unc-path
// For Bug 891945 Allow UNC paths like \\test\ in the local copy path, and file folder path
export const isNetworkPath = value =>
  /^\\\\([a-zA-Z0-9一-龠ぁ-ゔァ-ヴー_\-.])+(\\)+([a-zA-Z0-9一-龠ぁ-ゔァ-ヴー_])*/.test(
    value
  )
    ? undefined
    : CustomError("custom-input-error.network-path");
export const isValidLocalVolumePath = value =>
  /^[a-zA-Z]:\\[\\\S|*\S]?.*$/.test(value)
    ? undefined
    : CustomError("custom-input-error.network-path");

export const isValidVolumeGUIDPathBool = value =>
  /^\\\\[?]\\([a-zA-Z0-9{}\\_-])*/.test(value) ? true : false;

const TRUE = true;
export const isValidLocalPathForConnect = value =>
  /(?:^|\s)((\\\\\?\\)[A-Za-z0-9{}\\-]*)/.test(value) ? TRUE : false;

export const isValidLocalVolumePathBoolean = value =>
  /^[a-zA-Z]:\\[\\\S|*\S]?.*$/.test(value) ? TRUE : false;

export const isValidSharedFolderPath = value =>
  /^\\\\([a-zA-Z0-9一-龠ぁ-ゔァ-ヴー_\-.])+(\\)+(([^(/)]+))*$/.test(value)
    ? undefined
    : CustomError("custom-input-error.network-path");

export const isValidSharedFolderPathBoolean = value =>
  /^\\\\([a-zA-Z0-9一-龠ぁ-ゔァ-ヴー_\-.])+(\\)+(([^(/)]+))*$/.test(value)
    ? TRUE
    : false;

export const isPath = value =>
  isNetworkPath(value) === undefined ||
  isWindowsPath(value) === undefined ||
  isLinuxPath(value) === undefined
    ? undefined
    : CustomError("custom-input-error.path");

export const isPort = value =>
  !isNaN(value) && value > 0 && value < 65536
    ? undefined
    : CustomError("Must be a valid port number");

export const passwordsMatch = (value, allValues) =>
  value !== allValues.create_password
    ? CustomError("custom-input-error.pwd-not-match")
    : undefined;

export const passwordsCheck = (value, allValues, ev, name) => {
  const childObj = getCorrespondingObject(name, allValues);
  return value !== childObj.password
    ? CustomError("custom-input-error.pwd-not-match")
    : undefined;
};

export const passwordFormat = value =>
  value &&
  // eslint-disable-next-line  no-control-regex
  !/^(?:(?=.*([^\u0000-\u007F])+)|(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{8,}$/g.test(
    value
  );

export const dataStoreHashMemory = (value, allValues) =>
  value &&
  parseInt(value) <= (allValues.maxHash || 4095) &&
  parseInt(value) >= (allValues.minHash || 1024)
    ? undefined
    : CustomError("invalid_value");

export const isValidDateFormat = value =>
  value && /([12]\d{3}-([1-9]|1[0-2])-([1-9]|[12]\d|3[01]))/.test(value);

export const dataStorePasswordsMatch = (value, allValues) =>
  value &&
  value.length &&
  allValues &&
  allValues.encryptionPassword &&
  allValues.encryptionPassword.length &&
  value !== allValues.encryptionPassword
    ? CustomError("custom-input-error.pwd-not-match")
    : undefined;

export const validatePercentage = value =>
  value && value >= 0 && value <= 100
    ? undefined
    : CustomError("invalid_value");

export const isEmpty = value => (value ? undefined : CustomError("Required"));

export const handleBlankSpaces = value =>
  value && value.trim()
    ? undefined
    : CustomError("custom-input-error.handle-blank-spaces");

export const handleLongSiteName = value =>
  value && value.trim() && value.trim().length <= 256
    ? undefined
    : CustomError("site.add_site.error.site_name_max_length");

export const isInstallationPath = value =>
  VALIDATION_REGEX.INSTALLATION_PATH_VALIDATOR.test(value)
    ? undefined
    : CustomError("custom-input-error.window-path");

export const isValidInstallationPath = value =>
  isInstallationPath(value) === undefined ||
  isWindowsPath(value) === undefined ||
  isLinuxPath(value) === undefined
    ? undefined
    : CustomError("custom-input-error.path");

export const isValidLocalPath = value =>
  /^[a-zA-Z]:\\[\\\S|*\S]?.*$/.test(value)
    ? undefined
    : CustomError("custom-input-error.local-path");

export const isValidPath = value =>
  /^[a-zA-Z]:\\[\\\S|*\S]?.*$/.test(value)
    ? undefined
    : CustomError("custom-input-error.path");

export const isValidFilesFolderPath = value =>
  /^[a-zA-Z]:\\[\\\S|*\S]+.*$/.test(value)
    ? undefined
    : CustomError("custom-input-error.path");

export const isValidLocalPathForCIFS = value =>
  /^\\(\\[^\s\\]+)+(\\)?$/.test(value)
    ? undefined
    : CustomError("custom-input-error.path");

export const isValidLocalPathForNFS = value =>
  VALIDATION_REGEX.VALID_LOCAL_PATH_FOR_NFS.test(value)
    ? undefined
    : CustomError("custom-input-error.path");

export const isValidLocalOrNetworkPath = value =>
  value &&
  (/^[a-zA-Z]:\\[\\\S|*\S]?.*$/.test(value) ||
    /^\\(\\[^\s\\]+)+(\\)?$/.test(value))
    ? undefined
    : CustomError("custom-input-error.path");

const getCorrespondingObject = (name, allValues) => {
  const objectTrack = name.split("confirm_password")[0];
  const path = [];
  // eslint-disable-next-line array-callback-return
  objectTrack.split(".").map(val => {
    if (val.includes("[")) {
      // eslint-disable-next-line array-callback-return
      val.split("[").map(des => {
        path.push(des.replace("]", ""));
      });
    } else if (val) {
      path.push(val);
    }
  });
  const childObj = path.reduce(
    (prev, curr) => (prev ? prev[curr] : null),
    allValues
  );
  return childObj;
};

const checkActivityType = (value, formValue) => {
  if (value === COPY_TO_DS.type) {
    if (_.get(formValue, "policyType", "") === "udp_agent_based_linux") {
      const destinationDetailAgent = _.get(formValue, "destinations", []).find(
        s => _.get(s, "task_type", null) === "udp_linux_backup"
      );
      const backUpDestinationType = destinationDetailAgent
        ? destinationDetailAgent.udp_linux_backup.destination
            .backup_destination_type
        : null;
      if (backUpDestinationType === COPY_TO_DS.linux_backup_destination_type) {
        return true;
      }
    } else {
      const destinationDetailAgent = _.get(formValue, "destinations", []).find(
        s => _.get(s, "task_type", null) === "udp_agentbase_backup"
      );
      const destinationDetailAgentLess = _.get(
        formValue,
        "destinations",
        []
      ).find(s => _.get(s, "task_type", null) === "udp_agentless_backup");
      let backUpDestinationType = destinationDetailAgent
        ? destinationDetailAgent.udp_agentbase_backup.destination
            .backup_destination_type
        : null;
      backUpDestinationType = destinationDetailAgentLess
        ? destinationDetailAgentLess.udp_agentless_backup.destination
            .backup_destination_type
        : backUpDestinationType;
      if (backUpDestinationType === COPY_TO_DS.backup_destination_type) {
        return true;
      }
    }
  }
  return false;
};

export const passwordMinLength = value => {
  if (!value) {
    return CustomError("Required");
  }
  if (value && value.length) {
    return value.length < 4
      ? CustomError("protect.destination.rps.add.password.min.length")
      : undefined;
  }
};

export const isNotSpace = value =>
  VALIDATION_REGEX.SPACES.test(value)
    ? CustomError("custom-input-error.pwd-is-all-spaces")
    : undefined;

export const numberRange = (min, max) => value =>
  value < min || value > max
    ? CustomError("custom-input-error.number_range", { min, max })
    : undefined;

export const isValidConcurrentActiveNodes = value =>
  !isNaN(value) && value <= 1000 && value >= 1
    ? undefined
    : CustomError("custom-input-error.invalid_concurrent_active_nodes");

const isValidNetworkPath = value => /^\\(\\[^\s\\]+)+(\\)?$/.test(value);

const isValidLocalPathDedupe = value =>
  /^[a-zA-Z]:\\[\\\S|*\S]?.*$/.test(value);

export const validateDedupePaths = value => {
  let dedupePaths = true;

  // get the dedupe values
  if (isValidNetworkPath(value) || isValidLocalPathDedupe(value)) {
    dedupePaths = true;
  } else {
    dedupePaths = false;
  }
  return dedupePaths ? undefined : CustomError("custom-input-error.path");
};
export const isValidDataStoreSize = value =>
  value && value >= 20 && value <= 100000
    ? undefined
    : CustomError("invalid_value");

export const memoryMultipleOfFour = value =>
  value && parseInt(value) % 4 === 0
    ? undefined
    : CustomError("custom-input-error.multiple_of_four_error");

export const minMaxLimitsNumberInput = (min, max) => value => {
  const newValue = parseInt(value);
  return newValue && newValue >= min && newValue <= max
    ? undefined
    : CustomError("custom-input-error.min_max_for_integer_input", {
        min,
        max
      });
};

export const vmwareCpuMinMaxLimitsNumberInput = min => (
  value,
  allVal,
  props
) => {
  const newValue = parseInt(value);
  const max = props.vmWareMemoryInfo.cpu_count;
  return newValue && newValue >= min && newValue <= max
    ? undefined
    : CustomError("custom-input-error.min_max_for_integer_input", {
        min,
        max
      });
};

export const hypervCpuMinMaxLimitsNumberInput = min => (
  value,
  allVal,
  props
) => {
  const newValue = parseInt(value);
  const max = props.hypervMemoryInfo.cpu_count;
  return newValue && newValue >= min && newValue <= max
    ? undefined
    : CustomError("custom-input-error.min_max_for_integer_input", {
        min,
        max
      });
};
export const vmwareMemoryMinMaxLimitsNumberInput = min => (
  value,
  allVal,
  props
) => {
  const newValue = parseInt(value);
  const max = Math.floor(props.vmWareMemoryInfo.size / (1024 * 1024));
  return newValue && newValue >= min && newValue <= max
    ? undefined
    : CustomError("custom-input-error.min_max_for_integer_input", {
        min,
        max
      });
};

export const memoryMinMaxLimitsNumberInput = min => (value, allVal, props) => {
  const newValue = parseInt(value);
  let max = 0;
  if (props.vmwareConfig && props.vmwareConfig.memory_information) {
    max = Math.floor(
      props.vmwareConfig.memory_information.available_size /
        (1024 * 1024 * 1024)
    );
  } else if (props.vmwareConfig && props.vmwareConfig.server_info) {
    max = Math.floor(
      props.vmwareConfig.server_info.available_memory_size /
        (1024 * 1024 * 1024)
    );
  }
  return newValue && newValue >= min && newValue <= max
    ? undefined
    : min === max
    ? newValue < min
      ? CustomError("protect.ivm.tab4.memory_size.error2")
      : CustomError("protect.ivm.tab4.memory_size.error1")
    : max < min
    ? CustomError("protect.ivm.tab4.memory_size.error2")
    : CustomError("custom-input-error.min_max_for_integer_input", {
        min,
        max
      });
};

export const memoryMinMaxLimitsNumberInputAR = (value, allVal) => {
  const min = 1;
  const newValue = parseInt(value);
  let max = "";
  if (allVal.destinations && allVal.destinations.length > 0) {
    // eslint-disable-next-line array-callback-return
    allVal.destinations.map(destination => {
      if (destination.task_type === DCs.TASK_TYPES.UDP_ASSURED_RECOVERY_TEST) {
        if (
          destination?.udp_assured_recovery_test?.instant_virtual_machine_option
            ?.vm_setting?.avail_size
        ) {
          const availSize =
            destination.udp_assured_recovery_test.instant_virtual_machine_option
              .vm_setting.avail_size;
          max = Math.floor(availSize / (1024 * 1024 * 1024));
        }
      }
    });
  }

  if (max === "") {
    return undefined;
  }
  return newValue && newValue >= min && newValue <= max
    ? undefined
    : min === max
    ? newValue < min
      ? CustomError("protect.ivm.tab4.memory_size.error2")
      : CustomError("protect.ivm.tab4.memory_size.error1")
    : max < min
    ? CustomError("protect.ivm.tab4.memory_size.error2")
    : CustomError("custom-input-error.min_max_for_integer_input", {
        min,
        max
      });
};

export const hypervMemoryMinMaxLimitsNumberInput = min => (
  value,
  allVal,
  props
) => {
  const newValue = parseInt(value);
  const max = Math.floor(props.hypervMemoryInfo.memory_size / (1024 * 1024));
  return newValue && newValue >= min && newValue <= max
    ? undefined
    : CustomError("custom-input-error.min_max_for_integer_input", {
        min,
        max
      });
};

export const onlyNumber = value =>
  VALIDATION_REGEX.ONLY_NUMBER.test(value)
    ? undefined
    : CustomError("custom-input-error.number");

export const passwordsMustMatch = (value, allValues) =>
  allValues.password === allValues.confirmPassword
    ? undefined
    : CustomError("custom-input-error.confirm-password");

export const ttlValidation = value => {
  if (parseInt(value) > 86400) {
    return CustomError("custom-input-error.ttl-max-validation");
  }
  if (parseInt(value) < 1) {
    return CustomError("custom-input-error.ttl-min-validation");
  }
  if (value === "" || value === undefined) {
    return CustomError("custom-input-error.required");
  }
  if (isNaN(value)) {
    return CustomError("custom-input-error.number");
  }
};

export const isValidIVMName = (min, max) => value => {
  if (value === "" || value === undefined) {
    return CustomError("custom-input-error.allowed-character", {
      min,
      max
    });
  }
  if (
    value &&
    (!VALIDATION_REGEX.ALLOWED_CHARACTER.test(value) ||
      value.length < min ||
      value.length > max)
  ) {
    return CustomError("custom-input-error.allowed-character", {
      min,
      max
    });
  }
};

export const isValidIVMNamePrefix = (min, max) => value => {
  if (value === "" || value === undefined) {
    return CustomError("custom-input-error.required");
  }
  if (
    value &&
    (!VALIDATION_REGEX.ALLOWED_ALPHANUMERIC_HYPHEN_UNDERSCORE.test(value) ||
      value.length < min ||
      value.length > max)
  ) {
    return CustomError("custom-input-error.allowed-character", {
      min,
      max
    });
  }
};

export const isValidBucketName = type => value => {
  if (type === CLOUD_TYPES.AWS) {
    return (value && !VALIDATION_REGEX.AWS_BUCKET_NAME.test(value)) ||
      value === ""
      ? CustomError("custom-input-error.alpha-numeric")
      : undefined;
  }
  if (type === CLOUD_TYPES.WASABI) {
    return (value && !VALIDATION_REGEX.WASABI_BUCKET_NAME.test(value)) ||
      value === ""
      ? CustomError("custom-input-error.alpha-numeric")
      : undefined;
  }
  if (type === CLOUD_TYPES.GCP) {
    return (value && !VALIDATION_REGEX.GCP_BUCKET_NAME.test(value)) ||
      value === ""
      ? CustomError("custom-input-error.alpha-numeric")
      : undefined;
  }
};

export const isPasswordConfirmPasswordMatch = (value, allValues) =>
  allValues.password === allValues.confirm_password
    ? undefined
    : CustomError("custom-input-error.pwd-not-match");

export const validateDumpFilePath = value =>
  value && value.toLowerCase().indexOf("share") > -1
    ? CustomError("custom-input-error.dumpfile-path-error")
    : false;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { cloneDeep } from "lodash";
import { injectIntl } from "react-intl";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { setResetFlag } from "../../../state/actions/actions";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormattedMessage from "components/common/formatted-message";
import { ID_PREFIX } from "configs/IdConfig";
import IconMUI from "components/common/icon/IconMUI";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  getLocaleDate,
  convertUTCtimestamp
} from "../../../utils/SpogDataGridUtil";
import { DATE_RANGE_OPTIONS } from "../../../utils/appConstants";

class DateTimeRangePicker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      start_ts: 0,
      end_ts: 0
    };
    this.dropdownOptions = Object.keys(DATE_RANGE_OPTIONS).map(
      item => DATE_RANGE_OPTIONS[item]
    );
  }

  componentDidMount = () => this.renderDatePicker();

  componentDidUpdate = () => this.renderDatePicker();

  getTimestamps = () => {
    let stateObj = { ...this.state };
    this.props.onChange(stateObj);
  };

  updateState = event => {
    const value = event.target.value;
    let stateObj = {
      type: value,
      start_ts: 0,
      end_ts: 0
    };
    if (value === DATE_RANGE_OPTIONS.CUSTOM) {
      stateObj = {
        ...stateObj,
        start_ts: convertUTCtimestamp(
          this.props.showTimePicker
            ? moment().unix()
            : moment()
                .startOf("day")
                .unix(),
          false
        ),
        end_ts: convertUTCtimestamp(
          this.props.showTimePicker
            ? moment().unix()
            : moment()
                .endOf("day")
                .unix(),
          false
        )
      };
    }
    if (value === "select") {
      stateObj = {
        type: "",
        start_ts: 0,
        end_ts: 0
      };
    }
    this.setState(stateObj, () => this.getTimestamps());
  };

  renderDatePicker = () => {
    if (
      this.props.componentValue &&
      Object.keys(this.props.componentValue).length
    ) {
      this.setState({
        ...this.props.componentValue
      });
    } else {
      this.setState({
        type: "",
        start_ts: 0,
        end_ts: 0
      });
    }
  };

  render() {
    let options = cloneDeep(this.props.options);
    if (!this.props.skipDefaultOption) {
      options = ["select", ...options];
    }
    if (this.props.resetComponent && !this.props.disableReset) {
      this.props.dispatch(setResetFlag());
      this.renderDatePicker();
    }
    let title =
      this.state.type !== "" ? (
        <FormattedMessage
          id={this.state.type}
          defaultMessage={this.state.type}
        />
      ) : (
        <FormattedMessage id="select" defaultMessage="Select" />
      );
    return (
      <div className="d-flex">
        <div className="time-range-dropdown-container">
          <Select
            className="select-box mui-action-select-dropdown time-range-dropdown"
            onChange={this.updateState}
            id={`${ID_PREFIX}AKos33`}
            value={this.state.type}
            displayEmpty={true}
            renderValue={() => title}
          >
            {options.map((item, index) => {
              return (
                <MenuItem value={item} key={index}>
                  <FormattedMessage id={item} defaultMessage={item} />
                </MenuItem>
              );
            })}
          </Select>
        </div>
        {this.state.type === DATE_RANGE_OPTIONS.CUSTOM && (
          <div style={this.props.customDateStyle}>
            <DateRangePicker
              applyClass="date-range-picker-buttons primary"
              cancelClass="date-range-picker-buttons secondary"
              parentEl={this.props.parentEl}
              autoUpdateInput
              drops={this.props.dropdownDirection}
              showDropdowns={this.props.showDropdowns}
              timePicker={this.props.showTimePicker}
              timePickerSeconds={false}
              minDate={this.props.minDate}
              maxDate={moment()}
              locale={{
                applyLabel: this.props.intl.formatMessage({
                  id: "datepicker.apply"
                }),
                cancelLabel: this.props.intl.formatMessage({
                  id: "datepicker.cancel"
                })
              }}
              startDate={convertUTCtimestamp(
                moment.unix(this.state.start_ts),
                false
              )}
              endDate={convertUTCtimestamp(
                moment.unix(this.state.end_ts),
                false
              )}
              onApply={(event, picker) => {
                this.setState(
                  {
                    start_ts: convertUTCtimestamp(
                      this.props.showTimePicker
                        ? picker.startDate.unix()
                        : picker.startDate.startOf("day").unix(),
                      false
                    ),
                    end_ts: convertUTCtimestamp(
                      this.props.showTimePicker
                        ? picker.endDate.unix()
                        : picker.endDate.endOf("day").unix(),
                      false
                    )
                  },
                  () => this.getTimestamps()
                );
              }}
            >
              <div className="report-modal-date-picker">
                <input
                  type="text"
                  placeholder="Select"
                  className="date-picker-control"
                  readOnly
                  value={`${getLocaleDate(
                    this.state.start_ts,
                    this.props.format
                  )} - ${getLocaleDate(this.state.end_ts, this.props.format)}`}
                />
                <span className="calendar-icon">
                  <IconMUI
                    color="primary"
                    icon={CalendarTodayIcon}
                    style={{
                      height: "25px",
                      width: "30px",
                      position: "relative",
                      bottom: "8px",
                      right: "5px"
                    }}
                  />
                </span>
              </div>
            </DateRangePicker>
          </div>
        )}
      </div>
    );
  }
}

DateTimeRangePicker.defaultProps = {
  options: Object.keys(DATE_RANGE_OPTIONS).map(
    item => DATE_RANGE_OPTIONS[item]
  ),
  dropdownDirection: "down",
  format: "lll",
  showTimePicker: true,
  showDropdowns: true,
  minDate: null,
  skipDefaultOption: true,
  disableReset: true
};

DateTimeRangePicker.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  componentValue: PropTypes.shape({}).isRequired,
  dropdownDirection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  format: PropTypes.string,
  showTimePicker: PropTypes.bool,
  showDropdowns: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  skipDefaultOption: PropTypes.bool,
  resetComponent: PropTypes.bool,
  dispatch: PropTypes.func,
  disableReset: PropTypes.bool
};

export default injectIntl(DateTimeRangePicker);

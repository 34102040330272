import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Form, reduxForm, Field } from "redux-form";
import _ from "lodash";
import { changeTitle } from "../../utils/changeTitleUtil";
import { injectIntl } from "react-intl";
import * as v from "components/common/redux-form-components/validation";
//import { renderInput as renderField } from "components/common/redux-form-components";
import FormattedMessage, {
  FormattedHTMLMessage
} from "../common/formatted-message";
import "../../styles/userVerification.css";
import emailIcon from "../../../src/assets/images/icon-email-verified.png";
import logoUrl from "../../../src/assets/images/arcserve-logo-black.png";
import {
  createPasswordForNewUser,
  getBrandingOnLogin
} from "../../state/actions/actions";
import {
  makeEnrollSuccessfulState,
  makeEnrollRedirectToState,
  makeEnrollRequestingState,
  makeEnrollFailureState
} from "../../state/selectors/enroll";
import {
  makeLoginBrandState,
  makeBrandDataRequestingState
} from "../../state/selectors/LoginSelectors";
import { requiredTerms } from "components/common/redux-form-components/validation";
import {
  required,
  isNumber
} from "components/common/redux-form-components/validation";
import { getFormValues } from "redux-form";
// const requiredTerms = value =>
//   value ? undefined : "Please read and agree to the terms and conditions";
// const passwordsMatch = (value, allValues) =>
//   value !== allValues.create_password ? "Passwords don't match" : undefined;
// const passwordFormat = value =>
//   value &&
//   !/^(?:(?=.*([^\u0000-\u007F])+)|(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{8,}$/g.test(
//     value
//   );

const twoFactorCodeStyle = {
  marginTop: "10px",
  marginBottom: "10px",
  borderColor: "#8f8f8f",
  borderStyle: "solid",
  borderWidth: "1px"
};

const renderTermsOfServiceLabel = locale => {
  if (locale === "ja") {
    return (
      <label htmlFor="terms">
        <a
          href="/termsOfService"
          style={{ marginRight: "5px" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            id="user.registration.label.terms.service"
            defaultMessage="Terms of Service"
          />
        </a>
        <FormattedMessage
          id="user.registration.label.agree"
          defaultMessage="I agree to the"
        />
      </label>
    );
  }
  return (
    <label htmlFor="terms">
      <FormattedMessage
        id="user.registration.label.agree"
        defaultMessage="I agree to the"
      />
      <a href="/termsOfService" target="_blank" rel="noopener noreferrer">
        <FormattedMessage
          id="user.registration.label.terms.service"
          defaultMessage="Terms of Service"
        />
      </a>
    </label>
  );
};

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  id,
  maxlength,
  meta: { touched, error }
}) => (
  <div>
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      id={id}
      disabled={disabled}
      maxLength={maxlength || ""}
      className={touched ? (error ? "error" : "") : ""}
    />
    {touched && error && error.id && (
      <div
        className="user-registration"
        style={{ color: "#f76c83", marginTop: "0px", marginBottom: "3px" }}
      >
        <FormattedMessage
          id={error.id}
          values={error.values}
          defaultMessage={error.id}
        />
      </div>
    )}
  </div>
);

const renderCheckBox = ({
  input,
  type,
  disabled,
  id,
  locale,
  meta: { touched, error }
}) => (
  <div>
    <input
      {...input}
      type={type}
      id={id}
      disabled={disabled}
      className={touched ? (error ? "error" : "") : ""}
      onChange={e => input.onChange(e.target.checked)}
    />
    {renderTermsOfServiceLabel(locale)}
    {touched && error && (
      <div
        className="user-registration"
        style={{ color: "#f76c83", marginTop: "8px" }}
      >
        <FormattedMessage id={error.id} defaultMessage={error.id} />
      </div>
    )}
  </div>
);

const validate = (values = {}) => {
  const errors = {};
  errors.terms = requiredTerms(values.terms);
  return errors;
};
let timestamp;
class UserVerification extends React.Component {
  componentDidMount() {
    timestamp = new Date().getTime();
    // Extracting portalUrl from window.location.href
    let url = window.location.href;
    let pos = url.search("/users/");
    let portalUrl = url.slice(0, pos);
    this.props.dispatch(getBrandingOnLogin(portalUrl));
    let params = this.getURLParameters(this.props.location.search);
    this.props.dispatch({ type: "TWO_FACTOR_CHECK", username: params.email });
  }

  componentDidUpdate(prevProps) {
    if (
      //eslint-disable-next-line eqeqeq
      this.props.brandData_requesting == false &&
      //eslint-disable-next-line eqeqeq
      prevProps.brandData_requesting == true
    ) {
      const orgName = _.get(this.props, "brandData.organization_name", "");
      const logoUrl = _.get(this.props, "brandData.logo_img_url", "");
      const brandingFrom = _.get(this.props, "brandData.branding_from", "");
      changeTitle(orgName, `${logoUrl}?${timestamp}`, brandingFrom);
    }
  }
  componentWillUnmount() {
    localStorage.clear();
    window.removeEventListener("resize", this._handleWindowResizeListener);
  }

  getURLParameters = search => {
    let decoded = decodeURIComponent(search.slice(1, search.length));
    const params = {};
    let vars = decoded.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      params[pair[0]] = pair[1];
    }
    //const params = new URLSearchParams(decoded);
    return params;
  };

  getErrorMessage = ({ status, errorCode }) => {
    let errorMsg;

    if (status === 403) {
      errorMsg = "user.registration.error.403";
    } else {
      if (status === 400) {
        errorMsg = `user.registration.error.${status}.${errorCode}`;
      }
    }

    return errorMsg;
  };

  handleSetPassword = () => {
    let div = document.createElement("div");
    div.innerHTML = this.props.location.search;

    let decoded = decodeURIComponent(div.firstChild.nodeValue);
    const params = {};
    let vars = decoded.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      params[pair[0]] = pair[1];
    }

    let submitPayload = {
      email: params.email,
      token: params.token,
      type: params.type,
      password: this.props.formData.create_password.trim()
    };

    if (_.get(this.props, "formData.two_factor_code")) {
      submitPayload["two_factor_code"] = this.props.formData.two_factor_code;
    }
    this.props.dispatch(createPasswordForNewUser(submitPayload));
  };

  render() {
    const params = this.getURLParameters(this.props.location.search);

    const createAccount =
      params != null && params.type === "reset_password" ? (
        <FormattedMessage
          id="user.verification.reset.account"
          defaultMessage="Reset Password"
        />
      ) : (
        <FormattedMessage
          id="user.verification.create.account"
          defaultMessage="Create Account"
        />
      );

    //const brandMessage = <FormattedHTMLMessage id="message" />;
    const copyrightMessage = _.get(this.props, "brandData.copyright", "");
    const message = <FormattedHTMLMessage id="message" />;
    let branding_from = this.props.brandData.branding_from;
    // If branding_from is not "DEFAULT_ARCSERVE" then branding message will take from BE response else it will be default arcserve branding message
    const brandMessage =
      branding_from &&
      branding_from !== "DEFAULT_ARCSERVE" &&
      this.props.brandData.branding_msg ? (
        <FormattedHTMLMessage
          id="user.brandingMessage"
          values={{ message: this.props.brandData.branding_msg }}
        />
      ) : (
        message
      );
    const organizationFrom =
      this.props.brandData && this.props.brandData.branding_from
        ? this.props.brandData.branding_from
        : "";
    const organizationName =
      this.props.brandData && this.props.brandData.organization_name
        ? this.props.brandData.organization_name
        : "";
    // const secondaryColor =
    //   params != null && params.secondarycolor
    //     ? params.secondarycolor
    //     : "#196ba2";
    // const brandLogo = params != null && params.logo ? params.logo : logoUrl;
    const email = params != null && params.email ? params.email : "";

    const {
      handleSubmit,
      pristine,
      successful,
      redirectTo,
      submitting,
      requesting,
      enroll_requesting,
      errors
    } = this.props;

    return this.props.brandData_requesting ? (
      <div></div>
    ) : (
      <div className="user-verification-container d-flex align-items-start justify-content-center">
        <div className="left-section">
          {/* <img alt="Logo" className="brand-logo" src={brandLogo} /> */}
          {/*If branding_from is DEFAULT_ARCSERVE then arcserve logo will be used else
            If brandingLogo property is true in response data then logo_img_url will be use else login_img_url will be use for logo */}
          {this.props.brandData &&
          this.props.brandData.brandingLogo !== null &&
          this.props.brandData.brandingLogo !== undefined ? (
            <img
              className="brand-logo"
              alt={this.props.intl.formatMessage({ id: "branding.login.logo" })}
              src={
                branding_from && branding_from === "DEFAULT_ARCSERVE"
                  ? logoUrl
                  : this.props.brandData && this.props.brandData.brandingLogo
                  ? `${this.props.brandData.logo_img_url}?${timestamp}`
                  : `${this.props.brandData.login_img_url}?${timestamp}`
              }
            />
          ) : (
            <div />
          )}
          <div className="login-branding-msg">{brandMessage}</div>
          <span className="copyright-text">
            <FormattedMessage
              id="copyright.text"
              values={{ msg: copyrightMessage }}
            />
          </span>
        </div>
        <Form
          onSubmit={handleSubmit(this.handleSetPassword)}
          className="right-section-form"
        >
          <div className="right-section d-flex flex-column">
            {successful && <Redirect to={redirectTo} />}
            <div className="email-verified-message d-flex flex-column">
              <img alt="email-icon" src={emailIcon} />
              <span>
                {/* <span>
                  <FormattedMessage
                    id="user.verification.text.email"
                    defaultMessage="Your email address"
                  />
                </span> */}
                <FormattedHTMLMessage
                  id="user.verification.message"
                  values={{
                    email: `${email}`
                  }}
                />
              </span>
            </div>
            <div className="input-wrapper d-flex flex-column">
              <label htmlFor="create_password">
                {params != null && params.type === "reset_password" ? (
                  <FormattedMessage
                    id="user.verification.reset.instruction"
                    defaultMessage={`Please enter a new password for your ${organizationName} Cloud account.`}
                    values={{
                      organization: organizationName
                    }}
                  />
                ) : organizationFrom === "SELF" ? (
                  <FormattedMessage
                    id="user.verification.create.instruction"
                    defaultMessage={`Please create a password to set up your ${organizationName} Cloud account.`}
                    values={{
                      organization: organizationName
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="user.verification.create.instruction_default"
                    defaultMessage={`Please create a password to set up your Arcserve Business Continuity Cloud account.`}
                  />
                )}
                {/* <sup>*</sup> */}
              </label>
              <Field
                name="create_password"
                type="password"
                validate={[v.required, v.passwordFormat]}
                id="create_password"
                component={renderField}
                placeholder={this.props.intl.formatMessage({
                  id: "user.registration.enter_password"
                })}
                maxlength="20"
              />
              <span className="password-instruction">
                <FormattedMessage
                  id="user.verification.confirm.instruction"
                  defaultMessage="Minimum 8 and Maximum 20 characters. Mix of uppercase, lowercase and numbers"
                />
              </span>
              <Field
                name="confirm-password"
                type="password"
                validate={[v.required, v.passwordsMatch]}
                id="confirm-password"
                component={renderField}
                placeholder={this.props.intl.formatMessage({
                  id: "user.registration.confirm_password"
                })}
                maxlength="20"
              />
              <div style={{ height: "30px", padding: "10px 0" }}>
                {errors && (
                  <label className="error-message" htmlFor="loginErrorId">
                    <span id="loginErrorId">
                      <FormattedMessage
                        id={this.getErrorMessage({
                          status: errors.status,
                          errorCode: errors.errorCode
                        })}
                        defaultMessage={errors.errorMessage}
                      />
                    </span>
                  </label>
                )}
              </div>
              {this.props.twoFactorEnabled && params.type === "reset_password" && (
                <Field
                  name="two_factor_code"
                  type="two_factor_code"
                  validate={(required, isNumber)}
                  placeholder={this.props.intl.formatMessage({
                    id: "Two Factor Code"
                  })}
                  autoComplete="off"
                  id="two_factor_code"
                  component="input"
                  style={twoFactorCodeStyle}
                />
              )}
              <div className="enroll_checkbox">
                <Field
                  name="terms"
                  id="terms"
                  component={renderCheckBox}
                  type="checkbox"
                  locale={this.props.intl.locale}
                />
              </div>
            </div>
            <div className="create-account-wrapper d-flex flex-column">
              <button
                id="create-account"
                disabled={
                  pristine ||
                  requesting ||
                  submitting ||
                  successful ||
                  enroll_requesting
                }
                className="btn btn-primary btn-arrow"
              >
                {createAccount}
              </button>
              {/* <span>
                <sup>*</sup>
                <FormattedMessage
                  id="user.verification.login.instruction"
                  defaultMessage="Use the same password to login to Arcserve Cloud"
                />
              </span> */}
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

/**
 * Using makeMapStateToProps to memoize a selectorFunction for reusability
 * This will create a new instance of selector function
 * every time it is needed for proper memoization in multiple components.
 */
const makeMapStateToProps = () => {
  /**
   * create a new instance of selector function
   * This makes it independent of any other selector function
   * and it will properly memoize across multiple mounted & connected components.
   */
  const getEnrollSuccessfulState = makeEnrollSuccessfulState();
  const getEnrollRedirectToState = makeEnrollRedirectToState();
  const getEnrollRequestingState = makeEnrollRequestingState();
  const getEnrollFailureState = makeEnrollFailureState();
  const getLoginBrandState = makeLoginBrandState();
  const getBrandDataRequestingState = makeBrandDataRequestingState();

  const mapStateToProps = (state, props) => {
    // const query = qs.parse(props.location.search);

    return {
      /**
       * Use selector function instance to update Props.
       */
      successful: getEnrollSuccessfulState(state, props),
      redirectTo: getEnrollRedirectToState(state, props),
      enroll_requesting: getEnrollRequestingState(state, props),
      errors: getEnrollFailureState(state, props),
      brandData: getLoginBrandState(state, props),
      brandData_requesting: getBrandDataRequestingState(state, props),
      twoFactorEnabled: state.login.twoFactorEnabled,
      formData: getFormValues("userVerification")(state)
    };
  };
  return mapStateToProps;
};

// const mapDispatchToProps = (dispatch, ownProps) => {
//   // reduxForm() expects the component to have an onSubmit
//   // prop. You could also pass this from a parent component.
//   // I want to dispatch a redux action.

//   let div = document.createElement("div");
//   div.innerHTML = ownProps.location.search;

//   let decoded = decodeURIComponent(div.firstChild.nodeValue);
//   const params = {};
//   let vars = decoded.split("&");
//   for (let i = 0; i < vars.length; i++) {
//     let pair = vars[i].split("=");
//     params[pair[0]] = pair[1];
//   }
//   console.log(ownProps)
//   console.log("disapatch")

//   let submitPayload = {
//     email: params.email,
//     token: params.token,
//     type: params.type,

//   }

//   if (_.get(ownProps,"formData.two_factor_code")) {
//     submitPayload['two_factor_code'] = ownProps.formData.two_factor_code
//   }

//   return {
//     onSubmit: ({ create_password }) => {
//       dispatch(
//         createPasswordForNewUser({...submitPayload, password: create_password})
//       );
//     }
//   };
// };

/**
 * Default properties of component
 * @type {{}}
 */
UserVerification.defaultProps = {
  brandData: {
    organization_name: "",
    portal_url: "",
    login_img_url: "",
    logo_img_url: "",
    primary_color: "#28a745",
    secondary_color: "#20c997",
    brandingLogo: false,
    branding_msg:
      "Guaranteed data protection through seamless integrations of backup-restore",
    branding_from: "DEFAULT_ARCSERVE",
    copyright: ""
  },
  pristine: true,
  invalid: true,
  enroll_requesting: false,
  requesting: false,
  successful: false,
  brandData_requesting: false
};

UserVerification.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  enroll_requesting: PropTypes.bool,
  successful: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool,
  requesting: PropTypes.bool,
  brandData_requesting: PropTypes.bool,
  brandData: PropTypes.shape({
    organization_name: PropTypes.string,
    portal_url: PropTypes.string,
    branding_msg: PropTypes.string,
    primary_color: PropTypes.string,
    secondary_color: PropTypes.string,
    login_img_url: PropTypes.string,
    logo_img_url: PropTypes.string,
    brandingLogo: PropTypes.bool,
    branding_from: PropTypes.string,
    copyright: PropTypes.string
  }),
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  redirectTo: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    locale: PropTypes.string
  }).isRequired
};

const UserVerificationForm = reduxForm({
  form: "userVerification",
  validate,
  enableReinitialize: true
})(UserVerification);

const connected = withRouter(
  injectIntl(connect(makeMapStateToProps)(UserVerificationForm))
);

export default connected;

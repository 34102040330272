import { combineReducers } from "redux";
import * as C from "state/constants/ccToast";

const byId = (state = {}, action) => {
  let rVal = { ...state };

  switch (action.type) {
    case C.CCTOAST_ADD_NOTIFICATION:
      rVal[action.notification.notificationId] = action.notification;
      return rVal;
    case C.CCTOAST_CLEAR_NOTIFICATIONS:
      return {};
    case C.CCTOAST_CLEAR_NOTIFICATION:
      if (rVal.hasOwnProperty(action.notificationId)) {
        delete rVal[action.notificationId];
      }
      return rVal;
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case C.CCTOAST_ADD_NOTIFICATION:
      return state.includes(action.notification.notificationId)
        ? state
        : [action.notification.notificationId, ...state];
    case C.CCTOAST_CLEAR_NOTIFICATIONS:
      return [];
    case C.CCTOAST_CLEAR_NOTIFICATION:
      return state.filter(nId => nId !== action.notificationId);
    default:
      return state;
  }
};

const ccToast = combineReducers({
  byId,
  allIds
});

export default ccToast;

export const GET_BRAND_DETAILS_ON_LOGIN = "GET_BRAND_DETAILS_ON_LOGIN";
export const FETCH_MENU = "FETCH_MENU";
export const RECEIVE_MENU = "RECEIVE_MENU";
export const COLLAPSE_SIDEBAR = "COLLAPSE_SIDEBAR";
export const SELECT_MENU = "SELECT_MENU";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_MESSAGE = "FORGOT_PASSWORD_MESSAGE";
export const FORGOT_PASSWORD_MESSAGE_CLEAR = "FORGOT_PASSWORD_MESSAGE_CLEAR";

export const LOGIN_REQUESTING = "LOGIN_REQUESTING";
export const FETCH_ACCESS_TOKEN = "FETCH_ACCESS_TOKEN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOG_OUT = "LOG_OUT";
export const UNSET_LOG_OUT = "UNSET_LOG_OUT";
export const TRIGGER_LOG_OUT = "TRIGGER_LOG_OUT";
export const SET_GO_BACK_NAVIGATION = "SET_GO_BACK_NAVIGATION";
export const SET_JWT_TOKEN = "SET_JWT_TOKEN";
export const UNSET_JWT_TOKEN = "UNSET_JWT_TOKEN";
export const SET_OCTA_TOKEN = "SET_OCTA_TOKEN";
export const UNSET_OCTA_TOKEN = "UNSET_OCTA_TOKEN";
export const RESET_EXCLUSION_CHECKBOX = "RESET_EXCLUSION_CHECKBOX";
export const RESET_POLICY_PASSWORD = "RESET_POLICY_PASSWORD";
export const DATA_GRID = "DATA_GRID";
export const ADVANCE_SEARCH_LOADED = "ADVANCE_SEARCH_LOADED";
//menu constants
export const UPDATE_ANALYZE_LIST_COUNT = "UPDATE_ANALYZE_LIST_COUNT";
export const EXPORT_LOG = "EXPORT_LOG";
export const GET_LOG_LIST = "GET_LOG_LIST";
export const SHOW_LOG_LIST = "SHOW_LOG_LIST";
export const GET_JOBS_LIST = "GET_JOBS_LIST";
export const SHOW_JOBS_LIST = "SHOW_JOBS_LIST";
export const GET_ANALYZE_COUNT = "GET_ANALYZE_COUNT";
export const UPDATE_JOBS_LOGS_COUNT = "UPDATE_JOBS_LOGS_COUNT";
export const ANALYZE_ADVANCED_SEARCH_TRIGGERED =
  "ANALYZE_ADVANCED_SEARCH_TRIGGERED";
export const ADVANCED_SEARCH_LOADING = "ADVANCED_SEARCH_LOADING";
export const ADVANCED_SEARCH_LOADED = "ADVANCED_SEARCH_LOADED";
export const DEFAULT_SEARCH_CONFIGURED = "DEFAULT_SEARCH_CONFIGURED";
export const DO_NOT_INITIALIZE_GRAPH = "DO_NOT_INITIALIZE_GRAPH";
export const GET_SOURCE_LIST = "GET_SOURCE_LIST";
export const SHOW_SOURCE_LIST = "SHOW_SOURCE_LIST";
export const SHOW_ORACLE_SOURCES = "SHOW_ORACLE_SOURCES";
export const CLEAR_ORACLE_SOURCES = "CLEAR_ORACLE_SOURCES";
export const SHOW_SOURCE_LOCAL_LIST = "SHOW_SOURCE_LOCAL_LIST";
export const ADD_BACKUP_SCHEDULE = "ADD_BACKUP_SCHEDULE";
export const REMOVE_BACKUP_SCHEDULE = "REMOVE_BACKUP_SCHEDULE";
export const GET_POLICIES_LIST = "GET_POLICIES_LIST";
export const SHOW_POLICIES_LIST = "SHOW_POLICIES_LIST";
export const SHOW_POLICIES_LOCAL_LIST = "SHOW_POLICIES_LOCAL_LIST";

export const GET_COLUMN_DATA = "GET_COLUMN_DATA";
export const UPDATE_COLUMN_DATA = "UPDATE_COLUMN_DATA";
export const SET_COLUMN_WIDTH = "SET_COLUMN_WIDTH";
export const UPDATE_COLUMN_CONFIG_DATA = "UPDATE_COLUMN_CONFIG_DATA";
export const UPDATE_COLUMN_ANALYZE_DATA = "UPDATE_COLUMN_ANALYZE_DATA";
export const GET_COLUMN_DATA_FROM_STATE = "GET_COLUMN_DATA_FROM_STATE";
export const UPDATE_COLUMN_DATA_FROM_STATE = "UPDATE_COLUMN_DATA_FROM_STATE";
export const UPDATE_REORDER_COLUMN_DATA = "UPDATE_REORDER_COLUMN_DATA";
export const UPDATE_MENU_MSP_MONITOR = "UPDATE_MENU_MSP_MONITOR";
export const UPDATE_MENU_DIRECT_MONITOR = "UPDATE_MENU_DIRECT_MONITOR";
export const GET_DESTINATION_LIST = "GET_DESTINATION_LIST";
export const SHOW_DESTINATION_LIST = "SHOW_DESTINATION_LIST";

export const GET_PROTECT_LIST_COUNT = "GET_PROTECT_LIST_COUNT";
export const UPDATE_PROTECT_LIST_COUNT = "UPDATE_PROTECT_LIST_COUNT";

export const SELECT_FILTER = "SELECT_FILTER";
export const SHOW_GRID_LOADER = "SHOW_GRID_LOADER";

export const GET_CLOUD_ACCOUNTS_LIST = "GET_CLOUD_ACCOUNTS_LIST";
export const GET_INFRASTRUCTURE_LIST_COUNT = "GET_INFRASTRUCTURE_LIST_COUNT";
export const SHOW_CLOUD_ACCOUNTS_LIST = "SHOW_CLOUD_ACCOUNTS_LIST";
export const GET_ALL_DETAILS = "GET_ALL_DETAILS";
export const GET_ARCSERVE_CLOUD_DETAILS = "GET_ARCSERVE_CLOUD_DETAILS";
export const UPDATE_ARCSERVE_CLOUD_FILTER = "UPDATE_ARCSERVE_CLOUD_FILTER";
export const GET_ARCSERVE_CLOUD_VOLUME = "GET_ARCSERVE_CLOUD_VOLUME";
export const SHOW_ARCSERVE_CLOUD_DETAILS = "SHOW_ARCSERVE_CLOUD_DETAILS";
export const SHOW_ARCSERVE_CLOUD_VOLUME_DETAILS =
  "SHOW_ARCSERVE_CLOUD_VOLUME_DETAILS";
export const SHOW_DETAILS_VIEW = "SHOW_DETAILS_VIEW";
export const UPDATE_VM_DETAILS = "UPDATE_VM_DETAILS";
export const CLEAR_DETAILS_VIEW = "CLEAR_DETAILS_VIEW";
export const UPDATE_INFRASTRUCTURE_LIST_COUNT =
  "UPDATE_INFRASTRUCTURE_LIST_COUNT";

export const SITES = "SITES";
export const SOURCE_GROUPS = "SOURCE_GROUPS";
export const SELECT_FILTER_UPDATE = "SELECT_FILTER_UPDATE";

export const DETECT_WINDOW_DIMENSIONS = "DETECT_WINDOW_DIMENSIONS";

export const TOGGLE_ADVANCED_SEARCH = "TOGGLE_ADVANCED_SEARCH";
export const UPDATE_ADVANCED_SEARCH = "UPDATE_ADVANCED_SEARCH";
export const ADVANCED_SEARCH_CLICKED = "ADVANCED_SEARCH_CLICKED";
export const ADVANCED_SEARCH_SET = "ADVANCED_SEARCH_SET";
export const ADVANCED_SEARCH_TRIGGERED = "ADVANCED_SEARCH_TRIGGERED";
export const REMOVE_SEARCH_TAGS = "REMOVE_SEARCH_TAGS";
export const REMOVE_SEARCH_TAGS_RELOAD = "REMOVE_SEARCH_TAGS_RELOAD";
export const CLEAR_ALL_SEARCH_TAGS = "CLEAR_ALL_SEARCH_TAGS";
export const CLEAR_ALL_SEARCH_TAGS_RELOAD = "CLEAR_ALL_SEARCH_TAGS_RELOAD";
export const SEARCH_ENTITY = "SEARCH_ENTITY";
export const UPDATE_SELECTED_OPTION = "UPDATE_SELECTED_OPTION";
export const REMOVE_SEARCH_TEXT_TAG = "REMOVE_SEARCH_TEXT_TAG";
export const UPDATE_SEARCH_TEXT = "UPDATE_SEARCH_TEXT";
export const GET_DEFAULT_SAVED_SEARCH = "GET_DEFAULT_SAVED_SEARCH"; //get default saved data from api
export const STOP_GET_DEFAULT_SAVED_SEARCH = "STOP_GET_DEFAULT_SAVED_SEARCH";
export const UPDATE_DEFAULT_SEARCH = "UPDATE_DEFAULT_SEARCH"; //update default all data in reducer
export const UPDATE_SAVED_SEARCH = "UPDATE_SAVED_SEARCH"; //update saved search data in reducer
export const UPDATE_SAVED_SEARCH_DATA = "UPDATE_SAVED_SEARCH_DATA"; //update saved search data in protect reducer

export const DELETE_SAVED_SEARCH = "DELETE_SAVED_SEARCH"; //delete saved search data by api
export const DELETE_SAVED_SEARCH_CCDATAGRID = "DELETE_SAVED_SEARCH_CCDATAGRID";
export const DELETE_UPDATE_MANAGE_SAVE_SEARCH =
  "DELETE_UPDATE_MANAGE_SAVE_SEARCH"; //update deleted response in reducer
export const EDIT_UPDATE_MANAGE_SAVE_SEARCH = "EDIT_UPDATE_MANAGE_SAVE_SEARCH"; //post edit data to update by api
export const EDIT_UPDATE_MANAGE_SAVE_SEARCH_CCDATAGRID =
  "EDIT_UPDATE_MANAGE_SAVE_SEARCH_CCDATAGRID";
export const UPDATE_MANAGE_SAVE_SEARCH = "UPDATE_MANAGE_SAVE_SEARCH"; //update api post resopnse in reducer
export const UPDATE_CURRENT_SEARCH_FROM_SAVED =
  "UPDATE_CURRENT_SEARCH_FROM_SAVED"; //update current search data to reducer
export const GET_SELECTED_SAVED_SEARCH = "GET_SELECTED_SAVED_SEARCH"; //update current search name
export const UPDATE_CURRENT_SAVEDSEARCH_NAME =
  "UPDATE_CURRENT_SAVEDSEARCH_NAME"; //update current search name
export const UPDATE_CURRENT_SAVEDSEARCH_TEXT =
  "UPDATE_CURRENT_SAVEDSEARCH_TEXT"; //update current search text
export const UPDATE_CURRENT_SAVEDSEARCH_MARK_DEF =
  "UPDATE_CURRENT_SAVEDSEARCH_MARK_DEF"; //update current search markDefault property
export const FLUSH_CURRENT_SAVEDSEARCH = "FLUSH_CURRENT_SAVEDSEARCH"; // flush current search data

export const UPDATE_CURRENT_SAVEDSEARCH = "UPDATE_CURRENT_SAVEDSEARCH"; //update current search
export const GET_SEARCH_FROM_DEFAULT_SAVED_SEARCH =
  "GET_SEARCH_FROM_DEFAULT_SAVED_SEARCH"; //get search from default saved search api
export const SHOW_MANAGE_SEARCH_MODAL = "SHOW_MANAGE_SEARCH_MODAL"; //show hide manage save search modal
export const SHOW_SAVE_SEARCH_MODAL = "SHOW_SAVE_SEARCH_MODAL"; //show hide save search modal
export const ADD_SAVE_SEARCH = "ADD_SAVE_SEARCH"; //save new search action by modal
export const INSERT_MANAGE_SAVE_SEARCH = "INSERT_MANAGE_SAVE_SEARCH"; //add inserted search to saved search
export const SHOW_SAVEDSEARCH_CLEARALL_LINK = "SHOW_SAVEDSEARCH_CLEARALL_LINK"; //for showing and hiding saved search and clear all links
export const BROWSE_REMOTE_CONSOLE_LOADING = "BROWSE_REMOTE_CONSOLE_LOADING";
export const BROWSE_REMOTE_CONSOLE_SUCCESS = "BROWSE_REMOTE_CONSOLE_SUCCESS";
export const BROWSE_REMOTE_CONSOLE_FAILURE = "BROWSE_REMOTE_CONSOLE_FAILURE";
export const CLEAR_BROWSE_REMOTE_CONSOLE = "CLEAR_BROWSE_REMOTE_CONSOLE"; // CLEAR/RESET BROWSE REMOTE CONSOLE DATA
export const SET_CURRENT_TAB = "SET_CURRENT_TAB";
export const GET_ADVANCED_SEARCH_OPTIONS = "GET_ADVANCED_SEARCH_OPTIONS";
export const GET_ADVANCED_SEARCH_OPTIONS_INNER_TAB =
  "GET_ADVANCED_SEARCH_OPTIONS_INNER_TAB";
export const UPDATE_ADVANCED_SEARCH_OPTIONS = "UPDATE_ADVANCED_SEARCH_OPTIONS";
export const UPDATE_ADVANCED_SEARCH_OPTIONS_INNER_TAB =
  "UPDATE_ADVANCED_SEARCH_OPTIONS_INNER_TAB";
export const CLEAR_STATE = "CLEAR_STATE";
export const CLEAR_STATE_WITHOUT_COLUMN_DATA =
  "CLEAR_STATE_WITHOUT_COLUMN_DATA";
export const CLEAR_STATE_RESOURCE_ID = "CLEAR_STATE_RESOURCE_ID";
export const DELETE_DATA_STORE = "DELETE_DATA_STORE";
export const FORCE_DELETE_DATA_STORE = "FORCE_DELETE_DATA_STORE";
export const START_DATA_STORE = "START_DATA_STORE";
export const STOP_DATA_STORE = "STOP_DATA_STORE";
export const REFRESH_DATA_STORE = "REFRESH_DATA_STORE";
export const GET_SELECTED_ROWS = "GET_SELECTED_ROWS";
export const GET_SELECTED_ROWS_FROM_ALL_PAGES =
  "GET_SELECTED_ROWS_FROM_ALL_PAGES";
export const SHOW_POLICIES_SUMMARY_VIEW = "SHOW_POLICIES_SUMMARY_VIEW";
export const EDIT_UPDATE_HYPERVISOR_INFORMATION =
  "EDIT_UPDATE_HYPERVISOR_INFORMATION";
export const UPDATE_HYPERVISOR_INFORMATION = "UPDATE_HYPERVISOR_INFORMATION";
export const UPDATE_HYPERVISOR_INFORMATION_DROPDOWNS =
  "UPDATE_HYPERVISOR_INFORMATION_DROPDOWNS";

export const GET_HYPERVISOR_TYPE = "GET_HYPERVISOR_TYPE";
export const GET_SELECTED_SOURCE_HYPERVISOR_TYPE =
  "GET_SELECTED_SOURCE_HYPERVISOR_TYPE";
export const GET_SELECTED_SOURCE_HYPERVISOR_TYPE_SUCCESS =
  "GET_SELECTED_SOURCE_HYPERVISOR_TYPE_SUCCESS";
export const GET_SELECTED_SOURCE_HYPERVISOR_TYPE_LOADING =
  "GET_SELECTED_SOURCE_HYPERVISOR_TYPE_LOADING";
export const GET_SELECTED_SOURCE_HYPERVISOR_TYPE_DONE =
  "GET_SELECTED_SOURCE_HYPERVISOR_TYPE_DONE";
export const GET_HYPERVISOR_TYPE_SUCCESS = "GET_HYPERVISOR_TYPE_SUCCESS";
export const SET_SELECTED_HYPERVISOR_ID_IN_RECOVERY =
  "SET_SELECTED_HYPERVISOR_ID_IN_RECOVERY";
export const SET_RECOVERY_NEXT_LOADING = "SET_RECOVERY_NEXT_LOADING";
export const SHOW_DESTINATION_OVERLAY = "SHOW_DESTINATION_OVERLAY";
export const ADD_DESTINATIONS = "ADD_DESTINATIONS";
export const ADD_REMOTE_CONSOLE = "ADD_REMOTE_CONSOLE";
export const UPDATE_REMOTE_CONSOLE = "UPDATE_REMOTE_CONSOLE";
export const UPDATE_PAGINATION_DROPDOWN = "UPDATE_PAGINATION_DROPDOWN";
export const CLEAR_STATE_SELECTED_ROWS = "CLEAR_STATE_SELECTED_ROWS";
export const CLEAR_STATE_ADVANCED_PARAMS = "CLEAR_STATE_ADVANCED_PARAMS";
export const GET_POLICY_DETAILS = "GET_POLICY_DETAILS";
export const UPDATE_POLICY_DETAILS = "UPDATE_POLICY_DETAILS";
export const GET_POLICY_DETAILS_DROPDOWN_PROTECTIONCARD =
  "GET_POLICY_DETAILS_DROPDOWN_PROTECTIONCARD";
export const UPDATE_POLICY_DETAILS_DROPDOWN_PROTECTIONCARD =
  "UPDATE_POLICY_DETAILS_DROPDOWN_PROTECTIONCARD";
export const SHOW_POLICY_DETAILS = "SHOW_POLICY_DETAILS";
export const SHOW_POLICY_NAME = "SHOW_POLICY_NAME";
export const UPDATE_DESTINATION_OVERLAY_DETAILS =
  "UPDATE_DESTINATION_OVERLAY_DETAILS";
export const SHOW_SELECT_SOURCE_GRID_VIEW = "SHOW_SELECT_SOURCE_GRID_VIEW";
export const UNSELECT_ALL_CHECKBOXES = "UNSELECT_ALL_CHECKBOXES";
export const SELECT_CHECKBOX = "SELECT_CHECKBOX";
export const CUSTOM_SORT = "CUSTOM_SORT";
export const GET_DESTINATION_DETAILS = "GET_DESTINATION_DETAILS";
export const SHOW_DESTINATION_DETAILS = "SHOW_DESTINATION_DETAILS";
export const EDIT_UPDATE_DESTINATION_DETAILS =
  "EDIT_UPDATE_DESTINATION_DETAILS";
export const UPDATED_DESTINATION_DETAILS = "UPDATED_DESTINATION_DETAILS";
export const SHOW_RETENTION_DROPDOWNS = "SHOW_RETENTION_DROPDOWNS";
export const SHOW_SCHEDULE_MODAL = "SHOW_SCHEDULE_MODAL";
export const SHOW_EVENT_DETAILS_OVERLAY = "SHOW_EVENT_DETAILS_OVERLAY";
export const SHOW_ADD_DESTINATION = "SHOW_ADD_DESTINATION";
export const UPDATE_RECOVERY_STATUS = "UPDATE_RECOVERY_STATUS";
export const UPDATED_RECOVERY_DETAILS = "UPDATED_RECOVERY_DETAILS";
export const SHOW_CONFIRMATION = "SHOW_CONFIRMATION";
export const SHOW_RECOVERY_POINT = "SHOW_RECOVERY_POINT";
export const FILTERED_RECOVERY_POINTS = "FILTERED_RECOVERY_POINTS";
export const ADD_SCHEDULE_JOB = "ADD_SCHEDULE_JOB";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const REMOVE_DESTINATION = "REMOVE_DESTINATION";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_OCTA_USER_DETAILS = "GET_OCTA_USER_DETAILS";
export const SHOW_USER_DETAILS = "SHOW_USER_DETAILS";
export const SHOW_USER_PROFILE_DETAILS = "SHOW_USER_PROFILE_DETAILS";
export const SHOW_UPDATE_PHOTO = "SHOW_UPDATE_PHOTO";
export const UPDATE_USER_CONTACT_INFO = "UPDATE_USER_CONTACT_INFO";
export const UPDATED_USER_CONTACT_INFO = "UPDATED_USER_CONTACT_INFO";
export const UPDATE_IMG_URL = "UPDATE_IMG_URL";
export const CROPPED_IMG_URL = "CROPPED_IMG_URL";
export const SHOW_INFORMATION = "SHOW_INFORMATION";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const PASSWORD_UPDATED = "PASSWORD_UPDATED";
export const SET_LOGIN_LOADER_HEIGHT = "SET_LOGIN_LOADER_HEIGHT";
export const SET_LOGIN_LOADER_OPACITY = "SET_LOGIN_LOADER_OPACITY";
export const BROWSE_REMOTE_CONSOLE = "BROWSE_REMOTE_CONSOLE";
export const SHOW_HYPERVISOR_LIST = "SHOW_HYPERVISOR_LIST";
export const LIST_HYPERVISORS = "LIST_HYPERVISORS";
export const LIST_HYPERVISORS_SUCCESS = "LIST_HYPERVISORS_SUCCESS";
export const GET_VMS_LIST = "GET_VMS_LIST";
export const SHOW_VMS_LIST = "SHOW_VMS_LIST";
export const GET_TRANSACTION_DETAILS_SUCCESS =
  "GET_TRANSACTION_DETAILS_SUCCESS";
export const GET_TRANSACTION_DETAILS = "GET_TRANSACTION_DETAILS";
export const SET_THEME = "SET_THEME";
export const REMOVE_CCDATAGRID_INSTANCE = "REMOVE_CCDATAGRID_INSTANCE";

export const ADD_NEW_SOURCE = "ADD_NEW_SOURCE";

export const GET_SOURCE_DETAILS = "GET_SOURCE_DETAILS";
export const SHOW_SOURCE_DETAILS = "SHOW_SOURCE_DETAILS";
export const DISMISS_ALERT = "DISMISS_ALERT";
export const UPDATE_BREADCRUMB_LIST = "UPDATE_BREADCRUMB_LIST";
export const UPLOAD_PHOTO = "UPLOAD_PHOTO";
export const DELETE_PHOTO = "DELETE_PHOTO";
export const USER_PHOTO_DELETED = "USER_PHOTO_DELETED";
export const USER_PHOTO_UPDATED = "USER_PHOTO_UPDATED";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const GET_DATA_CENTERS = "GET_DATA_CENTERS";
export const SHOW_DATA_CENTERS = "SHOW_DATA_CENTERS";
export const HANDLE_GRID_CONTEXTUAL_ACTIONS = "HANDLE_GRID_CONTEXTUAL_ACTIONS";
export const REMOVE_SOURCE = "REMOVE_SOURCE";
export const REMOVE_POLICY = "REMOVE_POLICY";
export const DISABLE_POLICY = "DISABLE_POLICY";
export const ENABLE_POLICY = "ENABLE_POLICY";
export const API_RESPONSE = "API_RESPONSE";
export const START_FAILBACK_LOADING = "START_FAILBACK_LOADING";
export const START_FAILBACK_SUCCESS = "START_FAILBACK_SUCCESS";
export const START_FAILBACK_FAILURE = "START_FAILBACK_FAILURE";
export const UPGRADE_AGENT = "UPGRADE_AGENT";
export const DELETE_DESTINATION = "DELETE_DESTINATION";
export const TOGGLE_BACKUP = "TOGGLE_BACKUP";
export const TOGGLE_BACKUP_WITH_PAYLOAD = "TOGGLE_BACKUP_WITH_PAYLOAD";
export const CANCEL_RECOVERY = "CANCEL_RECOVERY";
export const SHOW_SQL_ADDITIONAL_DATABASE_OPTIONS_MODAL =
  "SHOW_SQL_ADDITIONAL_DATABASE_OPTIONS_MODAL";
export const UPDATE_RESOURCE_IN_GRID = "UPDATE_RESOURCE_IN_GRID";
export const UPDATE_ORACLE_HOSTS_IN_GRID = "UPDATE_ORACLE_HOSTS_IN_GRID";
export const GET_SOURCE_GROUP = "GET_SOURCE_GROUP";
export const UPDATE_SOURCE_GROUP = "UPDATE_SOURCE_GROUP";
export const ASSIGN_UNASSIGN_SOURCE_GROUP = "ASSIGN_UNASSIGN_SOURCE_GROUP";
export const UPDATED_DESTINATION_LIST = "UPDATED_DESTINATION_LIST";
export const DELETED_DESTINATION_LIST = "DELETED_DESTINATION_LIST";
export const ADD_SOURCE_GROUP_IN_LIST = "ADD_SOURCE_GROUP_IN_LIST";
export const SHOW_HIDE_CREATE_SOURCE_GROUP = "SHOW_HIDE_CREATE_SOURCE_GROUP";
export const CREATE_SOURCE_GROUP = "CREATE_SOURCE_GROUP";
export const GRID_DEFAULT_SELECTION = "GRID_DEFAULT_SELECTION";
export const FILTERED_SOURCE_GROUP = "FILTERED_SOURCE_GROUP";
export const GET_USERS = "GET_USERS"; //  fetch list of users from back end
export const GET_USERS_V2 = "GET_USERS_V2"; //  fetch list of users from back end
export const UPDATE_USERS = "UPDATE_USERS"; //  update list of users in the configureReducer
export const UPDATE_USERS_V2 = "UPDATE_USERS_V2";
export const DELETE_USER = "DELETE_USER"; //  delete user by id from back end
export const DELETE_UPDATE_USER_ACCOUNTS = "DELETE_UPDATE_USER_ACCOUNTS"; //  update users data after successful delete
export const DELETE_UPDATE_USER_ACCOUNTS_V2 = "DELETE_UPDATE_USER_ACCOUNTS_V2";
export const SHOW_ADD_USER = "SHOW_ADD_USER"; //  update the flag used to show/hide 'Add User' modal form in Configure -> User Accounts
export const SHOW_DIRECT_USER_PERMISSION = "SHOW_DIRECT_USER_PERMISSION"; //  update the flag used to show/hide 'Direct User Permission' modal form in Configure -> User Accounts
export const SHOW_MSP_USER_PERMISSION = "SHOW_MSP_USER_PERMISSION"; //  update the flag used to show/hide 'MSP User Permission' modal form in MSP -> Configure -> User Accounts
export const GET_USER_ROLES = "GET_USER_ROLES"; //  get the user roles from back end
export const UPDATE_USER_ROLES = "UPDATE_USER_ROLES"; //  update list of user roles in the configureReducer
export const ADD_USER = "ADD_USER"; //  create a new user
export const UPDATE_DIRECT_USER_PERMISSIONS = "UPDATE_DIRECT_USER_PERMISSIONS";
export const MANAGE_MSP_USER_PERMISSIONS = "MANAGE_MSP_USER_PERMISSIONS";
export const ADD_UPDATE_USERS = "ADD_UPDATE_USERS"; //  update users after creating a new user
export const ADD_UPDATE_USERS_V2 = "ADD_UPDATE_USERS_V2";
export const SET_SELECTED_USER = "SET_SELECTED_USER"; //  set the selected user to be updated
export const SET_ADD_MODE = "SET_ADD_MODE";
export const EDIT_UPDATE_USERS = "EDIT_UPDATE_USERS"; //  update users list after updating a user
export const EDIT_UPDATE_USERS_V2 = "EDIT_UPDATE_USERS_V2";
export const SHOW_BROWSE_RECOVERY_POINT = "SHOW_BROWSE_RECOVERY_POINT";
export const SWITCH_RECOVERY_TABS = "SWITCH_RECOVERY_TABS";
export const DOWNLOAD_RECOVERY_POINT = "DOWNLOAD_RECOVERY_POINT";
export const MOUNTPOINT_MODAL = "MOUNTPOINT_MODAL";
export const GET_MOUNTPOINT_INFO = "GET_MOUNTPOINT_INFO";
export const STORE_MOUNTPOINT_INFO = "STORE_MOUNTPOINT_INFO";
export const CANCEL_JOB_BY_ID = "CANCEL_JOB_BY_ID";
export const DELETE_JOB_BY_ID = "DELETE_JOB_BY_ID";
export const CANCEL_BACKUP_JOB_BY_ID = "CANCEL_BACKUP_JOB_BY_ID";
export const GET_JOB_DETAILS = "GET_JOB_DETAILS";
export const GET_MONITOR_JOB_DETAILS = "GET_MONITOR_JOB_DETAILS";
export const UPDATE_MONITOR_JOB_DETAILS = "UPDATE_MONITOR_JOB_DETAILS";
export const CLEAR_MONITOR_JOB_DETAILS = "CLEAR_MONITOR_JOB_DETAILS";
export const SHOW_JOB_DETAILS = "SHOW_JOB_DETAILS";
export const CLEAR_JOB_DETAILS = "CLEAR_JOB_DETAILS";
export const UPDATE_SELECTED_RESOURCE = "UPDATE_SELECTED_RESOURCE";
export const UPDATE_JOB_RESOURCE_IN_GRID = "UPDATE_JOB_RESOURCE_IN_GRID";
export const GET_CURRENT_ACTION_CALLED = "GET_CURRENT_ACTION_CALLED"; //for getting current action called when clicked on secondary navigation
export const CREATING_NEW_POLICY = "CREATING_NEW_POLICY"; //for adding default schedule to backup schedules
export const SET_SELECTED_ROLE = "SET_SELECTED_ROLE"; //  set selected role in dropdown
export const GET_CUSTOMER_ACCOUNTS_LIST = "GET_CUSTOMER_ACCOUNTS_LIST"; //  get customer accounts list data
export const DELETE_CUSTOMER_ACCOUNT = "DELETE_CUSTOMER_ACCOUNT"; //  delete customer account
export const SUSPEND_CUSTOMER_ACCOUNT = "SUSPEND_CUSTOMER_ACCOUNT"; //  suspend customer account
export const ENABLE_CUSTOMER_ACCOUNT = "ENABLE_CUSTOMER_ACCOUNT"; //  enable customer account
export const DELETED_CUSTOMER_ACCOUNT_DATA = "DELETED_CUSTOMER_ACCOUNT_DATA"; //  deleted customer account data
export const GET_CUSTOMER_ACCOUNT_DETAILS = "GET_CUSTOMER_ACCOUNT_DETAILS"; //  get customer account data
export const UPDATE_CUSTOMER_ACCOUNT_DETAILS =
  "UPDATE_CUSTOMER_ACCOUNT_DETAILS"; //  update customer account data in reducer
export const EDIT_UPDATE_CUSTOMER_ACCOUNT_DETAILS =
  "EDIT_UPDATE_CUSTOMER_ACCOUNT_DETAILS"; //  edit customer account data
export const SHOW_ADD_CUSTOMER_ACCOUNT_MODAL =
  "SHOW_ADD_CUSTOMER_ACCOUNT_MODAL"; //show/hide add customer accnt form
export const ADD_CUSTOMER_ACCOUNT_DATA = "ADD_CUSTOMER_ACCOUNT_DATA"; //add customer accnt data
export const UPDATE_ADDED_CUSTOMER_ACCOUNT_DATA =
  "UPDATE_ADDED_CUSTOMER_ACCOUNT_DATA"; //update inserted cust data in reducer
export const UPDATE_METRICS_CHECKBOX_FILTER = "UPDATE_METRICS_CHECKBOX_FILTER"; //update metrics checkboxes
export const EXPORT_CUSTOMER_ACCOUNT = "EXPORT_CUSTOMER_ACCOUNT";

export const GET_ROLE_DETAILS = "GET_ROLE_DETAILS";
export const SHOW_ROLE_DETAILS = "SHOW_ROLE_DETAILS";
export const CLEAR_STATE_USER_ROLES = "CLEAR_STATE_USER_ROLES";
export const GET_LICENSE_DETAILS = "GET_LICENSE_DETAILS";
export const SHOW_LICENSE_DETAILS = "SHOW_LICENSE_DETAILS";
export const SHOW_HIDE_ACTIVATE_ORDER_MODAL = "SHOW_HIDE_ACTIVATE_ORDER_MODAL";
export const ACTIVATE_ORDER = "ACTIVATE_ORDER";
export const CLEAR_LICENSE_DETAILS_VIEW = "CLEAR_LICENSE_DETAILS_VIEW";

export const SHOW_RECOVERED_RESOURCES = "SHOW_RECOVERED_RESOURCES"; //show the list of recovered resources
export const RECOVERED_RESOURCE_DETAILS = "RECOVERED_RESOURCE_DETAILS"; //get the details of selected resource
export const SHOW_RESOURCE_DETAILS = "SHOW_RESOURCE_DETAILS"; //show the details of selected resource
export const SET_BROWSE_URL = "SET_BROWSE_URL"; //set the browse recovery point URL
export const SET_RESOURCE_NAME = "SET_RESOURCE_NAME"; //Update resource name, to be used for populating breadcrumbs
export const SHOW_SET_USAGE_THRESHOLD_MODAL = "SHOW_SET_USAGE_THRESHOLD_MODAL"; //show set usage threshold form
export const EDIT_SET_USAGE_THRESHOLD_MODAL = "EDIT_SET_USAGE_THRESHOLD_MODAL"; //edit set usage threshold form
export const UPDATE_SET_USAGE_THRESHOLD_MODAL =
  "UPDATE_SET_USAGE_THRESHOLD_MODAL"; //update set usage threshold in reducer
export const SHOW_HIDE_ASSIGN_USERS_MODAL = "SHOW_HIDE_ASSIGN_USERS_MODAL"; //show hide assign users modal
export const GET_ADMIN_FOR_ASSIGN = "GET_ADMIN_FOR_ASSIGN"; //search admin from api
export const RESEND_ENROLL_EMAIL = "RESEND_ENROLL_EMAIL"; //resend enrollment email
export const ADD_ADMIN_TO_SHOW_TAGS = "ADD_ADMIN_TO_SHOW_TAGS"; //add admin in reducer to show tags
export const UPDATE_ADMIN_DETAILS_TO_ASSIGN = "UPDATE_ADMIN_DETAILS_TO_ASSIGN"; //update admin in reducer to show tags

export const IMPERSONATE_CUSTOMER = "IMPERSONATE_CUSTOMER"; //Opens Impersonated customer View (updates state)
export const IMPERSONATE_SOURCE = "IMPERSONATE_SOURCE"; //Opens Impersonated source View (updates state)
export const IMPERSONATE_POLICY = "IMPERSONATE_POLICY"; //Opens Impersonated POLICY View (updates state)
export const IMPERSONATE_JOB = "IMPERSONATE_JOB"; //Opens Impersonated analyze View (updates state)
export const IMPERSONATE_RECOVERY_POINTS = "IMPERSONATE_RECOVERY_POINTS"; //Opens Impersonated recovery point View (updates state)
export const RESET_IMPERSONATE_CUSTOMER = "RESET_IMPERSONATE_CUSTOMER"; //resets Impersonated customer View state on home click

export const GET_DESTINATION_LIST_MOCK = "GET_DESTINATION_LIST_MOCK"; //destination mock purpose
export const SHOW_DESTINATION_LIST_MOCK = "SHOW_DESTINATION_LIST_MOCK"; //destination mock purpose

export const GET_PROTECT_CUSTOMER_LIST = "GET_PROTECT_CUSTOMER_LIST";
export const SHOW_PROTECT_CUSTOMER_LIST = "SHOW_PROTECT_CUSTOMER_LIST";
export const UPDATE_SELECTED_ADMIN = "UPDATE_SELECTED_ADMIN"; //for admin search string update in reducer
export const UPDATE_ADMIN_DROPDOWN_OPTIONS = "UPDATE_ADMIN_DROPDOWN_OPTIONS"; //for admin selected dropdown values update in reducer
export const REMOVE_ADMIN_ASSIGN_TAGS = "REMOVE_ADMIN_ASSIGN_TAGS"; //for remove tag update in reducer
export const ASSIGNED_ADMIN_POST_DATA = "ASSIGNED_ADMIN_POST_DATA"; //for assigned admin data be
export const UPDATE_ASSIGNED_ADMIN_USER_LIST =
  "UPDATE_ASSIGNED_ADMIN_USER_LIST"; //for adding assigned admin user in reducer
export const UNASSIGN_ADMIN_FROM_CUSTOMERS_LIST =
  "UNASSIGN_ADMIN_FROM_CUSTOMERS_LIST"; //for adding assigned admin user in reducer
export const REMOVE_UPDATE_UNASSIGN_ADMIN_FROM_CUSTOMER =
  "REMOVE_UPDATE_UNASSIGN_ADMIN_FROM_CUSTOMER"; //for remove assigned admin user in reducer
export const SHOW_CUSTOMER_ACCOUNTS_LIST = "SHOW_CUSTOMER_ACCOUNTS_LIST"; //for updating customer accnt list in reducer
export const GET_RECOVERY_POINTS = "GET_RECOVERY_POINTS"; //  to get recovery points
export const FILTER_POLICY_SCHEDULES = "FILTER_POLICY_SCHEDULES"; //  to filter schedules in policy
export const FILTER_POLICY_SCHEDULES_DROPDOWN =
  "FILTER_POLICY_SCHEDULES_DROPDOWN"; //  to add destination filter schedules dropdown in policy
export const UPDATE_SOURCE_CONFIGURATION = "UPDATE_SOURCE_CONFIGURATION"; //  update the source -> configuration
export const DELETE_POLICY_FROM_GRID = "DELETE_POLICY_FROM_GRID"; // remove deleted policy from grid
export const DELETE_SOURCE_FROM_GRID = "DELETE_SOURCE_FROM_GRID"; // remove hard deleted source from grid
export const DELETE_ORACLE_HOSTS_FROM_GRID = "DELETE_ORACLE_HOSTS_FROM_GRID"; //remove hard deleted oracle hosts from grid
export const CLEAR_POLICY_DETAILS_STATE = "CLEAR_POLICY_DETAILS_STATE"; // clear policy state
export const REDIRECT_TO_POLICY_GRID = "REDIRECT_TO_POLICY_GRID"; // redirects to policy grid
export const FETCH_USER_LANGUAGE = "FETCH_USER_LANGUAGE";
export const FETCH_USER_LANGUAGE_SUCCESS = "FETCH_USER_LANGUAGE_SUCCESS";
export const FETCH_USER_LANGUAGE_ERROR = "FETCH_USER_LANGUAGE_ERROR";
/**
 * update the sourceData in protectReducer after a configuration is updated at the back end
 */
export const EDIT_UPDATE_SOURCE_CONFIGURATION =
  "EDIT_UPDATE_SOURCE_CONFIGURATION";
export const UPDATE_CREATE_GROUP_FLAG = "UPDATE_CREATE_GROUP_FLAG"; //
export const DELETE_SOURCE_GROUP = "DELETE_SOURCE_GROUP"; //  delete source group from back end
export const DELETE_RECOVERED_VM = "DELETE_RECOVERED_VM";
export const UNMOUNT_RPS = "UNMOUNT_RPS";
export const START_FAILBACK = "START_FAILBACK"; //start failback action for recovered resource
export const STOP_FAILBACK = "STOP_FAILBACK"; //stop failback action for recovered resource
export const START_FINAL_FAILBACK_STEP = "START_FINAL_FAILBACK_STEP"; //start final failback action for recovered resource
export const DELETE_RECOVERED_VM_FROM_GRID = "DELETE_RECOVERED_VM_FROM_GRID";
export const DELETE_UPDATE_SOURCE_GROUP = "DELETE_UPDATE_SOURCE_GROUP"; //  update groups in reducer after delete
export const GET_SOURCES_BY_GROUP = "GET_SOURCES_BY_GROUP"; //  fetch sources for selected group from back end
export const UPDATE_SOURCES = "UPDATE_SOURCES"; //  update sources fetched for selected group into configureReducer
export const REMOVE_SOURCES_FROM_GROUP = "REMOVE_SOURCES_FROM_GROUP"; //  remove source/s from a source group
export const DELETE_UPDATE_SOURCES = "DELETE_UPDATE_SOURCES"; //  update sources from list after successul delete
export const ADD_SOURCES_TO_GROUP = "ADD_SOURCES_TO_GROUP"; //  add multiple sources to a group
export const SET_SELECTED_SOURCE_GROUP = "SET_SELECTED_SOURCE_GROUP"; //  set selected source group info
export const GET_WIDGETS = "GET_WIDGETS";
export const UPDATE_WIDGETS_ORDER = "UPDATE_WIDGETS_ORDER";

export const GET_WIDGETS_LIST = "GET_WIDGETS_LIST";
export const GET_REPORT_COLUMN_DATA = "GET_REPORT_COLUMN_DATA";
export const GET_REPORT_LIST = "GET_REPORT_LIST";
export const SHOW_REPORT_LIST = "SHOW_REPORT_LIST";
export const GET_REPORT_SCHEDULES = "GET_REPORT_SCHEDULES";
export const UPDATE_REPORT_SCHEDULE = "UPDATE_REPORT_SCHEDULE";
export const DELETE_SCHEDULED_REPORT_FOR_MANAGE_SCHEDULES =
  "DELETE_SCHEDULED_REPORT_FOR_MANAGE_SCHEDULES";
export const DELETE_ANALYZE_ALERT = "DELETE_ANALYZE_ALERT";

export const CLEAR_SELECTED_RESOURCE = "CLEAR_SELECTED_RESOURCE";
export const UPDATE_BRAND_LOGO = "UPDATE_BRAND_LOGO"; //  to show brand logo modal
export const GET_BRANDING_DETAILS = "GET_BRANDING_DETAILS";
export const SET_LAST_VIEWED_APP = "SET_LAST_VIEWED_APP";
export const SHOW_BRANDING_DETAILS = "SHOW_BRANDING_DETAILS";
export const EDIT_UPDATE_BRAND_INFORMATION = "EDIT_UPDATE_BRAND_INFORMATION";
export const CLEAR_BRAND_INFORMATION = "CLEAR_BRAND_INFORMATION";
export const UPDATE_BRAND_INFORMATION = "UPDATE_BRAND_INFORMATION";
export const GET_EMAILER_DETAILS = "GET_EMAILER_DETAILS";
export const SHOW_EMAILER_DETAILS = "SHOW_EMAILER_DETAILS";
export const EDIT_UPDATE_EMAILER_INFORMATION =
  "EDIT_UPDATE_EMAILER_INFORMATION";
export const UPDATE_IS_BRANDING_LOGO = "UPDATE_IS_BRANDING_LOGO";
export const GET_HYPERVISORS_OF_TYPE = "GET_HYPERVISORS_OF_TYPE";
export const GET_HYPERVISORS = "GET_HYPERVISORS";
export const GET_HYPERVISORS_SUCCESS = "GET_HYPERVISORS_SUCCESS";
export const GET_HYPERVISORS_FAILED = "GET_HYPERVISORS_FAILED";
export const RESET_HYPERVISORS_LIST = "RESET_HYPERVISORS_LIST";
export const GET_HYPERV_OF_TYPE_SUCCESS = "GET_HYPERV_OF_TYPE_SUCCESS";
export const RESET_HYPERV_LIST = "RESET_HYPERV_LIST";
export const GET_HYPERV_OF_TYPE_FAILED = "GET_HYPERV_OF_TYPE_FAILED";
export const UPDATE_EMAILER_INFORMATION = "UPDATE_EMAILER_INFORMATION";
export const GET_RECOVERY_POINTS_DEATILS = "GET_RECOVERY_POINTS_DEATILS"; //recovery point details for showing subcomponent by accordian
export const UPDATED_RECOVERY_SUBCOMPONENT_DETAILS =
  "UPDATED_RECOVERY_SUBCOMPONENT_DETAILS"; //recovery point details for showing subcomponent by accordian
export const RESET_PASSWORD = "RESET_PASSWORD"; //  reset user password
export const RESEND_VERIFICATION_EMAIL = "RESEND_VERIFICATION_EMAIL"; //  resend user verification mail
export const CHANGE_URL = "CHANGE_URL"; //  change url flag, based on this flag change url
export const CLEAR_SOURCE_GROUPS = "CLEAR_SOURCE_GROUPS"; //  clear all source groups data
export const CLEAR_CONTEXTUAL_ACTION = "CLEAR_CONTEXTUAL_ACTION"; //clear the contextual action
export const SET_SORTED_DATA = "SET_SORTED_DATA"; //  sorted columns info
export const CLEAR_SORTED_DATA = "CLEAR_SORTED_DATA"; //  clear sorted columns info on destroy of component

export const GET_BRANDING_IMG_URL = "GET_BRANDING_IMG_URL";
export const UPDATE_LOGO_IMG_URL = "UPDATE_LOGO_IMG_URL";
export const UPDATE_LOGIN_IMG_URL = "UPDATE_LOGIN_IMG_URL";
export const UPDATE_BRANDING_FROM = "UPDATE_BRANDING_FROM";

export const GET_HYPERVISORS_LIST = "GET_HYPERVISORS_LIST";
export const GET_STORAGE_ARRAYS = "GET_STORAGE_ARRAYS";
export const SHOW_STORAGE_ARRAYS = "SHOW_STORAGE_ARRAYS";
export const SHOW_SELECTED_USAGE_THRESHOLD = "SHOW_SELECTED_USAGE_THRESHOLD"; //  show slected threshold on form
export const SHOW_ALERT_DETAILS_MODAL = "SHOW_ALERT_DETAILS_MODAL"; //show/hide alert details modal
export const GET_ALERT_LIST = "GET_ALERT_LIST"; //get alerts list
export const UPDATE_ALERT_LIST_COUNT = "UPDATE_ALERT_LIST_COUNT"; //update alerts list count
export const RESET_ALERT_LIST_COUNT = "RESET_ALERT_LIST_COUNT"; //reset alerts list count
export const CONFIGURE_ACCOUNT_TYPE = "CONFIGURE_ACCOUNT_TYPE";
export const GET_ORGANIZATION_WITHOUT_ID = "GET_ORGANIZATION_WITHOUT_ID";
export const SET_ORGANIZATION_TYPE = "SET_ORGANIZATION_TYPE";
export const CLOSE_ALERT_LIST = "CLOSE_ALERT_LIST";

export const GET_ALERT_LIST_DETAIL = "GET_ALERT_LIST_DETAIL";
export const SHOW_ALERT_DETAILS = "SHOW_ALERT_DETAILS";

export const SHOW_ALERT_LIST = "SHOW_ALERT_LIST"; //show alerts list
export const REMOVE_ALERT = "REMOVE_ALERT"; //remove alert from list
export const UPDATE_ALERT = "UPDATE_ALERT";
export const ACKNOWLEDGE_ALL_ALERTS = "ACKNOWLEDGE_ALL_ALERTS"; //to remove all acknowledged alerts

export const FROM_TO_FILTER = "FROM_TO_FILTER";
export const DELETE_REPORT_BY_ID = "DELETE_REPORT_BY_ID";
export const DELETE_SCHEDULE_REPORT = "DELETE_SCHEDULE_REPORT";
export const RUN_REPORT_NOW = "RUN_REPORT_NOW";
export const SHOW_REPORT_OVERLAY = "SHOW_REPORT_OVERLAY";
export const UPDATE_REPORT_DATA = "UPDATE_REPORT_DATA";
export const CLOSE_REPORT_MODAL = "CLOSE_REPORT_MODAL";
export const CREATE_REPORT = "CREATE_REPORT";
export const CREATE_ANALYZE_ALERT = "CREATE_ANALYZE_ALERT";
export const FETCH_ANALYZE_ALERTS = "FETCH_ANALYZE_ALERTS";
export const UPDATE_ANALYZE_ALERT = "UPDATE_ANALYZE_ALERT";
export const UPDATE_REPORT_SETTINGS = "UPDATE_REPORT_SETTINGS";

export const SHOW_NETWORK_CONFIG_VIEW = "SHOW_NETWORK_CONFIG_VIEW";
export const GET_NETWORK_CONFIG_VIEW = "GET_NETWORK_CONFIG_VIEW";

export const REMOVE_ASSIGNED_CUSTOMER = "REMOVE_ASSIGNED_CUSTOMER";
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const GET_MSP_CHILD_ACCOUNTS = "GET_MSP_CHILD_ACCOUNTS";
export const UPDATE_CUSTOMER_LIST = "UPDATE_CUSTOMER_LIST";
export const UPDATE_MSP_CHILD_ACCOUNTS = "UPDATE_MSP_CHILD_ACCOUNTS";
export const CLEAR_ADD_USER_STATE = "CLEAR_ADD_USER_STATE";
export const UPDATE_ASSIGNED_CUSTOMERS = "UPDATE_ASSIGNED_CUSTOMERS";
export const GET_FILE_BROWSER_DATA = "GET_FILE_BROWSER_DATA"; //get file browser data from api
export const UPDATE_FILE_BROWSER_DATA = "UPDATE_FILE_BROWSER_DATA"; //UPDATE file browser data in reducer
export const UPDATE_FILE_BROWSER_GRID_DATA = "UPDATE_FILE_BROWSER_GRID_DATA"; //UPDATE file browser grid data in reducer
export const UPDATE_FILE_BROWSER_GRID_PAGINATION_DATA =
  "UPDATE_FILE_BROWSER_GRID_PAGINATION_DATA"; //UPDATE file browser grid data in reducer
export const SET_RESTORE_COOKIE_AUTH = "SET_RESTORE_COOKIE_AUTH"; //  set cookie auth
// export const UPDATE_RESTORE_COOKIE_FLAG = "UPDATE_RESTORE_COOKIE_FLAG"; //  set cookie auth flag
export const UPDATE_RECOVERY_POINT_DETAILS = "UPDATE_RECOVERY_POINT_DETAILS"; //  update recovery point details on subcomponent
export const UPDATE_RECOVERED_VMS = "UPDATE_RECOVERED_VMS";
export const UPDATE_MOUNTED_RPS = "UPDATE_MOUNTED_RPS";
export const GET_SELECTED_RECOVERED_VM = "GET_SELECTED_RECOVERED_VM";
export const GET_SELECTED_RECOVERED_VM_USING_SOURCE_ID =
  "GET_SELECTED_RECOVERED_VM_USING_SOURCE_ID";
export const UPDATE_SELECTED_RECOVERED_VM = "UPDATE_SELECTED_RECOVERED_VM";
export const ACTION_FOR_RECOVERED_VM = "ACTION_FOR_RECOVERED_VM"; //  recovered VM action
export const CREATE_PASSWORD = "CREATE_PASSWORD"; //User Verification - Create Password.
export const CREATE_PASSWORD_SUCCESS = "CREATE_PASSWORD_SUCCESS"; //navigate to /login.
export const CREATE_PASSWORD_ERROR = "CREATE_PASSWORD_ERROR"; //shows error
export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT"; //Activate account/Customer enrollment.
export const ENROLL_PERSONAL_DETAILS = "ENROLL_PERSONAL_DETAILS"; //Enroll personal Details.
export const RESEND_ACTIVATION_EMAIL = "RESEND_ACTIVATION_EMAIL"; //resend activation email.
export const DATACENTER_REGION = "DATACENTER_REGION"; //get data center regions.
export const NEXT_ENROLL_PAGE = "NEXT_ENROLL_PAGE"; //increment enrollment page wizard.
export const UPDATE_DATA_CENTER_LIST = "UPDATE_DATA_CENTER_LIST";
export const ACTIVATION_SUCCESSFUL = "ACTIVATION_SUCCESSFUL";
export const ACTIVATION_FAILED = "ACTIVATION_FAILED";
export const CLEAR_ACTIVATION_ERROR = "CLEAR_ACTIVATION_ERROR";
export const GET_CONFIGURE_COUNT = "GET_CONFIGURE_COUNT"; //  get count for source groups, user accounts and roles
export const UPDATE_CONFIGURE_COUNT = "UPDATE_CONFIGURE_COUNT";
export const UPDATE_SOURCE_LIST_COUNT = "UPDATE_SOURCE_LIST_COUNT";

// Dashboard action constants
export const LOAD_WIDGETS = "LOAD_WIDGETS";
export const UPDATE_WIDGETS = "UPDATE_WIDGETS";
export const UPDATE_SUMMARY_WIDGETS = "UPDATE_SUMMARY_WIDGETS";
export const MANAGE_WIDGETS = "MANAGE_WIDGETS";
export const LOAD_DASHBOARD_TOP_CUSTOMERS_WIDGET =
  "LOAD_DASHBOARD_TOP_CUSTOMERS_WIDGET";
export const LOAD_DASHBOARD_TOP_POLICIES_WIDGET =
  "LOAD_DASHBOARD_TOP_POLICIES_WIDGET";
export const LOAD_DATA_TRANSFER_SUMMARY_WIDGET =
  "LOAD_DATA_TRANSFER_SUMMARY_WIDGET";
export const FILTER_DASHBOARD_TOP_SOURCES_WIDGET_DATA =
  "FILTER_DASHBOARD_TOP_SOURCES_WIDGET_DATA";
export const LOAD_CAPACITY_USAGE_TREND_WIDGET =
  "LOAD_CAPACITY_USAGE_TREND_WIDGET";
export const LOAD_USAGE_TREND_CD_VOLUMES_WIDGET =
  "LOAD_USAGE_TREND_CD_VOLUMES_WIDGET";
export const LOAD_USAGE_TREND_CH_STORES_WIDGET =
  "LOAD_USAGE_TREND_CH_STORES_WIDGET";
export const LOAD_RECENT_JOBS_IN_PROGRESS_WIDGET =
  "LOAD_RECENT_JOBS_IN_PROGRESS_WIDGET";
export const CLEAR_WIDGET_DATA = "CLEAR_WIDGET_DATA";
export const UPDATE_DATE_RANGE_VALUE = "UPDATE_DATE_RANGE_VALUE";
export const UPDATE_DATE_RANGE_VALUE_CAPACITY_DATATRANSFER =
  "UPDATE_DATE_RANGE_VALUE_CAPACITY_DATATRANSFER";

export const UPDATE_ASSIGNED_SOURCE_GROUPS = "UPDATE_ASSIGNED_SOURCE_GROUPS";
export const REMOVE_ASSIGNED_SOURCE_GROUP = "REMOVE_ASSIGNED_SOURCE_GROUP";
export const REMOVE_ASSIGNED_EMAIL = "REMOVE_ASSIGNED_EMAIL";
export const UPDATE_ASSIGNED_EMAILS = "UPDATE_ASSIGNED_EMAILS";
export const EXPAND_WIDGET = "EXPAND_WIDGET";
export const FETCH_BACKUP_JOBS = "FETCH_BACKUP_JOBS";
export const UPDATE_BACKUP_JOBS_TOP_SOURCES = "UPDATE_BACKUP_JOBS_TOP_SOURCES";
export const FILTER_BACKUP_JOBS_TOP_SOURCES = "FILTER_BACKUP_JOBS_TOP_SOURCES";
export const REFRESH_BACKUP_TOP_SOURCES = "REFRESH_BACKUP_TOP_SOURCES";
export const REFRESH_BACKUP_JOBS_SUMMARY = "REFRESH_BACKUP_JOBS_SUMMARY";
export const UPDATE_BACKUP_JOBS_TREND_SUMMARY =
  "UPDATE_BACKUP_JOBS_TREND_SUMMARY";
export const MAXIMIZE = "maximize";
export const COLLAPSE = "collapse";
export const UPDATE_FILE_BROWSER_NODE_DATA = "UPDATE_FILE_BROWSER_NODE_DATA"; //to update file browser node data
export const UPDATE_FILE_BROWSER_NODE_DATA_FOR_PAGINATION =
  "UPDATE_FILE_BROWSER_NODE_DATA_FOR_PAGINATION"; //to update file browser node data
export const UPDATE_GRID_PAGINATION = "UPDATE_GRID_PAGINATION"; //to update the pagination query value
export const UPDATE_CUSTOMERS_TO_ASSIGN = "UPDATE_CUSTOMERS_TO_ASSIGN";
export const ADD_CUSTOMERS_TO_USER = "ADD_CUSTOMERS_TO_USER";

export const GET_HYPERVISOR_DETAIL = "GET_HYPERVISOR_DETAIL";
export const SHOW_HYPERVISOR_DETAILS = "SHOW_HYPERVISOR_DETAILS";
export const DOWNLOAD_AGENTS = "DOWNLOAD_AGENTS";
export const REFRESH_VMS = "REFRESH_VMS";
export const GET_DESTINATIONS_FOR_DROPDOWNS = "GET_DESTINATIONS_FOR_DROPDOWNS";
export const UPDATE_DESTINATIONS_FOR_DROPDOWNS =
  "UPDATE_DESTINATIONS_FOR_DROPDOWNS";
export const GET_CUSTOMERS_FOR_USER = "GET_CUSTOMERS_FOR_USER";
export const GET_RECOVERY_IMAGE_FORMATS = "GET_RECOVERY_IMAGE_FORMATS"; //get recovery image formats from api
export const UPDATE_RECOVERY_IMAGE_FORMATS = "UPDATE_RECOVERY_IMAGE_FORMATS"; //update recovery image formats in reducer
export const UPDATE_PARTICULAR_RECOVERY_POINT_DETAILS_DATA =
  "UPDATE_PARTICULAR_RECOVERY_POINT_DETAILS_DATA"; //update single recovery points data in reducer
export const UPDATE_RECOVERY_TARGETS_DATA = "UPDATE_RECOVERY_TARGETS_DATA"; //update recovery targets data in reducer
export const UPDATE_SELECTED_RECOVERY_IMAGE_FORMAT_DATA =
  "UPDATE_SELECTED_RECOVERY_IMAGE_FORMAT_DATA"; //update recovery image format data in reducer
export const UPDATE_RESTORE_RECOVER_PATH = "UPDATE_RESTORE_RECOVER_PATH"; //update restore recover path in reducer
export const UPDATE_RESTORE_RECOVER_ZFT_DATA =
  "UPDATE_RESTORE_RECOVER_ZFT_DATA"; //update restore recover path in reducer
export const GET_RECOVERY_TARGETS_DATA = "GET_RECOVERY_TARGETS_DATA"; //get recovery target data
export const SET_MACHINE_DETAILS = "SET_MACHINE_DETAILS";
export const GET_DOWNLOAD_LINKS = "GET_DOWNLOAD_LINKS";
export const RESET_IMAGE_UPLOAD_SUCCESS_FLAG =
  "RESET_IMAGE_UPLOAD_SUCCESS_FLAG";
export const GET_RESTORE_JOBS = "GET_RESTORE_JOBS";
export const UPDATE_RESTORE_JOBS_TOP_SOURCES =
  "UPDATE_RESTORE_JOBS_TOP_SOURCES";
export const UPDATE_RESTORE_JOBS_TREND_SUMMARY =
  "UPDATE_RESTORE_JOBS_TREND_SUMMARY";
export const FILTER_RESTORE_JOBS_TOP_SOURCES =
  "FILTER_RESTORE_JOBS_TOP_SOURCES";
export const UPDATE_RECOVER_TARGET_SOURCE_ID =
  "UPDATE_RECOVER_TARGET_SOURCE_ID"; //update recover target source id in reducer
export const UPDATE_RECOVER_TARGET_VMWARE_DATASTORE_NAME =
  "UPDATE_RECOVER_TARGET_VMWARE_DATASTORE_NAME";
export const UPDATE_RECOVER_TARGET_VMWARE_VM_NAME =
  "UPDATE_RECOVER_TARGET_VMWARE_VM_NAME";
export const POST_RECOVER_DATA = "POST_RECOVER_DATA"; //post recover data in saga
export const SWITCH_RECOVERY_POINTS_GROUP = "SWITCH_RECOVERY_POINTS_GROUP"; //switch rp group ungroup
export const SWITCH_RECOVERY_TARGET_MACHINE = "SWITCH_RECOVERY_TARGET_MACHINE"; //switch recovery target machines
export const CLEAR_RECOVERY_POINTS_STATE = "CLEAR_RECOVERY_POINTS_STATE"; //clear recovery points state
export const RECOVERY_POINS_DESTINATION_DETAILS =
  "RECOVERY_POINS_DESTINATION_DETAILS"; //rp in destination details
export const SHOW_HYPERVISOR_DETAIL = "SHOW_HYPERVISOR_DETAIL";
export const SHOW_NETWORK_PORT_FORWARD = "SHOW_NETWORK_PORT_FORWARD";
export const GET_NETWORK_PORT_FORWARD = "GET_NETWORK_PORT_FORWARD";
export const EDIT_NETWORK_CONFIG_LIST = "EDIT_NETWORK_CONFIG_LIST";
export const UPDATE_NETWORK_CONFIG_LIST = "UPDATE_NETWORK_CONFIG_LIST";
export const NETWORK_CONFIG_LIST = "NETWORK_CONFIG_LIST";
export const DELETE_NETWORK_CONFIG_LIST = "DELETE_NETWORK_CONFIG_LIST";
export const STATE_DELETE_NETWORK_CONFIG_LIST =
  "STATE_DELETE_NETWORK_CONFIG_LIST";
export const UPDATE_DELETED_NETWORK_CONFIG_LIST =
  "UPDATE_DELETED_NETWORK_CONFIG_LIST";
export const ADD_NETWORK_CONFIG_LIST = "ADD_NETWORK_CONFIG_LIST";
export const SHOW_ADDED_NETWORK_CONFIG_LIST = "SHOW_ADDED_NETWORK_CONFIG_LIST";
export const NEW_RULE_NETWORK_CONFIG_LIST = "NEW_RULE_NETWORK_CONFIG_LIST";
export const DOWNLOAD_NETWORK_SESSION_LOGS = "DOWNLOAD_NETWORK_SESSION_LOGS";
export const GET_DOWNLOAD_NETWORK_SESSION_LOGS =
  "GET_DOWNLOAD_NETWORK_SESSION_LOGS";
export const GET_DESTINATION_CD = "GET_DESTINATION_CD";
export const UPDATE_DESTINATION_CD = "UPDATE_DESTINATION_CD";
export const SET_CHART_FILTER = "SET_CHART_FILTER";
export const SHOW_DATE_FILTER_HANDLER = "SHOW_DATE_FILTER_HANDLER";
export const SET_SELECTED_DATES = "SET_SELECTED_DATES";
export const FETCH_RESTORE_JOBS_TREND_SUMMARY =
  "FETCH_RESTORE_JOBS_TREND_SUMMARY";
export const GET_CUSTOMER_ACCOUNT_CD = "GET_CUSTOMER_ACCOUNT_CD";
export const UPDATE_CUSTOMER_ACCOUNT_CD = "UPDATE_CUSTOMER_ACCOUNT_CD";
export const CLEAR_SELECTED_DATES = "CLEAR_SELECTED_DATES";
export const CLEAR_SELECTED_DATES_CH = "CLEAR_SELECTED_DATES_CH";
export const CLEAR_SELECTED_DATES_CU = "CLEAR_SELECTED_DATES_CU";
export const ASSIGN_POLICY_SOURCE = "ASSIGN_POLICY_SOURCE";
export const UNASSIGN_POLICY_SOURCE = "UNASSIGN_POLICY_SOURCE";
export const UPDATE_ASSIGN_POLICY_SOURCE = "UPDATE_ASSIGN_POLICY_SOURCE";
export const UPDATE_ASSIGN_TO_POLICY_SOURCE = "UPDATE_ASSIGN_TO_POLICY_SOURCE";
export const UPDATE_SELECTED_ASSIGN_POLICY_SOURCE =
  "UPDATE_SELECTED_ASSIGN_POLICY_SOURCE";
export const SHOW_LOGIN_TAB = "SHOW_LOGIN_TAB";
export const SET_RETENTION_DURATION = "SET_RETENTION_DURATION";
export const SET_SELECTED_HYPERVISOR = "SET_SELECTED_HYPERVISOR";
//data transfer charts
export const GET_DATA_TRANSFER_TREND_SUMMARY =
  "GET_DATA_TRANSFER_TREND_SUMMARY";
export const GET_DATA_TRANSFER_TREND_TOP_SOURCE =
  "GET_DATA_TRANSFER_TREND_TOP_SOURCE";
export const UPDATE_DATA_TRANSFER_TREND_SUMMARY =
  "UPDATE_DATA_TRANSFER_TREND_SUMMARY";
export const UPDATE_DATA_TRANSFER_TREND_TOP_SOURCE =
  "UPDATE_DATA_TRANSFER_TREND_TOP_SOURCE";

//Jobs and destination action types
export const GET_JOBS_AND_DESTINATION_JOBS_SUMMARY =
  "GET_JOBS_AND_DESTINATION_JOBS_SUMMARY";
export const GET_JOBS_AND_DESTINATION_TREND_TOP_SOURCE =
  "GET_JOBS_AND_DESTINATION_TREND_TOP_SOURCE";
export const GET_JOBS_AND_DESTINATION_TREND_TOP_SOURCE_FOR_DESTINATION =
  "GET_JOBS_AND_DESTINATION_TREND_TOP_SOURCE_FOR_DESTINATION";
export const UPDATE_JOBS_AND_DESTINATION_JOBS_SUMMARY =
  "UPDATE_JOBS_AND_DESTINATION_JOBS_SUMMARY";
export const UPDATE_JOBS_AND_DESTINATION_TREND_TOP_SOURCE =
  "UPDATE_JOBS_AND_DESTINATION_TREND_TOP_SOURCE";
export const UPDATE_JOBS_AND_DESTINATION_TREND_TOP_SOURCE_FOR_DESTINATION =
  "UPDATE_JOBS_AND_DESTINATION_TREND_TOP_SOURCE_FOR_DESTINATION";

//Capacity usage
export const GET_CAPACITY_USAGE_CD_TREND_USAGE =
  "GET_CAPACITY_USAGE_CD_TREND_USAGE";
export const GET_CAPACITY_USAGE_CH_TREND_USAGE =
  "GET_CAPACITY_USAGE_CH_TREND_USAGE";
export const GET_CAPACITY_USAGE_CH_STORE_SAVING_TREND =
  "GET_CAPACITY_USAGE_CH_STORE_SAVING_TREND";
export const UPDATE_CAPACITY_USAGE_CD_TREND_USAGE =
  "UPDATE_CAPACITY_USAGE_CD_TREND_USAGE";
export const UPDATE_CAPACITY_USAGE_CH_TREND_USAGE =
  "UPDATE_CAPACITY_USAGE_CH_TREND_USAGE";
export const UPDATE_CAPACITY_USAGE_CH_STORE_SAVING_TREND =
  "UPDATE_CAPACITY_USAGE_CH_STORE_SAVING_TREND";
export const GET_DASHBOARD_SUMMARY = "GET_DASHBOARD_SUMMARY";
export const UPDATE_DASHBOARD_SUMMARY = "UPDATE_DASHBOARD_SUMMARY";
export const GET_CH_RECOVERY_POINTS_DESTINATION_DETAILS =
  "GET_CH_RECOVERY_POINTS_DESTINATION_DETAILS";
export const UPDATE_NEW_TREE_BROWSER_PATH = "UPDATE_NEW_TREE_BROWSER_PATH"; //update specifically file browser data
export const GET_FILE_BROWSER_GRID_DATA = "GET_FILE_BROWSER_GRID_DATA"; //get file borwser grid data
export const UNMOUNT_FILE_BROWSER_DATA = "UNMOUNT_FILE_BROWSER_DATA"; //unmount file borwser grid data
export const GENERATE_DOWNLOAD_LINK_FOR_RP = "GENERATE_DOWNLOAD_LINK_FOR_RP"; //generate download link for RP

export const FETCH_BACKUP_JOBS_TOP_SOURCES = "FETCH_BACKUP_JOBS_TOP_SOURCES";

//toggle alerts panel
export const TOGGLE_ALERTS_PANEL = "TOGGLE_ALERTS_PANEL";
export const HIDE_ALERTS_PANEL = "HIDE_ALERTS_PANEL";
export const JOBS_AND_DESTINATION_ADVANCE_SEARCH =
  "JOBS_AND_DESTINATION_ADVANCE_SEARCH";
export const GET_DESTINATION_CH = "GET_DESTINATION_CH";
export const UPDATE_DESTINATION_CH = "UPDATE_DESTINATION_CH";
export const GET_CUSTOMER_ACCOUNT_CH = "GET_CUSTOMER_ACCOUNT_CH";
export const UPDATE_CUSTOMER_ACCOUNT_CH = "UPDATE_CUSTOMER_ACCOUNT_CH";
export const SHOW_DATE_FILTER_CH_HANDLER = "SHOW_DATE_FILTER_CH_HANDLER";
export const SHOW_DATE_FILTER_CU_HANDLER = "SHOW_DATE_FILTER_CU_HANDLER";
export const SHOW_HIDE_PROTECTED_PASSWORD_FORM =
  "SHOW_HIDE_PROTECTED_PASSWORD_FORM"; //show hide protected modal
export const POST_PROTECTED_PASSWORD_DATA = "POST_PROTECTED_PASSWORD_DATA"; //show hide protected modal
export const UPDATE_RESTORE_RECOVER_SOURCE_TO_PATH =
  "UPDATE_RESTORE_RECOVER_SOURCE_TO_PATH"; //update or modify restore recover path
export const SOURCE_PROVISION = "SOURCE_PROVISION";
export const DATA_TRANSFER_ADVANCE_SEARCH = "DATA_TRANSFER_ADVANCE_SEARCH";
export const UPDATE_SECONDARY_NAVIGATION = "UPDATE_SECONDARY_NAVIGATION";
export const CAPACITY_USAGE_ADVANCE_SEARCH = "CAPACITY_USAGE_ADVANCE_SEARCH";
export const SET_SELECTED_DATES_CH = "SET_SELECTED_DATES_CH";
export const SET_SELECTED_DATES_CU = "SET_SELECTED_DATES_CU";
export const UPDATE_SOURCE_GROUPS_FOR_ADVANCE_SEARCH =
  "UPDATE_SOURCE_GROUPS_FOR_ADVANCE_SEARCH";
export const UPDATE_SITES_FOR_ADVANCE_SEARCH =
  "UPDATE_SITES_FOR_ADVANCE_SEARCH";
export const UPDATE_DESTINATIONS_FOR_ADVANCE_SEARCH =
  "UPDATE_DESTINATIONS_FOR_ADVANCE_SEARCH";
export const UPDATE_POLICIES_FOR_ADVANCE_SEARCH =
  "UPDATE_POLICIES_FOR_ADVANCE_SEARCH";
export const UPDATE_GENERATED_FROM_ADVANCE_SEARCH =
  "UPDATE_GENERATED_FROM_ADVANCE_SEARCH";
export const UPDATE_ORGANIZATIONS_FOR_ADVANCE_SEARCH =
  "UPDATE_ORGANIZATIONS_FOR_ADVANCE_SEARCH";
export const ADD_ADVANCED_SEARCH_PARAM = "ADD_ADVANCED_SEARCH_PARAM";

// For User Support Data
export const GET_USER_SUPPORT_DATA = "GET_USER_SUPPORT_DATA";
export const FETCH_USER_SUPPORT_DATA = "FETCH_USER_SUPPORT_DATA";
export const UPDATE_REPORT_TYPE_FOR_COMPONENT =
  "UPDATE_REPORT_TYPE_FOR_COMPONENT";
export const ACTION_FOR_RECOVERED_VM_DETAILS =
  "ACTION_FOR_RECOVERED_VM_DETAILS";
export const FROM_NAVIGATION_API = "FROM_NAVIGATION_API";
export const PUT_COLUMN_DATA = "PUT_COLUMN_DATA";
export const FETCH_SINGLE_REPORT_DETAILS = "FETCH_SINGLE_REPORT_DETAILS";
export const UPDATE_SINGLE_REPORT_DETAILS = "UPDATE_SINGLE_REPORT_DETAILS";
export const UPDATE_SINGLE_REPORT_REDIRECTION =
  "UPDATE_SINGLE_REPORT_REDIRECTION";
export const INITIALIZE_EMAIL_SELECTOR = "INITIALIZE_EMAIL_SELECTOR";
export const UPDATE_ASSIGNED_ORGANIZATIONS = "UPDATE_ASSIGNED_ORGANIZATIONS";
export const UPDATE_ASSIGNED_ORGANIZATIONS_WHEN_EDIT =
  "UPDATE_ASSIGNED_ORGANIZATIONS_WHEN_EDIT";
export const REMOVE_ASSIGNED_ORGANIZATION = "REMOVE_ASSIGNED_ORGANIZATION";
export const RESET_REPORT_MODAL = "RESET_REPORT_MODAL";
export const UPDATE_EXPORT_LOG = "UPDATE_EXPORT_LOG";
export const UPDATE_SUBMIT_BTN_STATE = "UPDATE_SUBMIT_BTN_STATE";
export const REMOVE_SINGLE_ADVANCESEARCH_OPTION =
  "REMOVE_SINGLE_ADVANCESEARCH_OPTION";
export const HANDLE_BULK_ACTION = "HANDLE_BULK_ACTION";
export const SHOW_BULK_ACTION = "SHOW_BULK_ACTION";
export const CLOSE_BULK_ACTION = "CLOSE_BULK_ACTION";
export const SOURCE_CANCEL_REPLICATION = "SOURCE_CANCEL_REPLICATION";
export const GET_SELECTED_SOURCE_DETAILS = "GET_SELECTED_SOURCE_DETAILS";
export const SHOW_SELECTED_SOURCE_DETAILS = "SHOW_SELECTED_SOURCE_DETAILS";

export const EXPORT_REPORT_AS_CSV = "EXPORT_REPORT_AS_CSV";

export const UPDATE_SELECTED_FILTER_OPTIONS = "UPDATE_SELECTED_FILTER_OPTIONS";
export const IMPERSONATE_FROM_CSR = "IMPERSONATE_FROM_CSR";

export const GET_LICENSES = "GET_LICENSES";
export const UPDATE_LICENSES = "UPDATE_LICENSES";
export const UPDATE_GRID_PAGE_NUMBER = "UPDATE_GRID_PAGE_NUMBER";
export const UPDATE_GRID_PAGE_SIZE = "UPDATE_GRID_PAGE_SIZE";
export const SHOW_CURRENT_SEARCH = "SHOW_CURRENT_SEARCH";
export const UPDATE_SELECTED_POLICY_TYPE = "UPDATE_SELECTED_POLICY_TYPE";
export const CLEAR_ALL_MENU_COUNTS = "CLEAR_ALL_MENU_COUNTS";
export const UPDATE_MENU_COUNTS = "UPDATE_MENU_COUNTS";

export const FETCH_POLICY_REQUEST = "FETCH_POLICY_REQUEST";
export const FETCH_POLICY_SUCCESS = "FETCH_POLICY_SUCCESS";
export const FETCH_POLICY_FAILURE = "FETCH_POLICY_FAILURE";
export const DESTROY_POLICY = "DESTROY_POLICY";

export const FETCH_HYPERVISOR_REQUEST = "FETCH_HYPERVISOR_REQUEST";
export const FETCH_HYPERVISOR_SUCCESS = "FETCH_HYPERVISOR_SUCCESS";
export const FETCH_HYPERVISOR_FAILURE = "FETCH_HYPERVISOR_FAILURE";

export const UPDATE_HYPERVISOR_REQUEST = "UPDATE_HYPERVISOR_REQUEST";
export const UPDATE_HYPERVISOR_SUCCESS = "UPDATE_HYPERVISOR_SUCCESS";
export const UPDATE_HYPERVISOR_FAILURE = "UPDATE_HYPERVISOR_FAILURE";

export const HYPERVISOR_UPGRADE_AGENT_REQUEST =
  "HYPERVISOR_UPGRADE_AGENT_REQUEST";
export const HYPERVISOR_REFRESH_VMS_REQUEST = "HYPERVISOR_REFRESH_VMS_REQUEST";

export const FETCH_REMOTE_POLICIES_CLEAR = "FETCH_REMOTE_POLICIES_CLEAR";
export const FETCH_REMOTE_POLICIES_REQUEST = "FETCH_REMOTE_POLICIES_REQUEST";
export const FETCH_REMOTE_POLICIES_SUCCESS = "FETCH_REMOTE_POLICIES_SUCCESS";
export const FETCH_REMOTE_POLICIES_FAILURE = "FETCH_REMOTE_POLICIES_FAILURE";

export const UPDATE_POLICY_REQUEST = "UPDATE_POLICY_REQUEST";
export const UPDATE_POLICY_SUCCESS = "UPDATE_POLICY_SUCCESS";
export const UPDATE_POLICY_FAILURE = "UPDATE_POLICY_FAILURE";

export const FETCH_FAILBACK_DESTINATIONS_REQUEST =
  "FETCH_FAILBACK_DESTINATIONS_REQUEST";

export const FETCH_FAILBACK_DESTINATIONS_SUCCESS =
  "FETCH_FAILBACK_DESTINATIONS_SUCCESS";

export const FETCH_FAILBACK_DESTINATIONS_FAILURE =
  "FETCH_FAILBACK_DESTINATIONS_FAILURE";

export const FETCH_ALL_DESTINATIONS_REQUEST = "FETCH_ALL_DESTINATIONS_REQUEST";
export const FETCH_ALL_DESTINATIONS_SUCCESS = "FETCH_ALL_DESTINATIONS_SUCCESS";
export const FETCH_ALL_DESTINATIONS_FAILURE = "FETCH_ALL_DESTINATIONS_FAILURE";

export const REMOVE_FROM_SELECTED_SAVED_SEARCH =
  "REMOVE_FROM_SELECTED_SAVED_SEARCH";
export const UPDATE_TAGS_CURRENT_SEARCH = "UPDATE_TAGS_CURRENT_SEARCH";
export const UPDATE_TAGS_CURRENT_SEARCH_VALUE =
  "UPDATE_TAGS_CURRENT_SEARCH_VALUE";
export const UPDATE_JOBLOG_JOBDATA = "UPDATE_JOBLOG_JOBDATA";
export const CANCEL_RECENT_JOB_BY_ID = "CANCEL_RECENT_JOB_BY_ID";
export const DELETE_RECENT_JOB_BY_ID = "DELETE_RECENT_JOB_BY_ID";
export const UPDATE_SELECTED_FILTER_OPTIONS_WIDGET =
  "UPDATE_SELECTED_FILTER_OPTIONS_WIDGET";
export const FETCH_VPN_DETAILS = "FETCH_VPN_DETAILS";
export const UPDATE_VPN_DETAILS = "UPDATE_VPN_DETAILS";
export const CLEAR_SELECTED_FILTER_OPTIONS_WIDGET =
  "CLEAR_SELECTED_FILTER_OPTIONS_WIDGET";
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";

export const START_GRID_LOADER_BY_ID = "START_GRID_LOADER_BY_ID";
export const STOP_GRID_LOADER_BY_ID = "STOP_GRID_LOADER_BY_ID";

export const SET_RESET_FLAG = "SET_RESET_FLAG";
export const UPDATE_DEFAULT_SAVEDSEARCH = "UPDATE_DEFAULT_SAVEDSEARCH";
export const UPDATE_MANAGE_SAVEDSEARCH_NAME = "UPDATE_MANAGE_SAVEDSEARCH_NAME";

export const DELETE_HYPERVISOR = "DELETE_HYPERVISOR";
export const DELETE_STORAGE_ARRAY = "DELETE_STORAGE_ARRAY";

export const FETCH_ENTITLEMENTS_PRODUCTS_REQUEST =
  "FETCH_ENTITLEMENTS_PRODUCTS_REQUEST";
export const FETCH_ENTITLEMENTS_PRODUCTS_SUCCESS =
  "FETCH_ENTITLEMENTS_PRODUCTS_SUCCESS";
export const FETCH_ENTITLEMENTS_PRODUCTS_FAILURE =
  "FETCH_ENTITLEMENTS_PRODUCTS_FAILURE";

export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";

export const UPDATE_RECOVERED_VMS_STATE = "UPDATE_RECOVERED_VMS_STATE";
export const POST_REMOTE_POLICIES = "POST_REMOTE_POLICIES";
export const FETCH_REMOTE_POLICIES = "FETCH_REMOTE_POLICIES";
export const FETCH_REMOTE_POLICIES_SUCCESSFUL =
  "FETCH_REMOTE_POLICIES_SUCCESSFUL";
export const UPDATE_REMOTE_POLICIES = "UPDATE_REMOTE_POLICIES";
export const UPDATE_REMOTE_POLICIES_ERROR = "UPDATE_REMOTE_POLICIES_ERROR";
export const CLEAR_REMOTE_POLICIES = "CLEAR_REMOTE_POLICIES";
export const CLEAR_REMOTE_POLICIES_ERROR = "CLEAR_REMOTE_POLICIES_ERROR";

export const CLOUD_HYBRID_TRIAL_REQUEST = "CLOUD_HYBRID_TRIAL_REQUEST";
export const UDP_TRIAL_REQUEST = "UDP_TRIAL_REQUEST";
export const CANCEL_REPLICATION_OUT = "CANCEL_REPLICATION_OUT";
export const START_REPLICATION_OUT = "START_REPLICATION_OUT";
export const START_REPLICATION = "START_REPLICATION";
export const HEARTBEAT_PAUSE = "HEARTBEAT_PAUSE";
export const HEARTBEAT_RESUME = "HEARTBEAT_RESUME";
export const STANDBY_VM_SNAPSHOT = "STANDBY_VM_SNAPSHOT";
export const VIRTUAL_STANDBY_RESUME = "VIRTUAL_STANDBY_RESUME";
export const VIRTUAL_STANDBY_PAUSE = "VIRTUAL_STANDBY_PAUSE";
export const STANDBY_VM_NETWORK_CONFIGURATION =
  "STANDBY_VM_NETWORK_CONFIGURATION";
export const BACKUP_VMS = "BACKUP_VMS";
export const DEPLOY = "DEPLOY";
export const DEPLOY_POLICY = "DEPLOY_POLICY";
export const UPDATE_SOURCE = "UPDATE_SOURCE";
export const UPDATE_SOURCE_DETAILS_IN_STORE = "UPDATE_SOURCE_DETAILS_IN_STORE";
export const REFRESH_POLICY = "REFRESH_POLICY";
export const BACKUP_POLICY = "BACKUP_POLICY";
export const REPLICATE_POLICY = "REPLICATE_POLICY";
export const SHARE_POLICY = "SHARE_POLICY";
export const UN_SHARE_POLICY = "UN_SHARE_POLICY";
export const CONNECT_O365 = "CONNECT_O365";
export const ADDO365 = "ADDO365";
export const SAVE_TO_LIST_WINDOWS_SOURCE = "SAVE_TO_LIST_WINDOWS_SOURCE";
export const UPDATE_AD = "UPDATE_AD";
export const DELETE_SOURCE_FROM_LIST_WINDOWS_SOURCE =
  "DELETE_SOURCE_FROM_LIST_WINDOWS_SOURCE";
export const DELETE_SOURCE_FROM_LIST_LINUX_SOURCE =
  "DELETE_SOURCE_FROM_LIST_LINUX_SOURCE";
export const CLEAR_WINDOWS_SOURCE_LIST = "CLEAR_WINDOWS_SOURCE_LIST";
export const CLEAR_LINUX_SOURCE_LIST = "CLEAR_LINUX_SOURCE_LIST";
export const SAVE_TO_LIST_LINUX_SOURCE = "SAVE_TO_LIST_LINUX_SOURCE";
export const GET_SITE_NAME = "GET_SITE_NAME";
export const UPDATE_SITE_NAME = "UPDATE_SITE_NAME";
export const DELETE_SITE = "DELETE_SITE";
export const ADD_SOURCES_WINDOWS_SOURCE = "ADD_SOURCES_WINDOWS_SOURCE";
export const ADD_SOURCES = "ADD_SOURCES";
export const ADD_ORACLE_SOURCES = "ADD_ORACLE_SOURCES";
export const ADD_ORACLE_DB = "ADD_ORACLE_DB";
export const ADD_AD_SAVE_SOURCES = "ADD_AD_SAVE_SOURCES";
export const ADD_LINUX_SOURCES = "ADD_LINUX_SOURCES";
export const ADD_SOURCES_LINUX_SOURCE = "ADD_SOURCES_LINUX_SOURCE";

/**
 * Add Sources > Linux backup server action types
 */
export const SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE =
  "SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE";
export const REMOVE_FROM_LIST_LINUX_BACKUP_SERVER_SOURCE =
  "REMOVE_FROM_LIST_LINUX_BACKUP_SERVER_SOURCE";
export const SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE_ERROR =
  "SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE_ERROR";
export const SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE_SUCCESS =
  "SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE_SUCCESS";
export const ADD_SOURCES_LINUX_BACKUP_SERVER_SOURCE =
  "ADD_SOURCES_LINUX_BACKUP_SEREVER_SOURCE";

export const SAVE_TO_LIST_WINDOWS_SOURCE_ERROR =
  "SAVE_TO_LIST_WINDOWS_SOURCE_ERROR";
export const CLEAR_WINDOWS_SOURCE_ERROR = "CLEAR_WINDOWS_SOURCE_ERROR";
export const CLEAR_LINUX_SOURCE_ERROR = "CLEAR_LINUX_SOURCE_ERROR";
export const SAVE_TO_LIST_WINDOWS_SOURCE_SUCCESS =
  "SAVE_TO_LIST_WINDOWS_SOURCE_SUCCESS";
export const SAVE_TO_LIST_LINUX_SOURCE_ERROR =
  "SAVE_TO_LIST_LINUX_SOURCE_ERROR";
export const SAVE_TO_LIST_LINUX_SOURCE_SUCCESS =
  "SAVE_TO_LIST_LINUX_SOURCE_SUCCESS";
export const SET_SELECTED_SITE = "SET_SELECTED_SITE";
export const ERROR_MESSAGE_CONNECT = "ERROR_MESSAGE_CONNECT";

export const SAVE_TO_LIST_ORACLE_WINDOWS_SOURCE =
  "SAVE_TO_LIST_ORACLE_WINDOWS_SOURCE";
export const SAVE_TO_LIST_ORACLE_LINUX_SOURCE =
  "SAVE_TO_LIST_ORACLE_LINUX_SOURCE";
export const SAVE_TO_LIST_ORACLE_SOURCE_ERROR =
  "SAVE_TO_LIST_ORACLE_SOURCE_ERROR";
export const SAVE_TO_LIST_ORACLE_SOURCE_SUCCESS =
  "SAVE_TO_LIST_ORACLE_SOURCE_SUCCESS";
export const CLEAR_ORACLE_SOURCE_LIST = "CLEAR_ORACLE_SOURCE_LIST";
export const DELETE_SOURCE_FROM_LIST_ORACLE_SOURCE =
  "DELETE_SOURCE_FROM_LIST_ORACLE_SOURCE";
export const CLEAR_ORACLE_SOURCE_ERROR = "CLEAR_ORACLE_SOURCE_ERROR";

// Oracle Windows DB Settings
export const GET_ORACLE_WINDOWS_DB_OPTIONS = "GET_ORACLE_WINDOWS_DB_OPTIONS";
export const GET_ORACLE_WINDOWS_DB_OPTIONS_SUCCESS =
  "GET_ORACLE_WINDOWS_DB_OPTIONS_SUCCESS";
export const GET_ORACLE_WINDOWS_DB_OPTIONS_FAILURE =
  "GET_ORACLE_WINDOWS_DB_OPTIONS_FAILURE";
// DB save to list
export const SAVE_TO_LIST_ORACLE_DB_WINDOWS_SOURCE =
  "SAVE_TO_LIST_ORACLE_DB_WINDOWS_SOURCE";
export const SAVE_TO_LIST_ORACLE_DB_WINDOWS_SOURCE_ERROR =
  "SAVE_TO_LIST_ORACLE_DB_WINDOWS_SOURCE_ERROR";
export const SAVE_TO_LIST_ORACLE_DB_WINDOWS_SOURCE_SUCCESS =
  "SAVE_TO_LIST_ORACLE_DB_WINDOWS_SOURCE_SUCCESS";
export const DELETE_SOURCE_FROM_LIST_ORACLE_WINDOWS_DB_SOURCE =
  "DELETE_SOURCE_FROM_LIST_ORACLE_WINDOWS_DB_SOURCE";
export const CLEAR_ORACLE_WINDOWS_DB_SOURCE_ERROR =
  "CLEAR_ORACLE_WINDOWS_DB_SOURCE_ERROR";
export const CLEAR_ORACLE_WINDOWS_DB_SOURCE_LIST =
  "CLEAR_ORACLE_WINDOWS_DB_SOURCE_LIST";
export const ADD_ORACLE_WINDOW_SOUCE_PAGE = "ADD_ORACLE_WINDOW_SOUCE_PAGE";

// Add Sources > Discover Sources from AD
export const ADD_DISCOVER_SOURCES = "ADD_DISCOVER_SOURCES";
export const GET_ACTIVE_DIRECTORIES = "GET_ACTIVE_DIRECTORIES";
export const AD_SOURCES_SUCCESSFULLY_AUTHENTICATED =
  "AD_SOURCES_SUCCESSFULLY_AUTHENTICATED";
export const AD_SOURCES_AUTH_RESPONSE = "AD_SOURCES_AUTH_RESPONSE";
export const AD_SOURCES_FAILED_AUTHENTICATED =
  "AD_SOURCES_FAILED_AUTHENTICATED";
export const DELETE_AUTHENTICATED_SOURCE = "DELETE_AUTHENTICATED_SOURCE";
export const AUTHENTICATE_AD_WINDOWS_SOURCES =
  "AUTHENTICATE_AD_WINDOWS_SOURCES";
export const CLEAR_AD_DATA = "CLEAR_AD_DATA";
export const CLEAR_SUCCESSFULL_AUTHENTICATED_AD_SOURCES =
  "CLEAR_SUCCESSFULL_AUTHENTICATED_AD_SOURCES";
export const CLEAR_AD_SOURCES_ERROR = "CLEAR_AD_SOURCES_ERROR";
export const CLEAR_AD_SOURCES_LIST = "CLEAR_AD_SOURCES_LIST";
export const CLEAR_AD_ERROR = "CLEAR_AD_ERROR";
export const SHOW_AUTH_MODAL = "SHOW_AUTH_MODAL";
export const CLOSE_AUTH_MODAL = "CLOSE_AUTH_MODAL";
export const CLEAR_AD_AUTH_RESPONSE = "CLEAR_AD_AUTH_RESPONSE";
export const BROWSE_ACTIVE_DIRECTORIES = "BROWSE_ACTIVE_DIRECTORIES";
export const DELETE_ACTIVE_DIRECTORY = "DELETE_ACTIVE_DIRECTORY";
export const AD_SOURCES_FAILED_AUTHENTIATION =
  "AD_SOURCES_FAILED_AUTHENTIATION";
export const CLEAR_AD_SOURCES_FAILED_AUTHENTIATION =
  "CLEAR_AD_SOURCES_FAILED_AUTHENTIATION";
export const ADD_DISCOVER_ADD_AD = "ADD_DISCOVER_ADD_AD";
export const ADD_DISCOVER_LIST_AD = "ADD_DISCOVER_LIST_AD";
export const ADD_DISCOVER_BROWSE_AD_SOURCES = "ADD_DISCOVER_BROWSE_AD_SOURCES";
export const ADD_DISCOVER_SOURCES_AGAIN = "ADD_DISCOVER_SOURCES_AGAIN";
export const FETCH_AD_SOURCES = "FETCH_AD_SOURCES";
export const ADD_DISCOVER_SOURCES_CANCEL = "ADD_DISCOVER_SOURCES_CANCEL";
export const ADD_DISCOVER_SOURCES_SUCCESS = "ADD_DISCOVER_SOURCES_SUCCESS";
export const ADD_SOURCES_FROM_ACTIVE_DIRECTORIES =
  "ADD_SOURCES_FROM_ACTIVE_DIRECTORIES";
export const FETCH_SOURCES_FROM_ACTIVE_DIRECTORIES_ERROR =
  "FETCH_SOURCES_FROM_ACTIVE_DIRECTORIES_ERROR";
export const ADD_DISCOVER_SOURCES_FAILURE = "ADD_DISCOVER_SOURCES_FAILURE";
export const GET_DISCOVER_SOURCE_NAME = "GET_DISCOVER_SOURCE_NAME";
export const GET_DISCOVER_SOURCE_NAME_SUCCESS =
  "GET_DISCOVER_SOURCE_NAME_SUCCESS";
export const GET_DISCOVER_SOURCE_NAME_FAILURE =
  "GET_DISCOVER_SOURCE_NAME_FAILURE";
export const DISCOVER_SOURCE_NAME = "DISCOVER_SOURCE_NAME";
export const DISCOVER_SOURCE_NAME_SUCCESS = "DISCOVER_SOURCE_NAME_SUCCESS";
export const DISCOVER_SOURCE_NAME_FAILURE = "DISCOVER_SOURCE_NAME_FAILURE";
export const DISCOVER_SOURCES_AUTHENTICATION_CREDENTIAL =
  "DISCOVER_SOURCES_AUTHENTICATION_CREDENTIAL";
export const DISCOVER_SOURCES_AUTHENTICATION_CREDENTIAL_SUCCESS =
  "DISCOVER_SOURCES_AUTHENTICATION_CREDENTIAL_SUCCESS";
export const DISCOVER_SOURCES_AUTHENTICATION_CREDENTIAL_FAILURE =
  "DISCOVER_SOURCES_AUTHENTICATION_CREDENTIAL_FAILURE";
export const DISCOVER_SOURCES_ADD_SOURCES = "DISCOVER_SOURCES_ADD_SOURCES";
export const DISCOVER_SOURCES_ADD_SOURCES_SUCCESS =
  "DISCOVER_SOURCES_ADD_SOURCES_SUCCESS";
export const DISCOVER_SOURCES_ADD_SOURCES_FAILURE =
  "DISCOVER_SOURCES_ADD_SOURCES_FAILURE";
export const DISCOVER_SOURCES_CANCEL_ADDITION =
  "DISCOVER_SOURCES_CANCEL_ADDITION";
export const SELECT_AD_FOR_BROWSE = "SELECT_AD_FOR_BROWSE";
export const AD_INCREMENT_AUTH_COUNT = "AD_INCREMENT_AUTH_COUNT";
export const AD_INITIALIZE_AUTH_COUNT = "AD_INITIALIZE_AUTH_COUNT";
export const AD_WIN_SOURCE_AUTH_SUCCESS = "AD_WIN_SOURCE_AUTH_SUCCESS";
export const AD_WIN_SOURCE_AUTH_FAILURE = "AD_WIN_SOURCE_AUTH_FAILURE";

// Dialog Box showing / hiding (generic)
export const SHOW_DIALOG = "SHOW_DIALOG";
export const HIDE_DIALOG = "HIDE_DIALOG";
export const ADD_SOURCES_HYPER_VMWARE_CONNECT =
  "ADD_SOURCES_HYPER_VMWARE_CONNECT";
export const ADD_SOURCES_HYPER_SAVE_TO_LIST = "ADD_SOURCES_HYPER_SAVE_TO_LIST";
export const ADD_SOURCES_HYPER_SAVE_TO_LIST_VMWARE =
  "ADD_SOURCES_HYPER_SAVE_TO_LIST_VMWARE";
export const ADD_SOURCES_HYPER_VALIDATION_SOURCE_POLICY_ESX =
  "ADD_SOURCES_HYPER_VALIDATION_SOURCE_POLICY_ESX";
export const ADD_SOURCES_HYPER_VALIDATION_SOURCE_POLICY_HV =
  "ADD_SOURCES_HYPER_VALIDATION_SOURCE_POLICY_HV";
export const ADD_SOURCES_HYPER_SAVE_TO_LIST_HN =
  "ADD_SOURCES_HYPER_SAVE_TO_LIST_HN";
export const ADD_SOURCES_HYPERVISOR_SAVE_TO_LIST =
  "ADD_SOURCES_HYPERVISOR_SAVE_TO_LIST";
export const ADD_SOURCES_HYPER_VMWARE_CONNECT_SUCCESS =
  "ADD_SOURCES_HYPER_VMWARE_CONNECT_SUCCESS";
export const ADD_SOURCES_HYPERVISOR_CONNECT_SUCCESS =
  "ADD_SOURCES_HYPERVISOR_CONNECT_SUCCESS";
export const ADD_NEW_SITE = "ADD_NEW_SITE";
export const UPDATE_SITES_DATA = "UPDATE_SITES_DATA";
export const SWITCH_ADD_SITE_TAB = "SWITCH_ADD_SITE_TAB";
export const CREATE_NEW_SITE = "CREATE_NEW_SITE";
export const RESTORE_NEW_SITE_VALUE = "RESTORE_NEW_SITE_VALUE";
export const ADD_SOURCES_HYPER_CONNECT_ERROR =
  "ADD_SOURCES_HYPER_CONNECT_ERROR";
export const RESET_ADD_SOURCES_HYPER = "RESET_ADD_SOURCES_HYPER";
export const ADD_SOURCES_HYPER_DELETE_SELECTION =
  "ADD_SOURCES_HYPER_DELETE_SELECTION";
export const ADD_SOURCES_HYPER_SELECT_SITE = "ADD_SOURCES_HYPER_SELECT_SITE";
export const ADD_SOURCES_HYPER_ADD_SOURCE = "ADD_SOURCES_HYPER_ADD_SOURCE";
export const ADD_SOURCES_HYPER_ADD_SOURCE_POLICY_ESX =
  "ADD_SOURCES_HYPER_ADD_SOURCE_POLICY_ESX";
export const ADD_SOURCES_HYPER_ADD_SOURCE_POLICY_HV =
  "ADD_SOURCES_HYPER_ADD_SOURCE_POLICY_HV";
export const ADD_HYPERVISOR_SOURCES = "ADD_HYPERVISOR_SOURCES";
export const ADD_SOURCES_HYPER_HYPERV_CONNECT_SUCCESS =
  "ADD_SOURCES_HYPER_HYPERV_CONNECT_SUCCESS";
export const ADD_SOURCES_HYPER_HYPERV_CONNECT =
  "ADD_SOURCES_HYPER_HYPERV_CONNECT";
export const ADD_SOURCES_HYPER_NUTANIX_CONNECT_SUCCESS =
  "ADD_SOURCES_HYPER_NUTANIX_CONNECT_SUCCESS";
export const ADD_SOURCES_HYPER_NUTANIX_CONNECT =
  "ADD_SOURCES_HYPER_NUTANIX_CONNECT";
export const VMWARE_CONNECTION_DETAILS_CHANGED =
  "VMWARE_CONNECTION_DETAILS_CHANGED";
export const VMWARE_SERVER_TYPE_SUCCESS = "VMWARE_SERVER_TYPE_SUCCESS";
export const GET_VMWARE_SERVER_TYPE = "GET_VMWARE_SERVER_TYPE";
export const ADD_SOURCES_SET_HYPERVISOR_ID = "ADD_SOURCES_SET_HYPERVISOR_ID";
export const CLEAR_HYPER_FORM = "CLEAR_HYPER_FORM";

/**
 * Downloads related action types
 */
export const FETCH_DOWNLOADS = "FETCH_DOWNLOADS";
export const FETCH_DOWNLOADS_SUCCESS = "FETCH_DOWNLOADS_SUCCESS";
export const FETCH_DOWNLOADS_ERROR = "FETCH_DOWNLOADS_ERROR";

/**
 * Set & Reset Loading
 */
export const SET_LOADING = "SET_LOADING";
export const RESET_LOADING = "RESET_LOADING";

/**
 * Protect > Destination > Recover Point Servers
 */
export const LOAD_RPS_LIST = "LOAD_RPS_LIST";
export const LOAD_ALL_RPS_LIST = "LOAD_ALL_RPS_LIST";
export const STOP_LOAD_RPS_LIST = "STOP_LOAD_RPS_LIST";
export const LOAD_RPS_LIST_SUCCESS = "LOAD_RPS_LIST_SUCCESS";
export const SET_CURRENT_RPS = "SET_CURRENT_RPS";
export const LOAD_CURRENT_RPS = "LOAD_CURRENT_RPS";
export const SET_CURRENT_RPS_ID = "SET_CURRENT_RPS_ID";
export const CLEAR_RPS_LIST = "CLEAR_RPS_LIST";
export const DELETE_RPS = "DELETE_RPS";
export const REFRESH_RPS = "REFRESH_RPS";
export const UPDATE_RPS = "UPDATE_RPS";
export const UPDATE_RPS_SUCCESS = "UPDATE_RPS_SUCCESS";
export const REFRESH_RPS_SUCCESS = "REFRESH_RPS_SUCCESS";
export const REFRESH_DATA_STORE_SUCCESS = "REFRESH_DATA_STORE_SUCCESS";
export const ADD_NEW_RPS = "ADD_NEW_RPS";
export const RUN_ASSURED_RECOVERY_TEST = "RUN_ASSURED_RECOVERY_TEST";
export const SOURCE_RUN_ASSURED_RECOVERY_TEST =
  "SOURCE_RUN_ASSURED_RECOVERY_TEST";
export const CLEAR_DATA_STORES = "CLEAR_DATA_STORES";
export const LOAD_DATA_STORES = "LOAD_DATA_STORES";
export const LOAD_ALL_DATA_STORES = "LOAD_ALL_DATA_STORES";
export const LOAD_DATA_STORES_SUCCESS = "LOAD_DATA_STORES_SUCCESS";
export const IMPORT_DATA_STORE_TEMP = "IMPORT_DATA_STORE_TEMP";
export const IMPORT_DATA_STORE_TEMP_CLEAR = "IMPORT_DATA_STORE_TEMP_CLEAR";
export const IMPORT_DATA_STORE_STEP_ONE = "IMPORT_DATA_STORE_STEP_ONE";
export const IMPORT_DATA_STORE_STEP_TWO = "IMPORT_DATA_STORE_STEP_TWO";
export const LOAD_DATA_STORES_SEARCH = "LOAD_DATA_STORES_SEARCH";
export const IMPORT_CLOUD_DATA_STORE_STEP_ONE =
  "IMPORT_CLOUD_DATA_STORE_STEP_ONE";
export const IMPORT_CLOUD_DATA_STORE_STEP_TWO =
  "IMPORT_CLOUD_DATA_STORE_STEP_TWO";
export const IMPORT_CLOUD_DATA_STORE_TEMP = "IMPORT_CLOUD_DATA_STORE_TEMP";
export const IMPORT_CLOUD_DATA_STORE_TEMP_CLEAR =
  "IMPORT_CLOUD_DATA_STORE_TEMP_CLEAR";

export const SET_CURRENT_DATA_STORE = "SET_CURRENT_DATA_STORE";
export const SET_DATA_STORE_ENCRYPTION_ENABLED =
  "SET_DATA_STORE_ENCRYPTION_ENABLED";
export const SET_RESTORE_VOLUME = "SET_RESTORE_VOLUME";
export const LOAD_CURRENT_DATA_STORE = "LOAD_CURRENT_DATA_STORE";
export const LOAD_CURRENT_DATA_STORE_SOURCE_RECOVERY =
  "LOAD_CURRENT_DATA_STORE_SOURCE_RECOVERY";
export const SET_CURRENT_DATA_STORE_ID = "SET_CURRENT_DATA_STORE_ID";
export const REFRESH_DATA_STORE_DATA = "REFRESH_DATA_STORE_DATA";
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export const INSTALL_DEPLOY_RPS = "INSTALL_DEPLOY_RPS";
export const INSTALL_DEPLOY_RPS_BULK = "INSTALL_DEPLOY_RPS_BULK";
export const INSTALL_UPDATE_UPGRADE_AGENT = "INSTALL_UPDATE_UPGRADE_AGENT";

export const ADD_DATA_STORE = "ADD_DATA_STORE";
export const ADD_DATA_STORE_SUCCESS = "ADD_DATA_STORE_SUCCESS";
export const CONNECT_AUTH_SUCCESS = "CONNECT_AUTH_SUCCESS";
export const CONNECT_AUTH_FAILURE = "CONNECT_AUTH_FAILURE";
export const CLEAR_CONNECT_AUTH = "CLEAR_CONNECT_AUTH";

/**
 * Start
 * Configure > Infrastructure > Sites
 */
export const LOAD_SITES = "LOAD_SITES";
export const UPGRADE_SITE = "UPGRADE_SITE";
export const LOAD_REGISTERED_SITES = "LOAD_REGISTERED_SITES";
export const LOAD_SITES_SUCCESS = "LOAD_SITES_SUCCESS";
export const LOAD_REGISTERED_SITES_SUCCESS = "LOAD_REGISTERED_SITES_SUCCESS";
export const UPDATE_ADD_CLOUD_ACCOUNT_FLAG = "UPDATE_ADD_CLOUD_ACCOUNT_FLAG";
/**
 * End
 */

/**
 * Protect > Destination > Remote Console
 */
export const LOAD_REMOTE_CONSOLE_LIST = "LOAD_REMOTE_CONSOLE_LIST";
export const LOAD_REMOTE_CONSOLE_LIST_SUCCESS =
  "LOAD_REMOTE_CONSOLE_LIST_SUCCESS";
export const DELETE_REMOTE_CONSOLE = "DELETE_REMOTE_CONSOLE";
export const DELETE_REMOTE_CONSOLE_SUCCESS = "DELETE_REMOTE_CONSOLE_SUCCESS";
export const ADD_DATA_STORE_TO_RPS = "ADD_DATA_STORE_TO_RPS";
export const ADD_CLOUD_DATA_STORE_TO_RPS = "ADD_CLOUD_DATA_STORE_TO_RPS";
export const AUTHENTICATE_SHARED_FOLDER = "AUTHENTICATE_SHARED_FOLDER";
export const UPDATE_DATA_STORE_RPS = "UPDATE_DATA_STORE_RPS";
export const UPDATE_CLOUD_DATA_STORE = "UPDATE_CLOUD_DATA_STORE";
export const STORE_SHARED_PATH_CREDENTIALS = "STORE_SHARED_PATH_CREDENTIALS";

/**
 * Protect > Destination > Cloud Account
 */
export const LOAD_SHADOW_XAFE_LIST = "LOAD_SHADOW_XAFE_LIST";
export const LOAD_SHADOW_XAFE_LIST_SUCCESS = "LOAD_SHADOW_XAFE_LIST_SUCCESS";
export const LOAD_CLOUD_ACCOUNT_LIST = "LOAD_CLOUD_ACCOUNT_LIST";
export const LOAD_CLOUD_ACCOUNT_LIST_BY_RPS_ID =
  "LOAD_CLOUD_ACCOUNT_LIST_BY_RPS_ID";
export const LOAD_END_POINT_LIST = "LOAD_END_POINT_LIST";

export const LOAD_CLOUD_ACCOUNT_LIST_SUCCESS =
  "LOAD_CLOUD_ACCOUNT_LIST_SUCCESS";
export const LOAD_CLOUD_ACCOUNT_LIST_BY_RPS_SUCCESS =
  "LOAD_CLOUD_ACCOUNT_LIST_BY_RPS_SUCCESS";
export const LOAD_END_POINT_LIST_SUCCESS = "LOAD_END_POINT_LIST_SUCCESS";

export const DELETE_CLOUD_ACCOUNT = "DELETE_CLOUD_ACCOUNT";
export const DELETE_CLOUD_ACCOUNT_SUCCESS = "DELETE_CLOUD_ACCOUNT_SUCCESS";
export const TEST_CLOUD_ACCOUNT_CONNECTION = "TEST_CLOUD_ACCOUNT_CONNECTION";
export const TEST_CLOUD_ACCOUNT_CONNECTION_SUCCESS =
  "TEST_CLOUD_ACCOUNT_CONNECTION_SUCCESS";

/**
 * Protect > Destination > Shared Folder
 */
export const UPDATE_SHARED_FOLDER = "UPDATE_SHARED_FOLDER";
export const DELETE_SHARED_FOLDER = "DELETE_SHARED_FOLDER";
export const LOAD_SHARED_FOLDER_LIST = "LOAD_SHARED_FOLDER_LIST";
export const LOAD_SHARED_FOLDER_LIST_SUCCESS =
  "LOAD_SHARED_FOLDER_LIST_SUCCESS";
export const DELETE_SHARED_FOLDER_SUCCESS = "DELETE_SHARED_FOLDER_SUCCESS";

export const RPS_DATA_STORE_LIST = "RPS_DATA_STORE_LIST";
export const RPS_DATA_STORE_LIST_SUCCESS = "RPS_DATA_STORE_LIST_SUCCESS";
export const RPS_DATA_STORE_SOURCE_LIST = "RPS_DATA_STORE_SOURCE_LIST";
export const RPS_DATA_STORE_SOURCE_LIST_SUCCESS =
  "RPS_DATA_STORE_SOURCE_LIST_SUCCESS";
export const RPS_DATA_STORE_SOURCE_LIST_FAILURE =
  "RPS_DATA_STORE_SOURCE_LIST_FAILURE";

export const GET_POLICY_LIST = "GET_POLICY_LIST";
export const CLEAR_POLICY_LIST = "CLEAR_POLICY_LIST";
export const GET_POLICY_LIST_SUCCESS = "GET_POLICY_LIST_SUCCESS";
export const ON_SEARCH_FILTER = "ON_SEARCH_FILTER";
export const ON_POLICY_CHANFE_JUMPSTART = "ON_POLICY_CHANFE_JUMPSTART";
export const GET_SHARED_POLICY_DETAILS = "GET_SHARED_POLICY_DETAILS";
export const GET_SHARED_POLICY_DETAILS_SUCCESS =
  "GET_SHARED_POLICY_DETAILS_SUCCESS";
export const CLEAR_SHARED_POLICY_DETAILS = "CLEAR_SHARED_POLICY_DETAILS";
export const ON_CLEAR_SOURCE_LIST = "ON_CLEAR_SOURCE_LIST";
export const ON_CLEAR_FORM = "ON_CLEAR_FORM";
export const TARGETED_DATA_STORE_LIST = "TARGETED_DATA_STORE_LIST";
export const SET_DATA_STORE_LIST = "SET_DATA_STORE_LIST";
export const TARGETED_RPS_LIST = "TARGETED_RPS_LIST";
export const CONNECT_SHARED_FOLDER = "CONNECT_SHARED_FOLDER";
export const CONNECT_SHARED_FOLDER_SUCCESS = "CONNECT_SHARED_FOLDER_SUCCESS";
export const CONNECT_SHARED_FOLDER_FAILURE = "CONNECT_SHARED_FOLDER_FAILURE";
export const SAVE_SHARED_FOLDER_DETAILS = "SAVE_SHARED_FOLDER_DETAILS";
export const SAVE_JUMP_START = "SAVE_JUMP_START";
export const SAVE_JUMP_START_FAILURE = "SAVE_JUMP_START_FAILURE";
export const CREATE_CLOUD_ACCOUNT = "CREATE_CLOUD_ACCOUNT";
export const UPDATE_CLOUD_ACCOUNT = "UPDATE_CLOUD_ACCOUNT";
export const ON_SESSION_PASSWORD_APPLY = "ON_SESSION_PASSWORD_APPLY";
export const ON_SESSION_PASSWORD_APPLY_SUCCESS =
  "ON_SESSION_PASSWORD_APPLY_SUCCESS";
export const ON_SESSION_PASSWORD_APPLY_FAILURE =
  "ON_SESSION_PASSWORD_APPLY_SUCCESS";
export const FETCH_BACKUP_PROXY = "FETCH_BACKUP_PROXY";
export const FETCH_LBS = "FETCH_LBS";
export const FETCH_LINUX_LBS = "FETCH_LINUX_LBS";
export const FETCH_LINUX = "FETCH_LINUX";
export const GET_SELECTED_PROXY = "GET_SELECTED_PROXY";
export const GET_SELECTED_LBS = "GET_SELECTED_LBS";
export const UPDATE_SELECTED_LBS = "UPDATE_SELECTED_LBS";
export const FETCH_SELECTED_NETWORK_BACKUP_PROXY =
  "FETCH_SELECTED_NETWORK_BACKUP_PROXY";
export const FETCH_SELECTED_LBS_NETWORKS = "FETCH_SELECTED_LBS_NETWORKS";
export const CLEAR_SELECTED_NETWORK_BACKUP_PROXY =
  "CLEAR_SELECTED_NETWORK_BACKUP_PROXY";
export const FETCH_SELECTED_NETWORK_BACKUP_PROXY_SUCCESS =
  "FETCH_SELECTED_NETWORK_BACKUP_PROXY_SUCCESS";
export const LOAD_SELECTED_LBS_NETWORK_SUCCESS =
  "LOAD_SELECTED_LBS_NETWORK_SUCCESS";
export const LOAD_BACKUP_PROXY_SUCCESS = "LOAD_BACKUP_PROXY_SUCCESS";
export const LOAD_LBS_SUCCESS = "LOAD_LBS_SUCCESS";
export const LOAD_LINUX_LBS_SUCCESS = "LOAD_LINUX_LBS_SUCCESS";
export const LOAD_LINUX_SUCCESS = "LOAD_LINUX_SUCCESS";
export const LOAD_VSB_MONITOR_SUCCESS = "LOAD_VSB_MONITOR_SUCCESS";
export const LOAD_VSB_MONITOR = "LOAD_VSB_MONITOR";
export const ADD_BACKUP_PROXY_FLAG = "ADD_BACKUP_PROXY_FLAG";
export const ADD_LINUX_BACKUP_FLAG = "ADD_LINUX_BACKUP_FLAG";
export const CREATE_BACKUP_PROXY = "CREATE_BACKUP_PROXY";
export const CREATE_LINUX_BACKUP_PROXY = "CREATE_LINUX_BACKUP_PROXY";
export const CREATE_LINUX_BACKUP_SERVER = "CREATE_LINUX_BACKUP_SERVER";
export const GET_AVAILABLE_DRIVE_LETTERS = "GET_AVAILABLE_DRIVE_LETTERS";
export const MOUNT_RECOVERY_POINT = "MOUNT_RECOVERY_POINT";

/**
 * This action will be triggered whenever the application starts
 * and if you want to reset any persisted value from redux
 * you should listen to this action to reset the value
 * Please do not trigger this action manually at any other point
 * of time
 */
export const INITIALIZE_APP = "INITIALIZE_APP";
export const GET_BROWSE_RECOVERY_POINT_DS = "GET_BROWSE_RECOVERY_POINT_DS";
export const GET_BROWSE_RECOVERY_POINT_DS_SUCCESS =
  "GET_BROWSE_RECOVERY_POINT_DS_SUCCESS";
export const CLEAR_BROWSE_SOURCES_DS = "CLEAR_BROWSE_SOURCES_DS";
export const UPDATE_AVAILABLE_DRIVE_LETTER = "UPDATE_AVAILABLE_DRIVE_LETTER";
export const CLEAR_AVAILABLE_DRIVE_LETTER = "CLEAR_AVAILABLE_DRIVE_LETTER";
export const GET_SOURCES_WITH_WINDOWS_FILTER =
  "GET_SOURCES_WITH_WINDOWS_FILTER";
export const CLEAR_AGENT_MACHINE_SOURCES_LIST =
  "CLEAR_AGENT_MACHINE_SOURCES_LIST";
export const UPDATE_AGENT_MACHINE_SOURCES_LIST =
  "UPDATE_AGENT_MACHINE_SOURCES_LIST";

export const RPS_DATA_STORE_SOURCE_LIST_ITEM_DELETE =
  "RPS_DATA_STORE_SOURCE_LIST_ITEM_DELETE";
export const RPS_DATA_STORE_SOURCE_LIST_ITEM_REFRESH =
  "RPS_DATA_STORE_SOURCE_LIST_ITEM_REFRESH";
export const RPS_DATA_STORE_SOURCE_LIST_ITEM_MERGE =
  "RPS_DATA_STORE_SOURCE_LIST_ITEM_MERGE";
export const RPS_DATA_STORE_SOURCE_LIST_GET_RETENTION =
  "RPS_DATA_STORE_SOURCE_LIST_GET_RETENTION";
export const RPS_DATA_STORE_SOURCE_LIST_SET_RETENTION =
  "RPS_DATA_STORE_SOURCE_LIST_SET_RETENTION";
export const RPS_DATA_STORE_SOURCE_LIST_RP_MERGE_NOW =
  "RPS_DATA_STORE_SOURCE_LIST_RP_MERGE_NOW";
export const RPS_DATA_STORE_REFRESH_RECOVERY_POINT =
  "RPS_DATA_STORE_REFRESH_RECOVERY_POINT";
export const CLEAR_BROWSE_RECOVERY_POINTS = "CLEAR_BROWSE_RECOVERY_POINTS";

export const LOAD_RECOVERY_POINT_SERVERS = "LOAD_RECOVERY_POINT_SERVERS";
export const LOAD_BACKUP_TRAFFIC_USING_RPS = "LOAD_BACKUP_TRAFFIC_USING_RPS";
export const CLEAR_BACKUP_TRAFFIC_USING_RPS = "CLEAR_BACKUP_TRAFFIC_USING_RPS";
export const LOAD_RECOVERY_POINT_SERVERS_SUCCESS =
  "LOAD_RECOVERY_POINT_SERVERS_SUCCESS";
export const LOAD_BACKUP_TRAFFIC_USING_RPS_SUCCESS =
  " LOAD_BACKUP_TRAFFIC_USING_RPS_SUCCESS";
export const LOAD_RPS_DATA_STORES = "LOAD_RPS_DATA_STORES";
export const LOAD_RPS_DATA_STORES_SUCCESS = "LOAD_RPS_DATA_STORES_SUCCESS";
export const INGRAM_ORG_FAILURE = "INGRAM_ORG_FAILURE";
export const SHOW_INGRAM_ORG_MODAL = "SHOW_INGRAM_ORG_MODAL";
export const SET_HYPER_LINK = "SET_HYPER_LINK";
export const SET_ADVANCE_SEARCH_DATA = "SET_ADVANCE_SEARCH_DATA";
export const RESET_ADVANCE_SEARCH_DATA = "RESET_ADVANCE_SEARCH_DATA";
export const SET_FILTER_FROM_MONITOR_WIDGET = "SET_FILTER_FROM_MONITOR_WIDGET";
export const RESET_FILTER_FROM_MONITOR_WIDGET =
  "RESET_FILTER_FROM_MONITOR_WIDGET";
// UNC OR NFS Path action types
export const ADD_UNC_OR_NFS_PATH_SOURCE = "ADD_UNC_OR_NFS_PATH_SOURCE";
export const UNC_OR_NFS_PATH_SAVE_TO_LIST = "UNC_OR_NFS_PATH_SAVE_TO_LIST";
export const UNC_OR_NFS_PATH_SAVE_TO_LIST_ERROR =
  "UNC_OR_NFS_PATH_SAVE_TO_LIST_ERROR";
export const UNC_OR_NFS_PATH_SAVE_TO_LIST_SUCCESS =
  "UNC_OR_NFS_PATH_SAVE_TO_LIST_SUCCESS";
export const UNC_OR_NFS_CLEAR_PATH_LIST = "UNC_OR_NFS_CLEAR_PATH_LIST";
export const UNC_OR_NFS_DELETE_PATH = "UNC_OR_NFS_DELETE_PATH";
export const UNC_OR_NFS_CLEAR_PATH_LIST_ERROR =
  "UNC_OR_NFS_CLEAR_PATH_LIST_ERROR";
export const ADD_PATHS = "ADD_PATHS";
/**
 * Source Discovery actions
 */
export const LOAD_SOURCE_DISCOVERY_SETTINGS = "LOAD_SOURCE_DISCOVERY_SETTINGS";
export const LOAD_SOURCE_DISCOVERY_SETTINGS_SUCCESS =
  "LOAD_SOURCE_DISCOVERY_SETTINGS_SUCCESS";
export const FETCH_SOURCE_DISCOVERY_LIST = "FETCH_SOURCE_DISCOVERY_LIST";
export const FETCH_SOURCE_DISCOVERY_LIST_SUCCESS =
  "FETCH_SOURCE_DISCOVERY_LIST_SUCCESS";
export const SAVE_SOURCE_DISCOVERY_SETTINGS = "SAVE_SOURCE_DISCOVERY_SETTINGS";
export const SAVE_SOURCE_DISCOVERY_SETTINGS_SUCCESS =
  "SAVE_SOURCE_DISCOVERY_SETTINGS_SUCCESS";
export const ADD_DISCOVER_FROM_AD = "ADD_DISCOVER_FROM_AD";
export const ADD_DISCOVER_FROM_HYPERV = "ADD_DISCOVER_FROM_HYPERV";
export const ADD_DISCOVER_FROM_VMWARE = "ADD_DISCOVER_FROM_VMWARE";
export const SOURCE_DISCOVERY_RUN_NOW = "SOURCE_DISCOVERY_RUN_NOW";
export const DELETE_SOURCE_DISCOVERY = "DELETE_SOURCE_DISCOVERY";
/**
 * Default Deployment actions
 */
export const LOAD_DEFAULT_DEPLOYMENT = "LOAD_DEFAULT_DEPLOYMENT";
export const LOAD_DEFAULT_DEPLOYMENT_SUCCESS =
  "LOAD_DEFAULT_DEPLOYMENT_SUCCESS";
export const UPDATE_DEFAULT_DEPLOYMENT = "UPDATE_DEFAULT_DEPLOYMENT";
export const UPDATE_DEFAULT_DEPLOYMENT_SUCCESS =
  "UPDATE_DEFAULT_DEPLOYMENT_SUCCESS";

/**
 * Recovery Actions
 */
export const FETCH_AVAILABLE_VOLUMES = "FETCH_AVAILABLE_VOLUMES";
export const FETCH_AVAILABLE_VOLUMES_SUCCESS =
  "FETCH_AVAILABLE_VOLUMES_SUCCESS";
export const FETCH_MOUNTED_VOLUMES = "FETCH_MOUNTED_VOLUMES";
export const FETCH_MOUNTED_VOLUMES_SUCCESS = "FETCH_MOUNTED_VOLUMES_SUCCESS";
export const FETCH_MOUNTED_RPS = "FETCH_MOUNTED_RPS";
export const MOUNT_VOLUME_SUCCESS = "MOUNT_VOLUME_SUCCESS";
export const DISMOUNT_VOLUME = "DISMOUNT_VOLUME";
// Restore recovery point
export const GET_POLICY_DATA_BY_ID = "GET_POLICY_DATA_BY_ID";
export const GET_SOURCE_DATA_BY_ID = "GET_SOURCE_DATA_BY_ID";
export const SOURCE_ART_DATA_GET_SUCCESS = "SOURCE_ART_DATA_GET_SUCCESS";
export const CLEAR_SOURCE_ART_LOADER = "CLEAR_SOURCE_ART_LOADER";
export const CLEAR_SOURCE_ART_DETAILS = "CLEAR_SOURCE_ART_DETAILS";
export const POLICY_DATA_GET_SUCCESS = "POLICY_DATA_GET_SUCCESS";
export const GET_RECOVERY_POINTS_BY_DESTINATION_PATH =
  "GET_RECOVERY_POINTS_BY_DESTINATION_PATH";
export const GET_RECOVERY_ORACLE_DB_CONFIG = "GET_RECOVERY_ORACLE_DB_CONFIG";
export const STORE_RECOVERY_ORACLE_DB_SETTING =
  "STORE_RECOVERY_ORACLE_DB_SETTING";
export const CLEAR_RECOVERY_ORACLE_DB_SETTING =
  "CLEAR_RECOVERY_ORACLE_DB_SETTING";
export const STORE_RECOVERY_ORACLE_PDB_LIST = "STORE_RECOVERY_ORACLE_PDB_LIST";
export const CLEAR_RECOVERY_ORACLE_PDB_LIST = "CLEAR_RECOVERY_ORACLE_PDB_LIST";
export const STORE_RECOVERY_ORACLE_DB_TABLE_SETTING =
  "STORE_RECOVERY_ORACLE_DB_TABLE_SETTING";
export const CLEAR_RECOVERY_ORACLE_DB_TABLE_SETTING =
  "CLEAR_RECOVERY_ORACLE_DB_TABLE_SETTING";
export const RECOVERY_POINTS_BY_DESTINATION_PATH_GET_SUCCESS =
  "RECOVERY_POINTS_BY_DESTINATION_PATH_GET_SUCCESS";
export const GET_DESTINATIONS_BY_SOURCE_ID = "GET_DESTINATIONS_BY_SOURCE_ID";
export const DESTINATIONS_DATA_GET_SUCCESS = "DESTINATIONS_DATA_GET_SUCCESS";
export const CLEAR_RECOVERY_MOUNT_DATA = "CLEAR_RECOVERY_MOUNT_DATA";
export const UPDATE_UI_VERSION = "UPDATE_UI_VERSION";
export const UPDATE_API_VERSION = "UPDATE_API_VERSION";
export const UPDATE_VM_INFORMATION = "UPDATE_VM_INFORMATION";
export const UPDATE_STORAGE_ARRAY = "UPDATE_STORAGE_ARRAY";
export const GET_CURRENT_ACTION_CALLED_DATA_STORE =
  "GET_CURRENT_ACTION_CALLED_DATA_STORE";
export const PAGE_RELOADED = "PAGE_RELOADED";
export const UPDATE_HYPER_V_OR_VMWARE = "UPDATE_HYPER_V_OR_VMWARE";
export const CLEAR_RESTORE_RECOVERY_POINTS = "CLEAR_RESTORE_RECOVERY_POINTS";
export const SAVE_DESTINATION_HOST = "SAVE_DESTINATION_HOST";
export const GET_DESTINATION_HOSTS = "GET_DESTINATION_HOSTS";
export const GET_DESTINATION_HOSTS_SUCCESS = "GET_DESTINATION_HOSTS_SUCCESS";
export const ADD_DESTINATION_HOST = "ADD_DESTINATION_HOST";
export const ADD_DESTINATION_HOST_LINUX = "ADD_DESTINATION_HOST_LINUX";
export const RECOVERY_NEXT_PAGE = "RECOVERY_NEXT_PAGE";
export const RECOVERY_PREV_PAGE = "RECOVERY_PREV_PAGE";
export const RECOVERY_CLEAR_PAGE = "RECOVERY_CLEAR_PAGE";
export const SQL_PAGE_1_NEXT = "SQL_PAGE_1_NEXT";
export const SQL_PAGE_2_NEXT = "SQL_PAGE_2_NEXT";
export const SQL_PAGE_3_NEXT = "SQL_PAGE_3_NEXT";
export const SQL_PAGE_4_NEXT = "SQL_PAGE_4_NEXT";
export const ORACLE_PAGE_1_NEXT = "ORACLE_PAGE_1_NEXT";
export const ORACLE_PAGE_2_NEXT = "ORACLE_PAGE_2_NEXT";
export const FnF_PAGE_2_NEXT = "FnF_PAGE_2_NEXT";
export const START_RECOVERY = "START_RECOVERY";
export const SET_RECOVERY_TYPE = "SET_RECOVERY_TYPE";
export const RESET_RECOVERY = "RESET_RECOVERY";
export const RESET_RECOVERY_ON_CONNECT = "RESET_RECOVERY_ON_CONNECT";
export const FETCH_INITIAL_TREE_DATA = "FETCH_INITIAL_TREE_DATA";
export const FETCH_INITIAL_TREE_DATA_SUCCESS =
  "FETCH_INITIAL_TREE_DATA_SUCCESS";
export const FETCH_RECURSIVE_TREE_DATA = "FETCH_RECURSIVE_TREE_DATA";
export const FETCH_RECURSIVE_TREE_DATA_SUCCESS =
  "FETCH_RECURSIVE_TREE_DATA_SUCCESS";
export const SET_TREE_SELECTION = "SET_TREE_SELECTION";
export const VOLUME_RECOVERY_PAGE2_NEXT = "VOLUME_RECOVERY_PAGE2_NEXT";
export const VOLUME_RECOVERY_PAGE1_NEXT = "VOLUME_RECOVERY_PAGE1_NEXT";
export const VOLUME_RECOVERY_CONFIRM_SUBMIT = "VOLUME_RECOVERY_CONFIRM_SUBMIT";
export const VOLUME_RECOVERY_CONFIRM_CANCEL = "VOLUME_RECOVERY_CONFIRM_CANCEL";
export const SET_SQL_SUMMARY_DATA = "SET_SQL_SUMMARY_DATA";
export const SET_ORACLE_SUMMARY_DATA = "SET_ORACLE_SUMMARY_DATA";
export const SET_SQL_DB_DETAILS = "SET_SQL_DB_DETAILS";
export const SET_SQL_CLICKED_CONFIGURATION_BUTTON_ID =
  "SET_SQL_CLICKED_CONFIGURATION_BUTTON_ID";
export const LOAD_CONFIGURE_PROXIES = "LOAD_CONFIGURE_PROXIES";
export const GET_PROXIES_DATA = "GET_PROXIES_DATA";
export const UPDATE_PROXY = "UPDATE_PROXY";
export const GET_ALL_HYPERVISORS = "GET_ALL_HYPERVISORS";
export const GET_ALL_HYPERVISORS_SUCCESS = "GET_ALL_HYPERVISORS_SUCCESS";
export const GET_ALL_POLICY_PROXIES = "GET_ALL_POLICY_PROXIES";
export const GET_ALL_PROXIES_FOR_POLICY_SUCCESS =
  "GET_ALL_PROXIES_FOR_POLICY_SUCCESS";
export const GET_HYPERVISOR_CONFIGURATION = "GET_HYPERVISOR_CONFIGURATION";
export const GET_VMWARE_CONFIGURATION_FOR_POLICY =
  "GET_VMWARE_CONFIGURATION_FOR_POLICY";
export const GET_VMWARE_CONFIGURATION_FOR_POLICY_SUCCESS =
  "GET_VMWARE_CONFIGURATION_FOR_POLICY_SUCCESS";
export const GET_HYPERVISOR_CONFIGURATION_SUCCESS =
  "GET_HYPERVISOR_CONFIGURATION_SUCCESS";
export const CLEAR_HYPERVISOR_CONFIGURATION_DATA =
  "CLEAR_HYPERVISOR_CONFIGURATION_DATA";
export const REMOVE_PROXY = "REMOVE_PROXY";
export const STORE_NEW_IVM_RP = "STORE_NEW_IVM_RP";
export const BACKUP_PASSWORDS_DATA = "BACKUP_PASSWORDS_DATA";
export const COLLECT_DIAGNOSTIC_DATA = "COLLECT_DIAGNOSTIC_DATA";
export const VALIDATE_RP = "VALIDATE_RP";
export const SWITCH_ADD_IVM_TAB = "SWITCH_ADD_IVM_TAB";
export const RESTORE_NEW_IVM_VALUE = "RESTORE_NEW_IVM_VALUE";

/**
 * IVM related Types
 */
export const FETCH_PROXIES_IVM = "FETCH_PROXIES_IVM";
export const FETCH_PROXIES_IVM_SUCCESS = "FETCH_PROXIES_IVM_SUCCESS";
export const CLEAR_IVM = "CLEAR_IVM";
export const CLEAR_BACKUP_PASSWORDS = "CLEAR_BACKUP_PASSWORDS";
export const SET_SELECTED_PROXY = "SET_SELECTED_PROXY";
export const IVM_VALIDATE_NFS = "IVM_VALIDATE_NFS";
export const VALIDATE_IVM_SUCCESS = "VALIDATE_IVM_SUCCESS";
export const IVM_VALIDATE_HYPERV = "IVM_VALIDATE_HYPERV";
export const SUBMIT_IVM = "SUBMIT_IVM";
export const VM_RECOVERY_PAGE2_NEXT = "VM_RECOVERY_PAGE2_NEXT";
export const GET_ESX_SERVERS = "GET_ESX_SERVERS";
export const GET_ESX_SERVERS_SUCCESS = "GET_ESX_SERVERS_SUCCESS";
export const SET_FnF_SUMMARY_DATA = "SET_FnF_SUMMARY_DATA";
export const SET_VOLUME_SUMMARY_DATA = "SET_VOLUME_SUMMARY_DATA";
export const SET_VM_SUMMARY_DATA = "SET_VM_SUMMARY_DATA";
export const GET_RECOVERY_POINTS_LIST = "GET_RECOVERY_POINTS_LIST";
// export const STORE_RECOVERY_POINTS = "STORE_RECOVERY_POINTS";
export const STORE_SELECTED_RECOVERY_POINT = "STORE_SELECTED_RECOVERY_POINT";
/**
 * Policy Shared Folder Connect Action Type
 */
export const POLICY_SHARED_FOLDER_DESTINATION_CONNECT =
  "POLICY_SHARED_FOLDER_DESTINATION_CONNECT";

export const FETCH_DESTINATION_PATH_REQUEST = "FETCH_DESTINATION_PATH_REQUEST";
export const FETCH_DESTINATION_PATH_SUCCESS = "FETCH_DESTINATION_PATH_SUCCESS";
export const FETCH_DESTINATION_PATH_FAILURE = "FETCH_DESTINATION_PATH_FAILURE";

export const SET_SHARED_FOLDER_ID = "SET_SHARED_FOLDER_ID";

/**
 * Policy Shared Folder Authenticate Action Type
 */
export const POLICY_SHARED_FOLDER_DESTINATION_AUTHENTICATE =
  "POLICY_SHARED_FOLDER_DESTINATION_AUTHENTICATE";
/**
 * VSB Policy Action Types
 */
export const POLICY_VSB_CONNECT_HYPERV = "POLICY_VSB_CONNECT_HYPERV";
export const POLICY_VSB_CONNECT_VMWARE = "POLICY_VSB_CONNECT_VMWARE";
export const POLICY_VSB_CONNECT_VMWARE_MONITOR =
  "POLICY_VSB_CONNECT_VMWARE_MONITOR";
export const POLICY_VSB_FETCH_VMWARES = "POLICY_VSB_FETCH_VMWARES";
export const POLICY_VSB_FETCH_VMWARES_SUCCESS =
  "POLICY_VSB_FETCH_VMWARES_SUCCESS";
export const POLICY_VSB_FETCH_VMWARES_NODES = "POLICY_VSB_FETCH_VMWARES_NODES";
export const POLICY_VSB_FETCH_VMWARES_CONNECT_SUCCESS =
  "POLICY_VSB_FETCH_VMWARES_CONNECT_SUCCESS";
export const POLICY_VSB_FETCH_VMWARES_NODES_SUCCESS =
  "POLICY_VSB_FETCH_VMWARES_NODES_SUCCESS";
export const GET_HYPERV_CONFIGURATION = "GET_HYPERV_CONFIGURATION";
export const GET_VMWARE_CONFIGURATION = "GET_VMWARE_CONFIGURATION";
export const GET_HYPERV_CONFIGURATION_SUCCESS =
  "GET_HYPERV_CONFIGURATION_SUCCESS";
export const GET_VMWARE_CONFIGURATION_SUCCESS =
  "GET_VMWARE_CONFIGURATION_SUCCESS";
export const SET_HYPERV_CONNECT_FORM = "SET_HYPERV_CONNECT_FORM";
export const SET_VMWARE_CONNECT_FORM = "SET_VMWARE_CONNECT_FORM";
export const CLEAR_VSB_POLICY_DATA = "CLEAR_VSB_POLICY_DATA";
export const CLEAR_VMWARE_CONFIGURATION = "CLEAR_VMWARE_CONFIGURATION";
export const CLEAR_ESX_NODES = "CLEAR_ESX_NODES";
export const SET_FnF_LINUX_SUMMARY_DATA = "SET_FnF_LINUX_SUMMARY_DATA";
export const FnF_LINUX_PAGE_2_NEXT = "FnF_LINUX_PAGE_2_NEXT";
export const FnF_LINUX_VALIDATE_SCHEDULE = "FnF_LINUX_VALIDATE_SCHEDULE";
export const SET_SOURCE_DATA = "SET_SOURCE_DATA";
export const FnF_LINUX_FETCH_SCRIPTS = "FnF_LINUX_FETCH_SCRIPTS";
export const GET_LINUX_SCRIPTS_SUCCESS = "GET_LINUX_SCRIPTS_SUCCESS";
export const FnF_GENERATE_DOWNLOAD_LINK = "FnF_GENERATE_DOWNLOAD_LINK";
export const UPDATE_RECOVERED_RESOURCES = "UPDATE_RECOVERED_RESOURCES";
export const FnF_FETCH_VOLUMES_FOR_RP = "FnF_FETCH_VOLUMES_FOR_RP";
export const FnF_FETCH_VOLUMES_FOR_RP_SUCCESS =
  "FnF_FETCH_VOLUMES_FOR_RP_SUCCESS";
export const FnF_FETCH_ITEMS_FOR_RP = "FnF_FETCH_ITEMS_FOR_RP";
export const FnF_FETCH_ITEMS_FOR_RP_LINUX = "FnF_FETCH_ITEMS_FOR_RP_LINUX";
export const FnF_FETCH_ITEMS_DATA_FOR_RP_LINUX =
  "FnF_FETCH_ITEMS_DATA_FOR_RP_LINUX";
export const FnF_FETCH_TABLE_PAGINATION = "FnF_FETCH_TABLE_PAGINATION";
export const FnF_FETCH_ITEMS_FOR_RP_SUCCESS = "FnF_FETCH_ITEMS_FOR_RP_SUCCESS";
export const UPDATE_FOLDER_TREE_DATA = "UPDATE_FOLDER_TREE_DATA";
export const SELECTED_SOURCE_PAGINATION = "SELECTED_SOURCE_PAGINATION";
export const FETCH_FNF_TABLE_DATA = "FETCH_FNF_TABLE_DATA";
export const FETCH_FNF_TABLE_DATA_LINUX = "FETCH_FNF_TABLE_DATA_LINUX";
export const HIDE_FOLDER_STRUCTURE_LOADER = "HIDE_FOLDER_STRUCTURE_LOADER";
export const STORE_SOURCE_DATA = "STORE_SOURCE_DATA";
export const STORE_DESTINATION_TYPE = "STORE_DESTINATION_TYPE";
export const STORE_RECOVERY_POINT = "STORE_RECOVERY_POINT";
export const STORE_SELECTED_ITEM_FOR_RECOVERY =
  "STORE_SELECTED_ITEM_FOR_RECOVERY";
export const STORE_SELECTED_ITEM_FROM_ALL_PAGES =
  "STORE_SELECTED_ITEM_FROM_ALL_PAGES";
export const UPDATE_FNF_TABLE_DATA = "UPDATE_FNF_TABLE_DATA";
export const UPDATE_FNF_TABLE_DATA_PAGINATION =
  "UPDATE_FNF_TABLE_DATA_PAGINATION";
export const UPDATE_FNF_PAGINATION = "UPDATE_FNF_PAGINATION";

export const GET_PENDING_SESSIONS = "GET_PENDING_SESSIONS";
export const GET_STANDBY_VM_SNAPSHOT = "GET_STANDBY_VM_SNAPSHOT";
export const SHUTDOWN_VM_CLICKED = "SHUTDOWN_VM_CLICKED";
export const POWERON_VM_CLICKED = "POWERON_VM_CLICKED";
export const VIRTUAL_STANDBY_RESUME_SMART_COPY =
  "VIRTUAL_STANDBY_RESUME_SMART_COPY";
export const UPDATE_INSTANT_VMS = "UPDATE_INSTANT_VMS";
export const SINGLE_ACTION_ON_IVM = "SINGLE_ACTION_ON_IVM";
export const BULK_ACTION_ON_IVM = "BULK_ACTION_ON_IVM";

export const GET_BACKUP_VM_DISKS = "GET_BACKUP_VM_DISKS";
export const GET_BACKUP_VM_DISKS_SUCCESS = "GET_BACKUP_VM_DISKS_SUCCESS";
export const SHOW_WARNING_SUCCESS = "SHOW_WARNING_SUCCESS";
export const GET_NETWORK_ADAPTERS = "GET_NETWORK_ADAPTERS";
export const GET_NETWORK_ADAPTERS_SUCCESS = "GET_NETWORK_ADAPTERS_SUCCESS";
export const GET_HYPERV_LIST = "GET_HYPERV_LIST";
export const GET_HYPERV_LIST_SUCCESS = "GET_HYPERV_LIST_SUCCESS";
export const GET_ESX_VCENTER_LIST = "GET_ESX_VCENTER_LIST";
export const GET_ESX_VCENTER_LIST_SUCCESS = "GET_ESX_VCENTER_LIST_SUCCESS";
export const CONNECT_TO_HYPERV_VM_DEFAULT_PATH =
  "CONNECT_TO_HYPERV_VM_DEFAULT_PATH";
export const GET_NUTANIX_STORAGE = "GET_NUTANIX_STORAGE";
export const GET_NUTANIX_STORAGE_SUCCESS = "GET_NUTANIX_STORAGE_SUCCESS";
export const GET_NUTANIX_NETWORKS = "GET_NUTANIX_NETWORKS";
export const GET_NUTANIX_NETWORKS_SUCCESS = "GET_NUTANIX_NETWORKS_SUCCESS";
export const CONNECT_TO_HYPERV_VM_DEFAULT_PATH_SUCCESS =
  "CONNECT_TO_HYPERV_VM_DEFAULT_PATH_SUCCESS";
export const VALIDATE_VM = "VALIDATE_VM";
export const VALIDATE_LINUX_VM = "VALIDATE_LINUX_VM";
export const FETCH_HYPERVISOR_DATA = "FETCH_HYPERVISOR_DATA";
export const STORE_VALIDATE_VM_RESPONSE = "STORE_VALIDATE_VM_RESPONSE";
export const STORE_VALIDATE_LINUX_VM_RESPONSE =
  "STORE_VALIDATE_LINUX_VM_RESPONSE";
export const STORE_HYPERVISOR_DATA = "STORE_HYPERVISOR_DATA";
export const VERIFY_ENCRYPTION_PASSWORD = "VERIFY_ENCRYPTION_PASSWORD";
export const SAVE_RP_PASSWORD = "SAVE_RP_PASSWORD";
export const RESET_RP_PASSWORD = "RESET_RP_PASSWORD";
export const NO_RECOVERY_POINTS = "NO_RECOVERY_POINTS";
export const START_FULL_VM_RECOVERY = "START_FULL_VM_RECOVERY";
export const FETCH_VMWARE_DATASTORES = "FETCH_VMWARE_DATASTORES";
export const FETCH_VMWARE_DATASTORES_SUCCESS =
  "FETCH_VMWARE_DATASTORES_SUCCESS";
export const FETCH_VMWARE_STORAGE_POLCIES = "FETCH_VMWARE_STORAGE_POLCIES";
export const FETCH_VMWARE_STORAGE_POLCIES_SUCCESS =
  "FETCH_VMWARE_STORAGE_POLCIES_SUCCESS";
export const CONNECT_TO_ESX_VCENTER = "CONNECT_TO_ESX_VCENTER";

export const CONNECT_TO_ESX_VCENTER_SUCCESS = "CONNECT_TO_ESX_VCENTER_SUCCESS";
export const POLICY_VSB_FETCH_VMWARES_MONITOR_CONNECT_SUCCESS =
  "POLICY_VSB_FETCH_VMWARES_MONITOR_CONNECT_SUCCESS";
export const SHARED_FOLDERS_BROWSE = "SHARED_FOLDERS_BROWSE";
export const LOAD_NETWORK_DATA = "LOAD_NETWORK_DATA";
export const POST_NETWORK_DATA = "POST_NETWORK_DATA";
export const LOAD_BACKUP_PASSWORDS_DATA = "LOAD_BACKUP_PASSWORDS_DATA";
export const GET_NETWORK_DATA = "GET_NETWORK_DATA";
export const SET_BACKUP_PASSWORDS_DATA = "SET_BACKUP_PASSWORDS_DATA";
export const LOAD_DNS_DATA = "LOAD_DNS_DATA";
export const RECOVERY_FULL_VM_WINDOWS_PAYLOAD =
  "RECOVERY_FULL_VM_WINDOWS_PAYLOAD";
export const SOURCE_VM_VALIDATE_DIALOG = "SOURCE_VM_VALIDATE_DIALOG";
export const VALIDATE_HYPERVISOR_HYPERV = "VALIDATE_HYPERVISOR_HYPERV";
export const VERIFY_PASSWORD_AND_SERVER = "VERIFY_PASSWORD_AND_SERVER";
export const VALIDATE_HYPERVISOR_VMWARE = "VALIDATE_HYPERVISOR_VMWARE";
export const VALIDATE_HYPERVISOR_NUTANIX = "VALIDATE_HYPERVISOR_NUTANIX";
export const SET_NEW_VM_ID = "SET_NEW_VM_ID";
export const VALIDATE_DISK = "VALIDATE_DISK";
export const RECOVER_FULL_VM_NEXT = "RECOVER_FULL_VM_NEXT";
export const UPDATE_FNF_GRID_DATA = "UPDATE_FNF_GRID_DATA";
export const TWO_FACTOR_VERIFY_PASSWORD = "TWO_FACTOR_VERIFY_PASSWORD";
export const PASSWORD_VALIDATED = "PASSWORD_VALIDATED";
export const TWO_FACTOR_CHECK = "TWO_FACTOR_CHECK";
export const TWO_FACTOR_ENABLED = "TWO_FACTOR_ENABLED";
export const TWO_FACTOR_GENERATE_CODES = "TWO_FACTOR_GENERATE_CODES";
export const TWO_FACTOR_CODES = "TWO_FACTOR_CODES";
export const TWO_FACTOR_TOGGLE = "TWO_FACTOR_TOGGLE";
export const TWO_FACTOR_STORE_SECRET = "TWO_FACTOR_STORE_SECRET";
export const TWO_FACTOR_GET_SECRET = "TWO_FACTOR_GET_SECRET";
export const GET_TWO_FACTOR_SECRET = "GET_TWO_FACTOR_SECRET";
export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const STORE_ORGANIZATION = "STORE_ORGANIZATION";
export const STORE_TEMP = "STORE_TEMP";
export const EDIT_ORGANIZATION = "EDIT_ORGANIZATION";
export const TWO_FACTOR_USER_DISABLE = "TWO_FACTOR_USER_DISABLE";
export const FETCH_ALL_HELP_LINK_REQUEST = "FETCH_ALL_HELP_LINK_REQUEST";
export const FETCH_ALL_HELP_LINK_SUCCESS = "FETCH_ALL_HELP_LINK_SUCCESS";
export const UPDATE_ACCOUNT_DETAILS_REPORT_DATA =
  "UPDATE_ACCOUNT_DETAILS_REPORT_DATA";
// export const GET_LOAD_CLOUD_ACCOUNT_LIST_BY_TYPE =
//   "GET_LOAD_CLOUD_ACCOUNT_LIST_BY_TYPE";
export const GET_LOAD_CLOUD_ACCOUNT_LIST_BY_TYPE_SUCCESS =
  "GET_LOAD_CLOUD_ACCOUNT_LIST_BY_TYPE_SUCCESS";
export const CLEAR_CLOUD_ACCOUNTS_BY_TYPE = "CLEAR_CLOUD_ACCOUNTS_BY_TYPE";
export const STORE_HYPERV_NETWORK_LIST = "STORE_HYPERV_NETWORK_LIST";
export const STORE_VMWARE_NETWORK_LIST = "STORE_VMWARE_NETWORK_LIST";
export const STORE_HYPERV_DEFAULT_VM_PATH = "STORE_HYPERV_DEFAULT_VM_PATH";
export const STORE_HYPERV_DEFAULT_VHD_PATH = "STORE_HYPERV_DEFAULT_VHD_PATH";
export const SNAPSHOT_STANDBY_VM_NETWORK_CONFIGURATION =
  "SNAPSHOT_STANDBY_VM_NETWORK_CONFIGURATION";
export const GET_IS_NETWORK_CONFIG = "GET_IS_NETWORK_CONFIG";
export const FETCH_CLOUD_CONFIGURATIONS = "FETCH_CLOUD_CONFIGURATIONS";
export const STORE_CLOUD_CONFIGURATIONS = "STORE_CLOUD_CONFIGURATIONS";
export const FETCH_CLOUD_SUBNET = "FETCH_CLOUD_SUBNET";
export const STORE_CLOUD_SUBNET = "STORE_CLOUD_SUBNET";
export const GET_REGIONS_FOR_CLOUD_ACCOUNT = "GET_REGIONS_FOR_CLOUD_ACCOUNT";
export const GET_REGIONS_FOR_CLOUD_ACCOUNT_V3 =
  "GET_REGIONS_FOR_CLOUD_ACCOUNT_V3";
export const GET_REGIONS_FOR_CLOUD_ACCOUNT_SUCCESS =
  "GET_REGIONS_FOR_CLOUD_ACCOUNT_SUCCESS";
export const GET_REGIONS_FOR_CLOUD_ACCOUNT_SUCCESS_V3 =
  "GET_REGIONS_FOR_CLOUD_ACCOUNT_SUCCESS_V3";
export const CLEAR_CLOUD_ACCOUNTS_BUCKETS = "CLEAR_CLOUD_ACCOUNTS_BUCKETS";
export const GET_BUCKETS_FOR_CLOUD_ACCOUNT = "GET_BUCKETS_FOR_CLOUD_ACCOUNT";
export const GET_BUCKETS_FOR_CLOUD_ACCOUNT_SUCCESS =
  "GET_BUCKETS_FOR_CLOUD_ACCOUNT_SUCCESS";
export const GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT_SUCCESS =
  "GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT_SUCCESS";
export const GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT =
  "GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT";
export const CLEAR_CLOUD_ACCOUNTS_REGIONS = "CLEAR_CLOUD_ACCOUNTS_REGIONS";
export const CLEAR_CLOUD_ACCOUNTS_REGIONS_V3 =
  "CLEAR_CLOUD_ACCOUNTS_REGIONS_V3";
export const SAVE_RECOVERY_SELECTED_DESTINATION =
  "SAVE_RECOVERY_SELECTED_DESTINATION";
export const SAVE_RECOVERY_DB_CONFIG_DATA = "SAVE_RECOVERY_DB_CONFIG_DATA";
export const CLEAR_RECOVERY_DB_CONFIG_DATA = "CLEAR_RECOVERY_DB_CONFIG_DATA";
export const CLEAR_RECOVERY_DB_STATIC_TABLE = "CLEAR_RECOVERY_DB_STATIC_TABLE";
export const SHOW_FREE_SIZE_WARNING = "SHOW_FREE_SIZE_WARNING";
export const FETCH_VMWARE_NETWORK_LIST = "FETCH_VMWARE_NETWORK_LIST";
export const VSB_VALIDATE_CLOUD_PROXY = "VSB_VALIDATE_CLOUD_PROXY";
export const EC2_VALIDATE_CLOUD_PROXY_RESPONSE =
  "EC2_VALIDATE_CLOUD_PROXY_RESPONSE";
export const FETCH_RESOURCE_POOL = "FETCH_RESOURCE_POOL";
export const BROWSE_RESOURCE_POOL_SUCCESS = "BROWSE_RESOURCE_POOL_SUCCESS";
export const GET_CH_DATASTORES = "GET_CH_DATASTORES";
export const SAVE_RECOVERY_SELECTED_DESTINATION_DATA =
  "SAVE_RECOVERY_SELECTED_DESTINATION_DATA";
export const GET_PROXY_BY_ID = "GET_PROXY_BY_ID";
export const GET_ALL_VSB_HYPERVISORS = "GET_ALL_VSB_HYPERVISORS";
export const GET_VSB_SUCCESS_HYPERVISORS = "GET_VSB_SUCCESS_HYPERVISORS";
export const GET_VSB_FAILED_HYPERVISORS = "GET_VSB_FAILED_HYPERVISORS";
export const CLEAR_VSB_HYPERVISORS = "CLEAR_VSB_HYPERVISORS";
export const GET_HYPERVISORS_LIST_BY_TYPE = "GET_HYPERVISORS_LIST_BY_TYPE";
export const GET_IVM_HYPERV_LIST = "GET_IVM_HYPERV_LIST";
export const CREATE_VMWARE_FOR_IVM = "CREATE_VMWARE_FOR_IVM";
export const CREATE_VMWARE_FOR_POLICY = "CREATE_VMWARE_FOR_POLICY";
export const CREATE_HYPERV_FOR_IVM = "CREATE_HYPERV_FOR_IVM";
export const CREATE_HYPERV_FOR_POLICY = "CREATE_HYPERV_FOR_POLICY";
export const CREATE_HYPERV_FOR_POLICY_SUCCESS =
  "CREATE_HYPERV_FOR_POLICY_SUCCESS";
export const CLEAR_HYPERV_FOR_POLICY = "CLEAR_HYPERV_FOR_POLICY";
export const CREATE_VMWARE_FOR_IVM_SUCCESS = "CREATE_VMWARE_FOR_IVM_SUCCESS";
export const CREATE_VMWARE_FOR_POLICY_SUCCESS =
  "CREATE_VMWARE_FOR_POLICY_SUCCESS";
export const CLEAR_VMWARE_FOR_POLICY = "CLEAR_VMWARE_FOR_POLICY";
export const CLEAR_CREATE_VMWARE_FOR_IVM_SUCCESS =
  "CLEAR_CREATE_VMWARE_FOR_IVM_SUCCESS";
export const HYPERV_TYPE_AR_CHANGED = "HYPERV_TYPE_AR_CHANGED";
export const RESET_HYPERV_TYPE_AR_CHANGED_VAL =
  "RESET_HYPERV_TYPE_AR_CHANGED_VAL";
export const CREATE_HYPERV_FOR_IVM_SUCCESS = "CREATE_HYPERV_FOR_IVM_SUCCESS";
export const CLEAR_CREATE_HYPERV_FOR_IVM_SUCCESS =
  "CLEAR_CREATE_HYPERV_FOR_IVM_SUCCESS";
export const CLEAR_VM_LOCATION_DETAILS = "CLEAR_VM_LOCATION_DETAILS";
export const UPDATE_SOURCE_PROTECTION_SOURCES_SUMMARY =
  "UPDATE_SOURCE_PROTECTION_SOURCES_SUMMARY";
export const SAVE_VMWARE_CONFIG_PAYLOAD = "SAVE_VMWARE_CONFIG_PAYLOAD";
export const CLEAR_VMWARE_CONFIG_RESP = "CLEAR_VMWARE_CONFIG_RESP";
export const SAVE_VMWARE_FINAL_PAYLOAD_INFO = "SAVE_VMWARE_FINAL_PAYLOAD_INFO";
export const CLEAR_VMWARE_FINAL_PAYLOAD_INFO =
  "CLEAR_VMWARE_FINAL_PAYLOAD_INFO";
export const CLEAR_HYPERV_PATHS = "CLEAR_HYPERV_PATHS";
export const CREATE_FILE_BROWSE_FOLDER = "CREATE_FILE_BROWSE_FOLDER";
export const CREATE_FILE_BROWSE_FOLDER_HYPERV =
  "CREATE_FILE_BROWSE_FOLDER_HYPERV";
export const CREATE_FILE_BROWSE_FOLDER_LINUX =
  "CREATE_FILE_BROWSE_FOLDER_LINUX";
export const SET_INITIAL_PATH = "SET_INITIAL_PATH";
export const SET_SELECTED_TYPE_IN_VM_TREE = "SET_SELECTED_TYPE_IN_VM_TREE";
export const GET_RECOVERY_POINT_RESPONSE = "GET_RECOVERY_POINT_RESPONSE";
export const SET_ORGANIZATION_ID_FOR_SOURCE_CSR =
  "SET_ORGANIZATION_ID_FOR_SOURCE_CSR";
export const RECOVERY_FNF_CONNECT_STATUS = "RECOVERY_FNF_CONNECT_STATUS";
export const CREATE_VMWARE_FOR_RECOVERY = "CREATE_VMWARE_FOR_RECOVERY";
export const SAVE_RECOVERY_SELECTED_DESTINATION_DATA_IVM =
  "SAVE_RECOVERY_SELECTED_DESTINATION_DATA_IVM";
export const STORE_VALIDATE_CONFIG_DATA = "STORE_VALIDATE_CONFIG_DATA";
export const FETCH_AGENTLESS_VM_SOURECES_REQUEST =
  "FETCH_AGENTLESS_VM_SOURECES_REQUEST";
export const DISABLE_SAVE_POLCIY_BUTTON = "DISABLE_SAVE_POLCIY_BUTTON";
export const REFRESH_HYPERVISORS = "REFRESH_HYPERVISORS";
export const REFRESH_CLOUD_ACCOUNT = "REFRESH_CLOUD_ACCOUNT";
export const CONNECT_HYPERVISOR = "CONNECT_HYPERVISOR";
export const ADD_HYPERVISOR = "ADD_HYPERVISOR";
export const SET_CONTAINER_POLICIES_DATA = "SET_CONTAINER_POLICIES_DATA";
export const CONTINUE_CONTAINERS_SAGA = "CONTINUE_CONTAINERS_SAGA";
export const SET_SELECTED_SOURCE_OS = "SET_SELECTED_SOURCE_OS";
export const GET_RPS_HASHMEMORY = "GET_RPS_HASHMEMORY";
export const SET_RPS_HASHMEMORY = "SET_RPS_HASHMEMORY";
export const SET_RPS_HASHMEMORY_LOADER = "SET_RPS_HASHMEMORY_LOADER";
export const SET_RPS_HASHMEMORY_ERROR = "SET_RPS_HASHMEMORY_ERROR";
export const RESET_SELECTED_NETWORK_FOR_BACKUP_CHECKBOX =
  "RESET_SELECTED_NETWORK_FOR_BACKUP_CHECKBOX";
export const SET_FROM_MONITOR_PAGE = "SET_FROM_MONITOR_PAGE";
export const PURGE_DATASTORE_RECOVERY_POINTS =
  "PURGE_DATASTORE_RECOVERY_POINTS";
export const SET_IS_DRAGGABLE = "SET_IS_DRAGGABLE";
export const ADDED_ORACLE_DB_LIST = "ADDED_ORACLE_DB_LIST";
export const CLEAR_ORACLE_DB_LIST = "CLEAR_ORACLE_DB_LIST";

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Form, reduxForm, Field } from "redux-form";
import { withRouter } from "react-router-dom";
import SelectDropdown from "components/common/select-dropdown-mui/index";
import FormattedMessage from "components/common/formatted-message";
import { getEntitlementsProducts } from "state/selectors";
import IconMUI from "components/common/icon/IconMUI";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "../date-picker";
import DateTimeRangePicker from "../date-time-range-picker";
import ActionDropdown from "../action-dropdown";
import Button from "components/common/button";
import * as ccToast from "state/actions/ccToast";
import {
  showHideSaveSearchNClearAll,
  clearAllSearchTags,
  updateAdvancedSearchOptions,
  updateTagsForCurrentSearch,
  updateCurrentSavedSearchName,
  clearAllSearchTagsReload,
  updateSelectedFilterOptions
} from "../../../state/actions/actions";
import { getAdvancedSearchNameLabel } from "../../../utils/SpogDataGridUtil";
import {
  PAGE_ROUTE,
  ADVANCE_SEARCH_ERROR_MESSAGE,
  searchOptionClearDS,
  searchOptionClearRPS
} from "../../../utils/appConstants";
import { Typography, Paper } from "@mui/material";

const renderDateRangePicker = props => {
  const {
    input,
    index,
    minDate,
    options,
    formatValue,
    showTimePicker,
    componentValue,
    skipDefaultOption,
    resetComponent,
    disableReset,
    dispatchValue,
    onChangeEvent
  } = props;
  return (
    <DateTimeRangePicker
      index={index}
      parentEl="#managedSavedSearch"
      options={options}
      componentValue={componentValue}
      minDate={minDate}
      dropdownDirection="up"
      onChange={data => {
        input.onChange(data);
        onChangeEvent(index, data);
      }}
      showTimePicker={showTimePicker}
      format={formatValue}
      skipDefaultOption={skipDefaultOption}
      resetComponent={resetComponent}
      disableReset={disableReset}
      dispatch={dispatchValue}
      customDateStyle={{ marginLeft: "10px" }}
    />
  );
};

renderDateRangePicker.defaultProps = {
  componentValue: {}
};

renderDateRangePicker.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func
  }).isRequired,
  componentValue: PropTypes.shape({
    start_ts: PropTypes.number,
    end_ts: PropTypes.number,
    type: PropTypes.string
  })
};

const renderDatePickerFunction = props => {
  const {
    input,
    index,
    minDate,
    hideDatePickerLabel,
    callBackHandler,
    startDate
  } = props;
  return (
    <DatePicker
      parentEl="#managedSavedSearch"
      index={index}
      minDate={minDate}
      maxDate={moment()}
      isSingleDatePicker
      hideDatePickerLabel={hideDatePickerLabel}
      callBackHandler={(index, data) => {
        input.onChange(data);
        callBackHandler(index, data);
      }}
      startDate={startDate}
    />
  );
};

const renderDropdownField = props => {
  const {
    input,
    index,
    titleValue,
    showDatePicker,
    options,
    skipDefaultOption,
    handleUpdateFunction,
    loaderValue = false
  } = props;
  return (
    <ActionDropdown
      title={titleValue}
      showDateRange={showDatePicker}
      options={options}
      skipDefaultOption={skipDefaultOption}
      onMenuItemClick={(index, data) => {
        input.onChange(data[0].value);
        handleUpdateFunction(index, data);
      }}
      loader={loaderValue}
      render
      optionIndex={index}
    />
  );
};

renderDropdownField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func
  }).isRequired
};

/**
 * Parent component to render advanced search overlay
 */
class AdvancedSearch extends PureComponent {
  /**
   * Function to fetch sate for SAVE button
   */
  getDisableState = () => {
    let flag = true;
    this.props.options &&
      Array.isArray(this.props.options) &&
      this.props.options.length > 0 &&
      this.props.options.forEach(option => {
        if (option == null) {
          return;
        }
        switch (option.type) {
          case "date-picker":
          case "date-range-picker":
            if (
              option.selectedOptions &&
              Object.keys(option.selectedOptions).length
            )
              flag = false;
            break;

          default:
            if (option.selectedOptions && option.selectedOptions.length > 0)
              flag = false;
            break;
        }
      });
    if (this.props.searchText && this.props.searchText.trim().length >= 3)
      flag = false;

    if (!this.props.selectedSavedSearchId.length) {
      if (
        this.props.searchOption &&
        this.props.searchOption.selectedOption &&
        this.props.searchOption.selectedOption.length
      )
        flag = false;
    }

    return flag;
  };

  isSearched = () => {
    let flag = false;

    this.props.currentSearchOptions &&
      this.props.currentSearchOptions.length > 0 &&
      this.props.currentSearchOptions.forEach(option => {
        switch (option.type) {
          case "date-picker":
          case "date-range-picker":
            if (
              option.selectedOptions &&
              Object.keys(option.selectedOptions).length
            )
              flag = true;
            break;

          default:
            if (option.selectedOptions && option.selectedOptions.length > 0)
              flag = true;
            break;
        }
      });

    if (
      this.props.searchOption &&
      this.props.searchOption.selectedOption &&
      this.props.searchOption.selectedOption.length
    )
      flag = true;

    return flag;
  };
  /**
   * Function to handle events and to dispatch action of date picker
   */
  handleUpdateAdvancedSearchOption = (index, item) =>
    this.props.dispatch(updateAdvancedSearchOptions({ index, item }));

  toggleAdvancedSearch = () => this.props.handleToggleAdvancedSearch();

  resetClick = () => {
    // anytime reset is clicked, update the search
    let flag = true; // this.props.localSearch ? true : this.isSearched();
    this.props.dispatch(clearAllSearchTags(true));
    const { url } = this.props.match;
    if (flag) {
      setTimeout(() => {
        if (
          url.includes(searchOptionClearDS) &&
          url.includes(searchOptionClearRPS)
        ) {
          this.changeBreadcrumbOnTagClear();
        }

        this.searchClick("reset");
        this.props.dispatch(clearAllSearchTagsReload());
      }, 0);
    }
  };
  searchClick = from => {
    const advancedSearchOptions = this.props.options;
    const searchText = this.props.searchText;
    if (this.props.searchText && this.props.searchText.length < 3) {
      this.showErrorMessage();
      return;
    }
    if (this.props.disableManageSaveSearches) {
      this.props.dispatch(showHideSaveSearchNClearAll(true));
    } else {
      this.props.dispatch(showHideSaveSearchNClearAll(false));
    }
    from !== "reset" && this.props.handleToggleAdvancedSearch();
    this.props.dispatch(updateTagsForCurrentSearch());
    if (this.props.handleAdvancedSearchClick) {
      this.props.dispatch(updateCurrentSavedSearchName("", ""));
      this.props.dispatch(updateSelectedFilterOptions([]));
      this.props.handleAdvancedSearchClick({
        advancedSearchOptions,
        searchText
      });
    }
  };

  changeBreadcrumbOnTagClear = () => {
    const { history } = this.props;
    history.push(`${PAGE_ROUTE.DATA_STORE}`);
  };

  /**
   * Handle close toast notification
   */
  handleDismiss() {
    this.props.dispatch(ccToast.clearNotifications());
  }

  hideToastNofication() {
    setTimeout(() => this.handleDismiss(), 5000);
  }

  /**
   * show error message when search string is entered and length < 3
   */
  showErrorMessage() {
    this.props.dispatch(
      ccToast.addErrorNotification("apiErrorResponse", {
        message: this.props.intl.formatMessage({
          id: ADVANCE_SEARCH_ERROR_MESSAGE
        })
      })
    );
    this.hideToastNofication();
  }

  /**
   * Return's selected option
   * * @param {*} options (Array)
   * @override
   */
  selectedOption = options => options.map(option => option.label);

  renderSingleSelectOptions = (value, index) => (
    <div key={index} className="select-wrapper">
      <Typography component="label" sx={{ color: "label.color" }}>
        <FormattedMessage id={value.name} defaultMessage={value.name} />
      </Typography>
      <div className="single-select-dropdown">
        <Field
          name={value.key}
          component={renderDropdownField}
          titleValue={
            value && value.selectedOptions && value.selectedOptions.length
              ? value.selectedOptions[0].label
                ? value.selectedOptions[0].label
                : this.props.intl.formatMessage({
                    id: "select",
                    defaultMessage: "Select"
                  })
              : this.props.intl.formatMessage({
                  id: "select",
                  defaultMessage: "Select"
                })
          }
          showDatePicker={value.showDatePicker}
          loaderValue={this.props.loaderValue}
          options={value.options}
          loader={false}
          skipDefaultOption={this.props.skipDefaultOption}
          handleUpdateFunction={this.handleUpdateAdvancedSearchOption}
          index={index}
        />
      </div>
    </div>
  );

  renderMultiDropBox = props => {
    let {
      options,
      hasi18nSupport,
      isMultiSelect,
      selectedOption,
      getSelectedOption,
      optionType,
      label,
      loaderValue
    } = props;
    let tempOptions = options;
    if (props.input.name === "source_state") {
      selectedOption = selectedOption.map(item => {
        return {
          ...item,
          value: !item.value.includes("source_state")
            ? `protect.RecoveredResources.virtual_standbys.source_state.${item.value}`
            : item.value
        };
      });
    } else if (props.input.name === "policy_id") {
      //this is to handle duplicate policy for MSP
      tempOptions = options.map(option => {
        return { ...option, value: option.id };
      });
    }
    return (
      <SelectDropdown
        hasi18nSupportByLabel
        hasi18nSupport={hasi18nSupport}
        dropdownOptions={tempOptions}
        isMenuCloseOnSelect={false}
        label={label}
        loading={loaderValue}
        isDropdownDisabled={false}
        isMultiSelect={isMultiSelect}
        placeholder={this.props.intl.formatMessage({
          id: "select",
          defaultMessage: "Select"
        })}
        getSelectedOption={(index, item) => {
          let selectedDataArray = item.map(option => option.value);
          props.input.onChange(selectedDataArray.join(","));
          getSelectedOption(index, item);
        }}
        optionType={optionType}
        selectedOption={selectedOption}
        testId={`${optionType}_wyKcY2`}
      />
    );
  };

  renderSelectOptions = (value, index, hasi18nSupport = true) => (
    <div key={index} className="select-wrapper">
      <Typography component="label" sx={{ color: "label.color" }}>
        <FormattedMessage id={value.name} defaultMessage={value.name} />
      </Typography>
      <Field
        name={value.key}
        options={value.options}
        component={this.renderMultiDropBox}
        hasi18nSupport={hasi18nSupport}
        isMultiSelect={this.props.isMultiSelect}
        selectedOption={value.selectedOptions}
        getSelectedOption={this.handleUpdateAdvancedSearchOption}
        optionType={index}
        loaderValue={this.props.loaderValue}
      />
    </div>
  );

  renderDateRangePickers = (value, index) => (
    <div
      key={index}
      className={`select-wrapper ${
        value &&
        value.selectedOptions &&
        value.selectedOptions.type &&
        value.selectedOptions.type === "custom"
          ? "date-range-picker-wrapper"
          : ""
      }`}
    >
      <Typography component="label" sx={{ color: "label.color" }}>
        <FormattedMessage id={value.name} defaultMessage={value.name} />
      </Typography>
      <Field
        name={value.key}
        component={renderDateRangePicker}
        index={index}
        minDate={value.minDate}
        options={value.options}
        formatValue={value.format}
        showTimePicker={value.showTimePicker}
        onChangeEvent={this.handleUpdateAdvancedSearchOption}
        componentValue={value.selectedOptions}
        skipDefaultOption={this.props.skipDefaultOption}
        resetComponent={this.props.resetDTRComponent}
        disableReset={this.props.hideSearchButtonInModal}
        dispatchValue={this.props.dispatch}
      />
    </div>
  );
  /**
   *  Date picker component
   */
  renderDatePicker = (value, index) => (
    <div key={index} className="select-wrapper">
      <Typography component="label" sx={{ color: "label.color" }}>
        <FormattedMessage id={value.name} defaultMessage={value.name} />
      </Typography>
      <Field
        name={value.key}
        component={renderDatePickerFunction}
        index={index}
        minDate={value.minDate}
        hideDatePickerLabel={value.hideLabel ? value.hideLabel : false}
        callBackHandler={this.handleUpdateAdvancedSearchOption}
        startDate={(value.selectedOptions && value.selectedOptions.start) || ""}
      />
    </div>
  );

  renderSearchOptions = () => {
    if (this.props.options && Array.isArray(this.props.options)) {
      return this.props.options.map((value, index) => {
        if (value == null) {
          //eslint-disable-next-line array-callback-return
          return;
        }
        switch (value.type) {
          case "date-range-picker":
            return this.renderDateRangePickers(value, index);

          case "date-picker":
            return this.renderDatePicker(value, index);

          case "single-select":
            return this.renderSingleSelectOptions(value, index);

          case "destinations":
            return this.renderSelectOptions(value, index, false);

          default:
            if (value.name === "report_type") {
              value.options = value.options.filter(
                option =>
                  option.value !== "policy_tasks" &&
                  option.value !== "restore_jobs"
              );
            }
            return this.renderSelectOptions(value, index);
        }
      });
    }
  };

  render() {
    let advanceSearchOptionClasses = [
      "column-name-list",
      "d-flex",
      "flex-wrap"
    ];
    const { handleSubmit, options } = this.props;
    const selectedSearchText = this.props.searchOption.selectedOption
      ? !this.props.selectedSavedSearchId.length
        ? this.props.searchOption.selectedOption
        : ""
      : this.props.searchText;
    const hideSearchButtonInModal = this.props.hideSearchButtonInModal;
    if (options && options.length > 6) {
      advanceSearchOptionClasses.push("custom-advance-search-list");
    }
    return (
      <div ref={node => (this.node = node)}>
        <Form
          onReset={this.resetClick}
          // onSubmit={handleSubmit(this.searchClick)}
          className={
            "advanced-search-wrapper flex-column " + this.props.className
          }
        >
          <Paper elevation={0} className="advance-search-paper-wrap">
            <div className="advanced-search-content d-flex flex-column">
              {!hideSearchButtonInModal && (
                // eslint-disable-next-line
                <a
                  role="button"
                  tabIndex={0}
                  className="close-advanced-search"
                  onClick={() => this.toggleAdvancedSearch()}
                  onKeyPress={() => {}}
                >
                  <IconMUI icon={CloseIcon} />
                </a>
              )}
              <div className="advanced-search-name-input">
                {!hideSearchButtonInModal && (
                  <FormattedMessage
                    id={getAdvancedSearchNameLabel(this.props.filterType)}
                    defaultMessage="Name contains"
                  />
                )}
                <Typography
                  name="filter"
                  id="filterName"
                  component="label"
                  sx={{ color: "label.color" }}
                >
                  {selectedSearchText}
                </Typography>
              </div>
              <div className="column-name-list-wrapper">
                <div className={advanceSearchOptionClasses.join(" ")}>
                  {this.renderSearchOptions()}
                </div>
              </div>
            </div>
            {!hideSearchButtonInModal && (
              <div className="advanced-search-footer">
                <Button
                  color="secondary"
                  type="reset"
                  disabled={this.getDisableState("reset")}
                  id="spog-clearall"
                  content={
                    <FormattedMessage
                      id="clearall"
                      defaultMessage="Clear All"
                    />
                  }
                  variant="contained"
                />
                <Button
                  color="primary"
                  type="button"
                  onClick={this.searchClick}
                  disabled={this.getDisableState("submit")}
                  id="cc_advanced_search"
                  content={
                    <FormattedMessage id="Search" defaultMessage="Search" />
                  }
                  variant="contained"
                />
              </div>
            )}
          </Paper>
        </Form>
      </div>
    );
  }
}

AdvancedSearch.defaultProps = {
  skipDefaultOption: true,
  selectedSavedSearchId: "",
  localSearch: false,
  initialValues: {},
  options: []
};

AdvancedSearch.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  handleToggleAdvancedSearch: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  disableManageSaveSearches: PropTypes.bool.isRequired,
  handleAdvancedSearchClick: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  searchOption: PropTypes.shape({
    selectedOption: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.string
    ])
  }).isRequired,
  hideSearchButtonInModal: PropTypes.bool,
  className: PropTypes.string.isRequired,
  filterType: PropTypes.string.isRequired,
  skipDefaultOption: PropTypes.bool,
  resetDTRComponent: PropTypes.bool.isRequired,
  currentSearchOptions: PropTypes.arrayOf(PropTypes.shape({})),
  selectedSavedSearchId: PropTypes.string,
  localSearch: PropTypes.bool,
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired
};

const AdvancedSearchForm = reduxForm({
  form: "advancedSearch",
  fields: ["keyword", "filterName"],
  enableReinitialize: true
})(injectIntl(AdvancedSearch));

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    let initialValues = {};
    initialValues.start_time_ts = {
      type: "",
      start_ts: 0,
      end_ts: 0
    };
    props.options &&
      Array.isArray(props.options) &&
      //eslint-disable-next-line array-callback-return
      props.options.map(value => {
        if (
          value &&
          value.orignalOptions &&
          ((value.type &&
            value.type === "date-range-picker" &&
            value.orignalOptions.type) ||
            (value.type &&
              value.type === "date-picker" &&
              value.orignalOptions.start) ||
            value.orignalOptions.length > 0)
        ) {
          initialValues[value.key] = Array.isArray(value.orignalOptions)
            ? value.orignalOptions.map(option => option.value).join(",")
            : value.orignalOptions;
        }
      });
    return {
      initialValues: initialValues,
      entitlements: getEntitlementsProducts(state),
      secondaryNavigation: state.reducer.secondaryNavigation,
      primaryNavigation: state.reducer.primaryNavigation
    };
  };
  return mapStateToProps;
};

export default withRouter(connect(makeMapStateToProps)(AdvancedSearchForm));

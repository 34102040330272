import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import differenceBy from "lodash/differenceBy";
import Select from "react-select";
import createFilterOptions from "react-select-fast-filter-options";
import classNames from "classnames";
import SelectDropdown from "components/common/select-with-tags";
import VirtualizedSelectDropdown from "components/common/virtualized-select-dropdown-mui";
import VirtualizedSelectDropdownManagePermissions from "components/common/virtualized-select-dropdown-mui/VirtualizedSelectDropdownManagePermissions";
import VirtualizedCustomizableDropdown from "components/common/virtualized-customizable-dropdown-mui";
import SelectDropdownMUI from "components/common/select-with-tags-mui";
import Tooltipped from "components/common/tooltipped";
import FormattedMessage from "components/common/formatted-message";
import ActionDropdown from "components/common/action-dropdown";
import ActionDropdownMUI from "components/common/action-dropdown-mui";
import TimePicker from "components/common/timepicker-mui";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMUI from "components/common/input-with-tags-mui";
import ScheduleDays from "components/common/ScheduleDays";
import { InputWithLabelMUI } from "components/common/form/InputWithLabel";
import { VALIDATION_REGEX } from "utils/appConstants";
import TextField from "@mui/material/TextField";
import { ID_PREFIX } from "configs/IdConfig";

export const renderTimePicker = props => {
  const {
    input,
    testId,
    showFutureTimeOnly,
    onMenuItemClick,
    selectedDate,
    scheduletime
  } = props;
  return (
    <TimePicker
      {...input}
      testId={testId}
      showFutureTimeOnly={showFutureTimeOnly}
      onMenuItemClick={onMenuItemClick}
      selectedDate={selectedDate}
      scheduletime={scheduletime}
    />
  );
};

renderTimePicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string
  }).isRequired
};

export class renderScheduleDays extends PureComponent {
  render() {
    const {
      input,
      meta: { touched, error },
      label
    } = this.props;
    return (
      <div>
        <ScheduleDays
          {...input}
          selectedDays={input && input.value}
          label={label}
        />
        {touched && error && (
          <div style={{ color: "#f76c83", marginTop: "8px", width: "55px" }}>
            <FormattedMessage id={error.id} values={error.values} />
          </div>
        )}
      </div>
    );
  }
}

renderScheduleDays.propTypes = {
  input: PropTypes.shape({
    name: PropTypes
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string
  }).isRequired,
  selectedDays: PropTypes.string.isRequired
};

//TODO change this to use as per CustomError
export const renderError = ({ input: { name }, meta: { error } }) =>
  !error ? null : (
    <Tooltipped id={name} text={error}>
      <span className="fa fa-exclamation-circle" />
    </Tooltipped>
  );

renderError.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string
  }).isRequired
};

//TODO: fix this one as well like renderInput
export const renderDropDown = ({
  input,
  options,
  disabledOptions,
  skipDefaultOption = true,
  defaultOptionValue = "",
  defaultOptionKey = "",
  disabled = false,
  meta: { touched, error },
  className,
  isTask
}) => {
  return (
    <div>
      <ActionDropdown
        {...input}
        options={options}
        disabledOptions={disabledOptions}
        disabled={disabled}
        pullRight={false}
        skipDefaultOption={skipDefaultOption}
        defaultOptionValue={defaultOptionValue}
        defaultOptionKey={defaultOptionKey}
        title={
          input && input.value ? input.value : <FormattedMessage id="select" />
        }
        onMenuItemClick={({ key }) => input.onChange(key)}
        className={className}
      />
      {touched && error && (
        <div style={{ color: "#f76c83", marginTop: "8px", width: "55px" }}>
          <FormattedMessage id={error.id} values={error.values} />
        </div>
      )}
      {isTask && error && (
        <div style={{ color: "#f76c83", marginTop: "8px" }}>
          <FormattedMessage id={error.id} values={error.values} />
        </div>
      )}
    </div>
  );
};

renderDropDown.propTypes = {
  input: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.bool,
  isTask: PropTypes.bool,
  meta: PropTypes.shape().isRequired,
  disabledOptions: PropTypes.arrayOf(PropTypes.shape())
};

renderDropDown.defaultProps = {
  disabled: false,
  isTask: false,
  disabledOptions: []
};

export const renderDropDownMUI = ({
  input,
  options,
  disabledOptions,
  skipDefaultOption = true,
  defaultOptionValue = "",
  defaultOptionKey = "",
  disabled = false,
  meta: { touched, error },
  className,
  isTask,
  testId,
  id,
  customStyle = {},
  titleId,
  monthlyScheduleDropdown,
  customMenuClassName = ""
}) => {
  const getTitleForLastDay = () => {
    let title = "";
    options.map(option => {
      if (option.key == input.value) {
        title = option.value;
      }
    });
    return title;
  };

  return (
    <div>
      <ActionDropdownMUI
        {...input}
        customMenuClassName={customMenuClassName}
        customStyle={customStyle}
        options={options}
        disabledOptions={disabledOptions}
        testId={testId}
        id={id}
        disabled={disabled}
        pullRight={false}
        skipDefaultOption={skipDefaultOption}
        defaultOptionValue={defaultOptionValue}
        defaultOptionKey={defaultOptionKey}
        className={className}
        title={
          monthlyScheduleDropdown && input.value == 32 ? (
            getTitleForLastDay()
          ) : input && input.value ? (
            input.value
          ) : (
            <FormattedMessage id={titleId ? titleId : "select"} />
          )
        }
        onMenuItemClick={({ key }) => input.onChange(key)}
      />
      {touched && error && (
        <div style={{ color: "#f76c83", marginTop: "8px", width: "55px" }}>
          <FormattedMessage id={error.id} values={error.values} />
        </div>
      )}
      {isTask && error && (
        <div style={{ color: "#f76c83", marginTop: "8px" }}>
          <FormattedMessage id={error.id} values={error.values} />
        </div>
      )}
    </div>
  );
};

renderDropDown.propTypes = {
  input: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.bool,
  isTask: PropTypes.bool,
  meta: PropTypes.shape().isRequired,
  disabledOptions: PropTypes.arrayOf(PropTypes.shape())
};

renderDropDown.defaultProps = {
  disabled: false,
  isTask: false,
  disabledOptions: []
};

// csr select field
export class renderSelectField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null
    };
  }
  render() {
    const {
      id,
      label,
      input,
      meta: { touched, error },
      children,
      disabled
    } = this.props;

    return (
      <label htmlFor={id}>
        <div className="kv-container">
          <div
            className={classNames("kv-label", {
              "kv-label--error": touched && error
            })}
          >
            {label}
          </div>
          <div className="kv-children">
            <select className="user-role-radio" {...input} disabled={disabled}>
              {children}
            </select>
          </div>
        </div>
      </label>
    );
  }
}
renderSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  children: PropTypes.node.isRequired
};

// csr select field
export class renderSelectFieldMUI extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null
    };
  }
  render() {
    const {
      id,
      label,
      meta: { touched, error }
    } = this.props;

    return (
      <label htmlFor={id}>
        <div className="kv-container">
          <div
            className={classNames("kv-label", {
              "kv-label--error": touched && error
            })}
          >
            {label}
          </div>
          <div className="kv-children">
            <ActionDropdownMUI
              {...this.props}
              id={id}
              value={
                this.props.input.value.value
                  ? this.props.input.value.value
                  : this.props.input.value
              }
              // label={this.props.label}
              label={null}
              customStyle={this.props.customStyle}
              pullRight={false}
              onMenuItemClick={data => {
                this.props.input.onChange(data.key);
              }}
            />
            {/*  <select className="user-role-radio" {...input} disabled={disabled}>
              {children}
            </select> */}
          </div>
        </div>
      </label>
    );
  }
}

export class renderSearchableDropdown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null
    };
  }

  render() {
    const { selectedOption } = this.state;

    const {
      input,
      label,
      options,
      placeholder,
      meta: { touched, error }
    } = this.props;
    return (
      <div className="dropdown">
        <div
          style={{
            margin: label ? "10px" : 0
          }}
        >
          {label !== null && (
            <label className="content-item-title">{label}</label>
          )}
          <Select
            value={selectedOption || input.value}
            onChange={e => {
              this.setState({ selectedOption: e });
              input.onChange(e.value);
            }}
            options={options}
            placeholder={placeholder}
          />
          {touched && error && (
            <div className="custom-input-error">
              <FormattedMessage id={error.id} values={error.values} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

renderSearchableDropdown.propTypes = {
  input: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string
};

renderSearchableDropdown.defaultProps = {
  label: null,
  placeholder: undefined
};

// TODO merge with renderSearchableDropdown
// export class renderCSRSearchableDropdown extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       selectedOption: null
//     };
//   }

//   render() {
//     const { selectedOption } = this.state;

//     const {
//       input,
//       label,
//       options,
//       placeholder,
//       meta: { touched, error }
//     } = this.props;

//     const filterOptions = createFilterOptions({
//       options
//     });

//     return (
//       <div>
//         <label>
//           <div className="kv-container display-flex">
//             <div className="kv-label">{label}</div>
//             <div className="kv-children flex-zero">
//               <VirtualizedSelect
//                 value={selectedOption || input.value}
//                 onChange={e => {
//                   this.setState({ selectedOption: e });
//                   input.onChange(e ? e.value : "");
//                 }}
//                 filterOptions={filterOptions}
//                 options={options}
//                 placeholder={placeholder}
//                 className={classNames({
//                   "custom-field-error": touched && error
//                 })}
//               />
//             </div>
//           </div>
//           {touched && error && (
//             <div className="custom-input-error error-margin">
//               <FormattedMessage id={error.id} values={error.values} />
//             </div>
//           )}
//         </label>
//       </div>
//     );
//   }
// }

// renderCSRSearchableDropdown.propTypes = {
//   input: PropTypes.shape().isRequired,
//   options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
//   meta: PropTypes.shape().isRequired,
//   label: PropTypes.string
// };

// renderCSRSearchableDropdown.defaultProps = {
//   label: null,
//   placeholder: undefined
// };

export class renderSearchableDropdownMUI extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null
    };
  }

  render() {
    const { selectedOption } = this.state;

    const {
      id,
      input,
      label,
      options,
      placeholder,
      meta: { touched, error },
      customStyle,
      testId = ""
    } = this.props;
    const filterOptions = createFilterOptions({
      options
    });
    return (
      <div>
        <label>
          <div
            className="kv-container display-flex"
            style={!label ? { padding: 0 } : {}}
          >
            {label && (
              <label className="kv-label" htmlFor="virtualized-dropdown">
                {label}
              </label>
            )}
            <div className="kv-children flex-zero">
              <VirtualizedSelectDropdown
                value={selectedOption || input.value}
                testId={testId}
                id={id ? id : "virtualized-dropdown"}
                onChange={(e, newval) => {
                  this.setState({ selectedOption: e });
                  input.onChange(newval ? newval.value : "");
                }}
                filterOptions={filterOptions}
                options={options}
                placeholder={placeholder}
                customStyle={customStyle}
                className={classNames({
                  "custom-field-error": touched && error
                })}
              />
            </div>
          </div>
          {touched && error && (
            <div className="custom-input-error error-margin">
              <FormattedMessage id={error.id} values={error.values} />
            </div>
          )}
        </label>
      </div>
    );
  }
}

renderSearchableDropdownMUI.propTypes = {
  input: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string
};

renderSearchableDropdownMUI.defaultProps = {
  label: null,
  placeholder: undefined
};

//Manage Permissinons

export class renderSearchableDropdownMUIManagePermissions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null
    };
  }

  render() {
    const { selectedOption } = this.state;

    const {
      id,
      input,
      label,
      options,
      placeholder,
      meta: { touched, error },
      customStyle,
      testId = "",
      defaultValue = "",
      disabled
    } = this.props;
    const filterOptions = createFilterOptions({
      options
    });
    return (
      <div>
        <label>
          <div
            className="kv-container display-flex"
            style={!label ? { padding: 0 } : {}}
          >
            {label && (
              <label className="kv-label" htmlFor="virtualized-dropdown">
                {label}
              </label>
            )}
            <div className="kv-children flex-zero">
              <VirtualizedSelectDropdownManagePermissions
                value={selectedOption || input.value}
                testId={testId}
                id={id ? id : "virtualized-dropdown"}
                onChange={(e, newval) => {
                  this.setState({ selectedOption: e });
                  input.onChange(newval ? newval.value : "");
                }}
                filterOptions={filterOptions}
                options={options}
                placeholder={placeholder}
                customStyle={customStyle}
                className={classNames({
                  "custom-field-error": touched && error
                })}
                defaultValue={defaultValue}
                disabled={disabled}
              />
            </div>
          </div>
          {touched && error && (
            <div className="custom-input-error error-margin">
              <FormattedMessage id={error.id} values={error.values} />
            </div>
          )}
        </label>
      </div>
    );
  }
}

renderSearchableDropdownMUIManagePermissions.propTypes = {
  input: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string
};

renderSearchableDropdownMUIManagePermissions.defaultProps = {
  label: null,
  placeholder: undefined
};

//emdds

export class renderVirtualizedCustomizableDropdownMUI extends PureComponent {
  render() {
    const {
      id,
      input,
      options,
      placeholder,
      meta: { error },
      customStyle,
      selectedOption,
      setSelectedOption
    } = this.props;

    const filterOptions = createFilterOptions({
      options
    });

    return (
      <React.Fragment>
        <VirtualizedCustomizableDropdown
          value={selectedOption || input.value}
          id={id ? id : "virtualized-dropdown"}
          onChange={(e, newval) => {
            if (typeof newval === "string") {
              setSelectedOption({ label: newval });
            } else if (newval && newval.inputValue) {
              setSelectedOption({
                value: newval.inputValue,
                label: newval.inputValue
              });
              input.onChange(newval.inputValue);
            } else {
              setSelectedOption(newval);
              input.onChange(newval ? newval.label : "");
            }
          }}
          selectedOption={selectedOption}
          filterOptions={filterOptions}
          options={options}
          placeholder={placeholder}
          customStyle={customStyle}
          className={classNames({
            "custom-field-error": input.value && error
          })}
        />
        {input.value && error && (
          <div className="custom-input-error error-margin">
            <FormattedMessage id={error.id} values={error.values} />
          </div>
        )}
      </React.Fragment>
    );
  }
}

renderVirtualizedCustomizableDropdownMUI.propTypes = {
  input: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string
};

renderVirtualizedCustomizableDropdownMUI.defaultProps = {
  label: null,
  placeholder: undefined
};

//TODO fix name - use proper naming convention
export class renderInput extends PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }
  render() {
    const {
      input,
      // label,
      type,
      disabled,
      placeholders,
      meta: { touched, error },
      errorWidth = null,
      className,
      maxlength = "",
      inputWidth = null,
      autoFocus = undefined,
      additionalProps = {}
    } = this.props;
    let otherProps = { ...additionalProps };
    if (type === "number") {
      otherProps["onKeyDown"] = e => {
        // Prevent characters that are not numbers ("e", ".", "+" & "-")
        let isInvalid = false;
        if (e.key !== undefined) {
          isInvalid =
            e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
        } else if (e.keyCode !== undefined) {
          isInvalid =
            e.keyCode === 69 ||
            e.keyCode === 190 ||
            e.keyCode === 187 ||
            e.keyCode === 189;
        }
        return isInvalid && e.preventDefault();
      };
    }
    return (
      <div className={className}>
        <input
          {...input}
          {...otherProps}
          autoFocus={autoFocus}
          type={type}
          placeholder={placeholders}
          style={{ width: inputWidth }}
          maxLength={maxlength}
          id={input.name}
          disabled={disabled}
          onChange={e => input.onChange(e.target.value)}
          onBlur={e => {
            e.preventDefault();
          }}
          ref={this.textInput}
          autoComplete="new-password"
        />
        {touched && error && (
          <div
            className="custom-input-error"
            style={{
              width:
                errorWidth ||
                this.textInput.current.getBoundingClientRect().width ||
                "300px"
            }}
          >
            <FormattedMessage id={error.id} values={error.values} />
          </div>
        )}
      </div>
    );
  }
}
export class renderInputMUI extends PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }
  render() {
    const {
      input,
      // label,
      type,
      disabled,
      placeholders,
      meta: { touched, error },
      errorWidth = null,
      className,
      maxlength = "",
      inputWidth = null,
      autoFocus = undefined,
      additionalProps = {},
      preventDefaultOnBlur = true
      // hideFloatingLabel = true
    } = this.props;
    let otherProps = { ...additionalProps };
    if (type === "number") {
      otherProps["onKeyDown"] = e => {
        // Prevent characters that are not numbers ("e", ".", "+" & "-")
        let isInvalid = false;
        if (e.key !== undefined) {
          isInvalid =
            e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
        } else if (e.keyCode !== undefined) {
          isInvalid =
            e.keyCode === 69 ||
            e.keyCode === 190 ||
            e.keyCode === 187 ||
            e.keyCode === 189;
        }
        return isInvalid && e.preventDefault();
      };
    }
    return (
      <div className={className}>
        <TextField
          {...input}
          {...otherProps}
          autoFocus={autoFocus}
          type={type}
          placeholder={placeholders}
          label={null}
          style={{ width: inputWidth }}
          maxLength={maxlength}
          id={input.name}
          // hideFloatingLabel={hideFloatingLabel}
          disabled={disabled}
          onChange={e => input.onChange(e.target.value)}
          onBlur={
            preventDefaultOnBlur
              ? e => {
                  e.preventDefault();
                }
              : input.onBlur
          }
          sx={{
            "& .MuiOutlinedInput-root.Mui-disabled": {
              "& > fieldset": {
                backgroundColor: "rgba(0,0,0,0.12)",
                color: "rgba(0,0,0,0.26)"
              }
            }
          }}
          inputProps={{
            form: {
              autocomplete: "off"
            }
          }}
          ref={this.textInput}
          autoComplete="new-password"
        />
        {touched && error && (
          <div
            className="custom-input-error"
            style={{
              width:
                errorWidth ||
                this.textInput.current.getBoundingClientRect().width ||
                "300px"
            }}
          >
            <FormattedMessage id={error.id} values={error.values} />
          </div>
        )}
      </div>
    );
  }
}

export class renderPasswordInput extends PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }
  render() {
    const {
      input,
      // label,
      type,
      disabled,
      placeholders,
      meta: { touched, error },
      errorWidth = null,
      className,
      maxlength = "",
      inputWidth = null,
      autoFocus = undefined
    } = this.props;
    return (
      <div className={className}>
        <input
          {...input}
          autoFocus={autoFocus}
          type={type}
          placeholder={placeholders}
          style={{ width: inputWidth }}
          maxLength={maxlength}
          id={input.name}
          disabled={disabled}
          onChange={e => input.onChange(e.target.value)}
          ref={this.textInput}
          autoComplete="new-password"
        />
        {touched && error && (
          <div
            className="custom-input-error"
            style={{
              width:
                errorWidth ||
                this.textInput.current.getBoundingClientRect().width ||
                "300px"
            }}
          >
            <FormattedMessage id={error.id} values={error.values} />
          </div>
        )}
      </div>
    );
  }
}
export class renderCustomInput extends PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }
  render() {
    const {
      input,
      // label,
      type,
      disabled,
      placeholders,
      meta: { touched, error },
      errorWidth = null,
      className,
      maxlength = "",
      inputWidth = null,
      onKeyDown
    } = this.props;
    return (
      <div>
        <div className={className}>
          <input
            {...input}
            type={type}
            placeholder={placeholders}
            style={{ width: inputWidth }}
            maxLength={maxlength}
            id={input.name}
            onKeyDown={onKeyDown}
            disabled={disabled}
            onChange={e => input.onChange(e.target.value)}
            ref={this.textInput}
            autoComplete={type === "password" ? "off" : "on"}
          />
          {touched && error && (
            <div
              className="custom-input-error"
              style={{
                width:
                  errorWidth ||
                  this.textInput.current.getBoundingClientRect().width ||
                  "300px"
              }}
            >
              <FormattedMessage id={error.id} values={error.values} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

// csr input field column wise
export class renderInputField extends PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }
  render() {
    const {
      id,
      input,
      type,
      label,
      disabled,
      maxlength,
      placeholder,
      meta: { touched, error },
      additionalProps = {}
    } = this.props;
    // Prevent characters that are not numbers ("e", ".", "+" & "-")
    let otherProps = { ...additionalProps };
    if (type === "number") {
      otherProps["onKeyDown"] = e => {
        // Prevent characters that are not numbers ("e", ".", "+" & "-")
        let isInvalid = false;
        if (e.key !== undefined) {
          isInvalid =
            e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
        } else if (e.keyCode !== undefined) {
          isInvalid =
            e.keyCode === 69 ||
            e.keyCode === 190 ||
            e.keyCode === 187 ||
            e.keyCode === 189;
        }
        return isInvalid && e.preventDefault();
      };
    }
    return (
      <div>
        <label htmlFor={id}>
          <div className="kv-container display-flex">
            <div className="kv-label">{label}</div>
            <div className="kv-children flex-zero">
              <input
                {...input}
                {...otherProps}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                maxLength={maxlength}
                onChange={e => input.onChange(e.target.value)}
                // className={classNames({
                //   "custom-field-error": touched && error
                // })}
              />
            </div>
          </div>
          {touched && error && (
            <div className="custom-input-error error-margin">
              <FormattedMessage id={error.id} values={error.values} />
            </div>
          )}
        </label>
      </div>
    );
  }
}
renderInputField.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired
};

// csr input field column wise
export class renderInputFieldMUI extends PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }
  render() {
    const { id, type, label, additionalProps = {} } = this.props;
    // Prevent characters that are not numbers ("e", ".", "+" & "-")
    let otherProps = { ...additionalProps };
    if (type === "number") {
      otherProps["onKeyDown"] = e => {
        // Prevent characters that are not numbers ("e", ".", "+" & "-")
        let isInvalid = false;
        if (e.key !== undefined) {
          isInvalid =
            e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
        } else if (e.keyCode !== undefined) {
          isInvalid =
            e.keyCode === 69 ||
            e.keyCode === 190 ||
            e.keyCode === 187 ||
            e.keyCode === 189;
        }
        return isInvalid && e.preventDefault();
      };
    }
    return (
      <div>
        <label htmlFor={id}>
          <div className="kv-container display-flex">
            <div className="kv-label">{label}</div>
            <div className="kv-children flex-zero">
              <InputWithLabelMUI {...this.props} />
              {/* <input
                {...input}
                {...otherProps}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                maxLength={maxlength}
                onChange={e => input.onChange(e.target.value)}
                // className={classNames({
                //   "custom-field-error": touched && error
                // })}
              /> */}
            </div>
          </div>
        </label>
      </div>
    );
  }
}

export class renderInputWithLabel extends PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }
  render() {
    const {
      input,
      type,
      disabled,
      placeholders,
      inputWidth = null,
      meta: { touched, error }
    } = this.props;
    return (
      <div>
        <div style={{ margin: "10px" }}>
          {this.props.label !== null ? (
            <label className="content-item-title">{this.props.label}</label>
          ) : (
            ""
          )}
          <input
            {...input}
            type={type}
            placeholder={placeholders}
            id={input.name}
            disabled={disabled}
            onChange={e => input.onChange(e.target.value)}
            ref={this.textInput}
            style={{ float: "right", width: inputWidth }}
          />
          {touched && error && (
            <div
              className="custom-input-error"
              style={{
                width: this.textInput.current.getBoundingClientRect().width
              }}
            >
              <FormattedMessage id={error.id} values={error.values} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

// TODO: fix this component
export class renderCommaSeparatedInput extends PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }
  render() {
    const {
      input,
      // label,
      disabled,
      placeholders,
      meta: { touched, error }
    } = this.props;
    const value = Array.isArray(input.value) ? input.value.join(",") : "";

    return (
      <div>
        <div>
          <TextField
            {...input}
            type="text"
            value={value}
            placeholder={placeholders}
            id={input.name}
            disabled={disabled}
            onChange={e => {
              e.preventDefault();
              input.onChange(e.target.value.split(",").filter(v => v));
            }}
            onBlur={e => {
              e.preventDefault();
              input.onBlur(e.target.value.split(",").filter(v => v));
            }}
            ref={this.textInput}
          />
          {touched && error && (
            <div
              className="custom-input-error"
              style={{
                width: this.textInput.current.getBoundingClientRect().width
              }}
            >
              <FormattedMessage
                id={error.id}
                defaultMessage={error.id}
                values={error.values}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const renderCheckBox = ({
  input,
  label,
  type,
  defaultMessage,
  defaultMessageId,
  applyClass,
  checked,
  disabled
}) => {
  let checkBoxCss = applyClass ? applyClass : "";
  return (
    <div className={checkBoxCss}>
      <input
        checked={checked}
        {...input}
        type={type}
        placeholder={label}
        id={input.name}
        disabled={disabled}
        onChange={e => input.onChange(e.target.checked)}
      />
      <label htmlFor={input.name}>
        <FormattedMessage
          id={defaultMessageId}
          defaultMessage={defaultMessage}
        />
      </label>
    </div>
  );
};

export const renderPolicyCheckBoxMUI = ({
  input,
  defaultMessage,
  defaultMessageId,
  disabled
}) => {
  return (
    <FormControlLabel
      className="policy-mui-checkbox"
      control={
        <Checkbox
          size="small"
          checked={input.value ? true : false}
          id={input.name}
          disabled={disabled ? true : false}
          onChange={e => input.onChange(e.target.checked)}
        />
      }
      label={
        <FormattedMessage
          id={defaultMessageId}
          defaultMessage={defaultMessage}
        />
      }
    />
  );
};
export const renderCheckboxMUI = ({ input, label, id, testId, disabled }) => {
  let inputFieldId = id ? id : testId ? `${ID_PREFIX}${testId}` : "";
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={inputFieldId}
          size="small"
          checked={input.checked}
          onChange={input.onChange}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

export class renderEmailSelectorMUI extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.input.value ? this.props.input.value : []
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleTagClose = this.handleTagClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.input.value.length === 0 &&
      this.props.input.value !== prevProps.input.value
    ) {
      this.setState({
        options: this.props.input.value
      });
    }
  }

  handleAddClick(item) {
    let options = this.state.options;
    if (options) {
      if (options.indexOf(item) === -1) {
        options.push(item);
        this.props.input.onChange(options);
      }
    } else {
      options = [item];
      this.props.input.onChange(options);
    }
    this.setState({ options: [...options] });
  }

  handleTagClose(item) {
    let filteredOptions = this.state.options.filter(option => option !== item);
    this.setState({ options: filteredOptions });
    this.props.input.onChange(filteredOptions);
  }
  render() {
    let optionsToTags = this.state.options.map(item => ({
      label: item,
      value: item
    }));

    return (
      <InputMUI
        addClickHandler={this.handleAddClick}
        handleTagClose={this.handleTagClose}
        optionsToTags={optionsToTags}
        testId={this.props.testId}
        noEmailValidation={this.props.noEmailValidation}
        validator={VALIDATION_REGEX.EMAIL_VALIDATOR}
        customStyles={this.props.customStyles}
      />
    );
  }
}

export class renderSelectWithTagsMUI extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.options,
      optionsToTags: this.props.optionsToTags || [],
      customStyle: this.props.customStyle,
      label: this.props.label
    };
    this.addClickHandler = this.addClickHandler.bind(this);
    this.handleTagClose = this.handleTagClose.bind(this);
  }

  //TODO fix this
  componentDidUpdate(prevProps, prevState) {
    if (this.props.optionsToTags !== prevProps.optionsToTags) {
      this.setState({ optionsToTags: this.props.optionsToTags });
    }
    if (this.props.options !== prevProps.options) {
      this.setState({ options: this.props.options });
    }
    if (this.state.optionsToTags !== prevState.optionsToTags) {
      this.setState({
        ...this.state,
        optionsToTags: this.state.optionsToTags
      });
    }
    if (this.state.options !== prevState.options) {
      this.setState({
        ...this.state,
        options: this.state.options
      });
    }
  }
  addClickHandler(itemsArray) {
    let filteredOptions = differenceBy(this.state.options, itemsArray, "value");
    let tempOptionsToTags = this.state.optionsToTags.concat(itemsArray);
    this.setState({
      optionsToTags: tempOptionsToTags,
      options: filteredOptions
    });
    if (this.props.addClickHandler) {
      this.props.addClickHandler(itemsArray);
    }
    this.props.input.onChange(tempOptionsToTags);
  }

  handleTagClose(item) {
    let filteredOptionsToTags = this.state.optionsToTags.filter(
      optionsToTags => optionsToTags !== item
    );
    this.setState({
      optionsToTags: filteredOptionsToTags,
      options: this.state.options.concat(item)
    });
    if (this.props.handleTagClose) {
      this.props.handleTagClose(item);
    }
    this.props.input.onChange(filteredOptionsToTags);
  }
  render() {
    return (
      <SelectDropdownMUI
        options={this.state.options}
        addClickHandler={this.addClickHandler}
        handleTagClose={this.handleTagClose}
        optionsToTags={this.state.optionsToTags}
        customStyle={this.state.customStyle}
        label={this.state.label}
        testId={this.props.testId}
        loading={this.props.loading}
      />
    );
  }
}

export class renderSelectWithTags extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.options,
      optionsToTags: this.props.optionsToTags || []
    };
    this.addClickHandler = this.addClickHandler.bind(this);
    this.handleTagClose = this.handleTagClose.bind(this);
  }

  //TODO fix this
  componentDidUpdate(prevProps, prevState) {
    if (this.props.optionsToTags !== prevProps.optionsToTags) {
      this.setState({ optionsToTags: this.props.optionsToTags });
    }
    if (this.props.options !== prevProps.options) {
      this.setState({ options: this.props.options });
    }
    if (this.state.optionsToTags !== prevState.optionsToTags) {
      this.setState({
        ...this.state,
        optionsToTags: this.state.optionsToTags
      });
    }
    if (this.state.options !== prevState.options) {
      this.setState({
        ...this.state,
        options: this.state.options
      });
    }
  }
  addClickHandler(itemsArray) {
    let filteredOptions = differenceBy(this.state.options, itemsArray, "value");
    let tempOptionsToTags = this.state.optionsToTags.concat(itemsArray);
    this.setState({
      optionsToTags: tempOptionsToTags,
      options: filteredOptions
    });
    this.props.input.onChange(tempOptionsToTags);
  }

  handleTagClose(item) {
    let filteredOptionsToTags = this.state.optionsToTags.filter(
      optionsToTags => optionsToTags !== item
    );
    this.setState({
      optionsToTags: filteredOptionsToTags,
      options: this.state.options.concat(item)
    });
    this.props.input.onChange(filteredOptionsToTags);
  }
  render() {
    return (
      <SelectDropdown
        options={this.state.options}
        addClickHandler={this.addClickHandler}
        handleTagClose={this.handleTagClose}
        optionsToTags={this.state.optionsToTags}
      />
    );
  }
}
export default {};

/* eslint-disable no-case-declarations */
import { combineReducers } from "redux";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_SOURCES_HYPER_VMWARE_CONNECT_SUCCESS,
  ADD_SOURCES_HYPERVISOR_CONNECT_SUCCESS,
  ADD_SOURCES_HYPER_VMWARE_CONNECT,
  ADD_SOURCES_HYPER_CONNECT_ERROR,
  RESET_ADD_SOURCES_HYPER,
  ADD_SOURCES_HYPER_SAVE_TO_LIST,
  ADD_SOURCES_HYPERVISOR_SAVE_TO_LIST,
  ADD_SOURCES_HYPER_DELETE_SELECTION,
  ADD_SOURCES_HYPER_SELECT_SITE,
  ADD_SOURCES_HYPER_HYPERV_CONNECT_SUCCESS,
  ADD_SOURCES_HYPER_NUTANIX_CONNECT_SUCCESS,
  ADD_SOURCES_HYPER_HYPERV_CONNECT,
  ADD_SOURCES_HYPER_NUTANIX_CONNECT,
  ADD_SOURCES_SET_HYPERVISOR_ID,
  CLEAR_HYPER_FORM,
  CLEAR_ERROR_MESSAGE,
  VMWARE_CONNECTION_DETAILS_CHANGED,
  VMWARE_SERVER_TYPE_SUCCESS,
  GET_VMWARE_SERVER_TYPE,
  INITIALIZE_APP
} from "state/actions/actionTypes";

/**
 * Constants for Left Side Panel of the Dialog
 */
export const LeftPanel = {
  CONFIG_FORM: "CONFIG_FORM",
  VMWARE_TREE: "VMWARE_TREE",
  HYPERV_TREE: "HYPERV_TREE",
  HYPERVISOR_GRID: "HYPERVISOR_GRID"
};

const hypervisorConnectResp = (state = null, action) => {
  switch (action.type) {
    case ADD_SOURCES_HYPERVISOR_CONNECT_SUCCESS:
      const listArr = action.data.map(hv => {
        return {
          ...hv,
          _id: `_${hv.source_id}`,
          id: hv.source_id,
          _uuid: uuidv4()
        };
      });
      return [...listArr];
    case CLEAR_HYPER_FORM:
      return [];
    default:
      return state;
  }
};
const vmwareConnectResp = (state = null, action) => {
  switch (action.type) {
    case ADD_SOURCES_HYPER_VMWARE_CONNECT_SUCCESS:
      return addUniqueIdToNodes(action.data);
    case RESET_ADD_SOURCES_HYPER:
      return null;
    default:
      return state;
  }
};
const hypervConnectResp = (state = null, action) => {
  switch (action.type) {
    // eslint-disable-next-line no-case-declarations
    case ADD_SOURCES_HYPER_HYPERV_CONNECT_SUCCESS:
      return addUniqueIdToNodes(action.data);
    case CLEAR_HYPER_FORM:
      return [];
    default:
      return state;
  }
};
const nutanixConnectResp = (state = null, action) => {
  switch (action.type) {
    // eslint-disable-next-line no-case-declarations
    case ADD_SOURCES_HYPER_NUTANIX_CONNECT_SUCCESS:
      return addUniqueIdToNodes(action.data);
    case CLEAR_HYPER_FORM:
      return [];
    default:
      return state;
  }
};
const isHyper = (state = null, action) => {
  switch (action.type) {
    // eslint-disable-next-line no-case-declarations
    case ADD_SOURCES_HYPER_HYPERV_CONNECT_SUCCESS:
      return true;
    case ADD_SOURCES_HYPER_NUTANIX_CONNECT_SUCCESS:
      return false;
    default:
      return state;
  }
};

/**
 * Set unique id for the tree of data
 * @param {*} node
 */
const addUniqueIdToNodes = node => {
  if (Array.isArray(node.children) === false) {
    return { ...node, _uuid: uuidv4() };
  } else {
    return {
      ...node,
      _uuid: uuidv4(),
      children: node.children.map(addUniqueIdToNodes)
    };
  }
};

const leftPanel = (state = LeftPanel.CONFIG_FORM, action) => {
  switch (action.type) {
    case ADD_SOURCES_HYPERVISOR_CONNECT_SUCCESS:
      return LeftPanel.HYPERVISOR_GRID;
    case ADD_SOURCES_HYPER_VMWARE_CONNECT_SUCCESS:
      return LeftPanel.VMWARE_TREE;
    case RESET_ADD_SOURCES_HYPER:
      return LeftPanel.CONFIG_FORM;
    case ADD_SOURCES_HYPER_HYPERV_CONNECT_SUCCESS:
      return LeftPanel.HYPERV_TREE;
    case ADD_SOURCES_HYPER_NUTANIX_CONNECT_SUCCESS:
      return LeftPanel.HYPERV_TREE;
    case CLEAR_HYPER_FORM:
      return LeftPanel.CONFIG_FORM;
    default:
      return state;
  }
};

export const RightPanel = {
  NO_RECORDS: "NO_RECORDS",
  VM_GRID: "VM_GRID",
  HYPERV_GRID: "HYPERV_GRID"
};

const rightPanel = (state = RightPanel.NO_RECORDS, action) => {
  switch (action.type) {
    case ADD_SOURCES_HYPERVISOR_SAVE_TO_LIST:
      return RightPanel.HYPERV_GRID;
    case ADD_SOURCES_HYPER_SAVE_TO_LIST:
      return RightPanel.VM_GRID;
    case RESET_ADD_SOURCES_HYPER:
      return RightPanel.NO_RECORDS;
    case CLEAR_HYPER_FORM:
      return RightPanel.NO_RECORDS;
    default:
      return state;
  }
};

/**
 * Errors occured during Connect API call to LBS is maintained here
 * @param {*} state
 * @param {*} action
 */
const connectErrors = (state = [], { type, data }) => {
  switch (type) {
    case CLEAR_ERROR_MESSAGE:
      return [];
    case ADD_SOURCES_HYPER_VMWARE_CONNECT:
      return [];
    case ADD_SOURCES_HYPER_HYPERV_CONNECT:
      return [];
    case ADD_SOURCES_HYPER_NUTANIX_CONNECT:
      return [];
    case GET_VMWARE_SERVER_TYPE:
      return [];
    case ADD_SOURCES_HYPER_CONNECT_ERROR:
      return data;

    case RESET_ADD_SOURCES_HYPER:
      return [];

    default:
      return state;
  }
};

// const vmwareSelectedVms = (state = [], { type, data, uuid }) => {
//   switch (type) {
//     case ADD_SOURCES_HYPER_VMWARE_SAVE_TO_LIST:
//       return data.map(vm => ({ ...vm, uuid: uuidv4() }));
//     case RESET_ADD_SOURCES_HYPER_VMWARE:
//       return [];
//     case ADD_SOURCES_HYPER_VMWARE_DELETE_SELECTION:
//       return state.filter(vm => vm.uuid !== uuid);
//     default:
//       return state;
//   }
// };

/**
 * Selected VMs reducer
 * Data should be an array of objects with following fields
 *  - vm_name
 *  - vm_uuid
 *  - windows_os (boolean)
 *  - hypervisor_id
 *  - username (optional)
 *  - password (optional)
 *  - vm_hostname
 *  - vm_instance_uuid
 *  - vm_esx_host
 *  - vm_esx_socket_count
 *  - vm_xpath
 *  - vm_guest_os
 *  - vm_ip
 *  - global_id_for_webservice
 * @param {*} state
 * @param {*} data
 */
const selectedVms = (state = [], { type, data, uuid }) => {
  switch (type) {
    case ADD_SOURCES_HYPER_SAVE_TO_LIST:
      return [
        ...state,
        ...data
          .filter(
            vm =>
              state.filter(existingVm => existingVm._uuid === vm._uuid)
                .length === 0
          )
          .map(vm => (vm._uuid ? vm : { ...vm, _uidd: uuidv4() }))
      ];
    case ADD_SOURCES_HYPERVISOR_SAVE_TO_LIST:
      return [
        ...state,
        ...data
          .filter(
            vm =>
              state.filter(existingVm => existingVm._uuid === vm._uuid)
                .length === 0
          )
          .map(vm => (vm._uuid ? vm : { ...vm, _uidd: uuidv4() }))
      ];
    case RESET_ADD_SOURCES_HYPER:
      return [];
    case ADD_SOURCES_HYPER_DELETE_SELECTION:
      return state.filter(vm => vm._uuid !== uuid);
    case CLEAR_HYPER_FORM:
      return [];
    default:
      return state;
  }
};

const selectedSite = (state = null, { type, name, id }) => {
  switch (type) {
    case ADD_SOURCES_HYPER_SELECT_SITE:
      return { name, id };
    case RESET_ADD_SOURCES_HYPER:
      return null;
    case CLEAR_HYPER_FORM:
      return null;
    default:
      return state;
  }
};

const hypervisorId = (state = null, { type, id }) => {
  switch (type) {
    case ADD_SOURCES_SET_HYPERVISOR_ID:
      return id;
    case RESET_ADD_SOURCES_HYPER:
      return null;
    default:
      return state;
  }
};

const vmwareServerType = (state = null, { type, serverType }) => {
  switch (type) {
    case VMWARE_CONNECTION_DETAILS_CHANGED:
    case RESET_ADD_SOURCES_HYPER:
    case INITIALIZE_APP:
      return null;
    case VMWARE_SERVER_TYPE_SUCCESS:
      return serverType;

    default:
      return state;
  }
};

export default combineReducers({
  hypervisorConnectResp,
  vmwareConnectResp,
  hypervConnectResp,
  nutanixConnectResp,
  isHyper,
  leftPanel,
  rightPanel,
  connectErrors,
  selectedVms,
  selectedSite,
  hypervisorId,
  vmwareServerType
});

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { MenuTabMUI } from "../common/tab";
import {
  clearStateSelectedRows,
  clearResourceId
} from "../../state/actions/actions";
import { getLogin } from "state/selectors";

class SecondaryNavigator extends Component {
  constructor(props) {
    super(props);
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick(clickable, title, key) {
    this.props.dispatch(clearStateSelectedRows());
    this.props.dispatch(clearResourceId());
    if (clickable) {
      this.props.handleSelectFilter(title.toLowerCase(), key);
    }
  }

  renderLinks(item, menu, index) {
    //eslint-disable-next-line eqeqeq
    let clickable = item.clickable == undefined ? true : item.clickable;
    let displayCount =
      //eslint-disable-next-line eqeqeq
      item.displayCount == undefined ? true : item.displayCount;
    let routeLink = "";

    // To handle sites/proxies url in configure tab
    if (item.name === "Sites") {
      routeLink += `/${menu
        .toLowerCase()
        .replace(/\s/g, "")}/${item.parentKey
        .toLowerCase()
        .replace(/\s/g, "")}/${item.key.toLowerCase().replace(/\s/g, "")}/all`;
    } else if (clickable && item.isFilterType) {
      routeLink += `/${menu
        .toLowerCase()
        .replace(/\s/g, "")}/${item.parentKey
        .toLowerCase()
        .replace(/\s/g, "")}/${item.key.toLowerCase().replace(/\s/g, "")}`;
    } else if (clickable && item.parentKey === "configure") {
      routeLink += `/${menu
        .toLowerCase()
        .replace(/\s/g, "")}/${item.key.toLowerCase().replace(/\s/g, "")}`;
    } else if (clickable) {
      routeLink += `/${menu
        .toLowerCase()
        .replace(/\s/g, "")}/${item.key.toLowerCase().replace(/\s/g, "")}/all`;
    }

    return item.isFilterType ? (
      <MenuTabMUI
        routeLink={routeLink}
        title={item.name}
        key={index}
        menuKey={item.key}
        displayCount={displayCount}
        clickable={clickable}
        className="d-flex justify-content-between align-items-center"
        onClick={this.handleTabClick.bind(item.name, clickable, item.key)}
        amount={item.amount}
      />
    ) : (
      <MenuTabMUI
        routeLink={routeLink}
        title={item.name}
        key={index}
        menuKey={item.key}
        displayCount={displayCount}
        clickable={clickable}
        className="submenu-header d-flex justify-content-between align-items-center"
        onClick={this.handleTabClick.bind(item.name, clickable, item.key)}
        amount={item.amount}
      />
    );
  }

  render() {
    let showNavigator = this.props.collapseSideBar
      ? "collapse"
      : "collapse show";
    return this.props.menu.map(menu =>
      menu.name === this.props.primaryNavigation && menu.subMenu.length > 0 ? (
        <ul key={menu.id} className={"sub-menu-panel " + showNavigator}>
          {menu.subMenu.map((submenu, index) =>
            this.props.organizationId ===
              "78506751-cc21-4989-a1f4-d7019df22a9f" &&
            (submenu.key === "recovered_resources" ||
              submenu.parentKey === "recovered_resources")
              ? null
              : this.props.organizationId !==
                  "78506751-cc21-4989-a1f4-d7019df22a9f" &&
                submenu.key === "shadow_xafe"
              ? null
              : this.renderLinks(submenu, menu.name, index)
          )}
        </ul>
      ) : (
        <div key={menu.id} />
      )
    );
  }
}

SecondaryNavigator.defaultProps = {
  collapseSideBar: false,
  menu: []
};

SecondaryNavigator.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSelectFilter: PropTypes.func.isRequired,
  collapseSideBar: PropTypes.bool,
  menu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  primaryNavigation: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    organizationId: getLogin(state).organization_id
  };
};

export default connect(mapStateToProps, null)(SecondaryNavigator);

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isNumber } from "util";
import store from "state/store";
import { injectIntl } from "react-intl";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import Radio from "@mui/material/Radio";
import DataGridConstants from "./DataGridConstants";
import Icon from "../../../assets/icons/icons";
import ActionDropdown from "../action-dropdown";
import ActionDropdownMUI from "../action-dropdown-button-mui/index";
import ActionSelectDropdown from "../action-dropdown-mui";
import IconMUI from "components/common/icon/IconMUI";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HeaderDropdown from "./HeaderDropdown";
import Overlay from "../overlay";
import FormattedMessage, { FormattedHTMLMessage } from "../formatted-message";
import FormattedBrandMessage from "components/common/FormattedBrandMessage";
import Button from "components/common/button";
import ProgressBarComponent from "../progress-bar";
import ProgressBarComponentMUI from "../progress-bar-mui";
import ProgressBarLinkComponentMUI from "../progress-bar-link-mui";
import ProfileImgUrl from "../../../assets/images/Profile_icon.png";
import SelectDropdown from "../../common/select-dropdown/index";
import { Icons } from "../../protect/destination/data-store/browse/recovery-points/pages/components/BrowseConstants";
import Select from "react-select";
import {
  impersonateCustomer,
  updateCurrentSavedSearchName,
  clearAllSearchTags,
  dismissAlert,
  getBrandingDetails,
  selectFilterAction,
  getAlertList
} from "state/actions/actions";
import { isMspMonitorUser, isMspAccountMonitorUser } from "utils";
import TextField from "@mui/material/TextField";
import {
  JobNameMapping,
  JobStatus,
  PAGE_ROUTE,
  ACTION_TYPE,
  RECOVERD_VMS_LOADING_STATES,
  CD_RECOVER_POINT_BROWSE_CURRENT_RP_NAME,
  PROXY_ROLES,
  SECONDARY_NAVIGATION_TYPE,
  JOB_TYPES_HIDE_PROGRESS_BAR
} from "../../../utils/appConstants";
import { DARK_MODE, MUI_INITIAL_THEME } from "../../../utils/theme";
import { getLocaleDate, formatBytes } from "../../../utils/SpogDataGridUtil";
import { IconMapping } from "./IconConstants";
import { useSelector } from "react-redux";

const GridColumnCellTypes = DataGridConstants.GridColumnCellTypes;
const GridColumnHeaderTypes = DataGridConstants.GridColumnHeaderTypes;
const OverlayTypes = DataGridConstants.OverlayTypes;
const GridColumnCellWidth = DataGridConstants.GridColumnCellWidth;
const GridColumnStatusTypes = DataGridConstants.GridColumnStatusTypes;

/**
 * Common helper to build & render data-grid definitions
 */
const DataGridHelper = {
  /**
   * get the pagination object
   * if pagination is present in data fetched from back end, use this else use default pagination from state
   */
  getPaginationObject(dataObj, gridCurrentPage, gridPageSize) {
    const paginationObj = dataObj && dataObj.pagination;
    if (paginationObj) {
      gridCurrentPage = paginationObj.curr_page;
      gridPageSize = paginationObj.page_size;
    }
    const pagination = { gridCurrentPage, gridPageSize };
    return pagination;
  },

  /**
   * Returns columnData with displayIdentifier key for i18n support
   * @param columnConfigurations
   * @param {[{accessor: string, displayIdentifier: string, renderHeader: {}, renderRecord: {}, settings: {}]} columnSpecifications
   * @returns {*} columnConfigurations
   */
  getColumnData(columnConfigurations, columnSpecifications) {
    columnConfigurations &&
      columnConfigurations.forEach(column => {
        column["displayIdentifier"] = this.getColumnDefinition(
          columnSpecifications,
          column.identifier
        )
          ? this.getColumnDefinition(columnSpecifications, column.identifier)
              .displayIdentifier
          : column.identifier;
      });
    return columnConfigurations;
  },

  /**
   * Returns the column definition for requested identifier.
   * @param {*} columnData
   * @param {*} columnIdentifier
   */
  getColumnDefinition(columnData, columnIdentifier) {
    return columnData.find(columnDefinition => {
      return columnDefinition.accessor === columnIdentifier;
    });
  },

  getCCDataGridColumnDefinitions(columnConfigurations, columnData) {
    let columnDefinitions = [];
    columnConfigurations
      .filter(column => column.show)
      .forEach(column => {
        let columnObj = this.getColumnDefinition(columnData, column.identifier);
        if (columnObj) {
          if (column.width) {
            columnObj.settings.width = parseInt(column.width);
          }
          if (
            column.sort !== null &&
            column.sort !== undefined &&
            columnObj.settings
          ) {
            columnObj.settings.sortable = column.sort;
          }
          let t = this.buildColumnRenderSchema(
            column,
            this.getColumnDefinition(columnData, column.identifier),
            {
              sortHandler: this.sortHandler
            }
          );
          columnDefinitions.push(t);
        }
      });
    return columnDefinitions;
  },

  /**
   * Get column definitions to be rendered by {@link SpogDataGrid}
   * @param {*} columnConfigurations
   * @param {[{accessor: string, displayIdentifier: string, renderHeader: {}, renderRecord: {}, settings: {}]} columnSpecifications
   * @param {sortHandler:()=>{}, actionsHandler:()=>{}} callback
   */
  getColumnDefinitions(columnConfigurations, columnSpecifications, callback) {
    let columnDefinitions = [];
    columnConfigurations &&
      columnConfigurations
        .filter(column => column.show)
        .forEach(column => {
          let columnDef = cloneDeep(
            this.getColumnDefinition(columnSpecifications, column.identifier)
          );
          if (columnDef) {
            if (column.width && columnDef.settings) {
              columnDef.settings.width = parseInt(column.width);
            }
            if (columnDef.settings) {
              columnDef.settings.sortable = column.sort;
              columnDef.settings.disableSortBy = column.sort === false;
            }
            columnDefinitions.push(
              this.buildColumnRenderSchema(column, columnDef, callback)
            );
          }
        });
    return columnDefinitions;
  },

  /**
   * Builds the column rendering schema for requested definition
   * @param {*} columnData
   * @param {*} columnDefinition
   * @param {*} columnDefinition
   * @param {sortHandler:()=>{}, actionsHandler:()=>{}} callback
   *
   */
  buildColumnRenderSchema(columnData, columnDefinition, callback) {
    const columnHeader = renderHeaderForType(
      columnDefinition.renderHeader.type,
      {
        value: columnData.identifier,
        displayIdentifier: columnDefinition.displayIdentifier,
        defaultDisplayMessage: columnDefinition.defaultDisplayMessage,
        addtionalOptions: columnDefinition.renderHeader.additionOptions
          ? columnDefinition.renderHeader.additionOptions
          : [],
        sortCallback: columnDefinition.renderHeader.customSort
          ? callback["sortHandler"]
          : undefined
      }
    );
    const columnCell = renderCellForType(columnDefinition.renderRecord.type, {
      sortCallback: columnDefinition.renderRecord.actionDropdown
        ? callback
          ? callback["actionsHandler"]
          : undefined
        : undefined,
      editInputCallback: columnDefinition.renderRecord.editInput
        ? callback
          ? callback["editInputHandler"]
          : undefined
        : undefined,
      editDropdownCallback: columnDefinition.renderRecord.editDropdown
        ? callback["editDropdownHandler"]
        : undefined,
      multiSelectCallback: columnDefinition.renderRecord.multiSelect
        ? callback["multiSelectHandler"]
        : undefined,
      radioButtonCallback: columnDefinition.renderRecord.radioSelected
        ? callback["radioButtonSelectHandler"]
        : undefined,
      buttonActionCallback: columnDefinition.renderRecord.buttonAction
        ? callback["buttonActionsHandler"]
        : undefined,
      showCursorPointer: columnDefinition.showCursorPointer ? true : false
    });

    const columnCellWidth = setCellWidthForType(
      columnDefinition.renderRecord.type
    );
    const columnHeaderClass = setClassForHeaderType(
      columnDefinition.renderHeader.type
    );
    const columnSortClass = setClassForHeaderType(
      columnData.sort ||
        (columnDefinition.settings && columnDefinition.settings.sortable)
        ? "CHECKSORTTRUE"
        : "SORTFALSE"
    );

    if (columnHeader && columnCell) {
      return {
        id: columnData.identifier,
        accessor: columnDefinition.accessor,
        Header: columnHeader,
        sortMethod:
          columnDefinition.settings && columnDefinition.settings.sortMethod
            ? columnDefinition.settings.sortMethod
            : () => columnDefinition.accessor,
        Cell: columnCell,
        ...columnCellWidth,
        ...columnHeaderClass,
        ...columnDefinition.settings,
        ...columnSortClass
      };
    } else {
      return null;
    }
  },

  /**
   * Builds a column rendering schema for grid row select
   */
  buildColumnRenderSchemaForRowSelect() {
    const columnData = {
      identifier: "checkbox"
    };
    const columnDefinition = {
      accessor: "checkbox",
      renderHeader: {
        type: GridColumnHeaderTypes.HEADER_SELECT
      },
      renderRecord: {
        type: GridColumnCellTypes.CHECKBOX_CELL
      },
      settings: {
        minWidth: 50,
        resizable: false,
        sortable: false,
        headerClassName: "justify-content-center",
        className: "text-center"
      }
    };
    return this.buildColumnRenderSchema(columnData, columnDefinition);
  }
};

/**
 * Returns a {@link Component} or a function to be rendered/invoked
 * for requested cell type.
 * @param {*} type
 * @param {*} data
 */
const renderCellForType = (type, data) => {
  switch (type) {
    case GridColumnCellTypes.CHECKBOX_CELL:
      return renderCellForCheckbox(data);
    case GridColumnCellTypes.CHECKBOX_CELL_MUI:
      return renderCellForCheckboxMUI(data);
    case GridColumnCellTypes.ICON_CELL:
      return renderCellForIcon(data);
    case GridColumnCellTypes.ICON_CELL_MUI:
      return renderCellForIconMUI(data);
    case GridColumnCellTypes.ICON_CELL_MUI_WITHOUT_OVERLAY:
      return renderCellForIconMUIWithoutOverlay(data);
    case GridColumnCellTypes.LINK_CELL:
      return renderCellForLink(data);
    case GridColumnCellTypes.LINK_CELL_MUI:
      return renderCellForLinkMUI(data);
    case GridColumnCellTypes.LINK_CELL_WITH_OVERLAY_MUI:
      return renderCellForLinkWithOverlayMUI(data);
    case GridColumnCellTypes.LINK_CELL_WITH_ICON:
      return renderCellForLinkWithIcon(data);
    case GridColumnCellTypes.LINK_CELL_WITH_ICON_MUI:
      return renderCellForLinkWithIconMUI(data);
    case GridColumnCellTypes.EXTERNAL_LINK_CELL:
      return renderCellForExternalLink(data);
    case GridColumnCellTypes.EXTERNAL_LINK_CELL_MUI:
      return renderCellForExternalLinkMUI(data);
    case GridColumnCellTypes.OVERLAY_CELL:
      return renderCellForOverlay(data);
    case GridColumnCellTypes.OVERLAY_CELL_MUI:
      return renderCellForOverlayMUI(data);
    case GridColumnCellTypes.SHORT_TEXT_CELL:
      return renderCellForShortText(data);
    case GridColumnCellTypes.SHORT_TEXT_CELL_MUI:
      return renderCellForShortTextMUI(data);
    case GridColumnCellTypes.NUMERICAL_TEXT_CELL:
      return renderCellForNumericalText(data);
    case GridColumnCellTypes.NUMERICAL_TEXT_CELL_MUI:
      return renderCellForNumericalTextMUI(data);
    case GridColumnCellTypes.LONG_TEXT_CELL:
      return renderCellForLongText(data);
    case GridColumnCellTypes.LONG_TEXT_CELL_MUI:
      return renderCellForLongTextMUI(data);
    case GridColumnCellTypes.HOVER_CELL:
      return renderCellForHoverText(data);
    case GridColumnCellTypes.HOVER_CELL_MUI:
      return renderCellForHoverTextMUI(data);
    case GridColumnCellTypes.ACTION_DROPDOWN_CELL:
      return renderCellForActionDropdown(data);
    case GridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI:
      return renderCellForActionDropdownMUI(data);
    case GridColumnCellTypes.ACTION_SELECT_DROPDOWN_CELL_MUI:
      return renderCellForActionSelectDropdownMUI(data);
    case GridColumnCellTypes.ICON_TEXT_CELL:
      return renderCellForIconText(data);
    case GridColumnCellTypes.ICON_TEXT_CELL_MUI:
      return renderCellForIconTextMUI(data);
    case GridColumnCellTypes.MULTILINE_TEXT_CELL:
      return renderCellForMultilineText(data);
    case GridColumnCellTypes.MULTILINE_TEXT_CELL_MUI:
      return renderCellForMultilineTextMUI(data);
    case GridColumnCellTypes.TWOLINE_TEXT_CELL:
      return renderCellForTwolineText(data);
    case GridColumnCellTypes.TWOLINE_TEXT_CELL_MUI:
      return renderCellForTwolineTextMUI(data);
    case GridColumnCellTypes.PROGRESS_TEXT_CELL:
      return renderCellForProgressText(data);
    case GridColumnCellTypes.PROGRESS_TEXT_CELL_MUI:
      return renderCellForProgressTextMUI(data);
    case GridColumnCellTypes.PROGRESS_TEXT_CELL_WITHOUT_PERCENTAGE_MUI:
      return renderCellForProgressTextWithoutPercentageMUI(data);
    case GridColumnCellTypes.DATE_TIME_CELL:
      return renderCellForDateTime(data);
    case GridColumnCellTypes.DATE_TIME_CELL_MUI:
      return renderCellForDateTimeMUI(data);
    case GridColumnCellTypes.MULTI_TYPE:
      return renderCellForMutiType(data);
    case GridColumnCellTypes.MULTI_TYPE_MUI:
      return renderCellForMutiTypeMUI(data);
    case GridColumnCellTypes.IMAGE_CELL:
      return renderCellForImage(data);
    case GridColumnCellTypes.IMAGE_CELL_MUI:
      return renderCellForImageMUI(data);
    case GridColumnCellTypes.EDIT_DROPDOWN_CELL:
      return renderCellForEditDropdown(data);
    case GridColumnCellTypes.EDIT_DROPDOWN_CELL_MUI:
      return renderCellForEditDropdownMUI(data);
    case GridColumnCellTypes.MULTI_SELECT_DROPDOWN_CELL:
      return renderCellForMultiSelectDropdown(data);
    case GridColumnCellTypes.MULTI_SELECT_DROPDOWN_CELL_MUI:
      return renderCellForMultiSelectDropdownMUI(data);
    case GridColumnCellTypes.ID_VALUE_CELL:
      return renderCellForIdValue(data);
    case GridColumnCellTypes.ID_VALUE_CELL_MUI:
      return renderCellForIdValueMUI(data);
    case GridColumnCellTypes.SINGLE_SELECT_DROPDOWN_CELL:
      return renderCellForSingleSelectDropdown(data);
    case GridColumnCellTypes.SINGLE_SELECT_DROPDOWN_CELL_MUI:
      return renderCellForSingleSelectDropdownMUI(data);
    case GridColumnCellTypes.SINGLE_SELECT_SCROLL_DROPDOWN_CELL:
      return renderCellForSingleScrollSelectDropdown(data);
    case GridColumnCellTypes.SINGLE_SELECT_SCROLL_DROPDOWN_CELL_MUI:
      return renderCellForSingleScrollSelectDropdownMUI(data);
    case GridColumnCellTypes.INPUT_CELL:
      return renderCellForInput(data);
    case GridColumnCellTypes.INPUT_CELL_MUI:
      return renderCellForInputMUI(data);
    case GridColumnCellTypes.PORT_INPUT_CELL_MUI:
      return renderCellForPortInputMUI(data);
    case GridColumnCellTypes.RADIO_BUTTON_CELL:
      return renderCellForRadioButton(data);
    case GridColumnCellTypes.RADIO_BUTTON_CELL_MUI:
      return renderCellForRadioButtonMUI(data);
    case GridColumnCellTypes.EMPTY_TEXT_CELL:
      return renderCellForEmptyText(data);
    case GridColumnCellTypes.EMPTY_TEXT_CELL_MUI:
      return renderCellForEmptyTextMUI(data);
    case GridColumnCellTypes.LATEST_JOB_LINK_CELL:
      return renderCellForLatestJob(data);
    case GridColumnCellTypes.LATEST_JOB_LINK_CELL_MUI:
      return renderCellForLatestJobMUI(data);
    case GridColumnCellTypes.LATEST_JOB_TEXT_CELL:
      return renderCellForLatestJob(data, true);
    case GridColumnCellTypes.LATEST_JOB_TEXT_CELL_MUI:
      return renderCellForLatestJobMUI(data, true);
    case GridColumnCellTypes.RENDER_DESTINATION_OVERLAY_CELL:
      return renderCellForDestinationOverlay(data);
    case GridColumnCellTypes.RENDER_DESTINATION_OVERLAY_CELL_MUI:
      return renderCellForDestinationOverlayMUI(data);
    case GridColumnCellTypes.RENDER_POLICY_OVERLAY_CELL:
      return renderCellForPolicyOverlay(data);
    case GridColumnCellTypes.RENDER_POLICY_OVERLAY_CELL_MUI:
      return renderCellForPolicyOverlayMUI(data);
    case GridColumnCellTypes.RENDER_POLICY_OVERLAY_CELL_WITHOUT_LINK:
      return renderCellForImpersonatePolicyOverlay(data);
    case GridColumnCellTypes.RENDER_POLICY_OVERLAY_CELL_WITHOUT_LINK_MUI:
      return renderCellForImpersonatePolicyOverlayMUI(data);
    case GridColumnCellTypes.RENDER_ACCOUNTS_OVERLAY_CELL_WITHOUT_LINK_MUI:
      return renderCellForImpersonateAccountsOverlayMUI(data);
    case GridColumnCellTypes.RENDER_ROLE_OVERLAY_CELL:
      return renderCellForRoleOverlay(data);
    case GridColumnCellTypes.RENDER_ROLE_OVERLAY_CELL_MUI:
      return renderCellForRoleOverlayMUI(data);
    case GridColumnCellTypes.WRAPPED_CELL:
      return renderCellForWrappedText(data);
    case GridColumnCellTypes.WRAPPED_CELL_MUI:
      return renderCellForWrappedTextMUI(data);
    case GridColumnCellTypes.WRAPPED_CELL_WITH_HYPERLINK:
      return renderCellForWrappedTextWithHyperlink(data);
    case GridColumnCellTypes.WRAPPED_CELL_WITH_HYPERLINK_MUI:
      return renderCellForWrappedTextWithHyperlinkMUI(data);
    case GridColumnCellTypes.STATE_LOADER_CELL:
      return renderCellForStateLoader(data);
    case GridColumnCellTypes.STATE_LOADER_CELL_MUI:
      return renderCellForStateLoaderMUI(data);
    case GridColumnCellTypes.COMPONENT_CELL:
      return renderCellForComponent(data);
    case GridColumnCellTypes.COMPONENT_CELL_MUI:
      return renderCellForComponentMUI(data);
    case GridColumnCellTypes.ICON_LINK_CELL:
      return renderCellForIconLink(data);
    case GridColumnCellTypes.ICON_LINK_CELL_MUI:
      return renderCellForIconLinkMUI(data);
    case GridColumnCellTypes.CELL_ICON:
      return renderIconCell(data);
    case GridColumnCellTypes.CELL_ICON_MUI:
      return renderIconCellMUI(data);
    case GridColumnCellTypes.FORMATTED_LOCALIZED_TEXT_CELL:
      return renderFormattedLocalizedTextCell(data);
    case GridColumnCellTypes.FORMATTED_LOCALIZED_TEXT_CELL_MUI:
      return renderFormattedLocalizedTextCellMUI(data);
    case GridColumnCellTypes.BYTE_CELL:
      return renderByteCell(data);
    case GridColumnCellTypes.BYTE_CELL_MUI:
      return renderByteCellMUI(data);
    case GridColumnCellTypes.JOB_TYPE_CELL:
      return renderCellForJobType(data);
    case GridColumnCellTypes.JOB_TYPE_CELL_MUI:
      return renderCellForJobTypeMUI(data);
    case GridColumnCellTypes.BUTTON_MUI:
      return renderCellForButtonMUI(data);
    case GridColumnCellTypes.BUTTON_ACTION:
      return renderCellForActionButton(data);
    case GridColumnCellTypes.BUTTON_ACTION_MUI:
      return renderCellForActionButtonMUI(data);
    case GridColumnCellTypes.TEXT_BUTTON_MUI:
      return renderCellForTextAndButton(data);
    case GridColumnCellTypes.DURATION:
      return renderCellForDuration(data);
    case GridColumnCellTypes.DURATION_MUI:
      return renderCellForDurationMUI(data);
    case GridColumnCellTypes.DURATION_MOMENT_MUI:
      return renderCellForDurationMomentMUI(data);

    default:
      return null;
  }
};

/**
 * Return a {@link Component} to be rendered for requested header type.
 * @param {*} type
 * @param {*} data
 */
const renderHeaderForType = (type, data) => {
  switch (type) {
    case GridColumnHeaderTypes.HEADER_TEXT:
      return renderHeaderForText(data);
    case GridColumnHeaderTypes.HEADER_TEXT_MUI:
      return renderHeaderForTextMUI(data);
    case GridColumnHeaderTypes.HEADER_SELECT:
      return renderHeaderForSelect(data);
    case GridColumnHeaderTypes.HEADER_SELECT_MUI:
      return renderHeaderForSelectMUI(data);
    case GridColumnHeaderTypes.HEADER_TEXT_SELECT:
      return renderHeaderForTextSelect(data);
    case GridColumnHeaderTypes.HEADER_TEXT_SELECT_MUI:
      return renderHeaderForTextSelectMUI(data);
    case GridColumnHeaderTypes.ICON_LINK_CELL_MUI:
      return renderHeaderForIconMUI(data);
    default:
      return null;
  }
};

/**
 * Returns styles to be applied for requested cell type.
 * @param {*} type
 */
const setCellWidthForType = type => {
  switch (type) {
    case GridColumnCellTypes.CHECKBOX_CELL:
      return setCellWidthForCheckbox(type);
    case GridColumnCellTypes.CHECKBOX_CELL_MUI:
      return setCellWidthForCheckboxMUI(type);
    case GridColumnCellTypes.ICON_CELL:
      return setCellWidthForIcon(type);
    case GridColumnCellTypes.ICON_CELL_MUI:
      return setCellWidthForIconMUI(type);
    case GridColumnCellTypes.LINK_CELL:
    case GridColumnCellTypes.EXTERNAL_LINK_CELL:
      return setCellWidthForLink(type);
    case GridColumnCellTypes.LINK_CELL_MUI:
    case GridColumnCellTypes.EXTERNAL_LINK_CELL_MUI:
    case GridColumnCellTypes.LINK_CELL_WITH_OVERLAY_MUI:
      return setCellWidthForLinkMUI(type);
    case GridColumnCellTypes.ACTION_DROPDOWN_CELL:
      return setCellWidthForActionDropdown(type);
    case GridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI:
      return setCellWidthForActionDropdownMUI(type);
    case GridColumnCellTypes.PROGRESS_TEXT_CELL:
      return setCellWidthForProgressText(type);
    case GridColumnCellTypes.PROGRESS_TEXT_CELL_MUI:
      return setCellWidthForProgressTextMUI(type);
    case GridColumnCellTypes.PROGRESS_TEXT_CELL_WITHOUT_PERCENTAGE_MUI:
      return setCellWidthForProgressTextWithoutPercentageMUI(type);
    case GridColumnCellTypes.EDIT_DROPDOWN_CELL:
      return setCellWidthForEditDropdown(type);
    case GridColumnCellTypes.EDIT_DROPDOWN_CELL_MUI:
      return setCellWidthForEditDropdownMUI(type);
    case GridColumnCellTypes.MULTI_SELECT_DROPDOWN_CELL:
      return setCellWidthForMultiSelectDropdown(type);
    case GridColumnCellTypes.MULTI_SELECT_DROPDOWN_CELL_MUI:
      return setCellWidthForMultiSelectDropdownMUI(type);
    case GridColumnCellTypes.ID_VALUE_CELL:
      return setCellWidthForIdValue(type);
    case GridColumnCellTypes.ID_VALUE_CELL_MUI:
      return setCellWidthForIdValueMUI(type);
    case GridColumnCellTypes.DATE_TIME_CELL:
      return setCellWidthForDateTime(type);
    case GridColumnCellTypes.DATE_TIME_CELL_MUI:
      return setCellWidthForDateTimeMUI(type);
    case GridColumnCellTypes.SINGLE_SELECT_DROPDOWN_CELL:
      return setCellWidthForSingleSelectDropdown(type);
    case GridColumnCellTypes.SINGLE_SELECT_DROPDOWN_CELL_MUI:
      return setCellWidthForSingleSelectDropdownMUI(type);
    case GridColumnCellTypes.INPUT_CELL:
      return setCellForInput(type);
    case GridColumnCellTypes.INPUT_CELL_MUI:
      return setCellForInputMUI(type);
    case GridColumnCellTypes.RADIO_BUTTON_CELL:
      return setCellForRadioButton(type);
    case GridColumnCellTypes.RADIO_BUTTON_CELL_MUI:
      return setCellForRadioButtonMUI(type);
    case GridColumnCellTypes.EMPTY_TEXT_CELL:
      return setCellWidthForEmptyText(type);
    case GridColumnCellTypes.EMPTY_TEXT_CELL_MUI:
      return setCellWidthForEmptyTextMUI(type);
    case GridColumnCellTypes.BUTTON_ACTION:
      return setCellWidthForActionButton(type);
    case GridColumnCellTypes.BUTTON_ACTION_MUI:
      return setCellWidthForActionButtonMUI(type);
    case GridColumnCellTypes.DURATION_MUI:
    case GridColumnCellTypes.DURATION_MOMENT_MUI:
      return setCellWidthForDurationMUI(type);

    default:
      return null;
  }
};

/**
 * Return a {@link Component} to be rendered for requested header type.
 * @param {*} type
 * @param {*} data
 */
const setClassForHeaderType = type => {
  switch (type) {
    case GridColumnHeaderTypes.HEADER_SELECT:
      return setClassForSelectHeader();
    case GridColumnHeaderTypes.HEADER_SELECT_MUI:
      return setClassForSelectHeaderMUI();
    case GridColumnHeaderTypes.HEADER_TEXT_SELECT:
      return setClassForTextSelectHeader();
    case GridColumnHeaderTypes.HEADER_TEXT_SELECT_MUI:
      return setClassForTextSelectHeaderMUI();
    case "CHECKSORTTRUE":
      return setSortTrueFalseValue();
    default:
      return null;
  }
};

/**
 * Returns width & minWidth for checkbox cell.
 */
const setCellWidthForCheckbox = () => {
  return {
    width: GridColumnCellWidth.CHECKBOX_CELL,
    minWidth: GridColumnCellWidth.CHECKBOX_CELL
  };
};

/**
 * Returns width & minWidth for checkbox cell.
 */
const setCellWidthForCheckboxMUI = () => {
  return {
    width: GridColumnCellWidth.CHECKBOX_CELL_MUI,
    minWidth: GridColumnCellWidth.CHECKBOX_CELL_MUI
  };
};

/**
 * Returns width & minWidth for Icon cell.
 */
const setCellWidthForIcon = () => {
  return {
    width: GridColumnCellWidth.ICON_CELL,
    minWidth: GridColumnCellWidth.ICON_CELL
  };
};

/**
 * Returns width & minWidth for Icon cell.
 */
const setCellWidthForIconMUI = () => {
  return {
    width: GridColumnCellWidth.ICON_CELL_MUI,
    minWidth: GridColumnCellWidth.ICON_CELL_MUI
  };
};

/**
 * Returns width & minWidth for Link cell.
 */
const setCellWidthForLink = () => {
  return {
    minWidth: GridColumnCellWidth.LINK_CELL,
    className: "link-cell"
  };
};

/**
 * Returns width & minWidth for Link cell.
 */
const setCellWidthForLinkMUI = () => {
  return {
    minWidth: GridColumnCellWidth.LINK_CELL_MUI,
    className: "link-cell"
  };
};

/**
 * Returns width & minWidth for empty text cell.
 */
const setCellWidthForEmptyText = () => {
  return {
    width: GridColumnCellWidth.EMPTY_TEXT_CELL,
    minWidth: GridColumnCellWidth.EMPTY_TEXT_CELL
  };
};

/**
 * Returns width & minWidth for empty text cell.
 */
const setCellWidthForEmptyTextMUI = () => {
  return {
    width: GridColumnCellWidth.EMPTY_TEXT_CELL_MUI,
    minWidth: GridColumnCellWidth.EMPTY_TEXT_CELL_MUI
  };
};

// /**
//  *  Returns width, minWidth for action dropdown cell.
//  */
const setCellWidthForActionDropdown = () => {
  return {
    width: GridColumnCellWidth.ACTION_DROPDOWN_CELL,
    minWidth: GridColumnCellWidth.ACTION_DROPDOWN_CELL
  };
};

// /**
//  *  Returns width, minWidth for action dropdown cell.
//  */
const setCellWidthForActionDropdownMUI = () => {
  return {
    width: GridColumnCellWidth.ACTION_DROPDOWN_CELL_MUI,
    minWidth: GridColumnCellWidth.ACTION_DROPDOWN_CELL_MUI
  };
};

/**
 *  Returns width & minWidth for progress bar cell.
 */
const setCellWidthForProgressText = () => {
  return {
    width: GridColumnCellWidth.PROGRESS_TEXT_CELL,
    minWidth: GridColumnCellWidth.PROGRESS_TEXT_CELL
  };
};

/**
 *  Returns width & minWidth for progress bar cell.
 */
const setCellWidthForProgressTextMUI = () => {
  return {
    width: GridColumnCellWidth.PROGRESS_TEXT_CELL_MUI,
    minWidth: GridColumnCellWidth.PROGRESS_TEXT_CELL_MUI
  };
};
/**
 *  Returns width & minWidth for progress bar cell.
 */
const setCellWidthForProgressTextWithoutPercentageMUI = () => {
  return {
    width: GridColumnCellWidth.PROGRESS_TEXT_CELL_WITHOUT_PERCENTAGE_MUI,
    minWidth: GridColumnCellWidth.PROGRESS_TEXT_CELL_WITHOUT_PERCENTAGE_MUI
  };
};

/**
 *  Returns width & minWidth for progress bar cell.
 */
const setCellWidthForEditDropdown = () => {
  return {
    width: GridColumnCellWidth.EDIT_DROPDOWN_CELL,
    minWidth: GridColumnCellWidth.EDIT_DROPDOWN_CELL,
    headerClassName: "no-ellipsis",
    className: "no-ellipsis"
  };
};

/**
 *  Returns width & minWidth for progress bar cell.
 */
const setCellWidthForEditDropdownMUI = () => {
  return {
    width: GridColumnCellWidth.EDIT_DROPDOWN_CELL_MUI,
    minWidth: GridColumnCellWidth.EDIT_DROPDOWN_CELL_MUI,
    headerClassName: "no-ellipsis",
    className: "no-ellipsis"
  };
};

/**
 *  Returns width & minWidth for multi select dropdown
 */
const setCellWidthForMultiSelectDropdown = () => {
  return {
    width: GridColumnCellWidth.MULTI_SELECT_DROPDOWN_CELL,
    minWidth: GridColumnCellWidth.MULTI_SELECT_DROPDOWN_CELL,
    headerClassName: "no-ellipsis",
    className: "no-ellipsis"
  };
};

/**
 *  Returns width & minWidth for multi select dropdown
 */
const setCellWidthForMultiSelectDropdownMUI = () => {
  return {
    width: GridColumnCellWidth.MULTI_SELECT_DROPDOWN_CELL_MUI,
    minWidth: GridColumnCellWidth.MULTI_SELECT_DROPDOWN_CELL_MUI,
    headerClassName: "no-ellipsis",
    className: "no-ellipsis"
  };
};

/**
 *  Returns width & minWidth for single select dropdown
 */
const setCellWidthForSingleSelectDropdown = () => {
  return {
    width: GridColumnCellWidth.MULTI_SELECT_DROPDOWN_CELL,
    minWidth: GridColumnCellWidth.MULTI_SELECT_DROPDOWN_CELL,
    headerClassName: "no-ellipsis",
    className: "no-ellipsis"
  };
};

/**
 *  Returns width & minWidth for single select dropdown
 */
const setCellWidthForSingleSelectDropdownMUI = () => {
  return {
    width: GridColumnCellWidth.MULTI_SELECT_DROPDOWN_CELL_MUI,
    minWidth: GridColumnCellWidth.MULTI_SELECT_DROPDOWN_CELL_MUI,
    headerClassName: "no-ellipsis",
    className: "no-ellipsis"
  };
};

/**
 *  Returns width & minWidth for input
 */
const setCellForInput = () => {
  return {
    width: GridColumnCellWidth.INPUT_CELL,
    minWidth: GridColumnCellWidth.INPUT_CELL
  };
};

/**
 *  Returns width & minWidth for input
 */
const setCellForInputMUI = () => {
  return {
    width: GridColumnCellWidth.INPUT_CELL_MUI,
    minWidth: GridColumnCellWidth.INPUT_CELL_MUI
  };
};

/**
 *  Returns width & minWidth for radio button
 */
const setCellForRadioButton = () => {
  return {
    width: GridColumnCellWidth.RADIO_BUTTON_CELL,
    minWidth: GridColumnCellWidth.RADIO_BUTTON_CELL
  };
};

/**
 *  Returns width & minWidth for radio button
 */
const setCellForRadioButtonMUI = () => {
  return {
    width: GridColumnCellWidth.RADIO_BUTTON_CELL_MUI,
    minWidth: GridColumnCellWidth.RADIO_BUTTON_CELL_MUI
  };
};

/**
 *  Returns width & minWidth for progress bar cell.
 */
const setCellWidthForIdValue = () => {
  return {
    width: GridColumnCellWidth.ID_VALUE_CELL,
    minWidth: GridColumnCellWidth.ID_VALUE_CELL
  };
};

/**
 *  Returns width & minWidth for progress bar cell.
 */
const setCellWidthForIdValueMUI = () => {
  return {
    width: GridColumnCellWidth.ID_VALUE_CELL_MUI,
    minWidth: GridColumnCellWidth.ID_VALUE_CELL_MUI
  };
};

/**
 * Returns width & minWidth for date time cell.
 */
const setCellWidthForDateTime = () => {
  return {
    width: GridColumnCellWidth.DATE_TIME_CELL,
    minWidth: GridColumnCellWidth.DATE_TIME_CELL
  };
};

/**
 * Returns width & minWidth for date time cell.
 */
const setCellWidthForDateTimeMUI = () => {
  return {
    width: GridColumnCellWidth.DATE_TIME_CELL_MUI,
    minWidth: GridColumnCellWidth.DATE_TIME_CELL_MUI
  };
};

/**
 * Set class to header and cell for select type.
 */
const setClassForSelectHeader = () => {
  return {
    headerClassName: "no-ellipsis",
    className: "no-ellipsis",
    resizable: false
  };
};

/**
 * Set class to header and cell for select type.
 */
const setClassForSelectHeaderMUI = () => {
  return {
    headerClassName: "no-ellipsis",
    className: "no-ellipsis",
    resizable: false
  };
};

/**
 * Set class to header and cell for text select type.
 */
const setClassForTextSelectHeader = () => {
  return {
    headerClassName: "ellipsis",
    className: "ellipsis",
    resizable: true
  };
};

/**
 * Set class to header and cell for text select type.
 */
const setClassForTextSelectHeaderMUI = () => {
  return {
    headerClassName: "ellipsis",
    className: "ellipsis",
    resizable: true
  };
};

/**
 * Set class to header and cell for text select type.
 */
const setSortTrueFalseValue = () => {
  return {
    headerClassName: "active-sort",
    className: "active-sort",
    resizable: true
  };
};

/**
 * Returns cell rendering function for checkbox.
 */
const renderCellForCheckbox = () => {
  return cell => (
    <div>
      <input id={cell.original.id} type="checkbox" />
      <label htmlFor={cell.original.id} />
    </div>
  );
};

/**
 * Returns cell rendering function for checkbox.
 */
const renderCellForCheckboxMUI = () => {
  return cell => (
    <div>
      <input
        id={cell.original ? cell.original.id : cell.row.original.id}
        type="checkbox"
      />
      <label
        htmlFor={cell.original ? cell.original.id : cell.row.original.id}
      />
    </div>
  );
};

/**
 * Return cell rendering function for date/time
 */
const renderCellForDateTime = () => {
  return cell => {
    if (cell.value && cell.value.dateString) {
      let ts = cell.value.dateString;
      if (cell.value.dateString === CD_RECOVER_POINT_BROWSE_CURRENT_RP_NAME) {
        return (
          <FormattedHTMLMessage id={CD_RECOVER_POINT_BROWSE_CURRENT_RP_NAME} />
        );
      } else {
        return (
          <div>
            <span style={{ fontSize: cell?.value?.timeDiff ? "16px" : "14px" }}>
              {getLocaleDate(ts, "L", false)} {getLocaleDate(ts, "LT", false)}
            </span>
            {cell.value && cell.value.timeDiff && (
              <div className="date-diff">{cell.value.timeDiff}</div>
            )}
          </div>
        );
      }
    } else return "-";
  };
};

/**
 * Return cell rendering function for date/time
 */
const renderCellForDateTimeMUI = () => {
  return cell => {
    if (cell.value && cell.value.dateString) {
      let ts = cell.value.dateString;
      if (cell.value.dateString === CD_RECOVER_POINT_BROWSE_CURRENT_RP_NAME) {
        return (
          <FormattedHTMLMessage id={CD_RECOVER_POINT_BROWSE_CURRENT_RP_NAME} />
        );
      } else {
        return (
          <div
            id={cell.id ? `${cell.id}_tr_${cell.row.id}_${cell.column.id}` : ""}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontSize: cell?.value?.timeDiff ? "0.875rem" : "14px"
            }}
          >
            <span>
              {getLocaleDate(ts, "L", false)} {getLocaleDate(ts, "LT", false)}
            </span>
            {cell.value && cell.value.timeDiff && (
              <div className="date-diff">{cell.value.timeDiff}</div>
            )}
          </div>
        );
      }
    } else return "-";
  };
};

/**
 * Returns cell rendering function for {@link Icon}.
 */
const renderCellForIcon = () => {
  return cell => {
    if (cell.value === "loading-status") {
      return loadingBar();
    } else {
      let overlayData = cell.value
        ? cell.value.text
          ? cell.value.text
          : typeof cell.value !== "object"
          ? cell.value
          : null
        : null;
      let iconClass = cell.value
        ? cell.value.iconClass
          ? cell.value.iconClass
          : overlayData
        : null;
      if ((overlayData === "-" && iconClass === "-") || iconClass === "-")
        return "-";
      if (iconClass === "rps_success") iconClass = "success";
      if (get(cell, "value.hideOverlay")) {
        return iconClass ? <Icon icon={iconClass} /> : "-";
      }
      if (iconClass === "loading-status") {
        return (
          <span>
            <div className="state-loading">
              {" "}
              &nbsp;
              <div className="state-loading-bar" />
              <div className="state-loading-bar" />
              <div className="state-loading-bar" />
            </div>
          </span>
        );
      } else {
        return overlayData && overlayData !== "" ? (
          <Overlay
            type={OverlayTypes.TOOLTIP}
            placement={cell.value && cell.value.placement}
            className={cell.column.id === "ivm_status" ? "ivm-tooltip" : ""}
            overlayData={
              overlayData && cell.column.id !== "ivm_status" ? (
                <FormattedHTMLMessage
                  id={overlayData}
                  defaultMessage={overlayData}
                  values={cell.value && cell.value.values}
                />
              ) : cell.column.id === "ivm_status" ? (
                overlayData
              ) : (
                ""
              )
            }
            data={iconClass ? <Icon icon={iconClass} /> : "-"}
          />
        ) : (
          <Icon icon={iconClass} />
        );
      }
    }
  };
};
const renderCellForIconMUI = data => {
  return cell => {
    if (cell.value === "loading-status") {
      return loadingBar();
    } else {
      let overlayData = cell.value
        ? cell.value.text
          ? cell.value.text
          : typeof cell.value !== "object"
          ? cell.value
          : null
        : null;
      let iconClass = cell.value
        ? cell.value.iconClass
          ? cell.value.iconClass
          : overlayData
        : null;
      if ((overlayData === "-" && iconClass === "-") || iconClass === "-")
        return "-";
      if (iconClass === "rps_success") iconClass = "success";
      if (get(cell, "value.hideOverlay")) {
        return iconClass ? (
          IconMapping[iconClass] ? (
            <IconMUI
              icon={IconMapping[iconClass]["icon"]}
              color={IconMapping[iconClass]["color"]}
              style={IconMapping[iconClass]["style"]}
            />
          ) : iconClass === "product_success" ||
            iconClass === "product_error" ? (
            <Icon
              icon={iconClass}
              width={"27"}
              height={"25"}
              viewBox="0 0 27 25"
            />
          ) : (
            <Icon icon={iconClass} style={{ height: "30px", width: "30px" }} />
          )
        ) : (
          "-"
        );
      }
      if (iconClass === "loading-status" || iconClass === "deploying") {
        return (
          <span>
            <div className="state-loading">
              {" "}
              &nbsp;
              <div className="state-loading-bar" />
              <div className="state-loading-bar" />
              <div className="state-loading-bar" />
            </div>
          </span>
        );
      } else {
        return overlayData && overlayData !== "" ? (
          <Overlay
            id={cell.id ? `${cell.id}_tr_${cell.row.id}_${cell.column.id}` : ""}
            type={OverlayTypes.TOOLTIP}
            placement={cell.value && cell.value.placement}
            className={cell.column.id === "ivm_status" ? "ivm-tooltip" : ""}
            style={
              data.showCursorPointer && {
                cursor: "pointer"
              }
            }
            overlayData={
              overlayData && cell.column.id !== "ivm_status" ? (
                <FormattedHTMLMessage
                  id={overlayData}
                  defaultMessage={overlayData}
                  values={cell.value && cell.value.values}
                />
              ) : cell.column.id === "ivm_status" ? (
                overlayData
              ) : (
                ""
              )
            }
            data={
              iconClass ? (
                IconMapping[iconClass] ? (
                  <IconMUI
                    icon={IconMapping[iconClass]["icon"]}
                    color={IconMapping[iconClass]["color"]}
                    style={
                      IconMapping[iconClass]["style"]
                        ? IconMapping[iconClass]["style"]
                        : {}
                    }
                  />
                ) : iconClass === "product_success" ||
                  iconClass === "product_error" ? (
                  <Icon
                    icon={iconClass}
                    width={"27"}
                    height={"25"}
                    viewBox="0 0 27 25"
                  />
                ) : (
                  <Icon
                    icon={iconClass}
                    style={
                      iconClass === "switchview"
                        ? { width: "28px", height: "24px" }
                        : { height: "30px", width: "30px" }
                    }
                  />
                )
              ) : (
                "-"
              )
            }
          />
        ) : (
          <Icon icon={iconClass} style={{ height: "30px", width: "30px" }} />
        );
      }
    }
  };
};

const renderCellForIconMUIWithoutOverlay = () => {
  return cell => {
    if (cell.value === "loading-status") {
      return loadingBar();
    } else {
      let overlayData = cell.value
        ? cell.value.text
          ? cell.value.text
          : typeof cell.value !== "object"
          ? cell.value
          : null
        : null;
      let iconClass = cell.value
        ? cell.value.iconClass
          ? cell.value.iconClass
          : overlayData
        : null;
      if ((overlayData === "-" && iconClass === "-") || iconClass === "-")
        return "-";
      if (iconClass === "rps_success") iconClass = "success";
      if (get(cell, "value.hideOverlay")) {
        return iconClass ? (
          IconMapping[iconClass] ? (
            <IconMUI
              icon={IconMapping[iconClass]["icon"]}
              color={IconMapping[iconClass]["color"]}
              style={IconMapping[iconClass]["style"]}
            />
          ) : iconClass === "product_success" ||
            iconClass === "product_error" ? (
            <Icon
              icon={iconClass}
              width={"27"}
              height={"25"}
              viewBox="0 0 27 25"
            />
          ) : (
            <Icon icon={iconClass} style={{ height: "30px", width: "30px" }} />
          )
        ) : (
          "-"
        );
      }
      if (iconClass === "loading-status" || iconClass === "deploying") {
        return (
          <span>
            <div className="state-loading">
              {" "}
              &nbsp;
              <div className="state-loading-bar" />
              <div className="state-loading-bar" />
              <div className="state-loading-bar" />
            </div>
          </span>
        );
      } else {
        return iconClass ? (
          IconMapping[iconClass] ? (
            <IconMUI
              icon={IconMapping[iconClass]["icon"]}
              color={IconMapping[iconClass]["color"]}
              style={IconMapping[iconClass]["style"]}
            />
          ) : iconClass === "product_success" ||
            iconClass === "product_error" ? (
            <Icon
              icon={iconClass}
              width={"27"}
              height={"25"}
              viewBox="0 0 27 25"
            />
          ) : (
            <Icon icon={iconClass} style={{ height: "30px", width: "30px" }} />
          )
        ) : (
          "-"
        );
      }
    }
  };
};

/**
 * Returns cell rendering function for link.
 */
const renderCellForLink = () => {
  let reduxStore = store?.getState();
  let theme = reduxStore?.reducer?.theme;

  return cell => {
    let label = "-";
    if (cell.value) {
      if (cell.value.label) {
        label = cell.value.label;
      } else if (typeof cell.value === "string") {
        label = cell.value;
      } else if (typeof cell.column.accessor === "function") {
        label = cell.column.accessor(cell.original);
      }
    }
    return cell.value &&
      cell.value.label !== "" &&
      cell.value.label !== null ? (
      <Link
        to={cell.value.href || "#"}
        href={cell.value.href || "#"}
        target={cell.value.target || "_self"}
        className={`${theme?.palette?.mode === DARK_MODE && "link-dark-theme"}
          ${
            cell.value.isDisabledPerm || cell.value.key === "isDisabledPerm"
              ? "link-cell link-disabled-perm wordBreak"
              : cell.value.isDisabled
              ? "link-cell link-disabled wordBreak"
              : "link-cell wordBreak"
          }
        `}
        style={{
          color: `${
            cell.value.isDisabledPerm || cell.value.isDisabled
              ? "inherit"
              : handleLinkColor()
          }`
        }}
      >
        {cell.value && cell.value.iconClass ? (
          <i className={cell.value.iconClass} />
        ) : (
          <span>{label}</span>
        )}
      </Link>
    ) : (
      "-"
    );
  };
};

const renderCellForLinkMUI = () => {
  let reduxStore = store?.getState();
  let theme = reduxStore?.reducer?.theme;

  return cell => {
    let label = "-";
    if (cell.value) {
      if (cell.value.label) {
        label = cell.value.label;
      } else if (typeof cell.value === "string") {
        label = cell.value;
      } else if (typeof cell.column.accessor === "function") {
        label = cell.column.accessor(cell.row.original);
      }
    }
    return cell.value &&
      cell.value.label !== "" &&
      cell.value.label !== null ? (
      <Link
        id={`${
          cell.row.original.id
            ? cell.row.original.id
            : cell.row.original.organization_id
        }-${cell.column.id}`}
        to={cell.value.href || "#"}
        href={cell.value.href || "#"}
        target={cell.value.target || "_self"}
        className={`
         ${
           cell.value.isDisabledPerm
             ? "link-cell link-disabled-perm wordBreak"
             : cell.value.isDisabled
             ? "link-cell link-disabled wordBreak"
             : "link-cell wordBreak"
         } ${theme?.palette?.mode === DARK_MODE ? "link-dark-theme" : ""}
        `}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: "14px",
          display: "block",
          color: `${
            cell.value.isDisabledPerm || cell.value.isDisabled
              ? "inherit"
              : handleLinkColor()
          }`
        }}
      >
        {cell.value && cell.value.iconClass ? (
          <i className={cell.value.iconClass} style={{ color: "#444" }} />
        ) : (
          <span>{label}</span>
        )}
      </Link>
    ) : (
      "-"
    );
  };
};

const renderCellForLinkWithOverlayMUI = () => {
  let reduxStore = store?.getState();
  let theme = reduxStore?.reducer?.theme;

  return cell => {
    let label = "-";
    if (cell.value) {
      if (cell.value.label) {
        label = cell.value.label;
      } else if (typeof cell.value === "string") {
        label = cell.value;
      } else if (typeof cell.column.accessor === "function") {
        label = cell.column.accessor(cell.row.original);
      }
    }
    return cell.value &&
      cell.value.label !== "" &&
      cell.value.label !== null ? (
      (cell.id === "cc_id_dg18" ? (
        useSelector(state =>
          get(state, "login.organizationInfo.organization_type")
        ) === "msp" ? (
          get(cell, "row.original.organization")
        ) : (
          get(cell, "row.original.policy.policy_name")
        )
      ) : (
        true
      )) ? (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose={false}
          delay={{ show: 0, hide: 200 }}
          placement="bottom"
          overlay={
            cell.id !== "cc_id_dg18" ? (
              <Tooltip>{label}</Tooltip>
            ) : (
              <Tooltip>
                {useSelector(state =>
                  get(state, "login.organizationInfo.organization_type")
                ) === "msp" ? (
                  <>
                    <strong>
                      <FormattedMessage
                        id="Organization"
                        defaultMessage="Organization"
                      />
                    </strong>
                    <br />
                    {get(cell, "row.original.organization")}
                  </>
                ) : (
                  <>
                    <strong>
                      <FormattedMessage id="policy" defaultMessage="Policy" />
                    </strong>
                    <br />
                    {get(cell, "row.original.policy.policy_name")}
                  </>
                )}
              </Tooltip>
            )
          }
          style={{ fontSize: "14px" }}
        >
          <Link
            id={`${
              cell.row.original.id
                ? cell.row.original.id
                : cell.row.original.organization_id
            }-${cell.column.id}`}
            to={cell.value.href || "#"}
            href={cell.value.href || "#"}
            target={cell.value.target || "_self"}
            className={`
         ${
           cell.value.isDisabledPerm
             ? "link-cell link-disabled-perm wordBreak"
             : cell.value.isDisabled
             ? "link-cell link-disabled wordBreak"
             : "link-cell wordBreak"
         } ${theme?.palette?.mode === DARK_MODE ? "link-dark-theme" : ""}
        `}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontSize: "14px",
              display: "block",
              color: `${
                cell.value.isDisabledPerm || cell.value.isDisabled
                  ? "inherit"
                  : handleLinkColor()
              }`
            }}
          >
            {cell.value && cell.value.iconClass ? (
              <i className={cell.value.iconClass} style={{ color: "#444" }} />
            ) : (
              <span>{label}</span>
            )}
          </Link>
        </OverlayTrigger>
      ) : (
        <Link
          id={`${
            cell.row.original.id
              ? cell.row.original.id
              : cell.row.original.organization_id
          }-${cell.column.id}`}
          to={cell.value.href || "#"}
          href={cell.value.href || "#"}
          target={cell.value.target || "_self"}
          className={`
         ${
           cell.value.isDisabledPerm
             ? "link-cell link-disabled-perm wordBreak"
             : cell.value.isDisabled
             ? "link-cell link-disabled wordBreak"
             : "link-cell wordBreak"
         } ${theme?.palette?.mode === DARK_MODE ? "link-dark-theme" : ""}
        `}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "14px",
            display: "block",
            color: `${
              cell.value.isDisabledPerm || cell.value.isDisabled
                ? "inherit"
                : handleLinkColor()
            }`
          }}
        >
          {cell.value && cell.value.iconClass ? (
            <i className={cell.value.iconClass} style={{ color: "#444" }} />
          ) : (
            <span>{label}</span>
          )}
        </Link>
      )
    ) : (
      "-"
    );
  };
};

const renderCellForLinkWithIcon = () => {
  let reduxStore = store?.getState();
  let theme = reduxStore?.reducer?.theme;
  return cell => {
    let label = "-";
    if (cell.value) {
      if (cell.value.label) {
        label = cell.value.label;
      } else if (typeof cell.value === "string") {
        label = cell.value;
      } else if (typeof cell.column.accessor === "function") {
        label = cell.column.accessor(cell.original);
      }
    }
    return cell.value &&
      cell.value.label !== "" &&
      cell.value.label !== null ? (
      <span>
        {cell.value.icon ? (
          <span
            className="link-icon-text"
            style={{ width: "14pt", height: "16pt" }}
          >
            <Overlay
              type={OverlayTypes.TOOLTIP}
              placement={"bottom"}
              overlayData={
                <FormattedMessage
                  id={`protect.source.add.hypervisor.${cell.value.entity_type}`}
                  defaultMessage={cell.value.entity_type}
                />
              }
              data={<cell.value.icon />}
            />
          </span>
        ) : null}
        <Link
          id={`${cell.original.id}-${cell.column.id}`}
          to={cell.value.href || "#"}
          href={cell.value.href || "#"}
          target={cell.value.target || "_self"}
          className={`${
            cell.value.isDisabledPerm
              ? "link-cell link-disabled-perm wordBreak"
              : cell.value.isDisabled
              ? "link-cell link-disabled wordBreak"
              : "link-cell wordBreak"
          } ${theme?.palette?.mode === DARK_MODE && "link-dark-theme"}`}
          style={{
            color: `${
              cell.value.isDisabledPerm || cell.value.isDisabled
                ? "inherit"
                : handleLinkColor()
            }`
          }}
        >
          {cell.value && cell.value.iconClass ? (
            <i className={cell.value.iconClass} />
          ) : (
            <span>{label}</span>
          )}
        </Link>
      </span>
    ) : (
      "-"
    );
  };
};

const renderCellForLinkWithIconMUI = () => {
  let reduxStore = store?.getState();
  let theme = reduxStore?.reducer?.theme;

  return cell => {
    let label = "-";
    if (cell.value) {
      if (cell.value.label) {
        label = cell.value.label;
      } else if (typeof cell.value === "string") {
        label = cell.value;
      } else if (typeof cell.column.accessor === "function") {
        label = cell.column.accessor(cell.row.original);
      }
    }
    return cell.value &&
      cell.value.label !== "" &&
      cell.value.label !== null ? (
      <>
        {cell.value.icon ? (
          <span
            className="link-icon-text"
            style={{ width: "14pt", height: "16pt" }}
          >
            <Overlay
              type={OverlayTypes.TOOLTIP}
              placement={"bottom"}
              overlayData={
                <FormattedMessage
                  id={`protect.source.add.hypervisor.${cell.value.entity_type}`}
                  defaultMessage={cell.value.entity_type}
                />
              }
              data={<cell.value.icon />}
            />
          </span>
        ) : null}
        <Link
          id={`${cell.row.original ? cell.row.original.id : cell.original.id}-${
            cell.column.id
          }`}
          to={cell.value.href || "#"}
          href={cell.value.href || "#"}
          target={cell.value.target || "_self"}
          className={`${theme?.palette?.mode === DARK_MODE && "link-dark-theme"}
            ${
              cell.value.isDisabledPerm
                ? "link-cell link-disabled-perm wordBreak"
                : cell.value.isDisabled
                ? "link-cell link-disabled wordBreak"
                : "link-cell wordBreak"
            }`}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "14px",
            display: "block",
            color: `${
              cell.value.isDisabledPerm || cell.value.isDisabled
                ? "inherit"
                : handleLinkColor()
            }`
          }}
        >
          {cell.value && cell.value.iconClass ? (
            <i className={cell.value.iconClass} />
          ) : (
            <span>{typeof label === "string" ? label : "-"}</span>
          )}
        </Link>
      </>
    ) : (
      "-"
    );
  };
};

/**
 * Returns cell rendering function for link.
 */
const renderCellForExternalLink = () => {
  return cell => {
    let label = "-";

    if (cell.value) {
      if (cell.value.label) {
        label = cell.value.label;
      } else if (typeof cell.value === "string") {
        label = cell.value;
      } else if (typeof cell.column.accessor === "function") {
        label = cell.column.accessor(cell.original);
      }
    }
    return cell.value ? (
      <a
        id={`${cell.original.id}-${cell.column.id}`}
        // eslint-disable-next-line no-script-url
        to={cell.value.href || "javascript:void(0);"}
        // eslint-disable-next-line no-script-url
        href={cell.value.href || "javascript:void(0);"}
        target={cell.value.target || "_self"}
        className={cell.value.isDisabled ? "link-disabled" : ""}
        style={{ color: handleLinkColor() }}
      >
        {label}
      </a>
    ) : (
      "-"
    );
  };
};

/**
 * Returns cell rendering function for link.
 */
const renderCellForExternalLinkMUI = () => {
  return cell => {
    let label = "-";

    if (cell.value) {
      if (cell.value.label) {
        label = cell.value.label;
      } else if (typeof cell.value === "string") {
        label = cell.value;
      } else if (typeof cell.column.accessor === "function") {
        label = cell.column.accessor(cell.original);
      }
    }
    return cell.value && label !== "-" ? (
      <a
        id={`${cell.original ? cell.original.id : cell.row.original.id}-${
          cell.column.id
        }`}
        // eslint-disable-next-line no-script-url
        to={cell.value.href || "javascript:void(0);"}
        // eslint-disable-next-line no-script-url
        href={cell.value.href || "javascript:void(0);"}
        target={cell.value.target || "_self"}
        className={cell.value.isDisabled ? "link-disabled" : ""}
        style={{
          color: cell.value.isDisabled ? "inherit" : handleLinkColor(),
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }}
      >
        {label}
      </a>
    ) : (
      "-"
    );
  };
};

/**
 * Returns job status color based on status of job
 */
// const handleJobStatusColor = status => {
//   let reduxStore = store?.getState();
//   let theme = reduxStore?.reducer?.theme;
//   const { error, warning, success } =
//     theme && theme.palette ? theme.palette : MUI_INITIAL_THEME.palette;
//   const { main: red } = error;
//   const { main: yellow } = warning;
//   const { main: green } = success;

//   switch (status) {
//     case JOB_STATUS.LICENSE_FAILED:
//     case JOB_STATUS.CANCELED:
//     case JOB_STATUS.CRASH:
//     case JOB_STATUS.FAILED:
//     case JOB_STATUS.MISSED:
//     case JOB_STATUS.STOP:
//       return {
//         color: `${red}`
//       };
//     case JOB_STATUS.SKIPPED:
//     case JOB_STATUS.INCOMPLETE:
//     case JOB_STATUS.WARNING:
//     case JOB_STATUS.WAITING:
//       return {
//         color: `${yellow}`
//       };
//     default:
//       return {
//         color: `${green}`
//       };
//   }
// };

const handleLinkColor = () => {
  let reduxStore = store?.getState();
  let theme = reduxStore?.reducer?.theme;
  const { secondary } =
    theme && theme.palette ? theme.palette : MUI_INITIAL_THEME().palette;
  const { main: linkSecondary } = secondary;
  return linkSecondary;
};

const linkCellForDestination = (value, cell) => {
  let linkToPath =
    value.destinationType === "datastore"
      ? `${PAGE_ROUTE.DATA_STORE}/${value.destinationId}/information`
      : `${PAGE_ROUTE.DESTINATIONS}/${SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD}/${value.destinationId}/edit/information`;

  return (
    <div
      className="latest-job-popver-text"
      id={cell.id}
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
        whiteSpace: "nowrap",
        fontSize: "14px"
      }}
    >
      {value.isDisabledPerm === true || !value.destinationType ? (
        <span className="disabled">{value.destinationName}</span>
      ) : (
        <Link
          to={{
            pathname: linkToPath,
            state: {
              selectedResource: {
                destination_id: value.destinationId,
                destination_name: value.destinationName
              }
            }
          }}
          style={{ color: handleLinkColor() }}
          href={linkToPath}
          onClick={() => {
            if (value.organizationType === "msp") {
              let customerData = {
                customerOrgId:
                  cell.obj.orgName && cell.obj.orgName.organization_id,
                customerOrgName:
                  cell.obj.orgName && cell.obj.orgName.organization_name,
                customerOrgType:
                  cell.obj.orgName && cell.obj.orgName.organization_type
                    ? cell.obj.orgName && cell.obj.orgName.organization_type
                    : ""
              };
              store.dispatch(impersonateCustomer(customerData));
            }
          }}
        >
          {value.destinationName}
        </Link>
      )}
    </div>
  );
};

const linkCellForPolicy = (value, index, readOnlyView = false) => {
  let linkToPath = `${PAGE_ROUTE.POLICIES}/${value.policyId}/${ACTION_TYPE.EDIT}/basics`;

  return (
    <div
      className="latest-job-popver-text"
      id={index}
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "14px"
      }}
    >
      {readOnlyView ? (
        <span className="disabled" style={{ fontSize: "14px" }}>
          {value.policyName}
        </span>
      ) : (
        <Link
          to={{
            pathname: linkToPath,
            state: {
              selectedResource: {
                policy_id: value.policyId,
                policy_name: value.policyName,
                hypervisor_name:
                  value.hypervisorObj && value.hypervisorObj.hypervisor_name
                    ? value.hypervisorObj.hypervisor_name
                    : ""
              }
            }
          }}
          style={{ fontSize: "14px", color: handleLinkColor() }}
          href={linkToPath}
        >
          {value.policyName}
        </Link>
      )}
    </div>
  );
};
const linkCellForImpersonatePolicy = (
  value,
  index,
  org_name,
  org_id,
  org_type
) => {
  let linkToPath = `${PAGE_ROUTE.CSR_POLICIES}/all/${value.policyName}/${org_name}/${org_id}/${org_type}`;
  return (
    <div className="latest-job-popver-text" id={index}>
      <span
        style={{ color: handleLinkColor(), cursor: "pointer" }}
        onClick={e => {
          e.preventDefault();
          window.open(linkToPath, "_blank");
        }}
      >
        {" "}
        {value.policyName}
      </span>
    </div>
  );
};

const linkCellForImpersonateAccounts = (history, value, index) => {
  return (
    <div className="latest-job-popver-text" id={index}>
      <span
        style={{ color: handleLinkColor(), cursor: "pointer" }}
        onClick={e => {
          e.preventDefault();
          store.dispatch(updateCurrentSavedSearchName("", ""));
          store.dispatch(clearAllSearchTags());
          store.dispatch(dismissAlert(false));
          let customerData = {
            customerOrgId: value.organizationId,
            customerOrgName: value.organizationName,
            customerOrgType: value.organizationType
          };
          store.dispatch(impersonateCustomer(customerData));
          store.dispatch(getBrandingDetails());
          store.dispatch(
            selectFilterAction({
              selectedFilter: "sources",
              selectedFilterKey: "Sources"
            })
          );
          store.dispatch(selectFilterAction(getAlertList()));
          if (
            isMspMonitorUser(value.originalRoleId) ||
            isMspAccountMonitorUser(value.originalRoleId)
          ) {
            history.push(`/monitor`);
          } else {
            history.push(`/protect/sources/all`);
          }
        }}
      >
        {" "}
        {value.organizationName}
      </span>
    </div>
  );
};

/**
 * Returns popover for policy cell
 * @param {array of object} data
 * @param {boolean} readOnlyView
 */
const renderPolicyPopover = (data, readOnlyView) => {
  return (
    <Tooltip
      id="cell-popover-progressbar"
      className="policy-row-popover"
      title=""
    >
      {data &&
        data.map((value, index) => {
          return linkCellForPolicy(value, index, readOnlyView);
        })}
    </Tooltip>
  );
};
const renderDestinationPopover = (data, cell) => {
  return (
    <Tooltip
      id="cell-popover-progressbar"
      className="policy-row-popover"
      title=""
    >
      {data &&
        data.map(value => {
          return linkCellForDestination(value, cell);
        })}
    </Tooltip>
  );
};

/**
 * Returns popover for policy cell
 * @param {array of object} data
 */
const renderImpersontePolicyPopover = (data, org_name, org_id, org_type) => {
  return (
    <Tooltip
      id="cell-popover-progressbar"
      className="policy-row-popover"
      title=""
    >
      {data &&
        data.map((value, index) => {
          return linkCellForImpersonatePolicy(
            value,
            index,
            org_name,
            org_id,
            org_type
          );
        })}
    </Tooltip>
  );
};

const renderImpersonteAccountsPopover = (history, data) => {
  return (
    <Tooltip
      id="cell-popover-progressbar"
      className="policy-row-popover"
      title=""
    >
      {data &&
        data.map((value, index) => {
          return linkCellForImpersonateAccounts(history, value, index);
        })}
    </Tooltip>
  );
};

/**
 * Returns popover for role cell
 * @param {array of object} data
 */
const renderRolePopover = data => {
  let proxy_data = "";
  return (
    <Tooltip
      id="cell-popover-progressbar"
      className="role-row-popover"
      title=""
    >
      {data &&
        data.map((value, index) => {
          //eslint-disable-next-line array-callback-return
          Object.keys(PROXY_ROLES).map(item => {
            if (value.roleName.includes(PROXY_ROLES[item])) {
              proxy_data = PROXY_ROLES[item];
            } else {
              proxy_data = value.roleName;
            }
          });
          return (
            <div className="latest-job-popver-text" id={index}>
              <FormattedMessage
                id={proxy_data ? proxy_data : value.roleName}
                defaultMessage={proxy_data}
              />
            </div>
          );
        })}
    </Tooltip>
  );
};

/**
 * Returns policy cell rendering function for {@link Overlay}.
 */
const renderCellForPolicyOverlay = () => {
  return cell => {
    if (!cell.value) {
      return "-";
    }
    if (cell.value && cell.value.content === "-") {
      return "-";
    }

    if (cell.value && cell.value.labelOnly) {
      let additionalValueObject =
        cell.value.additionalOperations &&
        cell.value.additionalOperations.length > 0
          ? {
              policyId: cell.value.additionalOperations[0].policyId,
              policyName: cell.value.additionalOperations[0].policyName,
              hypervisorObj: cell.value.additionalOperations[0].hypervisorObj
            }
          : { policyId: "", policyName: "", hypervisorObj: "" };
      return linkCellForPolicy(
        additionalValueObject,
        cell.original.id,
        cell.value.readOnlyView
      );
    }

    if (cell.value && !cell.value.labelOnly) {
      return (
        <OverlayTrigger
          rootClose
          trigger="click"
          id="popover"
          placement="top"
          overlay={renderPolicyPopover(
            cell.value.additionalOperations,
            cell.value.readOnlyView
          )}
        >
          <span>{cell.value.content}</span>
        </OverlayTrigger>
      );
    }
  };
};

const renderCellForPolicyOverlayMUI = () => {
  return cell => {
    if (!cell.value) {
      return "-";
    }

    if (cell.value && cell.value.content === "-") {
      return "-";
    }

    if (cell.value && cell.value.labelOnly) {
      let additionalValueObject =
        cell.value.additionalOperations &&
        cell.value.additionalOperations.length > 0
          ? {
              policyId: cell.value.additionalOperations[0].policyId,
              policyName: cell.value.additionalOperations[0].policyName,
              hypervisorObj: cell.value.additionalOperations[0].hypervisorObj
            }
          : { policyId: "", policyName: "", hypervisorObj: "" };
      return linkCellForPolicy(
        additionalValueObject,
        cell.original ? cell.original.id : cell.row.original.id,
        cell.value.readOnlyView,
        cell.id,
        cell.row,
        cell.column
      );
    }

    if (cell.value && !cell.value.labelOnly) {
      return (
        <OverlayTrigger
          rootClose
          trigger="click"
          id="popover"
          placement="top"
          overlay={renderPolicyPopover(
            cell.value.additionalOperations,
            cell.value.readOnlyView
          )}
        >
          <span style={{ fontSize: "14px" }}>{cell.value.content}</span>
        </OverlayTrigger>
      );
    }
  };
};

const renderCellForDestinationOverlay = () => {
  return cell => {
    if (!cell.value) {
      return "-";
    }
    if (cell.value && cell.value.content === "-") {
      return "-";
    }

    if (cell.value && cell.value.labelOnly) {
      let additionalValueObject =
        cell.value.additionalOperations &&
        cell.value.additionalOperations.length > 0
          ? {
              destinationId: cell.value.additionalOperations[0].destinationId,
              destinationName:
                cell.value.additionalOperations[0].destinationName,
              destinationType:
                cell.value.additionalOperations[0].destinationType,
              organizationType:
                cell.value.additionalOperations[0].organizationType,
              isDisabledPerm: cell.value.additionalOperations[0].isDisabledPerm
            }
          : {
              destinationId: "",
              destinationName: "",
              destinationType: "",
              organizationType: ""
            };

      return linkCellForDestination(additionalValueObject, cell.original);
    }

    if (cell.value && !cell.value.labelOnly) {
      return (
        <OverlayTrigger
          rootClose
          trigger="click"
          id="popover"
          placement="top"
          overlay={renderDestinationPopover(
            cell.value.additionalOperations,
            cell.original
          )}
        >
          <span>{cell.value.content}</span>
        </OverlayTrigger>
      );
    }
  };
};

const renderCellForDestinationOverlayMUI = () => {
  return cell => {
    if (!cell.value) {
      return "-";
    }
    if (cell.value && cell.value.content === "-") {
      return "-";
    }

    if (cell.value && cell.value.labelOnly) {
      let additionalValueObject =
        cell.value.additionalOperations &&
        cell.value.additionalOperations.length > 0
          ? {
              destinationId: cell.value.additionalOperations[0].destinationId,
              destinationName:
                cell.value.additionalOperations[0].destinationName,
              destinationType:
                cell.value.additionalOperations[0].destinationType,
              organizationType:
                cell.value.additionalOperations[0].organizationType,
              isDisabledPerm: cell.value.additionalOperations[0].isDisabledPerm
            }
          : {
              destinationId: "",
              destinationName: "",
              destinationType: "",
              organizationType: ""
            };

      return linkCellForDestination(
        additionalValueObject,
        cell.original ? cell.original : cell.row.original
      );
    }

    if (cell.value && !cell.value.labelOnly) {
      return (
        <OverlayTrigger
          rootClose
          trigger="click"
          id="popover"
          placement="top"
          overlay={renderDestinationPopover(
            cell.value.additionalOperations,
            cell.original ? cell.original : cell.row.original
          )}
        >
          <span>{cell.value.content}</span>
        </OverlayTrigger>
      );
    }
  };
};

/**
 * Returns policy cell rendering function for {@link Overlay}.
 */
const renderCellForImpersonatePolicyOverlay = () => {
  return cell => {
    if (!cell.value) {
      return "-";
    }
    if (cell.value && cell.value.content === "-") {
      return "-";
    }

    if (cell.value && cell.value.labelOnly) {
      let additionalValueObject =
        cell.value.additionalOperations &&
        cell.value.additionalOperations.length > 0
          ? {
              policyId: cell.value.additionalOperations[0].policyId,
              policyName: cell.value.additionalOperations[0].policyName,
              hypervisorObj: cell.value.additionalOperations[0].hypervisorObj
            }
          : { policyId: "", policyName: "", hypervisorObj: "" };

      return linkCellForImpersonatePolicy(
        additionalValueObject,
        cell.original.id,
        cell.original.organization_name,
        cell.original.organization_id,
        cell.original.organization_type
      );
    }

    if (cell.value && !cell.value.labelOnly) {
      return (
        <OverlayTrigger
          rootClose
          trigger="click"
          id="popover"
          placement="top"
          overlay={renderImpersontePolicyPopover(
            cell.value.additionalOperations,
            cell.original.organization_name,
            cell.original.organization_id,
            cell.original.organization_type
          )}
        >
          <span>{cell.value.content}</span>
        </OverlayTrigger>
      );
    }
  };
};

/**
 * Returns policy cell rendering function for {@link Overlay}.
 */
const renderCellForImpersonatePolicyOverlayMUI = () => {
  return cell => {
    if (!cell.value) {
      return "-";
    }
    if (cell.value && cell.value.content === "-") {
      return "-";
    }

    if (cell.value && cell.value.labelOnly) {
      let additionalValueObject =
        cell.value.additionalOperations &&
        cell.value.additionalOperations.length > 0
          ? {
              policyId: cell.value.additionalOperations[0].policyId,
              policyName: cell.value.additionalOperations[0].policyName,
              hypervisorObj: cell.value.additionalOperations[0].hypervisorObj
            }
          : { policyId: "", policyName: "", hypervisorObj: "" };

      return linkCellForImpersonatePolicy(
        additionalValueObject,
        cell.original ? cell.original.id : cell.row.original.id,
        cell.original
          ? cell.original.organization_name
          : cell.row.original.organization_name,
        cell.original
          ? cell.original.organization_id
          : cell.row.original.organization_id,
        cell.original
          ? cell.original.organization_type
          : cell.row.original.organization_type
      );
    }

    if (cell.value && !cell.value.labelOnly) {
      return (
        <OverlayTrigger
          rootClose
          trigger="click"
          id="popover"
          placement="top"
          overlay={renderImpersontePolicyPopover(
            cell.value.additionalOperations,
            cell.original
              ? cell.original.organization_name
              : cell.row.original.organization_name,
            cell.original
              ? cell.original.organization_id
              : cell.row.original.organization_id,
            cell.original
              ? cell.original.organization_type
              : cell.row.original.organization_type
          )}
        >
          <span>{cell.value.content}</span>
        </OverlayTrigger>
      );
    }
  };
};

const renderCellForImpersonateAccountsOverlayMUI = () => {
  return cell => {
    if (!cell.value) {
      return "-";
    }
    if (cell.value && cell.value.content === "-") {
      return "-";
    }

    if (cell.value && cell.value.labelOnly) {
      let additionalValueObject =
        cell.value.additionalOperations &&
        cell.value.additionalOperations.length > 0
          ? {
              organizationId: cell.value.additionalOperations[0].organizationId,
              organizationName:
                cell.value.additionalOperations[0].organizationName,
              organizationType:
                cell.value.additionalOperations[0].organizationType
            }
          : { organizationId: "", organizationName: "", organizationType: "" };

      return linkCellForImpersonateAccounts(
        cell.value.history,
        additionalValueObject,
        cell.row.original.id
      );
    }

    if (cell.value && !cell.value.labelOnly) {
      return (
        <OverlayTrigger
          rootClose
          trigger="click"
          id="popover"
          placement="top"
          overlay={renderImpersonteAccountsPopover(
            cell.value.history,
            cell.value.additionalOperations
          )}
        >
          <span>{cell.value.content}</span>
        </OverlayTrigger>
      );
    }
  };
};

/**
 * Returns role cell rendering function for {@link Overlay}.
 */
const renderCellForRoleOverlay = () => {
  return cell => {
    if (!cell.value) {
      return "-";
    }
    if (cell.value && cell.value.content === "-") {
      return "-";
    }

    let content = "-";
    if (cell.value.length > 1) {
      content = cell.value.length + " Roles";
    } else if (cell.value.length === 1) {
      content = cell.value[0];
    }
    let data = {
      labelOnly: cell.value.length < 2,
      content,
      additionalOperations: cell.value.map(role => ({
        roleId: role,
        roleName: role
      }))
    };

    if (data && data.labelOnly) {
      let additionalValueObject =
        data.additionalOperations && data.additionalOperations.length > 0
          ? {
              roleId: data.additionalOperations[0].roleId,
              roleName: data.additionalOperations[0].roleName
            }
          : { roleId: "", roleName: "" };

      let proxy_data = "";
      //eslint-disable-next-line array-callback-return
      Object.keys(PROXY_ROLES).map(item => {
        if (
          additionalValueObject &&
          additionalValueObject.roleName &&
          additionalValueObject.roleName.includes(PROXY_ROLES[item])
        ) {
          proxy_data = PROXY_ROLES[item];
        } else {
          proxy_data = additionalValueObject.roleName || "-";
        }
      });
      return (
        <span>
          {" "}
          <FormattedMessage
            id={proxy_data ? proxy_data : additionalValueObject.roleName}
            defaultMessage={proxy_data}
          />
        </span>
      );
    }
    if (data && !data.labelOnly) {
      return (
        <OverlayTrigger
          rootClose
          trigger="click"
          id="popover"
          placement="top"
          overlay={renderRolePopover(data.additionalOperations)}
        >
          <span>{data.content}</span>
        </OverlayTrigger>
      );
    }
  };
};

/**
 * Returns role cell rendering function for {@link Overlay}.
 */
const renderCellForRoleOverlayMUI = () => {
  return cell => {
    if (!cell.value) {
      return "-";
    }
    if (cell.value && cell.value.content === "-") {
      return "-";
    }

    let content = "-";
    if (cell.value.length > 1) {
      content = cell.value.length + " Roles";
    } else if (cell.value.length === 1) {
      content = cell.value[0];
    }
    let data = {
      labelOnly: cell.value.length < 2,
      content,
      additionalOperations: cell.value.map(role => ({
        roleId: role,
        roleName: role
      }))
    };

    if (data && data.labelOnly) {
      let additionalValueObject =
        data.additionalOperations && data.additionalOperations.length > 0
          ? {
              roleId: data.additionalOperations[0].roleId,
              roleName: data.additionalOperations[0].roleName
            }
          : { roleId: "", roleName: "" };
      let proxy_data = "";
      //eslint-disable-next-line array-callback-return
      Object.keys(PROXY_ROLES).map(item => {
        if (
          additionalValueObject &&
          additionalValueObject.roleName &&
          additionalValueObject.roleName.includes(PROXY_ROLES[item])
        ) {
          proxy_data = PROXY_ROLES[item];
        } else {
          proxy_data = additionalValueObject.roleName || "-";
        }
      });
      return (
        <span>
          {" "}
          <FormattedMessage
            id={proxy_data ? proxy_data : additionalValueObject.roleName}
            defaultMessage={proxy_data}
          />
        </span>
      );
    }
    if (data && !data.labelOnly) {
      return (
        <OverlayTrigger
          rootClose
          trigger="click"
          id="popover"
          placement="top"
          overlay={renderRolePopover(data.additionalOperations)}
        >
          <span>{data.content}</span>
        </OverlayTrigger>
      );
    }
  };
};

/**
 * Returns cell rendering function for {@link Overlay}.
 */
const renderCellForLatestJob = (data, text = false) => {
  return cell => {
    const [placement, setPlacement] = useState("bottom");
    if (!cell.value) {
      return "-";
    }
    if (cell.value && cell.value.content === "-") {
      return "-";
    }
    if (
      cell.value &&
      cell.value.labelOnly &&
      cell.value.additionalOperations[0].status !== "active"
    ) {
      let tempStr = cell.value.content;
      let tempArr = tempStr && tempStr.split("__");
      let showLink;
      // Impersonate from Source Search CSR
      if (cell.value.isCSR) {
        let linkToPath = `${PAGE_ROUTE.CSR_JOBS}/all/${
          cell.value.additionalOperations[0].jobId
        }/${ACTION_TYPE.LOG}/${tempArr.length && tempArr[0].trim()}/${
          cell.original.organization_name
        }/${cell.original.organization_id}/${cell.original.organization_type}`;
        showLink = (
          <span
            style={{ color: handleLinkColor(), cursor: "pointer" }}
            onClick={e => {
              e.preventDefault();
              window.open(linkToPath, "_blank");
            }}
          >
            {" "}
            <FormattedMessage id={tempArr.length && tempArr[0].trim()} />
          </span>
        );
      } else {
        showLink = (
          <Link
            to={{
              pathname: `${PAGE_ROUTE.JOBS}/all/${cell.value.additionalOperations[0].jobId}/${ACTION_TYPE.LOG}`,
              state: {
                selectedResource: {
                  job_id: cell.value.additionalOperations[0].jobId,
                  job_name: tempArr.length ? tempArr[0].trim() : ""
                }
              }
            }}
            href={`${PAGE_ROUTE.JOBS}/all/${cell.value.additionalOperations[0].jobId}/${ACTION_TYPE.LOG}`}
          >
            <FormattedMessage id={tempArr.length && tempArr[0].trim()} />
          </Link>
        );
      }
      return (
        <div className="multi-line-text">
          {text ? (
            <FormattedMessage id={tempArr.length && tempArr[0].trim()} />
          ) : (
            showLink
          )}
          {tempArr && tempArr.length && tempArr.length > 1 && (
            <div>
              <span>
                <FormattedMessage id={tempArr.length && tempArr[1].trim()} />
              </span>
              <span>{tempArr.length && tempArr[2].trim()}</span>
            </div>
          )}
        </div>
      );
    } else if (
      cell.value &&
      cell.value.labelOnly &&
      cell.value.additionalOperations[0] &&
      cell.value.additionalOperations[0].status === "active"
    ) {
      let showLink;
      if (cell.value.isCSR) {
        let linkToPath = `${PAGE_ROUTE.CSR_JOBS}/all/${cell.value.additionalOperations[0].jobId}/${ACTION_TYPE.LOG}/${cell.value.additionalOperations[0].type}/${cell.original.organization_name}/${cell.original.organization_id}/${cell.original.organization_type}`;
        showLink = (
          <span
            style={{ color: handleLinkColor(), cursor: "pointer" }}
            onClick={e => {
              e.preventDefault();
              window.open(linkToPath, "_blank");
            }}
          >
            {" "}
            <FormattedMessage id={cell.value.additionalOperations[0].type} />
          </span>
        );
      } else {
        showLink = (
          <Link
            to={{
              pathname: `${PAGE_ROUTE.JOBS}/all/${cell.value.additionalOperations[0].jobId}/${ACTION_TYPE.LOG}`,
              state: {
                selectedResource: {
                  job_id: cell.value.additionalOperations[0].jobId,
                  job_name: cell.value.additionalOperations[0].type
                }
              }
            }}
            href={`${PAGE_ROUTE.JOBS}/all/${cell.value.additionalOperations[0].jobId}/${ACTION_TYPE.LOG}`}
          >
            <FormattedMessage id={cell.value.additionalOperations[0].type} />
          </Link>
        );
      }
      let job_type =
        cell.value &&
        cell.value.labelOnly &&
        cell.value.additionalOperations[0] &&
        cell.value.additionalOperations[0].type;
      let status = cell.value.additionalOperations[0].value;
      let statusToShow = status && status.split("(")[0];
      return (
        <div className="latest-jb-progress-bar" style={{ fontSize: "16px" }}>
          {text ? (
            <FormattedMessage id={cell.value.additionalOperations[0].type} />
          ) : (
            showLink
          )}
          {JOB_TYPES_HIDE_PROGRESS_BAR.includes(job_type) ? (
            <span
              id={`${cell.original.id}-${cell.column.id}-content`}
              className="progress-content display-next-line"
            >
              {statusToShow}
            </span>
          ) : (
            <React.Fragment>
              <ProgressBarComponent
                now={cell.value.additionalOperations[0].percentage}
                bsStyle={cell.value.bsStyle}
              />
              <span className="progress-content display-status">
                {cell.value.additionalOperations[0].value}
              </span>
            </React.Fragment>
          )}
        </div>
      );
    }

    if (cell.value && !cell.value.labelOnly) {
      cell.value.additionalOperations = cell.value.additionalOperations.map(
        value => {
          let temp = value.tempKey && value.tempKey.split("__");
          let jobName =
            JobNameMapping[temp[0].trim()] !== undefined ? temp[0].trim() : "";
          let status =
            temp && temp.length > 1 && JobStatus[temp[1].trim()] !== undefined
              ? temp[1].trim()
              : "";
          let time = `${temp && temp.length > 2 && temp[2]}`;
          value.jobStatus = status;
          value.jobTime = time;
          value.jobName = jobName;
          return value;
        }
      );

      if (cell.value.isCSR) {
        return (
          <OverlayTrigger
            rootClose
            trigger="click"
            id="popover"
            placement="bottom"
            overlay={renderLatestJobPopoverImpersonate(
              cell.value.additionalOperations,
              cell.value.organization_name,
              cell.value.organization_id,
              cell.value.organization_type
            )}
          >
            <span>{cell.value.content}</span>
          </OverlayTrigger>
        );
      } else {
        return (
          <div
            onClick={e => {
              if (document.body.clientHeight - e.clientY < 240)
                setPlacement("top");
              else setPlacement("bottom");
            }}
          >
            <OverlayTrigger
              rootClose
              trigger="click"
              id="popover"
              placement={placement}
              overlay={renderLatestJobPopover(cell.value.additionalOperations)}
            >
              <span>{cell.value.content}</span>
            </OverlayTrigger>
          </div>
        );
      }
    }
  };
};

/**
 * Returns cell rendering function for {@link Overlay}.
 */
const renderCellForLatestJobMUI = (data, text = false) => {
  return cell => {
    const [placement, setPlacement] = useState("bottom");
    if (!cell.value) {
      return "-";
    }
    if (cell.value && cell.value.content === "-") {
      return "-";
    }
    if (
      cell.value &&
      cell.value.labelOnly &&
      cell.value.additionalOperations[0].status !== "active"
    ) {
      let tempStr = cell.value.content;
      let tempArr = tempStr && tempStr.split("__");
      let showLink;
      // Impersonate from Source Search CSR
      if (cell.value.isCSR) {
        let linkToPath = `${PAGE_ROUTE.CSR_JOBS}/all/${
          cell.value.additionalOperations[0].jobId
        }/${ACTION_TYPE.LOG}/${tempArr.length && tempArr[0].trim()}/${
          cell.original
            ? cell.original.organization_name
            : cell.row.original.organization_name
        }/${
          cell.original
            ? cell.original.organization_id
            : cell.row.original.organization_id
        }/${
          cell.original
            ? cell.original.organization_type
            : cell.row.original.organization_type
        }`;
        showLink = (
          <span
            style={{
              color: handleLinkColor(),
              cursor: "pointer",
              fontSize: "14px"
            }}
            onClick={e => {
              e.preventDefault();
              window.open(linkToPath, "_blank");
            }}
          >
            {" "}
            <FormattedMessage id={tempArr.length && tempArr[0].trim()} />
          </span>
        );
      } else {
        showLink = (
          <Link
            to={{
              pathname: `${PAGE_ROUTE.JOBS}/all/${cell.value.additionalOperations[0].jobId}/${ACTION_TYPE.LOG}`,
              state: {
                selectedResource: {
                  job_id: cell.value.additionalOperations[0].jobId,
                  job_name: tempArr.length ? tempArr[0].trim() : ""
                }
              }
            }}
            style={{ color: handleLinkColor() }}
            href={`${PAGE_ROUTE.JOBS}/all/${cell.value.additionalOperations[0].jobId}/${ACTION_TYPE.LOG}`}
          >
            <FormattedMessage id={tempArr.length && tempArr[0].trim()} />
          </Link>
        );
      }

      return (
        <div
          className="multi-line-text latest-job-cell"
          id={cell.id ? `${cell.id}_tr_${cell.row.id}_${cell.column.id}` : ""}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "14px"
          }}
        >
          {text ? (
            <FormattedMessage id={tempArr.length && tempArr[0].trim()} />
          ) : (
            showLink
          )}
          {tempArr && tempArr.length && tempArr.length > 1 && (
            <div style={{ fontSize: "14px" }}>
              <span>
                <FormattedMessage id={tempArr.length && tempArr[1].trim()} />
              </span>
              <span>{tempArr.length && tempArr[2].trim()}</span>
            </div>
          )}
        </div>
      );
    } else if (
      cell.value &&
      cell.value.labelOnly &&
      cell.value.additionalOperations[0] &&
      cell.value.additionalOperations[0].status === "active"
    ) {
      let showLink;
      if (cell.value.isCSR) {
        let linkToPath = `${PAGE_ROUTE.CSR_JOBS}/all/${
          cell.value.additionalOperations[0].jobId
        }/${ACTION_TYPE.LOG}/${cell.value.additionalOperations[0].type}/${
          cell.original
            ? cell.original.organization_name
            : cell.row.original.organization_name
        }/${
          cell.original
            ? cell.original.organization_id
            : cell.row.original.organization_id
        }/${
          cell.original
            ? cell.original.organization_type
            : cell.row.original.organization_type
        }`;
        showLink = (
          <span
            style={{ color: handleLinkColor(), cursor: "pointer" }}
            onClick={e => {
              e.preventDefault();
              window.open(linkToPath, "_blank");
            }}
          >
            {" "}
            <FormattedMessage id={cell.value.additionalOperations[0].type} />
          </span>
        );
      } else {
        showLink = (
          <Link
            to={{
              pathname: `${PAGE_ROUTE.JOBS}/all/${cell.value.additionalOperations[0].jobId}/${ACTION_TYPE.LOG}`,
              state: {
                selectedResource: {
                  job_id: cell.value.additionalOperations[0].jobId,
                  job_name: cell.value.additionalOperations[0].type
                }
              }
            }}
            style={{ color: handleLinkColor(), cursor: "pointer" }}
            href={`${PAGE_ROUTE.JOBS}/all/${cell.value.additionalOperations[0].jobId}/${ACTION_TYPE.LOG}`}
          >
            <FormattedMessage id={cell.value.additionalOperations[0].type} />
          </Link>
        );
      }
      let job_type =
        cell.value &&
        cell.value.labelOnly &&
        cell.value.additionalOperations[0] &&
        cell.value.additionalOperations[0].type;
      let status = cell.value.additionalOperations[0].value;
      let statusToShow = status && status.split("(")[0];
      return (
        <div
          className="latest-jb-progress-bar"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "14px"
          }}
        >
          {text ? (
            <FormattedMessage id={cell.value.additionalOperations[0].type} />
          ) : (
            showLink
          )}
          {JOB_TYPES_HIDE_PROGRESS_BAR.includes(job_type) ? (
            <span
              id={`${cell.original ? cell.original.id : cell.row.original.id}-${
                cell.column.id
              }-content`}
              className="progress-content display-next-line"
            >
              {statusToShow}
            </span>
          ) : (
            <React.Fragment>
              <ProgressBarComponent
                now={cell.value.additionalOperations[0].percentage}
                bsStyle={cell.value.bsStyle}
              />
              <span className="progress-content display-status">
                {cell.value.additionalOperations[0].value}
              </span>
            </React.Fragment>
          )}
        </div>
      );
    }

    if (cell.value && !cell.value.labelOnly) {
      cell.value.additionalOperations =
        cell &&
        cell.value &&
        cell.value.additionalOperations &&
        cell.value.additionalOperations.map(value => {
          let temp = value.tempKey && value.tempKey.split("__");
          let jobName =
            JobNameMapping[temp[0].trim()] !== undefined ? temp[0].trim() : "";
          let status =
            temp && temp.length > 1 && JobStatus[temp[1].trim()] !== undefined
              ? temp[1].trim()
              : "";
          let time = `${temp && temp.length > 2 && temp[2]}`;
          value.jobStatus = status;
          value.jobTime = time;
          value.jobName = jobName;
          return value;
        });

      if (cell.value.isCSR) {
        return (
          <OverlayTrigger
            rootClose
            trigger="click"
            id="popover"
            placement="bottom"
            overlay={renderLatestJobPopoverImpersonate(
              cell.value.additionalOperations,
              cell.value.organization_name,
              cell.value.organization_id,
              cell.value.organization_type
            )}
          >
            <span>{cell.value.content}</span>
          </OverlayTrigger>
        );
      } else {
        return (
          <div
            onClick={e => {
              if (document.body.clientHeight - e.clientY < 240)
                setPlacement("top");
              else setPlacement("bottom");
            }}
          >
            <OverlayTrigger
              rootClose
              trigger="click"
              id="popover"
              placement={placement}
              overlay={renderLatestJobPopover(cell.value.additionalOperations)}
            >
              <span>{cell.value.content}</span>
            </OverlayTrigger>
          </div>
        );
      }
    }
  };
};

const isCritical = value => {
  return value === "failed";
};
const linkCell = (value, index) => {
  //change here
  return (
    <div key={index} className="latest-job-popver-text" id={index}>
      <Link
        to={{
          pathname: `${PAGE_ROUTE.JOBS}/all/${value.jobId}/${ACTION_TYPE.LOG}`,
          state: {
            selectedResource: {
              job_id: value.jobId,
              job_name: value.jobName
            }
          }
        }}
        style={{ color: handleLinkColor() }}
        href={`${PAGE_ROUTE.JOBS}/all/${value.jobId}/${ACTION_TYPE.LOG}`}
      >
        {/* handling blank screen issue if jobname is sent as empty string from BE */}
        {value.jobName ? (
          <FormattedMessage id={value.jobName} defaultMessage={value.jobName} />
        ) : (
          <span> </span>
        )}{" "}
      </Link>
      <div>
        <span //TODO try using variables for colors
          style={{ color: isCritical(value.status) ? "#f76c83" : "#4bbfa5" }}
        >
          {/* handling blank screen issue if jobstatus is sent as empty string from BE */}
          {value.jobStatus ? (
            <FormattedMessage
              id={value.jobStatus}
              defaultMessage={value.jobStatus}
            />
          ) : (
            <span> </span>
          )}
        </span>
        {value.jobTime}
      </div>
    </div>
  );
};

const linkCellImpersonate = (value, index, org_name, org_id, org_type) => {
  let linkToPath = `${PAGE_ROUTE.CSR_JOBS}/all/${value.jobId}/${ACTION_TYPE.LOG}/${value.jobName}/${org_name}/${org_id}/${org_type}`;
  //change here
  return (
    <div className="latest-job-popver-text" id={index}>
      <span
        style={{ color: handleLinkColor(), cursor: "pointer" }}
        onClick={e => {
          e.preventDefault();
          window.open(linkToPath, "_blank");
        }}
      >
        {" "}
        <FormattedMessage id={value.jobName} defaultMessage={value.jobName} />
      </span>
      <div>
        <span //TODO try using variables for colors
          style={{ color: isCritical(value.status) ? "#f76c83" : "#4bbfa5" }}
        >
          <FormattedMessage
            id={value.jobStatus}
            defaultMessage={value.jobStatus}
          />
        </span>
        {value.jobTime}
      </div>
    </div>
  );
};

/**
 * Returns popover for latest job cell
 * @param {array of object} data
 */
const renderLatestJobPopover = data => {
  return (
    <Popover
      id="cell-popover-progressbar"
      className="latest-job-popover"
      title=""
    >
      {data &&
        data.map((value, index) => {
          return linkCell(value, index);
        })}
    </Popover>
  );
};

/**
 * Returns popover for latest job cell
 * @param {array of object} data
 */
const renderLatestJobPopoverImpersonate = (
  data,
  org_name,
  org_id,
  org_type
) => {
  return (
    <Popover
      id="cell-popover-progressbar"
      className="latest-job-popover"
      title=""
    >
      {data.map((value, index) => {
        return linkCellImpersonate(value, index, org_name, org_id, org_type);
      })}
    </Popover>
  );
};

/**
 * Returns cell rendering function for {@link Overlay}.
 */
const renderCellForOverlay = () => {
  return cell => {
    if (!cell.value) {
      return "-";
    }
    if (cell.value && cell.value.content === "-") {
      return "-";
    }
    if (cell.value && cell.value.labelOnly) {
      return <span>{cell.value && cell.value.content}</span>;
    }
    const overlayData = cell.value ? cell.value.additionalOperations : [];
    const toolTip = (
      <Tooltip>
        {overlayData &&
          overlayData.map((value, index) => {
            return (
              <div>
                {
                  // eslint-disable-next-line
                  <a key={index}>{value.key}</a>
                }
                <br />
              </div>
            );
          })}
      </Tooltip>
    );
    return (
      <div id="recipient-tooltip">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose={false}
          delay={{ show: 0, hide: 200 }}
          placement="bottom"
          overlay={toolTip}
        >
          {
            // eslint-disable-next-line
            <a>{cell.value ? cell.value.content : ""}</a>
          }
        </OverlayTrigger>
      </div>
    );
  };
};

/**
 * Returns cell rendering function for {@link Overlay}.
 */
const renderCellForOverlayMUI = () => {
  return cell => {
    if (!cell.value) {
      return "-";
    }
    if (cell.value && cell.value.content === "-") {
      return "-";
    }
    if (cell.value && cell.value.labelOnly) {
      return (
        <span
          id={cell.id ? `${cell.id}_tr_${cell.row.id}_${cell.column.id}` : ""}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          {cell.value && cell.value.content}
        </span>
      );
    }
    const overlayData = cell.value ? cell.value.additionalOperations : [];
    const toolTip = (
      <Tooltip>
        {overlayData &&
          overlayData.map((value, index) => {
            return (
              <div key={index}>
                {
                  // eslint-disable-next-line
                  <a>{value.key}</a>
                }
                <br />
              </div>
            );
          })}
      </Tooltip>
    );
    return (
      <div id="recipient-tooltip">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose={false}
          delay={{ show: 0, hide: 200 }}
          placement="bottom"
          overlay={toolTip}
        >
          {
            // eslint-disable-next-line
            <a>{cell.value ? cell.value.content : ""}</a>
          }
        </OverlayTrigger>
      </div>
    );
  };
};

/**
 * Returns cell rendering function to display short text.
 */
const renderCellForShortText = () => {
  return cell =>
    cell.value ? (
      cell.value === "loading-status" ? (
        loadingBar()
      ) : (
        <span>
          <FormattedBrandMessage id={cell.value} defaultMessage={cell.value} />
        </span>
      )
    ) : (
      "-"
    );
};
const renderCellForShortTextMUI = () => {
  return cell =>
    //eslint-disable-next-line eqeqeq
    (cell.column.id === "app_enabled" && cell.value != undefined) ||
    cell.value ? (
      cell.value === "loading-status" ? (
        loadingBar()
      ) : (
        <div
          id={`${cell.original ? cell.original.id : cell.row.original.id}-${
            cell.column.id
          }`}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "14px"
          }}
        >
          {cell.id === "cc_id_dg08" && cell.column.id === "state" ? (
            <FormattedBrandMessage
              id={"protect.ivm.state." + cell.value}
              defaultMessage={cell.value}
            />
          ) : cell.column.id === "app_enabled" ? (
            <FormattedBrandMessage
              id={cell.value.toString()}
              defaultMessage={cell.value.toString()}
            />
          ) : (
            <FormattedBrandMessage
              id={cell.value}
              defaultMessage={cell.value}
            />
          )}
        </div>
      )
    ) : (
      "-"
    );
};
/**
 * Returns cell rendering function to display short text.
 */
const renderCellForJobType = () => {
  return cell =>
    cell.value ? (
      cell.value === "loading-status" ? (
        loadingBar()
      ) : (
        <span id={`${cell.original.id}-${cell.column.id}`}>
          <FormattedBrandMessage
            id={`job_type.${cell.value}`}
            defaultMessage={cell.value}
          />
        </span>
      )
    ) : (
      "-"
    );
};

/**
 * Returns cell rendering function to display short text.
 */
const renderCellForJobTypeMUI = () => {
  return cell =>
    cell.value ? (
      cell.value === "loading-status" ? (
        loadingBar()
      ) : (
        <span
          id={`${cell.original ? cell.original.id : cell.row.original.id}-${
            cell.column.id
          }`}
          style={{ fontSize: "14px" }}
        >
          <FormattedBrandMessage
            id={`job_type.${cell.value}`}
            defaultMessage={cell.value}
          />
        </span>
      )
    ) : (
      "-"
    );
};

/**
 * Returns cell rendering function to display formatted byte text.
 */
const renderByteCell = () => {
  return cell =>
    cell.value ? (
      <span
        id={`${cell.original.id}-${cell.column.id}`}
        style={{ fontSize: "14px" }}
      >
        {formatBytes(cell.value)}
      </span>
    ) : (
      "-"
    );
};
const renderByteCellMUI = () => {
  return cell =>
    cell.value ? (
      <span
        id={`${cell.original ? cell.original.id : cell.row.original.id}-${
          cell.column.id
        }`}
        style={{ fontSize: "14px" }}
      >
        {formatBytes(cell.value)}
      </span>
    ) : (
      "-"
    );
};

/**
 * Returns cell rendering function to display short text.
 */
const renderCellForComponent = () => {
  return cell =>
    cell.value ? <div style={{ fontSize: "14px" }}>{cell.value}</div> : <div />;
};

/**
 * Returns cell rendering function to display short text.
 */
const renderCellForComponentMUI = () => {
  return cell =>
    cell.value ? <div style={{ fontSize: "14px" }}>{cell.value}</div> : <div />;
};

/**
 * Returns cell rendering function to display state loader text.
 */
const renderCellForStateLoader = () => {
  return cell =>
    cell.value ? (
      <span id={`${cell.original.id}-${cell.column.id}`}>
        <div
          className={
            RECOVERD_VMS_LOADING_STATES.includes(cell.value)
              ? "state-loading"
              : ""
          }
        >
          <FormattedMessage id={cell.value} defaultMessage={cell.value} />
          &nbsp;
          <div
            className={
              RECOVERD_VMS_LOADING_STATES.includes(cell.value)
                ? "state-loading-bar"
                : ""
            }
          />
          <div
            className={
              RECOVERD_VMS_LOADING_STATES.includes(cell.value)
                ? "state-loading-bar"
                : ""
            }
          />
          <div
            className={
              RECOVERD_VMS_LOADING_STATES.includes(cell.value)
                ? "state-loading-bar"
                : ""
            }
          />
          <div
            className={
              RECOVERD_VMS_LOADING_STATES.includes(cell.value)
                ? "state-loading-bar"
                : ""
            }
          />
        </div>
      </span>
    ) : (
      "-"
    );
};

/**
 * Returns cell rendering function to display state loader text.
 */
const renderCellForStateLoaderMUI = () => {
  return cell =>
    cell.value ? (
      <span
        id={`${cell.original ? cell.original.id : cell.row.original.id}-${
          cell.column.id
        }`}
      >
        <div
          className={
            RECOVERD_VMS_LOADING_STATES.includes(cell.value)
              ? "state-loading"
              : ""
          }
        >
          <FormattedMessage id={cell.value} defaultMessage={cell.value} />
          &nbsp;
          <div
            className={
              RECOVERD_VMS_LOADING_STATES.includes(cell.value)
                ? "state-loading-bar"
                : ""
            }
          />
          <div
            className={
              RECOVERD_VMS_LOADING_STATES.includes(cell.value)
                ? "state-loading-bar"
                : ""
            }
          />
          <div
            className={
              RECOVERD_VMS_LOADING_STATES.includes(cell.value)
                ? "state-loading-bar"
                : ""
            }
          />
          <div
            className={
              RECOVERD_VMS_LOADING_STATES.includes(cell.value)
                ? "state-loading-bar"
                : ""
            }
          />
        </div>
      </span>
    ) : (
      "-"
    );
};

/**
 * Returns cell rendering function to display short or empty text.
 */
const renderCellForEmptyText = () => {
  return cell =>
    cell.value ? (
      <span id={`${cell.original.id}-${cell.column.id}`}>
        <FormattedMessage id={cell.value} defaultMessage={cell.value} />
      </span>
    ) : (
      " "
    );
};

/**
 * Returns cell rendering function to display short or empty text.
 */
const renderCellForEmptyTextMUI = () => {
  return cell =>
    cell.value ? (
      <span
        id={`${cell.original ? cell.original.id : cell.row.original.id}-${
          cell.column.id
        }`}
      >
        <FormattedMessage id={cell.value} defaultMessage={cell.value} />
      </span>
    ) : (
      " "
    );
};

/**
 * Returns cell rendering function to display numbers.
 */
const renderCellForNumericalText = () => {
  return cell => {
    return isNumber(cell.value) ? (
      <span id={`${cell.original.id}-${cell.column.id}`}>{cell.value}</span>
    ) : (
      "-"
    );
  };
};

/**
 * Returns cell rendering function to display numbers.
 */
const renderCellForNumericalTextMUI = () => {
  return cell => {
    return isNumber(cell.value) ? (
      <span
        id={`${cell.original ? cell.original.id : cell.row.original.id}-${
          cell.column.id
        }`}
      >
        {cell.value}
      </span>
    ) : (
      "-"
    );
  };
};

/**
 * Returns cell rendering function to display long text.
 */
const renderCellForLongText = () => {
  return cell => {
    return cell.value ? (
      <span id={`${cell.original.id}-${cell.column.id}`}>
        <FormattedBrandMessage id={cell.value} defaultMessage={cell.value} />
      </span>
    ) : (
      "-"
    );
  };
};
const renderCellForLongTextMUI = () => {
  return cell => {
    return cell.value ? (
      <div
        id={`${cell.original ? cell.original.id : cell.row.original.id}-${
          cell.column.id
        }`}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "14px"
        }}
      >
        <FormattedBrandMessage id={cell.value} defaultMessage={cell.value} />
      </div>
    ) : (
      "-"
    );
  };
};
/**
 * Returns cell rendering function to display wrapped text.
 */
const renderCellForWrappedText = () => {
  return cell =>
    cell.value ? (
      <span
        style={{
          wordWrap: "break-word",
          whiteSpace: "normal",
          wordBreak: "keep-all"
        }}
        id={`${cell.original.id}-${cell.column.id}`}
      >
        <FormattedMessage id={cell.value} defaultMessage={cell.value} />
      </span>
    ) : (
      "-"
    );
};

/**
 * Returns cell rendering function to display wrapped text.
 */
const renderCellForWrappedTextMUI = () => {
  return cell =>
    cell.value ? (
      <span
        style={{
          wordWrap: "break-word",
          whiteSpace: "normal",
          wordBreak: "keep-all",
          overflow: "hidden"
        }}
        id={`${cell.original ? cell.original.id : cell.row.original.id}-${
          cell.column.id
        }`}
      >
        <FormattedMessage id={cell.value} defaultMessage={cell.value} />
      </span>
    ) : (
      "-"
    );
};

/**
 * Returns cell rendering function to display wrapped text with hyperlink.
 */
const renderCellForWrappedTextWithHyperlink = () => {
  return cell => {
    let anchorLink = "#",
      link = "",
      right = "",
      left = "",
      anchorText = "",
      href = "#",
      aLink = "#";
    let anchorTextArr = [];
    let hrefs = [];
    let anchorLinkArr = [];
    if (cell.value) {
      try {
        let start = cell.value.search(/<a/g);
        let end = cell.value.search(/a>/g);
        if (start >= 0 && end < cell.value.length && start < end + 1) {
          left = cell.value.substring(0, start);
          right = cell.value.substring(end + 2, cell.value.length);
          link = cell.value.substring(start, end + 2);
        }
        if (start === -1 || end === -1 || !link) {
          left = "";
          right = "";
          link = cell.value;
        } else {
          anchorTextArr = link.match(/<a [^>]+>([^<]+)<\/a>/);
          anchorText =
            anchorTextArr && anchorTextArr[1] ? anchorTextArr[1] : "";
          hrefs = link.match(/href='([^']*)/g);
          href = hrefs && hrefs.length ? hrefs[hrefs.length - 1] : "href='#";
          anchorLinkArr = href.split("href=");
          aLink =
            anchorLinkArr && anchorLinkArr.length > 1 ? anchorLinkArr[1] : "#";
          anchorLink = aLink.substr(1);
          link = "";
        }
        return (
          <span
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              wordBreak: "keep-all"
            }}
            id={`${cell.original.id}-${cell.column.id}`}
          >
            <span>{left}</span>
            {link ? (
              link
            ) : (
              <span
                onClick={e => {
                  e.preventDefault();
                  window.open(anchorLink, "_blank");
                }}
              >
                {
                  // eslint-disable-next-line
                  <a href="#">{anchorText}</a>
                }
              </span>
            )}
            <span>{right}</span>
          </span>
        );
      } catch (e) {
        console.log(e);
        return (
          <span
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              wordBreak: "keep-all"
            }}
            id={`${cell.original.id}-${cell.column.id}`}
          >
            <FormattedMessage id={cell.value} defaultMessage={cell.value} />
          </span>
        );
      }
    } else {
      return "-";
    }
  };
};
/**
 * Returns cell rendering function to display wrapped text with hyperlink.
 */
const renderCellForWrappedTextWithHyperlinkMUI = () => {
  return cell => {
    let anchorLink = "#",
      link = "",
      right = "",
      left = "",
      anchorText = "",
      href = "#",
      aLink = "#";
    let anchorTextArr = [];
    let hrefs = [];
    let anchorLinkArr = [];
    if (cell.value) {
      try {
        let start = cell.value.search(/<a/g);
        let end = cell.value.search(/a>/g);
        if (start >= 0 && end < cell.value.length && start < end + 1) {
          left = cell.value.substring(0, start);
          right = cell.value.substring(end + 2, cell.value.length);
          link = cell.value.substring(start, end + 2);
        }
        if (start === -1 || end === -1 || !link) {
          left = "";
          right = "";
          link = cell.value;
        } else {
          anchorTextArr = link.match(/<a [^>]+>([^<]+)<\/a>/);
          anchorText =
            anchorTextArr && anchorTextArr[1] ? anchorTextArr[1] : "";
          hrefs = link.match(/href='([^']*)/g);
          href = hrefs && hrefs.length ? hrefs[hrefs.length - 1] : "href='#";
          anchorLinkArr = href.split("href=");
          aLink =
            anchorLinkArr && anchorLinkArr.length > 1 ? anchorLinkArr[1] : "#";
          anchorLink = aLink.substr(1);
          link = "";
        }
        return (
          <span
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              wordBreak: "keep-all",
              overflow: "hidden"
            }}
            id={`${cell.original ? cell.original.id : cell.row.original.id}-${
              cell.column.id
            }`}
          >
            <span>{left}</span>
            {link ? (
              link
            ) : (
              <span
                onClick={e => {
                  e.preventDefault();
                  window.open(anchorLink, "_blank");
                }}
              >
                {
                  // eslint-disable-next-line
                  <a href="#">{anchorText}</a>
                }
              </span>
            )}
            <span>{right}</span>
          </span>
        );
      } catch (e) {
        console.log(e);
        return (
          <span
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              wordBreak: "keep-all",
              overflow: "hidden"
            }}
            id={`${cell.original ? cell.original.id : cell.row.original.id}-${
              cell.column.id
            }`}
          >
            <FormattedMessage id={cell.value} defaultMessage={cell.value} />
          </span>
        );
      }
    } else {
      return "-";
    }
  };
};

/**
 * Returns cell rendering function for {@link ActionDropdown}.
 * @param {*} data
 */
const renderCellForActionDropdown = data => {
  return cell => (
    <ActionDropdown
      title=""
      options={cell.value}
      onMenuItemClick={
        data.sortCallback ? data.sortCallback.bind(null, cell) : ""
      }
      pullRight={false}
    />
  );
};

/**
 * Returns cell rendering function for {@link ActionDropdown}.
 * @param {*} data
 */
const renderCellForActionDropdownMUI = data => {
  let reduxStore = store?.getState();
  let theme = reduxStore?.reducer?.theme;
  let customStyle = {
    maxWidth: "26px",
    maxHeight: "26px",
    minWidth: "26px",
    minHeight: "26px"
  };

  return cell => (
    <ActionDropdownMUI
      id={cell.id ? `${cell.id}_tr_${cell.row.id}_${cell.column.id}` : ""}
      keyAsTestId={cell.keyAsTestId}
      title={<IconMUI icon={ArrowDropDownIcon} />}
      options={cell.value}
      onMenuItemClick={
        data.sortCallback ? data.sortCallback.bind(null, cell) : ""
      }
      menuAlign="left"
      color="secondary"
      variant="outlined"
      size="small"
      customButtonStyle={customStyle}
    />
  );
};

const formTitle = (stateData, valueSelected, item) => {
  const selectedItem = stateData && stateData.find(value => value.db === item);
  return valueSelected ? (
    valueSelected
  ) : selectedItem && selectedItem.new_instance ? (
    selectedItem.new_instance
  ) : (
    <FormattedMessage id="select" />
  );
};

const renderCellForActionSelectDropdownMUI = () => {
  return cell => {
    const [valueSelected, setValueSelected] = useState("");
    const data = useSelector(
      state => state?.destinations?.recovery?.sqlSummaryData
    );
    const selectedItems = useSelector(
      state => state?.destinations?.recovery?.selectedItemForRecovery
    );
    const stateData = data && data.databaseSettingsData;
    const item = cell.row.original.title;

    //For retaining the values
    useEffect(() => {
      if (item && selectedItems?.length > 0) {
        for (let i = 0; i <= selectedItems.length - 1; i++) {
          if (
            selectedItems[i].title === item &&
            selectedItems[i]?.new_instance
          ) {
            setValueSelected(selectedItems[i]?.new_instance);
            break;
          }
        }
      }
    }, [item]); // eslint-disable-line react-hooks/exhaustive-deps

    const { options, onChange, disabled } = cell.value;
    return (
      <div>
        <ActionSelectDropdown
          title={formTitle(stateData, valueSelected, item)}
          options={options}
          onMenuItemClick={data => {
            setValueSelected(data.value);
            onChange(data.value, cell.row.id);
          }}
          menuAlign="left"
          color="secondary"
          variant="outlined"
          size="small"
          customButtonStyle={{
            maxWidth: "26px",
            maxHeight: "26px",
            minWidth: "26px",
            minHeight: "26px"
          }}
          disabled={options && options.length === 0 ? true : disabled}
        />
      </div>
    );
  };
};

/**
 * Returns cell rendering function for single select dropdown
 * @param {*} data
 */
const renderCellForSingleSelectDropdown = data => {
  return cell => {
    let dropdownhandler = item => {
      let dropdown = Object.assign({}, cell.value);
      dropdown.selectedOption = item;
      data.editDropdownCallback(cell, dropdown);
    };

    return (
      <div className="edit-dropdown-wrapper">
        <ActionDropdown
          disabled={cell.value.disabled ? cell.value.disabled : false}
          title={cell.value.selectedOption}
          options={cell.value.options}
          onMenuItemClick={dropdownhandler}
        />
      </div>
    );
  };
};

/**
 * Returns cell rendering function for single select dropdown
 * @param {*} data
 */
const renderCellForSingleSelectDropdownMUI = data => {
  return cell => {
    let dropdownhandler = item => {
      let dropdown = Object.assign({}, cell.value);
      dropdown.selectedOption = item;
      data.editDropdownCallback(cell, dropdown);
    };

    return (
      <div className="edit-dropdown-wrapper" style={{ overflow: "hidden" }}>
        <ActionSelectDropdown
          disabled={cell.value.disabled ? cell.value.disabled : false}
          title={cell.value.selectedOption}
          options={cell.value.options}
          onMenuItemClick={dropdownhandler}
          menuAlign="left"
          color="secondary"
          variant="outlined"
          value={cell.value.selectedValue || cell.value.selectedOption || ""}
        />
      </div>
    );
  };
};

const renderCellForSingleScrollSelectDropdown = data => {
  return cell => {
    let dropdownhandler = item => {
      let dropdown = Object.assign({}, cell.value);
      dropdown.selectedOption = item;
      data.editDropdownCallback(cell, dropdown);
    };
    let options = cell.value.options.map(ele => {
      ele.label = ele.key;
      return ele;
    });

    return (
      <div className="tableScrollDropdown">
        <Select
          options={options}
          placeholder="Select"
          closeOnSelect
          disabled={cell.value.disabled ? cell.value.disabled : false}
          isSearchable={false}
          value={{
            label: cell.value.selectedOption,
            value: cell.value.selectedOption
          }}
          removeSelected={false}
          autosize={false}
          onChange={data => {
            dropdownhandler(data);
          }}
          scrollMenuIntoView={false}
          onSelectResetsInput={false}
          onCloseResetsInput
          onBlurResetsInput
          onMenuClose={() => {}}
        />
      </div>
    );
  };
};

const renderCellForSingleScrollSelectDropdownMUI = data => {
  return cell => {
    let dropdownhandler = item => {
      let dropdown = Object.assign({}, cell.value);
      dropdown.selectedOption = item;
      data.editDropdownCallback(cell, dropdown);
    };
    let options = cell.value.options.map(ele => {
      ele.label = ele.key;
      return ele;
    });

    return (
      <div className="tableScrollDropdown">
        <Select
          options={options}
          placeholder="Select"
          closeOnSelect
          disabled={cell.value.disabled ? cell.value.disabled : false}
          isSearchable={false}
          value={{
            label: cell.value.selectedOption,
            value: cell.value.selectedOption
          }}
          removeSelected={false}
          autosize={false}
          onChange={data => {
            dropdownhandler(data);
          }}
          scrollMenuIntoView={false}
          onSelectResetsInput={false}
          onCloseResetsInput
          onBlurResetsInput
          onMenuClose={() => {}}
        />
      </div>
    );
  };
};

/**
 * Returns cell rendering function for {@link Input}.
 * @param {*} data
 */
const renderCellForInput = data => {
  return cell => {
    const [cellValue, setCellValue] = useState(cell.value);
    let inputHandler = item => {
      setCellValue(item.target.value);
    };
    let onBlur = item => {
      data.editInputCallback && data.editInputCallback(cell, item.target.value);
    };
    return (
      <input
        id={`${cell.original._id}_${cell.column.id}`}
        type="text"
        className="small-size"
        value={cellValue}
        onChange={inputHandler}
        onBlur={onBlur}
      />
    );
  };
};

/**
 * Returns cell rendering function for {@link Input}.
 * @param {*} data
 */
const renderCellForPortInputMUI = data => {
  return cell => {
    const [cellValue, setCellValue] = useState(
      cell?.value?.value !== undefined
        ? cell?.value?.value
        : cell?.value
        ? cell?.value
        : ""
    );
    const onValueChange = event => {
      setCellValue(event.target.value);
      data.editInputCallback &&
        data.editInputCallback(cell, event.target.value, event.target.id);
    };

    const onBlurInput = event => {
      cell &&
        cell.value &&
        cell.value.onBlur &&
        cell.value.onBlur(event.target.value, cell.value.index, cell);

      // data.editInputCallback &&
      //   data.editInputCallback(cell, event.target.value,"");
    };

    return (
      <TextField
        id={`${cell?.row?.original?.id}_${cell?.column?.id}`}
        type="text"
        variant="outlined"
        className="small-size input-cell"
        name={cell?.value?.input_name}
        // meta={{ touched: false, error: "" }}
        hideFloatingLabel={true}
        value={cellValue}
        onChange={onValueChange}
        onBlur={onBlurInput}
        inputProps={{
          style: {
            minWidth: "50px"
          }
        }}
        disabled={cell?.value?.disabled}
      />
    );
  };
};

/**
 * Returns cell rendering function for {@link Input}.
 * @param {*} data
 */
const renderCellForInputMUI = data => {
  return cell => {
    const [cellValue, setCellValue] = useState(
      cell?.value?.value !== undefined
        ? cell?.value?.value
        : cell?.value
        ? cell?.value
        : ""
    );
    const onValueChange = event => {
      setCellValue(event.target.value);
    };

    const onBlurInput = event => {
      cell &&
        cell.value &&
        cell.value.onBlur &&
        cell.value.onBlur(event.target.value, cell.value.index, cell);

      data.editInputCallback &&
        data.editInputCallback(cell, event.target.value);
    };

    return (
      <TextField
        id={`${cell.original ? cell.original._id : cell.row.original._id}_${
          cell.column.id
        }`}
        type="text"
        variant="outlined"
        className="small-size input-cell"
        name={cell?.value?.input_name}
        hideFloatingLabel={true}
        value={cellValue}
        onChange={onValueChange}
        onBlur={onBlurInput}
        inputProps={{
          style: {
            minWidth: "50px"
          }
        }}
        disabled={cell?.value?.disabled}
      />
    );
  };
};

/**
 * Returns cell rendering function for {@link RadioButton}.
 * @param {*} data
 */
const renderCellForRadioButton = data => {
  return cell => {
    let radioButtonHandler = item => {
      data.radioButtonCallback(cell, item.target.value);
    };

    return (
      <div>
        <input
          type="radio"
          id={`radio_${cell.original.id}`}
          name="targetRadioButton"
          value={cell.value}
          onChange={radioButtonHandler}
        />
        <label htmlFor={`radio_${cell.original.id}`} />
      </div>
    );
  };
};

/**
 * Returns cell rendering function for {@link RadioButton}.
 * @param {*} data
 */
const renderCellForRadioButtonMUI = data => {
  return cell => {
    const [selectedValue, setSelectedValue] = useState(cell.row.original.id);
    let radioButtonHandler = event => {
      data.radioButtonCallback(cell, event.target.value);
      setSelectedValue(event.target.value);
    };

    return (
      <div>
        <Radio
          checked={selectedValue === cell.row.original.selectedValue}
          id={`radio_${cell.row.original.id}`}
          name="targetRadioButton"
          value={cell.row.original.id}
          onChange={radioButtonHandler}
        />
        <label htmlFor={`radio_${cell.row.original.id}`} />
      </div>
    );
  };
};

/**
 * Returns cell rendering function for {@link EditDropdown}.
 * @param {*} data
 */
const renderCellForEditDropdown = data => {
  return cell => {
    let dropdownhandler = item => {
      let dropdown = Object.assign({}, cell.value);
      dropdown.selectedOption = item;
      data.editDropdownCallback(cell, dropdown);
    };
    let inputHandler = item => {
      let input = Object.assign({}, cell.value);
      input.inputField = item.target.value;
      data.editDropdownCallback(cell, input);
    };

    return (
      <div className="edit-dropdown-wrapper">
        <input
          id={`${cell.original.id}-${cell.column.id}`}
          type="text"
          className="small-size"
          value={cell.value.inputField}
          onChange={inputHandler}
        />
        <ActionDropdown
          title={cell.value.selectedOption}
          options={cell.value.options}
          onMenuItemClick={dropdownhandler}
        />
      </div>
    );
  };
};

/**
 * Returns cell rendering function for {@link EditDropdown}.
 * @param {*} data
 */
const renderCellForEditDropdownMUI = data => {
  return cell => {
    let dropdownhandler = item => {
      let dropdown = Object.assign({}, cell.value);
      dropdown.selectedOption = item;
      data.editDropdownCallback(cell, dropdown);
    };
    let inputHandler = item => {
      let input = Object.assign({}, cell.value);
      input.inputField = item.target.value;
      data.editDropdownCallback(cell, input);
    };

    return (
      <div className="edit-dropdown-wrapper">
        <input
          id={`${cell.original ? cell.original.id : cell.row.original.id}-${
            cell.column.id
          }`}
          type="text"
          className="small-size"
          value={cell.value.inputField}
          onChange={inputHandler}
        />
        <ActionDropdown
          title={cell.value.selectedOption}
          options={cell.value.options}
          onMenuItemClick={dropdownhandler}
        />
      </div>
    );
  };
};

/**
 * Returns cell rendering function for {@link EditDropdown}.
 * @param {*} data
 */
const renderCellForMultiSelectDropdown = data => {
  return cell => {
    let dropdownhandler = (type, item) => {
      let dropdown = Object.assign({}, cell.value);
      dropdown.selectedOptions.splice(
        0,
        dropdown.selectedOptions.length,
        ...item
      );
      data.multiSelectCallback(cell, dropdown);
    };

    return (
      <div className="select-wrapper multi-select-dropdown-wrapper">
        <SelectDropdown
          dropdownOptions={cell.value.options}
          placeholder="Select"
          isMultiSelect
          getSelectedOption={dropdownhandler}
          optionType="type"
          selectedOption={cell.value.selectedOptions}
        />
      </div>
    );
  };
};

/**
 * Returns cell rendering function for {@link EditDropdown}.
 * @param {*} data
 */
const renderCellForMultiSelectDropdownMUI = data => {
  return cell => {
    let dropdownhandler = (type, item) => {
      let dropdown = Object.assign({}, cell.value);
      dropdown.selectedOptions.splice(
        0,
        dropdown.selectedOptions.length,
        ...item
      );
      data.multiSelectCallback(cell, dropdown);
    };

    return (
      <div className="select-wrapper multi-select-dropdown-wrapper">
        <SelectDropdown
          dropdownOptions={cell.value.options}
          placeholder="Select"
          isMultiSelect
          getSelectedOption={dropdownhandler}
          optionType="type"
          selectedOption={cell.value.selectedOptions}
        />
      </div>
    );
  };
};

const loadingBar = () => {
  return (
    <span>
      <div className="state-loading">
        {" "}
        &nbsp;
        <div className="state-loading-bar" />
        <div className="state-loading-bar" />
        <div className="state-loading-bar" />
      </div>
    </span>
  );
};

/**
 * Returns cell rendering function for {@link IconText}.
 */
const renderCellForHoverText = () => {
  return cell => {
    let cellValue = cell.value;
    let overlayData = cell.value
      ? cell.value.text
        ? cell.value.text
        : typeof cell.value !== "object"
        ? cell.value
        : null
      : null;
    return cellValue === "loading-status" ? (
      loadingBar()
    ) : overlayData ? (
      <Overlay
        type={OverlayTypes.TOOLTIP}
        placement={cell.value && cell.value.placement}
        overlayData={
          overlayData ? (
            <FormattedHTMLMessage
              id={overlayData}
              defaultMessage={overlayData}
              values={cell.value && cell.value.values}
            />
          ) : (
            ""
          )
        }
        data={
          cell.value
            ? cell.value.displayValue
              ? cell.value.displayValue
              : cell.value
            : "-"
        }
      />
    ) : (
      "-"
    );
  };
};

/**
 * Returns cell rendering function for {@link IconText}.
 */
const renderCellForHoverTextMUI = () => {
  return cell => {
    let cellValue = cell.value;
    let overlayData = cell.value
      ? cell.value.text
        ? cell.value.text
        : typeof cell.value !== "object"
        ? cell.value
        : null
      : null;
    return cellValue === "loading-status" ? (
      loadingBar()
    ) : overlayData ? (
      <Overlay
        type={OverlayTypes.TOOLTIP}
        placement={cell.value && cell.value.placement}
        overlayData={
          overlayData ? (
            <FormattedHTMLMessage
              id={overlayData}
              defaultMessage={overlayData}
              values={cell.value && cell.value.values}
            />
          ) : (
            ""
          )
        }
        style={{ fontSize: "14px" }}
        data={
          cell.value
            ? cell.value.displayValue
              ? cell.value.displayValue
              : cell.value
            : "-"
        }
      />
    ) : (
      "-"
    );
  };
};

/**
 * Returns cell rendering function for {@link IconText}.
 */
const renderCellForIconText = () => {
  return cell => {
    let text = cell.value
      ? cell.value.text
        ? cell.value.text
        : typeof cell.value !== "object"
        ? cell.value
        : null
      : null;
    return cell.value ? (
      <div>
        <span id={`${cell.original.id}-${cell.column.id}`}>
          <Icon
            icon={
              cell.value
                ? cell.value.iconClass
                  ? cell.value.iconClass
                  : typeof cell.value !== "object"
                  ? cell.value
                  : null
                : null
            }
          />
        </span>
        <span>
          {text ? <FormattedMessage id={text} defaultMessage={text} /> : "-"}
        </span>
        {cell.value.failedCount && <span>({cell.value.failedCount})</span>}
      </div>
    ) : (
      "-"
    );
  };
};

/**
 * Returns cell rendering function for {@link IconText}.
 */
const renderCellForIconTextMUI = () => {
  return cell => {
    let text = cell.value
      ? cell.value.text
        ? cell.value.text
        : typeof cell.value !== "object"
        ? cell.value
        : null
      : null;
    return cell.value ? (
      <div>
        <span
          id={`${cell.original ? cell.original.id : cell.row.original.id}-${
            cell.column.id
          }`}
        >
          <Icon
            icon={
              cell.value
                ? cell.value.iconClass
                  ? cell.value.iconClass
                  : typeof cell.value !== "object"
                  ? cell.value
                  : null
                : null
            }
          />
        </span>
        <span>
          {text ? <FormattedMessage id={text} defaultMessage={text} /> : "-"}
        </span>
        {cell.value.failedCount && <span>({cell.value.failedCount})</span>}
      </div>
    ) : (
      "-"
    );
  };
};

/**
 * Returns cell rendering function for {@link MutilineText}.
 * @param {*} data
 */
const renderCellForMultilineText = () => {
  return cell =>
    cell.value && (
      <div>
        <div id={`${cell.original.id}-${cell.column.id}`}>
          {cell.value.name}
        </div>
        {cell.value.status ? (
          <div>
            {cell.value.status} on {cell.value.date}
          </div>
        ) : (
          " "
        )}
      </div>
    );
};

/**
 * Returns cell rendering function for {@link MutilineText}.
 * @param {*} data
 */
const renderCellForMultilineTextMUI = () => {
  return cell =>
    cell.value && (
      <div>
        <div id={`${cell.row.original.id}-${cell.column.id}`}>
          {cell.value.name}
        </div>
        {cell.value.status ? (
          <div>
            {cell.value.status} on {cell.value.date}
          </div>
        ) : (
          " "
        )}
      </div>
    );
};
/**
 * Returns cell rendering function for {@link TwolineText}.
 * @param {*} data
 */
const renderCellForTwolineText = () => {
  return cell => {
    let overlayData = cell.value
      ? cell.value.text
        ? cell.value.text
        : typeof cell.value !== "object"
        ? cell.value
        : null
      : null;
    let iconClass = cell.value
      ? cell.value.iconClass
        ? cell.value.iconClass
        : overlayData
      : null;
    if (iconClass === "rps_success") iconClass = "success";
    if (get(cell, "value.hideOverlay")) {
      return iconClass ? <Icon icon={iconClass} /> : "-";
    }
    let icon;
    if (iconClass === "loading-status") {
      icon = (
        <span>
          <div className="state-loading">
            {" "}
            &nbsp;
            <div className="state-loading-bar" />
            <div className="state-loading-bar" />
            <div className="state-loading-bar" />
          </div>
        </span>
      );
    } else {
      icon = (
        <Overlay
          type={OverlayTypes.TOOLTIP}
          placement={cell.value && cell.value.placement}
          overlayData={
            overlayData ? (
              <FormattedHTMLMessage
                id={overlayData}
                defaultMessage={overlayData}
                values={cell.value && cell.value.values}
              />
            ) : (
              ""
            )
          }
          data={iconClass ? <Icon icon={iconClass} /> : ""}
        />
      );
    }
    return (
      cell.value && (
        <div
          style={{ display: "flex" }}
          id={`${cell.original.id}-${cell.column.id}`}
        >
          <div>{icon}</div>
          <div>
            {cell.value.line1 ? <div>{cell.value.line1}</div> : ""}
            {cell.value.line2 ? <div>{cell.value.line2}</div> : ""}
          </div>
        </div>
      )
    );
  };
};
const renderCellForTwolineTextMUI = () => {
  return cell => {
    let overlayData = cell.value
      ? cell.value.text
        ? cell.value.text
        : typeof cell.value !== "object"
        ? cell.value
        : null
      : null;
    let iconClass = cell.value
      ? cell.value.iconClass
        ? cell.value.iconClass
        : overlayData
      : null;
    if (iconClass === "rps_success") iconClass = "success";
    if (get(cell, "value.hideOverlay")) {
      return iconClass ? (
        <Icon icon={iconClass} style={{ height: "30px", width: "30px" }} />
      ) : (
        "-"
      );
    }
    let icon;
    if (iconClass === "loading-status") {
      icon = (
        <span>
          <div className="state-loading">
            {" "}
            &nbsp;
            <div className="state-loading-bar" />
            <div className="state-loading-bar" />
            <div className="state-loading-bar" />
          </div>
        </span>
      );
    } else {
      icon = (
        <Overlay
          type={OverlayTypes.TOOLTIP}
          placement={cell.value && cell.value.placement}
          overlayData={
            overlayData ? (
              <FormattedHTMLMessage
                id={overlayData}
                defaultMessage={overlayData}
                values={cell.value && cell.value.values}
              />
            ) : (
              ""
            )
          }
          data={
            iconClass ? (
              <Icon
                icon={iconClass}
                style={{ height: "30px", width: "30px" }}
              />
            ) : (
              ""
            )
          }
        />
      );
    }
    return (
      cell.value && (
        <div
          style={{
            display: "flex",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
          id={`${cell.original ? cell.original.id : cell.row.original.id}-${
            cell.column.id
          }`}
        >
          <div>{icon}</div>
          <div>
            {cell.value.line1 ? (
              <div
                className="datagrid-ellipsis"
                style={{ fontSize: cell.value.line2 ? "0.875rem" : "14px" }}
              >
                {cell.value.line1}
              </div>
            ) : (
              ""
            )}
            {cell.value.line2 ? (
              <div className="datagrid-ellipsis line2">{cell.value.line2}</div>
            ) : (
              ""
            )}
          </div>
        </div>
      )
    );
  };
};

/**
 *
 */
const renderCellForIdValue = () => {
  return cell => (
    <div id={`${cell.original.id}-${cell.column.id}`}>
      {cell && cell.value ? cell.value.value : "-"}
    </div>
  );
};

const renderCellForIdValueMUI = () => {
  return cell => (
    <div
      id={`${cell.original ? cell.original.id : cell.row.original.id}-${
        cell.column.id
      }`}
    >
      {cell && cell.value ? cell.value.value : "-"}
    </div>
  );
};

/**
 * Returns cell rendering for {@link ProgressText}
 */
const renderCellForProgressText = () => {
  return cell => {
    let text = cell.value
      ? cell.value.text
        ? cell.value.text
        : typeof cell.value !== "object"
        ? cell.value
        : null
      : null;
    let job_type = cell.original.job_type;
    let status = cell.value.value;
    let statusToShow = status && status.split("(")[0];
    if (
      cell.value &&
      cell.value.value &&
      cell.value.percentage !== null &&
      cell.value.text === undefined &&
      cell.value.hoverText
    ) {
      return (
        <div id={`${cell.original.id}-${cell.column.id}`}>
          <OverlayTrigger
            trigger={["hover", "focus"]}
            rootClose={false}
            delay={{ show: 0, hide: 200 }}
            placement="bottom"
            overlay={<Tooltip>{cell.value.hoverText}</Tooltip>}
          >
            <div>
              <ProgressBarComponent
                now={cell.value.percentage}
                bsStyle={cell.value.bsStyle}
              />
              <div
                id={`${cell.original.id}-${cell.column.id}-content`}
                className="progress-content"
              >
                {cell.value.value}
              </div>
            </div>
          </OverlayTrigger>
        </div>
      );
    } else if (
      cell.value &&
      cell.value.value &&
      cell.value.percentage !== null &&
      cell.value.text === undefined
    ) {
      return (
        <div id={`${cell.original.id}-${cell.column.id}`}>
          <ProgressBarComponent
            now={cell.value.percentage}
            bsStyle={cell.value.bsStyle}
          />
          <div
            id={`${cell.original.id}-${cell.column.id}-content`}
            className="progress-content"
          >
            {cell.value.value}
          </div>
        </div>
      );
    } else if (
      cell.value &&
      cell.value.text === "active" &&
      cell.value.percentage !== null
    ) {
      return (
        <div id={`${cell.original.id}-${cell.column.id}`}>
          {JOB_TYPES_HIDE_PROGRESS_BAR.includes(job_type) ? (
            <div
              id={`${cell.original.id}-${cell.column.id}-content`}
              className="progress-content"
            >
              {statusToShow}
            </div>
          ) : (
            <React.Fragment>
              <ProgressBarComponent
                now={cell.value.percentage}
                bsStyle={cell.value.bsStyle}
              />
              <div
                id={`${cell.original.id}-${cell.column.id}-content`}
                className="progress-content"
              >
                {cell.value.value}
              </div>
            </React.Fragment>
          )}
        </div>
      );
    } else if (
      cell.value &&
      cell.value.iconClass &&
      cell.value.text !== "active"
    ) {
      return (
        <div>
          <span id={`${cell.original.id}-${cell.column.id}`}>
            <Icon
              icon={
                cell.value
                  ? cell.value.iconClass
                    ? cell.value.iconClass
                    : typeof cell.value !== "object"
                    ? cell.value
                    : null
                  : null
              }
            />
          </span>
          <span>
            {text ? <FormattedMessage id={text} defaultMessage={text} /> : "-"}
          </span>
          {cell.value.failedCount && <span>({cell.value.failedCount})</span>}
        </div>
      );
    } else if (cell.value && cell.value.value && cell.value.hoverText) {
      return (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose={false}
          delay={{ show: 0, hide: 200 }}
          placement="bottom"
          overlay={<Tooltip>{cell.value.hoverText}</Tooltip>}
        >
          <span>{cell.value.value}</span>
        </OverlayTrigger>
      );
    } else {
      if (cell.value === "loading-status") {
        return loadingBar();
      } else return cell.value && cell.value.value ? cell.value.value : "-";
    }
  };
};

/**
 * Returns cell rendering for {@link ProgressText}
 */
const renderCellForProgressTextWithoutPercentageMUI = () => {
  let reduxStore = store?.getState();
  let theme = reduxStore?.reducer?.theme;
  return cell => {
    let text = cell.value
      ? cell.value.text
        ? cell.value.text
        : typeof cell.value !== "object"
        ? cell.value
        : null
      : null;
    let job_type = cell.original
      ? cell.original.job_type
      : cell.row.original.job_type;
    let status = cell.value.value;
    let statusToShow = status && status.split("(")[0];
    if (
      cell.value &&
      cell.value.value &&
      cell.value.percentage !== null &&
      cell.value.text === undefined &&
      cell.value.hoverText
    ) {
      return (
        <div
          id={`${cell.original ? cell.original.id : cell.row.original.id}-${
            cell.column.id
          }`}
        >
          <OverlayTrigger
            trigger={["hover", "focus"]}
            rootClose={false}
            delay={{ show: 0, hide: 200 }}
            placement="bottom"
            overlay={<Tooltip>{cell.value.hoverText}</Tooltip>}
            style={{ fontSize: "14px" }}
          >
            <div>
              <ProgressBarComponentMUI
                now={cell.value.percentage}
                bsStyle={cell.value.bsStyle}
              />
              <div
                id={`${
                  cell.original ? cell.original.id : cell.row.original.id
                }-${cell.column.id}-content`}
                className="progress-content"
              >
                <ProgressBarLinkComponentMUI
                  cell={cell}
                  child={cell.value.value}
                  store={store}
                />
              </div>
            </div>
          </OverlayTrigger>
        </div>
      );
    } else if (
      cell.value &&
      cell.value.value &&
      cell.value.percentage !== null &&
      cell.value.text === undefined
    ) {
      return (
        <div
          id={`${cell.original ? cell.original.id : cell.row.original.id}-${
            cell.column.id
          }`}
          style={{ fontSize: "14px" }}
        >
          <ProgressBarComponentMUI
            now={cell.value.percentage}
            bsStyle={cell.value.bsStyle}
          />
          <div
            id={`${cell.original ? cell.original.id : cell.row.original.id}-${
              cell.column.id
            }-content`}
            className="progress-content"
          >
            <ProgressBarLinkComponentMUI
              cell={cell}
              child={cell.value.value}
              store={store}
            />
          </div>
        </div>
      );
    } else if (
      cell.value &&
      cell.value.text === "active" &&
      cell.value.percentage !== null
    ) {
      return (
        <div
          style={
            JOB_TYPES_HIDE_PROGRESS_BAR.includes(job_type)
              ? { margin: "5px 0px 0px 0px" }
              : { margin: "5px 0px 0px 0px" }
          }
          id={`${cell.original ? cell.original.id : cell.row.original.id}-${
            cell.column.id
          }`}
        >
          {JOB_TYPES_HIDE_PROGRESS_BAR.includes(job_type) ? (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              rootClose={false}
              delay={{ show: 0, hide: 200 }}
              placement="bottom"
              overlay={<Tooltip>{cell.value.job_name}</Tooltip>}
              style={{ fontSize: "14px" }}
            >
              <div>
                <div
                  id={`${
                    cell.original ? cell.original.id : cell.row.original.id
                  }-${cell.column.id}-content`}
                  style={{ paddingTop: 0 }}
                  className="progress-content"
                >
                  <FormattedMessage
                    id="active"
                    defaultMessage={"In progress"}
                  />
                </div>
                <div
                  id={`${
                    cell.original ? cell.original.id : cell.row.original.id
                  }-${cell.column.id}-content`}
                  className="progress-content"
                  style={{ paddingTop: 0 }}
                >
                  <ProgressBarLinkComponentMUI
                    cell={cell}
                    child={statusToShow}
                    store={store}
                  />
                </div>
              </div>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              rootClose={false}
              delay={{ show: 0, hide: 200 }}
              placement="bottom"
              overlay={<Tooltip>{cell.value.job_name}</Tooltip>}
              style={{ fontSize: "14px" }}
            >
              <div>
                <ProgressBarComponentMUI
                  now={cell.value.percentage}
                  bsStyle={cell.value.bsStyle}
                />
                <div
                  id={`${
                    cell.original ? cell.original.id : cell.row.original.id
                  }-${cell.column.id}-content`}
                  className="progress-content"
                  style={{ paddingTop: 3 }}
                >
                  <ProgressBarLinkComponentMUI
                    cell={cell}
                    child={cell.value.value}
                    store={store}
                  />
                </div>
              </div>
            </OverlayTrigger>
          )}
        </div>
      );
    } else if (
      cell.value &&
      cell.value.iconClass &&
      cell.value.text !== "active"
    ) {
      return (
        <div>
          <span
            id={`${cell.original ? cell.original.id : cell.row.original.id}-${
              cell.column.id
            }`}
          >
            {IconMapping[
              cell.value
                ? cell.value.iconClass
                  ? cell.value.iconClass
                  : typeof cell.value !== "object"
                  ? cell.value
                  : null
                : null
            ] ? (
              <IconMUI
                icon={
                  IconMapping[
                    cell.value
                      ? cell.value.iconClass
                        ? cell.value.iconClass
                        : typeof cell.value !== "object"
                        ? cell.value
                        : null
                      : null
                  ]["icon"]
                }
                color={
                  IconMapping[
                    cell.value
                      ? cell.value.iconClass
                        ? cell.value.iconClass
                        : typeof cell.value !== "object"
                        ? cell.value
                        : null
                      : null
                  ]["color"]
                }
              />
            ) : (
              <Icon
                style={{
                  height: 30
                }}
                icon={
                  cell.value
                    ? cell.value.iconClass
                      ? cell.value.iconClass
                      : typeof cell.value !== "object"
                      ? cell.value
                      : null
                    : null
                }
              />
            )}
          </span>
          <span
            style={{
              marginLeft: 5
            }}
          >
            <OverlayTrigger
              trigger={["hover", "focus"]}
              rootClose={false}
              delay={{ show: 0, hide: 200 }}
              placement="bottom"
              overlay={<Tooltip>{cell.value.job_name}</Tooltip>}
              style={{ fontSize: "14px" }}
            >
              <Link
                id={`${
                  cell.original ? cell.original.id : cell.row.original.id
                }-${cell.column.id}-job-type`}
                to={cell.value.href || "#"}
                href={cell.value.href || "#"}
                target={cell.value.target || "_self"}
                className={`
         ${
           cell.value.isDisabledPerm
             ? "link-cell-in-progress link-disabled-perm wordBreak"
             : cell.value.isDisabled
             ? "link-cell-in-progress link-disabled wordBreak"
             : "link-cell-in-progress wordBreak"
         } ${theme?.palette?.mode === DARK_MODE ? "link-dark-theme" : ""}
        `}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  //whiteSpace: "nowrap",
                  fontSize: "14px",
                  display: "inline",
                  color: `${
                    cell.value.isDisabledPerm || cell.value.isDisabled
                      ? "inherit"
                      : handleLinkColor()
                  }`
                }}
              >
                {text ? (
                  <FormattedMessage id={text} defaultMessage={text} />
                ) : (
                  "-"
                )}
              </Link>
            </OverlayTrigger>
          </span>
          {cell.value.failedCount && <span>({cell.value.failedCount})</span>}
        </div>
      );
    } else if (cell.value && cell.value.value && cell.value.hoverText) {
      return (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose={false}
          delay={{ show: 0, hide: 200 }}
          placement="bottom"
          overlay={<Tooltip>{cell.value.hoverText}</Tooltip>}
        >
          <span>{cell.value.value}</span>
        </OverlayTrigger>
      );
    } else {
      if (cell.value === "loading-status") {
        return loadingBar();
      } else return cell.value && cell.value.value ? cell.value.value : "-";
    }
  };
};

/**
 * Returns cell rendering for {@link ProgressText}
 */
const renderCellForProgressTextMUI = () => {
  return cell => {
    let text = cell.value
      ? cell.value.text
        ? cell.value.text
        : typeof cell.value !== "object"
        ? cell.value
        : null
      : null;
    let job_type = cell.original
      ? cell.original.job_type
      : cell.row.original.job_type;
    let status = cell.value.value;
    let statusToShow = status && status.split("(")[0];
    if (
      cell.value &&
      cell.value.value &&
      cell.value.percentage !== null &&
      cell.value.text === undefined &&
      cell.value.hoverText
    ) {
      return (
        <div
          id={`${cell.original ? cell.original.id : cell.row.original.id}-${
            cell.column.id
          }`}
        >
          <OverlayTrigger
            trigger={["hover", "focus"]}
            rootClose={false}
            delay={{ show: 0, hide: 200 }}
            placement="bottom"
            overlay={<Tooltip>{cell.value.hoverText}</Tooltip>}
            style={{ fontSize: "14px" }}
          >
            <div>
              <ProgressBarComponent
                now={cell.value.percentage}
                bsStyle={cell.value.bsStyle}
              />
              <div
                id={`${
                  cell.original ? cell.original.id : cell.row.original.id
                }-${cell.column.id}-content`}
                className="progress-content"
              >
                {cell.value.value}
              </div>
            </div>
          </OverlayTrigger>
        </div>
      );
    } else if (
      cell.value &&
      cell.value.value &&
      cell.value.percentage !== null &&
      cell.value.text === undefined
    ) {
      return (
        <div
          id={`${cell.original ? cell.original.id : cell.row.original.id}-${
            cell.column.id
          }`}
          style={{ fontSize: "14px" }}
        >
          <ProgressBarComponent
            now={cell.value.percentage}
            bsStyle={cell.value.bsStyle}
          />
          <div
            id={`${cell.original ? cell.original.id : cell.row.original.id}-${
              cell.column.id
            }-content`}
            className="progress-content"
          >
            {cell.value.value}
          </div>
        </div>
      );
    } else if (
      cell.value &&
      cell.value.text === "active" &&
      cell.value.percentage !== null
    ) {
      return (
        <div
          id={`${cell.original ? cell.original.id : cell.row.original.id}-${
            cell.column.id
          }`}
        >
          {JOB_TYPES_HIDE_PROGRESS_BAR.includes(job_type) ? (
            <div
              id={`${cell.original ? cell.original.id : cell.row.original.id}-${
                cell.column.id
              }-content`}
              className="progress-content"
            >
              {statusToShow}
            </div>
          ) : (
            <React.Fragment>
              <ProgressBarComponent
                now={cell.value.percentage}
                bsStyle={cell.value.bsStyle}
              />
              <div
                id={`${
                  cell.original ? cell.original.id : cell.row.original.id
                }-${cell.column.id}-content`}
                className="progress-content"
              >
                {cell.value.value}
              </div>
            </React.Fragment>
          )}
        </div>
      );
    } else if (
      cell.value &&
      cell.value.iconClass &&
      cell.value.text !== "active"
    ) {
      return (
        <div>
          <span
            id={`${cell.original ? cell.original.id : cell.row.original.id}-${
              cell.column.id
            }`}
          >
            {IconMapping[
              cell.value
                ? cell.value.iconClass
                  ? cell.value.iconClass
                  : typeof cell.value !== "object"
                  ? cell.value
                  : null
                : null
            ] ? (
              <IconMUI
                icon={
                  IconMapping[
                    cell.value
                      ? cell.value.iconClass
                        ? cell.value.iconClass
                        : typeof cell.value !== "object"
                        ? cell.value
                        : null
                      : null
                  ]["icon"]
                }
                color={
                  IconMapping[
                    cell.value
                      ? cell.value.iconClass
                        ? cell.value.iconClass
                        : typeof cell.value !== "object"
                        ? cell.value
                        : null
                      : null
                  ]["color"]
                }
              />
            ) : (
              <Icon
                style={{
                  height: 30
                }}
                icon={
                  cell.value
                    ? cell.value.iconClass
                      ? cell.value.iconClass
                      : typeof cell.value !== "object"
                      ? cell.value
                      : null
                    : null
                }
              />
            )}
          </span>
          <span
            style={{
              marginLeft: 5
            }}
          >
            {text ? <FormattedMessage id={text} defaultMessage={text} /> : "-"}
          </span>
          {cell.value.failedCount && <span>({cell.value.failedCount})</span>}
        </div>
      );
    } else if (cell.value && cell.value.value && cell.value.hoverText) {
      return (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose={false}
          delay={{ show: 0, hide: 200 }}
          placement="bottom"
          overlay={<Tooltip>{cell.value.hoverText}</Tooltip>}
        >
          <span>{cell.value.value}</span>
        </OverlayTrigger>
      );
    } else {
      if (cell.value === "loading-status") {
        return loadingBar();
      } else return cell.value && cell.value.value ? cell.value.value : "-";
    }
  };
};

/**
 * Returns progress bar for multiType cell
 * @param {object} value
 */
const progressBarMultiTypeCell = (value, index) => {
  return (
    <div className="progress-bar-wrapper" key={index}>
      <ProgressBarComponent now={value.percentage} />
      <div id={`${value.percentage}-Completed`}>
        <FormattedMessage id={value.status} />
        <FormattedMessage
          id="%_completed"
          values={{ percent: value.percentage }}
        />
      </div>
    </div>
  );
};

/**
 * Returns date time for multiType cell
 * @param {object} value
 */
const dateTimeMultiTypeCell = (value, index) => {
  return (
    <div id={index}>
      <div>{value.name}</div>
      {value.status ? (
        <div id={`${value.status}-${value.name}`}>
          {value.status} on {value.date}
        </div>
      ) : (
        " "
      )}
    </div>
  );
};

/**
 * Returns icon with text for multiType cell
 * @param {object} value
 */
const iconTextMultiTypeCell = (value, index) => {
  return (
    <div className="d-flex align-items-center popover-icon-cell" id={index}>
      <span id={`${value.iconClass}-${index}-icon`}>
        <Icon icon={value.iconClass} />
      </span>
      <span id={`${value.failedCount}-${value.status}`}>{value.status}</span>
      {value.failedCount ? <span>({value.failedCount})</span> : ""}
    </div>
  );
};

/**
 * Returns popover for multiType cell
 * @param {array of object} data
 */
const renderPopover = data => {
  return (
    <Popover id="cell-popover-progressbar" title="">
      {data.map((value, index) => {
        switch (value.text) {
          case GridColumnStatusTypes.IN_PROGRESS:
            return progressBarMultiTypeCell(value, index);
          case GridColumnStatusTypes.SCHEDULED:
            return dateTimeMultiTypeCell(value, index);
          default:
            return iconTextMultiTypeCell(value, index);
        }
      })}
    </Popover>
  );
};

/**
 * Returns cell rendering for multiType cell
 */
const renderCellForMutiType = () => {
  return cell => {
    if (Array.isArray(cell)) {
      if (cell.value && cell.value[0].text === "") {
        return "-";
      } else if (cell.value.length === 1) {
        return cell.value.map((value, index) => {
          switch (value.text) {
            case GridColumnStatusTypes.IN_PROGRESS:
              return progressBarMultiTypeCell(value, index);
            case GridColumnStatusTypes.SCHEDULED:
              return dateTimeMultiTypeCell(value, index);
            default:
              return iconTextMultiTypeCell(value, index);
          }
        });
      } else {
        return (
          <OverlayTrigger
            rootClose
            trigger="click"
            id="popover"
            placement="bottom"
            overlay={renderPopover(cell.value)}
          >
            <span>{cell.value.length} Jobs</span>
          </OverlayTrigger>
        );
      }
    } else {
      return null;
    }
  };
};

/**
 * Returns cell rendering for multiType cell
 */
const renderCellForMutiTypeMUI = () => {
  return cell => {
    if (Array.isArray(cell)) {
      if (cell.value && cell.value[0].text === "") {
        return "-";
      } else if (cell.value.length === 1) {
        return cell.value.map((value, index) => {
          switch (value.text) {
            case GridColumnStatusTypes.IN_PROGRESS:
              return progressBarMultiTypeCell(value, index);
            case GridColumnStatusTypes.SCHEDULED:
              return dateTimeMultiTypeCell(value, index);
            default:
              return iconTextMultiTypeCell(value, index);
          }
        });
      } else {
        return (
          <OverlayTrigger
            rootClose
            trigger="click"
            id="popover"
            placement="bottom"
            overlay={renderPopover(cell.value)}
          >
            <span>{cell.value.length} Jobs</span>
          </OverlayTrigger>
        );
      }
    } else {
      return null;
    }
  };
};

/**
 * Returns icon with text for multiType cell
 */
const renderCellForImage = () => {
  return cell => (
    <div
      className="d-flex align-items-center"
      id={`${cell.original.id}-${cell.column.id}-img`}
    >
      {cell.value.initial && cell.value.initial === true ? (
        <div>
          {cell.value.imageUrl && cell.value.imageUrl.indexOf(null) === -1 ? (
            <img
              alt="img"
              style={{
                height: "26px",
                width: "26px",
                borderRadius: "50px"
              }}
              src={cell.value.imageUrl}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <span className="initial-letter">
                {cell.value && cell.value.name.substr(0, 1)}
              </span>
            </div>
          )}
        </div>
      ) : (
        <img
          alt="img"
          style={{
            height: "26px",
            width: "26px",
            borderRadius: "50px"
          }}
          src={
            cell.value.imageUrl && cell.value.imageUrl.indexOf(null) === -1
              ? cell.value.imageUrl
              : ProfileImgUrl
          }
        />
      )}
      <span
        style={{
          paddingLeft: "16px",
          cursor: "pointer",
          color: handleLinkColor()
        }}
        id={`${cell.original.id}-${cell.column.id}-name`}
      >
        {cell.value.name}
      </span>
    </div>
  );
};

/**
 * Returns icon with text for multiType cell
 */
const renderCellForImageMUI = () => {
  return cell => (
    <div
      className="d-flex align-items-center"
      id={`${cell.original ? cell.original.id : cell.row.original.id}-${
        cell.column.id
      }-img`}
    >
      {cell.value.initial && cell.value.initial === true ? (
        <div>
          {cell.value.imageUrl && cell.value.imageUrl.indexOf(null) === -1 ? (
            <img
              alt="img"
              style={{
                height: "26px",
                width: "26px",
                borderRadius: "50px"
              }}
              src={cell.value.imageUrl}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <span className="initial-letter">
                {cell.value && cell.value.name.substr(0, 1)}
              </span>
            </div>
          )}
        </div>
      ) : (
        <img
          alt="img"
          style={{
            height: "26px",
            width: "26px",
            borderRadius: "50px"
          }}
          src={
            cell.value.imageUrl && cell.value.imageUrl.indexOf(null) === -1
              ? cell.value.imageUrl
              : ProfileImgUrl
          }
        />
      )}
      <span
        style={{
          paddingLeft: "16px",
          cursor: "pointer",
          color: handleLinkColor()
        }}
        id={`${cell.original ? cell.original.id : cell.row.original.id}-${
          cell.column.id
        }-name`}
      >
        {cell.value.name}
      </span>
    </div>
  );
};

/**
 * Returns grid header rendering function for text.
 * @param {*} data
 */
const renderHeaderForText = data => {
  return (
    <span>
      <FormattedBrandMessage
        id={data.displayIdentifier}
        defaultMessage={data.defaultDisplayMessage}
      />
    </span>
  );
};

const renderHeaderForTextMUI = data => {
  return (
    <div
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
        whiteSpace: "nowrap",
        fontSize: "14px"
      }}
    >
      <FormattedBrandMessage
        id={data.displayIdentifier}
        defaultMessage={data.defaultDisplayMessage}
      />
    </div>
  );
};

/**
 * Returns grid header rendering function for checkbox.
 */
const renderHeaderForSelect = () => {
  return (
    <div>
      <input id="select-th" type="checkbox" />
      <label htmlFor="select-th" />
    </div>
  );
};

/**
 * Returns grid header rendering function for checkbox.
 */
const renderHeaderForSelectMUI = () => {
  return (
    <div>
      <input id="select-th" type="checkbox" />
      <label htmlFor="select-th" />
    </div>
  );
};

const renderHeaderForIconMUI = data => {
  const onClick =
    data && data.addtionalOptions && data.addtionalOptions.onClick;
  const style = data && data.addtionalOptions && data.addtionalOptions.style;
  const iconClass =
    data && data.addtionalOptions && data.addtionalOptions.iconClass;
  return (
    <div onClick={onClick} style={style}>
      {IconMapping[iconClass] ? (
        <IconMUI
          icon={IconMapping[iconClass]["icon"]}
          color={IconMapping[iconClass]["color"]}
        />
      ) : (
        <Icon icon={iconClass} style={{ height: "30px", width: "30px" }} />
      )}
    </div>
  );
};

/**
 * Returns grid header rendering function for {@link HeaderDropdown}.
 * @param {*} data
 */
const renderHeaderForTextSelect = data => {
  return (
    <HeaderDropdown
      onMenuItemClick={data.sortCallback}
      name={data.value}
      displayIdentifier={data.displayIdentifier}
      defaultMessage={data.defaultDisplayMessage}
      options={data.addtionalOptions}
    />
  );
};

/**
 * Returns grid header rendering function for {@link HeaderDropdown}.
 * @param {*} data
 */
const renderHeaderForTextSelectMUI = data => {
  return (
    <HeaderDropdown
      onMenuItemClick={data.sortCallback}
      name={data.value}
      displayIdentifier={data.displayIdentifier}
      defaultMessage={data.defaultDisplayMessage}
      options={data.addtionalOptions}
    />
  );
};

const renderIconCell = () => {
  return cell => {
    let overlayData = cell.value
      ? cell.value.text
        ? cell.value.text
        : typeof cell.value !== "object"
        ? cell.value
        : null
      : null;
    let iconClass = cell.value
      ? cell.value.iconClass
        ? cell.value.iconClass
        : overlayData
      : null;
    return (
      <Overlay
        type={OverlayTypes.TOOLTIP}
        placement={cell.value && cell.value.placement}
        overlayData={
          overlayData ? (
            <FormattedHTMLMessage
              id={overlayData}
              defaultMessage={overlayData}
              values={cell.value && cell.value.values}
            />
          ) : (
            ""
          )
        }
        data={iconClass ? Icons[iconClass] : "-"}
      />
    );
  };
};

const renderIconCellMUI = () => {
  return cell => {
    let overlayData = cell.value
      ? cell.value.text
        ? cell.value.text
        : typeof cell.value !== "object"
        ? cell.value
        : null
      : null;
    let iconClass = cell.value
      ? cell.value.iconClass
        ? cell.value.iconClass
        : overlayData
      : null;
    return (
      <Overlay
        type={OverlayTypes.TOOLTIP}
        placement={cell.value && cell.value.placement}
        overlayData={
          overlayData ? (
            <FormattedHTMLMessage
              id={overlayData}
              defaultMessage={overlayData}
              values={cell.value && cell.value.values}
            />
          ) : (
            ""
          )
        }
        data={iconClass ? Icons[iconClass] : "-"}
      />
    );
  };
};

/**
 * Returns cell rendering function for {@link FormattedMessage}.
 */
const renderFormattedLocalizedTextCell = () => {
  return cell => {
    return cell.value ? <span>{cell.value}</span> : "-";
  };
};

/**
 * Returns cell rendering function for {@link FormattedMessage}.
 */
const renderFormattedLocalizedTextCellMUI = () => {
  return cell => {
    return cell.value ? <span>{cell.value}</span> : "-";
  };
};

const renderCellForDuration = () => {
  return cell => {
    return cell.value ? <span>{cell.value.value}</span> : "-";
  };
};

const renderCellForDurationMUI = () => {
  return cell => {
    return cell.value ? <span>{cell.value.value}</span> : "-";
  };
};

const renderCellForDurationMomentMUI = () => {
  return cell => {
    return cell.value ? (
      cell.value.hoverText ? (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose={false}
          delay={{ show: 0, hide: 200 }}
          placement="bottom"
          overlay={
            !cell.value?.hoverHeader ? (
              <Tooltip>{cell.value.hoverText}</Tooltip>
            ) : (
              <Tooltip>
                <strong>
                  <FormattedMessage id={cell.value.hoverHeader} />
                </strong>
                <br />
                {cell.value.hoverText}
              </Tooltip>
            )
          }
          style={{ fontSize: "14px" }}
        >
          <span>{cell.value.value}</span>
        </OverlayTrigger>
      ) : (
        <span>{cell.value.value}</span>
      )
    ) : (
      "-"
    );
  };
};

/**
 * Returns cell rendering function for {@link Download}.
 */
const renderCellForIconLink = () => {
  return cell => {
    if (cell.value && cell.value.show) {
      let title = cell.value
        ? cell.value.title
          ? cell.value.title
          : typeof cell.value !== "object"
          ? cell.value
          : ""
        : "";
      let iconClass = cell.value
        ? cell.value.iconClass
          ? cell.value.iconClass
          : ""
        : "";
      return cell.value ? (
        <div>
          <div
            id={`${cell.original.id}-${cell.column.id}`}
            onClick={event => cell.value.onClickAction(event, cell.original.id)}
          >
            <Overlay
              type={OverlayTypes.TOOLTIP}
              placement={cell.value && cell.value.placement}
              overlayData={
                cell.value.tooltip ? (
                  <FormattedHTMLMessage
                    id={cell.value.tooltip}
                    defaultMessage={title}
                  />
                ) : (
                  ""
                )
              }
              data={iconClass ? <Icon icon={iconClass} /> : "-"}
            />
          </div>
        </div>
      ) : (
        "-"
      );
    } else return "";
  };
};

/**
 * Returns cell rendering function for {@link Download}.
 */
const renderCellForIconLinkMUI = () => {
  return cell => {
    const style = cell && cell.value && cell.value.style;
    if (cell.value && cell.value.show) {
      let title = cell.value
        ? cell.value.title
          ? cell.value.title
          : typeof cell.value !== "object"
          ? cell.value
          : ""
        : "";
      let iconClass = cell.value
        ? cell.value.iconClass
          ? cell.value.iconClass
          : ""
        : "";
      return cell.value ? (
        <div>
          <div
            id={`${cell.original ? cell.original.id : cell.row.original.id}-${
              cell.column.id
            }`}
            style={style || {}}
            onClick={event =>
              cell.value.onClickAction(
                event,
                cell.original ? cell.original.id : cell.row.original.id
              )
            }
          >
            <Overlay
              type={OverlayTypes.TOOLTIP}
              placement={cell.value && cell.value.placement}
              overlayData={
                cell.value.tooltip ? (
                  <FormattedHTMLMessage
                    id={cell.value.tooltip}
                    defaultMessage={title}
                  />
                ) : (
                  ""
                )
              }
              data={
                iconClass ? (
                  IconMapping[iconClass] ? (
                    <IconMUI
                      icon={IconMapping[iconClass]["icon"]}
                      color={IconMapping[iconClass]["color"]}
                    />
                  ) : (
                    <Icon
                      icon={iconClass}
                      style={{ height: "30px", width: "30px" }}
                    />
                  )
                ) : (
                  "-"
                )
              }
            />
          </div>
        </div>
      ) : (
        "-"
      );
    } else return "";
  };
};

const renderCellForButtonMUI = () => {
  return cell => {
    if (cell && cell.value) {
      const { textOnButton, onClick } = cell.value;
      return cell.value ? (
        <Button
          id={
            cell.id
              ? `${cell.id}_tr_${cell.row.id}_${cell.column.id}_button`
              : ""
          }
          color="primary"
          content={<FormattedMessage id={`grid.button.${textOnButton}`} />}
          variant="contained"
          onClick={onClick}
        />
      ) : (
        ""
      );
    } else {
      return "";
    }
  };
};

/**
 * Returns cell rendering function for {@link ActionButton}.
 */
const renderCellForTextAndButton = () => {
  return cell => {
    if (cell && cell.value) {
      const { nameOnButton, preText, onClick, index } = cell.value;
      return cell.value ? (
        <div className="tr-center-align">
          <p
            style={{
              fontSize: "14px",
              wordBreak: "break-word",
              marginRight: "10px",
              minWidth: "308px",
              marginBottom: "0"
            }}
          >
            {preText}
          </p>
          <Button
            id={
              cell.id
                ? `${cell.id}_tr_${cell.row.id}_${cell.column.id}_button`
                : ""
            }
            style={{
              minWidth: "50px",
              height: "30px",
              lineHeight: "30px",
              padding: "5px",
              marginTop: "10px"
            }}
            color="primary"
            content={nameOnButton}
            variant="contained"
            onClick={() => onClick(index)}
          />
        </div>
      ) : (
        ""
      );
    } else {
      return "";
    }
  };
};

/**
 * Returns cell rendering function for {@link ActionButton}.
 * @param {*} data
 */
const renderCellForActionButton = data => {
  return cell => {
    if (cell && cell.value) {
      const { nameOnButton, preText, display, disabledButton } = cell.value;
      return cell.value ? (
        <div style={{ display: display ? "" : "none" }}>
          {preText && (
            <FormattedMessage id={`policy.source.grid.exclusion.${preText}`} />
          )}
          <button
            className="btn btn-secondary"
            disabled={disabledButton}
            style={
              preText === "no" ? { marginLeft: "14px" } : { marginLeft: "10px" }
            }
            onClick={
              data.buttonActionCallback
                ? data.buttonActionCallback.bind(null, cell)
                : ""
            }
          >
            <FormattedMessage
              id={`policy.source.grid.action.button.${nameOnButton}`}
            />
          </button>
        </div>
      ) : (
        ""
      );
    } else {
      return "";
    }
  };
};

/**
 * Returns cell rendering function for {@link ActionButton}.
 * @param {*} data
 */
const renderCellForActionButtonMUI = data => {
  return cell => {
    if (cell && cell.value) {
      const { nameOnButton, preText, display } = cell.value;
      return cell.value ? (
        <div style={{ display: display ? "" : "none" }}>
          {preText && (
            <FormattedMessage id={`policy.source.grid.exclusion.${preText}`} />
          )}
          <Button
            id={
              cell.id
                ? `${cell.id}_tr_${cell.row.id}_${cell.column.id}_button`
                : ""
            }
            color="secondary"
            variant="outlined"
            style={
              preText === "no" ? { marginLeft: "14px" } : { marginLeft: "10px" }
            }
            onClick={
              data.buttonActionCallback
                ? data.buttonActionCallback.bind(null, cell)
                : ""
            }
            content={
              <FormattedMessage
                id={`policy.source.grid.action.button.${nameOnButton}`}
              />
            }
          />
        </div>
      ) : (
        ""
      );
    } else {
      return "";
    }
  };
};

/**
 *  Returns width, minWidth for action button cell.
 */
const setCellWidthForActionButton = () => {
  return {
    width: GridColumnCellWidth.BUTTON_ACTION,
    minWidth: GridColumnCellWidth.BUTTON_ACTION
  };
};

/**
 *  Returns width, minWidth for action button cell.
 */
const setCellWidthForActionButtonMUI = () => {
  return {
    width: GridColumnCellWidth.BUTTON_ACTION_MUI,
    minWidth: GridColumnCellWidth.BUTTON_ACTION_MUI
  };
};

const setCellWidthForDurationMUI = () => {
  return {
    width: GridColumnCellWidth.DURATION_MUI,
    minWidth: GridColumnCellWidth.DURATION_MUI
  };
};

export default injectIntl(DataGridHelper);

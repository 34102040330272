import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Nav } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import SpogBreadcrumb from "components/common/breadcrumb";
import {
  getAlertList,
  toggleAlertsPanel,
  selectMenuAction,
  setHasGoBackNavigation,
  updateAlertListCount,
  resetAlertListCount,
  logOut,
  unsetJWTToken
} from "state/actions/actions";
import IconMUI from "components/common/icon/IconMUI";
import * as constant from "utils/appConstants";
import { DARK_MODE } from "utils/theme";
import Alerts from "components/common/alerts";
import { getBrowserLocale } from "utils/SpogDataGridUtil";
import FormattedMessage from "components/common/formatted-message";
import _ from "lodash";
import { getLogin } from "state/selectors";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import WarningAmber from "@mui/icons-material/WarningAmber";
import LogoutIcon from "@mui/icons-material/Logout";
//import LeftNavBar from "../navigator";
import LeftNavBar from "../navigator-mui";
import { isCSRUser } from "utils";
import { PAGE_ROUTE } from "../../utils/appConstants";
import { ID_PREFIX } from "configs/IdConfig";
import { handleLinkColor } from "state/selectors/ui";

// const getCookieValue = name =>
//   document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.handleCollapseSideBar = this.handleCollapseSideBar.bind(this);
    this.handleMSPHomeClick = this.handleMSPHomeClick.bind(this);
    this.handleToggleAlertsPanel = this.handleToggleAlertsPanel.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.updateStateOnClick = this.updateStateOnClick.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.state = {
      random: 0,
      tabValue: 0
    };
  }

  componentDidMount = () => {
    this.props.getAlertList();
  };

  componentDidUpdate(prevProps) {
    if (this.props.alertsList !== prevProps.alertsList) {
      clearTimeout(this.timeout);
      this.startPoll();
      let newlen =
        this.props.alertsList.data.length - prevProps.alertsList.data.length;
      this.props.updateAlertListCount(newlen);
    } else if (
      this.props.isImpersonationView !== prevProps.isImpersonationView
    ) {
      prevProps.getAlertList();
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleTabChange(event, newValue) {
    this.setState({ tabValue: newValue });
    if (newValue === 1) {
      if (this.props.roleId && this.props.roleId.includes("msp")) {
        this.props.history.push("/protect/customer-accounts/all");
      } else {
        this.props.history.push("/protect/sources/all");
      }
    } else if (newValue === 2) {
      this.props.history.push("/analyze/jobs/all");
    } else if (newValue === 3) {
      this.props.history.push("/configure/infrastructure/hypervisors");
    } else if (newValue === 0) {
      this.props.history.push("/");
    }
  }

  // handleClick() {
  //   const min = 1;
  //   const max = 100;
  //   const rand = min + Math.random() * (max - min);
  //   this.setState({ random: this.state.random + rand });
  // }

  startPoll() {
    //poll for alerts every 5 mins.
    const pollingAction = true;
    this.timeout = setTimeout(() => {
      this.props.getAlertList(pollingAction);
    }, 300000);
  }

  handleCollapseSideBar() {
    this.props.handleCollapseSideBar(this.props.collapseSideBar);
  }

  handleToggleAlertsPanel() {
    this.props.toggleAlertsPanel(this.props.toggleAlertsList);
    this.props.resetAlertListCount();
  }

  handleLogout() {
    //this.props.unsetJWTToken();
    // this.props.logOut();
    //dispatch(actions.logOut());
    // let token = getCookieValue("idtoken");
    // window.location.href = `${OCTA_LOGOUT}?id_token_hint=${token}`;
    //return null;
    this.props.history.push(`/logout`);
  }

  handleMSPHomeClick() {
    /*this.props.handleSelectMenu({
      selectedMenu: "Protect",
      selectedMenuKey: "Protect"
    });
    this.props.selectFilterAction({
      selectedFilter: "customer-accounts",
      selectedFilterKey: "Customer Accounts"
    });
    // this.props.handleSelectFilter("customer-accounts", "Customer Accounts");
    this.props.resetImpersonateCustomerState();*/
    this.props.handleHomeMSPHomeClick();
  }

  updateStateOnClick(secondaryKey, secondaryNavigation) {
    //secondaryKey && this.handleSelectFilter(secondaryNavigation, secondaryKey);
    //this.updateResourceName(null);
  }

  handleHelpClick = () => {
    let locale = getBrowserLocale();
    let url = `https://arcserve.zendesk.com/hc/${locale.toLowerCase()}`;
    window.open(url, "_blank").focus();
  };

  render() {
    const primaryNavigation = this.props.primaryNavigation;
    const secondaryNavigation = this.props.secondaryNavigation;
    const primaryKey = this.props.primaryKey;
    let secondaryKey = this.props.secondaryKey;
    const resourceName = this.props.resourceName;
    const theme = this.props.getTheme;
    let alertColor =
      this.props.primaryNavigation === "Monitor" || this.props.toggleAlertsList
        ? theme.palette
          ? theme.palette.primary.main
          : "#6200EE"
        : this.props.getTheme.palette?.mode === DARK_MODE
        ? "rgba(255, 255, 255, 0.7)"
        : "rgba(0, 0, 0, 0.6)";

    const isDarkTheme =
      theme && theme.palette && theme.palette.mode === "dark" ? true : false;
    const customerOrgName = _.get(
      this.props,
      "organizationInfo.organization_name",
      ""
    );
    const parentOrgName = _.get(
      this.props,
      "parentOrganizationInfo.organization_name"
    );
    const monitorName = _.get(this.props, "customerOrgName", customerOrgName);
    ///const classes = useStyles();
    // const html = (
    //   <Popover id="popover-logout" title="">
    //     <Popover.Content>
    //       <NavLink
    //         to="/user_profile"
    //         onClick={() => {
    //           this.props.setHasGoBackNavigation(true);
    //           this.props.selectMenuAction({
    //             selectedMenu: "My Profile",
    //             selectedMenuKey: "My Profile"
    //           });
    //           document.body.click();
    //         }}
    //       >
    //         <IconMUI icon={PersonIcon} />{" "}
    //         <FormattedMessage
    //           id="csr.user_profile"
    //           defaultMessage="User Profile"
    //         />
    //       </NavLink>

    //       <NavLink
    //         to="/logout"
    //         onClick={() => {
    //           this.props.setHasGoBackNavigation(false);
    //         }}
    //       >
    //         <IconMUI icon={LogoutOutlinedIcon} />{" "}
    //         <FormattedMessage id="csr.logout" defaultMessage="Logout" />
    //       </NavLink>
    //     </Popover.Content>
    //   </Popover>
    // );

    //Populate Primary level breadcrumb.
    let breadcrumbItems =
      this.props.isImpersonationView &&
      !["My Profile", "Support"].includes(this.props.primaryNavigation)
        ? [
            this.props.loginInfo &&
            this.props.loginInfo.role_id.includes("msp") &&
            secondaryNavigation &&
            secondaryNavigation === "customer-accounts"
              ? {
                  name: primaryNavigation,
                  url: "/protect/customer-accounts/all",
                  key: primaryKey,
                  navigationKey: "Customer Accounts",
                  navigation: "customer-accounts"
                }
              : {
                  name: primaryNavigation,
                  url:
                    constant.PRIMARY_NAVIGATION_URL_MAPPING[primaryNavigation],
                  key:
                    primaryNavigation ===
                      constant.SECONDARY_NAVIGATION.MONITOR && monitorName
                      ? monitorName
                      : primaryKey,
                  navigationKey:
                    constant.SECONDARY_NAVIGATION_PARENT_MAPPING[
                      primaryNavigation
                    ],
                  navigation:
                    constant.SECONDARY_NAVIGATION_MAPPING[primaryNavigation]
                }
          ]
        : [
            secondaryNavigation && secondaryNavigation === "customer-accounts"
              ? {
                  name: primaryNavigation,
                  url: "/protect/customer-accounts/all",
                  key: primaryKey,
                  navigationKey: "Customer Accounts",
                  navigation: "customer-accounts"
                }
              : {
                  name: primaryNavigation,
                  url:
                    this.props.roleId && this.props.roleId.includes("msp")
                      ? constant.PRIMARY_NAVIGATION_URL_MAPPING_MSP_LEVEL[
                          primaryNavigation
                        ]
                      : constant.PRIMARY_NAVIGATION_URL_MAPPING[
                          primaryNavigation
                        ],
                  key:
                    primaryNavigation ===
                      constant.SECONDARY_NAVIGATION.MONITOR && customerOrgName
                      ? customerOrgName
                      : primaryKey,
                  navigationKey:
                    this.props.roleId &&
                    this.props.roleId.includes("msp") &&
                    primaryNavigation ===
                      constant.SECONDARY_NAVIGATION.CONFIGURE
                      ? constant.SECONDARY_NAVIGATION_PARENT_MAPPING[
                          constant.SECONDARY_NAVIGATION.user_accounts
                        ]
                      : constant.SECONDARY_NAVIGATION_PARENT_MAPPING[
                          primaryNavigation
                        ],
                  navigation:
                    constant.SECONDARY_NAVIGATION_MAPPING[primaryNavigation]
                }
          ];
    // let showSideBarOptions = "collapse";

    if (primaryNavigation === constant.SECONDARY_NAVIGATION.MONITOR) {
      this.props.isImpersonationView &&
        breadcrumbItems.unshift({
          name: parentOrgName,
          url: !isCSRUser(this.props.roleId)
            ? `${PAGE_ROUTE.CUSTOMER_ACCOUNTS}/all`
            : "/csr",
          key: parentOrgName,
          navigationKey: null,
          navigation: null
        });
    }

    //If secondary Navigation is selected, Populate secondary level Bread crumb.

    if (secondaryNavigation) {
      // Whenever we impersonate and have secondary navigation then adding intial monitor breadcrumb level.
      this.props.isImpersonationView &&
        breadcrumbItems.unshift(
          {
            name: parentOrgName,
            url: !isCSRUser(this.props.roleId)
              ? `${PAGE_ROUTE.CUSTOMER_ACCOUNTS}/all`
              : "/csr",
            key: parentOrgName,
            navigationKey: null,
            navigation: null
          },
          {
            name: monitorName,
            url: "/monitor",
            key: monitorName,
            navigationKey: null,
            navigation: null
          }
        );
      if (isCSRUser(this.props.roleId) && this.props.orgType === "msp_child") {
        breadcrumbItems.splice(1, 0, {
          name: _.get(this.props, "brandData.organization_name", "Arcserve"),
          url: `${PAGE_ROUTE.CUSTOMER_ACCOUNTS}/all`,
          key: _.get(this.props, "brandData.organization_name", "Arcserve"),
          navigationKey: "Customer Accounts",
          navigation: "customer-accounts"
        });
      }
      if (constant.SECONDARY_NAVIGATION_PARENT_MAPPING[secondaryNavigation]) {
        //If secondary navigation selection is not a filter type,
        // (Child of Filter type) get the parent for the selected second level Navigation
        //eg: Sources/Destinations/Infrastructure/Recovered resources...
        breadcrumbItems = breadcrumbItems.concat({
          name:
            constant.SECONDARY_NAVIGATION_PARENT_MAPPING[secondaryNavigation],
          url: `/${primaryNavigation}/${constant.SECONDARY_NAVIGATION_PARENT_URL_MAPPING[secondaryNavigation]}`,
          key:
            constant.SECONDARY_NAVIGATION_PARENT_MAPPING[secondaryNavigation],
          navigationKey:
            constant.SECONDARY_NAVIGATION_PARENT_MAPPING[secondaryKey],
          navigation: constant.SECONDARY_NAVIGATION_MAPPING[secondaryKey]
        });
        //Bread crumb for selected secondary navigation
        breadcrumbItems = breadcrumbItems.concat({
          name: secondaryKey,
          url: `/${primaryNavigation}/${constant.SECONDARY_NAVIGATION_PARENT_URL_MAPPING[secondaryKey]}/${secondaryNavigation}`,
          key: secondaryKey,
          navigationKey: secondaryKey,
          navigation: secondaryNavigation
        });
      }
      //Fixing recovered vms navigation TFS Bug:897230
      //currently parent navigation of recovered_vms is not a clickable link unlike sources/physical_machines
      //when recovered resources becomes a clickable link then we would need to modify breadcrumb to add  parent_url_mapping
      // else if (
      //   secondaryNavigation === constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_VMS
      // ) {
      //   breadcrumbItems = breadcrumbItems.concat({
      //     name: secondaryKey,
      //     url: `/${primaryNavigation}/${constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_RESOURCES}/${secondaryNavigation}`,
      //     key: secondaryKey,
      //     navigationKey: secondaryKey,
      //     navigation: secondaryNavigation
      //   });
      // }
      else if (
        secondaryNavigation ===
        constant.SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD
      ) {
        breadcrumbItems = breadcrumbItems.concat({
          name: secondaryKey,
          url: `/${primaryNavigation}/${constant.SECONDARY_NAVIGATION_TYPE.DESTINATIONS}/${secondaryNavigation}`,
          key: secondaryKey,
          navigationKey: secondaryKey,
          navigation: secondaryNavigation
        });
      } else {
        //If secondary navigation selection is a filter type,
        breadcrumbItems = breadcrumbItems.concat({
          name: secondaryKey,
          url: `/${primaryNavigation}/${secondaryNavigation}/all`,
          key: secondaryKey,
          navigationKey: secondaryKey,
          navigation: secondaryNavigation
        });
      }
      // showSideBarOptions += " show";
    }
    if (
      primaryKey === "configure" &&
      secondaryKey === "Source Group" &&
      this.props.location.pathname
        .split("/")
        .includes(constant.SECONDARY_NAVIGATION_TYPE.ADD_SOURCES)
    ) {
      breadcrumbItems = breadcrumbItems.concat({
        name: _.get(this.props.selectedSourceGroup, "group_name.label"),
        url: `/${primaryNavigation}/${secondaryNavigation}/${_.get(
          this.props.selectedSourceGroup,
          "group_id"
        )}/details#`,
        key: _.get(this.props.selectedSourceGroup, "group_name.label")
      });
      breadcrumbItems = breadcrumbItems.concat({
        name: constant.ADD_SOURCES_TO_GROUP,
        url: `/${primaryNavigation}/${secondaryNavigation}/${_.get(
          this.props.selectedSourceGroup,
          "group_id"
        )}/${constant.SECONDARY_NAVIGATION_TYPE.ADD_SOURCES_TO_GROUP}`,
        key: constant.ADD_SOURCES_TO_GROUP,
        navigationKey: constant.ADD_SOURCES_TO_GROUP,
        navigation: constant.SECONDARY_NAVIGATION_TYPE.ADD_SOURCES
      });
    }

    if (resourceName) {
      breadcrumbItems = breadcrumbItems.concat({
        name: resourceName,
        url: "#",
        key: resourceName,
        class: "no-transform"
      });
    }

    if (this.props.secondaryNavigation === "Downloads") {
      breadcrumbItems = [
        {
          name: this.props.primaryNavigation,
          url: "/user_support",
          key: this.props.primaryNavigation,
          navigationKey: this.props.primaryKey,
          navigation: this.props.primaryNavigation
        },
        {
          name: this.props.secondaryNavigation,
          url: "/user_support/downloads",
          key: this.props.secondaryNavigation,
          navigationKey: this.props.secondaryNavigation,
          navigation: this.props.secondaryNavigation
        }
      ];
    }

    let showCollapsableIcon =
      this.props.secondaryNavigation !== "customer-accounts" &&
      this.props.primaryNavigation !== "Monitor" &&
      this.props.primaryNavigation !== "My Profile" &&
      this.props.primaryNavigation !== "Support";

    // let profileImg =
    //   this.props.profileImage.slice(-3).toLowerCase() === "png"
    //     ? `${this.props.profileImage}?dummy=${this.state.random}`
    //     : `${this.props.profileImage}`;

    // let backUrl = `/csr/view-org/${_.get(
    //   this.props,
    //   "organizationInfo.parent_id"
    // )}/${_.get(
    //   this.props,
    //   "organizationInfo.organization_name"
    // )}/msp/protect/customer-accounts/all`;

    let paddingTop = this.props.isImpersonationView ? 0 : 0;

    return (
      <>
        <Paper
          square
          className="header-paper-wrapper"
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "space-between",
            zIndex: "+11",
            paddingTop: paddingTop
            //maxWidth: '50%',
          }}
        >
          <LeftNavBar {...this.props} />
          <Nav
            variant="pills"
            style={{ minHeight: "auto", border: "none" }}
            className={`
              header-left-sidebar
              ${
                showCollapsableIcon
                  ? "justify-content-between"
                  : "justify-content-between noCollapseIcon"
              }
            `}
          >
            {" "}
            <Nav.Item className="d-flex breadcrumb-wrapper">
              {/* {this.props.isImpersonationView &&
                this.props.primaryNavigation !== "My Profile" &&
                !this.props.location.pathname.includes("/user_profile") &&
                !this.props.location.pathname.includes("/user_support") &&
                this.props.primaryNavigation !== "Support" && (
                  <NavLink
                    className="back-arrow"
                    to={
                      _.get(this.props, "roleId").includes("csr") &&
                      _.get(
                        this.props,
                        "organizationInfo.organization_type"
                      ) === constant.SUB_MSP_ORG_TYPE
                        ? backUrl
                        : "/protect/customer-accounts/all"
                    }
                    onClick={this.handleMSPHomeClick}
                  >
                    <Icon icon="back" width="32px" height="18px" />
                  </NavLink>
                )} */}
              {/* {(this.props.primaryNavigation === "My Profile" ||
                this.props.location.pathname.includes("/user_profile") ||
                this.props.location.pathname.includes("/user_support") ||
                this.props.primaryNavigation === "Support") && (
                <span
                  id={`${ID_PREFIX}LD9Su5`}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center"
                  }}
                  onClick={() => {
                    if (this.props.hasGoBackNavigation)
                      this.props.history.goBack();
                    else this.props.history.push("/monitor");
                  }}
                >
                  <Icon icon="back" width="32px" height="18px" />
                </span>
              )} */}
              {/* <SpogBreadcrumb
        items={breadcrumbItems}
        updateStateOnClick={this.updateStateOnClick}
        {...this.props}
      /> */}
            </Nav.Item>
            <Nav.Item>
              <div
                className="header-right-icons-panel d-flex align-items-center"
                id="alertTabs"
              >
                {/* <NavLink
                  onClick={() => {
                    this.props.setHasGoBackNavigation(true);
                  }}
                  to="/user_support"
                  id={`${ID_PREFIX}389ryD`}
                >
                  <Icon icon="help" width="24px" height="24px" />
                  <IconMUI
                    icon={Help}
                    style={{ color: "#0000006a", fontSize: 30 }}
                  />
                </NavLink> */}
                {/* <a
          className="alerts-icon"
          to="#"
          onClick={this.handleToggleAlertsPanel}
          disabled={this.props.primaryNavigation === "Monitor"}
        > */}
                {/* <span className={"alerts-count " + isRedAlert}>{alertCount}</span> */}
                {/* <Icon icon="notification" width="32px" height="24px" /> */}
                {this.props.primaryNavigation !== "Monitor" && (
                  <Badge
                    badgeContent={
                      this.props.toggleAlertsList ||
                      this.props.primaryNavigation === "Monitor" ||
                      this.props.alertListCount < 0
                        ? 0
                        : this.props.alertListCount
                    }
                    color="error"
                    role="button"
                    onClick={this.handleToggleAlertsPanel}
                    disabled={this.props.primaryNavigation === "Monitor"}
                    id={`${ID_PREFIX}oxTTS7`}
                    sx={{ "& .MuiBadge-badge": { marginTop: 0.5 } }}
                    style={{
                      display: "flex",
                      textTransform: "none",
                      alignItems: "center",
                      flexDirection: "column",
                      // color: "rgba(0, 0, 0, 0.6)",
                      color: alertColor,
                      marginLeft: 20,
                      fontFamily: "Sans Source Pro Medium",
                      fontSize: 15
                    }}
                    max={9}
                  >
                    <IconMUI
                      icon={WarningAmber}
                      style={{
                        height: 30,
                        width: 30,
                        marginBottom: 6
                        // color: alertColor
                      }}
                      id="PrimaryNav-Alert"
                    />
                    <FormattedMessage id={"Alerts"} defaultMessage={"Alerts"} />
                  </Badge>
                )}
                {/* <Badge
                  badgeContent={alertCount}
                  color="error"
                  role="button"
                  onClick={this.handleToggleAlertsPanel}
                  disabled={this.props.primaryNavigation === "Monitor"}
                >
                  <IconMUI
                    icon={WarningRoundedIcon}
                    style={{ fontSize: 30, color: "#0000006a" }}
                  />
                </Badge> */}

                {/* </a> */}
                {/* <span>
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    overlay={html}
                  >
                    <div className="logout-wrapper d-flex align-items-center">
                      <Avatar
                        alt="Remy Sharp"
                        src={profileImg}
                        onClick={this.handleClick.bind(this)}
                        id={`${ID_PREFIX}46XDJM`}
                      />
                      <img
                        alt=""
                        style={{ borderRadius: "50%" }}
                        src={profileImg}
                        onClick={this.handleClick.bind(this)}
                      />
                      <Profile style={{fontSize: 30}} onClick={this.handleClick.bind(this)} color="primary"/>
                    </div>
                  </OverlayTrigger>
                </span> */}
              </div>
              {this.props.primaryNavigation === "Monitor" ? (
                <Alerts
                  locale={this.props.locale}
                  testId="31ERCp"
                  show
                  history={this.props.history}
                  alertsList={this.props.alertsList}
                  handleSelectFilter={this.props.handleSelectFilter}
                  linkColor={this.props.linkColor}
                  isMonitor
                />
              ) : (
                <Alerts
                  locale={this.props.locale}
                  testId="31ERCp"
                  show={this.props.toggleAlertsList}
                  history={this.props.history}
                  alertsList={this.props.alertsList}
                  handleSelectFilter={this.props.handleSelectFilter}
                  linkColor={this.props.linkColor}
                />
              )}
            </Nav.Item>
            {/* commented logout code */}
            {/* <Nav.Item>
              <div
                className="header-right-icons-panel d-flex align-items-center"
                style={{ cursor: "pointer", paddingLeft: 10 }}
                onClick={this.handleLogout}
              >
                <span
                  id={`${ID_PREFIX}oxTTU0`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    color:
                      this.props.getTheme.palette?.mode === DARK_MODE
                        ? "rgba(255, 255, 255, 0.7)"
                        : "rgba(0, 0, 0, 0.6)",
                    fontFamily: "Sans Source Pro Medium",
                    fontSize: 15
                  }}
                >
                  <IconMUI
                    icon={LogoutIcon}
                    style={{ height: 30, width: 30, marginBottom: 6 }}
                  />
                  <FormattedMessage id={"Logout"} defaultMessage={"Logout"} />
                </span>
              </div>
            </Nav.Item> */}
          </Nav>
        </Paper>
        <div
          style={{
            borderBottom: isDarkTheme
              ? "1px solid rgba(255, 255, 255, 0.1)"
              : "1px solid lightgrey"
          }}
        >
          <SpogBreadcrumb
            items={breadcrumbItems}
            updateStateOnClick={this.updateStateOnClick}
            {...this.props}
          />
        </div>
      </>
    );
  }
}

Header.defaultProps = {
  alertsList: []
};

Header.propTypes = {
  primaryNavigation: PropTypes.string.isRequired,
  secondaryNavigation: PropTypes.string,
  primaryKey: PropTypes.string.isRequired,
  secondaryKey: PropTypes.string,
  collapseSideBar: PropTypes.bool,
  handleCollapseSideBar: PropTypes.func,
  handleSelectFilter: PropTypes.func.isRequired,
  handleSelectMenu: PropTypes.func.isRequired,
  // selectFilterAction: PropTypes.func.isRequired,
  handleHomeMSPHomeClick: PropTypes.func.isRequired,
  resetImpersonateCustomerState: PropTypes.func,
  logout: PropTypes.func,
  profileImage: PropTypes.string,
  resourceName: PropTypes.string,
  parentKey: PropTypes.string,
  customerOrgName: PropTypes.string,
  isImpersonationView: PropTypes.bool,
  updateResourceName: PropTypes.func,
  alertsList: PropTypes.shape(),
  getAlertList: PropTypes.func.isRequired,
  selectMenuAction: PropTypes.func.isRequired,
  toggleAlertsPanel: PropTypes.func.isRequired,
  toggleAlertsList: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

/**
 * Maps state to the props of the component in React Redux connection
 * @param state
 */
const mapStateToProps = state => ({
  getTheme: state.reducer.theme,
  alertsList: state.monitorReducer.alertsList,
  alertListCount: state.monitorReducer.alertListCount,
  toggleAlertsList: state.reducer.toggleAlertsList,
  hasGoBackNavigation: state.reducer.hasGoBackNavigation,
  organizationInfo: state.login.organizationInfo,
  parentOrganizationInfo: state.login.parentOrganizationInfo,
  loginInfo: getLogin(state),
  selectedSourceGroup: state.configureReducer.selectedSourceGroup,
  brandData:
    state &&
    state.configureReducer &&
    state.configureReducer &&
    state.configureReducer.brandData
      ? state.configureReducer.brandData
      : {},
  linkColor: handleLinkColor(state),
  locale: state?.login?.locale || ""
});

/**
 * Bind the dispatch to the function for Redux action dispatcher
 * @param dispatch
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAlertList,
      toggleAlertsPanel,
      selectMenuAction,
      setHasGoBackNavigation,
      updateAlertListCount,
      resetAlertListCount,
      logOut,
      unsetJWTToken
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

import React from "react";
import FormattedMessage from "../../../common/formatted-message";

/**
 * Helper service to build Dashboard widgets
 */
const ReportsHelper = {
  /**
   * Builds dashboard widget with requested configuration and data.
   * @param {string} type
   * @param {object} configuration
   * @param {object} data
   * @param {object} legends
   */
  buildWidgetForType() {
    // type,
    // configuration,
    // data,
    // legends,
    // options,
    // actions,
    // advancedSearchData
    // switch (type) {
    // case constant.ReportsWidgetTypes.BACKUP_JOBS_TREND:
    //   return buildWidgetForBackupJobsTrend(
    //     configuration,
    //     data,
    //     legends,
    //     options,
    //     actions,
    //     advancedSearchData
    //   );
    // case constant.ReportsWidgetTypes.BACKUP_JOBS_DONUT:
    // return buildWidgetForLastJobStatus(
    //   configuration,
    //   data,
    //   options,
    //   actions,
    //   advancedSearchData
    // );

    // case constant.ReportsWidgetTypes.TOP_TEN_SOURCES_STACKEDBAR:
    //   return buildWidgetForTopTenSources(
    //     configuration,
    //     data,
    //     options,
    //     actions,
    //     advancedSearchData,
    //     legends
    //   );

    // case constant.ReportsWidgetTypes.RESTORE_JOBS_DONUT:
    //   return buildWidgetForRestoreJobs(
    //     configuration,
    //     data,
    //     options,
    //     actions,
    //     advancedSearchData
    //   );

    // case constant.ReportsWidgetTypes.RESTORE_JOBS_TREND:
    //   return buildWidgetForRestoreJobsTrend(
    //     configuration,
    //     data,
    //     legends,
    //     options,
    //     actions,
    //     advancedSearchData
    //   );

    // case constant.ReportsWidgetTypes.DATA_TRANSFER_LINE:
    //   return buildWidgetForDataTransfer(
    //     configuration,
    //     data,
    //     options,
    //     actions,
    //     advancedSearchData
    //   );

    // case constant.ReportsWidgetTypes.JOBS_SUMMARY_DONUT:
    //   return buildWidgetForJobsSummary(
    //     configuration,
    //     data,
    //     options,
    //     actions,
    //     advancedSearchData
    //   );

    // case constant.ReportsWidgetTypes.TOP_TEN_DESTINATIONS_STACKEDBAR:
    //   return buildWidgetForTopTenDestinations(
    //     configuration,
    //     data,
    //     options,
    //     actions,
    //     advancedSearchData
    //   );

    // case constant.ReportsWidgetTypes.CD_VOLUME_TREND:
    //   return buildWidgetForCDvolumes(
    //     configuration,
    //     data,
    //     legends,
    //     options,
    //     actions,
    //     advancedSearchData
    //   );

    // case constant.ReportsWidgetTypes.CH_VOLUME_TREND:
    //   return buildWidgetForCHvolumes(
    //     configuration,
    //     data,
    //     legends,
    //     options,
    //     actions,
    //     advancedSearchData
    //   );

    // case constant.ReportsWidgetTypes.DEDUPE_SAVING_LINE:
    //   return buildWidgetForDedupeSaving(
    //     configuration,
    //     data,
    //     options,
    //     actions,
    //     advancedSearchData
    //   );

    //   default:
    //     return null;
    // }
    return null;
  }
};

/**
 * Check valid data for line chart
 * @param data
 * @returns {boolean}
 */
const checkForValidData = data => {
  let isDataAvailable = false;
  if (data.length) {
    let singleData = data[0]["dataByTopic"];
    singleData &&
      singleData.forEach(item => {
        if (item.dates.length > 0) {
          isDataAvailable = true;
        }
      });
  }
  return isDataAvailable;
};

/**
 * Empty view for widget
 * @returns {*}
 */
const emptyChartView = () => (
  <span
    style={{
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      height: "calc( 100% - 58px)",
      fontFamily: "Sans Source Pro",
      fontWeight: 400,
      fontSize: "18px",
      color: "#8f8f8f"
    }}
  >
    <FormattedMessage
      id="dashboard.insufficientData"
      defaultMessage="Insufficient data to render this widget."
    />
  </span>
);

/**
 * Builds widget for constant.ReportsWidgetTypes.BACKUP_JOBS_TREND
 * @param {object} configuration
 * @param {object} data
 * @param {object} legends
 */
// const buildWidgetForBackupJobsTrend = (
//   configuration,
//   data,
//   legends,
//   options,
//   actions,
//   advancedSearchData
// ) => {
//   return (
//     <ChartLayout
//       title="Backup Jobs Trend"
//       actions={actions}
//       advancedSearchData={advancedSearchData}
//       options={options ? options : ""}
//       chart={
//         data.length ? (
//           <Trend configuration={configuration} data={data} legends={legends} />
//         ) : (
//           emptyChartView()
//         )
//       }
//     />
//   );
// };

const getInsufficientViewWithLegends = (legends, configuration) => {
  return (
    <span
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        height: "calc( 100% - 58px)",
        fontFamily: "Sans Source Pro",
        fontWeight: 400,
        fontSize: "18px",
        color: "#8f8f8f"
      }}
    >
      <FormattedMessage
        id="dashboard.insufficientData"
        defaultMessage="Insufficient data to render this widget."
      />
    </span>

    /*<div
      className="bar-chart-wrapper chart d-flex flex-wrap"
      style={{ height: "calc(100% - 90px)", width: "100%" }}
    >
      <div className="legends-container">
        <div className="legend d-flex">
          {legends &&
            legends.map((res, i) => {
              return (
                <div key={res.stack} className="legends">
                  <div>
                    <span className="label">
                      <span
                        className="circle"
                        style={{ background: configuration.colorSchema[i] }}
                      />
                      <span>{startCase(res.stack)}</span>
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="bar d-flex" style={{ height: "100%" }}>
        <span
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "calc( 100% - 58px)",
            fontFamily: "Sans Source Pro",
            fontWeight: 400,
            fontSize: "18px",
            color: "#8f8f8f",
            width: "444px"
          }}
        >
          <FormattedMessage
            id="dashboard.insufficientData"
            defaultMessage="Insufficient data to render this widget."
          />
        </span>
      </div>
    </div>*/
  );
};

/**
 * Builds widget for constant.ReportsWidgetTypes.TOP_TEN_SOURCES_STACKEDBAR
 * @param {object} configuration
 * @param {object} data
 */
// const buildWidgetForTopTenSources = (
//   configuration,
//   data,
//   options,
//   actions,
//   advancedSearchData,
//   legends
// ) => {
//   configuration.toolTipTitle = "Sources";
//   configuration.nameLabel = "id";
//   if (data && typeof data === "object" && data.data && data.data.sources) {
//     data = data.data.sources;
//   }
//   let nameMapping = data.reduce((obj, cv) => {
//     obj[cv.id] = cv.name;
//     return obj;
//   }, {});
//   return (
//     <ChartLayout
//       title="Top 10 sources"
//       options={options ? options : ""}
//       actions={{
//         optionAction:
//           actions && actions.optionAction ? actions.optionAction : "",
//         refreshAction:
//           actions && actions.refreshAction ? actions.refreshAction : ""
//       }}
//       advancedSearchData={advancedSearchData}
//       chart={
//         data.length ? (
//           <StackedBarChart
//             configuration={configuration}
//             data={data}
//             legends={legends}
//             nameMapping={nameMapping}
//           />
//         ) : (
//           getInsufficientViewWithLegends(legends, configuration)
//         )
//       }
//     />
//   );
// };

/**
 * Builds widget for constant.ReportsWidgetTypes.RESTORE_JOBS_DONUT
 * @param {object} configuration
 * @param {object} data
 */
// const buildWidgetForRestoreJobs = (
//   configuration,
//   data,
//   options,
//   actions,
//   advancedSearchData
// ) => {
//   return (
//     <ChartLayout
//       title="Recovery Jobs Summary"
//       actions={actions}
//       advancedSearchData={advancedSearchData}
//       chart={
//         data.length ? (
//           <DonutChart
//             configuration={configuration}
//             legendTitle="Job"
//             data={data}
//           />
//         ) : (
//           emptyChartView()
//         )
//       }
//     />
//   );
// };

/**
 * Builds widget for constant.ReportsWidgetTypes.RESTORE_JOBS_TREND
 * @param {object} configuration
 * @param {object} data
 * @param {object} legends
 */
// const buildWidgetForRestoreJobsTrend = (
//   configuration,
//   data,
//   legends,
//   options,
//   actions,
//   advancedSearchData
// ) => {
//   return (
//     <ChartLayout
//       title="Recovery Jobs Trend"
//       actions={actions}
//       advancedSearchData={advancedSearchData}
//       options={options ? options : ""}
//       chart={
//         data.length ? (
//           <Trend configuration={configuration} data={data} legends={legends} />
//         ) : (
//           emptyChartView()
//         )
//       }
//     />
//   );
// };

/**
 * Builds widget for constant.ReportsWidgetTypes.DATA_TRANSFER_LINE
 * @param {object} configuration
 * @param {object} data
 */
// const buildWidgetForDataTransfer = (
//   configuration,
//   data,
//   options,
//   actions,
//   advancedSearchData
// ) => {
//   configuration.toolTipTitle = "Sources";
//   return (
//     <ChartLayout
//       title="Cloud Direct Data Transfer Summary"
//       options={options ? options : ""}
//       actions={{
//         optionAction:
//           actions && actions.optionAction ? actions.optionAction : "",
//         refreshAction:
//           actions && actions.refreshAction ? actions.refreshAction : ""
//       }}
//       advancedSearchData={advancedSearchData}
//       chart={
//         checkForValidData(data) ? (
//           <LineChart configuration={configuration} data={data} />
//         ) : (
//           emptyChartView()
//         )
//       }
//     />
//   );
// };

/**
 * Builds widget for constant.ReportsWidgetTypes.JOBS_SUMMARY_DONUT
 * @param {object} configuration
 * @param {object} data
 */
// const buildWidgetForJobsSummary = (
//   configuration,
//   data,
//   options,
//   actions,
//   advancedSearchData
// ) => {
//   return (
//     <ChartLayout
//       title="Jobs Summary"
//       options={options ? options : ""}
//       actions={{
//         optionAction:
//           actions && actions.optionAction ? actions.optionAction : "",
//         refreshAction:
//           actions && actions.refreshAction ? actions.refreshAction : ""
//       }}
//       advancedSearchData={advancedSearchData}
//       chart={
//         data.length ? (
//           <DonutChart
//             configuration={configuration}
//             legendTitle="Job"
//             data={data}
//           />
//         ) : (
//           emptyChartView()
//         )
//       }
//     />
//   );
// };

/**
 * Builds widget for constant.ReportsWidgetTypes.TOP_TEN_DESTINATIONS_STACKEDBAR
 * @param {object} configuration
 * @param {object} data
 */
// const buildWidgetForTopTenDestinations = (
//   configuration,
//   data,
//   options,
//   actions,
//   advancedSearchData
// ) => {
//   configuration.toolTipTitle = "Jobs";
//   configuration.nameLabel = "id";
//   let nameMapping = data.reduce((obj, cv) => {
//     obj[cv.id] = cv.name;
//     return obj;
//   }, {});
//   return (
//     <ChartLayout
//       title="Top 10 Destinations"
//       options={options ? options : ""}
//       actions={{
//         optionAction:
//           actions && actions.optionAction ? actions.optionAction : "",
//         refreshAction:
//           actions && actions.refreshAction ? actions.refreshAction : ""
//       }}
//       advancedSearchData={advancedSearchData}
//       chart={
//         data.length ? (
//           <StackedBarChart
//             configuration={configuration}
//             data={data}
//             nameMapping={nameMapping}
//           />
//         ) : (
//           emptyChartView()
//         )
//       }
//     />
//   );
// };

/**
 * Builds widget for constant.ReportsWidgetTypes.CD_VOLUME_TREND
 * @param {object} configuration
 * @param {object} data
 * @param {object} legends
 */
// const buildWidgetForCDvolumes = (
//   configuration,
//   data,
//   legends,
//   options,
//   actions,
//   advancedSearchData
// ) => {
//   if (data && data.length) {
//     configuration.topicsOrder = _.map(legends, "id");
//     configuration.height = 360;
//   }

//   return (
//     <ChartLayout
//       title="Usage Trend for Cloud Direct Volumes"
//       options={options ? options : ""}
//       actions={{
//         optionAction:
//           actions && actions.optionAction ? actions.optionAction : "",
//         refreshAction:
//           actions && actions.refreshAction ? actions.refreshAction : ""
//       }}
//       chart={
//         data && data.length ? (
//           <Trend
//             configuration={configuration}
//             data={data}
//             legends={[]}
//             advancedSearchData={advancedSearchData}
//           />
//         ) : (
//           emptyChartView()
//         )
//       }
//     />
//   );
// };

/**
 * Builds widget for constant.ReportsWidgetTypes.CH_VOLUME_TREND
 * @param {object} configuration
 * @param {object} data
 * @param {object} legends
 */
//TODO clean up
// const buildWidgetForCHvolumes = (
//   configuration,
//   data,
//   legends,
//   options,
//   actions,
//   advancedSearchData
// ) => {
//   return (
//     <ChartLayout
//       title="Usage Trend for Cloud Hybrid Stores"
//       actions={{
//         optionAction:
//           actions && actions.optionAction ? actions.optionAction : "",
//         refreshAction:
//           actions && actions.refreshAction ? actions.refreshAction : ""
//       }}
//       advancedSearchData={advancedSearchData}
//       chart={
//         data.length ? (
//           <Trend
//             configuration={configuration}
//             data={data}
//             legends={[]}
//             options={options ? options : ""}
//           />
//         ) : (
//           emptyChartView()
//         )
//       }
//     />
//   );
// };

/**
 * Builds widget for constant.ReportsWidgetTypes.DEDUPE_SAVING_LINE
 * @param {object} configuration
 * @param {object} data
 */
// const buildWidgetForDedupeSaving = (
//   configuration,
//   data,
//   options,
//   actions,
//   advancedSearchData
// ) => {
//   configuration.toolTipTitle = "Sources";
//   return (
//     <ChartLayout
//       title="Dedupe savings trend for Cloud Hybrid Stores"
//       actions={{
//         optionAction:
//           actions && actions.optionAction ? actions.optionAction : "",
//         refreshAction:
//           actions && actions.refreshAction ? actions.refreshAction : ""
//       }}
//       advancedSearchData={advancedSearchData}
//       chart={
//         checkForValidData(data) ? (
//           <LineChart
//             configuration={configuration}
//             data={data}
//             options={options ? options : ""}
//           />
//         ) : (
//           emptyChartView()
//         )
//       }
//     />
//   );
// };

/**
 * Builds widget for DashboardWidgetTypes.LAST_JOB_STATUS
 * @param {object} configuration
 * @param {object} data
 */
// const buildWidgetForLastJobStatus = (
//   configuration,
//   data,
//   options,
//   actions,
//   advancedSearchData
// ) => {
//   return (
//     <ChartLayout
//       title="Backup Jobs Summary"
//       actions={{
//         optionAction:
//           actions && actions.optionAction ? actions.optionAction : "",
//         refreshAction:
//           actions && actions.refreshAction ? actions.refreshAction : ""
//       }}
//       advancedSearchData={advancedSearchData}
//       options={options ? options : ""}
//       chart={
//         data.length ? (
//           <DonutChart
//             configuration={configuration}
//             legendTitle="Job"
//             data={data}
//           />
//         ) : (
//           emptyChartView()
//         )
//       }
//     />
//   );
// };

export default ReportsHelper;

import { RecoveryTypes } from "components/protect/destination/data-store/browse/recovery-points/Constants";
import { combineReducers } from "redux";
import {
  POLICY_DATA_GET_SUCCESS,
  DESTINATIONS_DATA_GET_SUCCESS,
  RECOVERY_POINTS_BY_DESTINATION_PATH_GET_SUCCESS,
  FETCH_AVAILABLE_VOLUMES,
  FETCH_AVAILABLE_VOLUMES_SUCCESS,
  FETCH_MOUNTED_VOLUMES_SUCCESS,
  FETCH_MOUNTED_VOLUMES,
  MOUNT_VOLUME_SUCCESS,
  CLEAR_RECOVERY_MOUNT_DATA,
  CLEAR_RESTORE_RECOVERY_POINTS,
  GET_DESTINATION_HOSTS_SUCCESS,
  STORE_SHARED_PATH_CREDENTIALS,
  RECOVERY_NEXT_PAGE,
  RECOVERY_PREV_PAGE,
  RECOVERY_CLEAR_PAGE,
  SET_RECOVERY_TYPE,
  RESET_RECOVERY,
  FETCH_INITIAL_TREE_DATA,
  FETCH_RECURSIVE_TREE_DATA,
  SET_TREE_SELECTION,
  SET_SQL_SUMMARY_DATA,
  SET_SQL_DB_DETAILS,
  SET_SQL_CLICKED_CONFIGURATION_BUTTON_ID,
  SET_FnF_SUMMARY_DATA,
  SET_VOLUME_SUMMARY_DATA,
  SET_VM_SUMMARY_DATA,
  SET_FnF_LINUX_SUMMARY_DATA,
  GET_LINUX_SCRIPTS_SUCCESS,
  FnF_FETCH_VOLUMES_FOR_RP,
  FnF_FETCH_ITEMS_FOR_RP,
  FnF_FETCH_ITEMS_FOR_RP_LINUX,
  FnF_FETCH_ITEMS_DATA_FOR_RP_LINUX,
  HIDE_FOLDER_STRUCTURE_LOADER,
  STORE_SOURCE_DATA,
  UPDATE_SELECTED_LBS,
  STORE_RECOVERY_POINT,
  UPDATE_FOLDER_TREE_DATA,
  SELECTED_SOURCE_PAGINATION,
  UPDATE_FNF_TABLE_DATA,
  UPDATE_FNF_TABLE_DATA_PAGINATION,
  UPDATE_FNF_PAGINATION,
  GET_BACKUP_VM_DISKS_SUCCESS,
  SHOW_WARNING_SUCCESS,
  GET_ESX_SERVERS_SUCCESS,
  GET_ESX_VCENTER_LIST_SUCCESS,
  GET_HYPERV_LIST_SUCCESS,
  CONNECT_TO_HYPERV_VM_DEFAULT_PATH_SUCCESS,
  GET_NETWORK_ADAPTERS_SUCCESS,
  STORE_SELECTED_ITEM_FROM_ALL_PAGES,
  STORE_SELECTED_ITEM_FOR_RECOVERY,
  STORE_VALIDATE_VM_RESPONSE,
  STORE_VALIDATE_LINUX_VM_RESPONSE,
  STORE_HYPERVISOR_DATA,
  SAVE_RP_PASSWORD,
  RESET_RP_PASSWORD,
  FETCH_FNF_TABLE_DATA,
  FETCH_FNF_TABLE_DATA_LINUX,
  NO_RECOVERY_POINTS,
  FETCH_VMWARE_STORAGE_POLCIES_SUCCESS,
  FETCH_VMWARE_DATASTORES_SUCCESS,
  CONNECT_TO_ESX_VCENTER_SUCCESS,
  RECOVERY_FULL_VM_WINDOWS_PAYLOAD,
  SET_DATA_STORE_ENCRYPTION_ENABLED,
  SET_RESTORE_VOLUME,
  STORE_HYPERV_NETWORK_LIST,
  STORE_VMWARE_NETWORK_LIST,
  STORE_HYPERV_DEFAULT_VM_PATH,
  STORE_HYPERV_DEFAULT_VHD_PATH,
  SAVE_RECOVERY_SELECTED_DESTINATION,
  SAVE_RECOVERY_DB_CONFIG_DATA,
  CLEAR_RECOVERY_DB_CONFIG_DATA,
  CLEAR_RECOVERY_DB_STATIC_TABLE,
  SHOW_FREE_SIZE_WARNING,
  CLEAR_HYPERV_PATHS,
  CLEAR_IVM,
  GET_RECOVERY_POINTS_BY_DESTINATION_PATH,
  GET_DESTINATIONS_BY_SOURCE_ID,
  SAVE_RECOVERY_SELECTED_DESTINATION_DATA,
  GET_HYPERVISOR_TYPE_SUCCESS,
  GET_SELECTED_SOURCE_HYPERVISOR_TYPE_SUCCESS,
  GET_SELECTED_SOURCE_HYPERVISOR_TYPE_LOADING,
  GET_SELECTED_SOURCE_HYPERVISOR_TYPE_DONE,
  GET_RECOVERY_POINT_RESPONSE,
  RESET_RECOVERY_ON_CONNECT,
  INITIALIZE_APP,
  SET_SELECTED_HYPERVISOR_ID_IN_RECOVERY,
  SET_RECOVERY_NEXT_LOADING,
  SET_NEW_VM_ID,
  CLEAR_RECOVERY_ORACLE_DB_SETTING,
  STORE_RECOVERY_ORACLE_DB_SETTING,
  CLEAR_RECOVERY_ORACLE_DB_TABLE_SETTING,
  STORE_RECOVERY_ORACLE_DB_TABLE_SETTING,
  STORE_RECOVERY_ORACLE_PDB_LIST,
  CLEAR_RECOVERY_ORACLE_PDB_LIST,
  ORACLE_RECOVERY_TYPE,
  SET_ORACLE_SUMMARY_DATA,
  GET_NUTANIX_STORAGE_SUCCESS,
  GET_NUTANIX_NETWORKS_SUCCESS
} from "state/actions/actionTypes";

function newVMInstanceUID(state = "", action) {
  switch (action.type) {
    case SET_NEW_VM_ID:
      return action.data;
    case RESET_RECOVERY:
      return "";
    default:
      return state;
  }
}

function policyDataResponse(state = null, action) {
  switch (action.type) {
    case POLICY_DATA_GET_SUCCESS:
      return { ...action.data };
    case CLEAR_RECOVERY_MOUNT_DATA:
      return null;
    default:
      return state;
  }
}

function destinationsDataBySourceForRecovery(state = null, action) {
  switch (action.type) {
    case DESTINATIONS_DATA_GET_SUCCESS:
      return [...action.data];
    case CLEAR_RESTORE_RECOVERY_POINTS:
    case CLEAR_IVM:
      return [];
    default:
      return state;
  }
}

function isSourceRecoveryEncryptionEnabled(state = false, action) {
  switch (action.type) {
    case SET_DATA_STORE_ENCRYPTION_ENABLED:
      return action.id ? true : false;
    default:
      return state;
  }
}

function isSQLServerWriterEnabled(state = false, action) {
  switch (action.type) {
    case SET_RESTORE_VOLUME:
      return action.id ? true : false;
    default:
      return state;
  }
}

function recoveryPointsDataForDestinationPath(state = null, action) {
  switch (action.type) {
    case RECOVERY_POINTS_BY_DESTINATION_PATH_GET_SUCCESS:
      return [...action.data];
    case CLEAR_RECOVERY_MOUNT_DATA:
    case CLEAR_IVM:
    case GET_RECOVERY_POINTS_BY_DESTINATION_PATH:
    case GET_DESTINATIONS_BY_SOURCE_ID:
      return null;
    default:
      return state;
  }
}

function availableVolumes(state = [], action) {
  switch (action.type) {
    case FETCH_AVAILABLE_VOLUMES:
      return [];
    case FETCH_AVAILABLE_VOLUMES_SUCCESS:
      return action.data;
    case CLEAR_RECOVERY_MOUNT_DATA:
      return [];
    default:
      return state;
  }
}

function getRecoveryPointResponse(state = [], action) {
  switch (action.type) {
    case GET_RECOVERY_POINT_RESPONSE:
      return action.data;
    case CLEAR_RECOVERY_MOUNT_DATA:
    case CLEAR_IVM:
    case GET_RECOVERY_POINTS_BY_DESTINATION_PATH:
    case GET_DESTINATIONS_BY_SOURCE_ID:
      return null;
    default:
      return state;
  }
}

function mountedVolumes(state = [], action) {
  switch (action.type) {
    case FETCH_MOUNTED_VOLUMES:
      return [];
    case FETCH_MOUNTED_VOLUMES_SUCCESS:
      return action.data;
    case MOUNT_VOLUME_SUCCESS:
      return action.data;
    case CLEAR_RECOVERY_MOUNT_DATA:
      return [];
    default:
      return state;
  }
}

function recoveryFlowPage(state = 0, action) {
  const pageCount = action.count ? action.count : 1;
  switch (action.type) {
    case RECOVERY_NEXT_PAGE:
      return state + pageCount;
    case RECOVERY_PREV_PAGE:
      return state - pageCount;
    case RECOVERY_CLEAR_PAGE:
      return 0;
    case RESET_RECOVERY:
      return 0;
    default:
      return state;
  }
}

function recoveryType(state = RecoveryTypes.NOT_SELECTED, action) {
  switch (action.type) {
    case SET_RECOVERY_TYPE:
      return action.data;
    case RESET_RECOVERY:
      return RecoveryTypes.NOT_SELECTED;
    default:
      return state;
  }
}

function destinationHostList(state = [], action) {
  switch (action.type) {
    case GET_DESTINATION_HOSTS_SUCCESS:
      return action.data;
    case RESET_RECOVERY:
      return [];
    default:
      return state;
  }
}

function sharedPathCredentials(state = null, action) {
  switch (action.type) {
    case STORE_SHARED_PATH_CREDENTIALS:
      return action.data;
    case RESET_RECOVERY:
      return null;
    default:
      return state;
  }
}

function treeData(state = null, action) {
  switch (action.type) {
    case FETCH_INITIAL_TREE_DATA:
      return null;
    case FETCH_RECURSIVE_TREE_DATA:
      // Modify and make the tree data with
      // existing tree data and return the new one
      return null;
    case RESET_RECOVERY:
      return null;
    default:
      return state;
  }
}

function treeSelection(state = [], action) {
  switch (action.type) {
    case SET_TREE_SELECTION:
      return [];
    case RESET_RECOVERY:
      return [];
    default:
      return state;
  }
}
function sqlSummaryData(state = [], action) {
  switch (action.type) {
    case SET_SQL_SUMMARY_DATA:
      return action.data;
    default:
      return state;
  }
}
function oracleSummaryData(state = [], action) {
  switch (action.type) {
    case SET_ORACLE_SUMMARY_DATA:
      return action.data;
    case RESET_RECOVERY:
      return [];
    default:
      return state;
  }
}

function sqlDbDetails(state = null, action) {
  switch (action.type) {
    case SET_SQL_DB_DETAILS:
      return action.data;
    case RESET_RECOVERY:
      return null;
    default:
      return state;
  }
}

function clickedConfigureButtonId(state = [], action) {
  switch (action.type) {
    case SET_SQL_CLICKED_CONFIGURATION_BUTTON_ID:
      return action.data;
    default:
      return state;
  }
}

function fnfSummaryData(state = null, action) {
  switch (action.type) {
    case SET_FnF_SUMMARY_DATA:
      return action.data;
    case RESET_RECOVERY:
      return null;
    default:
      return state;
  }
}

function volumeSummaryData(state = null, action) {
  switch (action.type) {
    case SET_VOLUME_SUMMARY_DATA:
      return action.data;
    case RESET_RECOVERY:
      return null;
    default:
      return state;
  }
}

function VMSummaryData(state = null, action) {
  switch (action.type) {
    case SET_VM_SUMMARY_DATA:
      return action.data;
    case RESET_RECOVERY:
      return null;
    default:
      return state;
  }
}

function fnfLinuxSummaryData(state = [], action) {
  switch (action.type) {
    case SET_FnF_LINUX_SUMMARY_DATA:
      return action.data;
    case RESET_RECOVERY:
      return [];
    default:
      return state;
  }
}

function linuxScriptsList(state = [], action) {
  switch (action.type) {
    case GET_LINUX_SCRIPTS_SUCCESS:
      return action.data;
    case RESET_RECOVERY:
      return [];
    default:
      return state;
  }
}

function selectedSource(state = null, action) {
  switch (action.type) {
    case STORE_SOURCE_DATA:
      return action.data;
    case RESET_RECOVERY:
      // case CLEAR_IVM:
      return null;
    default:
      return state;
  }
}

function updatedLbs(state = null, action) {
  switch (action.type) {
    case UPDATE_SELECTED_LBS:
      return action.data;
    default:
      return state;
  }
}

function selectedHypervisorType(state = { hypervisor_type: "" }, action) {
  switch (action.type) {
    case GET_HYPERVISOR_TYPE_SUCCESS:
      return action.data;
    case RESET_RECOVERY:
      return {};
    default:
      return state;
  }
}

function selectedSourceHypervisorType(
  state = { loading: false, hypervisor_type: "" },
  action
) {
  switch (action.type) {
    case GET_SELECTED_SOURCE_HYPERVISOR_TYPE_LOADING:
      return { loading: true, hypervisor_type: "" };
    case GET_SELECTED_SOURCE_HYPERVISOR_TYPE_SUCCESS:
      return { ...action.data };
    case GET_SELECTED_SOURCE_HYPERVISOR_TYPE_DONE:
      return { ...state, loading: false };
    case RESET_RECOVERY:
      return { loading: false };
    default:
      return state;
  }
}

function selectedHypervisorIdInRecovery(state = "", action) {
  switch (action.type) {
    case SET_SELECTED_HYPERVISOR_ID_IN_RECOVERY:
      return action.data;
    case RESET_RECOVERY:
      return "";
    default:
      return state;
  }
}

function selectedRecoveryPoint(state = null, action) {
  switch (action.type) {
    case STORE_RECOVERY_POINT:
      return action.data;
    case RESET_RECOVERY:
      return null;
    default:
      return state;
  }
}

function selectedItemForRecovery(state = [], action) {
  switch (action.type) {
    case STORE_SELECTED_ITEM_FOR_RECOVERY:
      return action.data;
    case STORE_SELECTED_ITEM_FROM_ALL_PAGES:
      return action.data;
    case FnF_FETCH_VOLUMES_FOR_RP:
    case UPDATE_FNF_TABLE_DATA:
    case RESET_RECOVERY:
      return [];
    default:
      return state;
  }
}

function folderTreeData(state = [], action) {
  switch (action.type) {
    case UPDATE_FOLDER_TREE_DATA:
      return action.payload;
    case RESET_RECOVERY:
    case FnF_FETCH_VOLUMES_FOR_RP:
    case NO_RECOVERY_POINTS:
      return [];
    default:
      return state;
  }
}
function selectedSourcePagination(
  state = { gridCurrentPage: 1, gridPageSize: 25 },
  action
) {
  switch (action.type) {
    case SELECTED_SOURCE_PAGINATION:
      return action.payload;
    default:
      return state;
  }
}
function folderTreeDataLoadingState(
  state = { treeNodeId: "", loading: false },
  action
) {
  switch (action.type) {
    case HIDE_FOLDER_STRUCTURE_LOADER:
    case UPDATE_FNF_TABLE_DATA:
      return { treeNodeId: "", loading: false };
    case FnF_FETCH_VOLUMES_FOR_RP:
      return { treeNodeId: "", loading: true };
    case FnF_FETCH_ITEMS_FOR_RP_LINUX:
      return { treeNodeId: "", loading: true };
    case FnF_FETCH_ITEMS_FOR_RP:
    case FnF_FETCH_ITEMS_DATA_FOR_RP_LINUX:
    case FETCH_FNF_TABLE_DATA:
      return { treeNodeId: action.data.treeNodeId, loading: false };
    case FETCH_FNF_TABLE_DATA_LINUX:
      return { treeNodeId: action.data.treeNodeId, loading: false };
    default:
      return state;
  }
}
function FnfGridData(state = [], action) {
  switch (action.type) {
    case UPDATE_FNF_TABLE_DATA:
      return action.payload;
    case RESET_RECOVERY:
    case FnF_FETCH_VOLUMES_FOR_RP:
    case NO_RECOVERY_POINTS:
      return [];
    default:
      return state;
  }
}

function FnfGridDataPagination(
  state = { pagination: {}, currSelectedData: {} },
  action
) {
  switch (action.type) {
    case UPDATE_FNF_TABLE_DATA_PAGINATION:
      return {
        ...state,
        pagination: action.payload.pagination,
        currSelectedData: action.payload.currSelectedData
      };
    case UPDATE_FNF_PAGINATION:
      return { ...state, pagination: action.payload.pagination };
    case RESET_RECOVERY:
      return {
        ...state,
        pagination: {},
        currSelectedData: {}
      };
    default:
      return state;
  }
}

function hypervisorInfo(state = null, action) {
  switch (action.type) {
    case STORE_HYPERVISOR_DATA:
      return action.data;
    case RESET_RECOVERY:
    case STORE_SOURCE_DATA:
      return null;
    default:
      return state;
  }
}

function validVMData(state = null, action) {
  switch (action.type) {
    case STORE_VALIDATE_VM_RESPONSE:
      return action.data;
    case RESET_RECOVERY:
      return null;
    default:
      return state;
  }
}

function validLinuxVMData(state = null, action) {
  switch (action.type) {
    case STORE_VALIDATE_LINUX_VM_RESPONSE:
      return action.data;
    case RESET_RECOVERY:
      return null;
    default:
      return state;
  }
}

function rpEncryptionPassword(state = "", action) {
  switch (action.type) {
    case SAVE_RP_PASSWORD:
      return action.data;
    case RESET_RECOVERY:
    case STORE_SOURCE_DATA:
    case RESET_RP_PASSWORD:
      return "";
    default:
      return state;
  }
}

// Recover VM
function backupVmDisks(state = [], action) {
  switch (action.type) {
    case GET_BACKUP_VM_DISKS_SUCCESS:
      return action.data;
    case RESET_RECOVERY:
    case STORE_SOURCE_DATA:
      return [];
    default:
      return state;
  }
}

function warningMsg(state = "", action) {
  switch (action.type) {
    case SHOW_WARNING_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function networkAdapters(state = [], action) {
  switch (action.type) {
    case GET_NETWORK_ADAPTERS_SUCCESS:
      return action.data;
    case RESET_RECOVERY:
    case STORE_SOURCE_DATA:
      return [];
    default:
      return state;
  }
}

function esxServersList(state = [], action) {
  switch (action.type) {
    case GET_ESX_SERVERS_SUCCESS:
      return action.data;
    case RESET_RECOVERY:
    case STORE_SOURCE_DATA:
      return [];
    default:
      return state;
  }
}

function hypervList(state = [], action) {
  switch (action.type) {
    case GET_HYPERV_LIST_SUCCESS:
      return action.data;
    case RESET_RECOVERY:
    case STORE_SOURCE_DATA:
      return [];
    default:
      return state;
  }
}

function isRecoveryNextLoading(state = false, action) {
  switch (action.type) {
    case SET_RECOVERY_NEXT_LOADING:
      return action.data;
    case RESET_RECOVERY:
      return false;
    default:
      return state;
  }
}

function esxVcenterList(state = [], action) {
  switch (action.type) {
    case GET_ESX_VCENTER_LIST_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function selectedHyperVType(state = [], action) {
  switch (action.type) {
    case CONNECT_TO_HYPERV_VM_DEFAULT_PATH_SUCCESS:
      return action.data;
    case RESET_RECOVERY:
    case STORE_SOURCE_DATA:
      return "";
    default:
      return state;
  }
}

function nutanixStorage(state = [], action) {
  switch (action.type) {
    case GET_NUTANIX_STORAGE_SUCCESS:
      return action.data;
    case RESET_RECOVERY:
    case STORE_SOURCE_DATA:
      return "";
    default:
      return state;
  }
}

function nutanixNetworks(state = [], action) {
  switch (action.type) {
    case GET_NUTANIX_NETWORKS_SUCCESS:
      return action.data;
    case RESET_RECOVERY:
    case STORE_SOURCE_DATA:
      return "";
    default:
      return state;
  }
}

function vmwareDatastores(state = [], action) {
  switch (action.type) {
    case FETCH_VMWARE_DATASTORES_SUCCESS:
      return action.data;
    case RESET_RECOVERY:
    case RESET_RECOVERY_ON_CONNECT:
    case STORE_SOURCE_DATA:
      return [];
    default:
      return state;
  }
}

function vmwareStoragePolicies(state = [], action) {
  switch (action.type) {
    case FETCH_VMWARE_STORAGE_POLCIES_SUCCESS:
      return action.data;
    case RESET_RECOVERY:
    case RESET_RECOVERY_ON_CONNECT:
    case STORE_SOURCE_DATA:
      return [];
    default:
      return state;
  }
}

function esxServers(state = [], action) {
  switch (action.type) {
    case CONNECT_TO_ESX_VCENTER_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function recoverVMPayload(state = [], action) {
  switch (action.type) {
    case RECOVERY_FULL_VM_WINDOWS_PAYLOAD:
      return action.data;
    default:
      return state;
  }
}

function hypervNetworks(state = [], action) {
  switch (action.type) {
    case STORE_HYPERV_NETWORK_LIST:
      return action.data;
    case RESET_RECOVERY:
    case RESET_RECOVERY_ON_CONNECT:
    case STORE_SOURCE_DATA:
      return [];
    default:
      return state;
  }
}

function selectedDestinationData(state = {}, action) {
  switch (action.type) {
    case SAVE_RECOVERY_SELECTED_DESTINATION_DATA:
      return action.data;
    case RESET_RECOVERY:
    case INITIALIZE_APP:
      return null;
    default:
      return state;
  }
}
function showFreeSizeWarning(
  state = { show: false, datastoreName: "" },
  action
) {
  switch (action.type) {
    case SHOW_FREE_SIZE_WARNING:
      return { ...state, ...action.data };
    case RESET_RECOVERY:
      return { show: false, datastoreName: "" };
    default:
      return state;
  }
}

function vmwareNetworks(state = [], action) {
  switch (action.type) {
    case STORE_VMWARE_NETWORK_LIST:
      return action.data;
    case RESET_RECOVERY:
    case RESET_RECOVERY_ON_CONNECT:
    case STORE_SOURCE_DATA:
      return [];
    default:
      return state;
  }
}

function defaultVmPath(state = "", action) {
  switch (action.type) {
    case STORE_HYPERV_DEFAULT_VM_PATH:
      return action.data && action.data.vm_folder ? action.data.vm_folder : "";
    case RESET_RECOVERY:
    case STORE_SOURCE_DATA:
    case CLEAR_HYPERV_PATHS:
      return "";
    default:
      return state;
  }
}

function defaultVhdPath(state = "", action) {
  switch (action.type) {
    case STORE_HYPERV_DEFAULT_VHD_PATH:
      return action.data && action.data.vhd_folder
        ? action.data.vhd_folder
        : "";
    case RESET_RECOVERY:
    case STORE_SOURCE_DATA:
    case CLEAR_HYPERV_PATHS:
      return "";
    default:
      return state;
  }
}

function selectedDestination(state = {}, action) {
  switch (action.type) {
    case SAVE_RECOVERY_SELECTED_DESTINATION:
      return action.data;
    default:
      return state;
  }
}

function oracleDBConfigurationsData(state = {}, action) {
  switch (action.type) {
    case SAVE_RECOVERY_DB_CONFIG_DATA:
      return action.data;
    case CLEAR_RECOVERY_DB_CONFIG_DATA:
      return {};
    case CLEAR_RECOVERY_DB_STATIC_TABLE:
      return {
        ...state,
        param_files: [],
        archieve_logs: [],
        control_files: []
      };
    default:
      return state;
  }
}

function storedOracleDBSettings(state = {}, action) {
  switch (action.type) {
    case STORE_RECOVERY_ORACLE_DB_SETTING:
      return action.data;
    case CLEAR_RECOVERY_ORACLE_DB_SETTING:
      return {};
    default:
      return state;
  }
}

function storedOracleDBTableSettings(state = {}, action) {
  switch (action.type) {
    case STORE_RECOVERY_ORACLE_DB_TABLE_SETTING:
      return action.data;
    case CLEAR_RECOVERY_ORACLE_DB_TABLE_SETTING:
      return {};
    default:
      return state;
  }
}

function OraclePDBList(state = [], action) {
  switch (action.type) {
    case STORE_RECOVERY_ORACLE_PDB_LIST:
      return action.data;
    case CLEAR_RECOVERY_ORACLE_PDB_LIST:
      return [];
    default:
      return state;
  }
}

export default combineReducers({
  policyDataResponse,
  destinationsDataBySourceForRecovery,
  isSourceRecoveryEncryptionEnabled,
  isSQLServerWriterEnabled,
  recoveryPointsDataForDestinationPath,
  getRecoveryPointResponse,
  availableVolumes,
  mountedVolumes,
  destinationHostList,
  recoveryFlowPage,
  recoveryType,
  treeData,
  treeSelection,
  sqlSummaryData,
  oracleSummaryData,
  sqlDbDetails,
  clickedConfigureButtonId,
  fnfSummaryData,
  volumeSummaryData,
  fnfLinuxSummaryData,
  linuxScriptsList,
  selectedSource,
  updatedLbs,
  selectedRecoveryPoint,
  folderTreeData,
  selectedSourcePagination,
  FnfGridData,
  FnfGridDataPagination,
  backupVmDisks,
  networkAdapters,
  esxServersList,
  hypervList,
  esxVcenterList,
  selectedHyperVType,
  selectedItemForRecovery,
  hypervisorInfo,
  validVMData,
  validLinuxVMData,
  rpEncryptionPassword,
  folderTreeDataLoadingState,
  vmwareDatastores,
  vmwareStoragePolicies,
  esxServers,
  recoverVMPayload,
  sharedPathCredentials,
  hypervNetworks,
  vmwareNetworks,
  defaultVmPath,
  defaultVhdPath,
  selectedDestination,
  VMSummaryData,
  selectedDestinationData,
  selectedHypervisorType,
  selectedHypervisorIdInRecovery,
  warningMsg,
  showFreeSizeWarning,
  isRecoveryNextLoading,
  selectedSourceHypervisorType,
  newVMInstanceUID,
  oracleDBConfigurationsData,
  storedOracleDBSettings,
  storedOracleDBTableSettings,
  OraclePDBList,
  nutanixStorage,
  nutanixNetworks
});

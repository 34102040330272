import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getTextWidth } from "utils";
import { CHART_COLORS, ROTATE_ANGLE_X, ColorCodes } from "utils/appConstants";
import { withCCGraph } from "components/common/CCGraph/HOCs";
import * as sGraph from "state/selectors/ccGraph";
import Wrapper from "./RechartWrapper";
import InsufficientData from "./InsufficientData";
import { Bar, BarChart, XAxis, YAxis, Tooltip, Legend, Label } from "recharts";
import { colorSchema as getColorSchema } from "utils/SpogDataGridUtil";
import TooltipComponent from "./TooltipComponent";

// https://github.com/eventbrite/britecharts/blob/master/src/charts/helpers/load.js

const radians = a => {
  return (a * Math.PI) / 180;
};

const loadingState = (width, height) => (
  <svg
    className="load-state bar-load-state"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 711 325"
    width={width}
    height={height}
  >
    <defs>
      <linearGradient id="lgrad" x1="0%" y1="50%" x2="100%" y2="50%">
        <stop offset="0" className="loading-charts" stopOpacity="0.8" />
        <stop offset="33.33%" className="loading-charts" stopOpacity="0.8" />
        <stop offset="50%" className="loading-charts" stopOpacity="0" />
        <stop offset="66.66%" className="loading-charts" stopOpacity="0.8" />
        <stop offset="100%" className="loading-charts" stopOpacity="0.8" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 29)">
        <g stroke="#EFF2F5" strokeDasharray="4 4">
          <path d="M.400592911 200.302477L710.674315 200.302477M.400592884 131.172748L710.674315 131.172748M.324410282 64.2071321L710.621499 64.2071321M.291004517.563888874L709.82431.563888889" />
        </g>
        <g fill="#D2D6DF" transform="translate(63.08 11)">
          <polygon points="-.08 176 23.92 176 23.92 255 -.08 255" />
          <polygon points="50.829 147 74.829 147 74.829 255 50.829 255" />
          <polygon points="254.465 0 278.465 0 278.465 255 254.465 255" />
          <polygon points="458.102 169 482.102 169 482.102 255 458.102 255" />
          <polygon points="152.647 82 176.647 82 176.647 255 152.647 255" />
          <polygon points="356.283 66 380.283 66 380.283 255 356.283 255" />
          <polygon points="559.92 229 583.92 229 583.92 255 559.92 255" />
          <polygon points="101.738 115 125.738 115 125.738 255 101.738 255" />
          <polygon points="305.374 42 329.374 42 329.374 255 305.374 255" />
          <polygon points="509.011 201 533.011 201 533.011 255 509.011 255" />
          <polygon points="203.556 19 227.556 19 227.556 255 203.556 255" />
          <polygon points="407.192 115 431.192 115 431.192 255 407.192 255" />
        </g>
      </g>
      <polygon
        fill="#D2D6DF"
        fillRule="nonzero"
        points="0 295 711 295 711 294 0 294"
      />
    </g>
    <rect
      className="chart-filter"
      fill="url(#lgrad)"
      x="-100%"
      y="0"
      width="300%"
      height="100%"
    ></rect>
  </svg>
);
const StackedBarChart = props => {
  let wrapperRef = useRef();
  const prevProps = usePreviousValues(props);
  const [colorSchema, setColorSchema] = useState(CHART_COLORS);

  useEffect(() => {
    if (
      !props.colorSchema &&
      props.originalData !== (prevProps && prevProps.originalData)
    ) {
      setColorSchema(
        getColorSchema(props.originalData, ColorCodes, "stack") || CHART_COLORS
      );
    } else if (props.colorSchema) {
      setColorSchema(props.colorSchema);
    }
  }, [prevProps]); // eslint-disable-line react-hooks/exhaustive-deps

  // const getDurationFormatter = () => {
  //   return duration =>
  //     getDuration(duration * 60)
  //       .split(" ")
  //       .map(word =>
  //         props.intl.formatMessage({
  //           id: word !== "" ? word : "emptyString",
  //           defaultMessage: word
  //         })
  //       )
  //       .join(" ");
  // };
  let fixRightPosition = 0;
  const StackedBarChartData =
    props.data && props.data.data && props.data.data.length > 0
      ? props.data.data
      : [];
  StackedBarChartData &&
    StackedBarChartData.forEach(ele => {
      let textLength = Math.abs(
        getTextWidth("ABCDEFGHIJKLMNO", `9px  ${"Sans Source Pro"}`) * // 15 char space
          Math.cos(radians(22))
      );
      fixRightPosition = textLength;
    });
  const BarChartComponent = () => {
    if (!wrapperRef || !wrapperRef.current) return null;
    const { width, height } = getComputedStyle(wrapperRef.current);
    let myWidth = parseInt(width, 10) > 0 ? parseInt(width, 10) : 0;
    let myHeight = parseInt(height, 10) > 0 ? parseInt(height, 10) : 0;
    myWidth =
      myWidth === 0
        ? parseInt(
            (props.windowWidth ? props.windowWidth : window.innerWidth) *
              (props.widthAdjust ? props.widthAdjust : 1),
            10
          )
        : myWidth;
    myHeight =
      myHeight === 90
        ? parseInt(
            (props.windowHeight ? props.windowHeight : window.innerHeight) *
              (props.heightAdjust ? props.heightAdjust : 1),
            10
          )
        : myHeight;
    if (props.isLoading) {
      return loadingState(myWidth, myHeight);
    }
    const CustomizedXAxisTick = props => {
      const { x, y, payload } = props;
      let value = payload.value;
      value = value.match(/.{1,15}/g);
      // the x and y values for below text tags are obtained by trial and error method , while changing them make sure you dont break it at other places
      return (
        <g
          className="XaxisText"
          transform={`translate(${x},${y}) rotate(25)`}
          height={"100%"}
        >
          {value.length === 1 && (
            <>
              <text
                x={0}
                y={4}
                textAnchor="start"
                className="barchart-x-axis-label"
              >
                {value[0]}
              </text>
            </>
          )}
          {value.length > 1 && (
            <>
              <text
                x={0}
                y={3}
                textAnchor="start"
                className="barchart-x-axis-label"
              >
                {value[0]}
              </text>
              <text
                x={-15}
                y={10}
                textAnchor="start"
                className="barchart-x-axis-label"
              >
                {value[1]}
                {value.length > 2 && "..."}
              </text>
            </>
          )}
        </g>
      );
    };
    const yAxisLabelId =
      typeof props.yAxisLabelId === "function"
        ? props.yAxisLabelId(props.filter && props.filter.filter_type)
        : props.yAxisLabelId;
    const CustomizedLabel = props => {
      const { chartProps } = props;
      return (
        <g transform={`rotate(-90)`}>
          <text
            x={-220}
            y={30}
            fontFamily={"Sans Source Pro"}
            fontWeight={400}
            fontSize={16}
            textAnchor="start"
            fill="#adb0b6"
          >
            {chartProps.intl.formatMessage({
              id: yAxisLabelId
            })}
          </text>
        </g>
      );
    };
    // const processedData = !props.order
    //   ? StackedBarChartData
    //   : props.order
    //       .filter(o => StackedBarChartData.find(d => d.id === o))
    //       .map(o => StackedBarChartData.find(d => d.id === o));
    let stacks =
      (props.data && props.data.stacks && props.data.stacks) ||
      props.order ||
      [];
    return (
      <BarChart
        width={myWidth}
        height={myHeight}
        data={StackedBarChartData}
        margin={{
          top: 20,
          left: 10,
          right: fixRightPosition - 5
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          textAnchor={"start"}
          interval={0}
          angle={22}
          dataKey="name"
          height={Math.abs(
            getTextWidth("ABCDEFGHIJKLMNOPQRST", `9px ${"Sans-Regular"}`) *
              Math.sin((Math.PI * 22) / 180) +
              7
          )} //20 characters space is added , but displaying only 15 characters
          fontFamily={"Sans Source Pro"}
          tick={<CustomizedXAxisTick />}
          fontWeight={400}
        />
        <YAxis
          fill="#8f8f8f"
          axisLine={false}
          fontSize={11}
          tick={{ fill: "#8f8f8f" }}
          fontFamily={"Sans Source Pro"}
          fontWeight={400}
          scale={"linear"}
        >
          {yAxisLabelId && (
            <Label content={<CustomizedLabel chartProps={props} />} />
          )}
        </YAxis>
        <Legend
          name="legend-stacked-bar"
          iconSize="16"
          iconType="circle"
          formatter={(value, entry, index) => {
            return (
              <span
                style={{
                  fontSize: "15px",
                  marginRight: "21px",
                  marginLeft: "4px"
                }}
                className="stacked-chart-legend"
              >
                {props.intl.formatMessage({
                  id: `ccGraph.stacks.${value}`
                })}
              </span>
            );
          }}
          verticalAlign="top"
          height={66}
        />
        <Tooltip
          chartConfig={props.config}
          myWidth={myWidth}
          locale={props.intl.locale}
          order={stacks}
          cursor={{ fill: "transparent" }}
          content={<TooltipComponent className="StackedBarchart-tooltip" />}
          allowEscapeViewBox={{ x: true }}
          isAnimationActive={false}
        />
        {/* <Legend /> */}
        {stacks.map((stack, i) => (
          <Bar
            key={i}
            maxBarSize={194}
            $enableBackground={false}
            isAnimationActive={false}
            background={false}
            fill={colorSchema[i]}
            dataKey={stack}
            stackId="name"
          />
        ))}
      </BarChart>
    );
  };

  return (
    <>
      {!props.isLoading && StackedBarChartData.length <= 0 ? (
        <InsufficientData />
      ) : (
        <Wrapper wrapperRef={wrapperRef}>
          <BarChartComponent props={props} />
        </Wrapper>
      )}
    </>
  );
};
StackedBarChart.propTypes = {
  intl: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape().isRequired,
  colorSchema: PropTypes.arrayOf(PropTypes.string),
  rotateAngleX: PropTypes.number,
  yAxisLabelId: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  showLegends: PropTypes.bool,
  tooltipValueFormatter: PropTypes.func,
  order: PropTypes.arrayOf(PropTypes.string)
};

StackedBarChart.defaultProps = {
  colorSchema: null,
  rotateAngleX: ROTATE_ANGLE_X,
  yAxisLabelId: null,
  showLegends: false,
  tooltipValueFormatter: v => `${v}`,
  order: null
};

const makeMapState = (outerState, { ccGraph: { config: outerConfig } }) => {
  const getData = sGraph.makeGetStackedBarData(
    outerConfig.ccGraphId,
    outerConfig.graphType
  );
  const getFilter = sGraph.makeGetFilter(outerConfig.ccGraphId);
  const getTSStackedBarData = sGraph.makeGetRCStackedBarData(
    outerConfig.ccGraphId,
    outerConfig.graphType
  );

  return (state, { ccGraph: { config } }) => ({
    isLoading: sGraph.isLoading(config.ccGraphId)(state),
    originalData: getData(state),
    data: getTSStackedBarData(state),
    filter: getFilter(state),
    config
  });
};

export default compose(
  injectIntl,
  withCCGraph,
  connect(makeMapState)
)(StackedBarChart);
function usePreviousValues(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

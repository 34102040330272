import * as React from "react";
import Tooltip from "@mui/material/Tooltip";

const TooltipComponent = props => {
  return (
    <Tooltip
      title={props.content || props.defaultMessage}
      arrow
      className={`${props.className}`}
      placement={props.placement || "right"}
      classes={{
        tooltip: "tooltip-title",
        tooltipArrow: `tooltip-arrow${props.red ? "-red" : ""}`
      }}
    >
      <span>{props.children}</span>
    </Tooltip>
  );
};

export default TooltipComponent;

import { CHART_COLORS, ROTATE_ANGLE_X } from "utils/appConstants";

export const USAGE_TRENDS_FOR_CLOUD_HYBRID_STORES =
  "usageTrendsForCloudHybridStores";
export const USAGE_TRENDS_FOR_CLOUD_DIRECT_VOLUMES =
  "usageTrendsForCloudDirectVolumes";
export const USAGE_TRENDS_FOR_DATA_STORES = "usageTrendsForDataStores";
export const BACKUP_JOB_SUMMARY = "backupJobSummary";
export const TOP_PROBLEMATIC_SOURCES = "topProblematicSources";
export const TOP_PROBLEMATIC_RECOVERY_SOURCES = "topProblematicRecoverySources";
export const CAPACITY_USAGE_TREND_CLOUD_HYBRID_STORES =
  "capacityUsageTrendForCloudHybridStores";
export const DATA_TRANSFER_SUMMARY_FOR_CLOUD_DIRECT_VOLUMES =
  "dataTransferSummaryForCloudDirectVolumes";
export const TOP_10_POLICIES = "top10Policies";
export const ACTIVITY_SUMMARY = "activitySummary";
export const TOP_10_CUSTOMERS = "top10customers";
export const TOP_10_SOURCES = "topProblematicSources";
export const DASHBOARD_NUMBER_OF_RECOVERY_POINTS = "numberOfRecoveryPoints";
export const DASHBOARD_LATEST_RECOVERY_POINTS = "latestRecoveryPoint";
export const DASHBOARD_OLDEST_RECOVERY_POINTS = "oldestRecoveryPoint";
export const RECENT_10_JOBS_IN_PROGRESS = "recent10JobsInProgress";
export const STC_CHANGE_RATE = "stcChangeRate";
export const STC_USAGE = "stcUsage";

export const AllWidgetsList = [
  {
    dashboard_widget_id: "ddc66d96-6a54-4fc0-b0c4-a93004e43e2e",
    long_label: "Recent 10 Jobs In Progress",
    short_label: "Recent 10 Jobs In Progress",
    key: "recent_10_jobs_in_progress",
    visible: true,
    order_id: 1,
    is_expanded: true
  },
  {
    dashboard_widget_id: "63f9ed81-d8d2-45f7-a9f4-0b9eb24c275a",
    long_label: "Backup Job Summary",
    short_label: "Backup Job Summary",
    key: "backup_job_summary",
    visible: true,
    order_id: 2,
    is_expanded: true
  },
  {
    dashboard_widget_id: "8bdeec5c-46f4-45a7-98c9-23bb90182dd2",
    long_label: "Number of Recovery Points",
    short_label: "Number of Recovery Points",
    key: "number_of_recovery_point",
    visible: true,
    order_id: 3,
    is_expanded: true
  },
  {
    dashboard_widget_id: "fbc7aab6-0f98-44bb-88a0-d183cf412092",
    long_label: "Oldest Recovery Points",
    short_label: "Oldest Recovery Points",
    key: "oldest_recovery_point",
    visible: true,
    order_id: 4,
    is_expanded: true
  },
  {
    dashboard_widget_id: "0005deee-a9f0-4219-aa4f-4913834d7138",
    long_label: "Usage Trend For UDP Data Stores",
    short_label: "Usage Trend For UDP Data Stores",
    key: "usage_trend_for_udp_data_store",
    visible: true,
    order_id: 5,
    is_expanded: false
  },
  {
    dashboard_widget_id: "7a963a93-fe2b-462f-a3c0-02a4f4df760b",
    long_label: "Top 10 Sources",
    short_label: "Top 10 Sources",
    key: "top_10_sources",
    visible: true,
    order_id: 6,
    is_expanded: false
  },
  {
    dashboard_widget_id: "f45884db-88d8-4e0c-a627-e519c5207288",
    long_label: "Latest Recovery Points",
    short_label: "Latest Recovery Points",
    key: "latest_recovery_point",
    visible: true,
    order_id: 7,
    is_expanded: false
  },
  {
    dashboard_widget_id: "108d92a7-7603-4c8f-8570-d1f1b32b2e53",
    long_label: "Usage Trend For Cloud Hybrid Stores",
    short_label: "Usage Trend For Cloud Hybrid Stores",
    key: "usage_trend_for_cloud_hybrid_stores",
    visible: true,
    order_id: 7,
    is_expanded: false
  },
  {
    dashboard_widget_id: "c0537501-f4eb-4153-be7b-c1feebc0f3ba",
    long_label: "Top 10 Customers",
    short_label: "Top 10 Customers",
    key: "top_10_customers",
    visible: true,
    order_id: 8,
    is_expanded: false
  },
  {
    dashboard_widget_id: "8dcdaace-5dcf-4fb8-bb28-7f47056c8fc8",
    long_label: "Dedupe Savings Trend for Cloud Hybrid Stores",
    short_label: "Dedupe Savings Trend for Cloud Hybrid Stores",
    key: "dedup_savings_trend_for_cloud_hybrid_stores",
    visible: true,
    order_id: 10,
    is_expanded: false
  }
];

export const WidgetsList = [
  // {
  //   type: "backupJobSummary",
  //   show: true,
  //   data: [],
  //   key: "backup_job_summary"
  // },
  {
    type: "recent10JobsInProgress",
    configuration: {
      rotateAngleX: ROTATE_ANGLE_X
    },
    show: true,
    key: "recent_10_jobs_in_progress",
    data: [],
    actions: {
      refreshAction: "loadRecentTenJobsInProgressWidget"
    }
  },
  // {
  //   type: "topProblematicSources",
  //   show: true,
  //   key: "top_10_sources"
  // },
  {
    type: "numberOfRecoveryPoints",
    show: true,
    key: "number_of_recovery_point"
  },
  {
    type: "oldestRecoveryPoint",
    show: true,
    key: "oldest_recovery_point"
  },
  {
    type: "latestRecoveryPoint",
    show: true,
    key: "latest_recovery_point"
  }

  // {
  //   type: "top10Policies",
  //   configuration: {
  //     rotateAngleX: ROTATE_ANGLE_X,
  //     containerWidth: "",
  //     containerHeight: ""
  //   },
  //   key: "top_10_policies",
  //   show: true,
  //   data: [],
  //   actions: {
  //     refreshAction: "loadTopPoliciesWidget"
  //   }
  // },
  // {
  //   type: "top10customers",
  //   configuration: {
  //     rotateAngleX: ROTATE_ANGLE_X,
  //     containerWidth: "",
  //     containerHeight: "",
  //     colorSchema: ["#4BBFA5", "#F96B81", "#FACD6C", "#757181"]
  //   },
  //   key: "top_10_customers",
  //   show: true,
  //   data: [],
  //   actions: {
  //     refreshAction: "loadTopCustomersWidget"
  //   }
  // },
  // {
  //   type: "dataTransferSummaryForCloudDirectVolumes",
  //   configuration: {
  //     rotateAngleX: ROTATE_ANGLE_X
  //   },
  //   key: "data_transfer_summary_for_cloud_direct_volumes",
  //   show: true,
  //   data: [],
  //   actions: {
  //     refreshAction: "loadDataTransferSummaryWidget"
  //   }
  // },
  // {
  //   type: "usageTrendsForCloudHybridStores",
  //   configuration: {
  //     colorSchema: CHART_COLORS,
  //     rotateAngleX: ROTATE_ANGLE_X,
  //     xAxisFormat: "custom",
  //     xAxisCustomFormat: "%d %b %y",
  //     areaCurve: "linear"
  //   },
  //   key: "usage_trend_for_cloud_hybrid_stores",
  //   show: true,
  //   data: [],
  //   actions: {
  //     refreshAction: "loadUsageTrendCHStoresWidget"
  //   }
  // },
  // {
  //   type: "usageTrendsForCloudDirectVolumes",
  //   configuration: {
  //     colorSchema: CHART_COLORS,
  //     rotateAngleX: ROTATE_ANGLE_X,
  //     xAxisFormat: "custom",
  //     xAxisCustomFormat: "%d %b %y",
  //     areaCurve: "linear"
  //   },
  //   key: "usage_trend_for_cloud_direct_volumes",
  //   show: true,
  //   data: [],
  //   actions: {
  //     refreshAction: "loadUsageTrendCDVolumesWidget"
  //   }
  // },
  // {
  //   type: "capacityUsageTrendForCloudHybridStores",
  //   configuration: {
  //     rotateAngleX: ROTATE_ANGLE_X
  //   },
  //   key: "dedup_savings_trend_for_cloud_hybrid_stores",
  //   show: true,
  //   data: [],
  //   actions: {
  //     refreshAction: "loadCapacityUsageTrendWidget"
  //   }
  // },
  // {
  //   type: "usageTrendsForDataStores",
  //   configuration: {
  //     colorSchema: CHART_COLORS,
  //     rotateAngleX: ROTATE_ANGLE_X,
  //     xAxisFormat: "custom",
  //     xAxisCustomFormat: "%d %b %y",
  //     areaCurve: "linear"
  //   },
  //   key: "usage_trend_for_udp_data_store",
  //   show: true,
  //   data: [],
  //   actions: {
  //     refreshAction: ""
  //   }
  // },
  // {
  //   type: "stcChangeRate",
  //   show: true,
  //   key: "stc_change_rate"
  // },
  // {
  //   type: "stcUsage",
  //   show: true,
  //   key: "stc_usage"
  // }
];
/**
 * Lists the full list of supported dashboard types
 */
export const DashboardWidgetTypes = {
  USAGE_TRENDS_FOR_CLOUD_HYBRID_STORES,
  USAGE_TRENDS_FOR_CLOUD_DIRECT_VOLUMES,
  USAGE_TRENDS_FOR_DATA_STORES,
  BACKUP_JOB_SUMMARY,
  TOP_PROBLEMATIC_SOURCES, //TODO clean up
  CAPACITY_USAGE_TREND_CLOUD_HYBRID_STORES,
  TOP_10_POLICIES,
  DATA_TRANSFER_SUMMARY_FOR_CLOUD_DIRECT_VOLUMES,
  ACTIVITY_SUMMARY,
  RECENT_10_JOBS_IN_PROGRESS,
  TOP_10_CUSTOMERS,
  TOP_10_SOURCES,
  DASHBOARD_NUMBER_OF_RECOVERY_POINTS,
  DASHBOARD_LATEST_RECOVERY_POINTS,
  DASHBOARD_OLDEST_RECOVERY_POINTS
};

const COMMON_WIDGETS = [
  // BACKUP_JOB_SUMMARY,
  // TOP_PROBLEMATIC_SOURCES,
  // TOP_10_POLICIES,
  RECENT_10_JOBS_IN_PROGRESS,
  DASHBOARD_NUMBER_OF_RECOVERY_POINTS,
  DASHBOARD_LATEST_RECOVERY_POINTS,
  DASHBOARD_OLDEST_RECOVERY_POINTS
];
const UDP_WIDGETS = [
  // USAGE_TRENDS_FOR_DATA_STORES
];
const CD_WIDGETS = [
  // USAGE_TRENDS_FOR_CLOUD_DIRECT_VOLUMES,
  // DATA_TRANSFER_SUMMARY_FOR_CLOUD_DIRECT_VOLUMES
];
const CH_WIDGETS = [
  // USAGE_TRENDS_FOR_CLOUD_HYBRID_STORES,
  // CAPACITY_USAGE_TREND_CLOUD_HYBRID_STORES
];
const STC_WIDGETS = [
  // STC_CHANGE_RATE, STC_USAGE
];

const MSP_WIDGETS = [
  // ACTIVITY_SUMMARY, TOP_10_CUSTOMERS
];

export const getWidgetList = (isCD, isCH, isUDP, isMSP) => {
  let widgets = [...COMMON_WIDGETS];
  if (isCD) {
    widgets.push(...CD_WIDGETS);
  }
  if (isCH) {
    widgets.push(...CH_WIDGETS);
  }
  if (isUDP) {
    widgets.push(...UDP_WIDGETS);
  }
  if (isMSP) {
    widgets.push(...MSP_WIDGETS);
  }
  widgets.push(...STC_WIDGETS);
  return widgets;
};

/**
 * constant for customer entitlements Cloud hybird, cloud direct
 * @type {{CLOUD_DIRECT: string, CLOUD_HYBRID: string, DATA_STORES: string, UDP: string}}
 */
export const UsageSummaryProps = {
  CLOUD_DIRECT: "clouddirect",
  CLOUD_HYBRID: "cloudhybrid",
  DATA_STORES: "datastores",
  UDP: "udp"
};

export const data_transfer_summary = "data_transfer_summary";
export const dedupe_savings_summary = "dedupe_savings_summary";
export const dedupe_savings = "dedupe_savings";

export const recentJobsColumnData = [
  {
    identifier: "available_actions",
    show: true,
    sort: false
  },
  {
    identifier: "job_status",
    show: true,
    sort: true
  },
  {
    identifier: "source_name",
    show: true,
    sort: true
  },
  {
    identifier: "duration",
    show: true,
    sort: true
  }
];

export const columnData = [
  {
    identifier: "available_actions",
    show: true,
    sort: false
  },
  {
    identifier: "job_status",
    show: true,
    sort: true
  },
  {
    identifier: "job_name",
    show: true,
    sort: true
  },
  {
    identifier: "job_type",
    show: false
  },
  {
    identifier: "source_name",
    show: true,
    sort: true
  },
  {
    identifier: "destination_name",
    show: false
  },
  {
    identifier: "policy_name",
    show: false
  },
  {
    identifier: "organization",
    show: true,
    sort: true
  },
  {
    identifier: "start_time_ts",
    show: false,
    sort: true
  },
  {
    identifier: "end_time_ts",
    show: false
  },
  {
    identifier: "duration",
    show: true,
    sort: true
  }
];
export const job_status_active = "job_status=active";

import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { loadingBarReducer } from "react-redux-loading-bar";

import ui from "./ui";
import login from "./loginReducer";
import protectReducer from "./protectReducer";
import configureReducer from "./configureReducer";
import analyzeReducer from "./analyzeReducer";
import monitorReducer from "./monitorReducer";
import reducer from "./reducer";
import csr from "./csr";
import api from "./api";
import ccDataGrid from "./ccDataGrid";
import ccGraph from "./ccGraph";
import addSources from "./addSources";
import downloads from "./downloads";
import destinations from "./destinations";
import sites from "./sites";
import destination from "./destination";
import settings from "./settingsReducer";
import policy from "./policy";
import ivmReducer from "./ivmReducer";
import * as _ from "lodash";

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    state = undefined;
  } else if (action.type === "REMOVE_CCDATAGRID_INSTANCE") {
    const { data } = action;
    let modifiedCCDG = _.cloneDeep(state.ccDataGrid);
    if (data !== "all" && modifiedCCDG[action.data])
      delete modifiedCCDG[action.data];
    else modifiedCCDG = {};
    return {
      ...state,
      ccDataGrid: modifiedCCDG
    };
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  ui,
  reducer,
  login,
  addSources,
  protectReducer,
  configureReducer,
  analyzeReducer,
  monitorReducer,
  form,
  loadingBar: loadingBarReducer,
  csr,
  api,
  ccDataGrid,
  ccGraph,
  downloads,
  destinations,
  sites,
  destination,
  settings,
  policy,
  ivm: ivmReducer
});

export default rootReducer;

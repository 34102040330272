import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ID_PREFIX } from "configs/IdConfig";
import { DARK_MODE } from "utils/theme";

function TabPanel(props) {
  const { children, value, index, style, paperStyle, ...other } = props;
  return (
    <Paper
      elevation={0}
      style={value === index && paperStyle ? { ...paperStyle } : {}}
    >
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        style={{ height: "100%" }}
      >
        <Box
          style={{
            visibility: index === value ? "visible" : "hidden",
            ...(style?.customTabComponentStyle &&
              style.customTabComponentStyle),
            ...(style?.customHeight && style.customHeight)
          }}
          p={3}
        >
          <Typography
            className={props.customGridTabContainerClass || ""}
            component="div"
          >
            {children}
          </Typography>
        </Box>
      </div>
    </Paper>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const GridTab = ({
  detailsTab,
  tabIndex,
  vertical,
  tabOptions,
  animation,
  customStyle,
  routeLink,
  defaultTab,
  useTabIndex = false, //uses tabIndex prop so you can change with a parent button
  customStyleObject,
  paperMuiStyleAsPerIndex = false,
  override = false,
  verticalLayout,
  tabButtonClass = "",
  ...props
}) => {
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (props.tabHandleChange) {
      props.tabHandleChange(newValue);
    }
  };

  useEffect(() => {
    setValue(defaultTab);
  }, [defaultTab]);

  /**
   * to render tabs content
   * @param {array of object} data
   * @return html for tab content
   */
  const renderTabContent = data => {
    return data.map((item, index) => {
      const key = `tabPane_${index}`;
      return (
        <TabPanel
          key={key}
          value={useTabIndex ? tabIndex : value}
          index={index}
          style={customStyleObject ? customStyleObject : customStyle}
          paperStyle={paperMuiStyleAsPerIndex}
          customGridTabContainerClass={props.customGridTabContainerClass || ""}
        >
          {item.content}
        </TabPanel>
      );
    });
  };

  /**
   * to render tab items
   * @param {array of object} data
   * @return html for tabs
   */
  const renderTabItem = data => {
    return data.map((item, index) => {
      let key = `navItem_${index}`;
      return (
        <Tab
          id={`${ID_PREFIX}${item.testId}`}
          key={key}
          disabled={item.disabled}
          label={
            <>
              <b>{item.tab}</b>
              {detailsTab && item.details ? <label>{item.details}</label> : ""}
            </>
          }
          className={`${item.tabClassName || ""} ${tabButtonClass || ""}`}
          style={{
            textTransform: "none"
          }}
        />
      );
    });
  };

  let attrs = {};
  tabIndex && (attrs["activeKey"] = tabIndex);
  return (
    <div style={verticalLayout ? { height: "100%" } : {}}>
      <Paper square>
        <Tabs
          value={useTabIndex ? tabIndex : value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
          // sx={
          //   override
          //     ? {
          //         "& .MuiTab-root.Mui-selected": {
          //           color: "rgba(0, 0, 0, 0.6)"
          //         }
          //       }
          //     : {}
          // }
          className="mui-tab-header"
          // sx={{
          //   borderBottom: theme =>
          //     theme?.palette?.mode === DARK_MODE
          //       ? "1px solid rgba(255,255,255,0.1)"
          //       : "none"
          // }}
        >
          {renderTabItem(tabOptions)}
        </Tabs>
      </Paper>
      {renderTabContent(tabOptions)}
    </div>
  );
};

GridTab.defaultProps = {
  vertical: false,
  animation: true,
  detailsTab: false,
  defaultTab: 0,
  tabIndex: 0,
  handleTabClick: () => {},
  customStyleObject: {}
};

GridTab.propTypes = {
  vertical: PropTypes.bool,
  tabOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  animation: PropTypes.bool,
  detailsTab: PropTypes.bool,
  handleTabClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  defaultTab: PropTypes.number,
  customStyleObject: PropTypes.object
};

export default React.memo(GridTab);

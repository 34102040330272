import { DATAGRID_IDS } from "utils/appConstants";

const gridLoaderInitialVal = {
  [DATAGRID_IDS["sources_datagrid"]]: true,
  [DATAGRID_IDS["recoverypoint_server_grid"]]: true,
  [DATAGRID_IDS["mounted_recoverypoint_gid"]]: true,
  // [DATAGRID_IDS['render_folder_structure_volume']]: true,
  [DATAGRID_IDS["cloud_account_datagrid"]]: true,
  [DATAGRID_IDS["destination_datagrid"]]: true,
  [DATAGRID_IDS["recovered_resources_datagrid"]]: true,
  [DATAGRID_IDS["instant_vm_grid"]]: true,
  [DATAGRID_IDS["virtual_standby_grid"]]: true,
  // [DATAGRID_IDS['volumes_grid']]: true,
  [DATAGRID_IDS["browser_recovery_point_server_grid"]]: true,
  [DATAGRID_IDS["datastore_grid"]]: true,
  // [DATAGRID_IDS['remote_console_grid']]: true, //Not in use
  //  [DATAGRID_IDS['install_upgrade_recovery_point_modal_grid']]: true, //Preloaded Data
  [DATAGRID_IDS["jumb_start_grid"]]: false,
  // [DATAGRID_IDS['shadow_xafe_grid']]: true, //Not in use
  // [DATAGRID_IDS['shared_folder_grid']]: true, //Not in use
  [DATAGRID_IDS["recent_jobs_inprogress_grid"]]: true,
  [DATAGRID_IDS["user_accounts_grid"]]: true,
  // [DATAGRID_IDS['capcaity_usage_grid']]: true, //Not in use
  // [DATAGRID_IDS['port_grid']]: true,
  [DATAGRID_IDS["source_discovery_grid"]]: true,
  [DATAGRID_IDS["alerts_grid"]]: true,
  [DATAGRID_IDS["reports_grid"]]: true,
  [DATAGRID_IDS["account_details_grid"]]: true,
  [DATAGRID_IDS["backup_jobs_report_grid"]]: true,
  [DATAGRID_IDS["capcaity_usage_report_grid"]]: true,
  [DATAGRID_IDS["datatransfer_report_grid"]]: true,
  [DATAGRID_IDS["manage_schedules_grid"]]: true,
  // [DATAGRID_IDS['policy_task_report_grid']]: true, //Not in use
  [DATAGRID_IDS["recovery_point_report_grid"]]: true,
  // [DATAGRID_IDS['report_job_report_grid']]: true, //Not in use
  [DATAGRID_IDS["source_protection_report_grid"]]: true,
  [DATAGRID_IDS["jobs_grid"]]: true,
  [DATAGRID_IDS["logs_grid"]]: true,
  // [DATAGRID_IDS['recovered_resources_grid']]: true,
  // [DATAGRID_IDS['add_discover_sources_modal_grid']]: true, //Static Data
  [DATAGRID_IDS["discover_sources_active_directory_grid"]]: true,
  // [DATAGRID_IDS['discover_sources_browse_directory_grid']]: true, //Static Data
  // [DATAGRID_IDS['hyper_v_selected_table']]: true,
  // [DATAGRID_IDS['vmware_selected_table']]: true,
  // [DATAGRID_IDS['add_sources_grid']]: true, //Static Data
  // [DATAGRID_IDS['add_unc_or_nfs_path_modal_grid']]: true, //Static Data
  // [DATAGRID_IDS['add_windows_source_modal_grid']]: true, //Static Data
  // [DATAGRID_IDS['dns_update_settings_grid']]: true, //Preloaded Data
  [DATAGRID_IDS["network_adapter_seetings_grid"]]: true,
  [DATAGRID_IDS["proxies_tab_grid"]]: true,
  // [DATAGRID_IDS['vm_location_grid']]: true, //Preloaded Data
  [DATAGRID_IDS["sources_policy_grid"]]: true,
  // [DATAGRID_IDS['recover_sql_job_options_configuration_grid']]: true, //Preloaded Data
  // [DATAGRID_IDS['recover_sql_summary_grid']]: true, //Static Data
  // [DATAGRID_IDS['render_folder_structure_grid']]: true, //Static Data
  [DATAGRID_IDS["customer_account_grid"]]: true,
  // [DATAGRID_IDS['collect_diagostic_data_modal_grid']]: true,
  // [DATAGRID_IDS['recover_volume_summary_grid']]: true, //Static Data
  // [DATAGRID_IDS['render_folder_structure_windows_volume_grid']]: true,
  // [DATAGRID_IDS['hypervisor_list_grid']]: true,
  [DATAGRID_IDS["recover_fnf_linux_summary_grid"]]: true,
  // [DATAGRID_IDS['add_linux_backup_server_source_modal_grid']]: true, //Static Data
  // [DATAGRID_IDS['add_linux_source_modal_grid']]: true, //Static Data
  // [DATAGRID_IDS['recovery_fnf_summary_grid']]: true, //Static Data
  [DATAGRID_IDS["policy_grid"]]: true,
  [DATAGRID_IDS["merge_now_modal_dg"]]: true,
  [DATAGRID_IDS["cd_source_rps_grid"]]: true,
  [DATAGRID_IDS["destination_rps_grid"]]: true,
  [DATAGRID_IDS["recovery_target_grid"]]: true,
  [DATAGRID_IDS["hyperV_policy_grid"]]: true,
  [DATAGRID_IDS["vmTree_policy"]]: false,
  [DATAGRID_IDS["oracle_hosts_grid"]]: true,
  [DATAGRID_IDS["oracle_recovery_parameter_files_grid"]]: false,
  [DATAGRID_IDS["oracle_recovery_archive_logs_grid"]]: false,
  [DATAGRID_IDS["oracle_recovery_control_files_grid"]]: false,
  [DATAGRID_IDS["setBackupPasswordGrid"]]: true
};

export default {
  isImpersonateFromCsr: false,
  impersonateUserType: "",
  apiVersion: "",
  uiVersion: "",
  folderTreeData: [],
  FnfGridData: [],
  hypervisorsList: [],
  isHypervPresent: false,
  hypervisorInfo: null,
  selectedHyperVType: "",
  rpEncryptionPassword: "",
  connectStatus: "connect",
  resetDateTimeRangePicker: false,
  resetSelectNetworkForbackupCheckboxVal: false,
  site: "All Sites",
  hasGoBackNavigation: false, // this is boolean for now , if u want to store location details , then convert this to Object
  alert: "All",
  jobMonitorDetails: {},
  monitorView: "Dashboard",
  customer: "All Customers",
  primaryNavigation: "Monitor",
  primaryKey: "Monitor",
  secondaryKey: "",
  collapseSideBar: false,
  secondaryNavigation: null,
  showCurrentSearch: false,
  showAdvancedSearch: false,
  showManagedSearchModal: false,
  showSaveSearchModal: false,
  showDestinationModal: false,
  hyperLink: false,
  advanceSearchData: {},
  fromMonitorPage: false,
  filterFromMonitorWidget: false,
  filterFromMonitorWidgetData: {},
  showUserModal: false,
  showDirectUserPermissionModal: false,
  showMSPUserPermissionModal: false,
  showConfirmationModal: false,
  showRecoveryPointModal: false,
  showSaveSearchClearAllOnDefault: false,
  showUpdatePhotoModal: false,
  showInformationModal: false,
  showBrowseRecoveryPointModal: false,
  showDownloadRecoveryPointModal: false,
  gridLoader: true,
  gridLoaderById: { ...gridLoaderInitialVal },
  searchText: "",
  selectedRows: 0,
  selectedRowsFromAllPages: [],
  tabIndex: 1,
  selectAll: false,
  initialStateAdd: {},
  selection: [],
  paginationDropdownValue: 10,
  selectedSavedSearchName: "",
  selectedSavedSearchId: "",
  selectedSavedSearch: {},
  optionsForTags: {},
  theme: {},
  selectedFilterOptions: [],
  imageURL: "",
  croppedImageURL: "",
  browseURL: "",
  gridCurrentPage: 1,
  gridPageSize: 25,
  sortedColumns: [],
  toggleAlertsList: false,
  filterFrom: "dashboard",
  filterTo: "source",
  searchOption: {
    name: "name",
    selectedOption: ""
  },
  submitBtnDisabled: false,
  advanceSearchSourceGroupsData: [],
  advanceSearchPoliciesData: [],
  advanceSearchDestinationsData: [],
  fromNavigationApi: false,
  isImpersonationView: false,
  organization_type: "",
  jobLogJobData: { job_id: "" },
  advancedSearchLoaded: false,
  defaultSearchConfigured: false,
  doNotInitializeGraph: false,
  startFailback: {
    isLoading: false,
    error: null
  },
  sourceCounts: {},
  browseRemoteConsole: {
    isLoading: false,
    data: null,
    errors: null
  },
  ingramOrgFailure: [],
  menu: [
    {
      id: 1000,
      testId: "HP63ne",
      index: 1,
      name: "Monitor",
      icon: "monitor_menu",
      subMenu: [],
      key: "monitor"
    },
    {
      id: 2000,
      testId: "W95Pzg",
      index: 3,
      name: "Protect",
      icon: "protect_menu",
      key: "Protect",
      subMenu: [
        {
          id: 2100,
          name: "Sources",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: false,
          key: "sources",
          countKey: "sources",
          includeMSPMenu: false
        },
        {
          id: 2101,
          name: "Machines",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          parentKey: "sources",
          key: "physical_machines",
          countKey: "machine",
          includeMSPMenu: false
        },
        {
          id: 2102,
          name: "Agentless VMs",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          parentKey: "sources",
          key: "virtual_machines",
          countKey: "agentless_vm",
          includeMSPMenu: false
        },
        {
          id: 2103,
          name: "UNC/NFS Paths",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          parentKey: "sources",
          key: "unc_nfs_paths",
          countKey: "shared_folder",
          includeMSPMenu: false
        },

        //Removed other sources (Office 365, Shared Folders, Sharepoint) as per email from Brian.
        //Subject: Open Issues 20180426
        {
          id: 2200,
          name: "Disaster Recovery",
          clickable: false,
          displayCount: false,
          amount: 0,
          isFilterType: false,
          key: "recovered_resources",
          countKey: "recovered_resources",
          includeMSPMenu: false
        },
        {
          id: 2202,
          name: "DRaaS",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          key: "recovered_vms",
          parentKey: "recovered_resources",
          countKey: "recovered_vms",
          includeMSPMenu: false
        },
        {
          id: 2203,
          name: "Instant VMs",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          key: "instant_vms_rr",
          parentKey: "recovered_resources",
          countKey: "ivm",
          includeMSPMenu: false
        },
        {
          id: 2204,
          name: "Mounted Recovery Points",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          key: "mounted_recovery_points",
          parentKey: "recovered_resources",
          countKey: "mounted_recovery_points",
          includeMSPMenu: false
        },
        {
          id: 2205,
          name: "Virtual Standby",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          key: "virtual_standbys",
          parentKey: "recovered_resources",
          countKey: "virtual_standbys",
          includeMSPMenu: false
        },
        {
          id: 2300,
          name: "Destinations",
          displayCount: false,
          clickable: false,
          amount: 0,
          isFilterType: false,
          key: "destinations",
          countKey: "destinations",
          includeMSPMenu: false
        },
        {
          id: 2301,
          name: "ShadowXafe",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          key: "shadow_xafe",
          parentKey: "destinations",
          countKey: "shadow_xafe",
          includeMSPMenu: false
        },
        {
          id: 2303,
          name: "Arcserve Cloud",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          key: "arcserve_cloud",
          parentKey: "destinations",
          countKey: "arcserve_cloud",
          includeMSPMenu: false
        },
        // {
        //   id: 2301,
        //   name: "Cloud Direct Volumes",
        //   clickable: true,
        //   displayCount: true,
        //   amount: 0,
        //   isFilterType: true,
        //   key: "cloud_volumes",
        //   parentKey: "destinations",
        //   countKey: "cloud_direct_volume",
        //   includeMSPMenu: false
        // },
        // {
        //   id: 2302,
        //   name: "Cloud Hybrid Stores",
        //   clickable: true,
        //   displayCount: true,
        //   amount: 0,
        //   isFilterType: true,
        //   key: "cloud_stores",
        //   parentKey: "destinations",
        //   countKey: "cloud_hybrid_store",
        //   includeMSPMenu: false
        // },
        {
          id: 2303,
          name: "Recovery Point Servers",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          key: "recovery_point_servers",
          parentKey: "destinations",
          countKey: "recovery_point_servers",
          includeMSPMenu: false
        },
        {
          id: 2304,
          name: "Data Stores",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          key: "data_stores",
          parentKey: "destinations",
          countKey: "data_stores",
          includeMSPMenu: false
        },
        {
          id: 2305,
          name: "Cloud Accounts",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          key: "cloud_account",
          parentKey: "destinations",
          countKey: "cloud_account",
          includeMSPMenu: false
        },
        // {
        //   id: 2306,
        //   name: "Remote Console",
        //   clickable: true,
        //   displayCount: true,
        //   amount: 0,
        //   isFilterType: true,
        //   key: "remote_console",
        //   parentKey: "destinations",
        //   countKey: "remote_console",
        //   includeMSPMenu: false
        // },
        // {
        //   id: 2306,
        //   name: "Shared Folders",
        //   clickable: true,
        //   displayCount: true,
        //   amount: 0,
        //   isFilterType: true,
        //   key: "shared_folder",
        //   parentKey: "destinations",
        //   countKey: "shared_folder",
        //   includeMSPMenu: false
        // },
        {
          id: 2400,
          name: "Policies",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: false,
          key: "policies",
          countKey: "policies",
          includeMSPMenu: false
        }
      ]
    },
    {
      id: 3000,
      testId: "Mo74Fx",
      index: 2,
      name: "Analyze",
      icon: "analyze_menu",
      key: "Analyze",
      subMenu: [
        {
          id: 3100,
          name: "Jobs",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: false,
          key: "jobs",
          countKey: "jobs",
          includeMSPMenu: true
        },
        {
          id: 3200,
          name: "Log",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: false,
          key: "log",
          countKey: "log",
          includeMSPMenu: true
        },
        {
          id: 3400,
          name: "Alerts",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: false,
          key: "alerts",
          countKey: "alerts",
          includeMSPMenu: true
        },
        {
          id: 3300,
          name: "Reports",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: false,
          key: "reports",
          countKey: "reports",
          includeMSPMenu: true
        },
        {
          id: 3301,
          name: "Backup Jobs",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: true,
          parentKey: "reports",
          key: "backup_jobs",
          includeMSPMenu: true
        },
        // {
        //   id: 3302,
        //   name: "Policy Tasks",
        //   clickable: true,
        //   displayCount: false,
        //   amount: 0,
        //   isFilterType: true,
        //   parentKey: "reports",
        //   key: "policy_tasks",
        //   includeMSPMenu: true
        // },
        // {
        //   id: 3303,
        //   name: "Recovery Jobs",
        //   clickable: true,
        //   displayCount: false,
        //   amount: 0,
        //   isFilterType: true,
        //   parentKey: "reports",
        //   key: "restore_jobs",
        //   includeMSPMenu: true
        // },
        {
          id: 3304,
          name: "Data Transfer",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: true,
          parentKey: "reports",
          key: "data_transfer",
          includeMSPMenu: true
        },
        // {
        //   id: 3305,
        //   name: "Capacity Usage",
        //   clickable: true,
        //   displayCount: false,
        //   amount: 0,
        //   isFilterType: true,
        //   parentKey: "reports",
        //   key: "capacity_usage",
        //   includeMSPMenu: true
        // },
        {
          id: 3305,
          name: "Stored Data",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: true,
          parentKey: "reports",
          key: "capacity_usage",
          includeMSPMenu: true
        },
        {
          id: 3306,
          name: "Account Details",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: true,
          parentKey: "reports",
          key: "account_details",
          includeMSPMenu: true
        },
        {
          id: 3307,
          name: "Recovery Point Report",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: true,
          parentKey: "reports",
          key: "recovery_point_report",
          includeMSPMenu: true
        },
        {
          id: 3310,
          name: "Source Protection Report",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: true,
          parentKey: "reports",
          key: "source_protection",
          includeMSPMenu: true
        },
        {
          id: 3311,
          name: "Manage Report Schedules",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: true,
          parentKey: "reports",
          key: "manage_schedules",
          includeMSPMenu: true
        }
      ]
    },
    {
      id: 5000,
      testId: "ujpnJL",
      index: 5,
      name: "Configure",
      icon: "configure_menu",
      key: "Configure",
      subMenu: [
        {
          id: 5100,
          name: "Infrastructure",
          key: "infrastructure",
          isFilterType: false,
          clickable: false,
          displayCount: false,
          countKey: "infrastructure",
          includeMSPMenu: true
        },
        {
          id: 5102,
          name: "Hypervisors",
          key: "hypervisors",
          amount: 0,
          isFilterType: true,
          clickable: true,
          displayCount: true,
          parentKey: "infrastructure",
          countKey: "hypervisors",
          includeMSPMenu: false
        },
        {
          id: 5103,
          name: "Sites",
          clickable: true,
          displayCount: true,
          amount: 0,
          isFilterType: true,
          key: "sites",
          countKey: "sites",
          parentKey: "infrastructure",
          includeMSPMenu: false
        },
        {
          id: 5105,
          name: "Storage Arrays",
          key: "storage_arrays",
          amount: 0,
          isFilterType: true,
          clickable: true,
          displayCount: true,
          parentKey: "infrastructure",
          countKey: "storage_arrays",
          includeMSPMenu: false
        },
        {
          id: 5104,
          name: "Network Configuration",
          key: "network_configuration",
          amount: 1,
          isFilterType: true,
          clickable: true,
          displayCount: false,
          parentKey: "infrastructure",
          countKey: "network_configuration",
          includeMSPMenu: false
        },
        {
          id: 5105,
          name: "Proxies",
          key: "proxies",
          amount: 0,
          isFilterType: true,
          clickable: true,
          displayCount: true,
          parentKey: "infrastructure",
          countKey: "proxies",
          includeMSPMenu: true
        },
        {
          id: 5106,
          name: "Oracle Hosts",
          key: "oracle_hosts",
          amount: 0,
          isFilterType: true,
          clickable: true,
          displayCount: true,
          parentKey: "infrastructure",
          countKey: "oracle_hosts",
          includeMSPMenu: false
        },
        {
          id: 5300,
          name: "Source Groups",
          key: "source_groups",
          amount: 0,
          isFilterType: false,
          clickable: true,
          displayCount: true,
          includeMSPMenu: false,
          countKey: "source_groups"
        },
        {
          id: 5400,
          name: "Access Control",
          key: "access_control",
          isFilterType: false,
          clickable: false,
          displayCount: false,
          countKey: "access_control",
          includeMSPMenu: true
        },
        {
          id: 5401,
          name: "User Accounts",
          key: "user_accounts",
          amount: 0,
          isFilterType: true,
          clickable: true,
          displayCount: true,
          parentKey: "access_control",
          countKey: "user_accounts",
          includeMSPMenu: true
        },
        {
          id: 5402,
          name: "Roles",
          key: "user_roles",
          amount: 0,
          isFilterType: true,
          clickable: true,
          parentKey: "access_control",
          countKey: "user_roles",
          includeMSPMenu: true
        },
        {
          id: 5500,
          name: "Entitlements",
          key: "license_subscriptions",
          parentKey: "configure",
          isFilterType: false,
          clickable: true,
          displayCount: false,
          countKey: "license_subscriptions",
          includeMSPMenu: true
        },
        {
          id: 5600,
          name: "Branding",
          key: "branding",
          isFilterType: false,
          clickable: true,
          displayCount: false,
          countKey: "branding",
          includeMSPMenu: true
        },
        {
          id: 5700,
          name: "Settings",
          key: "settings",
          isFilterType: false,
          clickable: false,
          displayCount: false,
          includeMSPMenu: false
        },
        {
          id: 5701,
          name: "Source Discovery Configuration",
          clickable: true,
          displayCount: false,
          isFilterType: true,
          parentKey: "settings",
          key: "source_discovery",
          includeMSPMenu: false
        },
        {
          id: 5702,
          name: "Default Deployment Settings",
          clickable: true,
          displayCount: false,
          isFilterType: true,
          parentKey: "settings",
          key: "default_deployment",
          includeMSPMenu: false
        }
      ]
    },
    {
      id: 6000,
      index: 6,
      testId: "HP99ne",
      name: "Support",
      icon: "support_menu",
      subMenu: [],
      key: "Support"
    },
    {
      id: 7000,
      index: 7,
      testId: "W99Qzg",
      name: "Profile",
      icon: "profile_menu",
      subMenu: [],
      key: "Profile"
    }
  ],
  csr_menu: [
    {
      id: 10000,
      index: 1,
      name: "Organization Search",
      icon: "org_search_menu",
      key: "org_search",
      iconClass: "fa fa-sitemap",
      routeLink: "/csr/org-search",
      subMenu: []
    },
    {
      id: 20000,
      index: 2,
      name: "User Search",
      icon: "user_search_menu",
      key: "user_search",
      iconClass: "fa fa-users",
      routeLink: "/csr/user-search",
      subMenu: []
    },
    {
      id: 21000,
      index: 6,
      name: "Source Search",
      icon: "source_search_menu",
      key: "source_search",
      iconClass: "fa fa-desktop",
      routeLink: "/csr/source-search",
      subMenu: []
    },
    // {
    //   id: 30000,
    //   index: 3,
    //   name: "Cloud Hybrid",
    //   icon: "cloud_hybrid_menu",
    //   key: "cloud_hybrid",
    //   iconClass: "fa fa-cloud",
    //   routeLink: "/csr/cloud-hybrid",
    //   subMenu: [
    //     {
    //       id: 30001,
    //       name: "Recovery Point Servers",
    //       clickable: true,
    //       displayCount: false,
    //       amount: 0,
    //       isFilterType: false,
    //       key: "recovery-point-servers",
    //       countKey: "recovery-point-servers",
    //       includeMSPMenu: true
    //     },
    //     {
    //       id: 30002,
    //       name: "Linux Backup Servers",
    //       clickable: true,
    //       displayCount: false,
    //       amount: 0,
    //       isFilterType: false,
    //       key: "linux-backup-servers",
    //       countKey: "linux-backup-servers",
    //       includeMSPMenu: true
    //     }
    //   ]
    // },
    {
      id: 40000,
      index: 4,
      name: "Audit Trail",
      icon: "audit_trail_menu",
      key: "audit_trail",
      iconClass: "fa fa-align-left",
      routeLink: "/csr/audit-trail",
      subMenu: []
    },
    {
      id: 50000,
      index: 5,
      name: "Reports",
      icon: "reports_menu",
      key: "reports",
      iconClass: "fa fa-file",
      routeLink: "/csr/bi-reporting",
      subMenu: [
        {
          id: 50001,
          name: "Cloud Direct Trial",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: false,
          key: "cd_trial",
          countKey: "cloud_direct_trial",
          includeMSPMenu: true
        },
        {
          id: 50002,
          name: "Cloud Direct Trial Exemptions",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: false,
          key: "cd_exemption",
          countKey: "cloud_direct_trial_exemptions",
          includeMSPMenu: true
        },
        {
          id: 50003,
          name: "Cloud Direct Near Capacity",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: false,
          key: "cd_near_capacity",
          countKey: "cloud_direct_near_capacity",
          includeMSPMenu: true
        },
        {
          id: 50004,
          name: "Cloud Direct Over Capacity",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: false,
          key: "cd_over_capacity",
          countKey: "cloud_direct_over_capacity",
          includeMSPMenu: true
        },
        {
          id: 50005,
          name: "Cloud Direct Expiration",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: false,
          key: "cd_expiration",
          countKey: "cloud_direct_expiration",
          includeMSPMenu: true
        },
        // {
        //   id: 50006,
        //   name: "Cloud Hybrid Trial",
        //   clickable: true,
        //   displayCount: false,
        //   amount: 0,
        //   isFilterType: false,
        //   key: "ch_trial",
        //   countKey: "cloud_hybrid_trial",
        //   includeMSPMenu: true
        // },
        // {
        //   id: 50007,
        //   name: "Cloud Hybrid Trial Exemptions",
        //   clickable: true,
        //   displayCount: false,
        //   amount: 0,
        //   isFilterType: false,
        //   key: "ch_exemption",
        //   countKey: "cloud_hybrid_trial_exemptions",
        //   includeMSPMenu: true
        // },
        // {
        //   id: 50008,
        //   name: "Cloud Hybrid Near Capacity",
        //   clickable: true,
        //   displayCount: false,
        //   amount: 0,
        //   isFilterType: false,
        //   key: "ch_near_capacity",
        //   countKey: "cloud_hybrid_near_capacity",
        //   includeMSPMenu: true
        // },
        // {
        //   id: 50009,
        //   name: "Cloud Hybrid Over Capacity",
        //   clickable: true,
        //   displayCount: false,
        //   amount: 0,
        //   isFilterType: false,
        //   key: "ch_over_capacity",
        //   countKey: "cloud_hybrid_over_capacity",
        //   includeMSPMenu: true
        // },
        // {
        //   id: 50010,
        //   name: "Cloud Hybrid Expiration",
        //   clickable: true,
        //   displayCount: false,
        //   amount: 0,
        //   isFilterType: false,
        //   key: "ch_expiration",
        //   countKey: "cloud_hybrid_expiration",
        //   includeMSPMenu: true
        // },
        {
          id: 50011,
          name: "UDP Trial Report",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: false,
          key: "udp_trial",
          countKey: "udp_trial_report",
          includeMSPMenu: true
        },
        {
          id: 50012,
          name: "UDP Customer Report",
          clickable: true,
          displayCount: false,
          amount: 0,
          isFilterType: false,
          key: "customer_report",
          countKey: "udp_customer_report",
          includeMSPMenu: true
        }
      ]
    }
  ]
};

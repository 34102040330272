import {
  SHOW_DIALOG,
  HIDE_DIALOG,
  INITIALIZE_APP
} from "../../actions/actionTypes";

const initialState = {
  addSourceFromHypervisor: false,
  addLinuxBackupServer: false,
  connectSharedFolderDestination: false,
  discoverSourcesFromAd: false,
  addLinux: false,
  DISCOVER_SOURCES_FROM_AD_EDIT: false,
  addsite: false,
  addRecoveryPointServers: false,
  modifySite: false,
  deleteSite: false,
  cancelJob: false,
  showRegBaseCode: false,
  addRemoteConsole: false,
  RESET_ADD_SOURCE_FROM_HYPER: false,
  IMPORT_DATA_STORE: false,
  IMPORT_DATA_STORE_OVERRIDE: false,
  addCloudAccount: false,
  addDataStore: false,
  connectDataStore: false,
  dataStorePlanRequirements: false,
  INSTALL_UPGRADE_RPS: false,
  INSTALL_UPGRADE_SOURCE: false,
  UPGRADE_CLOUD_DIRECT: false,
  DELETE_RPS: false,
  RPS_JUMBSTART: false,
  updateSharedFolder: false,
  connectSharedFolder: false,
  DELETE_DATA_STORE: false,
  PURGE_DATA_STORE: false,
  PURGE_DATA_STORE_BULK: false,
  DELETE_DATA_STORE_BULK: false,
  STOP_DATA_STORE: false,
  STOP_DATA_STORE_BULK: false,
  UPDATE_RPS: false,
  BROWSE_REMOTE_CONSOLE: false,
  DATA_STORE_FOLDER_BROWSE: false,
  RP_MERGE: false,
  MODIFY_DATA_STORE_WARNING: false,
  connectUncNfsPath: false,
  RESTORE_RECOVERY_POINT: false,
  RESTORE_ORACLE_DB: false,
  RESTORE_ORACLE_TABLES: false,
  UPDATE_SOURCE: false,
  CLOUD_DIRECT_TRIAL: false,
  modifyCustomerName: false,
  SHARE_POLICY_ACTION: false,
  MOUNT_RECOVERY_POINT: false,
  MOUNT_RECOVERY_POINT_LINUX: false,
  DISCOVER_FROM_AD: false,
  DISCOVER_FROM_HYPERV: false,
  DISCOVER_FROM_VMWARE: false,
  DELETE_AD_SOURCE: false,
  DISMOUNT_VOLUME_CONFIRM: false,
  DISMOUNT_RPS_CONFIRM: false,
  UPDATE_HYPERVISORS: false,
  DELETE_SOURCE_DISCOVERY: false,
  ADD_WINDOWS_SOURCES: false,
  ADD_UNC_NFS_SOURCES: false,
  UPDATE_PROXY: false,
  AR_ADD_PROXY: false,
  ADD_RPA: false,
  IVM_CONFIRM_DIALOG: false,
  IVM_ERROR_DIALOG: false,
  IVM_BOOT_DIALOG: false,
  ADD_VMWARE: false,
  ADD_HYPERV: false,
  IVM_WIZARD: false,
  AR_ADD_LINUX_PROXY: false,
  AR_ADD_LINUX_POLICY_PROXY: false,
  ADD_DESTINATION_HOST: false,
  ADD_DESTINATION_HOST_LINUX: false,
  RECOVER_VOLUME_CONFIRM: false,
  ADD_STORAGE_ARRAY: false,
  MODIFY_STORAGE_ARRAY: false,
  IVM_NW_ADAPTERS: false,
  IVM_DNS_SETTINGS: false,
  IVM_UPDATE_DNS: false,
  CUSTOMIZE_DNS_UPDATE: false,
  STANDBY_VM_WIZARD: false,
  VIRTAUL_STANDBY_VM_RESUME_WIZARD: false,
  INSTANT_VMS_RR: false,
  VALIDATE_VM_DIALOG: false,
  VALIDATE_LINUX_VM_DIALOG: false,
  VERIFY_PASSWORD_DIALOG: false,
  STANDBY_VM_NETWORK_CONFIGURATION: false,
  SOURCE_VM_VALIDATE_DIALOG: false,
  FORCE_DELETE_DATA_STORE: false,
  ADD_SOURCE_FROM_HYPERVISOR_POLICY: false,
  TWO_FACTOR_CONFIG: false,
  CONFIRMATION_DIALOG: false,
  STANDBY_VM_SHUTDOWN_CONFIRMATION: false,
  SHOW_SQL_ADDITIONAL_DATABASE_OPTIONS_MODAL: false,
  CANCEL_RECOVERY: false,
  RECOVERY_FREE_SPACE_WARNING: false,
  CANCEL_IVM: false,
  SOURCE_CONFIGURE: false,
  WARNING_SOURCE_CONFIG: false,
  COLLECT_DIAGNOSTIC_DATA: false,
  CONNECT_TO_WIN: false,
  RECOVER_VOLUME_FULL_BMR: false,
  PIT_ERROR: false,
  RUN_ASSURED_RECOVERY_TEST: false,
  ADD_ORACLE_DB: false,
  CONFIRM_ORACLE_RECOVERY: false,
  SET_BACKUP_PASSWORDS_MODAL: false,
  ADD_BACKUP_PASSWORD_MODAL: false,
  EDIT_BACKUP_PASSWORD_MODAL: false,
  BACKUP_PASSWORD_CONFIRMATION_MODAL: false
};

const dialogs = (state = initialState, { type, name }) => {
  switch (type) {
    case SHOW_DIALOG:
      return { ...state, [name]: true };
    case HIDE_DIALOG:
      return { ...state, [name]: false };
    case INITIALIZE_APP:
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default dialogs;

import { intersection } from "lodash";
import { createSelector } from "reselect";
import {
  CCDATAGRID_ID_SEARCH_FIELD,
  SPOG_GRID_PROPERTIES
} from "utils/appConstants";
import { getFormValues } from "redux-form";
import { recoveredResourcesConstants } from "components/protect/recoveredResources/RecoveredResourcesConstant";
import SourceConstants from "../../../components/protect/source/SourceConstants.js";

export const getConfig = ccDataGridId => state => {
  if (state.ccDataGrid[ccDataGridId] === undefined) {
    return {};
  }

  return state.ccDataGrid[ccDataGridId].config;
};

export const isLoading = ccDataGridId => state => {
  if (state.ccDataGrid[ccDataGridId] === undefined) {
    return false;
  }

  return state.ccDataGrid[ccDataGridId].loading > 0;
};

// state stuff
const getUi = (ccDataGridId, state) => {
  if (state.ccDataGrid[ccDataGridId] === undefined) {
    return {};
  }

  return state.ccDataGrid[ccDataGridId].ui;
};

export const getSorted = ccDataGridId => state => {
  const { sorted } = getUi(ccDataGridId, state);
  return sorted ? sorted : [];
};

export const getPage = ccDataGridId => state => {
  const { page } = getUi(ccDataGridId, state);
  return page ? page : 1;
};

export const getPages = ccDataGridId => state => {
  const { pages } = getUi(ccDataGridId, state);
  return pages ? pages : 1;
};

export const getPageSize = ccDataGridId => state => {
  const { pageSize } = getUi(ccDataGridId, state);
  return pageSize ? pageSize : 25;
};

export const getTotalSize = ccDataGridId => state => {
  const { totalSize } = getUi(ccDataGridId, state);
  return totalSize ? totalSize : 25;
};

export const getColumns = ccDataGridId => state => {
  const { columns } = getUi(ccDataGridId, state);
  return columns ? columns : null;
};

export const getChecked = ccDataGridId => state => {
  const { checked } = getUi(ccDataGridId, state);
  return checked ? checked : {};
};

export const getCheckedItems = ccDataGridId => state => {
  return Object.keys(getChecked(ccDataGridId)(state));
};

export const getCheckedEntities = ccDataGridId => state => {
  if (state.ccDataGrid[ccDataGridId] === undefined) {
    return [];
  }

  const { byId } = state.ccDataGrid[ccDataGridId].entities;
  const checkedItems = getCheckedItems(ccDataGridId)(state);
  return checkedItems.map(id => byId[id]);
};

export const getAdvancedSearch = ccDataGridId => state => {
  const { advancedSearch } = getUi(ccDataGridId, state);
  return advancedSearch ? advancedSearch : {};
};

export const getAdvancedSearchOptionsSelected = ccDataGridId => state => {
  const { advancedSearchOptionsSelected = false } = getUi(ccDataGridId, state);
  return advancedSearchOptionsSelected ? advancedSearchOptionsSelected : false;
};

export const getAdvancedSearchOptions = ccDataGridId => state => {
  const { advancedSearchOptions } = getUi(ccDataGridId, state);
  return advancedSearchOptions ? advancedSearchOptions : [];
};

export const getAdvancedSearchOptionsUnChanged = ccDataGridId => state => {
  const { advancedSearchOptionsUnChanged } = getUi(ccDataGridId, state);
  return advancedSearchOptionsUnChanged ? advancedSearchOptionsUnChanged : [];
};

export const getAdvancedSearchCurrentSearchCriteria = ccDataGridId => state => {
  const { advancedSearch } = getUi(ccDataGridId, state);
  const searchName = CCDATAGRID_ID_SEARCH_FIELD[ccDataGridId];
  return advancedSearch
    ? { name: searchName, selectedOption: advancedSearch[searchName] }
    : {};
};

export const isAdvancedSearchShown = ccDataGridId => state => {
  const { showAdvancedSearchForm } = getUi(ccDataGridId, state);
  return showAdvancedSearchForm ? showAdvancedSearchForm : false;
};

export const getCCDataGridShowCurrentSearch = ccDataGridId => state => {
  const { showCCDataGridCurrentSearch } = getUi(ccDataGridId, state);
  return showCCDataGridCurrentSearch;
};

export const getCCDataGridAdvancedSearchSelected = ccDataGridId => state => {
  const { advancedSearchOptionsSelected = false } = getUi(ccDataGridId, state);
  return advancedSearchOptionsSelected;
};

export const getCCDataGridUserSearchData = ccDataGridId => state => {
  const { userSearchData = [] } = getUi(ccDataGridId, state);
  return userSearchData;
};

export const getCCDataGridSystemSearchData = ccDataGridId => state => {
  const { systemSearchData = [] } = getUi(ccDataGridId, state);
  return systemSearchData;
};

export const getCCDataGridSelectedSavedSearch = ccDataGridId => state => {
  const { selectedSavedSearch = {} } = getUi(ccDataGridId, state);
  return selectedSavedSearch;
};

export const getCCDataGridSelectedSavedSearchId = ccDataGridId => state => {
  const { selectedSavedSearch = {} } = getUi(ccDataGridId, state);
  const { filter_id = "" } = selectedSavedSearch;
  return filter_id;
};

export const getCCDataGridSelectedSavedSearchName = ccDataGridId => state => {
  const { selectedSavedSearch = {} } = getUi(ccDataGridId, state);
  const { filter_name = "" } = selectedSavedSearch;
  return filter_name;
};

export const getCCDataGridShowSaveSearchModal = ccDataGridId => state => {
  const { showSaveSearchModal = false } = getUi(ccDataGridId, state);
  return showSaveSearchModal;
};

export const getCCDataGridSaveSearchText = ccDataGridId => state => {
  const { saveSearchText = "" } = getUi(ccDataGridId, state);
  return saveSearchText;
};

export const getConsiderAdvancedSearchOptions = ccDataGridId => state => {
  const { considerAdvancedSearchOptions = true } = getUi(ccDataGridId, state);
  return considerAdvancedSearchOptions;
};

export const getCCDataGridOptionToTags = ccDataGridId => state => {
  const { optionsForTags = {} } = getUi(ccDataGridId, state);
  return optionsForTags;
};

export const getCCDataGridManageSaveSearchOptions = ccDataGridId => state => {
  const { manageSaveSearchOptions = [] } = getUi(ccDataGridId, state);
  return manageSaveSearchOptions;
};

export const getCCDataGridManageSelectedSavedSearch = ccDataGridId => state => {
  const { manageSelectedSavedSearch = {} } = getUi(ccDataGridId, state);
  return manageSelectedSavedSearch;
};

// WIP
export const getAvailableBulkActions = ccDataGridId => state => {
  if (state.ccDataGrid[ccDataGridId] === undefined) {
    return [];
  }

  let BULK_ACTION_UNSUPPORTED = [
    "convert_to_agentless",
    "deploy_policy",
    "deploy",
    "test_connection",
    "install_agent",
    "refresh",
    "resettwofactor",
    recoveredResourcesConstants.CONTEXTUAL_ACTION.FAILBACK_START,
    recoveredResourcesConstants.CONTEXTUAL_ACTION.FAILBACK_STOP,
    recoveredResourcesConstants.CONTEXTUAL_ACTION.FAILBACK_FINAL_STEP,
    SPOG_GRID_PROPERTIES.BULK_ACTION.ASSIGN_POLICY,
    SPOG_GRID_PROPERTIES.BULK_ACTION.LAUNCH_UDP_AGENT_UI,
    SPOG_GRID_PROPERTIES.BULK_ACTION.UPDATE,
    SPOG_GRID_PROPERTIES.BULK_ACTION.SHARE,
    SPOG_GRID_PROPERTIES.BULK_ACTION.EDIT_MANAGE_SCHEDULE_REPORT,
    "instantvm",
    SPOG_GRID_PROPERTIES.BULK_ACTION.CREATE_VM_INSTANT, //hiding vsb_pause,standbyvm, heart_beat_pause, standbyvmnetworkconfiguration for alpha
    SourceConstants.CONTEXTUAL_ACTION.HEARTBEAT_PAUSE,
    SourceConstants.CONTEXTUAL_ACTION.VIRTUAL_STANDBY_RESUME,
    SourceConstants.CONTEXTUAL_ACTION.VIRTUAL_STANDBY_PAUSE,
    SourceConstants.CONTEXTUAL_ACTION.STANDBY_VM,
    SourceConstants.CONTEXTUAL_ACTION.STANDBY_VM_NETWORK_CONFIGURATION,
    SourceConstants.CONTEXTUAL_ACTION.START_AR_TEST,
    "startrecovery",
    "assigngroup",
    "edit",
    "view_recovery_point",
    "remote_console",
    "modify",
    "edit_report",
    "create_now",
    "viewlogs",
    "assignaccount",
    "remoteconsole",
    "remote_console",
    "target_vm_configuration"
  ];

  const config = getConfig(ccDataGridId)(state);
  const checked = getChecked(ccDataGridId)(state);
  const { byId } = state.ccDataGrid[ccDataGridId].entities;
  const { disableBulkActions = BULK_ACTION_UNSUPPORTED } = config;
  return intersection(
    ...Object.keys(checked).map(
      id => byId[id] && (byId[id].available_actions || byId[id].allowed_actions)
    )
  )
    .filter(actionKey => !disableBulkActions.includes(actionKey))
    .sort();
};

// fetch stuff
export const getEntities = ccDataGridId => state => {
  if (state.ccDataGrid[ccDataGridId] === undefined) {
    return [];
  }

  const { allIds, byId } = state.ccDataGrid[ccDataGridId].entities;

  return allIds.map(id => byId[id]);
};
export const getCCDGEntities = createSelector(
  (state, ccDataGridId) =>
    state.ccDataGrid &&
    state.ccDataGrid[ccDataGridId] &&
    state.ccDataGrid[ccDataGridId].entities,
  entities => {
    //console.log("new entitie", entities);
    if (!entities) {
      return [];
    }

    const { allIds, byId } = entities;

    return allIds.map(id => byId[id]);
  }
);

export const getApiDetails = ccDataGridId => state => {
  if (state.ccDataGrid[ccDataGridId] === undefined) {
    return [];
  }

  const api = state.ccDataGrid[ccDataGridId].api;

  return api;
};

export const getFormText = ccDataGridId => state => {
  if (state.ccDataGrid[ccDataGridId] === undefined) {
    return {};
  }
  const formValues = getFormValues(`ccDataGrid_${ccDataGridId}`)(state);
  let searchField = CCDATAGRID_ID_SEARCH_FIELD[ccDataGridId];

  return formValues;
};

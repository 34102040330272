import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Form, Field, reduxForm } from "redux-form";
import {
  clearAllSearchTags,
  showHideSaveSearchModal,
  updateCurrentSavedSearchName,
  editUpdateManageSavedSearch,
  showHideSaveSearchNClearAll
} from "../../../state/actions/actions";
import SpogModal from "../modal-mui";
import Button from "components/common/button";
import FormattedMessage from "../formatted-message";
import InputField from "components/common/form/InputFieldMUI";
import { CustomError } from "../error";

const required = function(value) {
  let val = value ? value.trim() : value;
  if (val && val.length >= 3) {
    return undefined;
  } else {
    return CustomError("advanced.search.input.error");
  }
};

const SaveSearchModal = ({
  handleSubmit,
  submitting,
  pristine,
  invalid,
  show,
  filterType,
  dataGridID
}) => {
  const dispatch = useDispatch();
  const successSaveSearch = useSelector(
    state =>
      state.reducer.apiResponse && state.reducer.apiResponse.successSaveSearch
  );

  useEffect(() => {
    if (successSaveSearch) {
      //  dispatch(clearAllSearchTags());
      // dispatch(showHideSaveSearchNClearAll(true));
    }
  }, [successSaveSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * function to handle events related to closing of modal
   */
  const handleCloseModalEvent = () => {
    return dispatch(showHideSaveSearchModal(false));
  };

  /**
   * Function to save current search params
   * @argument values
   */
  const submitSaveSearchAction = values => {
    if (!values.saveSearchName) {
      return false;
    }
    dispatch(updateCurrentSavedSearchName(values.saveSearchName));
    dispatch(editUpdateManageSavedSearch(filterType, true, dataGridID));
    dispatch(showHideSaveSearchModal(false));
  };

  return (
    <SpogModal
      show={show}
      size="small"
      handleCloseModalEvent={handleCloseModalEvent}
      onClose={handleCloseModalEvent}
      title={<FormattedMessage id="save.search.save" defaultMessage="Save" />}
    >
      <Form
        onSubmit={handleSubmit(submitSaveSearchAction)}
        style={{ paddingBottom: 20 }}
      >
        <div className="mui-modal-content">
          <h6
            style={{
              textAlign: "left",
              width: "100%",
              marginTop: 10,
              fontSize: 14
            }}
          >
            <FormattedMessage
              id="save.search.heading"
              defaultMessage="save.search.heading"
            />
          </h6>
          <hr />
          <div className="save-search-input-wrapper d-flex flex-column col-md-5">
            <label>
              <FormattedMessage
                id="save.search.name"
                defaultMessage="save.search.name"
              />
            </label>
            <Field
              name="saveSearchName"
              className="content-item-option"
              component={InputField}
              hideFloatingLabel
              style={{ width: 300 }}
              maxLength="60"
              type="text"
              validate={[required]}
            />
          </div>
        </div>
        <div className="mui-modal-footer">
          <Button
            color="secondary"
            content={
              <FormattedMessage
                id="save.search.cancel"
                defaultMessage="save.search.cancel"
              />
            }
            onClick={handleCloseModalEvent}
            type="button"
            style={{ margin: "5px" }}
            variant="contained"
          />

          <Button
            color="primary"
            content={
              <FormattedMessage
                id="save.search.save"
                defaultMessage="Save Search"
              />
            }
            type="submit"
            disabled={pristine || submitting || invalid}
            style={{ margin: "5px" }}
            variant="contained"
          />
        </div>
      </Form>
    </SpogModal>
  );
};

SaveSearchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired
};

const SaveSearchModalForm = reduxForm({
  form: "advancedSearch",
  fields: ["keyword", "filterName"]
})(SaveSearchModal);

export default SaveSearchModalForm;

import { combineReducers } from "redux";
import {
  POLICY_VSB_FETCH_VMWARES_CONNECT_SUCCESS,
  POLICY_VSB_FETCH_VMWARES_NODES_SUCCESS,
  POLICY_VSB_FETCH_VMWARES_SUCCESS,
  GET_HYPERV_CONFIGURATION_SUCCESS,
  GET_VMWARE_CONFIGURATION_SUCCESS,
  CLEAR_VSB_HYPERVISORS,
  GET_VSB_SUCCESS_HYPERVISORS,
  GET_VSB_FAILED_HYPERVISORS,
  SET_HYPERV_CONNECT_FORM,
  SET_VMWARE_CONNECT_FORM,
  POLICY_VSB_FETCH_VMWARES_MONITOR_CONNECT_SUCCESS,
  CLEAR_VSB_POLICY_DATA,
  CLEAR_ESX_NODES,
  CLEAR_VMWARE_CONFIGURATION,
  STORE_CLOUD_CONFIGURATIONS,
  STORE_CLOUD_SUBNET,
  GET_LOAD_CLOUD_ACCOUNT_LIST_BY_TYPE_SUCCESS,
  GET_REGIONS_FOR_CLOUD_ACCOUNT_SUCCESS,
  GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT_SUCCESS,
  CLEAR_CLOUD_ACCOUNTS_BY_TYPE,
  CLEAR_CLOUD_ACCOUNTS_REGIONS,
  EC2_VALIDATE_CLOUD_PROXY_RESPONSE,
  LOAD_VSB_MONITOR_SUCCESS
} from "state/actions/actionTypes";

function vmwares(state = [], action) {
  switch (action.type) {
    case POLICY_VSB_FETCH_VMWARES_SUCCESS:
      return action.data;
    case CLEAR_VSB_POLICY_DATA:
      return [];
    default:
      return state;
  }
}

function hypervVSBList(state = [], action) {
  switch (action.type) {
    case GET_VSB_SUCCESS_HYPERVISORS:
      return action.data;
    case GET_VSB_FAILED_HYPERVISORS:
      return [];
    case CLEAR_VSB_HYPERVISORS:
      return [];
    default:
      return state;
  }
}

function monitors(state = [], action) {
  switch (action.type) {
    case LOAD_VSB_MONITOR_SUCCESS:
      return action.data;
    case CLEAR_VSB_POLICY_DATA:
      return [];
    default:
      return state;
  }
}

function vmwareConnectResponse(state = null, action) {
  switch (action.type) {
    case POLICY_VSB_FETCH_VMWARES_CONNECT_SUCCESS:
      return action.data;
    case CLEAR_VSB_POLICY_DATA:
      return null;
    default:
      return state;
  }
}

function esxNodes(state = [], action) {
  switch (action.type) {
    case POLICY_VSB_FETCH_VMWARES_NODES_SUCCESS:
      return action.data;
    case CLEAR_VSB_POLICY_DATA:
      return [];
    case CLEAR_ESX_NODES:
      return [];
    default:
      return state;
  }
}

function hyperVConfiguration(state = null, action) {
  switch (action.type) {
    case GET_HYPERV_CONFIGURATION_SUCCESS:
      return action.data;
    case CLEAR_VSB_POLICY_DATA:
      return null;
    default:
      return state;
  }
}

function hypervConnectForm(state = null, action) {
  switch (action.type) {
    case SET_HYPERV_CONNECT_FORM:
      return action.data;
    case CLEAR_VSB_POLICY_DATA:
      return null;
    default:
      return state;
  }
}
function vmWareConfiguration(state = null, action) {
  switch (action.type) {
    case GET_VMWARE_CONFIGURATION_SUCCESS:
      return action.data;
    case CLEAR_VSB_POLICY_DATA:
      return null;
    case CLEAR_VMWARE_CONFIGURATION:
      return null;
    default:
      return state;
  }
}

function vmwareConnectForm(state = null, action) {
  switch (action.type) {
    case SET_VMWARE_CONNECT_FORM:
      return action.data;
    case CLEAR_VSB_POLICY_DATA:
      return null;
    default:
      return state;
  }
}

function vmwareMonitorConnectResponse(state = null, action) {
  switch (action.type) {
    case POLICY_VSB_FETCH_VMWARES_MONITOR_CONNECT_SUCCESS:
      return action.data;
    case CLEAR_VSB_POLICY_DATA:
      return null;
    default:
      return state;
  }
}

function cloudAccounts(state = [], action) {
  switch (action.type) {
    case GET_LOAD_CLOUD_ACCOUNT_LIST_BY_TYPE_SUCCESS:
      return action.data;
    case CLEAR_VSB_POLICY_DATA:
      return null;
    case CLEAR_CLOUD_ACCOUNTS_BY_TYPE:
      return null;
    default:
      return state;
  }
}

function cloudConfigurations(state = null, action) {
  switch (action.type) {
    case STORE_CLOUD_CONFIGURATIONS:
      return action.data;
    case GET_LOAD_CLOUD_ACCOUNT_LIST_BY_TYPE_SUCCESS:
    case CLEAR_VSB_POLICY_DATA:
      return null;
    default:
      return state;
  }
}

function cloudSubnetList(state = [], action) {
  switch (action.type) {
    case STORE_CLOUD_SUBNET:
      return action.data;
    case GET_LOAD_CLOUD_ACCOUNT_LIST_BY_TYPE_SUCCESS:
    case CLEAR_VSB_POLICY_DATA:
      return [];
    default:
      return state;
  }
}

function cloudRegions(state = [], action) {
  switch (action.type) {
    case GET_REGIONS_FOR_CLOUD_ACCOUNT_SUCCESS:
      return action.data;
    case CLEAR_CLOUD_ACCOUNTS_REGIONS:
      return null;
    case CLEAR_VSB_POLICY_DATA:
      return null;
    default:
      return state;
  }
}

function azureResourceGroups(state = [], action) {
  switch (action.type) {
    case GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT_SUCCESS:
      return action.data;
    case CLEAR_VSB_POLICY_DATA:
      return null;
    default:
      return state;
  }
}

function ec2CloudProxyValidationResponse(state = null, action) {
  switch (action.type) {
    case EC2_VALIDATE_CLOUD_PROXY_RESPONSE:
      return action.data;
    case CLEAR_VSB_POLICY_DATA:
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  vmwareConnectResponse,
  hyperVConfiguration,
  vmWareConfiguration,
  vmwares,
  hypervVSBList,
  monitors,
  esxNodes,
  hypervConnectForm,
  vmwareConnectForm,
  vmwareMonitorConnectResponse,
  cloudAccounts,
  cloudRegions,
  cloudConfigurations,
  cloudSubnetList,
  azureResourceGroups,
  ec2CloudProxyValidationResponse
});

import {
  select,
  all,
  call,
  put,
  takeLatest,
  takeEvery,
  race,
  take
} from "redux-saga/effects";
import * as _ from "lodash";
import { getFormValues } from "redux-form";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import {
  getLogin,
  getEntitlementsProducts,
  getLoginToken,
  getOrgId
} from "state/selectors";
import * as ccToastActions from "state/actions/ccToast";
import { recoveredResourcesConstants } from "components/protect/recoveredResources/RecoveredResourcesConstant";
import * as actionType from "../actions/actionTypes";
import {
  advancedSearchLoading,
  advancedSearchLoaded,
  defaultSearchConfigured,
  doNotInitializeGraph,
  showJobsList,
  showLogList,
  submitAdvancedSearch,
  updateCurrentSearchFromSaved,
  searchEnterEvent,
  updateSearchFiltersData,
  updateCurrentSavedSearch,
  clearAllSearchTags,
  showHideSaveSearchNClearAll,
  removeSearchTags,
  removeSearchTextTag,
  searchList,
  showAdvancedSearchOptions,
  updateAdvancedSearchOptions,
  resetAdvanceSearchData,
  setFilterFromMonitorWidget,
  resetFilterFromMonitorWidget,
  updateTagsForCurrentSearch,
  addAPIresponse,
  updateJobResourceInList,
  showJobDetails,
  setResourceName,
  getSavedSearchData,
  flushSelectedSavedSearch,
  updateCurrentSavedSearchName,
  getSourcesList,
  getCurrentActionCalled,
  updateRecoveredVMS,
  updateColumnData,
  updateSourceGroupsForAdvanceSearch,
  updateSitesForAdvanceSearch,
  updatePoliciesForAdvanceSearch,
  fetchUserSupportData,
  fromNavigationApi,
  updateDestinationsForAdvanceSearch,
  updateSourceGroupList,
  showHypervisorList,
  getDataTransferTrendSummary,
  getDataTransferTopSources,
  getCapacityUsageCDTrendUsage,
  getCapacityUsageCHTrendUsage,
  getCapacityUsageCHSavingTrend,
  showCurrentSearch,
  updateMenuCountsOnAddRemoveActions,
  startLoader,
  stopLoader,
  updateDefaultSavedSearch,
  updateDashboardSummary,
  getAlertList,
  updateProducts,
  getAdvancedSearchOptions,
  browseRemoteConsoleLoading,
  getHypervisorsSuccess,
  getHypervisorsFailed,
  getHypervisors,
  browseRemoteConsoleSuccess,
  browseRemoteConsoleFailure,
  initializeApp,
  updateJobsMonitorDetails,
  getJobsMonitorDetails,
  fetchEntitlementsProductsSuccess,
  fetchEntitlementsProductsFailure,
  showLicenseDetails,
  advanceSearchLoaded,
  hideDialog,
  Dialog,
  setLoading,
  resetLoading,
  Loader,
  clearJobDetails,
  showStorageArrays,
  loadProxies,
  getConfigureListCount,
  updateRecoveredResources,
  updateInstantVMs,
  browseResourcePoolSuccess,
  addSourcesHypervisorHyperVConnectSuccess,
  addSourcesHypervisorNutanixConnectSuccess,
  addSourcesHypervisorVmwareConnectSuccess,
  addSourcesSetHypervisorId,
  loadRegisteredSitesSuccess,
  stopGridLoaderById,
  startGridLoaderById,
  showOracleSources,
  clearAllSearchTagsReload,
  getSelectedSavedSearch as getSelectedSavedSearchAction
} from "../actions/actions";
import {
  commonApi,
  protectApi,
  configureApi,
  analyzeApi,
  monitorApi,
  destinationApis
} from "../api";

import * as constant from "../../utils/appConstants";
import * as util from "../../utils/SpogDataGridUtil";
import { JobsAttributes } from "../../components/common/jobs/JobsConstants";
import { LogAttributes } from "../../components/common/log/LogConstants";
import { userAccountsAttributes } from "../../components/configure/access-control/user-accounts/UserAccountsConstants";
import { sourceGroupsAttributes } from "../../components/configure/source-groups/SourceGroupsConstants";
import {
  joblogAdvanceSearchDataSets,
  logAdvanceSearchDataSets,
  jobsAdvanceSearchDataSets,
  recoveryPointsAdvanceSearchDataSets,
  policiesAdvanceSearchDataSets,
  destinationAdvanceSearchDataSets,
  sourcesAdvanceSearchDataSets,
  virtualStandbyDataSets,
  sourceAdvanceSearchDataSets,
  reportsManageReportSchedulesAdvanceSearchDataSets,
  reportsBackupJobsAdvanceSearchDataSets,
  reportsRecoveryPointAdvanceSearchDataSets,
  reportsRestoreJobsAdvanceSearchDataSets,
  reportsDataTransferAdvanceSearchDataSets,
  reportsJobsAndDestinationsAdvanceSearchDataSets,
  reportsCapacityUsageAdvanceSearchDataSets,
  reportsAccountActivityAdvanceSearchDataSets,
  reportsDetailsAdvanceSearchDataSets,
  reportsAdvanceSearchDataSets,
  recoveredVMSAdvanceSearchDataSets,
  policySourceAdvanceSearchDataSets,
  isDeletedApiConvert,
  recoveredResourcesAdvanceSearchDataSets,
  instantVMsAdvanceSearchDataSets,
  virtualStandbysDataSets,
  accountDetailsReportAdvanceSearchDataSets,
  reportsSourceProtectionAdvanceSearchDataSets,
  customerAccountAdvanceSearchDataSets
} from "../../components/common/advanced-search-mui/AdvanceSearchDataHelper";
import { CD_RECOVERY_POINTS_COLUMN_TYPE } from "../../components/common/recovery-points/RecoveryPointsConstants";
import { UsageSummaryProps } from "../../components/monitor/dashboard/DashboardConstants";
import { getCurrentRpsId } from "state/selectors/destinations/rps";
import { getCurrentDataStoreId } from "state/selectors/destinations/datastores";
import {
  VSB_IDENTIFIERS,
  VSB_FILTER_ATTRIBUTES
} from "components/protect/recoveredResources/virtualStandby/virtualStandbyConstants";
import { getFormattedMessage } from "./sagautils";
import { DestinationConstants } from "components/protect/destination/DestinationConstants";
import { makeToastData } from "./sagautils";

import * as ccDataGridActions from "state/actions/ccDataGrid";
import * as ccDataGridSelectors from "state/selectors/ccDataGrid";

const getReducer = state => state.reducer;
const getProtectReducer = state => state.protectReducer;
const getccDataGrid = state => state.ccDataGrid;

export function* collapseSidebar(payload) {
  const collapseSideBar = !payload.collapse;
  yield put({ collapseSideBar: collapseSideBar });
}

/**
 *
 * @param {*} data
 * advanced search handler
 */
export function* receiveList(data) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    const protectReducer = yield select(getProtectReducer);
    let stateReducersearchText = "";
    let navigation = stateReducer.secondaryNavigation;
    if (data.payload && data.payload.filter) {
      navigation = data.payload.filter;
    }
    let stateReducerOptions = yield* getStateReducerOptions(data);
    if (data.payload && data.payload.searchText) {
      stateReducersearchText = data.payload.searchText;
    } else {
      stateReducersearchText = "";
    }
    let stateReducersearchOption = stateReducer.searchOption.selectedOption;

    let dataList = [];
    let filterType = "";
    let queryString = "";
    let makeQueryString = util.getQueryStringByAdvanceSearchOptions(
      queryString,
      stateReducerOptions
    );

    if (makeQueryString !== "") {
      if (
        navigation !== constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBY &&
        navigation !== constant.SECONDARY_NAVIGATION_TYPE.USER_ACCOUNTS &&
        navigation !== constant.SECONDARY_NAVIGATION_TYPE.ACCOUNT_DETAILS && //eslint-disable-line eqeqeq
        navigation !==
          constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT
      ) {
        yield put(submitAdvancedSearch());
        const selectedOptions = stateReducerOptions;
        const searchString = stateReducersearchOption;
        // update the state options only when doNotUpdateOptions is not passed or is true
        if (data.payload && !data.payload.doNotUpdateOptions) {
          yield put(
            updateCurrentSearchFromSaved({ selectedOptions, searchString })
          );
        }
      }
    }

    if (
      stateReducersearchText &&
      stateReducersearchText.length > 0 &&
      data.payload &&
      !data.payload.doNotUpdateOptions
    ) {
      yield put(searchEnterEvent(data.payload));
    }
    makeQueryString = yield* getQueryString(data, navigation);

    yield put(showLoading());

    switch (navigation) {
      case constant.SECONDARY_NAVIGATION_TYPE.JOBS:
        // yield put(startLoader());
        // queryString = util.getQueryStringBySearchNameText(
        //   makeQueryString,
        //   stateReducersearchText,
        //   stateReducersearchOption,
        //   JobsAttributes.JOBS_PROP_SOURCE
        // );
        // queryString = util.replaceAll(
        //   queryString,
        //   "date_range",
        //   "start_time_ts"
        // );

        // queryString = queryString.replace(/\\/g, "%5C");

        // dataList = yield call(
        //   commonApi.fetchJobsList,
        //   queryString,
        //   stateLogin.token,
        //   data.pollingAction
        // );
        // if (dataList && constant.HTTP_STATUS.OK === dataList.status) {
        //   yield put(showJobsList(dataList, filterType));
        // }
        // yield put(fromNavigationApi(false));
        // yield put(stopLoader());
        yield put(startLoader());
        yield* loadJob(
          data.type,
          makeQueryString,
          stateReducersearchText,
          data.payload
        );
        yield put(stopLoader());
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.LOG:
        yield put(startLoader());
        yield put(startGridLoaderById(constant.DATAGRID_IDS["logs_grid"]));
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          LogAttributes.LOG_PROP_MESSAGE
        );
        queryString = util.replaceAll(queryString, "date_range", "log_ts");
        dataList = yield call(
          commonApi.fetchLogList,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (dataList && dataList.status === constant.HTTP_STATUS.OK) {
          yield put(showLogList(dataList, filterType));
        }
        yield put(fromNavigationApi(false));
        yield put(stopLoader());
        yield put(stopGridLoaderById(constant.DATAGRID_IDS["logs_grid"]));
        break;
      //add here
      case constant.SECONDARY_NAVIGATION_TYPE.JOBLOG:
        yield put(startLoader());
        yield put(startGridLoaderById(constant.DATAGRID_IDS["logs_grid"]));
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          LogAttributes.LOG_PROP_MESSAGE
        );
        queryString = util.replaceAll(queryString, "date_range", "log_ts");
        dataList = yield call(
          commonApi.fetchLogForSelectedJob,
          stateReducer.jobLogJobData.job_id
            ? stateReducer.jobLogJobData.job_id
            : stateReducer.selectedResource.job_id,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (constant.HTTP_STATUS.OK === dataList.status) {
          if (stateReducer.selectedResource)
            yield put(setResourceName(stateReducer.selectedResource.job_name));
          yield put(showLogList(dataList, filterType));
        }
        yield put(fromNavigationApi(false));
        yield put(stopGridLoaderById(constant.DATAGRID_IDS["logs_grid"]));
        yield put(stopLoader());
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_JOBS:
        yield put(startLoader());
        yield put(startGridLoaderById(constant.DATAGRID_IDS["jobs_grid"]));
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          JobsAttributes.JOBS_PROP_SOURCE
        );
        queryString = util.replaceAll(
          queryString,
          "date_range",
          "start_time_ts"
        );
        dataList = yield call(
          commonApi.fetchJobsForSourceList,
          protectReducer.selectedResource.id
            ? protectReducer.selectedResource.id
            : protectReducer.selectedResource.source_id,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (constant.HTTP_STATUS.OK === dataList.status) {
          yield put(showJobsList(dataList, filterType));
        }
        yield put(fromNavigationApi(false));
        yield put(stopLoader());
        yield put(stopGridLoaderById(constant.DATAGRID_IDS["jobs_grid"]));
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.RPS_JOBS:
        yield put(startLoader());
        yield put(startGridLoaderById(constant.DATAGRID_IDS["jobs_grid"]));
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          JobsAttributes.JOBS_PROP_SOURCE
        );
        queryString = util.replaceAll(
          queryString,
          "date_range",
          "start_time_ts"
        );

        queryString += `&rps_id=${yield select(getCurrentRpsId)}`;

        queryString = queryString.replace(/\\/g, "%5C");

        dataList = yield call(
          commonApi.fetchJobsList,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (dataList && constant.HTTP_STATUS.OK === dataList.status) {
          yield put(showJobsList(dataList, filterType));
        }
        yield put(fromNavigationApi(false));
        yield put(stopGridLoaderById(constant.DATAGRID_IDS["jobs_grid"]));
        yield put(stopLoader());
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.DATA_STORE_JOBS:
        yield put(startLoader());
        yield put(startGridLoaderById(constant.DATAGRID_IDS["jobs_grid"]));
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          JobsAttributes.JOBS_PROP_SOURCE
        );
        queryString = util.replaceAll(
          queryString,
          "date_range",
          "start_time_ts"
        );

        queryString += `&datastore_id=${yield select(getCurrentDataStoreId)}`;

        queryString = queryString.replace(/\\/g, "%5C");

        dataList = yield call(
          commonApi.fetchJobsList,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (dataList && constant.HTTP_STATUS.OK === dataList.status) {
          yield put(showJobsList(dataList, filterType));
        }
        yield put(fromNavigationApi(false));
        yield put(stopLoader());
        yield put(stopGridLoaderById(constant.DATAGRID_IDS["jobs_grid"]));
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_LOG:
        yield put(startLoader());
        yield put(startGridLoaderById(constant.DATAGRID_IDS["logs_grid"]));
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          LogAttributes.LOG_PROP_MESSAGE
        );
        queryString = util.replaceAll(queryString, "date_range", "log_ts");
        dataList = yield call(
          commonApi.fetchLogForSourceList,
          protectReducer.selectedResource.id
            ? protectReducer.selectedResource.id
            : protectReducer.selectedResource.source_id,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (constant.HTTP_STATUS.OK === dataList.status) {
          yield put(showLogList(dataList, filterType));
        }
        yield put(fromNavigationApi(false));
        yield put(stopLoader());
        yield put(stopGridLoaderById(constant.DATAGRID_IDS["logs_grid"]));
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.RPS_LOG:
        yield put(startLoader());
        yield put(startGridLoaderById(constant.DATAGRID_IDS["logs_grid"]));
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          LogAttributes.LOG_PROP_MESSAGE
        );
        queryString += `&rps_id=${yield select(getCurrentRpsId)}`;
        queryString = util.replaceAll(queryString, "date_range", "log_ts");
        dataList = yield call(
          commonApi.fetchLogList,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (dataList && dataList.status === constant.HTTP_STATUS.OK) {
          yield put(showLogList(dataList, filterType));
        }
        yield put(fromNavigationApi(false));
        yield put(stopLoader());
        yield put(stopGridLoaderById(constant.DATAGRID_IDS["logs_grid"]));
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.DATA_STORE_LOG:
        yield put(startLoader());
        yield put(startGridLoaderById(constant.DATAGRID_IDS["logs_grid"]));
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          LogAttributes.LOG_PROP_MESSAGE
        );
        queryString += `&datastore_id=${yield select(getCurrentDataStoreId)}`;
        queryString = util.replaceAll(queryString, "date_range", "log_ts");
        dataList = yield call(
          commonApi.fetchLogList,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (dataList && dataList.status === constant.HTTP_STATUS.OK) {
          yield put(showLogList(dataList, filterType));
        }
        yield put(fromNavigationApi(false));
        yield put(stopLoader());
        yield put(stopGridLoaderById(constant.DATAGRID_IDS["logs_grid"]));
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.USER_ACCOUNTS:
        yield put(startLoader());
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          userAccountsAttributes.USER_ACCOUNTS_SEARCH_STRING
        );
        dataList = yield call(
          // configureApi.getUsers,
          configureApi.getUsersV2,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (constant.HTTP_STATUS.OK === dataList.status) {
          //  update data in configureReducer on success
          yield put({ type: actionType.UPDATE_USERS_V2, data: dataList });
        }
        yield put(fromNavigationApi(false));
        yield put(stopLoader());
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.USER_ROLES:
        yield put(startLoader());
        dataList = yield call(
          configureApi.getUserRoles,
          makeQueryString,
          stateLogin.token,
          data.pollingAction
        );
        if (constant.HTTP_STATUS.OK === dataList.status) {
          //  update data in configureReducer on success
          yield put({ type: actionType.UPDATE_USER_ROLES, data: dataList });
        }
        yield put(stopLoader());
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_GROUPS:
        yield put(startLoader());
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          sourceGroupsAttributes.SOURCE_GROUPS_NAME
        );
        dataList = yield call(
          protectApi.fetchSourcesGroups,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        yield put(updateSourceGroupList(dataList));
        yield put(fromNavigationApi(false));
        yield put(stopLoader());
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_RESOURCES:
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          constant.SEARCH_TEXT_MAPPING[navigation]
        );
        yield call(recieveRecoveredVMsList, queryString, data, navigation);
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBY:
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          constant.SEARCH_TEXT_MAPPING[navigation]
        );
        yield call(recieveRecoveredVMsList, queryString, data, navigation);
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_VMS:
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          constant.SEARCH_TEXT_MAPPING[navigation]
        );
        yield call(recieveRecoveredVMsList, queryString, data, navigation);
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.INSTANT_VMS_RR:
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          constant.SEARCH_TEXT_MAPPING[navigation]
        );
        yield call(recieveRecoveredVMsList, queryString, data, navigation);
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.HYPERVISORS:
        yield put(startLoader());
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          constant.SEARCH_TEXT_MAPPING[navigation]
        );
        dataList = yield call(
          configureApi.fetchHypervisorList,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (constant.HTTP_STATUS.OK === dataList.status) {
          yield put(showHypervisorList(dataList));
        }
        yield put(fromNavigationApi(false));
        yield put(stopLoader());
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.STORAGE_ARRAYS:
        yield put(startLoader());
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          constant.SEARCH_TEXT_MAPPING[navigation]
        );
        dataList = yield call(
          configureApi.fetchStorageArrayList,
          queryString,
          stateLogin.token,
          data.pollingAction
        );

        if (constant.HTTP_STATUS.OK === dataList.status) {
          yield put(showStorageArrays(dataList));
        }
        yield put(fromNavigationApi(false));
        yield put(stopLoader());
        break;

      default:
        break;
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideLoading());
    //yield put(stopLoader());
  }
}

export function* loadJob(type, makeQueryString, stateReducersearchText) {
  let defaultSearchText = undefined;
  if (type === "GET_CURRENT_ACTION_CALLED") {
    //Fetch the default saved searches
    let defaultData = yield* getAllSavedSearchData({
      payload: { filterType: "jobs" }
    });

    makeQueryString = util.getQueryStringWithoutAdvanceSearchOptionsForJobs(
      makeQueryString,
      defaultData
    );

    defaultSearchText = defaultData[constant.SEARCH_TEXT_MAPPING["jobs"]]; //navigation
  }

  const stateLogin = yield select(getLogin);
  const stateReducer = yield select(getReducer);
  const entitlements = yield select(getEntitlementsProducts);

  const isUDP = _.get(entitlements, "udp.enable");
  const locale = stateLogin.locale;

  let stateReducersearchOption = stateReducer.searchOption.selectedOption;
  yield put(startLoader());
  yield put(startGridLoaderById(constant.DATAGRID_IDS["jobs_grid"]));
  let queryString = util.getQueryStringBySearchNameText(
    makeQueryString,
    defaultSearchText ? defaultSearchText : stateReducersearchText,
    stateReducersearchOption,
    JobsAttributes.JOBS_PROP_SOURCE
  );
  queryString =
    queryString && util.replaceAll(queryString, "date_range", "start_time_ts");

  queryString = queryString && queryString.replace(/\\/g, "%5C");
  let apiCallArray = [];
  let advanceSearchData = Object.assign(
    [],
    jobsAdvanceSearchDataSets(locale, isUDP)
  );
  if (type === "GET_CURRENT_ACTION_CALLED") {
    if (
      (stateLogin.organization_type === "msp" &&
        stateLogin.main_organization_type === "csr") ||
      (stateLogin.organization_type === "msp" &&
        stateReducer.isImpersonationView === false)
    ) {
      apiCallArray.push(fetchOrganizationOptions(advanceSearchData));
    }
    if (
      stateLogin.organization_type === "direct" ||
      stateLogin.organization_type === "msp_child"
    ) {
      apiCallArray.push(fetchSourceGroupOptions(advanceSearchData));
    }
    apiCallArray.push(fetchProtectionPolicyOptions(advanceSearchData));
  }
  //fetch jobs api
  let dataList = yield call(
    commonApi.fetchJobsList,
    queryString,
    queryString.includes("78506751-cc21-4989-a1f4-d7019df22a9f")
      ? stateLogin.cdtoken
      : stateLogin.token,
    false
  );
  if (dataList && constant.HTTP_STATUS.OK === dataList.status) {
    yield put(showJobsList(dataList));
  }
  //fetch advancedSearchOptions
  let options = yield all(apiCallArray);
  options.map(o => advanceSearchData.push(o));

  if (type === "GET_CURRENT_ACTION_CALLED") {
    yield put(showAdvancedSearchOptions(advanceSearchData));
  }
  yield put(fromNavigationApi(false));
  yield put(stopGridLoaderById(constant.DATAGRID_IDS["jobs_grid"]));
}

export function* getStateReducerOptions(data) {
  let stateReducerOptions = [];
  const stateReducer = yield select(getReducer);
  if (
    data.payload &&
    data.payload.advancedSearchOptions &&
    data.payload.advancedSearchOptions.length > 0
  ) {
    stateReducerOptions = data.payload.advancedSearchOptions;
  } else if (
    stateReducer.selectedFilterOptions &&
    stateReducer.selectedFilterOptions.length > 0
  ) {
    stateReducerOptions = stateReducer.selectedFilterOptions;
  } else if (
    stateReducer.currentSearchOptions &&
    stateReducer.currentSearchOptions.length > 0
  ) {
    stateReducerOptions = stateReducer.currentSearchOptions;
  }

  if (
    stateReducer.secondaryNavigation ===
      constant.SECONDARY_NAVIGATION_TYPE.USER_ACCOUNTS &&
    stateReducer.selectedSavedSearchID &&
    stateReducer.selectedFilterOptions
  ) {
    stateReducerOptions = stateReducer.selectedFilterOptions;
  }
  return stateReducerOptions;
}
export function* getQueryString(data, navigation) {
  const selectLogin = yield select(getLogin);
  const stateReducer = yield select(getReducer);

  let stateReducerOptions = yield* getStateReducerOptions(data);
  let stateReducersearchOption = stateReducer.searchOption.selectedOption;

  const gridCurrentPage =
    data.payload && data.payload.gridCurrentPage
      ? data.payload.gridCurrentPage
      : stateReducer.gridCurrentPage;
  const gridPageSize =
    data.payload && data.payload.gridPageSize
      ? data.payload.gridPageSize
      : stateReducer.gridPageSize;

  let queryString = "";
  let makeQueryString = !constant.SECONDARY_NAVIGATION_TYPE_UI_FILTER.includes(
    navigation
  )
    ? util.getQueryStringByAdvanceSearchOptions(
        queryString,
        stateReducerOptions
      )
    : "";

  if (makeQueryString !== "") {
    yield put(submitAdvancedSearch());
    const selectedOptions = stateReducerOptions;
    const searchString = stateReducersearchOption;
    // update the state options only when doNotUpdateOptions is not passed or is true
    if (data.payload && !data.payload.doNotUpdateOptions) {
      yield put(
        updateCurrentSearchFromSaved({ selectedOptions, searchString })
      );
    }
  }

  if (
    gridCurrentPage &&
    gridPageSize &&
    !constant.SECONDARY_NAVIGATION_TYPE_UI_FILTER.includes(navigation)
  ) {
    if (makeQueryString && makeQueryString.length > 0) {
      makeQueryString += `&`;
    }
    makeQueryString += `page=${gridCurrentPage}&page_size=${gridPageSize}`;
  }

  if (makeQueryString && makeQueryString.length > 0) {
    makeQueryString += `&`;
  }
  makeQueryString += `organization_id=${selectLogin.organization_id}`;
  let sortedDataSet = yield* getSortedDataSet(data);
  if (sortedDataSet.length) {
    makeQueryString = util.getQueryStringFromSortedData(
      makeQueryString,
      sortedDataSet
    );
  }
  return makeQueryString;
}

export function* getSortedDataSet(data) {
  const stateReducer = yield select(getReducer);
  let navigation = stateReducer.secondaryNavigation;
  if (data.payload && data.payload.filter) {
    navigation = data.payload.filter;
  }
  let sortedDataSet = [];
  if (data.payload && data.payload.sortedData) {
    sortedDataSet = data.payload.sortedData;
  } else if (
    stateReducer.sortedColumns &&
    Array.isArray(stateReducer.sortedColumns) &&
    stateReducer.sortedColumns.length > 0
  ) {
    sortedDataSet = stateReducer.sortedColumns;
  } else if (constant.GRID_DEFAULT_SORTING[navigation]) {
    sortedDataSet = constant.GRID_DEFAULT_SORTING[navigation];
  }
  return sortedDataSet;
}

function* recieveRecoveredVMsList(queryString, data, navigation) {
  try {
    yield put(fromNavigationApi(false));
    const stateLogin = yield select(getLogin);
    yield put(startLoader());
    let staticQueryString = null;
    let dynamicQueryString = null;
    let staticResponse = [];
    let dynamicResponse = [];
    let staticData = {};
    let dynamicData = {};
    const separator = encodeURIComponent("|");
    switch (navigation) {
      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_RESOURCES:
        //================================Fetch Static Data================================================
        staticQueryString = `${queryString}&recoveredresource_type=vsb${separator}ivm${separator}clouddirect_draas&content=static&pagination=true`;
        staticResponse = yield call(
          protectApi.getRecoveredVMS,
          staticQueryString,
          stateLogin.token,
          data.pollingAction
        );
        //================================Add Loading State Into Static Data===============================
        if (constant.HTTP_STATUS.OK === staticResponse.status) {
          staticData.data = staticResponse.data.map(staticRow => {
            return {
              ...staticRow,
              state: "loading-status"
            };
          });
          staticData.pagination = staticResponse.pagination;
          yield put(updateRecoveredResources(staticData));
        }
        //================================Fetch Dynamic Data===============================================
        dynamicQueryString = `${queryString}&recoveredresource_type=vsb${separator}ivm${separator}clouddirect_draas&content=dynamic&pagination=true`;
        dynamicResponse = yield call(
          protectApi.getRecoveredVMS,
          dynamicQueryString,
          stateLogin.token,
          data.pollingAction
        );
        //================================Repalce Loading State By Actual Data From Dynamic Data===========
        if (constant.HTTP_STATUS.OK === dynamicResponse.status) {
          dynamicData.data = staticData.data.map((staticRow, index) => {
            return {
              ...staticRow,
              ...dynamicResponse.data[index]
            };
          });
          dynamicData.pagination = dynamicResponse.pagination;
          yield put(updateRecoveredResources(dynamicData));
        }
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.NETWORK_CONFIGURATION:
        queryString = `pagination=false&organization_id=${stateLogin.organization_id}`;
        staticResponse = yield call(
          protectApi.getRecoveredVMS,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (constant.HTTP_STATUS.OK === staticResponse.status) {
          yield put(updateRecoveredVMS(staticResponse));
        }
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.INSTANT_VMS_RR:
        yield put(
          startGridLoaderById(constant.DATAGRID_IDS["instant_vm_grid"])
        );
        //================================Fetch Static Data================================================
        staticQueryString = `${queryString}&type=ivm&content=static&pagination=false`;
        staticResponse = yield call(
          protectApi.getRecoveredVMS,
          staticQueryString,
          stateLogin.token,
          data.pollingAction
        );
        if (constant.HTTP_STATUS.OK === staticResponse.status) {
          //If fetching first time then add loading state else show data returned in api response
          // if (data.payload.firstFetch) {
          //   staticData.data = staticResponse.data.map(staticRow => {
          //     return {
          //       ...staticRow,
          //       state: "loading-status",
          //       ip_address: "loading-status",
          //       ivm_status: "loading-status",
          //       available_actions: staticRow.available_actions
          //     };
          //   });
          //   //    staticData.pagination = staticResponse.pagination;
          //   yield put(updateInstantVMs(staticData));
          // } else {
          yield put(updateInstantVMs(staticResponse));
          // }
        }
        yield put(stopGridLoaderById(constant.DATAGRID_IDS["instant_vm_grid"]));
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBY:
        yield put(
          startGridLoaderById(constant.DATAGRID_IDS["virtual_standby_grid"])
        );
        queryString = `${queryString}&type=vsb&content=static&pagination=false`.replace(
          "source_state=vsb_active",
          "source_state=active"
        );
        staticResponse = yield call(
          protectApi.getRecoveredVMS,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (constant.HTTP_STATUS.OK === staticResponse.status) {
          yield put(updateRecoveredResources(staticResponse));
        }
        yield put(
          stopGridLoaderById(constant.DATAGRID_IDS["virtual_standby_grid"])
        );
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_VMS:
        yield put(
          startGridLoaderById(
            constant.DATAGRID_IDS["recovered_resources_datagrid"]
          )
        );
        //================================Fetch Static Data================================================
        staticQueryString = `${queryString}&type=clouddirect_draas&content=static&pagination=true`;
        staticResponse = yield call(
          protectApi.getRecoveredVMS,
          staticQueryString,
          stateLogin.token,
          data.pollingAction
        );
        //================================Add Loading State Into Static Data===============================
        if (constant.HTTP_STATUS.OK === staticResponse.status) {
          staticData = staticResponse;
          yield put(updateRecoveredVMS(staticData));
        }
        //================================Fetch Dynamic Data===============================================
        dynamicQueryString = `${queryString}&type=clouddirect_draas&content=dynamic&pagination=true`;
        dynamicResponse = yield call(
          protectApi.getRecoveredVMS,
          dynamicQueryString,
          stateLogin.token,
          data.pollingAction
        );
        //================================Repalce Loading State By Actual Data From Dynamic Data===========
        if (constant.HTTP_STATUS.OK === dynamicResponse.status) {
          yield put(updateInstantVMs({}));
          //eslint-disable-next-line array-callback-return
          staticData.data.concat(dynamicResponse.data).map(d => {
            dynamicData[d.recoveredresource_id] = {
              state: recoveredResourcesConstants.STATE.MISSING,
              // allowed_actions: [
              //   recoveredResourcesConstants.CONTEXTUAL_ACTION.DELETE
              // ],
              ...dynamicData[d.recoveredresource_id],
              ...d
            };
          });
          staticData.data = [];
          //eslint-disable-next-line array-callback-return
          Object.keys(dynamicData).map(key => {
            staticData.data.push({
              ...dynamicData[key],
              allowed_actions: [
                // ...dynamicData[key].allowed_actions,
                ...dynamicData[key].available_actions
              ]
            });
          });
          yield put(updateRecoveredVMS(staticData));
        }

        yield put(
          stopGridLoaderById(
            constant.DATAGRID_IDS["recovered_resources_datagrid"]
          )
        );
        break;
      default:
        break;
    }
    yield put(stopLoader());
  } catch (e) {
    //TODO handle error
    window.console.log(e);
  }
}

export function* handleBulkAction(payload) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : stateLogin.organization_id;
    let apiResponse = {};
    yield put(showLoading());
    //eslint-disable-next-line default-case
    switch (payload.action) {
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.START_BACKUP: {
        if (
          _.map(payload.data, "product_type").includes(
            constant.SOURCE_PRODUCT.CLOUD_DIRECT
          )
        ) {
          apiResponse = yield call(
            commonApi.bulkActionStartBackup,
            _.map(payload.data, "source_id"),
            stateLogin.token,
            `organization_id=${organizationId}`,
            { showCommonError: true, testId: "uz9V8c" }
          );
        } else {
          let backupOptionsValues = yield select(
            getFormValues("backupOptions")
          );
          let customPayload = {
            source_ids: _.map(payload.data, "source_id"),
            backup_name: _.get(backupOptionsValues, "backup_name"),
            backup_type: _.get(backupOptionsValues, "backup_type"),
            schedule_type: _.get(backupOptionsValues, "schedule_type")
          };
          apiResponse = yield call(
            commonApi.bulkActionStartBackupV2,
            customPayload,
            stateLogin.token,
            { showCommonError: true, testId: "nkpd9a" }
          );
        }
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.CANCEL_BACKUP: {
        apiResponse = yield call(
          commonApi.bulkActionCancelBackup,
          _.map(payload.data, "source_id"),
          stateLogin.token,
          `organization_id=${organizationId}`,
          { showCommonError: true, testId: "nkpd9a" }
        );
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.CANCEL_RECOVERY: {
        if (payload.resourceType === "jobs") {
          apiResponse = yield call(
            commonApi.bulkActionForResourcePostData,
            payload.resourceType,
            _.map(
              payload.data,
              constant.SPOG_GRID_PROPERTIES.RESOURCES_ID_KEY[
                payload.resourceType
              ]
            ),
            stateLogin.token,
            `organization_id=${organizationId}`,
            "canceljob",
            { showCommonError: true, testId: "Suk3yx" }
          );
          break;
        } else {
          apiResponse = yield call(
            commonApi.bulkActionCancelRecovery,
            _.map(payload.data, "source_id"),
            stateLogin.token,
            `organization_id=${organizationId}`,
            { showCommonError: true, testId: "HHT7i7" }
          );
          break;
        }
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.CANCEL_REPLICATION: {
        apiResponse = yield call(
          commonApi.bulkActionCancelReplicationin,
          _.map(payload.data, "source_id"),
          stateLogin.token,
          `organization_id=${organizationId}`,
          { showCommonError: true, testId: "ZygbwU" }
        );
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.START_REPLICATION_OUT: {
        apiResponse = yield call(
          commonApi.bulkActionStartReplicationOut,
          _.map(payload.data, "source_id"),
          stateLogin.token,
          `organization_id=${organizationId}`,
          { showCommonError: true, testId: "xOFr0u" }
        );
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.START_REPLICATION: {
        if (payload.resourceType === "policies") {
          let postBody = [];
          postBody = _.map(payload.data, "policy_id");
          apiResponse = yield call(
            commonApi.bulkActionReplicatePolicies,
            postBody,
            stateLogin.token,
            { showCommonError: true, testId: "TC4tmN" }
          );
          break;
        } else {
          apiResponse = yield call(
            commonApi.bulkActionStartReplication,
            _.map(payload.data, "source_id"),
            stateLogin.token,
            `organization_id=${organizationId}`,
            { showCommonError: true, testId: "6qevUI" }
          );
          break;
        }
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.REMOVE: {
        apiResponse = yield call(
          commonApi.bulkActionRemove,
          _.map(payload.data, "source_id"),
          stateLogin.token,
          `organization_id=${organizationId}`,
          { showCommonError: true, testId: "zvRYgd" }
        );
        break;
      }

      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.REMOVE_POLICY: {
        apiResponse = yield call(
          commonApi.bulkActionRemovePolicies,
          _.map(payload.data, "source_id"),
          stateLogin.token,
          `organization_id=${organizationId}`,
          { showCommonError: true, testId: "Fy94pT" }
        );
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.ENABLE_POLICY: {
        let postBody = { policy_id: null };
        postBody.policy_id = _.map(payload.data, "policy_id");
        apiResponse = yield call(
          commonApi.bulkActionEnablePolicies,
          postBody,
          stateLogin.token,
          { showCommonError: true, testId: "TBB0cw" }
        );
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.DISABLE_POLICY: {
        let postBody = { policy_id: null };
        postBody.policy_id = _.map(payload.data, "policy_id");
        apiResponse = yield call(
          commonApi.bulkActionDisablePolicies,
          postBody,
          stateLogin.token,
          { showCommonError: true, testId: "I3zclf" }
        );
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.BACKUP_POLICY: {
        let postBody = [];
        postBody = _.map(payload.data, "policy_id");
        apiResponse = yield call(
          commonApi.bulkActionBackupPolicies,
          postBody,
          stateLogin.token,
          { showCommonError: true, testId: "oTlHVU" }
        );
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.ASSIGN_POLICY: {
        apiResponse = yield call(
          commonApi.bulkActionAssignPolicy,
          payload.data.policyId,
          _.map(payload.data.selection, "source_id"),
          stateLogin.token,
          `organization_id=${organizationId}`,
          { showCommonError: true, testId: "cpqfS7" }
        );
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.PROVISION: {
        apiResponse = yield call(
          commonApi.bulkActionProvision,
          payload.resourceType,
          _.map(
            payload.data,
            constant.SPOG_GRID_PROPERTIES.RESOURCES_ID_KEY[payload.resourceType]
          ),
          stateLogin.token,
          `organization_id=${organizationId}`,
          { showCommonError: true, testId: "mDH3U3" }
        );
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.DEPLOY: {
        // apiResponse = yield call(
        //   commonApi.bulkActionDeploy,
        //   _.map(payload.data, "source_id"),
        //   stateLogin.token,
        //   `organization_id=${organizationId}`
        // );
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.UPGRADE_AGENT:
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.CANCEL_JOB:
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.DEPROVISION:
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.GRACEFUL_STOP:
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.START:
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.RESTART:
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.HARD_STOP:
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.RESET_PASSWORD:
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.VERIFICATION_EMAIL: {
        apiResponse = yield call(
          commonApi.bulkActionForResourcePostData,
          payload.resourceType,
          _.map(
            payload.data,
            constant.SPOG_GRID_PROPERTIES.RESOURCES_ID_KEY[payload.resourceType]
          ),
          stateLogin.token,
          `organization_id=${organizationId}`,
          payload.action,
          payload.action && payload.dgId
            ? {
                showCommonError: true,
                testId: `${payload.action}_${payload.dgId}`
              }
            : true
        );
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.DELETE_REPORT:
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.DELETE: {
        apiResponse = yield call(
          commonApi.bulkActionForResourceDeleteData,
          payload.resourceType,
          _.map(
            payload.data,
            constant.SPOG_GRID_PROPERTIES.RESOURCES_ID_KEY[payload.resourceType]
          ),
          stateLogin.token,
          `organization_id=${organizationId}`,
          payload.action && payload.dgId
            ? {
                showCommonError: true,
                testId: `${payload.action}_${payload.dgId}`
              }
            : true
        );
        break;
      }
      case constant.SPOG_GRID_PROPERTIES.BULK_ACTION.REFRESH: {
        apiResponse = yield call(
          commonApi.bulkActionForRefreshData,
          payload.resourceType,
          _.map(
            payload.data,
            constant.SPOG_GRID_PROPERTIES.RESOURCES_ID_KEY[payload.resourceType]
          ),
          stateLogin.token,
          `organization_id=${organizationId}`,
          { showCommonError: true, testId: "q1Dwpe" }
        );
        break;
      }
    }
    let advancedSearchOptions = stateReducer.currentSearchOptions;
    if (
      stateReducer.selectedSavedSearchId &&
      stateReducer.selectedFilterOptions
    ) {
      advancedSearchOptions = stateReducer.selectedFilterOptions;
    }
    const searchText = stateReducer.searchText;
    const filter = stateReducer.secondaryNavigation;
    if (
      [constant.HTTP_STATUS.OK, constant.HTTP_STATUS.CREATED].indexOf(
        apiResponse.status
      ) !== -1
    ) {
      apiResponse.context = "grid.bulkaction.response";
      apiResponse.action = payload.action;
      const resource = getResourceName(payload);
      let testIdForToast = "";
      let toastData = "grid.bulkaction.success";

      if (payload.action && payload.dgId) {
        testIdForToast = `${payload.dgId}_${payload.action}`;
        toastData = {
          messageId: "grid.bulkaction.success",
          testId: testIdForToast
        };
      }
      if (
        payload.action === DestinationConstants.CONTEXTUAL_ACTION.DELETE &&
        payload.resourceType === "destinations"
      ) {
        yield put(
          ccToastActions.addSuccessNotification(
            makeToastData(
              "destinations.grid.bulkaction.delete.success",
              "53sW5o"
            )
          )
        );
      } else if (resource) {
        const values = {
          name: getFormattedMessage(payload.action),
          resource: getFormattedMessage(resource)
        };
        yield put(ccToastActions.addSuccessNotification(toastData, values));
      }
      yield put(addAPIresponse(apiResponse));
      if (
        constant.SPOG_GRID_PROPERTIES.BULK_ACTION.DELETE === payload.action &&
        payload.resourceType === constant.SECONDARY_NAVIGATION_TYPE.PROXIES
      ) {
        yield put(loadProxies(1, 25));
        yield put(getConfigureListCount());
      }
      if (
        [
          constant.SECONDARY_NAVIGATION_TYPE.POLICIES,
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
          constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS
        ].includes(filter)
      ) {
        const polling = false;
        const init = false;
        const additionalParmeters = { useApi: true };
        yield put(
          ccDataGridActions.fetchData(
            filter,
            init,
            polling,
            additionalParmeters
          )
        );
        if (
          payload.action === constant.SPOG_GRID_PROPERTIES.BULK_ACTION.DELETE
        ) {
          const protectReducer = yield select(getProtectReducer);
          let oracleApiData = protectReducer.oracleSource;
          let oracleHost = oracleApiData?.data ? oracleApiData.data : [];
          let payloadData = payload.data.map(e => e.source_id);
          if (oracleHost.length > 0) {
            const filteredData = oracleHost.filter(
              e => !payloadData.includes(e.source_id)
            );
            oracleApiData.data = filteredData;
            // oracleApiData.pagination.total_size--;
            yield put(showOracleSources(oracleApiData, filter));
          }
        }
        // yield put(ccDataGridActions.fetchData(filter))
      } else {
        yield put(searchList({ advancedSearchOptions, searchText, filter }));
      }
      if (
        constant.SPOG_GRID_PROPERTIES.BULK_ACTION.REMOVE === payload.action ||
        (constant.SPOG_GRID_PROPERTIES.BULK_ACTION.DELETE === payload.action &&
          payload.resourceType !== constant.SECONDARY_NAVIGATION_TYPE.SOURCES)
      ) {
        let mainType = "Protect";
        if (payload.resourceType === "users") {
          mainType = "Configure";
        }
        let sendTypesArray = payload.subType
          ? _.uniq([payload.subType, payload.resourceType])
          : [payload.resourceType];
        let tempUpdateMenuKeys = { types: sendTypesArray, action: "remove" };
        let payloadLength = payload.data.length;
        if (sendTypesArray.includes("arcserve_cloud" && "destinations")) {
          return;
        }
        yield put(
          updateMenuCountsOnAddRemoveActions({
            type: mainType,
            updateMenuKeys: tempUpdateMenuKeys,
            count: payloadLength
          })
        );
      }
    } else {
      yield put(addAPIresponse(apiResponse));
    }

    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideLoading());
  }
}

function getResourceName(payload) {
  switch (payload.resourceType) {
    case "jobs":
      return "grid.bulkaction.resource.job";
    case "policies":
      return "policy";
    case "destinations":
      return "Destination";
    case "reports":
      return "grid.bulkaction.resource.report";
    case "recoveredresources":
      return "grid.bulkaction.resource.recovered_resource";
    case "proxies":
      return "Proxy";
    case "storagearrays":
      return "grid.bulkaction.resource.storage_array";
    case "sources":
      return "Source";
    default:
      break;
  }
}

export function* exportLog(payload) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    const protectReducer = yield select(getProtectReducer);
    let joblogId = "";
    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : stateLogin.organization_id;

    let stateReducerOptions = [];
    let stateReducersearchText = "";
    let navigation = stateReducer.secondaryNavigation;
    if (payload.data && payload.data.filter) {
      navigation = payload.data.filter;
    }

    if (payload.data && payload.data.advancedSearchOptions) {
      stateReducerOptions = payload.data.advancedSearchOptions;
    } else {
      stateReducerOptions = stateReducer.currentSearchOptions;
    }
    if (payload.data && payload.data.searchText) {
      stateReducersearchText = payload.data.searchText;
    } else {
      stateReducersearchText = stateReducer.searchText;
    }

    let stateReducersearchOption = stateReducer.searchOption.selectedOption;

    let queryString = `organization_id=${organizationId}&export=csv`;

    let makeQueryString = util.getQueryStringByAdvanceSearchOptions(
      queryString,
      stateReducerOptions
    );

    queryString = util.getQueryStringBySearchNameText(
      makeQueryString,
      stateReducersearchText,
      stateReducersearchOption,
      LogAttributes.LOG_PROP_MESSAGE
    );
    let apiResponse = null;
    switch (navigation) {
      case constant.SECONDARY_NAVIGATION_TYPE.LOG:
        apiResponse = yield call(
          commonApi.fetchCSVLogList,
          queryString,
          stateLogin.token
        );
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.DATA_STORE_LOG:
        queryString += `&datastore_id=${yield select(getCurrentDataStoreId)}`;
        apiResponse = yield call(
          commonApi.fetchCSVLogList,
          queryString,
          stateLogin.token
        );
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.RPS_LOG:
        queryString += `&rps_id=${yield select(getCurrentRpsId)}`;
        apiResponse = yield call(
          commonApi.fetchCSVLogList,
          queryString,
          stateLogin.token
        );
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_LOG:
        apiResponse = yield call(
          commonApi.fetchCSVLogForSourceList,
          protectReducer.selectedResource.id,
          queryString,
          stateLogin.token
        );
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.JOBLOG:
        joblogId = stateReducer.jobLogJobData.job_id
          ? stateReducer.jobLogJobData.job_id
          : stateReducer.selectedResource.job_id;
        apiResponse = yield call(
          commonApi.fetchCSVLogForSelectedJob,
          joblogId,
          queryString,
          stateLogin.token
        );
        break;

      default:
        break;
    }
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      // apiResponse.context = "common.logs.exportLog";
      const toastData = {
        messageId: constant.API_RESPONSE_CONTEXT.EXPORT_LOG_SUCCESS,
        testId: `soUYyD`
      };
      yield put(ccToastActions.addSuccessNotification(toastData));
      yield put(addAPIresponse(apiResponse));
    } else {
      const toastData = {
        messageId: constant.API_RESPONSE_CONTEXT.EXPORT_LOG_FAILED,
        testId: `soUYyD_${apiResponse.status}`
      };
      // apiResponse.context = "common.logs.exportLogFail";
      yield put(ccToastActions.addErrorNotification(toastData));
      yield put(addAPIresponse(apiResponse));
    }
    /* if (
      apiResponse &&
      apiResponse.response &&
      apiResponse.response.type.includes("text/csv")
    ) {
      if (typeof window.navigator.msSaveBlob !== "undefined") {
        // IE doesn't allow using a blob object directly as link href.
        // Workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        apiResponse && window.navigator.msSaveBlob(apiResponse.response);
        return;
      }
      // Other browsers
      // Create a link pointing to the ObjectURL containing the blob
      const blobURL = window.URL.createObjectURL(apiResponse.response);
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = blobURL;
      tempLink.setAttribute("download", apiResponse.filename);
      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
      }
      document.body.appendChild(tempLink);
      util.eventFire(tempLink, "click");
      document.body.removeChild(tempLink);
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
      }, 100);
    } else {
      apiResponse.status = 888;
      apiResponse.context = "common.logs.exportLogFail";
      yield put(addAPIresponse(apiResponse));
    }*/
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Call api method and handle api response
 */
export function* exportCustomAccount() {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    let apiResponse = yield call(
      commonApi.exportCustomAccount,
      organizationId,
      loginReducer.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToastActions.addSuccessNotification(
          makeToastData("toast-success.export.customer-account", "Ompv40")
        )
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* fetchResourcePool(payload) {
  try {
    const stateLogin = yield select(getLogin);
    const apiResponse = yield call(
      commonApi.browseResourcePoolV2,
      payload.data,
      stateLogin.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(browseResourcePoolSuccess(apiResponse.data));
    }
  } catch (error) {
    window.console.log(error);
  }
}

export function* getHypervisorsSaga({ fetchHypervForPage, fetchType }) {
  try {
    let token = yield select(getLoginToken);
    let orgId = yield select(getOrgId);
    let product_type = fetchType ? `&product_type=${fetchType}` : "";
    let queryString = `organization_id=${orgId}&pagination=false${product_type}`;
    if (fetchHypervForPage === constant.HYPERVISOR_PAGES.SOURCE_DISCOVERY) {
      queryString = `is_discovery=false&organization_id=${orgId}&pagination=false${product_type}`;
    }
    const resp = yield call(protectApi.fetchHypervisorList, queryString, token);

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getHypervisorsSuccess(resp.data));
    } else if (resp.status >= 400) {
      yield put(getHypervisorsFailed());
    }
  } catch (e) {
    window.console.log(e);
  }
}

function* browseRemoteConsole(payload) {
  try {
    yield put(showLoading());
    yield put(setLoading(Loader.CONNECT_TO_WIN));
    yield put(setLoading(Loader.COLLECT_DIAGNOSTIC_BROWSE));

    yield put(browseRemoteConsoleLoading());
    const stateLogin = yield select(getLogin);
    let postData = payload.data;
    if (payload.path) {
      postData.path = payload.path;
    }
    let apiResponse = null;
    if (
      payload.browseType === constant.BROWSE_REMOTE_CONSOLE_TYPE.RESOURCE_POOL
    ) {
      apiResponse = yield call(
        commonApi.browseResourcePool,
        payload.data,
        stateLogin.token
      );
    } else if (
      payload.browseType ===
      constant.BROWSE_REMOTE_CONSOLE_TYPE.RECOVERY_RESOURCE_POOL
    ) {
      apiResponse = yield call(
        commonApi.browseResourcePoolV2,
        payload.data,
        stateLogin.token
      );
    } else if (
      payload.browseType === constant.BROWSE_REMOTE_CONSOLE_TYPE.HYPERV
    ) {
      apiResponse = yield call(
        commonApi.browseHyperVV2,
        payload.data,
        stateLogin.token
      );
    } else if (
      payload.browseType === constant.BROWSE_REMOTE_CONSOLE_TYPE.LINUX
    ) {
      apiResponse = yield call(
        commonApi.browseRemoteConsoleLinux,
        payload.data,
        stateLogin.token
      );
    } else {
      apiResponse = yield call(
        commonApi.browseRemoteConsole,
        payload.data,
        stateLogin.token
      );
    }
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      if (apiResponse.data.length === 0) {
        yield put(
          ccToastActions.addErrorNotification(
            makeToastData(
              "browse.folder.empty",
              `pFssIN_${apiResponse?.status}`
            )
          )
        );
      }
      yield put(browseRemoteConsoleSuccess(apiResponse));
      apiResponse.context = constant.API_RESPONSE_CONTEXT.BROWSE_REMOTE_SUCCESS;
      yield put(addAPIresponse(apiResponse));
    } else {
      apiResponse.context = constant.API_RESPONSE_CONTEXT.BROWSE_REMOTE_FAILED;
      yield put(addAPIresponse(apiResponse));
      yield put(browseRemoteConsoleFailure(apiResponse));
      yield put(hideDialog(Dialog.BROWSE_REMOTE_CONSOLE));
    }
    yield put(hideLoading());
    yield put(resetLoading(Loader.CONNECT_TO_WIN));
    yield put(resetLoading(Loader.COLLECT_DIAGNOSTIC_BROWSE));
  } catch (e) {
    window.console.log(e);
  }
}
function* addSavedSearchData(data) {
  try {
    let postData = [];
    const stateReducerForMenu = yield select(getReducer);
    postData = Object.assign(
      {},
      util.getSelecetedOptionsInArray(
        [],
        stateReducerForMenu.options,
        "",
        stateReducerForMenu.searchOption.selectedOption,
        data.saveSearchName,
        stateReducerForMenu.userSearchData.length
      )
    );
    if (postData.id > 0) {
      const apiResponse = yield call(commonApi.addSavedSearchData, postData);
      yield put({
        type: actionType.INSERT_MANAGE_SAVE_SEARCH,
        data: apiResponse
      });
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteSavedSearchData(data) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    let selectedSavedSearchName = stateReducer.selectedSavedSearchName;
    let selectedSavedSearchID = stateReducer.selectedSavedSearchId;
    let deleteSuccess = yield call(
      commonApi.deleteSavedSearchData,
      loginReducer.token,
      data.id,
      { showCommonError: true, testId: "14P04F" }
    );
    if (deleteSuccess.status && deleteSuccess.status === 200) {
      yield put({
        type: actionType.DELETE_UPDATE_MANAGE_SAVE_SEARCH,
        data: data.id
      });
      yield put(
        ccDataGridActions.invokeDeleteSavedSearch(data.filter, data.id)
      );
      yield put(flushSelectedSavedSearch());
      yield put(
        updateCurrentSavedSearchName(
          selectedSavedSearchName,
          selectedSavedSearchID
        )
      );
      deleteSuccess.context =
        constant.API_RESPONSE_CONTEXT.DELETE_SAVED_SEARCH_DATA;
      let toastData = constant.API_RESPONSE_CONTEXT.DELETE_SAVED_SEARCH_DATA;
      if (data.dgId) {
        toastData = {
          messageId: constant.API_RESPONSE_CONTEXT.DELETE_SAVED_SEARCH_DATA,
          testId: `${data.dgId}_delete`
        };
      }
      yield put(ccToastActions.addSuccessNotification(toastData));
      yield put(addAPIresponse(deleteSuccess));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteSavedSearchDataCCDataGrid(data) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    let selectedSavedSearchName = stateReducer.selectedSavedSearchName;
    let selectedSavedSearchID = stateReducer.selectedSavedSearchId;
    let deleteSuccess = yield call(
      commonApi.deleteSavedSearchData,
      loginReducer.token,
      data.id
    );
    if (deleteSuccess.status && deleteSuccess.status === 200) {
      yield put({
        type: actionType.DELETE_UPDATE_MANAGE_SAVE_SEARCH,
        data: data.id
      });
      yield put(flushSelectedSavedSearch());
      yield put(
        updateCurrentSavedSearchName(
          selectedSavedSearchName,
          selectedSavedSearchID
        )
      );
      // deleteSuccess.context =
      //   constant.API_RESPONSE_CONTEXT.DELETE_SAVED_SEARCH_DATA;
      let toastData = constant.API_RESPONSE_CONTEXT.DELETE_SAVED_SEARCH_DATA;
      if (data.dgId) {
        toastData = {
          messageId: constant.API_RESPONSE_CONTEXT.DELETE_SAVED_SEARCH_DATA,
          testId: `${data.dgId}_delete`
        };
      }
      yield put(ccToastActions.addSuccessNotification(toastData));
      yield put(addAPIresponse(deleteSuccess));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* updateLoginProducts(loginReducer) {
  let licenseDetails = yield call(
    protectApi.fetchEntitlements,
    loginReducer.organization_id,
    loginReducer.token
  );
  if (licenseDetails.status === constant.HTTP_STATUS.OK) {
    // yield put(showLicenseDetails(licenseDetails.data));
    Object.keys(licenseDetails.data).forEach(key => {
      if (
        licenseDetails.data[key].state === "active" ||
        licenseDetails.data[key].state === "trial"
      ) {
        licenseDetails.data[key] = true;
      } else {
        licenseDetails.data[key] = false;
      }
    });
    yield put(updateProducts(licenseDetails.data));
  }
}

/**
 * Updated store data after CD trial is sucesss . If trial is started from entitlement then update licennce details store->configureReducer->licenseDetails.
 * Update store data store->api->entitlementsProducts
 * Update store data store->monitorReducer->usageSumary
 * TODO: same data is being store in multiple reducer it should store at one place and all components(monitor, secondaryNavigation and entitlement) should use same data
 */
export function* updateEntitlementsAfterCDTrial(
  loginReducer,
  showLicenseDetailsAfterSuccess
) {
  const usageResponse = yield call(
    monitorApi.fetchUsageSummary,
    loginReducer.organization_id,
    `organization_id=${loginReducer.organization_id}`,
    loginReducer.token
  );
  if (usageResponse && usageResponse.status === constant.HTTP_STATUS.OK) {
    //if trial is started from entitlement page then update data in store->configureReducer->licenseDetails responsible for showing details in entitlement page
    if (showLicenseDetailsAfterSuccess) {
      let data = _.cloneDeep(usageResponse.data);
      let firstName = _.get(loginReducer, "userDetails.first_name", "");
      let lastName = _.get(loginReducer, "userDetails.last_name", "");
      let licenseData = {
        ...data,
        user: firstName + " " + lastName
      };
      yield put(showLicenseDetails(licenseData));
    }
    //update store data store->api->entitlementsProducts responsible for showing Arcserve Cloud in secondary navigation bar
    yield put(fetchEntitlementsProductsSuccess(usageResponse.data));
    yield put(
      updateDashboardSummary({
        usageSummary: usageResponse.data
      })
    );
  } else {
    yield put(fetchEntitlementsProductsFailure());
  }
}

/**
 *
 * @param {*} data
 * create/edit a 'Saved Search' to back end
 */
export function* updateSavedSearchData(data) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    let navigation = stateReducer.secondaryNavigation;
    if (data.filter) {
      navigation = data.filter;
    }
    let filterType = constant.SAVED_SEARCH_FILTERS_KEYS_MAPPING[navigation];
    let userSearchData = _.cloneDeep(stateReducer.selectedSavedSearch);
    // Checking source state filter type and remove VSB identifier to sendd BE suporrted source state filter
    // To fix Bug 1038751
    if (
      userSearchData &&
      Object.keys(userSearchData).includes(
        VSB_FILTER_ATTRIBUTES.SOURCE_STATE
      ) &&
      _.get(userSearchData, "source_state[0]").includes(
        VSB_IDENTIFIERS[VSB_FILTER_ATTRIBUTES.SOURCE_STATE]
      )
    ) {
      userSearchData[VSB_FILTER_ATTRIBUTES.SOURCE_STATE].forEach(
        (item, index) => {
          userSearchData[VSB_FILTER_ATTRIBUTES.SOURCE_STATE][
            index
          ] = item.replace(
            VSB_IDENTIFIERS[VSB_FILTER_ATTRIBUTES.SOURCE_STATE],
            ""
          );
        }
      );
    }
    if (_.get(userSearchData, "source_state[0]") === "vsb_active") {
      userSearchData.source_state[0] = "active";
    }
    userSearchData.blocked && userSearchData.blocked.length > 0
      ? (userSearchData.blocked = userSearchData.blocked.toString())
      : void 0;
    if (data.isAdd) {
      const searchText =
        stateReducer.searchText ||
        (stateReducer.searchOption && stateReducer.searchOption.selectedOption);
      if (searchText) {
        const searchTextKey = constant.SEARCH_TEXT_MAPPING[navigation];
        userSearchData[searchTextKey] = searchText;
      }
      userSearchData.is_default = false;
    }
    userSearchData.filter_type = filterType;
    userSearchData.organization_id = stateReducer.customerOrgId
      ? stateReducer.customerOrgId
      : loginReducer.organization_id;
    userSearchData.user_id = loginReducer.user_id;

    const apiResponse = yield call(
      commonApi.updateSavedSearchData,
      data.isAdd,
      loginReducer.token,
      userSearchData,
      stateReducer.selectedSavedSearch.filter_id,
      { showCommonError: true, testId: "H9FKzq" }
    );
    if (
      apiResponse.status === constant.HTTP_STATUS.OK ||
      apiResponse.status === constant.HTTP_STATUS.CREATED
    ) {
      yield put({
        type: actionType.UPDATE_MANAGE_SAVE_SEARCH,
        data: apiResponse.data
      });

      if (data.isAdd) {
        yield put(getSavedSearchData({ navigation }));
        // apiResponse.context =
        //   constant.API_RESPONSE_CONTEXT.ADD_SAVED_SEARCH_DATA;
        let toastData = constant.API_RESPONSE_CONTEXT.ADD_SAVED_SEARCH_DATA;
        if (data.dgId) {
          toastData = {
            messageId: constant.API_RESPONSE_CONTEXT.ADD_SAVED_SEARCH_DATA,
            testId: `${data.dgId}_add`
          };
        }
        // Both these were added to clear all tags when saved search is added. earlier this was in save search modal and now it is removed. it was added here because save search modal triggers when data.isadd.
        yield put(clearAllSearchTags());
        yield put(showHideSaveSearchNClearAll(true));
        yield put(getSavedSearchData({ navigation, isAdd: data.isAdd }));
        yield put(ccToastActions.addSuccessNotification(toastData));
        yield put(
          updateCurrentSavedSearchName(
            apiResponse.data.filter_name,
            apiResponse.data.filter_id
          )
        );
      } else {
        if (apiResponse.data.is_default)
          yield put(
            updateCurrentSavedSearchName(
              apiResponse.data.filter_name,
              apiResponse.data.filter_id
            )
          );
        // apiResponse.context =
        //   constant.API_RESPONSE_CONTEXT.UPDATE_ACTION_SAVED_SEARCH_DATA;
        let toastData =
          constant.API_RESPONSE_CONTEXT.UPDATE_ACTION_SAVED_SEARCH_DATA;
        if (data.dgId) {
          toastData = {
            messageId:
              constant.API_RESPONSE_CONTEXT.UPDATE_ACTION_SAVED_SEARCH_DATA,
            testId: `${data.dgId}_update`
          };
        }
        yield put(ccToastActions.addSuccessNotification(toastData));
      }
      apiResponse.successSaveSearch = true;
      yield put(addAPIresponse(apiResponse));
      if (apiResponse.data.is_default) {
        yield put(
          getAdvancedSearchOptions({
            type: actionType.GET_ADVANCED_SEARCH_OPTIONS,
            data: { payload: stateReducer.options }
          })
        );
      }
    }
    yield put({
      type: actionType.CLEAR_STATE_SELECTED_ROWS
    });
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put({
      type: actionType.CLEAR_STATE_SELECTED_ROWS
    });
  }
}

export function* updateSavedSearchDataCCDataGrid(data) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const ccDataGrid = yield select(getccDataGrid);
    let { ui = {} } = ccDataGrid[data.filter];
    const { selectedSavedSearch = {}, manageSelectedSavedSearch = {} } = ui;
    let navigation = stateReducer.secondaryNavigation;
    if (data.filter) {
      navigation = data.filter;
    }
    if (data.isAdd) {
      selectedSavedSearch.filter_name = data.saveSearchName;
      delete selectedSavedSearch.filter_id;
    }
    let filterType = constant.SAVED_SEARCH_FILTERS_KEYS_MAPPING[navigation];
    let userSearchData = data.isAdd
      ? _.cloneDeep(selectedSavedSearch)
      : _.cloneDeep(manageSelectedSavedSearch);
    // Checking source state filter type and remove VSB identifier to sendd BE suporrted source state filter
    // To fix Bug 1038751
    if (
      userSearchData &&
      Object.keys(userSearchData).includes(
        VSB_FILTER_ATTRIBUTES.SOURCE_STATE
      ) &&
      _.get(userSearchData, "source_state[0]").includes(
        VSB_IDENTIFIERS[VSB_FILTER_ATTRIBUTES.SOURCE_STATE]
      )
    ) {
      userSearchData[VSB_FILTER_ATTRIBUTES.SOURCE_STATE].forEach(
        (item, index) => {
          userSearchData[VSB_FILTER_ATTRIBUTES.SOURCE_STATE][
            index
          ] = item.replace(
            VSB_IDENTIFIERS[VSB_FILTER_ATTRIBUTES.SOURCE_STATE],
            ""
          );
        }
      );
    }
    if (_.get(userSearchData, "source_state[0]") === "vsb_active") {
      userSearchData.source_state[0] = "active";
    }
    userSearchData.blocked && userSearchData.blocked.length > 0
      ? (userSearchData.blocked = userSearchData.blocked.toString())
      : void 0;
    if (data.isAdd) {
      userSearchData.is_default = false;
    }
    const searchText = data.searchText;
    if (searchText) {
      const searchTextKey = constant.SEARCH_TEXT_MAPPING[navigation];
      userSearchData[searchTextKey] = searchText;
    }
    userSearchData.filter_type = filterType;
    userSearchData.organization_id = stateReducer.customerOrgId
      ? stateReducer.customerOrgId
      : loginReducer.organization_id;
    userSearchData.user_id = loginReducer.user_id;
    let finalFilterId = data.isAdd
      ? selectedSavedSearch.filter_id
      : manageSelectedSavedSearch.filter_id;
    const apiResponse = yield call(
      commonApi.updateSavedSearchData,
      data.isAdd,
      loginReducer.token,
      userSearchData,
      finalFilterId
    );
    if (
      apiResponse.status === constant.HTTP_STATUS.OK ||
      apiResponse.status === constant.HTTP_STATUS.CREATED
    ) {
      yield put({
        type: actionType.UPDATE_MANAGE_SAVE_SEARCH,
        data: apiResponse.data
      });
      yield put(
        ccDataGridActions.invokeManageSavedSearch(navigation, apiResponse.data)
      );
      if (data.isAdd) {
        yield put(getSavedSearchData({ navigation }));
        apiResponse.context =
          constant.API_RESPONSE_CONTEXT.ADD_SAVED_SEARCH_DATA;
        yield put(
          updateCurrentSavedSearchName(
            apiResponse.data.filter_name,
            apiResponse.data.filter_id
          )
        );
        // New one Added
        yield put(
          ccDataGridActions.invokeSelectedSavedSearch({
            ccDataGridId: navigation,
            filter_id: apiResponse.data.filter_id,
            filter_name: apiResponse.data.filter_name,
            data: apiResponse.data,
            defaultValue: false,
            init: false
          })
        );
      } else {
        if (apiResponse.data.is_default) {
          yield put(
            updateCurrentSavedSearchName(
              apiResponse.data.filter_name,
              apiResponse.data.filter_id
            )
          );
          yield put(ccDataGridActions.updatePage(navigation, 1, false));
          yield put(
            ccDataGridActions.invokeSelectedSavedSearch({
              ccDataGridId: navigation,
              filter_id: apiResponse.data.filter_id,
              filter_name: apiResponse.data.filter_name,
              data: apiResponse.data,
              defaultValue: true,
              init: false
            })
          );

          //This Block is ManagedSavedSearch ->  when Filter is Made Default
        }

        apiResponse.context =
          constant.API_RESPONSE_CONTEXT.UPDATE_ACTION_SAVED_SEARCH_DATA;
      }
      apiResponse.successSaveSearch = true;
      yield put(addAPIresponse(apiResponse));
      if (apiResponse.data.is_default) {
        yield put(
          getAdvancedSearchOptions({
            type: actionType.GET_ADVANCED_SEARCH_OPTIONS,
            data: { payload: stateReducer.options }
          })
        );
      }
    }
    yield put({
      type: actionType.CLEAR_STATE_SELECTED_ROWS
    });
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put({
      type: actionType.CLEAR_STATE_SELECTED_ROWS
    });
  }
}

const cancelAbleSaga = (saga, cancelAction) =>
  function*(...args) {
    yield race([call(saga, ...args), take(cancelAction)]);
  };

export function* getAllSavedSearchData(data) {
  try {
    yield put(defaultSearchConfigured(false));
    yield put(advancedSearchLoading());
    let userSearchList = [];
    let systemSearchList = [];
    let bothData;
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    let reqFromMonitorWidget =
      stateReducer && stateReducer.filterFromMonitorWidget;
    let navigation = stateReducer.secondaryNavigation
      ? stateReducer.secondaryNavigation
      : "";
    let organizationId = loginReducer.organization_id;
    let options = stateReducer.currentSearchOptions
      ? stateReducer.currentSearchOptions
      : [];
    if (data.payload.navigation) {
      navigation = data.payload.navigation;
    }
    let primeNavigation = navigation;
    navigation = util.getTypeByNavigation(navigation);
    if (data.payload.advancedSearchOptions) {
      options = data.payload.advancedSearchOptions;
    }

    if (
      data.payload.filterType &&
      data.payload.filterType !== stateReducer.primaryNavigation
    ) {
      navigation = data.payload.filterType;
    }
    if (getDGId(navigation) && !data?.payload?.isAdd) {
      yield put(startGridLoaderById(getDGId(navigation)));
    }

    if (navigation !== "sources") {
      systemSearchList = yield call(
        commonApi.getSystemSearchData,
        loginReducer.user_id,
        loginReducer.token,
        navigation,
        organizationId
      );

      userSearchList = yield call(
        commonApi.getDefaultSearchData,
        loginReducer.user_id,
        loginReducer.token,
        navigation,
        organizationId
      );
    } else if (navigation === "sources") {
      bothData = yield call(
        commonApi.getSystemAndDefaultSearchData,
        loginReducer.user_id,
        loginReducer.token,
        navigation,
        organizationId
      );
      let finalData =
        bothData.data && bothData.data.length > 0 ? bothData.data : [];
      if (finalData.length > 0) {
        for (let i = 0; i < finalData.length; i++) {
          if (finalData[i].filter_type.includes("global"))
            systemSearchList = finalData[i];
          else if (!finalData[i].filter_type.includes("global"))
            userSearchList = finalData[i];
        }
      }
    }

    if (
      navigation === "virtual_standbys" &&
      _.get(userSearchList, "data.length") > 0
    ) {
      userSearchList.data.forEach((ele, i) => {
        if (_.get(ele, "source_state[0]" === "active")) {
          userSearchList.data[i].source_state[0] = "vsb_active";
        }
      });
    }
    let markDefaultData = [],
      markPreviousSelectedSearch = [],
      defaultData = false;
    let selectedSavedSearchID = "";
    if (constant.CCDATAGRID_GET_DATA_FROM_SAVED_SEARCH.includes(navigation))
      selectedSavedSearchID = yield select(
        ccDataGridSelectors.getCCDataGridSelectedSavedSearchId(navigation)
      );
    if (
      userSearchList &&
      userSearchList.data &&
      userSearchList.data.length > 0
    ) {
      userSearchList.data.forEach(obj => {
        if (obj.is_default) {
          markDefaultData.push(obj);
        }
        if (
          selectedSavedSearchID.length > 0 &&
          obj.filter_id === selectedSavedSearchID
        ) {
          markPreviousSelectedSearch.push(obj);
        }
      });
    }
    if (markDefaultData.length > 0) {
      defaultData = true;
      yield put(defaultSearchConfigured(true));
      yield put(updateDefaultSavedSearch({ data: markDefaultData[0] }));
      yield put(
        updateCurrentSavedSearchName(
          markDefaultData[0].filter_name,
          markDefaultData[0].filter_id
        )
      ); //  user deselects a pre selected option
      const advancedSearchOptions = util.generateOptions(
        options,
        markDefaultData[0]
      );
      const searchText =
        markDefaultData[0][constant.SEARCH_TEXT_MAPPING[navigation]];
      if (advancedSearchOptions.length > 0) {
        const filter = primeNavigation;
        switch (navigation) {
          case constant.SECONDARY_NAVIGATION_TYPE.SOURCES:
          case constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES:
          case constant.SECONDARY_NAVIGATION_TYPE.MACHINES:
          case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES:
          case constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM:
          case constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS:
          case constant.SECONDARY_NAVIGATION_TYPE.SHARE_POINT:
          case constant.SECONDARY_NAVIGATION_TYPE.OFFICE_365:
          case constant.SECONDARY_NAVIGATION_TYPE.SHARE_FOLDER:
          case constant.SECONDARY_NAVIGATION_TYPE.DESTINATIONS:
          case constant.SECONDARY_NAVIGATION_TYPE.CLOUD_VOLUMES:
          case constant.SECONDARY_NAVIGATION_TYPE.DEDUPE_STORE:
          case constant.SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD:
          case constant.DESTINATION_TYPE.CLOUD_DEDUPE_STORE:
          case constant.DESTINATION_TYPE.CLOUD_DIRECT_VOLUME:
          case constant.SECONDARY_NAVIGATION_TYPE.POLICIES:
            if (
              ![
                constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
                constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
                constant.SECONDARY_NAVIGATION_TYPE.MACHINES,
                constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
                constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
                constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS,
                constant.SECONDARY_NAVIGATION_TYPE.POLICIES
              ].includes(navigation)
            ) {
              yield put(
                getSourcesList({ advancedSearchOptions, filter, searchText })
              );
            }

            break;
          case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_JOBS:
          case constant.SECONDARY_NAVIGATION_TYPE.JOBS:
            // yield put(
            //   getJobsList({
            //     advancedSearchOptions,
            //     filter,
            //     searchText
            //   })
            // );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.USER_ACCOUNTS:
            yield put(
              searchList({ advancedSearchOptions, filter, searchText })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.REPORTS:
            yield put(
              searchList({ advancedSearchOptions, filter, searchText })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT:
            yield put(
              searchList({ advancedSearchOptions, filter, searchText })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.BACKUP_JOB_REPORT:
            yield put(
              searchList({ advancedSearchOptions, filter, searchText })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT:
            yield put(
              searchList({ advancedSearchOptions, filter, searchText })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.RESTORE_JOB_REPORT:
            yield put(
              searchList({ advancedSearchOptions, filter, searchText })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT:
            yield put(
              searchList({ advancedSearchOptions, filter, searchText })
            );
            yield put(
              getDataTransferTrendSummary({
                advancedSearchOptions,
                filter,
                searchText
              })
            );
            yield put(
              getDataTransferTopSources({
                advancedSearchOptions,
                filter,
                searchText
              })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.POLICY_TASKS_REPORT:
            yield put(
              searchList({ advancedSearchOptions, filter, searchText })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT:
            yield put(
              searchList({ advancedSearchOptions, filter, searchText })
            );
            yield put(
              getCapacityUsageCDTrendUsage({
                advancedSearchOptions,
                filter,
                searchText
              })
            );
            yield put(
              getCapacityUsageCHTrendUsage({
                advancedSearchOptions,
                filter,
                searchText
              })
            );
            yield put(
              getCapacityUsageCHSavingTrend({
                advancedSearchOptions,
                filter,
                searchText
              })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_VMS:
            yield put(
              getCurrentActionCalled({
                advancedSearchOptions,
                filter,
                searchText
              })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_RESOURCES:
          case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBY:
            yield put(
              getCurrentActionCalled({
                advancedSearchOptions,
                filter,
                searchText
              })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.INSTANT_VMS_RR:
            yield put(
              getCurrentActionCalled({
                advancedSearchOptions,
                filter,
                searchText
              })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.ACCOUNT_DETAILS:
            yield put(
              getCurrentActionCalled({
                advancedSearchOptions,
                filter,
                searchText
              })
            );
            break;
          case constant.SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS:
            yield put(
              getCurrentActionCalled({
                advancedSearchOptions,
                filter,
                searchText
              })
            );
            break;
          default:
            break;
        }
      }
    } else if (constant.DEFAULT_SEARCH_PAGES.indexOf(navigation) > -1) {
      if (!reqFromMonitorWidget) {
        let payload = { filter: navigation };
        // if (navigation === "sources") payload.advancedSearchOptions = options;
        if (
          !constant.CCDATAGRID_GET_DATA_FROM_SAVED_SEARCH.includes(navigation)
        ) {
          yield put(getCurrentActionCalled(payload));
        }
      }
    }

    if (true) {
      yield put(
        updateSearchFiltersData({
          systemSearchList:
            systemSearchList &&
            systemSearchList.data &&
            systemSearchList.data.length > 0
              ? systemSearchList.data
              : [],
          userSearchList:
            userSearchList &&
            userSearchList.data &&
            userSearchList.data.length > 0
              ? userSearchList.data
              : []
        })
      );
      if (
        [
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS,
          constant.SECONDARY_NAVIGATION_TYPE.POLICIES
        ].includes(navigation)
      ) {
        let idToSend = navigation;
        let dataToSend = {
          systemSearchList:
            systemSearchList &&
            systemSearchList.data &&
            systemSearchList.data.length > 0
              ? systemSearchList.data
              : [],
          userSearchList:
            userSearchList &&
            userSearchList.data &&
            userSearchList.data.length > 0
              ? userSearchList.data
              : []
        };
        yield put(ccDataGridActions.invokeCCSavedSearch(idToSend, dataToSend));
        let initValue = data.payload.init ? data.payload.init : false;
        let defaultDataSelected = defaultData ? defaultData : false;
        let filter_id_ =
            markDefaultData.length > 0 ? markDefaultData[0].filter_id : "",
          filter_name_ =
            markDefaultData.length > 0 ? markDefaultData[0].filter_name : "";
        if (data.payload.init) {
          const isAdvancedSearchOptionsSelected = yield select(
            ccDataGridSelectors.getAdvancedSearchOptionsSelected(navigation)
          );
          const selectedSavedSearch = yield select(
            ccDataGridSelectors.getCCDataGridSelectedSavedSearch(navigation)
          );
          // if init and advancedSearch not selected, then it will execute this inside this, it sends parameters either it could be defaultsearch and savedsearch (ifseelcted)
          if (
            markDefaultData.length > 0 &&
            markDefaultData[0].filter_id !==
              markPreviousSelectedSearch?.[0]?.filter_id
          ) {
            yield put(ccDataGridActions.updatePage(navigation, 1, false));
          }
          !isAdvancedSearchOptionsSelected ||
          markPreviousSelectedSearch.length > 0
            ? yield put(
                ccDataGridActions.invokeSelectedSavedSearch({
                  ccDataGridId: navigation,
                  filter_id:
                    markPreviousSelectedSearch.length > 0
                      ? selectedSavedSearch?.filter_id
                      : filter_id_,
                  filter_name:
                    markPreviousSelectedSearch.length > 0
                      ? selectedSavedSearch?.filter_name
                      : filter_name_,
                  data:
                    markPreviousSelectedSearch.length > 0
                      ? markPreviousSelectedSearch[0]
                      : markDefaultData[0],
                  defaultValue:
                    markPreviousSelectedSearch.length > 0
                      ? true
                      : defaultDataSelected,
                  init: data.payload.init ? data.payload.init : false
                })
              )
            : yield put(
                ccDataGridActions.invokeSelectedSavedSearch({
                  ccDataGridId: navigation,
                  filter_id: "",
                  filter_name: "",
                  data: {},
                  defaultValue: false,
                  init: data.payload.init ? data.payload.init : false
                })
              );
        }

        // yield put(ccDataGridActions.invokeCCSavedSearch(
        //           idToSend,
        //           dataToSend
        //         ));
        //   yield* updateSelectedSavedSearch({
        //   ccDataGridId: navigation,
        //   filter_id: markDefaultData[0].filter_id,
        //   filter_name: markDefaultData[0].filter_name,
        //   data: markDefaultData[0],
        //   default: true,
        //   init: data.payload.init ? data.payload.init : false
        // });
      }
    }

    if (reqFromMonitorWidget) {
      yield put(
        updateAdvancedSearchOptions(stateReducer.filterFromMonitorWidgetData)
      );
      yield put(updateTagsForCurrentSearch(true));
      yield put(resetFilterFromMonitorWidget());
    } else if (
      (stateReducer.optionsForTags &&
        Object.keys(stateReducer.optionsForTags).length > 0 &&
        stateReducer.pageReloaded) ||
      (stateReducer.optionsForTags &&
        Object.keys(stateReducer.optionsForTags).length > 0 &&
        navigation === "instant_vms_rr") ||
      (stateReducer.searchOption.selectedOption &&
        stateReducer.searchOption.selectedOption.length > 0 &&
        navigation === "instant_vms_rr")
    ) {
      yield put(showCurrentSearch(true));
    } else {
      yield put(showCurrentSearch(false));
    }
    yield put(advancedSearchLoaded());
    yield put(doNotInitializeGraph(false));
    // The below line was added, because after saved search api is completed, it is still show loding. this was happening when save search is created, it call save search modal which further calls the savedsearchdata api, loading is started but it is not stopped
    if (_.get(data, "payload.isAdd", false) === true) {
      yield put(stopGridLoaderById(getDGId(navigation)));
    }
    return markDefaultData.length > 0 && markDefaultData[0];
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSelectedSavedSearch(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    let navigation = stateReducer.secondaryNavigation;
    let organizationId = loginReducer.organization_id;
    if (action.data.filterType) {
      navigation = action.data.filterType;
    }
    navigation = util.getTypeByNavigation(navigation);
    const userSearchData = yield call(
      commonApi.fetchSelectedSaved,
      loginReducer.token,
      action.data.filterId,
      `organization_id=${organizationId}&filter_type=${constant.SAVED_SEARCH_FILTERS_KEYS_MAPPING[navigation]}&user_id=${loginReducer.user_id}`
    );
    const data = userSearchData.data;
    if (data.is_deleted) {
      data.is_deleted = isDeletedApiConvert(data.is_deleted);
    }
    if (
      data &&
      Object.keys(data).includes(VSB_FILTER_ATTRIBUTES.SOURCE_STATE)
    ) {
      data[VSB_FILTER_ATTRIBUTES.SOURCE_STATE].forEach((item, index) => {
        data[VSB_FILTER_ATTRIBUTES.SOURCE_STATE][index] = `${
          VSB_IDENTIFIERS[VSB_FILTER_ATTRIBUTES.SOURCE_STATE]
        }${item}`;
      });
    }
    yield put(updateCurrentSavedSearch({ data }));
    yield put(
      ccDataGridActions.invokeManageSavedSearchOptions(
        stateReducer.secondaryNavigation,
        data,
        stateReducer.organization_type,
        stateReducer.isImpersonationView
      )
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* removeSearchTagsAndReload(data) {
  if (data.data.item === "name" || data.data.index === "name") {
    yield put.resolve(removeSearchTextTag(data));
  } else {
    yield put.resolve(removeSearchTags(data));
  }
}

export function* clearSearchTagsAndReload() {
  yield put.resolve(clearAllSearchTags());
}

export function* getSearchByDefaultSavedSeach() {
  try {
    const stateReducer = yield select(getReducer);
    const advancedSearchOptions = stateReducer.currentSearchOptions;
    const searchText = stateReducer.searchText;
    yield put(searchList({ advancedSearchOptions, searchText }));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get the Advanced search options from API
 */
function* getAdvancedSearchOptionsData(payload) {
  try {
    const stateReducer = yield select(getReducer);
    const entitlements = yield select(getEntitlementsProducts);
    const stateLogin = yield select(getLogin);
    const isUDP = _.get(entitlements, "udp.enable");
    const locale = stateLogin.locale;
    let reqFromMonitorWidget = stateReducer && stateReducer.fromMonitorPage;
    if (reqFromMonitorWidget) {
      yield put(resetAdvanceSearchData());
      yield put(setFilterFromMonitorWidget(stateReducer.advanceSearchData));
    } else {
      yield put(resetFilterFromMonitorWidget());
    }
    let organizationId = stateLogin.organization_id;
    let rpsServerId = _.get(payload, "data.rpsServerId", "");
    let firstFetch = _.get(payload, "data.firstFetch", false);
    let renderSourceGroupFlag =
      stateLogin.organization_type === "direct" ||
      stateLogin.organization_type === "msp_child" ||
      (stateLogin.organization_type === "msp" &&
        stateReducer.isImpersonationView);

    let navigation = stateReducer.secondaryNavigation;
    if (payload.data && payload.data.filter) {
      navigation = payload.data.filter;
    }
    let advanceSearchData = [];
    let sourceGroups = [];
    let sourceGroupOptions = {};
    let organizationsData = [];
    let organizationsDataOptions = {};
    let destinationOptions = {};
    let generatedFrom = [];
    let generatedFromOptions = {};
    let protectionPolicyOptions = {};
    let sites = [];
    let siteOptions = {};

    let manageReportSchedulesAdvanceSearchDataSetsArrData = Object.assign(
      [],
      reportsManageReportSchedulesAdvanceSearchDataSets(stateLogin.role_id)
    );

    let sourcesAdvanceSearchDataSetsArrData = Object.assign(
      [],
      sourcesAdvanceSearchDataSets(locale)
    );
    let sourceAdvanceSearchDataSetsArrData = Object.assign(
      [],
      sourceAdvanceSearchDataSets
    );
    let policySourceAdvanceSearchDataSetsArrData = Object.assign(
      [],
      policySourceAdvanceSearchDataSets
    );
    let virtualStandbyDataSetsArrData = Object.assign(
      [],
      virtualStandbyDataSets
    );
    let destinationAdvanceSearchDataSetsArrData = Object.assign(
      [],
      destinationAdvanceSearchDataSets
    );
    let policiesAdvanceSearchDataSetsArrData = Object.assign(
      [],
      policiesAdvanceSearchDataSets
    );
    let recoveryPointsAdvanceSearchDataSetsArrData = Object.assign(
      [],
      recoveryPointsAdvanceSearchDataSets
    );
    let jobsAdvanceSearchDataSetsArrData = Object.assign(
      [],
      jobsAdvanceSearchDataSets(locale, isUDP)
    );
    let joblogAdvanceSearchDataSetsArrData = Object.assign(
      [],
      joblogAdvanceSearchDataSets
    );
    let logAdvanceSearchDataSetsArrData = Object.assign(
      [],
      logAdvanceSearchDataSets(locale, isUDP)
    );
    let reportsAdvanceSearchDataSetsArrData = Object.assign(
      [],
      reportsAdvanceSearchDataSets
    );
    let reportsSourceProtectionAdvanceSearchDataSetsArrData = Object.assign(
      [],
      reportsSourceProtectionAdvanceSearchDataSets(locale)
    );
    let reportsDetailsAdvanceSearchDataSetsArrData = Object.assign(
      [],
      reportsDetailsAdvanceSearchDataSets
    );
    let reportsBackupJobsAdvanceSearchDataSetsArrData = Object.assign(
      [],
      reportsBackupJobsAdvanceSearchDataSets
    );
    let reportsRecoveryPointAdvanceSearchDataSetsArrData = Object.assign(
      [],
      reportsRecoveryPointAdvanceSearchDataSets
    );
    let reportsRestoreJobsAdvanceSearchDataSetsArrData = Object.assign(
      [],
      reportsRestoreJobsAdvanceSearchDataSets
    );
    let reportsDataTransferAdvanceSearchDataSetsArrData = Object.assign(
      [],
      reportsDataTransferAdvanceSearchDataSets
    );
    let recoveredVMSAdvanceSearchDataSetsArrData = Object.assign(
      [],
      recoveredVMSAdvanceSearchDataSets
    );
    let recoveredResourcesAdvanceSearchDataSetsArrData = Object.assign(
      [],
      recoveredResourcesAdvanceSearchDataSets
    );
    let instantVMsAdvanceSearchDataSetsArrData = Object.assign(
      [],
      instantVMsAdvanceSearchDataSets
    );
    let virtualStandbyAdvanceSearchDataSetsArrData = Object.assign(
      [],
      virtualStandbysDataSets
    );
    // let usersAdvanceSearchDataSetsArrData = Object.assign(
    //   [],
    //   usersAdvanceSearchDataSets
    // );
    let reportsCapacityUsageAdvanceSearchDataSetsArrData = Object.assign(
      [],
      reportsCapacityUsageAdvanceSearchDataSets
    );
    let reportsJobsAndDestinationsAdvanceSearchDataSetsArrData = Object.assign(
      [],
      reportsJobsAndDestinationsAdvanceSearchDataSets
    );
    let reportsAccountDetailsAdvanceSearchDataSetsArrData = Object.assign(
      [],
      accountDetailsReportAdvanceSearchDataSets
    );
    let customerAccountAdvanceSearchDataSetsArrData = Object.assign(
      [],
      customerAccountAdvanceSearchDataSets
    );
    let tempSourceGroups,
      tempgeneratedfrom,
      tempOrganizations,
      tempSiteOptions,
      fetchAllAdvancedSearchData;
    let defaultSearch = false; //If there is a default search the list will be retrieved again in showAdvancedSearchOptions
    switch (navigation) {
      case constant.SECONDARY_NAVIGATION_KEYS.DATA_STORE:
      case constant.ADVANCED_SEARCH_OPTIONS.RPS_DATA_STORES:
        let token = stateLogin.token;
        let options = [];
        let apiCalls = [];
        yield put(startLoader());
        try {
          apiCalls = [
            call(destinationApis.getPolicies, organizationId, 1, 100, token),
            call(
              protectApi.getSites,
              1,
              100,
              organizationId,
              token,
              true,
              "is_registered=true"
            )
          ];
          apiCalls.push(
            call(destinationApis.getRPSList, organizationId, 1, 100, token)
          );

          let [policyResp, sitesResp, rpsResp] = yield all(apiCalls);

          // RPS Loading
          let rpsOption = util.makeAdvanceSearchOptions(
            options,
            rpsResp.data,
            "rps",
            "rps_server_id",
            "server_name",
            "server_id"
          );
          options.push(rpsOption);
          // Policies Loading
          let policiesOption = util.makeAdvanceSearchOptions(
            options,
            policyResp.data,
            "policy",
            "policy_id",
            "policy_name",
            "policy_id"
          );
          options.push(policiesOption);
          //Sites Loading
          let sitesOption = util.makeAdvanceSearchOptions(
            options,
            sitesResp.data.filter(site => site.site_type !== "remote_site"),
            "site",
            "site_id",
            "site_name",
            "site_id"
          );
          options.push(sitesOption);
          // options.push(dataStoreStatus); // ref: Bug 932583 , remove datastore status filter from UI
        } catch (e) {
          console.log(e);
        }
        advanceSearchData = options;
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.SOURCES:
      case constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES:
      case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES:
        defaultSearch = true;
      //eslint-disable-next-line no-fallthrough
      case constant.SECONDARY_NAVIGATION_TYPE.MACHINES:
      case constant.SECONDARY_NAVIGATION_TYPE.SHARE_POINT:
      case constant.SECONDARY_NAVIGATION_TYPE.OFFICE_365:
      case constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM:
      case constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS:
      case constant.SECONDARY_NAVIGATION_TYPE.SHARE_FOLDER:
      case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_POLICY:
      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_TARGETS:
        if (renderSourceGroupFlag) {
          if (navigation !== "sources") {
            tempSourceGroups = yield call(
              protectApi.fetchSourcesGroups,
              `organization_id=${organizationId}&pagination=false`,
              stateLogin.token,
              false,
              false
            );
            if (constant.HTTP_STATUS.OK === tempSourceGroups.status) {
              yield put(
                updateSourceGroupsForAdvanceSearch(tempSourceGroups.data)
              );
              sourceGroups = tempSourceGroups.data;
            }
          } else if (navigation === "sources") {
            fetchAllAdvancedSearchData = yield call(
              protectApi.fetchAllSourceAdvancedSearchAPI,
              `organization_id=${organizationId}&pagination=false`,
              stateLogin.token,
              false,
              false
            );
            if (constant.HTTP_STATUS.OK === fetchAllAdvancedSearchData.status) {
              let fetchedSourceGroups =
                fetchAllAdvancedSearchData.data &&
                fetchAllAdvancedSearchData.data.source_groups &&
                fetchAllAdvancedSearchData.data.source_groups.length > 0
                  ? fetchAllAdvancedSearchData.data.source_groups
                  : [];
              let fetchedSites =
                fetchAllAdvancedSearchData.data &&
                fetchAllAdvancedSearchData.data.sites &&
                fetchAllAdvancedSearchData.data.sites.length > 0
                  ? fetchAllAdvancedSearchData.data.sites
                  : [];
              let fetchedProtectionPolices =
                fetchAllAdvancedSearchData.data &&
                fetchAllAdvancedSearchData.data.policies &&
                fetchAllAdvancedSearchData.data.policies.length > 0
                  ? fetchAllAdvancedSearchData.data.policies
                  : [];
              yield put(
                updateSourceGroupsForAdvanceSearch(fetchedSourceGroups)
              );
              sourceGroups = fetchedSourceGroups;
              yield put(updateSitesForAdvanceSearch(fetchedSites));
              sites = fetchedSites;
              yield put(
                updatePoliciesForAdvanceSearch(fetchedProtectionPolices)
              );
              protectionPolicyOptions = util.makeAdvanceSearchOptionsArray(
                sourcesAdvanceSearchDataSetsArrData,
                fetchedProtectionPolices,
                constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.PROTECTION_POLICY
                  .NAME,
                constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.PROTECTION_POLICY.KEY,
                constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.PROTECTION_POLICY
                  .FIELD
              );
            }
          }
        }

        if (navigation !== constant.SECONDARY_NAVIGATION_TYPE.SOURCE_POLICY) {
          if (renderSourceGroupFlag) {
            sourceGroupOptions = util.makeAdvanceSearchOptionsArray(
              sourcesAdvanceSearchDataSetsArrData,
              sourceGroups,
              constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.NAME,
              constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.KEY,
              constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.FIELD
            );
            if (sourceGroupOptions !== null) {
              sourcesAdvanceSearchDataSetsArrData.unshift(sourceGroupOptions);
            }
          }
          if (navigation !== "sources") {
            [tempSiteOptions, protectionPolicyOptions] = yield all([
              call(
                protectApi.getRegisteredSites,
                `organization_id=${organizationId}&is_registered=true&pagination=false&site_type=gateway%7ccloud_direct`,
                stateLogin.token,
                false
              ),
              fetchProtectionPolicyOptions(sourceAdvanceSearchDataSetsArrData)
            ]);
            if (constant.HTTP_STATUS.OK === tempSiteOptions.status) {
              yield put(updateSitesForAdvanceSearch(tempSiteOptions.data));
              sites = tempSiteOptions.data;
            }
          }
          // This will be used in AddSources Modals Site dropdown
          yield put(loadRegisteredSitesSuccess(sites));
          if (sites.length > 1) {
            siteOptions = util.makeAdvanceSearchOptionsArray(
              sourcesAdvanceSearchDataSetsArrData,
              sites,
              constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SITES.NAME,
              constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SITES.KEY,
              constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SITES.FIELD
            );
            if (siteOptions !== null) {
              sourcesAdvanceSearchDataSetsArrData.unshift(siteOptions);
            }
          }

          if (protectionPolicyOptions !== null) {
            sourcesAdvanceSearchDataSetsArrData.unshift(
              protectionPolicyOptions
            );
          }
          advanceSearchData = sourcesAdvanceSearchDataSetsArrData;
        } else {
          if (renderSourceGroupFlag) {
            sourceGroupOptions = util.makeAdvanceSearchOptionsArray(
              sourceAdvanceSearchDataSetsArrData,
              sourceGroups,
              constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.NAME,
              constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.KEY,
              constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.FIELD
            );
            sourceGroupOptions &&
              policySourceAdvanceSearchDataSetsArrData.push(sourceGroupOptions);
          }
          advanceSearchData = policySourceAdvanceSearchDataSetsArrData;
        }
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBYS:
      case constant.SECONDARY_NAVIGATION_TYPE.INSTANT_VMS:
        advanceSearchData = virtualStandbyDataSetsArrData;
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.DESTINATIONS:
      case constant.SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD:
      case constant.SECONDARY_NAVIGATION_TYPE.CLOUD_VOLUMES:
      case constant.SECONDARY_NAVIGATION_TYPE.DEDUPE_STORE:
        advanceSearchData = yield* getAdvancedSearchOptionsDestinations(
          destinationAdvanceSearchDataSetsArrData
        );
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.POLICIES:
        if (renderSourceGroupFlag) {
          sourceGroupOptions = yield* fetchSourceGroupOptions(
            policiesAdvanceSearchDataSetsArrData
          );
          if (sourceGroupOptions !== null) {
            policiesAdvanceSearchDataSetsArrData.push(sourceGroupOptions);
          }
        }
        advanceSearchData = policiesAdvanceSearchDataSetsArrData;
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINTS:
        if (
          payload.data &&
          payload.data.subFilter &&
          payload.data.subFilter !==
            CD_RECOVERY_POINTS_COLUMN_TYPE.RECOVERY_POINT_CV
        ) {
          destinationOptions = yield* fetchDestinationOptions(
            recoveryPointsAdvanceSearchDataSetsArrData
          );
          if (destinationOptions !== null) {
            recoveryPointsAdvanceSearchDataSetsArrData.push(destinationOptions);
          }
        }
        advanceSearchData = recoveryPointsAdvanceSearchDataSetsArrData;
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_JOBS:
      case constant.SECONDARY_NAVIGATION_TYPE.RPS_JOBS:
      case constant.SECONDARY_NAVIGATION_TYPE.DATA_STORE_JOBS:
        protectionPolicyOptions = yield* fetchProtectionPolicyOptions(
          jobsAdvanceSearchDataSetsArrData
        );
        if (protectionPolicyOptions !== null) {
          jobsAdvanceSearchDataSetsArrData.push(protectionPolicyOptions);
        }

        if (
          stateLogin.organization_type === "msp" &&
          stateReducer.isImpersonationView === false
        ) {
          tempOrganizations = yield call(
            protectApi.fetchCustomerAccountsResult,
            `pagination=false`,
            stateLogin.token,
            organizationId
          );
          if (constant.HTTP_STATUS.OK === tempOrganizations.status) {
            organizationsData = tempOrganizations.data.filter(
              option => !option.sub_msp
            );
          }
          organizationsDataOptions = util.makeAdvanceSearchOptionsArray(
            jobsAdvanceSearchDataSetsArrData,
            organizationsData,
            constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.NAME,
            constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.KEY,
            constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.FIELD
          );
          if (organizationsDataOptions !== null) {
            jobsAdvanceSearchDataSetsArrData.push(organizationsDataOptions);
          }
        }

        advanceSearchData = jobsAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.JOBS:
        // protectionPolicyOptions = yield* fetchProtectionPolicyOptions(
        //   jobsAdvanceSearchDataSetsArrData
        // );
        // if (protectionPolicyOptions !== null) {
        //   jobsAdvanceSearchDataSetsArrData.push(protectionPolicyOptions);
        // }
        // if (
        //   (stateLogin.organization_type === "msp" &&
        //     stateLogin.main_organization_type === "csr") ||
        //   (stateLogin.organization_type === "msp" &&
        //     stateReducer.isImpersonationView === false)
        // ) {
        //   organizationsDataOptions = yield* fetchOrganizationOptions(
        //     jobsAdvanceSearchDataSetsArrData
        //   );
        //   if (organizationsDataOptions !== null) {
        //     jobsAdvanceSearchDataSetsArrData.push(organizationsDataOptions);
        //   }
        // }
        // if (
        //   stateLogin.organization_type === "direct" ||
        //   stateLogin.organization_type === "msp_child"
        // ) {
        //   sourceGroupOptions = yield* fetchSourceGroupOptions(
        //     jobsAdvanceSearchDataSetsArrData
        //   );
        //   if (sourceGroupOptions !== null) {
        //     jobsAdvanceSearchDataSetsArrData.push(sourceGroupOptions);
        //   }
        // }
        // advanceSearchData = jobsAdvanceSearchDataSetsArrData;
        //  yield* loadJob("ADVANCED_SEARCH_TRIGGERED");
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.JOBLOG:
        advanceSearchData = joblogAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_LOG:
      case constant.SECONDARY_NAVIGATION_TYPE.RPS_LOG:
      case constant.SECONDARY_NAVIGATION_TYPE.DATA_STORE_LOG:
        advanceSearchData = logAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.LOG:
        tempgeneratedfrom = yield call(
          analyzeApi.getGeneratedFromList,
          `organization_id=${organizationId}&pagination=false`,
          stateLogin.token
        );
        if (constant.HTTP_STATUS.OK === tempgeneratedfrom.status) {
          yield put(updatePoliciesForAdvanceSearch(tempgeneratedfrom.data));
          generatedFrom = tempgeneratedfrom.data;
        }
        generatedFromOptions = util.makeAdvanceSearchOptionsArray(
          logAdvanceSearchDataSetsArrData,
          generatedFrom,
          constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.GENERATED_FROM.NAME,
          constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.GENERATED_FROM.KEY,
          constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.GENERATED_FROM.FIELD
        );
        if (generatedFromOptions !== null) {
          logAdvanceSearchDataSetsArrData.push(generatedFromOptions);
        }
        advanceSearchData = logAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.USER_ACCOUNTS:
        // organizationId = stateLogin.organization_id;
        // apiResponse = yield call(
        //   configureApi.getUserRoles,
        //   `organization_id=${organizationId}&pagination=false`,
        //   stateLogin.token
        // );
        // if (constant.HTTP_STATUS.OK === apiResponse.status) {
        //   //  update data in configureReducer on success
        //   userRolesOptions = util.makeAdvanceSearchOptionsArrayTemp(
        //     usersAdvanceSearchDataSetsArrData,
        //     apiResponse.data,
        //     constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.USER_ROLES.NAME,
        //     constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.USER_ROLES.KEY,
        //     constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.USER_ROLES.FIELD
        //   );
        //   if (userRolesOptions) {
        //     usersAdvanceSearchDataSetsArrData.push(userRolesOptions);
        //   }
        //   yield put({
        //     type: actionType.UPDATE_USER_ROLES,
        //     data: apiResponse.data
        //   });
        //   advanceSearchData = usersAdvanceSearchDataSetsArrData;
        // }
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.REPORTS:
        /*if (renderSourceGroupFlag) {
          tempSourceGroups = yield call(
            protectApi.fetchSourcesGroups,
            `organization_id=${organizationId}&pagination=false`,
            stateLogin.token
          );
          if (constant.HTTP_STATUS.OK === tempSourceGroups.status) {
            yield put(
              updateSourceGroupsForAdvanceSearch(tempSourceGroups.data)
            );
            sourceGroups = tempSourceGroups.data;
          }
          sourceGroupOptions = util.makeAdvanceSearchOptionsArray(
            reportsAdvanceSearchDataSetsArrData,
            sourceGroups,
            constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.NAME,
            constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.KEY,
            constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.FIELD
          );
          if (sourceGroupOptions !== null) {
            reportsAdvanceSearchDataSetsArrData.push(sourceGroupOptions);
          }
        }*/
        defaultSearch = true;
        advanceSearchData = reportsAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.REPORT_DETAIL:
        protectionPolicyOptions = yield* fetchProtectionPolicyOptions(
          reportsDetailsAdvanceSearchDataSetsArrData
        );
        if (protectionPolicyOptions !== null) {
          reportsDetailsAdvanceSearchDataSetsArrData.push(
            protectionPolicyOptions
          );
        }
        destinationOptions = yield* fetchDestinationOptions(
          reportsDetailsAdvanceSearchDataSetsArrData
        );
        if (destinationOptions !== null) {
          reportsDetailsAdvanceSearchDataSetsArrData.push(destinationOptions);
        }

        if (renderSourceGroupFlag) {
          sourceGroupOptions = yield* fetchSourceGroupOptions(
            reportsDetailsAdvanceSearchDataSetsArrData
          );
          if (sourceGroupOptions !== null) {
            reportsDetailsAdvanceSearchDataSetsArrData.push(sourceGroupOptions);
          }
        }
        advanceSearchData = reportsDetailsAdvanceSearchDataSetsArrData;
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.POLICY_TASKS_REPORT:
      case constant.SECONDARY_NAVIGATION_TYPE.BACKUP_JOB_REPORT:
        defaultSearch = true;
        protectionPolicyOptions = yield* fetchProtectionPolicyOptions(
          reportsBackupJobsAdvanceSearchDataSetsArrData
        );
        if (protectionPolicyOptions !== null) {
          reportsBackupJobsAdvanceSearchDataSetsArrData.push(
            protectionPolicyOptions
          );
        }

        if (stateLogin.organization_type === "msp") {
          organizationsDataOptions = yield* fetchOrganizationOptions(
            reportsBackupJobsAdvanceSearchDataSetsArrData
          );
          if (organizationsDataOptions !== null) {
            reportsBackupJobsAdvanceSearchDataSetsArrData.push(
              organizationsDataOptions
            );
          }
        } else {
          destinationOptions = yield* fetchDestinationOptions(
            reportsBackupJobsAdvanceSearchDataSetsArrData
          );
          if (destinationOptions !== null) {
            reportsBackupJobsAdvanceSearchDataSetsArrData.push(
              destinationOptions
            );
          }
        }

        if (
          stateLogin.organization_type === "direct" ||
          stateLogin.organization_type === "msp_child"
        ) {
          sourceGroupOptions = yield* fetchSourceGroupOptions(
            reportsBackupJobsAdvanceSearchDataSetsArrData
          );
          if (sourceGroupOptions !== null) {
            reportsBackupJobsAdvanceSearchDataSetsArrData.push(
              sourceGroupOptions
            );
          }
        }
        advanceSearchData = reportsBackupJobsAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT:
        defaultSearch = true;
        if (stateLogin.organization_type === "msp") {
          organizationsDataOptions = yield* fetchOrganizationOptions(
            reportsSourceProtectionAdvanceSearchDataSetsArrData
          );
          if (organizationsDataOptions !== null) {
            reportsSourceProtectionAdvanceSearchDataSetsArrData.push(
              organizationsDataOptions
            );
          }
        } else {
          protectionPolicyOptions = yield* fetchProtectionPolicyOptions(
            reportsSourceProtectionAdvanceSearchDataSetsArrData
          );
          if (protectionPolicyOptions !== null) {
            reportsSourceProtectionAdvanceSearchDataSetsArrData.push(
              protectionPolicyOptions
            );
          }
        }

        if (
          stateLogin.organization_type === "direct" ||
          stateLogin.organization_type === "msp_child"
        ) {
          sourceGroupOptions = yield* fetchSourceGroupOptions(
            reportsSourceProtectionAdvanceSearchDataSetsArrData
          );
          if (sourceGroupOptions !== null) {
            reportsSourceProtectionAdvanceSearchDataSetsArrData.push(
              sourceGroupOptions
            );
          }
        }
        advanceSearchData = reportsSourceProtectionAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT:
        defaultSearch = true;
        if (stateLogin.organization_type === "msp") {
          organizationsDataOptions = yield* fetchOrganizationOptions(
            reportsRecoveryPointAdvanceSearchDataSetsArrData
          );
          if (organizationsDataOptions !== null) {
            reportsRecoveryPointAdvanceSearchDataSetsArrData.push(
              organizationsDataOptions
            );
          }
        } else {
          protectionPolicyOptions = yield* fetchProtectionPolicyOptions(
            reportsRecoveryPointAdvanceSearchDataSetsArrData
          );
          if (protectionPolicyOptions !== null) {
            reportsRecoveryPointAdvanceSearchDataSetsArrData.push(
              protectionPolicyOptions
            );
          }
        }

        if (
          stateLogin.organization_type === "direct" ||
          stateLogin.organization_type === "msp_child"
        ) {
          sourceGroupOptions = yield* fetchSourceGroupOptions(
            reportsRecoveryPointAdvanceSearchDataSetsArrData
          );
          if (sourceGroupOptions !== null) {
            reportsRecoveryPointAdvanceSearchDataSetsArrData.push(
              sourceGroupOptions
            );
          }
        }
        advanceSearchData = reportsRecoveryPointAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.RESTORE_JOB_REPORT:
        defaultSearch = true;
        destinationOptions = yield* fetchDestinationOptions(
          reportsRestoreJobsAdvanceSearchDataSetsArrData
        );
        if (destinationOptions !== null) {
          reportsRestoreJobsAdvanceSearchDataSetsArrData.push(
            destinationOptions
          );
        }
        if (
          stateLogin.organization_type === "msp" &&
          stateReducer.isImpersonationView === false
        ) {
          organizationsDataOptions = yield* fetchOrganizationOptions(
            reportsRestoreJobsAdvanceSearchDataSetsArrData
          );
          if (organizationsDataOptions !== null) {
            reportsRestoreJobsAdvanceSearchDataSetsArrData.push(
              organizationsDataOptions
            );
          }
        }
        if (
          stateLogin.organization_type === "direct" ||
          stateLogin.organization_type === "msp_child"
        ) {
          sourceGroupOptions = yield* fetchSourceGroupOptions(
            reportsRestoreJobsAdvanceSearchDataSetsArrData
          );
          if (sourceGroupOptions !== null) {
            reportsRestoreJobsAdvanceSearchDataSetsArrData.push(
              sourceGroupOptions
            );
          }
        }
        advanceSearchData = reportsRestoreJobsAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT:
        defaultSearch = true;
        /*if (renderSourceGroupFlag) {
          tempSourceGroups = yield call(
            protectApi.fetchSourcesGroups,
            `organization_id=${organizationId}&pagination=false`,
            stateLogin.token
          );
          if (constant.HTTP_STATUS.OK === tempSourceGroups.status) {
            yield put(
              updateSourceGroupsForAdvanceSearch(tempSourceGroups.data)
            );
            sourceGroups = tempSourceGroups.data;
          }
          sourceGroupOptions = util.makeAdvanceSearchOptionsArray(
            reportsDataTransferAdvanceSearchDataSetsArrData,
            sourceGroups,
            constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.NAME,
            constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.KEY,
            constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.FIELD
          );
          if (sourceGroupOptions !== null) {
            reportsDataTransferAdvanceSearchDataSetsArrData.push(
              sourceGroupOptions
            );
          }
        }*/
        advanceSearchData = reportsDataTransferAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.JOBS_AND_DESTINATIONS_REPORT:
        destinationOptions = yield* fetchDestinationOptions(
          reportsJobsAndDestinationsAdvanceSearchDataSetsArrData
        );
        if (destinationOptions !== null) {
          reportsJobsAndDestinationsAdvanceSearchDataSetsArrData.push(
            destinationOptions
          );
        }
        advanceSearchData = reportsJobsAndDestinationsAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT:
        defaultSearch = true;

        if (stateLogin.organization_type === "msp") {
          organizationsDataOptions = yield* fetchOrganizationOptions(
            reportsCapacityUsageAdvanceSearchDataSetsArrData
          );
          if (organizationsDataOptions !== null) {
            reportsCapacityUsageAdvanceSearchDataSetsArrData.push(
              organizationsDataOptions
            );
          }
        } else {
          destinationOptions = yield* fetchDestinationOptions(
            reportsCapacityUsageAdvanceSearchDataSetsArrData
          );
          if (destinationOptions !== null) {
            reportsCapacityUsageAdvanceSearchDataSetsArrData.push(
              destinationOptions
            );
          }
        }
        advanceSearchData = reportsCapacityUsageAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.MANAGE_REPORT_SCHEDULES:
        advanceSearchData = manageReportSchedulesAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.ACCOUNT_DETAILS:
        if (stateLogin.organization_type === "msp") {
          organizationsDataOptions = yield* fetchOrganizationOptions(
            reportsBackupJobsAdvanceSearchDataSetsArrData
          );
          if (organizationsDataOptions !== null) {
            reportsAccountDetailsAdvanceSearchDataSetsArrData.unshift(
              organizationsDataOptions
            );
          }
        }
        advanceSearchData = reportsAccountDetailsAdvanceSearchDataSetsArrData;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.ACCOUNT_ACTIVITY_REPORT:
        advanceSearchData = reportsAccountActivityAdvanceSearchDataSets;
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBY:
        protectionPolicyOptions = yield* fetchProtectionPolicyOptions(
          virtualStandbyAdvanceSearchDataSetsArrData
        );
        if (protectionPolicyOptions) {
          virtualStandbyAdvanceSearchDataSetsArrData.push(
            protectionPolicyOptions
          );
        }
        advanceSearchData = virtualStandbyAdvanceSearchDataSetsArrData;
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_RESOURCES:
        protectionPolicyOptions = yield* fetchProtectionPolicyOptions(
          recoveredResourcesAdvanceSearchDataSetsArrData
        );
        if (protectionPolicyOptions) {
          recoveredResourcesAdvanceSearchDataSetsArrData.push(
            protectionPolicyOptions
          );
        }
        advanceSearchData = recoveredResourcesAdvanceSearchDataSetsArrData;
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.INSTANT_VMS_RR:
        if (
          payload &&
          payload.data &&
          Array.isArray(payload.data.advancedSearchOptions) &&
          payload.data.advancedSearchOptions.length > 0
        ) {
          advanceSearchData = [...payload.data.advancedSearchOptions];
        } else {
          advanceSearchData = instantVMsAdvanceSearchDataSetsArrData;
        }
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_VMS:
        protectionPolicyOptions = yield* fetchProtectionPolicyOptions(
          recoveredVMSAdvanceSearchDataSetsArrData
        );
        if (protectionPolicyOptions) {
          recoveredVMSAdvanceSearchDataSetsArrData.push(
            protectionPolicyOptions
          );
        }
        advanceSearchData = recoveredVMSAdvanceSearchDataSetsArrData;
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS:
        // defaultSearch = true;
        advanceSearchData = customerAccountAdvanceSearchDataSetsArrData;
        break;
      default:
        break;
    }
    const advancedSearchOptions = advanceSearchData;
    if (navigation !== "jobs") {
      yield put(showAdvancedSearchOptions(advanceSearchData));
      if (
        [
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS,
          constant.SECONDARY_NAVIGATION_TYPE.POLICIES
        ].includes(navigation)
      ) {
        yield put(
          ccDataGridActions.invokeAdvancedSearchOptions(
            navigation,
            advanceSearchData
          )
        );
      }
    }
    if (
      payload.data &&
      !payload.data.cancelSavedSearchApiCall &&
      _.get(payload, "data.filter") !== "jobs"
    ) {
      const stateReducer = yield select(getReducer);
      if (
        ![
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS,
          constant.SECONDARY_NAVIGATION_TYPE.POLICIES
        ].includes(navigation)
      ) {
        yield put(
          getSavedSearchData({ advancedSearchOptions, filterType: navigation })
        );
      }
    }
    //}
    if (rpsServerId) {
      yield put(
        advanceSearchLoaded({ rpsServerId: rpsServerId, filter: navigation })
      );
    }
    let sourceGroupId = "";
    if (payload.data.sourceGroupId) {
      sourceGroupId = payload.data.sourceGroupId;
    }
    let gridCurrentPage = "";
    if (payload.data.gridCurrentPage) {
      gridCurrentPage = payload.data.gridCurrentPage;
    }
    let gridPageSize = "";
    if (payload.data.gridPageSize) {
      gridPageSize = payload.data.gridPageSize;
    }

    if (!defaultSearch && payload.data && !payload.data.cancelCurrentApiCall) {
      if (
        ![
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS,
          constant.SECONDARY_NAVIGATION_TYPE.POLICIES
        ].includes(navigation)
      ) {
        yield put(
          getCurrentActionCalled(
            {
              advancedSearchOptions,
              filter: navigation,
              param: payload.data.param,
              sourceGroupId,
              gridCurrentPage,
              gridPageSize,
              firstFetch
            },
            ""
          )
        );
      }
    }
    if (payload.data.fromSavedSearchList) {
      const filterId = payload.data.filterId;
      const filterName = payload.data.filterName;
      const filterType = payload.data.filterType;
      yield put(clearAllSearchTags());
      yield put(
        getSelectedSavedSearchAction({
          filterId,
          filterName,
          filterType
        })
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

function* getAdvancedSearchOptionsDestinations(
  destinationAdvanceSearchDataSetsArrData
) {
  const protectionPolicyOptions = yield* fetchProtectionPolicyOptions(
    destinationAdvanceSearchDataSetsArrData
  );
  if (protectionPolicyOptions !== null) {
    destinationAdvanceSearchDataSetsArrData.push(protectionPolicyOptions);
  }

  return destinationAdvanceSearchDataSetsArrData;
}
export function* fetchProtectionPolicyOptions(advanceSearchDataSets) {
  try {
    const stateLogin = yield select(getLogin);
    let protectionPolicy = [];
    let temppolicies = yield call(
      protectApi.fetchLightWeightPolicies,
      `organization_id=${stateLogin.organization_id}&pagination=false`,
      stateLogin.token,
      false
    );
    if (constant.HTTP_STATUS.OK === temppolicies.status) {
      yield put(updatePoliciesForAdvanceSearch(temppolicies.data));
      protectionPolicy = temppolicies.data;
    }

    return util.makeAdvanceSearchOptionsArray(
      advanceSearchDataSets,
      protectionPolicy,
      constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.PROTECTION_POLICY.NAME,
      constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.PROTECTION_POLICY.KEY,
      constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.PROTECTION_POLICY.FIELD
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchSourceGroupOptions(advanceSearchDataSets) {
  try {
    const stateLogin = yield select(getLogin);
    let sourceGroups = [];
    let tempSourceGroups = yield call(
      protectApi.fetchSourcesGroups,
      `organization_id=${stateLogin.organization_id}&pagination=false`,
      stateLogin.token
    );
    if (constant.HTTP_STATUS.OK === tempSourceGroups.status) {
      yield put(updateSourceGroupsForAdvanceSearch(tempSourceGroups.data));
      sourceGroups = tempSourceGroups.data;
    }
    return util.makeAdvanceSearchOptionsArray(
      advanceSearchDataSets,
      sourceGroups,
      constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.NAME,
      constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.KEY,
      constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.SOURCE_GROUPS.FIELD
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchDestinationOptions(advanceSearchDataSets) {
  try {
    const stateLogin = yield select(getLogin);
    let destinationsList = [];
    let destination = yield call(
      protectApi.fetchAllDestinationsList,
      `organization_id=${stateLogin.organization_id}&pagination=false`,
      stateLogin.token
    );
    if (destination && constant.HTTP_STATUS.OK === destination.status) {
      yield put(updateDestinationsForAdvanceSearch(destination.data));
      destinationsList = destination.data;
    }
    return util.makeAdvanceSearchOptionsArrayWithType(
      advanceSearchDataSets,
      destinationsList,
      constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.DESTINATIONS
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchOrganizationOptions(advanceSearchDataSets) {
  try {
    const stateLogin = yield select(getLogin);
    let organizationsData = [];
    let tempOrganizations = yield call(
      protectApi.fetchCustomerAccountsResult,
      `pagination=false`,
      stateLogin.token,
      stateLogin.organization_id
    );
    if (constant.HTTP_STATUS.OK === tempOrganizations.status) {
      organizationsData = tempOrganizations.data.filter(
        option => !option.sub_msp
      );
    }
    return util.makeAdvanceSearchOptionsArrayWithType(
      advanceSearchDataSets,
      organizationsData,
      constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* cancelJobById(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    yield put(setLoading(Loader.CANCEL_JOB));
    let apiResponse = yield call(
      commonApi.cancelJobById,
      action.data,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      if (action.actionType === "cancelRecoveryFromJobMonitor") {
        yield put(
          ccToastActions.addSuccessNotification(
            makeToastData("job.monitor.recovery.cancel.success", "9nmLml")
          )
        );
      } /*  else if (action.actionType === "cancelBackupFromJobMonitor") {
        yield put(
          ccToastActions.addSuccessNotification(
            "job.monitor.backup.cancel.success"
          )
        );
      } */
      let details = yield call(
        commonApi.fetchJobDetailsById,
        action.data,
        loginReducer.token
      );
      let job = details.data;
      job[constant.SPOG_GRID_PROPERTIES.ID_KEY] =
        job[JobsAttributes.JOBS_PROP_JOB_ID];
      let source_name =
        stateReducer.selectedResource &&
        stateReducer.selectedResource.source_name;

      if (action.actionType === "cancelrecovery") {
        yield put(
          ccToastActions.addSuccessNotification(
            makeToastData(
              constant.API_RESPONSE_CONTEXT.CANCEL_RECOVERY,
              "BCTizL"
            ),
            {
              name: source_name
            }
          )
        );
      } else if (action.actionType !== "cancelRecoveryFromJobMonitor") {
        yield put(
          ccToastActions.addSuccessNotification(
            makeToastData(constant.API_RESPONSE_CONTEXT.CANCEL_JOB, "IjX6rv"),
            {
              name: source_name
            }
          )
        );
      }

      yield put(updateJobResourceInList(job));
      yield put(getJobsMonitorDetails(action.data));
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
    yield put(hideDialog(Dialog.CANCEL_JOB));
    yield put(resetLoading(Loader.CANCEL_JOB));
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteJobById(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    let pagination =
      action && action.pagination ? { ...action.pagination } : {};
    let filterType = action && action.filter;
    yield put(showLoading());
    let apiResponse = yield call(
      commonApi.deleteJobById,
      action.data,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      let source_name =
        stateReducer.selectedResource &&
        stateReducer.selectedResource.source_name;
      yield put(
        ccToastActions.addSuccessNotification(
          makeToastData(constant.API_RESPONSE_CONTEXT.DELETE_JOB, "16sHLs"),
          {
            name: source_name
          }
        )
      );
      yield put(searchList({ ...pagination, filter: filterType || "jobs" }));
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* getJobDetails(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(clearJobDetails());
    yield put(showLoading());
    let apiResponse = yield call(
      commonApi.fetchJobDetailsById,
      action.data.id,
      loginReducer.token
    );
    if (apiResponse.status !== constant.HTTP_STATUS.OK) {
      apiResponse.context = constant.API_RESPONSE_CONTEXT.CANCEL_JOB;
      yield put(addAPIresponse(apiResponse));
    } else {
      if (stateReducer.selectedResource)
        yield put(setResourceName(stateReducer.selectedResource.job_name));
      yield put(showJobDetails(apiResponse));
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* cancelBackupJobById({ jobID, sourceID }) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    yield put(setLoading(Loader.CANCEL_JOB));
    let apiResponse = yield call(
      commonApi.cancelBackupJobById,
      jobID,
      sourceID,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      let details = yield call(
        commonApi.fetchJobDetailsById,
        jobID,
        loginReducer.token
      );
      let job = details.data;
      job[constant.SPOG_GRID_PROPERTIES.ID_KEY] =
        job[JobsAttributes.JOBS_PROP_JOB_ID];
      apiResponse.context = constant.API_RESPONSE_CONTEXT.CANCEL_JOB;
      apiResponse.source_name =
        stateReducer.selectedResource &&
        stateReducer.selectedResource.source_name;
      yield put(updateJobResourceInList(job));
      yield put(getJobsMonitorDetails(jobID));
      let source_name =
        stateReducer.selectedResource &&
        stateReducer.selectedResource.source_name;
      yield put(
        ccToastActions.addSuccessNotification(
          makeToastData(constant.API_RESPONSE_CONTEXT.CANCEL_JOB, "XiQbmw"),
          {
            name: source_name
          }
        )
      );
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
    yield put(hideDialog(Dialog.CANCEL_JOB));
    yield put(resetLoading(Loader.CANCEL_JOB));
  } catch (e) {
    window.console.log(e);
  }
}

export function* getMonitorJobDetails({ data }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let apiResponse = yield call(
      commonApi.fetchMonitorJobDetailsById,
      data,
      loginReducer.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put(updateJobsMonitorDetails(apiResponse.data));
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

function* getAllColumnsData(action) {
  try {
    const stateReducer = yield select(getReducer);
    const loginReducer = yield select(getLogin);
    let navigation =
      action && action.data && action.data.filter
        ? action.data.filter
        : stateReducer.secondaryNavigation;

    let organizationId = loginReducer.organization_id;
    let organizationType = loginReducer.organization_type;
    let data = [];
    if (getDGId(navigation)) {
      yield put(startGridLoaderById(getDGId(navigation)));
    }

    switch (navigation) {
      case constant.SECONDARY_NAVIGATION_TYPE.REPORTS:
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.NETWORK_CONFIGURATION:
      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINTS_SOURCES:
      case constant.SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT:
      case constant.SECONDARY_NAVIGATION_TYPE.JOBS_AND_DESTINATIONS_REPORT:
      case constant.SECONDARY_NAVIGATION_TYPE.USER_ROLES:
      case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_GROUPS: {
        const queryStr = `organization_id=${organizationId}&column_type=${constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation]}&user_id=${loginReducer.user_id}`;
        data = yield call(
          commonApi.fetchColumnsById,
          queryStr,
          loginReducer.token
        );
        yield put(updateColumnData(data, constant.COLUMN_API_TYPES.STATIC));
        break;
      }
      default: {
        let queryString = "";
        if (
          navigation ===
          constant.SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT
        ) {
          if (organizationType === "msp") {
            queryString = `organization_id=${organizationId}&column_type=${constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation].MSP}&user_id=${loginReducer.user_id}`;
          } else {
            queryString = `organization_id=${organizationId}&column_type=${constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation].DIRECT}&user_id=${loginReducer.user_id}`;
          }
        } else if (
          navigation ===
          constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT
        ) {
          if (organizationType === "msp") {
            queryString = `organization_id=${organizationId}&column_type=${constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation].MSP}&user_id=${loginReducer.user_id}`;
          } else {
            queryString = `organization_id=${organizationId}&column_type=${constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation].DIRECT}&user_id=${loginReducer.user_id}`;
          }
        } else if (
          navigation ===
          constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT
        ) {
          if (organizationType === "msp") {
            queryString = `organization_id=${organizationId}&column_type=${constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation].MSP}&user_id=${loginReducer.user_id}`;
          } else {
            queryString = `organization_id=${organizationId}&column_type=${constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation].DIRECT}&user_id=${loginReducer.user_id}`;
          }
        } else {
          queryString = `organization_id=${organizationId}&column_type=${constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation]}&user_id=${loginReducer.user_id}`;
        }

        data = yield call(
          commonApi.fetchColumnsById,
          queryString,
          loginReducer.token
        );
        if (
          navigation &&
          [
            /* constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
            constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
            constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS,
            constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES, */
            constant.SECONDARY_NAVIGATION_TYPE.MACHINES,
            constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM
          ].includes(navigation)
        ) {
          data.data.columns.push({
            column_id: "ca2b5e08-1fcc-445f-94e2-fb15ae5c6cb3",
            filter: true,
            key: "create_ts",
            long_label: "Type",
            order_id: 14,
            short_label: "Type",
            sort: true,
            visible: true
          });
        }

        let stateColumnData = [];
        stateColumnData = JSON.parse(
          JSON.stringify(Object.assign([], data.data.columns))
        );
        // yield call(console.log, stateColumnData);
        yield put(
          updateColumnData(
            {
              name: navigation,
              columnData: stateColumnData
            },
            {
              name: navigation,
              userColumnId: data.data && data.data.user_column_id,
              columnData: data.data.columns.map(obj => {
                obj.identifier = obj.key;
                obj.show = obj.visible;
                return obj;
              })
            }
          )
        );
        let allCCDataGrid = Object.values(constant.ACTIVE_CCDATAGRID_ID);
        if (allCCDataGrid.includes(navigation))
          yield put(
            ccDataGridActions.fetchColumnsCCDataGrid(
              {
                name: navigation,
                columnData: stateColumnData
              },
              {
                name: navigation,
                userColumnId: data.data && data.data.user_column_id,
                columnData: data.data.columns.map(obj => {
                  obj.identifier = obj.key;
                  obj.show = obj.visible;
                  return obj;
                })
              }
            )
          );
        break;
      }
    }
  } catch (e) {
    window.console.log(e);
  }
}

function getDGId(navigation) {
  switch (navigation) {
    case constant.SECONDARY_NAVIGATION_TYPE.SOURCES:
    case constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES:
    case constant.SECONDARY_NAVIGATION_TYPE.MACHINES:
    case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES:
    case constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM:
    case constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS:
      return constant.DATAGRID_IDS["sources_datagrid"];
    case constant.SECONDARY_NAVIGATION_TYPE.ORACLE_HOSTS:
      return constant.DATAGRID_IDS["oracle_hosts_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_VMS:
      return constant.DATAGRID_IDS["recovered_resources_datagrid"];
    case constant.SECONDARY_NAVIGATION_TYPE.MOUNTED_RECOVERY_POINTS:
      return constant.DATAGRID_IDS["mounted_recoverypoint_gid"];
    case constant.SECONDARY_NAVIGATION_TYPE.INSTANT_VMS_RR:
      return constant.DATAGRID_IDS["instant_vm_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBY:
      return constant.DATAGRID_IDS["virtual_standby_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD:
      return constant.DATAGRID_IDS["destination_datagrid"];
    case constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_SERVERS:
      return constant.DATAGRID_IDS["recoverypoint_server_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.CLOUD_ACCOUNT:
      return constant.DATAGRID_IDS["cloud_account_datagrid"];
    case constant.SECONDARY_NAVIGATION_TYPE.POLICIES:
      return constant.DATAGRID_IDS["policy_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS:
      return constant.DATAGRID_IDS["customer_account_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.JOBS:
      return constant.DATAGRID_IDS["jobs_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.LOG:
      return constant.DATAGRID_IDS["logs_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.ALERTS:
      return constant.DATAGRID_IDS["alerts_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.REPORTS:
      return constant.DATAGRID_IDS["reports_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.BACKUP_JOB_REPORT:
      return constant.DATAGRID_IDS["backup_jobs_report_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT:
      return constant.DATAGRID_IDS["datatransfer_report_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT:
      return constant.DATAGRID_IDS["capcaity_usage_report_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT:
      return constant.DATAGRID_IDS["recovery_point_report_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT:
      return constant.DATAGRID_IDS["source_protection_report_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.MANAGE_REPORT_SCHEDULES:
      return constant.DATAGRID_IDS["manage_schedules_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.USER_ACCOUNTS:
      return constant.DATAGRID_IDS["user_accounts_grid"];
    default:
      return "";
  }
}

export function* persistColumnData(action) {
  try {
    const stateReducer = yield select(getReducer);
    const loginReducer = yield select(getLogin);
    let organizationType = loginReducer.organization_type;
    let navigation = action.filterType;
    let colDataToUpdate =
      action &&
      action.columnData &&
      action.columnData.filter(col => col.identifier !== "available_actions");
    let copyStateColumnData = stateReducer.stateColumnData
      ? JSON.parse(
          JSON.stringify(
            Object.assign(
              [],
              stateReducer.stateColumnData &&
                stateReducer.stateColumnData.columnData
            )
          )
        )
      : [];

    let copyUpdatedColData = stateReducer.updatedColData;
    if (copyUpdatedColData) {
      copyStateColumnData.forEach(columnData => {
        copyUpdatedColData.forEach(columnWidth => {
          if (columnData.key === columnWidth.id) {
            columnData.width = columnWidth.value;
          }
        });
      });
      //eslint-disable-next-line no-self-assign
      copyStateColumnData = copyStateColumnData;
    }

    let tempColumnDataToUpdate = [];

    let updatedColDataToCompare = copyStateColumnData.map(colData => {
      colDataToUpdate.forEach((colDataToUpdate, i) => {
        if (colData.column_id === colDataToUpdate.column_id) {
          let obj = {
            column_id: colDataToUpdate.column_id,
            visible: colDataToUpdate.show,
            order_id: i + 1,
            width: colData.width
          };
          tempColumnDataToUpdate.push(obj);
          colData.visible = colDataToUpdate.show;
          colData.order_id = i + 1;
        }
      });
      return colData;
    });

    let isArrayEqual = function(x, y) {
      return _(x)
        .xorWith(y, _.isEqual)
        .isEmpty();
    };

    if (
      isArrayEqual(
        stateReducer.stateColumnData && stateReducer.stateColumnData.columnData,
        updatedColDataToCompare
      )
    ) {
      return;
    }

    let makeColumnDataToUpdate = {
      columns: tempColumnDataToUpdate,
      user_id: loginReducer.user_id,
      column_type: constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation]
    };

    if (
      navigation === constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT
    ) {
      if (organizationType === "msp") {
        makeColumnDataToUpdate = {
          columns: tempColumnDataToUpdate,
          user_id: loginReducer.user_id,
          column_type:
            constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation].MSP
        };
      } else {
        makeColumnDataToUpdate = {
          columns: tempColumnDataToUpdate,
          user_id: loginReducer.user_id,
          column_type:
            constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation].DIRECT
        };
      }
    }
    if (
      navigation === constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT
    ) {
      if (organizationType === "msp") {
        makeColumnDataToUpdate = {
          columns: tempColumnDataToUpdate,
          user_id: loginReducer.user_id,
          column_type:
            constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation].MSP
        };
      } else {
        makeColumnDataToUpdate = {
          columns: tempColumnDataToUpdate,
          user_id: loginReducer.user_id,
          column_type:
            constant.SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING[navigation].DIRECT
        };
      }
    }

    yield call(
      commonApi.putColumnsById,
      stateReducer.userColumnId,
      makeColumnDataToUpdate,
      loginReducer.token
    );
  } catch (e) {
    window.console.log(e);
  }
}
// For User Support Data
export function* getUserSupportData() {
  try {
    const loginReducer = yield select(getLogin);
    let apiResponse = yield call(
      commonApi.fetchUserSupportDetailsById,
      loginReducer.token,
      loginReducer.organization_id
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put(fetchUserSupportData(apiResponse.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* refreshHypervisorsSaga(action) {
  try {
    yield put(setLoading(Loader.REFRESH_HYPERVISOR));
    let token = yield select(getLoginToken);
    let orgId = yield select(getOrgId);
    let queryString = `&organization_id=${orgId}`;
    let fetchHypervForPage = _.get(action, "data.fetchHypervForPage", "");
    let { fetchType = "", product_type } = action.data;
    let resp = null;
    if (product_type === "cloud_direct") {
      resp = yield call(
        commonApi.refreshCDHypervisor,
        action.data,
        queryString,
        token,
        { showCommonError: true, testId: `${action.data.testId}_error_cd` }
      );
    } else {
      resp = yield call(
        commonApi.refreshHypervisor,
        action.data,
        queryString,
        token,
        { showCommonError: true, testId: `${action.data.testId}_error` }
      );
    }

    if (action.data && action.data.onRefresh);
    action.data.onRefresh(resp);
    if (resp.status === constant.HTTP_STATUS.OK) {
      let toastData = "hypervisors.refresh.sync.success";

      if (action.data.syncType === "sync" || action.data.syncType == null) {
        if (action.data.testId) {
          toastData = {
            messageId: "hypervisors.refresh.sync.success",
            testId: `${action.data.testId}_sync_success`
          };
        }
        yield put(ccToastActions.addSuccessNotification(toastData));
      } else {
        if (action.data.testId) {
          toastData = {
            messageId: "hypervisors.refresh.sync.success",
            testId: `${action.data.testId}_success`
          };
        }
        yield put(ccToastActions.addSuccessNotification(toastData));
      }
      yield put(getHypervisors(fetchHypervForPage, fetchType));
    }
    yield put(resetLoading(Loader.REFRESH_HYPERVISOR));
  } catch (e) {
    window.console.log(e);
  }
}

export async function filterAddedVMs(data) {
  if (data.children && data.children.length > 0) {
    const stateReducer = await select(getReducer);
    if (
      ![constant.SECONDARY_NAVIGATION_TYPE.POLICIES].includes(
        stateReducer.secondaryNavigation
      )
    )
      data.children = data.children.filter(
        item =>
          _.get(item, "vm_info.is_source_exists") === false ||
          _.get(item, "vm_info") === undefined
      );

    for (let i = 0; i < data.children.length; i++) {
      data.children[i] = await filterAddedVMs(data.children[i]);
    }
  }
  return data;
}

export function* connectHypervisorsSaga(action) {
  try {
    let token = yield select(getLoginToken);
    yield put(setLoading(Loader.ADD_FROM_HYPERVISOR_CONNECT_BTN));
    let orgId = yield select(getOrgId);
    let queryString = `&organization_id=${orgId}`;
    const resp = yield call(
      commonApi.connectAddSourceHypervisor,
      action.data,
      queryString,
      token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      if (action.data.hypervType === "hyperv") {
        let resp_hypervisor_id = resp.data.hypervisor_id;
        resp.data = yield call(filterAddedVMs, resp.data.hypervisor_info);
        resp.data.hypervisor_id = resp_hypervisor_id;
        if (resp.data.vm_info) {
          resp.data.vm_info = resp.data.vm_info.filter(
            item => _.get(item, "is_source_exists") === false
          );
        }
        yield put(addSourcesHypervisorHyperVConnectSuccess(resp.data));
        yield put(addSourcesSetHypervisorId(resp.data.hypervisor_id));
      } else {
        let resp_hypervisor_id = resp.data.hypervisor_id;
        resp.data = yield call(filterAddedVMs, resp.data);
        resp.data.hypervisor_id = resp_hypervisor_id;
        if (action.data.hypervType === "nutanix") {
          yield put(addSourcesHypervisorNutanixConnectSuccess(resp.data));
        } else {
          yield put(addSourcesHypervisorVmwareConnectSuccess(resp.data));
        }
        yield put(addSourcesSetHypervisorId(resp.data.hypervisor_id));
      }
    }
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_CONNECT_BTN));
  } catch (e) {
    window.console.log(e);
  }
}

export function* requestTrial(action) {
  try {
    let trialApi = "cloudhybrid/request";
    let postData = undefined;
    if (action.trialType === UsageSummaryProps.CLOUD_DIRECT) {
      trialApi = `${action.trialType}`;
      postData = { datacenter_id: action.dataCenterId };
    }
    const loginReducer = yield select(getLogin);
    let brandNames = _.get(loginReducer, "brand_names", constant.BRAND_NAMES);
    let apiResponse = yield call(
      commonApi.requestTrial,
      action.trialType,
      loginReducer.token,
      loginReducer.organization_id,
      trialApi,
      postData
    );

    if (apiResponse.errors.length === 0) {
      if (action.trialType === UsageSummaryProps.CLOUD_HYBRID) {
        yield put(
          ccToastActions.addSuccessNotification(
            makeToastData("toast-success.ch-trial", "jR5Ay0"),
            {
              ...brandNames
            }
          )
        );
      } else {
        yield put(
          ccToastActions.addSuccessNotification(
            makeToastData("toast-success.cd-trial", "hK7SfZ"),
            {
              ...brandNames
            }
          )
        );
        yield call(
          updateEntitlementsAfterCDTrial,
          loginReducer,
          action.showLicenseDetailsAfterSuccess
        );
        yield call(updateLoginProducts, loginReducer);
        yield put(getAlertList());
      }
    } else {
      //If multiple errors then we have to iterate errors
      if (apiResponse.errors.length > 0 && apiResponse.errors[0].code) {
        if (action.trialType === UsageSummaryProps.CLOUD_HYBRID) {
          yield put(
            ccToastActions.addErrorNotification(
              makeToastData(
                "error.monitor.chtrial." + apiResponse.errors[0].code,
                `1ABjS0_${apiResponse?.status}`
              ),
              { ...brandNames }
            )
          );
        } else {
          yield put(getAlertList());
          yield put(
            ccToastActions.addErrorNotification(
              makeToastData(
                "error.monitor.cdtrial." + apiResponse.errors[0].code,
                `1ABjS0_${apiResponse?.status}`
              ),
              { ...brandNames }
            )
          );
        }
      } else {
        ccToastActions.addErrorNotification(
          makeToastData(
            "toast-errors.internal-error",
            `1ABjS0_${apiResponse?.status}`
          )
        );
      }
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* requestUDPTrial(action) {
  const loginReducer = yield select(getLogin);
  let brandNames = _.get(loginReducer, "brand_names", constant.BRAND_NAMES);
  const apiResponse = yield call(
    commonApi.requestUDPTrial,
    loginReducer.token,
    loginReducer.organizationInfo.organization_id
  );

  if (apiResponse.errors.length === 0) {
    yield put(
      ccToastActions.addSuccessNotification(
        makeToastData("toast-success.udp-trial", "qIG4sm"),
        {
          ...brandNames
        }
      )
    );
    yield call(
      updateEntitlementsAfterCDTrial,
      loginReducer,
      action.showLicenseDetailsAfterSuccess
    );
    yield call(updateLoginProducts, loginReducer);
    yield put(getAlertList());
  } else {
    if (apiResponse.errors.length > 0 && apiResponse.errors[0].code) {
      yield put(getAlertList());
      yield put(
        ccToastActions.addErrorNotification(
          makeToastData(
            "error.monitor.udptrial." + apiResponse.errors[0].code,
            `UM6SDw_${apiResponse?.status}`
          ),
          { ...brandNames }
        )
      );
    } else {
      ccToastActions.addErrorNotification(
        makeToastData(
          "toast-errors.internal-error",
          `UM6SDw_${apiResponse?.status}`
        )
      );
    }
  }
  yield put(addAPIresponse(apiResponse));
}

export function* refreshCloudAccountSaga(action) {
  try {
    yield put(setLoading(Loader.REFRESH_HYPERVISOR));
    let token = yield select(getLoginToken);
    let orgId = yield select(getOrgId);
    let queryString = `&organization_id=${orgId}`;
    let fetchHypervForPage = _.get(action, "data.fetchHypervForPage", "");
    const resp = yield call(
      commonApi.refreshCloudAccount,
      action.data,
      queryString,
      token
    );
    if (action.data && action.data.onRefresh) action.data.onRefresh(resp);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToastActions.addSuccessNotification(
          makeToastData("cloudAccounts.refresh.success", "VROTH0")
        )
      );
      yield put(getHypervisors(fetchHypervForPage));
    } else if (resp.status === 403) {
      yield put(
        ccToastActions.addErrorNotification(
          makeToastData(resp.errors[0].message, `UM6SDw_${resp?.status}`)
        )
      );
    } else if (resp.status >= 400) {
      yield put(
        ccToastActions.addErrorNotification(
          makeToastData("cloudAccounts.refresh.fail", `UM6SDw_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.REFRESH_HYPERVISOR));
  }
}

export default function* appSaga() {
  yield put(initializeApp());
  yield all([
    takeLatest(
      actionType.GET_ADVANCED_SEARCH_OPTIONS,
      getAdvancedSearchOptionsData
    ),
    takeLatest(actionType.GET_JOBS_LIST, receiveList),
    takeLatest(actionType.GET_LOG_LIST, receiveList),
    takeLatest(actionType.GET_CURRENT_ACTION_CALLED, receiveList),
    takeLatest(actionType.ADVANCED_SEARCH_TRIGGERED, receiveList),
    takeLatest(actionType.EXPORT_LOG, exportLog),
    takeLatest(actionType.EXPORT_CUSTOMER_ACCOUNT, exportCustomAccount),
    takeLatest(
      actionType.GET_DEFAULT_SAVED_SEARCH,
      cancelAbleSaga(
        getAllSavedSearchData,
        actionType.STOP_GET_DEFAULT_SAVED_SEARCH
      )
    ),
    // takeLatest(actionType.GET_DEFAULT_SAVED_SEARCH, getAllSavedSearchData),
    takeEvery(actionType.GET_SELECTED_SAVED_SEARCH, getSelectedSavedSearch),
    takeEvery(actionType.EDIT_UPDATE_MANAGE_SAVE_SEARCH, updateSavedSearchData),
    takeEvery(
      actionType.EDIT_UPDATE_MANAGE_SAVE_SEARCH_CCDATAGRID,
      updateSavedSearchDataCCDataGrid
    ),
    takeEvery(actionType.DELETE_SAVED_SEARCH, deleteSavedSearchData),
    takeEvery(actionType.ADD_SAVE_SEARCH, addSavedSearchData),
    takeEvery(actionType.REMOVE_SEARCH_TAGS_RELOAD, removeSearchTagsAndReload),
    takeEvery(
      actionType.CLEAR_ALL_SEARCH_TAGS_RELOAD,
      clearSearchTagsAndReload
    ),
    takeEvery(actionType.CANCEL_JOB_BY_ID, cancelJobById),
    takeEvery(actionType.DELETE_JOB_BY_ID, deleteJobById),
    takeEvery(actionType.CANCEL_BACKUP_JOB_BY_ID, cancelBackupJobById),
    takeEvery(actionType.GET_JOB_DETAILS, getJobDetails),
    takeEvery(actionType.GET_MONITOR_JOB_DETAILS, getMonitorJobDetails),
    takeLatest(actionType.GET_COLUMN_DATA, getAllColumnsData),
    takeEvery(actionType.GET_USER_SUPPORT_DATA, getUserSupportData), // For User Support Data
    takeLatest(actionType.PUT_COLUMN_DATA, persistColumnData), //update column data
    takeEvery(actionType.HANDLE_BULK_ACTION, handleBulkAction),
    takeLatest(actionType.CLOUD_HYBRID_TRIAL_REQUEST, requestTrial),
    takeLatest(actionType.UDP_TRIAL_REQUEST, requestUDPTrial),
    takeLatest(actionType.BROWSE_REMOTE_CONSOLE, browseRemoteConsole),
    takeLatest(actionType.FETCH_RESOURCE_POOL, fetchResourcePool),
    takeLatest(actionType.GET_HYPERVISORS, getHypervisorsSaga),
    takeLatest(actionType.REFRESH_HYPERVISORS, refreshHypervisorsSaga),
    takeLatest(actionType.REFRESH_CLOUD_ACCOUNT, refreshCloudAccountSaga),
    takeLatest(actionType.CONNECT_HYPERVISOR, connectHypervisorsSaga)
  ]);
}

import { Grid, Typography } from "@mui/material";
import React from "react";
// import Tooltip from '@mui/material/Tooltip';
import { UCID } from "../appConstants";
const UserDetails = ({ name, email }) => {
  return (
    <Grid
      container
      direction="column"
      style={{ padding: 24, maxWidth: "240px", wordBreak: "break-all" }}
    >
      <Grid item style={{ marginBottom: 15 }}>
        <Typography variant="h4" id={`${UCID}_companyname`}>
          Arcserve
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h6" id={`${UCID}_user_name`}>
              {name}
            </Typography>
            <Grid container>
              <Grid item>
                <Typography variant="caption" style={{ color: "#919191" }}>
                  {/* <Tooltip title={email}> */}
                  <span id={`${UCID}_user_email`}>{email}</span>
                  {/* </Tooltip> */}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserDetails;

import { call } from "redux-saga/effects";
import { getData, postData, putData, deleteData } from "./apiMethods";
import * as API_URL from "./apiUrlConstants";
import { REPORTS_API_URL } from "../../components/analyze/reports/ReportsConstants";

/**
 * To get analyze tab secondary navigation counts
 * @returns {Promise<*>}
 */
export function* getAnalyzeCounts() {
  try {
    return yield call(getData, API_URL.FETCH_ANALYZE_COUNTS);
  } catch (e) {
    window.console.log(e);
  }
}

export function* getGeneratedFromList(queryString, token) {
  try {
    return yield call(
      getData,
      API_URL.FETCH_GENERATED_FROM,
      queryString,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchReportsList(
  queryString,
  token,
  navigation,
  pollingAction = false
) {
  try {
    //to be uncommented once APIs are available
    let url = API_URL[REPORTS_API_URL[navigation]];
    if (url) {
      return yield call(getData, url, queryString, token, pollingAction);
    }
    return undefined;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchAlertsList(queryString, token, pollingAction = false) {
  try {
    let url = API_URL.FETCH_ANALYZE_ALERT;
    if (url) {
      return yield call(getData, url, queryString, token, pollingAction);
    }
    return undefined;
  } catch (e) {
    window.console.log(e);
  }
}

export function* exportReportsListAsCSV(queryString, token, navigation, id) {
  try {
    //to be uncommented once APIs are available
    let url = API_URL[REPORTS_API_URL[navigation]].replace("{id}", id);
    if (url) {
      return yield call(
        getData,
        url,
        queryString,
        token,
        false,
        true /* showCommonError */
      );
    }
    return null;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchReportDetailsByID(reportID, queryString, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_REPORT_DATA}/${reportID}`,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteReport(id, token) {
  try {
    return yield call(deleteData, `${API_URL.FETCH_REPORTS}/${id}`, token);
    //?delete_schedule=true
  } catch (error) {
    window.console.log(error);
  }
}

export function* deleteScheduleReport(
  id,
  queryString,
  token,
  showCommonError = true
) {
  try {
    const url = queryString
      ? `${API_URL.FETCH_SCHEDULE_REPORTS}/${queryString}`
      : API_URL.FETCH_SCHEDULE_REPORTS;
    return yield call(deleteData, url, token, id, showCommonError);
  } catch (error) {
    window.console.log(error);
  }
}

export function* deleteAnalyzeAlert(id, token, showCommonError = true) {
  try {
    return yield call(
      deleteData,
      `${API_URL.FETCH_ANALYZE_ALERT}/${id}`,
      token,
      {},
      showCommonError
    );
  } catch (error) {
    window.console.log(error);
  }
}

export function* updateReportSchedule(
  id,
  report,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      putData,
      `${API_URL.FETCH_SCHEDULE_REPORTS}/${id}`,
      report,
      token,
      showCommonError
    );
  } catch (error) {
    window.console.log(error);
  }
}

export function* updateAnalyzeAlert(id, alert, token, showCommonError = true) {
  try {
    return yield call(
      putData,
      `${API_URL.FETCH_ANALYZE_ALERT}/${id}`,
      alert,
      token,
      showCommonError
    );
  } catch (error) {
    window.console.log(error);
  }
}

export function* runReportNow(id, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_REPORT_SCHEDULE}/${id}/generatereportnow`,
      {},
      token,
      showCommonError
    );
  } catch (error) {
    window.console.log(error);
  }
}

export function* getReportDataByID(id, queryString, token) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_REPORT_DATA_SINGLE}/${id}`,
      queryString,
      token
    );
  } catch (error) {
    window.console.log(error);
  }
}

export function* updateReportSettings(
  id,
  postbody,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      putData,
      id && id !== "null"
        ? `${API_URL.FETCH_REPORT_DATA_SINGLE}/${id}`
        : `${API_URL.FETCH_REPORT_DATA_SINGLE}`,
      postbody,
      token,
      showCommonError
    );
  } catch (error) {
    window.console.log(error);
  }
}

export function* updateReportSettingsForGenerateNow(
  id,
  postbody,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      postData,
      id && id !== "null"
        ? `${API_URL.FETCH_REPORT_DATA_SINGLE}/${id}`
        : `${API_URL.FETCH_REPORT_DATA_SINGLE}`,
      postbody,
      token,
      showCommonError
    );
  } catch (error) {
    window.console.log(error);
  }
}

export function* createReport(postbody, token, showCommonError = true) {
  try {
    let url = API_URL.FETCH_REPORT_SCHEDULE;
    return yield call(postData, url, postbody, token, showCommonError);
  } catch (error) {
    window.console.log(error);
  }
}

export function* createAlert(postbody, token, showCommonError = true) {
  try {
    let url = API_URL.POST_ANALYZE_ALERT;
    return yield call(postData, url, postbody, token, showCommonError);
  } catch (error) {
    window.console.log(error);
  }
}

export function* getBackupJobsData(url, queryString, token) {
  try {
    return yield call(getData, url, queryString, token);
  } catch (e) {
    window.console.error(e);
  }
}

export function* getBackupJobReportsDetails(token) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_REPORTS_BACKUP_JOB}`,
      "",
      token
    );
  } catch (e) {
    window.console.error(e);
  }
}

export function* getReportSchedules(token, queryParams = "") {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_REPORT_SCHEDULE}`,
      queryParams,
      token
    );
  } catch (e) {
    window.console.error(e);
  }
}

export function* getRestoreJobsData(url, queryString, token) {
  try {
    return yield call(getData, url, queryString, token);
  } catch (e) {
    window.console.error(e);
  }
}

export function* exportLog(queryString, token) {
  try {
    return yield call(getData, API_URL.FETCH_LOG, queryString, token);
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * Fetch Analyze > Reports > Account Details Report data and pagination
 */
export function* fetchAccountDetailsData(
  id,
  queryString,
  token,
  pollingAction = false
) {
  try {
    let url = API_URL.FETCH_ACCOUNT_DETAILS.replace("{id}", id);
    if (url) {
      return yield call(getData, url, queryString, token, pollingAction);
    }
    return undefined;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Fetch Analyze > Reports > Account Summary data on Account Details Report
 */
export function* fetchAccountSummaryData(
  id,
  queryString,
  token,
  pollingAction = false
) {
  try {
    let url = API_URL.FETCH_ACCOUNT_SUMMARY_DETAILS.replace("{id}", id);
    if (url) {
      return yield call(getData, url, queryString, token, pollingAction);
    }
    return undefined;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Fetch Analyze > Reports > Source Protections Report source summary widget data
 */
export function* fetchSourceProtectionSourcesSummary(id, queryString, token) {
  try {
    const url = `${API_URL.FETCH_SOURCE_PROTECTION_SOURCES_SUMMARY}/${id}`;
    return yield call(getData, url, queryString, token);
  } catch (e) {
    window.console.log(e);
  }
}

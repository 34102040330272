import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import functions from "lodash/functions";
// Note: Notice "Component", we want this to rerender on every prop change, so it
//  triggers componentDidUpdate and rerenders the chart
const Wrapper = props => {
  //eslint-disable-next-line  no-unused-vars
  const [__, forceUpdate] = React.useReducer(x => x + 1, 0);
  const prevProps = usePreviousValues(props);

  useEffect(() => {
    forceUpdate();
  }, []);

  useEffect(() => {
    if (
      props &&
      prevProps &&
      !isEqual(
        omit(props, functions(props)),
        omit(prevProps, functions(prevProps))
      )
    ) {
      forceUpdate();
    }
  }, [prevProps]); // eslint-disable-line react-hooks/exhaustive-deps

  const { height } = props;
  return (
    <div
      id={"Chart_0a30bfb3-9dfa-4e2c-b35f-44eda00e7878"}
      ref={props.wrapperRef}
      style={{ height }}
      className={props.className}
    >
      {props.children}
    </div>
  );
};

Wrapper.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  wrapperRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  renderChart: PropTypes.func
};

Wrapper.defaultProps = {
  className: "",
  height: "100%"
};

export default Wrapper;

function usePreviousValues(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

import { createSelector } from "reselect";
import { Action } from "components/protect/destination/rps/datagrid/RPSGridColumns";

/* eslint-disable import/prefer-default-export */

const RPS_AVAILABLE_ACTIONS = [
  Action.REFRESH,
  Action.UPDATE,
  Action.DELETE,
  Action.ADD_DATA_STORE,
  Action.IMPORT_LOCAL_DATA_STORE,
  Action.ADD_CLOUD_DATA_STORE_ACTION,
  Action.IMPORT_CLOUD_DATA_STORE,
  Action.RPS_JUMPSTART,
  Action.DEPLOY_RPS,
  Action.CANCEL_RPS
];

const getRpsAvaialbleAction = rps => {
  return RPS_AVAILABLE_ACTIONS.filter(action => {
    if (
      (action === Action.IMPORT_CLOUD_DATA_STORE &&
        !rps?.available_actions?.includes("import_cloud_data_store")) ||
      (action === Action.ADD_CLOUD_DATA_STORE_ACTION &&
        !rps?.available_actions?.includes("add_cloud_data_store"))
    ) {
      return false;
    } else {
      return true;
    }
  });
};

export const getRpsList = state =>
  state.destinations && state.destinations.rps.rpsResponse
    ? state.destinations.rps.rpsResponse.data
    : [];

export const getRpsListForDS = state =>
  state.protectReducer && state.protectReducer.recoveryPointServers
    ? state.protectReducer.recoveryPointServers
    : [];

const prepareIconObject = status => {
  let iconClass = "";
  switch (status) {
    case "running":
    case "normal":
      iconClass = "rps_success";
      break;
    case "starting":
    case "creating":
    case "deploying_patch":
      iconClass = "warning";
      break;
    case "loading":
    case "deploying":
      iconClass = "upgrading";
      break;
    default:
      iconClass = "error";
  }
  return {
    text: status,
    iconClass
  };
};

export const getRpsGridData = createSelector([getRpsList], rpsList =>
  rpsList.map(rps => ({
    id: rps.server_id,
    available_actions: getRpsAvaialbleAction(rps),
    name: rps.server_name,
    server_name: rps.server_name,
    username: rps.username,
    port: rps.port,
    protocol: rps.protocol,
    nat_server: rps.nat.nat_server,
    nat_server_port: rps.nat.nat_server_port,
    description: rps.description,
    site_name: rps.site.site_name,
    site_id: rps.site.site_id,
    status: prepareIconObject(rps.status),
    policies: rps.policies,
    datastore_count: rps.datastore_count,
    version: rps.rps_version,
    rps_version: rps.rps_version,
    actionsFromBE: [...rps.available_actions]
  }))
);

export const getRpsGridDataForDS = createSelector([getRpsListForDS], rpsList =>
  rpsList.map(rps => ({
    id: rps.server_id,
    available_actions: getRpsAvaialbleAction(rps),
    name: rps.server_name,
    server_name: rps.server_name,
    username: rps.username,
    port: rps.port,
    protocol: rps.protocol,
    nat_server: rps.nat.nat_server,
    nat_server_port: rps.nat.nat_server_port,
    description: rps.description,
    site_name: rps.site.site_name,
    site_id: rps.site.site_id,
    status: prepareIconObject(rps.status),
    policies: rps.policies,
    datastore_count: rps.datastore_count,
    version: rps.rps_version,
    rps_version: rps.rps_version,
    actionsFromBE: [...rps.available_actions]
  }))
);

export const getRpsPagination = state =>
  state.destinations && state.destinations.rps.rpsResponse
    ? state.destinations.rps.rpsResponse.pagination
    : {};

export const getCurrentRpsId = state => state.destinations.rps.currentRpsId;
export const getCurrentRps = state => state.destinations.rps.currentRps;

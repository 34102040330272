/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import FormattedMessage, { FormattedHTMLMessage } from "../formatted-message";
import { ID_PREFIX } from "configs/IdConfig";
import { useSelector } from "react-redux";
import { DARK_MODE } from "utils/theme";

export const InputWithLabel = ({
  labelKey,
  input,
  type,
  meta: { touched, error },
  className,
  style,
  disabled,
  wrapperClassName,
  labelClassName,
  autoComplete,
  autoFocus,
  additionalProps = {},
  id
}) => {
  const inputElement = useRef(null);

  useEffect(() => {
    if (inputElement.current && autoFocus) {
      inputElement.current.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`d-flex flex-column text-left mb-3 ${wrapperClassName}`}>
      <label className={labelClassName}>
        <FormattedMessage id={labelKey} {...additionalProps} />
      </label>
      <input
        className={`${className} ${touched && error && "border-danger"}`.trim()}
        ref={inputElement}
        style={style}
        {...input}
        type={type}
        disabled={disabled}
        autoComplete={autoComplete}
        id={id}
      />
      {touched && error && (
        <div className="text-danger mt-1">
          <FormattedMessage id={error.id} values={error.values} />
        </div>
      )}
    </div>
  );
};

InputWithLabel.propTypes = {
  labelKey: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  input: PropTypes.objectOf().isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  style: PropTypes.objectOf(),
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string
};

InputWithLabel.defaultProps = {
  className: "",
  wrapperClassName: "",
  labelClassName: "",
  style: {},
  disabled: false,
  autoComplete: "new-password"
};

export const InputWithLabelMUI = ({
  labelKey,
  input,
  type,
  meta: { touched, error },
  className,
  style,
  customStyle,
  disabled,
  wrapperClassName,
  labelIcon,
  labelClassName,
  autoComplete,
  autoFocus,
  additionalProps = {},
  label,
  placeholder,
  multiline,
  hideFloatingLabel,
  id,
  testId,
  onKeyDown
}) => {
  const inputElement = useRef(null);
  const themeMode = useSelector(state => state.reducer.theme.palette.mode);
  useEffect(() => {
    if (inputElement.current && autoFocus) {
      inputElement.current.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let fieldClassName = hideFloatingLabel ? "input-field-mui" : "";

  let inputFieldId = id ? id : testId ? `${ID_PREFIX}${testId}` : "";

  return (
    <div className={`d-flex flex-column text-left mb-3 ${wrapperClassName}`}>
      {labelKey ? (
        <label className={labelClassName}>
          {labelIcon}
          <FormattedMessage id={labelKey} {...additionalProps} />
        </label>
      ) : null}
      <TextField
        ref={inputElement}
        id={inputFieldId ? inputFieldId : "outlined-basic"}
        inputProps={{
          style: {
            border: "none",
            paddingTop: "18.5px",
            paddingRight: "14px",
            paddingBottom: "18.5px",
            paddingLeft: "14px",
            ...customStyle
          },
          form: {
            autocomplete: "off"
          }
        }}
        label={!hideFloatingLabel ? label : null}
        placeholder={placeholder || null}
        variant="outlined"
        className={`${className} ${fieldClassName} ${
          hideFloatingLabel ? "spog-mui-textfield" : ""
        } ${touched && error && "border-danger"}`.trim()}
        style={{ backgroundColor: "none !important", ...customStyle }}
        {...input}
        type={type}
        disabled={disabled}
        autoComplete={autoComplete}
        multiline={multiline}
        maxRows={6}
        onKeyDown={onKeyDown ? onKeyDown : () => {}}
        sx={
          themeMode !== DARK_MODE
            ? {
                "& .MuiOutlinedInput-root.Mui-disabled": {
                  "& > fieldset": {
                    backgroundColor: "rgba(0,0,0,0.12)",
                    color: "rgba(0,0,0,0.26)"
                  }
                }
              }
            : {
                "& .MuiOutlinedInput-root.Mui-disabled": {
                  "& > fieldset": {
                    backgroundColor: "rgba(255,255,255,0.12)",
                    color: "rgba(255,255,255,.38)",
                    borderColor: "rgba(255,255,255,0.12)"
                  }
                }
              }
        }
      />
      {touched && error && (
        <div className="text-danger mt-1">
          <FormattedHTMLMessage id={error.id} values={error.values} />
        </div>
      )}
    </div>
  );
};

InputWithLabelMUI.defaultProps = {
  multiline: false,
  maxRows: 1,
  hideFloatingLabel: false
};

export default InputWithLabel;

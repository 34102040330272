import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DropdownButton from "../dropdown-button-mui";
import PropTypes from "prop-types";
import ReactGridLayout from "react-grid-layout";
import CheckBoxItem from "./CheckBoxItem";
import {
  updateCurrentSearchFromSaved,
  showManageSearchModal,
  updateReorderColumns
} from "../../../state/actions/actions";
import ManageSavedSearches from "../manage-saved-searches-mui/index";
import FormattedMessage from "../formatted-message";
import Button from "../button";
import {
  makeColumnConfiguredDataState,
  makeShowManagedSearchModalState,
  makeSavedSearchDataState
} from "../../../state/selectors/CommonSelectors";

const GridSettings = ({
  showManageSearchModal,
  userSearchData,
  updateCurrentSearchFromSaved,
  updateReorderColumns,
  columnsModelData,
  columnConfiguredData,
  filterType,
  className,
  title,
  testId,
  disableManageSaveSearches,
  showManageSettingsModal,
  dataGridID
}) => {
  /**
   * stop event propagation on drag
   */
  const handleModalEvent = () => {
    showManageSearchModal(true);
    if (userSearchData.length > 0) {
      const selectedOptions = userSearchData[0].options;
      const searchString = userSearchData[0].searchString;
      updateCurrentSearchFromSaved({
        selectedOptions,
        searchString
      });
    }
  };

  /**
   * stop event propagation on drag
   */
  const handleOnDragEvent = (l, n, p, li, e) => {
    e.stopPropagation();
  };

  const handleReorderCols = (layout, oldItem, newItem) => {
    updateReorderColumns(
      columnsModelData,
      oldItem,
      newItem,
      columnConfiguredData,
      filterType
    );
  };

  const renderManageOverlayHeader = columnsDataArr => {
    let isDisabled = !(userSearchData && userSearchData.length > 0);
    return (
      <ul
        className="manage-header-wrapper"
        style={{
          margin: 0,
          padding: 0,
          listStyle: "none"
        }}
      >
        <li eventkey="1">
          <Button
            type="button"
            disabled={isDisabled}
            className={isDisabled ? "disabled" : ""}
            content={<FormattedMessage id="manage_saved_search" />}
            color="secondary"
            variant="default"
            style={{
              fontSize: 12,
              border: "none",
              background: "transparent"
            }}
            onClick={() => handleModalEvent()}
          />
        </li>
        {columnsDataArr && columnsDataArr.length > 0 && <hr />}
      </ul>
    );
  };

  let layout = [];
  let width = 170;
  let height = 30;
  let columnsTempDataArr = columnsModelData || [];
  let columnsDataArr = JSON.parse(
    JSON.stringify(Object.assign([], columnsTempDataArr))
  );
  if (columnsDataArr && columnsDataArr.length > 0) {
    columnsDataArr.splice(0, 2);
    columnsDataArr.forEach((obj, index) => {
      if (obj.show) {
        layout.push({
          i: "set_" + obj.identifier,
          x: 0,
          y: index,
          w: 1,
          h: 1,
          isResizable: false
        });
      } else {
        layout.push({
          i: "set_" + obj.identifier,
          x: 0,
          y: index,
          w: 1,
          h: 1,
          isResizable: false,
          isDraggable: false
        });
      }
    });
  }

  return (
    <div className={className} style={{ marginTop: "5px" }}>
      <DropdownButton
        closeOnClick={false}
        testId={testId}
        title={title}
        pullRight
        color="secondary"
        variant="outlined"
        customButtonStyle={{ marginBottom: 5, padding: 7.5 }}
        className="manage-settings-overlay no-text"
        showManageSettingsModal={showManageSettingsModal}
        disabled={
          !disableManageSaveSearches &&
          userSearchData &&
          userSearchData.length <= 0 &&
          columnsDataArr &&
          columnsDataArr.length <= 0
        }
      >
        {!disableManageSaveSearches &&
          userSearchData &&
          userSearchData.length > 0 &&
          renderManageOverlayHeader(columnsDataArr)}
        {columnsDataArr && columnsDataArr.length > 0 && (
          <ReactGridLayout
            style={{ width: 250, maxHeight: 330 }}
            className="layout"
            layout={layout}
            cols={1}
            rowHeight={height}
            width={width}
            onDragStart={handleOnDragEvent}
            onDragStop={handleReorderCols}
            draggableHandle=".dragDropHandle"
          >
            {columnsDataArr.map((item, i) => {
              let keyIndex = i;
              return (
                <div key={"set_" + item.identifier}>
                  <CheckBoxItem
                    column={item}
                    index={keyIndex}
                    key={keyIndex}
                    filterType={filterType}
                  />
                </div>
              );
            })}
          </ReactGridLayout>
        )}
      </DropdownButton>
      {showManageSettingsModal && (
        <ManageSavedSearches
          filterType={filterType}
          show={showManageSettingsModal}
          dataGridId={dataGridID}
        />
      )}
    </div>
  );
};

GridSettings.defaultProps = {
  userSearchData: [],
  title: "Select",
  className: "",
  columnsModelData: []
};

GridSettings.propTypes = {
  showManageSettingsModal: PropTypes.bool.isRequired,
  disableManageSaveSearches: PropTypes.bool.isRequired,
  columnConfiguredData: PropTypes.arrayOf(PropTypes.shape({})),
  filterType: PropTypes.string.isRequired,
  userSearchData: PropTypes.arrayOf(PropTypes.shape({})),
  showManageSearchModal: PropTypes.func.isRequired,
  updateReorderColumns: PropTypes.func.isRequired,
  updateCurrentSearchFromSaved: PropTypes.func.isRequired,
  title: PropTypes.shape({}),
  className: PropTypes.string,
  columnsModelData: PropTypes.arrayOf(PropTypes.shape({}))
};

/**
 * Using makeMapStateToProps to memoize a selectorFunction for reusability
 * This will create a new instance of selector function
 * every time it is needed for proper memoization in multiple components.
 */
const makeMapStateToProps = () => {
  /**
   * create a new instance of selector function
   * This makes it independent of any other selector function
   * and it will properly memoize across multiple mounted & connected components.
   */
  const getColumnConfiguredDataState = makeColumnConfiguredDataState();
  const getShowManagedSearchModalState = makeShowManagedSearchModalState();
  const getSavedSearchDataState = makeSavedSearchDataState();

  const mapStateToProps = (state, props) => {
    return {
      /**
       * Use selector function instance to update Props.
       */
      columnConfiguredData: getColumnConfiguredDataState(state, props),
      showManageSettingsModal: getShowManagedSearchModalState(state, props),
      userSearchData: getSavedSearchDataState(state, props)
    };
  };
  return mapStateToProps;
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateReorderColumns,
      showManageSearchModal,
      updateCurrentSearchFromSaved
    },
    dispatch
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(GridSettings);

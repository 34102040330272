import { call } from "redux-saga/effects";
import _ from "lodash";
import {
  getData,
  postData,
  putData,
  deleteData,
  postDataMultipart,
  getPlainTextData
} from "./apiMethods";
import * as API_URL from "./apiUrlConstants";
import { BRANDING_FROM_SELF } from "../../utils/appConstants";
import dataUserRoles from "state/sagas/data";
export const fetchInfrastructureList = async type => {
  try {
    let filterTypeStr = type ? `?type=${type}` : ``;
    const response = await fetch(API_URL.FETCH_INFRASTRUCTURE + filterTypeStr);
    const data = await response.json();
    return data;
  } catch (e) {
    window.console.log(e);
  }
};

/**
 * To get detail of infrastructure/cloudAccount/hypervisor
 * @param id
 * @returns {Promise<any>}
 */
export const fetchInfraDetailById = async id => {
  try {
    const response = await fetch(`${API_URL.FETCH_INFRASTRUCTURE}/${id}`);
    const data = await response.json();
    return data;
  } catch (e) {
    window.console.log(e);
  }
};

export function* fetchHypervisorDetailById(id, token) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_HYPERVISOR_LIST}/${id}`,
      "",
      token
    );
  } catch (e) {
    return e;
  }
}

export function* loadConfigureProxies(page, pageSize, orgId, token) {
  try {
    const queryString = `page=${page}&page_size=${pageSize}&${orgId}`;
    const data = yield call(
      getData,
      API_URL.FETCH_BACKUP_PROXY,
      queryString,
      token
    );
    return data;
  } catch (e) {
    return e;
  }
}

export function* deleteProxyById(proxyId, token, showCommonError = false) {
  try {
    const response = yield call(
      deleteData,
      `${API_URL.DELETE_PROXY}/${proxyId}`,
      token,
      {},
      showCommonError
    );
    return response;
  } catch (e) {
    return e;
  }
}
export const fetchRoleDetailsById = async () => {
  try {
    const response = await fetch(API_URL.FETCH_USER_ROLE_DETAILS);
    const data = await response.json();
    return data;
  } catch (e) {
    window.console.log(e);
  }
};

/**
 * To get detail of cloud volume
 * @param id
 * @returns {Promise<any>}
 */
export function* fetchArcerveCloudVolumeById(id, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_INFRASTRUCTURE_ARCSERVECLOUD_VOLUME}/${id}`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get VM detail of infrastructure/cloudAccount
 * @returns {Promise<any>}
 */
export const fetchInfraVMDetails = async searchCriteria => {
  try {
    const response = await fetch(
      `${API_URL.FETCH_VIRTUAL_MACHINES}?${searchCriteria}`
    );
    const data = await response.json();
    return data;
  } catch (e) {
    window.console.log(e);
  }
};

export const getInfrastructureCounts = async () => {
  try {
    const response = await fetch(API_URL.FETCH_INFRASTRUCTURE_COUNTS);
    const data = await response.json();
    return data;
  } catch (e) {
    window.console.log(e);
  }
};

/**
 * To get advanced search options for the Protect tab
 * @param type
 * @returns {*}
 */
export const getAdvancedSearch = async () => {
  try {
    const response = await fetch(API_URL.FETCH_ADVANCE_SEARCH);
    const data = await response.json();
    return data;
  } catch (e) {
    window.console.log(e);
  }
};

/**
 *
 * @param type
 * @returns {Promise<any>}
 */
export function* updateHypervisorDetails(postbody, id, token) {
  try {
    const data = yield call(
      putData,
      `${API_URL.FETCH_HYPERVISOR_LIST}/${id}`,
      postbody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * fetch users
 */
export function* getUsers(queryString = "", token, pollingAction = false) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_USERS,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * fetch users
 */
export function* getUsersV2(queryString = "", token, pollingAction = false) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_USERS_V2,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * delete user by id
 */
export function* deleteUser(userId, token) {
  try {
    const response = yield call(
      deleteData,
      `${API_URL.FETCH_USERS}/${userId}`,
      token
    );
    return response;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * fetch user roles
 */
// eslint-disable-next-line require-yield
export function* getUserRoles(query = "", token, pollingAction = false) {
  try {
    // const data = yield call(
    //   getData,
    //   API_URL.FETCH_USER_ROLES,
    //   query,
    //   token,
    //   pollingAction
    // );
    return dataUserRoles;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * create a new user
 */
export function* addUser(postDataBody, token, isAddMode) {
  try {
    let data;
    if (isAddMode) {
      data = yield call(postData, API_URL.FETCH_USERS, postDataBody, token);
    } else {
      const userId = postDataBody.user_id;
      data = yield call(
        putData,
        `${API_URL.FETCH_USERS}/${userId}`,
        postDataBody,
        token
      );
    }
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* manageMSPUserPermissions(postDataBody, token) {
  try {
    let data;
    const userId = postDataBody.user_id;
    _.unset(postDataBody, "user_id");
    data = yield call(
      putData,
      `${API_URL.FETCH_USERS}/${userId}/role`,
      postDataBody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * fetch license details
 */
export function* getLicenseDetails(
  token,
  orgId,
  pollingAction = false,
  showCommonError = true
) {
  return yield call(
    getData,
    `${API_URL.FETCH_LICENCE_DETAIL}/${orgId}/entitlements`,
    "",
    token,
    pollingAction,
    showCommonError
  );
}

/**
 * update license details
 */
export function* updateLicenseDetails(body, orgId, token) {
  const url = `${API_URL.FETCH_LICENCE_DETAIL}/${orgId}/orders`;
  const data = yield call(postData, url, body, token);
  return data;
}

/**
 * get sources by group id
 */
export function* getSourcesByGroupId(id = "", token, queryString) {
  try {
    const url = API_URL.FETCH_SOURCES_GROUPS + "/" + id + "/members";
    const data = yield call(getData, url, queryString, token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * remove sources from source group
 */
export function* removeSourcesFromGroup(
  id = "",
  token,
  body,
  showCommonError = false
) {
  try {
    const url = API_URL.FETCH_SOURCES_GROUPS + "/" + id + "/members";
    const response = yield call(deleteData, url, token, body, showCommonError);
    return response;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * add multiple sources to group
 */
export function* addSourcesToGroup(
  id = "",
  body,
  token,
  showCommonError = false
) {
  try {
    const url = API_URL.FETCH_SOURCES_GROUPS + "/" + id + "/members";
    const data = yield call(postData, url, body, token, showCommonError);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to fetch brand details
 */
export function* fetchBrandDetails(id, token) {
  try {
    const url = `${API_URL.FETCH_BY_ORGANIZATIONS}/${id}/branding`;
    const data = yield call(getData, url, "", token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 *
 * @param type
 * @returns {Promise<any>}
 */
export function* updateBrandDetails(
  postbody,
  token,
  id,
  showCommonError = false
) {
  try {
    let data = {};
    let url = `${API_URL.FETCH_BY_ORGANIZATIONS}/${id}/branding`;
    if (postbody.branding_from === BRANDING_FROM_SELF) {
      data = yield call(putData, url, postbody, token, showCommonError);
    } else {
      data = yield call(postData, url, postbody, token, showCommonError);
    }
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to fetch brand details
 */
export function* fetchEmailerDetails(id, token) {
  try {
    const url = `${API_URL.FETCH_BY_ORGANIZATIONS}/${id}/branding/emailer`;
    const data = yield call(getData, url, "", token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 *
 * @param type
 * @returns {Promise<any>}
 */
export function* updateEmailerDetails(
  postbody,
  token,
  id,
  showCommonError = true
) {
  try {
    let data = {};
    let url = `${API_URL.FETCH_BY_ORGANIZATIONS}/${id}/branding/emailer`;
    if (postbody.branding_from === "SELF") {
      data = yield call(putData, url, postbody, token, showCommonError);
    } else {
      data = yield call(postData, url, postbody, token, showCommonError);
    }
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * to reset user password
 */
export function* resetUserPassword(userId, token) {
  try {
    let data;
    data = yield call(
      postData,
      `${API_URL.FETCH_USERS}/${userId}/resetpassword`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to reset user password
 */
export function* resendUserVerificationEmail(userId, token) {
  try {
    let data;
    data = yield call(
      postData,
      `${API_URL.FETCH_USERS}/${userId}/verificationemail`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getLogoImageURL(postbody, id, token) {
  try {
    return yield call(
      postDataMultipart,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${id}/branding/logo`,
      postbody,
      token,
      true /* showCommonError */
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getLoginImageURL(postbody, id, token) {
  try {
    return yield call(
      postDataMultipart,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${id}/branding/loginimage`,
      postbody,
      token,
      true /* showCommonError */
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchHypervisorList(queryString, token, pollingAction) {
  try {
    return yield call(
      getData,
      API_URL.FETCH_HYPERVISOR_LIST,
      queryString,
      token,
      pollingAction
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchStorageArrayList(queryString, token, pollingAction) {
  try {
    return yield call(
      getData,
      API_URL.FETCH_STORAGE_ARRAY,
      queryString,
      token,
      pollingAction
    );
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * gets all the suborgs for a org
 */
export function* fetchCustomerList(id, token) {
  try {
    let queryString = "pagination=false&sort=organization_name";
    return yield call(
      getData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${id}/accounts`,
      queryString,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * gets all the suborgs for a org
 */
export function* fetchMSPChildAccounts(id, token) {
  try {
    let queryString = "pagination=false&sort=organization_name";
    return yield call(
      getData,
      `${API_URL.FETCH_BY_ORGANIZATIONS_V2}/${id}/accounts`,
      queryString,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to get hypervisors count
 */
export function* getHypervisorCounts(makeQueryString, token) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_HYPERVISORS_COUNTS,
      makeQueryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to get source groups count
 */
export function* getSourceGroupCounts(makeQueryString, token) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_SOURCE_GROUPS_COUNTS,
      makeQueryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to get users count
 */
export function* getUserCounts(makeQueryString, token) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_USER_COUNTS,
      makeQueryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to get roles count
 */
//eslint-disable-next-line require-yield
export function* getRoleCounts(makeQueryString, token, stateLogin) {
  try {
    // const data = yield call(
    //   getData,
    //   API_URL.FETCH_ROLE_COUNTS,
    //   makeQueryString,
    //   token
    // );
    const data = {
      status: 200,
      data: {
        amount:
          stateLogin.organizationInfo.organization_type !== "direct" ? 10 : 4,
        // state.ccDataGrid.user_roles.api.data.length
        amount_type: "Role"
      },
      errors: []
    };
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * assign customer(s) to a user
 */
export function* assignCustomersToUser(userId = "", body, token) {
  try {
    const url = API_URL.FETCH_USERS + "/" + userId + "/assignaccounts";
    const data = yield call(postData, url, body, token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* downloadAgents(id, token) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${id}/agentdownloads`,
      "",
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * to fetch network configured port details
 * @param type
 * @returns {*}
 */
export function* fetchNetworkPortForwardDetails(organization_id, token) {
  try {
    let response = yield call(
      getData,
      API_URL.FETCH_NETWORK_PORT_FORWARD_LIST,
      `organization_id=${organization_id}`,
      token
    );

    return response;
  } catch (e) {
    window.console.log(e);
  }
}

export function* refreshVMs(id, token) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_HYPERVISOR_LIST}/${id}/refreshvms`,
      {},
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getDestinationsForDropdowns(query, token) {
  try {
    return yield call(getData, API_URL.FETCH_DESTINATIONS, query, token);
  } catch (error) {
    window.console.log(error);
  }
}
/**
 *to save network configured vms list
 * @param type
 * @returns {Promise<any>}
 */
export function* updateNetworkConfigList(postbody, id, token) {
  try {
    const data = yield call(
      putData,
      `${API_URL.UPDATE_NETWORK_CONFIG_LIST}?recoveredresource_id=${id}`,
      postbody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to get customers by user id
 */
export function* getCustomersByUser(userId, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_USERS}/${userId}/assignedaccounts`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get customer accounts list from the BE API
 */
export function* fetchCustomerAccounts(queryString, token, organization_id) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${organization_id}/accounts`,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to fetch network configured vms list
 */
export function* fetchNetworkConfigList(organization_id, token) {
  try {
    let response = yield call(
      getData,
      API_URL.FETCH_NETWORK_CONFIG_LIST,
      `organization_id=${organization_id}&pagination=false`,
      token
    );

    return response;
  } catch (e) {
    window.console.log(e);
  }
}
/*
 * delete network config list by id
 */
export function* deleteNetworkConfigRow(id, token, data) {
  try {
    const response = yield call(
      deleteData,
      `${
        API_URL.UPDATE_NETWORK_CONFIG_LIST
      }?recoveredresource_id=${id}&rule_name=${data && data.rule_name}`,
      token
    );
    return response;
  } catch (e) {
    window.console.log(e);
  }
}
/*
 * add a new network config list
 */
export function* addNetworkConfigurationList(postDataBody, id, token) {
  try {
    let data = yield call(
      postData,
      `${API_URL.UPDATE_NETWORK_CONFIG_LIST}?recoveredresource_id=${id}`,
      postDataBody,
      token
    );

    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/*
 * to download session logs for network configuration
 */
export function* downloadNetworkConfigSessionLogs(organization_id, token) {
  try {
    let response = yield call(
      getPlainTextData,
      API_URL.DOWNLOAD_SESSION_LOG,
      `org_id=${organization_id}`,
      token
    );

    return response;
  } catch (e) {
    window.console.log(e);
  }
}
export function* fetchOrganizationVPNDetails(organizationID, token) {
  try {
    return yield call(
      getData,
      `${API_URL.FFTCH_ORGANIZATION_VPN_DETAILS}`,
      "org_id=" + organizationID,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteHypervisor(
  hypervisorId,
  token,
  showCommonError = false
) {
  try {
    const response = yield call(
      deleteData,
      `${API_URL.FETCH_HYPERVISOR_LIST}/${hypervisorId}`,
      token,
      {},
      showCommonError
    );
    return response;
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteStorageArray(storageArrayId, token) {
  try {
    const response = yield call(
      deleteData,
      `${API_URL.FETCH_STORAGE_ARRAY}/${storageArrayId}`,
      token
    );
    return response;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchDefaultDeployment(orgId, token) {
  try {
    return yield call(
      getData,
      `${API_URL.DEPLOYMENT_SETTINGS}`,
      "organization_id=" + orgId,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchSourceDiscoverySettings(orgId, token) {
  try {
    return yield call(
      getData,
      `${API_URL.SOURCE_DISCOVERY}`,
      `organization_id=${orgId}`,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchSourceDiscoveryList(queryString, token) {
  try {
    return yield call(
      getData,
      `${API_URL.AUTODISCOVERIES}`,
      queryString,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteSourceDiscovery(id, token) {
  try {
    return yield call(deleteData, `${API_URL.AUTODISCOVERIES}/${id}`, token);
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteSourceDiscoveryList(listOfIds, token) {
  try {
    return yield call(
      deleteData,
      `${API_URL.AUTODISCOVERIES}`,
      token,
      listOfIds
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* postDiscoverFromAD(data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.AUTODISCOVERIES}/activedirectory`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* postDiscoverFromHyperV(data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.AUTODISCOVERIES_V2}/hyperv`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* postDiscoverFromVmware(data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.AUTODISCOVERIES_V2}/vmware`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* postSourceDiscoveryRunNow(data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.AUTODISCOVERIES}/runnow`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* putDefaultDeployment(
  configId,
  data,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      putData,
      `${API_URL.DEPLOYMENT_SETTINGS}/${configId}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* putSourceDiscoverySettings(
  configId,
  data,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      putData,
      `${API_URL.SOURCE_DISCOVERY}/${configId}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* updateVMInformation(
  hypervisorId,
  token,
  data = {},
  showCommonError = true
) {
  try {
    let apiData = yield call(
      postData,
      `${API_URL.FETCH_HYPERVISOR_LIST}/${hypervisorId}/updatevminformation`,
      data,
      token,
      showCommonError
    );

    return apiData;
  } catch (e) {
    window.console.log(e);
  }
}

export function* updateStorageArray(
  storageArrayId,
  token,
  data = {},
  showCommonError = true
) {
  try {
    let apiData = yield call(
      putData,
      `${API_URL.FETCH_STORAGE_ARRAY}/${storageArrayId}`,
      data,
      token,
      showCommonError
    );

    return apiData;
  } catch (e) {
    window.console.log(e);
  }
}

export function* addStorageArray(token, data = {}, showCommonError = true) {
  try {
    let apiData = yield call(
      postData,
      `${API_URL.FETCH_STORAGE_ARRAY}`,
      data,
      token,
      showCommonError
    );

    return apiData;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * API call to update the VMware, vcenter & esxhost hypervisors
 * @param {*} id - hypervisor id
 * @param {*} data - form data
 * @param {*} token - token
 */
export function* updateVMwareVCenterESXHost(
  id,
  data,
  token,
  showCommonError = false
) {
  try {
    const resp = yield call(
      putData,
      `${API_URL.UPDATE_VMWARE_VCENTER_ESXHOST}/${id}`,
      data,
      token,
      showCommonError
    );
    return resp;
  } catch (error) {
    window.console.log(error);
  }
}

/**
 * API call to update the hyper-v hypervisor
 * @param {*} id - hypervisor id
 * @param {*} data - form data
 * @param {*} token - token
 */
export function* updateHyperV(id, data, token, showCommonError = false) {
  try {
    const resp = yield call(
      putData,
      `${API_URL.UPDATE_HYPERV}/${id}`,
      data,
      token,
      showCommonError
    );
    return resp;
  } catch (error) {
    window.console.log(error);
  }
}

/**
 * API call to update the Nutanix AHV hypervisors
 * @param {*} id - hypervisor id
 * @param {*} data - form data
 * @param {*} token - token
 */
export function* updateNutanixAHV(id, data, token, showCommonError = false) {
  try {
    const resp = yield call(
      putData,
      `${API_URL.UPDATE_NUTANIX_AHV}/${id}`,
      data,
      token,
      showCommonError
    );
    return resp;
  } catch (error) {
    window.console.log(error);
  }
}

/**
 * API call to update the Proxies
 * @param {*} id - proxy id
 * @param {*} data - form data
 * @param {*} token - token
 */
export function* updateProxies(id, data, token, showCommonError = false) {
  try {
    const resp = yield call(
      putData,
      `${API_URL.UPDATE_PROXIES}/${id}`,
      data,
      token,
      showCommonError
    );
    return resp;
  } catch (error) {
    window.console.log(error);
  }
}

export function* disableTwoFactor(id, token) {
  try {
    const resp = yield call(
      putData,
      `${API_URL.FETCH_USERS}/${id}/twofactor/disable`,
      {},
      token
    );
    return resp;
  } catch (error) {
    window.console.log(error);
  }
}

export function* editOrganization(id, data, token) {
  try {
    const resp = yield call(
      putData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${id}`,
      data,
      token
    );
    return resp;
  } catch (error) {
    window.console.log(error);
  }
}

/**
 * Upgrade site api handler
 * @param {String} id
 * @param {String} token
 * @param {Object} data
 * @returns {Object}
 */
export function* upgradeSite(id, token, data = {}) {
  try {
    const url = API_URL.UPGRADE_SITE.replace("id", id);
    let apiResponse = yield call(
      postData,
      url,
      data,
      token,
      true /* showCommonError */
    );
    return apiResponse;
  } catch (e) {
    window.console.log(e);
  }
}

export function* addHypervisorHyperVApi(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.ADD_HYPERVISOR_HYPERV,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* addHypervisorVmwareApi(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.ADD_HYPERVISOR_VMWARE,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* addHypervisorNutanixApi(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.ADD_HYPERVISOR_NUTANIX,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

import React from "react";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import DropdownButton from "../dropdown-button-mui";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { ID_PREFIX } from "configs/IdConfig";
import FormattedBrandMessage from "components/common/FormattedBrandMessage";

const LoadingDots = testId => {
  return (
    <div id={testId} className="loading-dropdown-dots">
      {" "}
      &nbsp;
      <div className="state-loading-bar" />
      <div className="state-loading-bar" />
      <div className="state-loading-bar" />
    </div>
  );
};

const ActionDropdown = ({
  showDateRange,
  onMenuItemClick,
  optionIndex,
  render,
  hasi18nSupport,
  disabledOptions,
  skipDefaultOption,
  defaultOptionKey,
  intl,
  defaultOptionValue,
  color,
  endIcon,
  variant,
  pullRight,
  dropup,
  loading,
  size,
  customButtonStyle,
  id,
  testId,
  keyAsTestId,
  ...props
}) => {
  /**
   * stop event propagation to table header cell
   */
  const handlebuttonClicked = e => {
    const _elemToStop = e.currentTarget.parentNode.parentNode;
    if (_elemToStop.id === "grid-header-dropdown-wrapper") {
      e.stopPropagation();
    }
  };

  const handleOnSelect = eventKey => {
    let event = Object.assign(
      {},
      {
        ...eventKey,
        showPicker: showDateRange,
        key: "single_select"
      }
    );
    if (onMenuItemClick) {
      render
        ? onMenuItemClick(optionIndex, [{ ...event }])
        : onMenuItemClick(eventKey);
    }
  };

  const processMenuItem = (
    item,
    optionIndex,
    isDisabled = false,
    menuid,
    keyAsTestId
  ) => {
    let customKey = item.value
      ? item.value.toString()
      : item.key
      ? item.key.toString()
      : item.toString();
    return item ? (
      <MenuItem
        id={
          menuid
            ? `${menuid}_${
                keyAsTestId ? customKey.split(".").pop() : optionIndex
              }`
            : ""
        }
        key={optionIndex}
        onClick={() => handleOnSelect(item)}
        disabled={isDisabled}
        style={{ fontSize: 14 }}
      >
        {hasi18nSupport ? (
          <FormattedBrandMessage
            id={
              item.value
                ? item.value.toString()
                : item.key
                ? item.key.toString()
                : item.toString()
            }
            defaultMessage={
              item.value
                ? item.value.toString()
                : item.key
                ? item.key.toString()
                : item.toString()
            }
          />
        ) : (
          <span>{typeof item === "object" ? item.key : item}</span>
        )}
      </MenuItem>
    ) : (
      false
    );
  };

  /**
   * to render menu items for dropdown
   */
  const renderMenuItem = (menuid, keyAsTestId) => {
    if (props.options && props.options.length + disabledOptions.length === 0)
      return <div />;
    let options = cloneDeep(props.options);
    if (!skipDefaultOption) {
      options.unshift({
        key: hasi18nSupport
          ? defaultOptionKey
          : intl.formatMessage({
              id: defaultOptionKey,
              defaultMessage: defaultOptionKey
            }),
        value: defaultOptionValue
      });
    }
    return (
      options &&
      options
        .map((item, index) =>
          processMenuItem(item, index, false, menuid, keyAsTestId)
        )
        .concat(
          disabledOptions.map((item, index) =>
            processMenuItem(item, index, true, menuid, keyAsTestId)
          )
        )
    );
  };

  const dropdownClass = props.title ? get(props, "className", "") : "no-text";

  let title = props.title ? (
    typeof props.title === "string" ? (
      <FormattedBrandMessage id={props.title} defaultMessage={props.title} />
    ) : (
      props.title
    )
  ) : (
    ""
  );

  let menuid = testId ? `${ID_PREFIX}${testId}` : id ? id : "";

  return (
    <React.Fragment>
      <DropdownButton
        title={title}
        color={color}
        variant={variant || "default"}
        endIcon={endIcon}
        pullRight={pullRight}
        menuAlign={get(props, "menuAlign", "left")}
        dropup={dropup}
        className={dropdownClass}
        onClick={handlebuttonClicked}
        customButtonStyle={customButtonStyle}
        size={size}
        disabled={
          props.disabled
            ? props.disabled
            : props.options && !props.options.length
        }
        id={id}
        testId={testId}
      >
        {renderMenuItem(menuid ? `${menuid}_menu` : "", keyAsTestId)}
      </DropdownButton>
      {/* Show loading dots on dropdown */}
      {loading && (
        <LoadingDots testId={menuid ? `${menuid}_loadingdots` : ""} />
      )}
    </React.Fragment>
  );
};

ActionDropdown.defaultProps = {
  dropup: false,
  pullRight: true,
  disabled: false,
  hasi18nSupport: true,
  options: [],
  title: "",
  optionIndex: 0,
  render: false,
  showDateRange: false,
  skipDefaultOption: true,
  defaultOptionKey: "select",
  defaultOptionValue: null,
  onMenuItemClick: () => {},
  disabledOptions: [],
  className: "",
  loading: false
};

ActionDropdown.propTypes = {
  defaultOptionKey: PropTypes.string,
  defaultOptionValue: PropTypes.string,
  dropup: PropTypes.bool,
  pullRight: PropTypes.bool,
  menuAlign: PropTypes.string,
  title: PropTypes.any,
  disabled: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  disabledOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  onMenuItemClick: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  hasi18nSupport: PropTypes.bool,
  optionIndex: PropTypes.number,
  render: PropTypes.bool,
  showDateRange: PropTypes.bool,
  skipDefaultOption: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool
};

export default injectIntl(React.memo(ActionDropdown));

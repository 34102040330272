import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { isPristine } from "redux-form";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { injectIntl } from "react-intl";
import {
  updateManageSavedSearchName,
  updateSearchText,
  editUpdateManageSavedSearch,
  deleteSavedSearchData,
  clearAllSearchTags,
  searchList,
  showManageSearchModal,
  updateCurrentSavedSearchMarkDef,
  updateCurrentSavedSearchText,
  flushSelectedSavedSearch,
  getAdvancedSearchOptions
} from "state/actions/actions";
import TextField from "@mui/material/TextField";
import SpogModal from "components/common/modal-mui";
import AdvancedSearch from "components/common/advanced-search-mui";
import FormattedMessage from "components/common/formatted-message";
import Overlay from "components/common/overlay-mui";
import Checkbox from "@mui/material/Checkbox";
import IconMUI from "components/common/icon/IconMUI";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  makeShowManagedSearchModalState,
  makeAdvancedSearchOptionsState,
  makeSearchOptionState,
  makeSearchTextState,
  makeSelectedSavedSearchState,
  makeShowCurrentSearchState,
  makeUserSearchDataState,
  makeSelectedSavedSearchNameState,
  makeSelectedSavedSearchIdState
} from "state/selectors/CommonSelectors";
import {
  getAdvancedSearchNameLabel,
  getMinimumSearchLength
} from "utils/SpogDataGridUtil";
import { SEARCH_TEXT_MAPPING } from "utils/appConstants";
import SavedSearchList from "./SavedSearchList";
import Button from "components/common/button";

const ManageSavedSearches = ({
  selectedSavedSearch,
  userSearchData,
  pristine,
  clearAllSearchTags,
  showManageSearchModal,
  flushSelectedSavedSearch,
  searchList,
  updateCurrentSavedSearchMarkDef,
  deleteSavedSearchData,
  editUpdateManageSavedSearch,
  updateSearchText,
  filterType,
  updateCurrentSavedSearchText,
  updateManageSavedSearchName,
  intl,
  selectedSavedSearchId,
  show,
  options,
  searchOption,
  getAdvancedSearchOptions,
  dataGridId,
  ...props
}) => {
  const searchNameInputRef = React.useRef(null);
  const [searchFieldsChangeFlag, setSearchFieldsChangeFlag] = useState(true);

  useEffect(() => {
    // Focus on input element
    //searchNameInputRef.current.focus();
    if (options.length <= 0 && filterType == "sources") {
      let advancedSearchOptions = [],
        searchText = "",
        param = {},
        sourceGroupId = 0;
      getAdvancedSearchOptions({
        advancedSearchOptions,
        searchText,
        filterType,
        param,
        sourceGroupId
      });
    }
  }, []);

  useEffect(() => {
    const filter_id = get(selectedSavedSearch, "filter_id", null);
    const orignalData =
      userSearchData &&
      userSearchData.filter(row => row.filter_id === filter_id)[0];
    const filterName = isEqual(
      get(selectedSavedSearch, "filter_name", null),
      get(orignalData, "filter_name", null)
    );
    const isDefault = isEqual(
      get(selectedSavedSearch, "is_default", null),
      get(orignalData, "is_default", null)
    );
    const groupName = isEqual(
      get(selectedSavedSearch, "group_name", "").trim(),
      get(orignalData, "group_name", "").trim()
    );
    const organizationName = isEqual(
      get(selectedSavedSearch, "organization_name", "").trim(),
      get(orignalData, "organization_name", "").trim()
    );

    if (
      !filterName ||
      !isDefault ||
      !groupName ||
      !pristine ||
      !organizationName
    ) {
      setSearchFieldsChangeFlag(false);
    } else {
      setSearchFieldsChangeFlag(true);
    }
  }, [selectedSavedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDisabledStateOnFilterName = text =>
    !(text && text.trim().length > 2);
  const getDisabledStateOnSearchText = text => {
    if (!text) return false;
    else if (text && text.length > 2) return false;
    else if (text && text.length === 0) return false;
    else return true;
  };

  /**
   * close event for modal
   */
  const handleCloseModalEvent = () => {
    clearAllSearchTags();
    showManageSearchModal(false);
    flushSelectedSavedSearch();
  };

  // //TODO: Cleanup
  // const handleSearchClick = () => {
  //   const advancedSearchOptions = [];
  //   const searchText = props.searchText;
  //   searchList({
  //     gridCurrentPage: 1,
  //     advancedSearchOptions,
  //     searchText
  //   });
  // };

  /**
   * checkbox on change
   */
  const markDefault = event => {
    updateCurrentSavedSearchMarkDef(event.target.checked);
  };

  /**
   * delete click action
   */
  const dispatchSavedSearchDeleteAction = () => {
    if (!(selectedSavedSearch && selectedSavedSearch.filter_id)) return;

    showManageSearchModal(false);
    clearAllSearchTags();
    deleteSavedSearchData(
      selectedSavedSearch.filter_id,
      filterType,
      dataGridId
    );
  };

  /**
   * save click action
   */
  const dispatchEditUpdateAction = () => {
    showManageSearchModal(false);
    editUpdateManageSavedSearch(filterType, false, dataGridId);
    updateSearchText("");
  };

  /**
   * saved search string on change
   */
  const handleSearchTextChange = event => {
    updateCurrentSavedSearchText(event.target.value);
  };

  /**
   * saved search name on change
   */
  const handleSearchNameChange = event => {
    updateManageSavedSearchName(event.target.value);
    if (
      event.target.value.trim().length > getMinimumSearchLength(intl.locale) &&
      event.target.value !== searchTextValMapping
    ) {
      setSearchFieldsChangeFlag(false);
    } else {
      setSearchFieldsChangeFlag(true);
    }
  };

  let toggleAdvancedSearch = "collapse show d-flex";
  const isDefault = selectedSavedSearch && selectedSavedSearch.is_default;
  let navigation = filterType;
  let searchTextValMapping =
    selectedSavedSearch[SEARCH_TEXT_MAPPING[navigation]];
  let title = intl.formatMessage({ id: "manage_saved_search" });
  let isDisabled = selectedSavedSearchId === selectedSavedSearch.filter_id;
  let disableClass = isDisabled ? "manage-saved-active-wrapper" : "";

  return (
    <SpogModal
      id="managedSavedSearch"
      className="manage-saved-searches-modal large"
      show={show}
      size="lg"
      hideClose={false}
      handleCloseModalEvent={handleCloseModalEvent}
      title={
        <div className="d-flex align-items-center justify-content-between">
          <div>
            {title}
            <span style={{ marginLeft: "5px" }}>
              <Overlay
                type="tooltip"
                placement="right"
                content={
                  <FormattedMessage
                    id="manage.saved.search.heading"
                    defaultMessage="This window shows all the Saved Searches for this table view. Select a Saved Search from the list on the left to view its relevant details. You can modify the search criteria, rename or delete from here."
                  />
                }
              >
                <IconMUI icon={InfoOutlinedIcon} />
              </Overlay>
            </span>
          </div>
        </div>
      }
    >
      <div className="d-flex section-wrapper mui-modal-content">
        <div className="col-3 left-section">
          <SavedSearchList filterType={navigation} />
        </div>
        <div className="col-9 p-0 d-flex flex-column right-section justify-content-between">
          {isDisabled && (
            <span className="manage-saved-search-disable-text">
              <FormattedMessage
                id="manage.saved_search.disable"
                defaultMessage="Active filter can't be edited or deleted."
              />
            </span>
          )}
          <div
            className={`${disableClass} d-flex flex-column justify-content-start manage-saved-search-form`}
          >
            <div className="manage-saved-search-fields">
              <div className="d-flex flex-column">
                <span className="content-item-title">
                  <FormattedMessage
                    id="protect.sources.manageSavedSearch.savedSearchName"
                    defaultMessage="Saved Search Name"
                  />
                </span>
                {/* SAVED SEARCH NAME FIELD */}
                {/* <input
                  type="text"
                  value={
                    selectedSavedSearch.filter_name
                      ? selectedSavedSearch.filter_name
                      : ""
                  }
                  onChange={event => handleSearchNameChange(event)}
                  maxLength={60}
                  autoComplete={false}
                  ref={searchNameInputRef}
                  readOnly={isDisabled}
                  // ref={input => (this.savedSearchName = input)}
                /> */}
                <TextField
                  value={
                    selectedSavedSearch.filter_name
                      ? selectedSavedSearch.filter_name
                      : ""
                  }
                  onChange={event => handleSearchNameChange(event)}
                  ref={searchNameInputRef}
                  InputProps={{
                    readOnly: isDisabled
                  }}
                  className="input-field-mui spog-mui-textfield"
                />
                <span className="error-message-color">
                  {selectedSavedSearch?.filter_name?.length <
                    getMinimumSearchLength(intl.locale) && (
                    <FormattedMessage
                      id="advanced.search.input.error.char"
                      values={{
                        min: intl.locale === "ja" ? "1" : "3"
                      }}
                    />
                  )}
                </span>
              </div>
              <div className="d-flex flex-column search-string-input">
                <span className="content-item-title">
                  <FormattedMessage
                    id={getAdvancedSearchNameLabel(navigation)}
                    defaultMessage="Name contains"
                  />
                </span>
                {/* SAVED SEARCH SOURCE NAME FIELD */}

                {/* <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "search_string",
                    defaultMessage: "Search String"
                  })}
                  value={searchTextValMapping ? searchTextValMapping : ""}
                  onChange={handleSearchTextChange}
                  autoComplete={false}
                /> */}

                <TextField
                  value={searchTextValMapping ? searchTextValMapping : ""}
                  onChange={handleSearchTextChange}
                  className="input-field-mui spog-mui-textfield"
                />
                <span className="error-message-color">
                  {searchTextValMapping?.length <
                    getMinimumSearchLength(intl.locale) && (
                    <FormattedMessage
                      id="advanced.search.input.error.char"
                      values={{
                        min: intl.locale === "ja" ? "1" : "3"
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
            {/* CHECK BOX FOR MARK AS DEFAULT SAVED SEARCH */}
            <div className="default-checkbox-wrapper">
              <Checkbox
                checked={isDefault || false}
                onChange={markDefault}
                id={"mark-default"}
              />
              <label htmlFor="mark-default">
                <FormattedMessage
                  id="manage.saved.search.default"
                  defaultMessage="Make as default table view"
                />
              </label>
            </div>
            <div className="manage-saved-searches-modal">
              <AdvancedSearch
                className={toggleAdvancedSearch}
                options={options}
                placeholder="props.placeholder"
                isMenuCloseOnSelect={false}
                isDropdownDisabled={false}
                isMultiSelect
                searchOption={searchOption}
                hideSearchButtonInModal
                isRenderedInModal
                skipDefaultOption={false}
                defaultValue
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mui-modal-footer d-flex justify-content-between">
        {/* <button
              type="button"
              className="btn btn-secondary"
              onClick={() => dispatchSavedSearchDeleteAction()}
              disabled={selectedSavedSearchId === selectedSavedSearch.filter_id}
            >
              <FormattedMessage
                id="manage.saved.search.delete"
                defaultMessage="Delete"
              />
            </button> */}
        <Button
          color="secondary"
          content={
            <FormattedMessage
              id="manage.saved.search.delete"
              defaultMessage="Delete"
            />
          }
          onClick={() => dispatchSavedSearchDeleteAction()}
          disabled={selectedSavedSearchId === selectedSavedSearch.filter_id}
          style={{ margin: "5px" }}
          variant="contained"
        />
        <div>
          {/* <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCloseModalEvent}
              >
                <FormattedMessage
                  id="manage.saved.search.cancel"
                  defaultMessage="Cancel"
                />
              </button> */}
          <Button
            color="secondary"
            content={
              <FormattedMessage
                id="manage.saved.search.cancel"
                defaultMessage="Cancel"
              />
            }
            onClick={handleCloseModalEvent}
            style={{ margin: "5px" }}
            variant="contained"
          />
          {/* <button
                type="button"
                className="btn btn-primary"
                onClick={e => dispatchEditUpdateAction(e)}
                disabled={
                  searchFieldsChangeFlag ||
                  getDisabledStateOnFilterName(
                    selectedSavedSearch.filter_name
                  ) ||
                  getDisabledStateOnSearchText(searchTextValMapping) ||
                  selectedSavedSearchId === selectedSavedSearch.filter_id
                }
              >
                <FormattedMessage
                  id="manage.saved.search.save"
                  defaultMessage="Save"
                />
              </button> */}
          <Button
            color="primary"
            content={
              <FormattedMessage
                id="manage.saved.search.save"
                defaultMessage="Save"
              />
            }
            onClick={e => dispatchEditUpdateAction(e)}
            disabled={
              searchFieldsChangeFlag ||
              getDisabledStateOnFilterName(selectedSavedSearch.filter_name) ||
              getDisabledStateOnSearchText(searchTextValMapping) ||
              selectedSavedSearchId === selectedSavedSearch.filter_id
            }
            style={{ margin: "5px" }}
            variant="contained"
          />
        </div>
      </div>
    </SpogModal>
  );
};

ManageSavedSearches.defaultProps = {
  selectedSavedSearch: {},
  show: false,
  searchOption: {}
};

ManageSavedSearches.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchText: PropTypes.string.isRequired,
  showManageSearchModal: PropTypes.func.isRequired,
  searchList: PropTypes.func.isRequired,
  clearAllSearchTags: PropTypes.func.isRequired,
  selectedSavedSearch: PropTypes.shape({
    filter_name: PropTypes.string,
    is_default: PropTypes.bool,
    source_name: PropTypes.string,
    destination_name: PropTypes.string,
    search_string: PropTypes.string,
    policy_name: PropTypes.string,
    filter_id: PropTypes.string
  }),
  selectedSavedSearchId: PropTypes.string.isRequired,
  deleteSavedSearchData: PropTypes.func.isRequired,
  editUpdateManageSavedSearch: PropTypes.func.isRequired,
  updateSearchText: PropTypes.func.isRequired,
  updateManageSavedSearchName: PropTypes.func.isRequired,
  updateCurrentSavedSearchMarkDef: PropTypes.func.isRequired,
  flushSelectedSavedSearch: PropTypes.func.isRequired,
  updateCurrentSavedSearchText: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
  show: PropTypes.bool,
  searchOption: PropTypes.shape({}),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  pristine: PropTypes.bool.isRequired
};

/**
 * Using makeMapStateToProps to memoize a selectorFunction for reusability
 * This will create a new instance of selector function
 * every time it is needed for proper memoization in multiple components.
 */
const makeMapStateToProps = () => {
  /**
   * create a new instance of selector function
   * This makes it independent of any other selector function
   * and it will properly memoize across multiple mounted & connected components.
   */
  const getShowManagedSearchModalState = makeShowManagedSearchModalState();
  const getAdvancedOptionsState = makeAdvancedSearchOptionsState();
  const getAdvancedSearchOptionsState = makeSearchOptionState();
  const getSearchTextState = makeSearchTextState();
  const getShowCurrentSearchState = makeShowCurrentSearchState();
  const getDefaultSearchDataState = makeUserSearchDataState();
  const getSelectedSavedSearchState = makeSelectedSavedSearchState();
  const getSelectedSavedSearchNameState = makeSelectedSavedSearchNameState();
  const getSelectedSavedSearchIdState = makeSelectedSavedSearchIdState();

  const mapStateToProps = (state, props) => {
    return {
      /**
       * Use selector function instance to update Props.
       */
      showManageModal: getShowManagedSearchModalState(state, props),
      options: getAdvancedOptionsState(state, props),
      searchOption: getAdvancedSearchOptionsState(state, props),
      searchText: getSearchTextState(state, props),
      showCurrentSearch: getShowCurrentSearchState(state, props),
      userSearchData: getDefaultSearchDataState(state, props),
      selectedSavedSearch: getSelectedSavedSearchState(state, props),
      selectedSavedSearchName: getSelectedSavedSearchNameState(state, props),
      selectedSavedSearchId: getSelectedSavedSearchIdState(state, props),
      pristine: isPristine("advancedSearch")(state)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showManageSearchModal,
      searchList,
      clearAllSearchTags,
      getAdvancedSearchOptions,
      deleteSavedSearchData,
      editUpdateManageSavedSearch,
      updateSearchText,
      updateManageSavedSearchName,
      updateCurrentSavedSearchMarkDef,
      flushSelectedSavedSearch,
      updateCurrentSavedSearchText
    },
    dispatch
  );

export default React.memo(
  injectIntl(
    connect(makeMapStateToProps, mapDispatchToProps)(ManageSavedSearches)
  )
);

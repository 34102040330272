import moment from 'moment'

export function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  return JSON.parse(window.atob(base64))
}

function checkAuthorization(loginToken, loginTokenExpiry) {
  //If Token not available in state and Token expiry =< current date, logout.
  //console.log("token-", loginToken);
  //console.log("token expiry-", loginTokenExpiry);
  if (!loginToken) {
    return { redirect: false, tokenExpire: false }
  } else {
    const parsedToken = parseJwt(loginToken)
    // console.log(
    //   "redirect-",
    //   moment(parsedToken.exp * 1000).diff(moment(), "minutes")
    // );
    // console.log(
    //   "tokenexpiry",
    //   moment(parsedToken.exp * 1000).diff(moment(), "minutes")
    // );
    // return {
    //   redirect: loginTokenExpiry  > Date.now(),
    //   tokenExpire: !(loginTokenExpiry > Date.now())
    // };

    // return {
    //   redirect:
    //     moment(parsedToken.exp * 1000 - 8.574e7).diff(moment(), "minutes") > 0,
    //   tokenExpire:
    //     moment(parsedToken.exp * 1000 - 8.574e7).diff(moment(), "minutes") < 0
    // };
    console.log(moment(parsedToken.exp * 1000).diff(moment(), "minutes"));
    return {
      redirect: moment(parsedToken.exp * 1000).diff(moment(), 'minutes') > 0,
      tokenExpire: moment(parsedToken.exp * 1000).diff(moment(), 'minutes') < 0,
    }
  }
}

export default checkAuthorization

import "react-grid-layout/css/styles.css";
import React from "react";
import _ from "lodash";
//import Select from "react-select";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { getLogin } from "state/selectors";
import { BRAND_NAMES } from "utils/appConstants";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FormattedMessage from "components/common/formatted-message";
import { ID_PREFIX } from "configs/IdConfig";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LoadingDots = classToAdd => {
  return (
    <div
      style={{
        minWidth: "185px",
        display: "flex",
        height: "10px",
        justifyContent: "center",
        alignItems: "center"
      }}
      className={classToAdd ? classToAdd : ""}
    >
      {" "}
      &nbsp;
      <div className="state-loading-bar" />
      <div className="state-loading-bar" />
      <div className="state-loading-bar" />
    </div>
  );
};

const SelectDropdown = ({
  dropdownOptions,
  selectedOption,
  testId,
  id,
  loading = false,
  classToAdd,
  ...props
}) => {
  const handleChange = (event, newValues, reason, currentSelectedOption) => {
    let updatedValues = [];

    if (currentSelectedOption && currentSelectedOption.option) {
      if (currentSelectedOption.option.value === "select_all") {
        if (reason === "removeOption") {
          updatedValues = [];
        } else {
          updatedValues = [...dropdownOptions];
        }
      } else {
        updatedValues = newValues.length ? [...newValues] : [];
      }
    }

    props.getSelectedOption(
      props.optionType,
      updatedValues
        .filter(item => item.value !== "select_all")
        .map(item => {
          return {
            value: item.value,
            label: item.label,
            id: item.id ? item.id : ""
          };
        })
    );
  };

  let brandNames = _.get(props, "brand_names", BRAND_NAMES);
  const dropdownAllOptions =
    dropdownOptions.map(option => {
      return {
        ...option,
        label: props.hasi18nSupportByLabel
          ? props.intl.formatMessage(
              {
                id: option.label,
                defaultMessage: option.label
              },
              { ...brandNames }
            )
          : props.hasi18nSupport
          ? props.intl.formatMessage(
              {
                id: option.value,
                defaultMessage: option.value
              },
              { ...brandNames }
            )
          : option.label
      };
    }) || [];

  if (props.isMultiSelect) {
    dropdownAllOptions.length &&
      dropdownAllOptions.unshift({
        value: "select_all",
        key: "select-all",
        label: props.intl.formatMessage({
          id: "select_all",
          defaultMessage: "Select All"
        })
      });
  }

  let options = selectedOption ? selectedOption.map(item => item.value) : [];
  if (selectedOption && selectedOption.length) {
    if (dropdownAllOptions.length === options.length + 1) {
      options.unshift("select_all");
    }
  } else {
    options = [];
  }
  const selectedValues = dropdownAllOptions
    ? dropdownAllOptions.filter(item => {
        return options.indexOf(item.value) !== -1;
      })
    : [];

  const textFieldClassname = props.label
    ? "multiselectDropdown"
    : "multiselectDropdown spog-mui-textfield";

  let selectDropdownId = testId ? `${ID_PREFIX}${testId}` : id ? id : "";

  return (
    <Autocomplete
      disabled={loading}
      noOptionsText={
        <FormattedMessage id="no_options" defaultMessage="No options" />
      }
      openText={null}
      closeText={null}
      className="mui-action-select-dropdown"
      multiple
      id={selectDropdownId ? selectDropdownId : "checkboxes-tags"}
      limitTags={1}
      //size="small"
      options={dropdownAllOptions}
      disableCloseOnSelect
      getOptionLabel={option => option.label}
      onChange={handleChange}
      value={[...selectedValues]}
      renderTags={value => {
        return selectedValues.length === 1 ? (
          <Chip
            label={props.intl.formatMessage(
              {
                id: selectedValues[0].label,
                defaultMessage: selectedValues[0].label
              },
              { ...brandNames }
            )}
            sx={{
              "& .MuiChip-label": {
                paddingRight: 0
              }
            }}
            variant="outlined"
            style={{ border: "none", width: "50%" }}
          />
        ) : (
          <Chip
            label={
              selectedValues.length
                ? `${
                    selectedValues.filter(item => item.value !== "select_all")
                      .length
                  } ${props.intl.formatMessage({
                    id: "Selected",
                    defaultMessage: "Selected"
                  })}`
                : null
            }
            variant="outlined"
            style={{ border: "none" }}
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 3 }}
              checked={options.indexOf(option.value) > -1}
              id={`${selectDropdownId}_checkbox_${option.value}`}
            />
            {option.label}
          </li>
        );
      }}
      style={props.style ? { ...props.style } : {}}
      renderInput={params => (
        <TextField
          {...params}
          label={loading ? LoadingDots(classToAdd) : props.label}
          placeholder={
            !selectedValues.length
              ? props.placeholder
                ? props.intl.formatMessage({
                    id: props.placeholder,
                    defaultMessage: props.placeholder
                  })
                : loading
                ? LoadingDots(classToAdd)
                : "Select"
              : " "
          }
          className={textFieldClassname}
          sx={{
            "& .MuiOutlinedInput-root.Mui-disabled": {
              "& > fieldset": {
                backgroundColor: "rgba(0,0,0,0.12)",
                color: "rgba(0,0,0,0.26)"
              }
            }
          }}
        />
      )}
    />
  );
};

SelectDropdown.defaultProps = {
  hasi18nSupport: true,
  isMultiSelect: true,
  isMenuCloseOnSelect: false,
  isDropdownDisabled: false,
  selectedOption: [],
  class: "",
  noResultsText: "select.noResults"
};

SelectDropdown.propTypes = {
  dropdownOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  placeholder: PropTypes.string.isRequired,
  isMenuCloseOnSelect: PropTypes.bool,
  isDropdownDisabled: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  selectedOption: PropTypes.arrayOf(PropTypes.shape({})),
  getSelectedOption: PropTypes.func.isRequired,
  optionType: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  class: PropTypes.string,
  hasi18nSupport: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  noResultsText: PropTypes.string
};

const makeMapStateToProps = () => {
  const mapStateToProps = state => ({
    brand_names: getLogin(state).brand_names
  });

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(injectIntl(SelectDropdown));

/**
 * List of supported column types for data grid
 */
export const DataGridConstants = {
  /**
   * Enumerates the list of grid column types that are supported for display
   */
  GridColumnCellTypes: {
    CHECKBOX_CELL: "checkbox-cell",
    CHECKBOX_CELL_MUI: "checkbox-cell-mui",
    LINK_CELL: "link-cell",
    LINK_CELL_MUI: "link-cell-mui",
    LINK_CELL_WITH_OVERLAY_MUI: "link-cell-with-overlay-mui",
    LINK_CELL_WITH_ICON: "link-cell-with-icon",
    LINK_CELL_WITH_ICON_MUI: "link-cell-with-icon-mui",
    ICON_CELL: "icon-cell",
    ICON_CELL_MUI: "icon-cell-mui",
    ICON_CELL_MUI_WITHOUT_OVERLAY: "icon-cell-mui-without-overlay",
    OVERLAY_CELL: "overlay-cell",
    OVERLAY_CELL_MUI: "overlay-cell-mui",
    NUMERICAL_TEXT_CELL: "numerical-text-cell",
    NUMERICAL_TEXT_CELL_MUI: "numerical-text-cell-mui",
    SHORT_TEXT_CELL: "short-text-cell",
    SHORT_TEXT_CELL_MUI: "short-text-cell-mui",
    LONG_TEXT_CELL: "long-text-cell",
    LONG_TEXT_CELL_MUI: "long-text-cell-mui",
    ACTION_DROPDOWN_CELL: "action-dropdown-cell",
    ACTION_DROPDOWN_CELL_MUI: "action-dropdown-cell-mui",
    ACTION_SELECT_DROPDOWN_CELL_MUI: "action-select-dropdown-cell-mui",
    ICON_TEXT_CELL: "icon-text-cell",
    ICON_TEXT_CELL_MUI: "icon-text-cell-mui",
    MULTILINE_TEXT_CELL: "multiline-text-cell",
    MULTILINE_TEXT_CELL_MUI: "multiline-text-cell-mui",
    TWOLINE_TEXT_CELL: "twoline-text-cell",
    TWOLINE_TEXT_CELL_MUI: "twoline-text-cell-mui",
    PROGRESS_TEXT_CELL: "progress-text-cell",
    PROGRESS_TEXT_CELL_MUI: "progress-text-cell-mui",
    PROGRESS_TEXT_CELL_WITHOUT_PERCENTAGE_MUI:
      "progress-text-cell-without-percentage-mui",
    MULTI_TYPE: "multi-type",
    MULTI_TYPE_MUI: "multi-type-mui",
    IMAGE_CELL: "image-cell",
    IMAGE_CELL_MUI: "image-cell-mui",
    EDIT_DROPDOWN_CELL: "edit-dropdown-cell",
    EDIT_DROPDOWN_CELL_MUI: "edit-dropdown-cell-mui",
    MULTI_SELECT_DROPDOWN_CELL: "multi-select-dropdown",
    MULTI_SELECT_DROPDOWN_CELL_MUI: "multi-select-dropdown-mui",
    ID_VALUE_CELL: "id-value-cell",
    ID_VALUE_CELL_MUI: "id-value-cell-mui",
    DATE_TIME_CELL: "date-time-cell",
    DATE_TIME_CELL_MUI: "date-time-cell-mui",
    DURATION: "duration",
    DURATION_MUI: "duration-mui",
    DURATION_MOMENT_MUI: "duration-moment-mui",
    SINGLE_SELECT_DROPDOWN_CELL: "single-select-dropdown",
    SINGLE_SELECT_DROPDOWN_CELL_MUI: "single-select-dropdown-mui",
    SINGLE_SELECT_SCROLL_DROPDOWN_CELL: "single-select-scroll-dropdown",
    SINGLE_SELECT_SCROLL_DROPDOWN_CELL_MUI: "single-select-scroll-dropdown-mui",
    INPUT_CELL: "input-cell",
    INPUT_CELL_MUI: "input-cell-mui",
    PORT_INPUT_CELL_MUI: "port-input-cell-mui",
    RADIO_BUTTON_CELL: "radio-button-cell",
    RADIO_BUTTON_CELL_MUI: "radio-button-cell-mui",
    EMPTY_TEXT_CELL: "empty-text-cell",
    EMPTY_TEXT_CELL_MUI: "empty-text-cell-mui",
    LATEST_JOB_LINK_CELL: "latest-job-link-cell",
    LATEST_JOB_LINK_CELL_MUI: "latest-job-link-cell-mui",
    LATEST_JOB_TEXT_CELL: "latest-job-text-cell",
    LATEST_JOB_TEXT_CELL_MUI: "latest-job-text-cell-mui",
    EXTERNAL_LINK_CELL: "external-link-cell",
    EXTERNAL_LINK_CELL_MUI: "external-link-cell-mui",
    RENDER_POLICY_OVERLAY_CELL: "policy-overlay-cell",
    RENDER_POLICY_OVERLAY_CELL_MUI: "policy-overlay-cell-mui",
    RENDER_ACCOUNTS_OVERLAY_CELL_MUI: "accounts-overlay-cell-mui",
    RENDER_DESTINATION_OVERLAY_CELL: "destination-overlay-cell",
    RENDER_DESTINATION_OVERLAY_CELL_MUI: "destination-overlay-cell-mui",
    RENDER_POLICY_OVERLAY_CELL_WITHOUT_LINK: "policy-overlay-cell-without-cell",
    RENDER_POLICY_OVERLAY_CELL_WITHOUT_LINK_MUI:
      "policy-overlay-cell-without-cell-mui",
    RENDER_ACCOUNTS_OVERLAY_CELL_WITHOUT_LINK_MUI:
      "accounts-overlay-cell-without-cell-mui",
    RENDER_ROLE_OVERLAY_CELL: "role-overlay-cell",
    RENDER_ROLE_OVERLAY_CELL_MUI: "role-overlay-cell-mui",
    STATE_LOADER_CELL: "state-loader-cell",
    STATE_LOADER_CELL_MUI: "state-loader-cell-mui",
    WRAPPED_CELL: "wrapped-cell",
    WRAPPED_CELL_MUI: "wrapped-cell-mui",
    WRAPPED_CELL_WITH_HYPERLINK: "wrapped-cell-hyperlink",
    WRAPPED_CELL_WITH_HYPERLINK_MUI: "wrapped-cell-hyperlink-mui",
    HOVER_CELL: "hover-cell",
    HOVER_CELL_MUI: "hover-cell-mui",
    MULTIPLE_POLICY_LINK_CELL: "mutiple-policy-link-cell",
    MULTIPLE_POLICY_LINK_CELL_MUI: "mutiple-policy-link-cell-mui",
    COMPONENT_CELL: "component-cell",
    COMPONENT_CELL_MUI: "component-cell-mui",
    ICON_LINK_CELL: "icon-link-cell",
    ICON_LINK_CELL_MUI: "icon-link-cell-mui",
    CELL_ICON: "cell-icon",
    CELL_ICON_MUI: "cell-icon-mui",
    FORMATTED_LOCALIZED_TEXT_CELL: "localization-text-cell",
    FORMATTED_LOCALIZED_TEXT_CELL_MUI: "localization-text-cell-mui",
    BYTE_CELL: "byte-cell",
    BYTE_CELL_MUI: "byte-cell-mui",
    JOB_TYPE_CELL: "job-type-cell",
    JOB_TYPE_CELL_MUI: "job-type-cell-mui",
    BUTTON_MUI: "button-mui",
    BUTTON_ACTION: "button_action",
    BUTTON_ACTION_MUI: "button_action-mui"
  },

  GridColumnStatusTypes: {
    IN_PROGRESS: "inprogress",
    SCHEDULED: "scheduled"
  },
  /**
   * Enumerates the list of grid column width depending on type of column that are supported for display
   */
  GridColumnCellWidth: {
    CHECKBOX_CELL: 50,
    CHECKBOX_CELL_MUI: 50,
    LINK_CELL: 150,
    LINK_CELL_MUI: 150,
    LINK_CELL_WITH_OVERLAY_MUI: 150,
    EXTERNAL_LINK_CELL: 150,
    EXTERNAL_LINK_CELL_MUI: 150,
    ICON_CELL: 100,
    ICON_CELL_MUI: 100,
    OVERLAY_CELL: 150,
    OVERLAY_CELL_MUI: 150,
    OVERLAY_CELL_MAX: 200,
    OVERLAY_CELL_MAX_MUI: 200,
    NUMERICAL_TEXT_CELL: 150,
    NUMERICAL_TEXT_CELL_MUI: 150,
    SHORT_TEXT_CELL: 150,
    SHORT_TEXT_CELL_MUI: 150,
    EMPTY_TEXT_CELL: 150,
    EMPTY_TEXT_CELL_MUI: 150,
    LONG_TEXT_CELL: 250,
    LONG_TEXT_CELL_MUI: 250,
    HOVER_CELL: 250,
    HOVER_CELL_MUI: 250,
    ACTION_DROPDOWN_CELL: 90,
    ACTION_DROPDOWN_CELL_MUI: 50,
    ICON_TEXT_CELL: 150,
    ICON_TEXT_CELL_MUI: 150,
    MULTILINE_TEXT_CELL: 250,
    MULTILINE_TEXT_CELL_MUI: 250,
    TWOLINE_TEXT_CELL: 250,
    TWOLINE_TEXT_CELL_MUI: 250,
    PROGRESS_TEXT_CELL: 250,
    PROGRESS_TEXT_CELL_MUI: 250,
    PROGRESS_TEXT_CELL_WITHOUT_PERCENTAGE_MUI: 200,
    IMAGE_CELL: 250,
    IMAGE_CELL_MUI: 250,
    EDIT_DROPDOWN_CELL: 250,
    EDIT_DROPDOWN_CELL_MUI: 250,
    MULTI_SELECT_DROPDOWN_CELL: 250,
    MULTI_SELECT_DROPDOWN_CELL_MUI: 250,
    DATE_TIME_CELL: 200,
    DATE_TIME_CELL_MUI: 200,
    DURATION: 150,
    DURATION_MUI: 150,
    DURATION_MOMENT_MUI: 150,
    MULTILINE_CONTENT_CELL: 250,
    MULTILINE_CONTENT_CELL_MUI: 250,
    ID_VALUE_CELL: 150,
    ID_VALUE_CELL_MUI: 150,
    SINGLE_SELECT_DROPDOWN_CELL: 150,
    SINGLE_SELECT_DROPDOWN_CELL_MUI: 150,
    SINGLE_SELECT_SCROLL_DROPDOWN_CELL: 150,
    SINGLE_SELECT_SCROLL_DROPDOWN_CELL_MUI: 150,
    INPUT_CELL: 100,
    INPUT_CELL_MUI: 100,
    RADIO_BUTTON_CELL: 100,
    RADIO_BUTTON_CELL_MUI: 100,
    WRAPPED_CELL: 200,
    WRAPPED_CELL_MUI: 200,
    CELL_ICON: 100,
    CELL_ICON_MUI: 100,
    BUTTON_MUI: 100,
    BUTTON_ACTION: 100,
    BUTTON_ACTION_MUI: 100
  },

  /**
   * Enumerates the grid column header types that are supported for display
   */
  GridColumnHeaderTypes: {
    HEADER_TEXT: "header-text",
    HEADER_TEXT_MUI: "header-text-mui",
    HEADER_SELECT: "header-select",
    HEADER_SELECT_MUI: "header-select-mui",
    HEADER_TEXT_SELECT: "header-text-select",
    HEADER_TEXT_SELECT_MUI: "header-text-select-mui",
    ICON_LINK_CELL_MUI: "icon-link-cell-mui"
  },

  /**
   * Enumerates the overlay types that are supported for display
   */
  OverlayTypes: {
    TOOLTIP: "tooltip",
    POPOVER: "popover"
  }
};

export default DataGridConstants;

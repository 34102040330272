import memoize from "fast-memoize";

import { ALL_CSR_USERS } from "utils/appConstants";

export const isCSRUser = roleId => ALL_CSR_USERS.includes(roleId);

export const isMonitorUser = roleId => roleId && roleId.indexOf("monitor") > -1;

export const isMspMonitorUser = roleId => roleId === "msp_monitor";
export const isMspAccountMonitorUser = roleId =>
  roleId === "msp_account_monitor";
export const isRecoveryUser = roleId =>
  roleId && roleId.indexOf("recovery") > -1;

export const isDirectRecoveryAdminUser = roleId =>
  roleId && roleId.indexOf("direct_recovery_admin") > -1;

export const isDirectMonitorUser = roleId => roleId === "direct_monitor";

export const isDirectAdminUser = roleId => roleId === "direct_admin";

export const isReadOnly = roleId => roleId === "csr_read_only";

export const isMspAccountAdmin = roleId => roleId === "msp_account_admin";

export const isMspRecoveryAdmin = roleId => roleId === "msp_recovery_admin";

export const isMspAccountRecoveryAmdin = roleId =>
  roleId === "msp_account_recovery_admin";

export const isCSRAdmin = roleId => roleId === "csr_admin";

export const createNamespace = namespace => attr => `${namespace}.${attr}`;

export const rotateArray = (arr, right = false) => {
  if (right) arr.unshift(arr.pop());
  else arr.push(arr.shift());
  return arr;
};

// for fast-memoize
export const cacheLastValue = {
  create() {
    let lastKey = undefined;
    let lastValue = undefined;
    return {
      has: key => key === lastKey,
      get: key => (key === lastKey ? lastValue : undefined),
      set: (key, value) => {
        lastKey = key;
        lastValue = value;
      }
    };
  }
};

export const createTreeConversionFunction = (
  getId,
  getParentId,
  customCache = cacheLastValue
) => {
  const funct = list => {
    // all root nodes have a parent_id of null
    const lookupChildren = { null: [] };

    // default lookup table for id -> children
    list.forEach(n => (lookupChildren[getId(n)] = []));

    // populate children
    list.forEach(n => lookupChildren[getParentId(n)].push(n));

    const roots = lookupChildren[null];

    const appendChildren = n => ({
      ...n,
      children: lookupChildren[getId(n)].map(child => appendChildren(child))
    });

    return roots.map(r => appendChildren(r));
  };

  return memoize(funct, { cache: customCache });
};

export const getTextWidth = (text, font) => {
  //get width of the text in div for the given font style
  let canvas = document.createElement("canvas");
  let context = canvas.getContext("2d");
  context.font = font;
  let metrics = context.measureText(text);
  return metrics.width;
};

export const ordinalSuffixOfDate = number => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

import React, { useRef } from "react";
import get from "lodash/get";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getTextWidth } from "utils";
import { CCGRAPH_IDS } from "utils/appConstants";
import { withCCGraph } from "components/common/CCGraph/HOCs";
import * as sGraph from "state/selectors/ccGraph";

import Wrapper from "./RechartWrapper";
import InsufficientData from "components/common/CCGraph/Charts/InsufficientData";
import FormattedMessage from "components/common/formatted-message";
import {
  Bar as RechartBar,
  BarChart,
  XAxis,
  Cell,
  YAxis,
  Tooltip,
  Label
} from "recharts";
import Paper from "@mui/material/Paper";
import TooltipComponent from "./TooltipComponent";
const loadingState = (width, height) => (
  <svg
    className="load-state bar-load-state"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 711 325"
    width={width}
    height={height}
  >
    <defs>
      <linearGradient id="lgrad" x1="0%" y1="50%" x2="100%" y2="50%">
        <stop offset="0" className="loading-charts" stopOpacity="0.8" />
        <stop offset="33.33%" className="loading-charts" stopOpacity="0.8" />
        <stop offset="50%" className="loading-charts" stopOpacity="0" />
        <stop offset="66.66%" className="loading-charts" stopOpacity="0.8" />
        <stop offset="100%" className="loading-charts" stopOpacity="0.8" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 29)">
        <g className="loading-state-dotted-line" strokeDasharray="4 4">
          <path d="M.400592911 200.302477L710.674315 200.302477M.400592884 131.172748L710.674315 131.172748M.324410282 64.2071321L710.621499 64.2071321M.291004517.563888874L709.82431.563888889" />
        </g>
        <g className="chart-bottom-line" transform="translate(63.08 11)">
          <polygon points="-.08 229 33.92 229 33.92 255 -.08 255" />
          <polygon points="599.92 229 633.92 229 633.92 255 599.92 255" />
          <polygon points="101.738 201 135.738 201 135.738 255 101.738 255" />
          <polygon points="305.374 0 339.374 0 339.374 255 305.374 255" />
          <polygon points="509.011 201 543.011 201 543.011 255 509.011 255" />
          <polygon points="203.556 115 237.556 115 237.556 255 203.556 255" />
          <polygon points="407.192 115 441.192 115 441.192 255 407.192 255" />
        </g>
      </g>
      <polygon
        className="chart-bottom-line"
        fillRule="nonzero"
        points="0 295 711 295 711 294 0 294"
      />
    </g>
    <rect
      className="chart-filter"
      fill="url(#lgrad)"
      x="-100%"
      y="0"
      width="300%"
      height="100%"
    ></rect>
  </svg>
);

const InsufficientBarChartData = ({ id, width, height }) => {
  return (
    <>
      <div className="insufficient-rps-data-container">
        <FormattedMessage
          id={id ? id : "dashboard.insufficientData"}
          defaultMessage="Insufficient data to render this widget."
        />
      </div>
      <svg
        className="load-state bar-load-state"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 711 325"
        width={width}
        height={height}
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(0 50)">
            <g className="loading-state-dotted-line" strokeDasharray="4 4">
              <path d="M.400592911 200.302477L710.674315 200.302477M.400592884 131.172748L710.674315 131.172748M.324410282 64.2071321L710.621499 64.2071321M.291004517.563888874L709.82431.563888889" />
            </g>
            <g
              className="chart-bottom-line"
              transform="translate(63.08 11)"
              opacity={0.1}
            >
              <polygon points="-.08 0 33.92 0 33.92 234 -.08 234" />
              <polygon points="101.738 42 135.738 42 135.738 234 101.738 234" />
              <polygon points="203.556 82 237.556 82 237.556 234 203.556 234" />
              <polygon points="305.374 115 339.374 115 339.374 234 305.374 234" />
              <polygon points="407.192 169 441.192 169 441.192 234 407.192 234" />
              <polygon points="509.011 201 543.011 201 543.011 234 509.011 234" />
              <polygon points="599.92 219 633.92 219 633.92 234 599.92 234" />
            </g>
          </g>
          <polygon
            className="chart-bottom-line"
            fillRule="nonzero"
            points="0 295 711 295 711 294 0 294"
          />
        </g>
        <rect
          className="chart-filter"
          fill="url(#lgrad)"
          x="-100%"
          y="0"
          width="300%"
          height="100%"
        ></rect>
      </svg>
    </>
  );
};

const Bar = props => {
  const wrapperRef = useRef();
  let fixRightPosition = 0;
  const BarChartData =
    props.DATA && props.DATA.length > 0
      ? props.DATA
      : props.data && props.data.length > 0
      ? props.data
      : []; //props.DATA is used when we pass data from other component
  BarChartData &&
    BarChartData.forEach(ele => {
      let textLength = Math.abs(
        getTextWidth(
          props.intl.formatMessage({
            id: ele.name
          }),
          `11px ${"Sans Source Pro"}`
        ) * Math.cos(Math.radians(22))
      );
      fixRightPosition = textLength;
    });
  const CustomizedTick = tickProps => {
    const { x, y, payload } = tickProps;
    if (!payload) {
      console.log("error");
      return null;
    }
    return (
      <g transform={`translate(${x},${y})`} height={"100%"}>
        <text
          y={7}
          fontFamily={"Sans Source Pro"}
          fontWeight={400}
          fontSize={11}
          textAnchor="middle"
          fill="#8f8f8f"
        >
          {props.intl.formatMessage({
            id: payload.value
          })}
        </text>
      </g>
    );
  };
  const CustomizedXAxisTick = tickProps => {
    const { x, y, payload } = tickProps;
    if (!payload) {
      console.log("error");
      return null;
    }
    let value = props.intl.formatMessage({
      id: payload.value
    });
    value = value.match(/.{1,15}/g);
    // the x and y values for below text tags are obtained by trial and error method , while changing them make sure you dont break it at other places
    return (
      <g
        className="XaxisText"
        transform={`translate(${x},${y}) rotate(25)`}
        height={"100%"}
      >
        {value.length === 1 && (
          <>
            <text
              x={0}
              y={4}
              textAnchor="start"
              className="barchart-x-axis-label"
            >
              {value[0]}
            </text>
          </>
        )}
        {value.length > 1 && (
          <>
            <text
              x={0}
              y={3}
              textAnchor="start"
              className="barchart-x-axis-label"
            >
              {value[0]}
            </text>
            <text
              x={-15}
              y={10}
              textAnchor="start"
              className="barchart-x-axis-label"
            >
              {value[1]}
              {value.length > 2 && "..."}
            </text>
          </>
        )}
      </g>
    );
  };
  const CustomizedHYTick = tickProps => {
    const { x, y, payload } = tickProps;
    if (!payload) {
      console.log("error");
      return null;
    }
    return (
      <g transform={`translate(${x},${y})`} height={50}>
        <text
          x={-5}
          y={7}
          fontFamily={"Sans Source Pro"}
          fontWeight={400}
          fontSize={14}
          textAnchor="end"
          fill="#8f8f8f"
        >
          {props.intl.formatMessage({
            id: payload.value
          })}
        </text>
      </g>
    );
  };
  const CustomizedVYTick = tickProps => {
    const { x, y, payload } = tickProps;
    if (!payload) {
      console.log("error");
      return null;
    }
    return (
      payload.value && (
        <g transform={`translate(${x},${y})`} height={50}>
          <text
            x={-5}
            y={3}
            fontFamily={"Sans Source Pro"}
            fontWeight={400}
            fontSize={11}
            textAnchor="end"
            fill="#8f8f8f"
          >
            {props.intl.formatMessage({
              id: payload.value
            })}
          </text>
        </g>
      )
    );
  };
  const CustomizedLabel = props => {
    const { chartProps } = props;
    return (
      <g transform={`rotate(-90)`}>
        <text
          x={-220}
          y={30}
          fontFamily={"Sans Source Pro"}
          fontWeight={400}
          fontSize={16}
          textAnchor="start"
          fill="#adb0b6"
        >
          {chartProps.intl.formatMessage({
            id: chartProps.yAxisLabelId
          })}
        </text>
      </g>
    );
  };
  const BarChartComponent = () => {
    if (!wrapperRef || !wrapperRef.current) return null;
    const { width, height } = getComputedStyle(wrapperRef.current);
    let myWidth = parseInt(width, 10) > 0 ? parseInt(width, 10) : 0;
    let myHeight = parseInt(height, 10) > 0 ? parseInt(height, 10) : 0;
    myWidth =
      myWidth === 0
        ? parseInt(
            (props.windowWidth ? props.windowWidth : window.innerWidth) *
              (props.widthAdjust ? props.widthAdjust : 1),
            10
          )
        : myWidth;
    myHeight =
      myHeight === 90
        ? parseInt(
            (props.windowHeight ? props.windowHeight : window.innerHeight) *
              (props.heightAdjust ? props.heightAdjust : 1),
            10
          )
        : myHeight;
    if (props.isLoading) {
      return loadingState(myWidth, myHeight);
    }
    //eslint-disable-next-line eqeqeq
    if (props.fetchApi == false && BarChartData.length === 0) {
      return loadingState(myWidth, myHeight);
    }
    const processedData = !props.order
      ? BarChartData
      : props.order
          .filter(o => BarChartData.find(d => d.id === o))
          .map(o => BarChartData.find(d => d.id === o));

    const isRpsChart = [
      CCGRAPH_IDS.DASHBOARD_NUMBER_OF_RECOVERY_POINTS,
      CCGRAPH_IDS.DASHBOARD_LATEST_RECOVERY_POINTS,
      CCGRAPH_IDS.DASHBOARD_OLDEST_RECOVERY_POINTS,
      CCGRAPH_IDS.REPORTS_LATEST_RECOVERY_POINTS,
      CCGRAPH_IDS.REPORTS_NUMBER_OF_RECOVERY_POINTS,
      CCGRAPH_IDS.REPORTS_OLDEST_RECOVERY_POINTS
    ].includes(props && props.chartConfig && props.chartConfig.ccGraphId);

    const isCountZero = BarChartData?.every(item => item.value === 0);
    if (
      (!props.isLoading && BarChartData.length < 2) ||
      (!props.isLoading && isCountZero)
    ) {
      const id = isRpsChart
        ? "dashboard.norecoverypoints.available"
        : "dashboard.insufficientData";
      return (
        <InsufficientBarChartData id={id} width={myWidth} height={myHeight} />
      );
    }
    return props.horizontalBarchart ? (
      //HORIZONTAL BAR CHART
      <BarChart
        className={props.barChartClassName || ""}
        width={myWidth}
        height={300}
        data={processedData}
        layout={"vertical"}
        margin={{
          right: 50,
          bottom: 50
        }}
      >
        <XAxis
          type={"number"}
          fontWeight={400}
          fontFamily={"Sans Source Pro"}
          tickLine={false}
        />
        <YAxis
          fill="#8f8f8f"
          textAnchor={"start"}
          width={
            (props?.unProtectedSources ||
              props.order?.includes("num_backups_failed")) &&
            props.intl?.locale === "de"
              ? 165
              : (props?.unProtectedSources ||
                  props.order?.includes("num_backups_failed")) &&
                props.intl?.locale === "es"
              ? 190
              : 150
          }
          type={"category"}
          dataKey="name"
          fontSize={14}
          tick={<CustomizedHYTick />}
          fontFamily={"Sans Source Pro"}
          fontWeight={400}
        />
        <Tooltip
          content={<CustomTooltip myWidth={myWidth} chartProps={props} />}
          cursor={{ fill: "transparent" }}
        />
        {/* <Legend /> */}
        <RechartBar
          isAnimationActive={false}
          background={false}
          dataKey="value"
          barSize={37}
        >
          {processedData &&
            processedData.map((entry, index) => (
              <Cell
                $enableBackground={0}
                dataKey={"value"}
                key={`cell-${index}`}
                fill={props.colorSchema ? props.colorSchema[index] : "#2196F3"}
              />
            ))}
        </RechartBar>
      </BarChart>
    ) : (
      <BarChart
        width={myWidth}
        height={myHeight}
        data={BarChartData}
        margin={{
          top: 20,
          // left: 10,
          right: fixRightPosition - 5
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          textAnchor={"start"}
          interval={0}
          angle={22}
          reversed={[
            CCGRAPH_IDS.DASHBOARD_NUMBER_OF_RECOVERY_POINTS,
            CCGRAPH_IDS.DASHBOARD_LATEST_RECOVERY_POINTS,
            CCGRAPH_IDS.DASHBOARD_OLDEST_RECOVERY_POINTS,
            CCGRAPH_IDS.REPORTS_LATEST_RECOVERY_POINTS,
            CCGRAPH_IDS.REPORTS_NUMBER_OF_RECOVERY_POINTS,
            CCGRAPH_IDS.REPORTS_OLDEST_RECOVERY_POINTS
          ].includes(props.chartConfig.ccGraphId)}
          tick={
            props.chartConfig &&
            props.chartConfig.ccGraphId !==
              CCGRAPH_IDS.DASHBOARD_TOP_CUSTOMERS ? (
              <CustomizedTick />
            ) : (
              <CustomizedXAxisTick />
            )
          } //choose the custom xaxistick here
          dataKey="name"
          height={Math.abs(
            getTextWidth("ABCDEFGHIJKLMNOPQRST", `9px ${"Sans Source Pro"}`) *
              Math.sin((Math.PI * 22) / 180) +
              10
          )} //20 characters space is added , but displaying only 15 characters
          fontFamily={"Sans Source Pro"}
          fontWeight={400}
          tickLine={false}
        />
        <YAxis
          fill="#8f8f8f"
          axisLine={isRpsChart ? true : false}
          fontSize={11}
          tick={<CustomizedVYTick />}
          fontFamily={"Sans Source Pro"}
          fontWeight={400}
          // scale={"linear"}
          orientation={isRpsChart ? "left" : "left"}
          interval={"preserveStartEnd"}
          tickCount={3}
          // domain={[0,'dataMax']}
        >
          {props.yAxisLabelId && (
            <Label content={<CustomizedLabel chartProps={props} />} />
          )}
        </YAxis>
        <Tooltip
          content={
            props.chartConfig.ccGraphId ===
            CCGRAPH_IDS.DASHBOARD_TOP_CUSTOMERS ? (
              <TooltipComponent
                color="#80D2C0"
                chartConfig={props.chartConfig}
              />
            ) : (
              <CustomRPSTooltip
                myWidth={myWidth}
                myHeight={myHeight}
                chartProps={props}
              />
            )
          }
          cursor={{ fill: "transparent" }}
        />
        {/* <Legend /> */}
        <RechartBar
          isAnimationActive={false}
          background={false}
          dataKey="value"
          barSize={25}
        >
          {BarChartData &&
            BarChartData.map((entry, index) => (
              <Cell
                enableBackground={0}
                key={`cell-${index}`}
                fill={props.colorSchema ? props.colorSchema[index] : "#2196F3"}
              />
            ))}
        </RechartBar>
      </BarChart>
    );
  };

  return (
    <>
      <Wrapper wrapperRef={wrapperRef}>
        <BarChartComponent props={props} />
      </Wrapper>
    </>
  );
};
const CustomTooltip = ({ active, payload, label, chartProps, ...props }) => {
  if (active && payload && payload.length) {
    let right = 0;
    right += get(payload[0], "payload.label", null)
      ? getTextWidth(
          chartProps.intl.formatMessage({
            id: payload[0].payload.label
          }),
          `13px ${"Sans Source Pro"}`
        )
      : 0;
    right =
      right +
        getTextWidth(payload[0].payload.value, `13px ${"Sans Source Pro"}`) +
        30 +
        props.coordinate.x >
      props.myWidth
        ? props.coordinate.x -
          getTextWidth(
            chartProps.intl.formatMessage({
              id: payload[0].payload.label
            }),
            `13px ${"Sans Source Pro"}`
          ) -
          getTextWidth(payload[0].payload.value, `13px ${"Sans Source Pro"}`) -
          50
        : props.coordinate.x;
    return payload[0].value ? (
      <Paper
        square
        elevation={0}
        className={`CustomTooltip Barchart-tooltip ${
          [
            CCGRAPH_IDS.DASHBOARD_NUMBER_OF_RECOVERY_POINTS,
            CCGRAPH_IDS.DASHBOARD_LATEST_RECOVERY_POINTS,
            CCGRAPH_IDS.DASHBOARD_OLDEST_RECOVERY_POINTS,
            CCGRAPH_IDS.REPORTS_LATEST_RECOVERY_POINTS,
            CCGRAPH_IDS.REPORTS_NUMBER_OF_RECOVERY_POINTS,
            CCGRAPH_IDS.REPORTS_OLDEST_RECOVERY_POINTS
          ].includes(
            chartProps &&
              chartProps.chartConfig &&
              chartProps.chartConfig.ccGraphId
          )
            ? "rp-chart"
            : ""
        }`}
        style={{
          transform: `translate(${right}px, ${props.coordinate.y - 20}px)`
        }}
      >
        {payload.map((ele, key) => {
          return (
            <div key={key} style={{ margin: "13px", marginBottom: "0px" }}>
              {ele.payload.toolTipTitle && (
                <p>
                  {chartProps.intl.formatMessage({
                    id: ele.payload.toolTipTitle
                  })}
                </p>
              )}
              <p>
                {ele.payload.label &&
                  chartProps.intl.formatMessage({
                    id: ele.payload.label
                  })}
                {ele.payload.label && ": "}
                {ele.payload.value}
              </p>
            </div>
          );
        })}
      </Paper>
    ) : null;
  }

  return null;
};
const CustomRPSTooltip = ({ active, payload, label, chartProps, ...props }) => {
  const barChart = document.getElementsByName(payload[0]?.payload?.name);
  let tooltipYPosition = 0;
  if (barChart && barChart.length > 0) {
    tooltipYPosition =
      props.myHeight - 125 - barChart[0].getBoundingClientRect().height;
  }
  if (active && payload && payload.length) {
    return payload[0].value ? (
      <Paper
        square
        elevation={0}
        className={`CustomTooltip Barchart-tooltip ${
          [
            CCGRAPH_IDS.DASHBOARD_NUMBER_OF_RECOVERY_POINTS,
            CCGRAPH_IDS.DASHBOARD_LATEST_RECOVERY_POINTS,
            CCGRAPH_IDS.DASHBOARD_OLDEST_RECOVERY_POINTS,
            CCGRAPH_IDS.REPORTS_LATEST_RECOVERY_POINTS,
            CCGRAPH_IDS.REPORTS_NUMBER_OF_RECOVERY_POINTS,
            CCGRAPH_IDS.REPORTS_OLDEST_RECOVERY_POINTS
          ].includes(
            chartProps &&
              chartProps.chartConfig &&
              chartProps.chartConfig.ccGraphId
          )
            ? "rp-chart"
            : ""
        }`}
        style={{
          position: "relative",
          top: `${tooltipYPosition}px`,
          left: `${props.coordinate.x - 60}px`
        }}
      >
        {payload.map((ele, key) => {
          return (
            <div key={key} style={{ margin: "13px", marginBottom: "0px" }}>
              {ele.payload.toolTipTitle && (
                <p style={{ marginBottom: "0px" }}>
                  {chartProps.intl.formatMessage({
                    id: ele.payload.toolTipTitle
                  })}
                </p>
              )}
              <p className="tooltip-text-divider"></p>
              <p>
                {ele.payload.label &&
                  chartProps.intl.formatMessage({
                    id: ele.payload.label
                  })}
                {ele.payload.label && ": "}
                {ele.payload.value}
              </p>
            </div>
          );
        })}
      </Paper>
    ) : null;
  }

  return null;
};

const makeMapState = (
  outerState,
  { ccGraph: { config: outerConfig }, fetchApi, intl }
) => {
  if (!outerConfig)
    return {
      fetchApi: false
    };
  const getData = sGraph.makeGetBarData(
    outerConfig.ccGraphId,
    outerConfig.graphType
  );
  const getTSBarData = sGraph.makeGetRCBarData(
    outerConfig.ccGraphId,
    outerConfig.graphType,
    intl.locale
  );
  return (state, { ccGraph: { config } }) => ({
    isLoading: sGraph.isLoading(config.ccGraphId)(state),
    originalData: getData(state),
    data: getTSBarData(state),
    chartConfig: outerConfig
  });
};

export default compose(injectIntl, withCCGraph, connect(makeMapState))(Bar);
//eslint-disable-next-line  no-lone-blocks
{
  /* 
    //these objects are declared here , so the dev can know the labels for the keys in api

    const monthNameTransfromer = {
    JANUARY: "Jan",
    FEBRUARY: "Feb",
    MARCH: "Mar",
    APRIL: "Apr",
    MAY: "May",
    JUNE: "Jun",
    JULY: "Jul",
    AUGUST: "Aug",
    SEPTEMBER: "Sep",
    OCTOBER: "Oct",
    NOVEMBER: "Nov",
    DECEMBER: "Dec"
  };
  const latestRpsNameTransformer = {
    quarter_hour: "< 15 min",
    one_hour: "15 min - 1 hour",
    one_day: "1 hour - 1 day",
    week: "1 day - 1 week",
    month: "1 week - 1 month",
    six_month: "1 month - 6 months",
    older: "> 6 months",
    no_backups: "No backups"
  };
  const oldestRpsNameTransformer = {
    week: "< 1 week",
    month: "1 week - 1 month",
    six_month: "1 month - 6 months",
    one_year: "6 months - 1 year",
    two_years: "1 year - 2 years",
    five_years: "2 years - 5 years",
    older: "5 years",
    no_backups: "No backups"
  };
*/
}

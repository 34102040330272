import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import LoadingBar from "react-redux-loading-bar";
import _ from "lodash";
import { changeTitle } from "../../utils/changeTitleUtil";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import * as v from "components/common/redux-form-components/validation";
import FormattedMessage, {
  FormattedHTMLMessage
} from "../common/formatted-message";
import "../../styles/login.css";
import { HTTP_STATUS } from "../../utils/appConstants";
import logoUrl from "../../../src/assets/images/arcserve-logo-black.png";
import enrollIcon from "../../../src/assets/images/enroll-icon-white.png";
import {
  clearForgotPassword,
  forgotPassword
} from "../../state/actions/actions";
import {
  makeEnrollActivationMessageState,
  makeForgotPasswordMessageState
} from "../../state/selectors/enroll";
import { makeLoginBrandState } from "../../state/selectors/LoginSelectors";

let timestamp;
class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  /**
   * Handle flow after component mounts.
   * @override
   */
  componentDidMount() {
    timestamp = new Date().getTime();
    const orgName = _.get(this.props, "brandData.organization_name", "");
    const logoUrl = _.get(this.props, "brandData.logo_img_url", "");
    const brandingFrom = _.get(this.props, "brandData.branding_from", "");
    changeTitle(orgName, `${logoUrl}?${timestamp}`, brandingFrom);
  }

  /**
   * Handle any cleanup before component unmounts.
   * @override
   */
  componentWillUnmount() {
    this.props.dispatch(clearForgotPassword());
  }

  handleSubmitClick(form) {
    this.props.dispatch(clearForgotPassword());
    this.props.dispatch(forgotPassword(form));
  }

  renderField = ({
    input,
    label,
    type,
    disabled,
    id,
    meta: { touched, error }
  }) => (
    <div>
      <input
        {...input}
        placeholder={label}
        type={type}
        id={id}
        disabled={disabled}
        className={touched ? (error ? "error" : "") : ""}
      />
      {touched && error && error.id && (
        <div
          className="user-registration"
          style={{ color: "#f76c83", marginTop: "8px" }}
        >
          <FormattedMessage
            id={error.id}
            values={error.values}
            defaultMessage={error.id}
          />
        </div>
      )}
    </div>
  );

  render() {
    let error =
      this.props.response &&
      this.props.response.errors &&
      this.props.response.errors[0];

    const {
      handleSubmit,
      pristine,
      invalid,
      requesting,
      loaderOpacity,
      loaderHeight
    } = this.props;
    const copyrightMessage = _.get(this.props, "brandData.copyright", "");
    const message = <FormattedHTMLMessage id="message" />;
    let branding_from = this.props.brandData.branding_from;
    // If branding_from is not "DEFAULT_ARCSERVE" then branding message will take from BE response else it will be default arcserve branding message
    const brandMessage =
      branding_from &&
      branding_from !== "DEFAULT_ARCSERVE" &&
      this.props.brandData.branding_msg ? (
        //this needs to be updated along with copyright.text
        <FormattedMessage
          id="user.brandingMessage"
          values={{ message: this.props.brandData.branding_msg }}
        />
      ) : (
        message
      );

    /*
    style={{
      backgroundImage: `url(${loginBg}), linear-gradient(to right, ${"#39324C"} 58%, ${"#196ba2"} 94%)`
    }}
*/

    return (
      <div
        className="enroll-container d-flex align-items-start justify-content-center"
        id="resetPass-container"
      >
        <div className="left-section">
          {/* <img className="logo" alt="Logo" src={logoUrl} /> */}
          {/*If branding_from is DEFAULT_ARCSERVE then arcserve logo will be used else
            If brandingLogo property is true in response data then logo_img_url will be use else login_img_url will be use for logo */}
          {this.props.brandData &&
          this.props.brandData.brandingLogo !== null &&
          this.props.brandData.brandingLogo !== undefined ? (
            <img
              className="logo"
              alt="Logo"
              src={
                branding_from && branding_from === "DEFAULT_ARCSERVE"
                  ? logoUrl
                  : this.props.brandData && this.props.brandData.brandingLogo
                  ? `${this.props.brandData.logo_img_url}?${timestamp}`
                  : `${this.props.brandData.login_img_url}?${timestamp}`
              }
            />
          ) : (
            <div />
          )}

          <div className="login-branding-msg">{brandMessage}</div>
          <span className="copyright-text">
            <FormattedMessage
              id="copyright.text"
              values={{ msg: copyrightMessage }}
            />
          </span>
        </div>
        <form onSubmit={handleSubmit(this.handleSubmitClick)}>
          <div className="right-section d-flex flex-column">
            <div className="right-section-header d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-end">
                <h2>
                  <FormattedMessage
                    id="user.resetpassword.heading"
                    defaultMessage="Reset Password"
                  />
                </h2>
                {
                  //eslint-disable-next-line jsx-a11y/heading-has-content
                  <h5 />
                }
              </div>
              <img alt="enroll-icon" src={enrollIcon} />
            </div>
            {this.props.response.status === HTTP_STATUS.OK && (
              <div className="input-wrapper d-flex flex-column">
                <h6>
                  <FormattedHTMLMessage
                    id="user.registration.email.notification"
                    values={{
                      email: this.props.formValues.email
                    }}
                  />
                </h6>
                <span>
                  <FormattedMessage
                    id="user.resetpassword.activation.text"
                    defaultMessage="Please click on the reset password link sent in the email in order to reset your account pasword."
                  />
                </span>
              </div>
            )}
            {this.props.response.status !== HTTP_STATUS.OK && (
              <div className="input-wrapper d-flex flex-column">
                <div className="input-block">
                  <span>
                    <FormattedMessage
                      id="user.registration.email"
                      defaultMessage="Email"
                    />
                  </span>
                  <Field
                    name="email"
                    component={this.renderField}
                    type="text"
                    validate={[v.required, v.email]}
                    placeholder="Email"
                  />
                </div>
                <span
                  className="error-message"
                  style={{ color: "#f76c83", display: "block" }}
                >
                  {error && error.message}
                </span>
                <div className="nextbtn-container">
                  <button
                    type="submit"
                    id="next"
                    disabled={invalid || requesting || pristine}
                    className="btn btn-primary btn-arrow"
                    onClick={this.handleSubmit}
                  >
                    <FormattedMessage
                      id="user.resetpassword.button.next"
                      defaultMessage="Reset"
                    />
                  </button>
                </div>
              </div>
            )}
            <div style={{ position: "relative" }}>
              <LoadingBar
                style={{
                  opacity: loaderOpacity,
                  height: loaderHeight,
                  top: -1 * loaderHeight
                }}
                showFastActions
                className="loginLoadingBar"
                updateTime={0}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

/**
 * Using makeMapStateToProps to memoize a selectorFunction for reusability
 * This will create a new instance of selector function
 * every time it is needed for proper memoization in multiple components.
 */
const makeMapStateToProps = () => {
  /**
   * create a new instance of selector function
   * This makes it independent of any other selector function
   * and it will properly memoize across multiple mounted & connected components.
   */
  const getEnrollActivationMessageState = makeEnrollActivationMessageState();
  const getForgotPasswordMessageState = makeForgotPasswordMessageState();
  const getLoginBrandState = makeLoginBrandState();

  const mapStateToProps = (state, props) => {
    return {
      /**
       * Use selector function instance to update Props.
       */
      activation_message: getEnrollActivationMessageState(state, props),
      response: getForgotPasswordMessageState(state, props),
      formValues: getFormValues("forgotPassword")(state),
      brandData: getLoginBrandState(state, props)
    };
  };
  return mapStateToProps;
};

/**
 * Bind the dispatch to the function for Redux action dispatcher
 * @param dispatch
 */
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
/**
 * Default properties of component
 * @type {{}}
 */
ForgotPassword.defaultProps = {
  brandData: {
    organization_name: "",
    portal_url: "",
    login_img_url: "",
    logo_img_url: "",
    primary_color: "#28a745",
    secondary_color: "#20c997",
    brandingLogo: false,
    branding_msg:
      "Guaranteed data protection through seamless integrations of backup-restore",
    branding_from: "DEFAULT_ARCSERVE",
    copyright: ""
  },
  loaderOpacity: 0,
  loaderHeight: null,
  pristine: false,
  invalid: false,
  requesting: false,
  activation_message: undefined
};

ForgotPassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loaderOpacity: PropTypes.number,
  loaderHeight: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  requesting: PropTypes.bool,
  successful: PropTypes.bool.isRequired,
  activation_message: PropTypes.shape({
    errors: PropTypes.arrayOf({})
  }),
  brandData: PropTypes.shape({
    organization_name: PropTypes.string,
    portal_url: PropTypes.string,
    branding_msg: PropTypes.string,
    primary_color: PropTypes.string,
    secondary_color: PropTypes.string,
    login_img_url: PropTypes.string,
    logo_img_url: PropTypes.string,
    brandingLogo: PropTypes.bool,
    branding_from: PropTypes.string,
    copyright: PropTypes.string
  })
};

const ForgotPasswordForm = reduxForm({
  form: "forgotPassword"
  // validate
})(ForgotPassword);
const connected = withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(ForgotPasswordForm)
);

export default connected;

import { combineReducers } from "redux";
import rps from "./rps";
import dataStores from "./datastores";
import CloudAccount from "./cloud_account";
import browseRPSDS from "./browseRPSDS";
import recoveryVolumes from "./recoveryVolumes";
import recovery from "./recovery";
import availableDriveLetters from "./availableDriveLetters";
import agentMachines from "./agentMachines";
import ivm from "./ivm";
import ShadowXafe from "./shadow_xafe";

export default combineReducers({
  rps,
  dataStores,
  CloudAccount,
  browseRPSDS,
  recoveryVolumes,
  recovery,
  availableDriveLetters,
  agentMachines,
  ivm,
  ShadowXafe
});

/*
 * Route config: Centrailized route DATA which will be mapped with ROUTE component
 */

import store from "../state/store";
import moduleCreator from "../state/registry/module";

//Get Register from module.js, which will fetch the reducer associated to the component.
const register = moduleCreator(store);
//Get all Route Components along with its reducer.

const Monitor = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "monitor" */ "../components/monitor")
  );
const Dashboard = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "monitor" */ "../components/monitor/dashboard")
  );
const Analyze = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "analyze" */ "../components/analyze")
  );
const Jobs = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "analyze" */ "../components/analyze/jobs")
  );
const Alerts = () =>
  register(
    "reducers",
    import(/*webpackChunkName: "analyze" */ "../components/analyze/alerts")
  );
const JobsGrid = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "analyze" */ "../components/common/jobs")
  );
const Log = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "analyze" */ "../components/common/log")
  );
const Reports = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "analyze" */ "../components/analyze/reports")
  );
const ReportsGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "analyze" */ "../components/analyze/reports/datagrid"
    )
  );
const ReportDetailComponent = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "analyze" */ "../components/analyze/reports/details"
    )
  );
const BackupJobReportComponent = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "analyze" */ "../components/analyze/reports/report-component/backup-job-report"
    )
  );
const SourceProtectionReportComponent = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "analyze" */ "../components/analyze/reports/report-component/sourceProtection-report"
    )
  );
const PolicyTasksReportComponent = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "analyze" */ "components/analyze/reports/report-component/policy-tasks-report"
    )
  );
const RestoreJobReportComponent = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "analyze" */ "../components/analyze/reports/report-component/restore-job-report"
    )
  );
const DataTransferReportComponent = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "analyze" */ "../components/analyze/reports/report-component/data-transfer-report"
    )
  );
const JobsAndDestinationReportComponent = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "analyze" */ "../components/analyze/reports/report-component/jobs-and-destinations-report"
    )
  );
const CapacityUsageReportComponent = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "analyze" */ "../components/analyze/reports/report-component/capacity-usage-report"
    )
  );
const ManageSchedules = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "analyze" */ "../components/analyze/reports/report-component/manage-schedules"
    )
  );
const AccountDetails = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "analyze" */ "../components/analyze/reports/report-component/account-details-report"
    )
  );

const RecoveryPointReport = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "analyze" */ "../components/analyze/reports/report-component/recoveryPoint-report"
    )
  );
const Protect = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "protect" */ "../components/protect")
  );
const Source = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "protect" */ "../components/protect/source")
  );
const SourceGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/source/datagrid"
    )
  );
const Destination = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination"
    )
  );
const DestinationGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination/datagrid"
    )
  );
const ShadowXafeDestinationGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination/shadowxafe"
    )
  );
const Policies = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "protect" */ "../components/protect/policy")
  );
const PolicyGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/policy/datagrid"
    )
  );
const Sites = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "configure" */ "../components/protect/site")
  );
const SitesGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/protect/site/datagrid"
    )
  );
const RecoveredResources = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/recoveredResources"
    )
  );
const AllRecoveredResources = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/recoveredResources/all"
    )
  );
const RecoveredResourcesGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/recoveredResources/datagrid"
    )
  );
const RecoveredResourcesDetails = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/recoveredResources/details"
    )
  );
const InstantVMGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/recoveredResources/instantVM"
    )
  );
const MountedRecoveryPointsGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/recoveredResources/mountedRecoveryPoints/datagrid"
    )
  );

const VirtualStandbyGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/recoveredResources/virtualStandby"
    )
  );

const SecondaryNavBar = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "navbar" */ "../components/secondary-navigator")
  );
const Configure = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "configure" */ "../components/configure")
  );
const Infrastructure = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/infrastructure"
    )
  );
const CloudAccountsDetails = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/infrastructure/cloud-accounts/details"
    )
  );
const Hypervisors = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/infrastructure/hypervisors"
    )
  );
const HypervisorsDetails = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/infrastructure/hypervisors/details"
    )
  );
const StorageArrays = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/infrastructure/storage-arrays"
    )
  );
const OracleHosts = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/infrastructure/oracle-hosts"
    )
  );
const NetworkConfiguration = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/infrastructure/network-configuration"
    )
  );
const ProxiesGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/infrastructure/proxies/datagrid"
    )
  );
const LicenseSubscriptions = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/license-subscriptions"
    )
  );
const Branding = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/branding"
    )
  );
const SourceDiscovery = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/settings/source_discovery"
    )
  );
const DefaultDeployment = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/settings/default_deployment"
    )
  );
const CreatePolicy = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/policy/create-policy"
    )
  );
const DestinationsDetails = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination/details"
    )
  );
const AddSource = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/source/add-source"
    )
  );
const UserProfile = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "userProfile" */ "../components/user-Profile")
  );
const SourceDetails = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/source/details"
    )
  );
const UserAccounts = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/access-control/user-accounts"
    )
  );
const UserRoles = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/access-control/user-roles"
    )
  );
const JobDetails = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/common/jobs/details"
    )
  );
const SourceGoups = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/source-groups"
    )
  );
const SourceGroupsGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/source-groups/datagrid"
    )
  );
const SourceGroupDetail = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/source-groups/detail"
    )
  );
const SourceGroupAddSources = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "configure" */ "../components/configure/source-groups/add-sources"
    )
  );
const Recover = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "recover" */ "../components/recover")
  );
const RecoverDetails = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "configure" */ "../components/recover/details")
  );
const CustomerAccounts = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/customer-accounts"
    )
  );
const CustomerAccountsGrid = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/customer-accounts/datagrid"
    )
  );
const CustomerAccountsDetails = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/customer-accounts/details"
    )
  );

const UserSupport = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "userSupport" */ "../components/user-support")
  );

const Machine = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/source/machines"
    )
  );
const AgentlessVms = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/source/agentless-vms"
    )
  );
const UncNfsPaths = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/source/unc-nfs-paths"
    )
  );
// const CloudDirect = () =>
//   register(
//     "reducers",
//     import(
//       /* webpackChunkName: "protect" */ "../components/protect/destination/cloud-direct"
//     )
//   );
// const CloudHybrid = () =>
//   register(
//     "reducers",
//     import(
//       /* webpackChunkName: "protect" */ "../components/protect/destination/cloud-hybrid"
//     )
//   );
const RemoteConsole = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination/remote-console"
    )
  );
const SharedFolder = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination/shared-folder"
    )
  );
const RecoveryPointServers = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination/rps"
    )
  );
const RecoveryPointServerDetails = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination/rps/details"
    )
  );
const BrowseDSSources = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination/data-store/browse"
    )
  );
const DataStores = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination/data-store"
    )
  );
const DataStoreDetails = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination/data-store/details"
    )
  );
const Downloads = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "userSupport" */ "../components/downloads")
  );
const CloudAccount = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination/cloud-account"
    )
  );

const ModalTest = () =>
  register(
    "reducers",
    import(
      /* webpackChunkName: "protect" */ "../components/protect/destination/ivm/dialogs"
    )
  );

// Route config
// Each logical "route" has two components, one for
// the sidebar and one for the main area. We want to
// render both of them in different places when the
// path matches the current URL.
const routes = [
  {
    path: "/",
    main: Monitor,
    exact: true,
    key: 10001
  },
  {
    path: "/monitor",
    main: Monitor,
    key: 10002,
    exact: true
  },
  {
    path: "/modals",
    main: ModalTest,
    key: 10099,
    exact: true
  },
  {
    path: "/monitor/dashboard",
    main: Dashboard,
    key: 10021,
    exact: true
  },
  {
    path: "/analyze",
    main: Analyze,
    key: 10031,
    exact: false,
    routes: [
      {
        path: "/analyze/jobs",
        key: 10311,
        main: Jobs,
        exact: false,
        routes: [
          {
            path: "/analyze/jobs/all/:id/details",
            main: JobDetails,
            key: 13111,
            exact: false
          },

          {
            path: "/analyze/jobs/all/:jobId/log",
            main: Log,
            key: 13113,
            exact: false
          },

          {
            path: "/analyze/jobs/all",
            key: 13112,
            main: JobsGrid,
            exact: false
          }
        ]
      },
      {
        path: "/analyze/log/all",
        key: 10032,
        main: Log,
        exact: false
      },
      {
        path: "/analyze/alerts/all",
        key: 10033,
        main: Alerts,
        exact: false
      },
      {
        path: "/analyze/reports",
        key: 10313,
        main: Reports,
        exact: false,
        routes: [
          {
            path: "/analyze/reports/all/:reportID/details",
            key: 13131,
            main: ReportDetailComponent,
            exact: false
          },
          {
            path: "/analyze/reports/backup_jobs",
            key: 13132,
            main: BackupJobReportComponent,
            exact: false
          },
          {
            path: "/analyze/reports/policy_tasks",
            key: 13133,
            main: PolicyTasksReportComponent,
            exact: false
          },
          {
            path: "/analyze/reports/restore_jobs",
            key: 13134,
            main: RestoreJobReportComponent,
            exact: false
          },
          {
            path: "/analyze/reports/data_transfer",
            key: 13135,
            main: DataTransferReportComponent,
            exact: false
          },
          {
            path: "/analyze/reports/jobs_and_destinations",
            key: 13136,
            main: JobsAndDestinationReportComponent,
            exact: false
          },
          {
            path: "/analyze/reports/capacity_usage",
            key: 13137,
            main: CapacityUsageReportComponent,
            exact: false
          },
          {
            path: "/analyze/reports/manage_schedules",
            key: 13138,
            main: ManageSchedules,
            exact: false
          },
          {
            path: "/analyze/reports/recovery_point_report",
            key: 13139,
            main: RecoveryPointReport,
            exact: false
          },
          {
            path: "/analyze/reports/all",
            key: 13140,
            main: ReportsGrid,
            exact: false
          },
          {
            path: "/analyze/reports/account_details",
            key: 13141,
            main: AccountDetails,
            exact: false
          },
          {
            path: "/analyze/reports/source_protection", //verify
            key: 13142,
            main: SourceProtectionReportComponent,
            exact: false
          }
        ]
      }
    ]
  },
  {
    path: "/protect",
    main: Protect,
    key: 10004,
    exact: false,
    routes: [
      {
        path: "/protect/sources",
        main: Source,
        key: 10041,
        exact: false,
        routes: [
          {
            path: "/protect/sources/:filterType/:id/:action/:tab",
            main: SourceDetails,
            key: 10410,
            exact: false
          },
          {
            path: "/protect/sources/:filterType/add",
            main: AddSource,
            key: 10412,
            exact: false
          },
          {
            path: "/protect/sources/all",
            main: SourceGrid,
            key: 10411,
            exact: false
          },
          {
            path: "/protect/sources/physical_machines",
            main: Machine,
            key: 10416,
            exact: false
          },
          {
            path: "/protect/sources/virtual_machines",
            main: AgentlessVms,
            key: 10417,
            exact: false
          },
          {
            path: "/protect/sources/unc_nfs_paths",
            main: UncNfsPaths,
            key: 10418,
            exact: false
          }
        ]
      },
      {
        path: "/protect/destinations",
        main: Destination,
        key: 10047,
        exact: false,
        routes: [
          {
            path: "/protect/destinations/:filterType/:id/:action/:tab",
            main: DestinationsDetails,
            key: 10471,
            exact: false
          },
          // {
          //   path: "/protect/destinations/all",
          //   main: DestinationGrid,
          //   key: 100472,
          //   exact: false
          // },
          // {
          //   path: "/protect/destinations/cloud_volumes",
          //   main: CloudDirect,
          //   key: 100473,
          //   exact: false
          // },
          // {
          //   path: "/protect/destinations/cloud_stores",
          //   main: CloudHybrid,
          //   key: 100474,
          //   exact: false
          // },
          {
            path: "/protect/destinations/recovery_point_servers/:id/:tab",
            main: RecoveryPointServerDetails,
            key: 100472,
            exact: true
          },
          {
            path: "/protect/destinations/recovery_point_servers",
            main: RecoveryPointServers,
            key: 100473,
            exact: false
          },
          {
            path: "/protect/destinations/data_stores/:id/sources",
            main: BrowseDSSources,
            key: 100474,
            exact: false
          },
          {
            path: "/protect/destinations/data_stores/:id/:tab",
            main: DataStoreDetails,
            key: 100475,
            exact: true
          },
          {
            path: "/protect/destinations/data_stores",
            main: DataStores,
            key: 100476,
            exact: false
          },
          // {
          //   path: "/protect/destinations/:filterType",
          //   main: DestinationGrid,
          //   key: 100475,
          //   exact: false
          // },
          {
            path: "/protect/destinations/shadow_xafe",
            main: ShadowXafeDestinationGrid,
            key: 100475,
            exact: false
          },
          {
            path: "/protect/destinations/arcserve_cloud",
            main: DestinationGrid,
            key: 100477,
            exact: false
          },
          {
            path: "/protect/destinations/cloud_account",
            main: CloudAccount,
            key: 100478,
            exact: true
          },
          {
            path: "/protect/destinations/remote_console",
            main: RemoteConsole,
            key: 100479,
            exact: true
          },
          {
            path: "/protect/destinations/shared_folder",
            main: SharedFolder,
            key: 100480,
            exact: true
          }
        ]
      },
      {
        path: "/protect/recovered_resources",
        main: RecoveredResources,
        key: 10048,
        exact: false,
        routes: [
          {
            path: "/protect/recovered_resources/all",
            main: AllRecoveredResources,
            key: 100481,
            exact: false
          },
          {
            path: "/protect/recovered_resources/:filterType/:id/:action/:tab",
            main: RecoveredResourcesDetails,
            key: 100482,
            exact: false
          },
          {
            path: "/protect/recovered_resources/recovered_vms",
            main: RecoveredResourcesGrid,
            key: 100483,
            exact: false
          },
          {
            path: "/protect/recovered_resources/instant_vms_rr",
            main: InstantVMGrid,
            key: 100484,
            exact: false
          },
          {
            path: "/protect/recovered_resources/mounted_recovery_points",
            main: MountedRecoveryPointsGrid,
            key: 100485,
            exact: false
          },
          {
            path: "/protect/recovered_resources/virtual_standbys",
            main: VirtualStandbyGrid,
            key: 100486,
            exact: false
          }
        ]
      },
      {
        path: "/protect/policies",
        main: Policies,
        key: 10049,
        exact: false,
        routes: [
          {
            path: "/protect/policies/:id/:action/:tab",
            main: CreatePolicy,
            key: 10492,
            exact: false
          },
          {
            path: "/protect/policies/all",
            main: PolicyGrid,
            key: 100472,
            exact: false
          }
        ]
      },
      {
        path: "/protect/customer-accounts",
        main: CustomerAccounts,
        key: 10091,
        exact: false,
        routes: [
          {
            path: "/protect/customer-accounts/:filterType/:id/:action/:tab",
            main: CustomerAccountsDetails,
            key: 10911,
            exact: false
          },
          {
            path: "/protect/customer-accounts/:filterType",
            main: CustomerAccountsGrid,
            key: 10912,
            exact: false
          }
        ]
      }
    ]
  },
  {
    path: "/configure",
    main: Configure,
    key: 10005,
    exact: false,
    routes: [
      {
        path: "/configure/infrastructure",
        main: Infrastructure,
        key: 10051,
        exact: false,
        routes: [
          {
            path: "/configure/infrastructure/arcserve_cloud/volume/:id",
            main: CloudAccountsDetails,
            key: 10512,
            exact: false
          },
          {
            path: "/configure/infrastructure/arcserve_cloud",
            main: CloudAccountsDetails,
            key: 10511,
            exact: false
          },
          {
            path: "/configure/infrastructure/hypervisors/:id/:action/:tab",
            main: HypervisorsDetails,
            key: 10513,
            exact: false
          },
          {
            path: "/configure/infrastructure/hypervisors",
            main: Hypervisors,
            key: 10514,
            exact: false
          },
          {
            path: "/configure/infrastructure/network_configuration",
            main: NetworkConfiguration,
            key: 10515,
            exact: false
          },
          {
            path: "/configure/infrastructure/sites",
            main: Sites,
            key: 10516,
            exact: false,
            routes: [
              {
                path: "/configure/infrastructure/sites/all",
                main: SitesGrid,
                key: 105161,
                exact: false
              }
            ]
          },
          {
            path: "/configure/infrastructure/storage_arrays",
            main: StorageArrays,
            key: 10518,
            exact: true
          },
          {
            path: "/configure/infrastructure/proxies",
            main: ProxiesGrid,
            key: 10517,
            exact: false
          },
          {
            path: "/configure/infrastructure/oracle_hosts",
            main: OracleHosts,
            key: 10519,
            exact: true
          }
        ]
      },
      {
        path: "/configure/source_groups",
        main: SourceGoups,
        key: 10055,
        exact: false,
        routes: [
          {
            path: "/configure/source_groups/all",
            main: SourceGroupsGrid,
            key: 10413,
            exact: false
          },
          {
            path: "/configure/source_groups/:id/details",
            main: SourceGroupDetail,
            key: 10414,
            exact: false
          },
          {
            path: "/configure/source_groups/:id/source_group_add_sources",
            main: SourceGroupAddSources,
            key: 10415,
            exact: false
          }
        ]
      },
      {
        path: "/configure/access_control/user_accounts",
        main: UserAccounts,
        key: 10056,
        exact: true
      },
      {
        path: "/configure/access_control/user_roles",
        main: UserRoles,
        key: 10057,
        exact: true
      },
      {
        path: "/configure/license_subscriptions",
        main: LicenseSubscriptions,
        key: 10058,
        exact: true
      },
      {
        path: "/configure/branding/all",
        main: Branding,
        key: 10059,
        exact: true
      },
      {
        path: "/configure/settings/source_discovery",
        main: SourceDiscovery,
        key: 10060,
        exact: true
      },
      {
        path: "/configure/settings/default_deployment",
        main: DefaultDeployment,
        key: 10061,
        exact: true
      }
    ]
  },
  {
    path: "/profile",
    main: UserProfile,
    key: 20092,
    sidebar: SecondaryNavBar,
    exact: true
  },
  {
    path: "/recover",
    main: Recover,
    key: 10010,
    exact: true,
    routes: [
      {
        path: "/recover/:tab",
        main: RecoverDetails,
        key: 10091,
        exact: true
      }
    ]
  },
  {
    path: "/user_support",
    main: UserSupport,
    key: 20091,
    sidebar: SecondaryNavBar,
    exact: true
  },
  {
    path: "/user_support/downloads",
    main: Downloads,
    key: 20911,
    sidebar: SecondaryNavBar,
    exact: true
  }
];

export default routes;

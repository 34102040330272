/* eslint-disable camelcase */
import React from "react";
import { FormattedDate, FormattedTime, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconMUI from "components/common/icon/IconMUI";
import ActionDropdown from "components/common/action-dropdown-button-mui";
import Button from "../../button";
import {
  PAGE_ROUTE,
  SECONDARY_NAVIGATION_TYPE
} from "../../../../utils/appConstants";
import {
  getAlertListDetails,
  deleteAlertById,
  // deleteAllAlerts,
  selectMenuAction,
  updateSelectedResource,
  selectFilterAction,
  fromNavigationApi,
  getAdvancedSearchOptions,
  updateJobLogJobData
} from "../../../../state/actions/actions";
import { JobNameNLSKey, JobNameNLSKeyHTML } from "../../jobs/JobsConstants";

/**
 * Parent component that loads all critical alerts
 */
const AlertListTab = ({ intl, history, type, alert }) => {
  // React-Redux Hooks
  const dispatch = useDispatch();

  /**
   * to show/hide alert details modal
   */
  const handleAlertDetailsModal = (id, organization_id) => {
    dispatch(
      getAlertListDetails({
        alert_id: id,
        organization_id
      })
    );
  };
  const actionButtonStyle = {
    maxWidth: "26px",
    maxHeight: "26px",
    minWidth: "26px",
    minHeight: "26px"
  };

  /**
   * Returns 'id' of nls string to be used to display job name
   * @argument job
   */
  const getJobNameKey = (job, flag) => {
    const flagSource = job.source_name && job.source_name.length > 0;
    const flagDestination =
      job.destination_name && job.destination_name.length > 0;

    if (flagSource && flagDestination) {
      return flag ? JobNameNLSKeyHTML.COMPLETE : JobNameNLSKey.COMPLETE;
    }
    if (!flagSource && flagDestination) {
      return flag
        ? JobNameNLSKeyHTML.DESTINATION_ONLY
        : JobNameNLSKey.DESTINATION_ONLY;
    }
    if (flagSource && !flagDestination) {
      if (job.job_type === "deploy_policy_to_udp") {
        return flag
          ? JobNameNLSKeyHTML.FOR_SOURCE_ONLY
          : JobNameNLSKey.FOR_SOURCE_ONLY;
      }
      return flag ? JobNameNLSKeyHTML.SOURCE_ONLY : JobNameNLSKey.SOURCE_ONLY;
    }
    return flag ? JobNameNLSKeyHTML.NAME_ONLY : JobNameNLSKey.NAME_ONLY;
  };

  /**
   * to handle view log click and navigate to source details
   */
  const handleViewLogClick = item => {
    dispatch(fromNavigationApi(true));
    const selectedJob = {
      source_name: item.details.source_name,
      destination_name:
        item.details.destination_names &&
        item.details.destination_names.join(", "),
      job_type: item.details.job_type
    };
    const job_name = intl.formatMessage(
      { id: getJobNameKey(selectedJob, false) },
      {
        name: selectedJob.job_type
          ? intl.formatMessage({
              id: selectedJob.job_type
            })
          : "Job",
        source: selectedJob.source_name,
        destination: selectedJob.destination_name
      }
    );
    dispatch(updateSelectedResource({ ...item, job_name }));

    dispatch(updateJobLogJobData({ ...item, job_name }));
    dispatch(
      selectMenuAction({
        selectedMenu: "Analyze",
        selectedMenuKey: "Analyze"
      })
    );
    setTimeout(() => {
      dispatch(
        selectFilterAction({
          selectedFilter: SECONDARY_NAVIGATION_TYPE.LOG,
          selectedFilterKey: "Log"
        })
      );
    }, 0);
    history.push(
      `${PAGE_ROUTE.ANALYZE}/${SECONDARY_NAVIGATION_TYPE.JOBS}/all/${item.job_id}/${SECONDARY_NAVIGATION_TYPE.LOG}`
    );

    const filter = SECONDARY_NAVIGATION_TYPE.JOBLOG;
    dispatch(
      getAdvancedSearchOptions({
        filter
      })
    );
  };

  /**
   * to handle acknowledge dropdown click
   */
  const handleAcknowledgeClick = id => {
    dispatch(deleteAlertById(id));
  };

  /**
   * to handle clcik of acknowledge all link
   */
  // const handleAcknowledgeAllClick = () => {
  //   dispatch(deleteAllAlerts(type));
  // };

  /**
   * to render alert list in panel
   */
  const renderAlertList = () =>
    alert.map((item, index) => {
      const keyIndex = index;
      return (
        <li key={keyIndex}>
          <span className="heading-wrapper">
            <Button
              type="button"
              content={item.title}
              onClick={() => {
                const id = item.alert_id;
                handleAlertDetailsModal(id, item.organization.organization_id);
              }}
              style={{ justifyContent: "flex-start", whiteSpace: "unset" }}
            />
            <ActionDropdown
              id={item.alert_id}
              title={<IconMUI icon={ArrowDropDownIcon} />}
              options={item.allowed_actions}
              onMenuItemClick={option => {
                const id = item.alert_id;
                // eslint-disable-next-line no-lone-blocks
                {
                  option !== "acknowledge"
                    ? handleViewLogClick(item)
                    : handleAcknowledgeClick(id);
                }
              }}
              color="secondary"
              variant="outlined"
              size="small"
              customButtonStyle={actionButtonStyle}
            />
          </span>
          <label htmlFor="alert-list" className="alert-list-wrapper">
            <Typography component="span" style={{ fontSize: 12 }}>
              <span className="alert-description">{item.description}</span>
            </Typography>
            {item.contact_email && (
              <span className="alert-email">
                {`${item.contact_email} `}
                <br />
              </span>
            )}
            {item.contact_phone && (
              <span>
                {`${item.contact_phone} `}
                <br />
              </span>
            )}
            <span className="timestamp">
              <FormattedDate value={new Date(item.create_ts)} />
              &nbsp;
              <FormattedTime value={new Date(item.create_ts)} />
            </span>
          </label>
        </li>
      );
    });

  // let alerts = alert;
  // let styleObject = { color: linkColor };
  return (
    <Paper elevation={0} square className="alerts-list-panel">
      {/* {alerts && alerts.length > 0 && (
        <Button
          type="button"
          className="acknowledge-link"
          style={styleObject}
          content={
            <FormattedMessage
              id="alerts.acknowledge.all"
              defaultMessage="Acknowledge All"
            />
          }
          onClick={() => handleAcknowledgeAllClick()}
        />
      )} */}
      {alert?.length > 0 && <ul>{renderAlertList()}</ul>}
    </Paper>
  );
};

/**
 * Default Properties of component
 * @type {{alert: *}}
 */
AlertListTab.defaultProps = {
  alert: []
};

/**
 * Properties of component
 * @type {{alert: *}}
 */
AlertListTab.propTypes = {
  type: PropTypes.string.isRequired,
  alert: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

export default React.memo(injectIntl(AlertListTab));

import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  ListItemButton
} from "@mui/material";

import { createTheme } from "@mui/material/styles";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import UserDetails from "./UserDetails";
import UserConfigurations from "./UserConfigurations";
import Skelton from "./Skelton";
import Typography from "@mui/material/Typography";
import { UCID } from "../appConstants";
const darkTheme = createTheme({
  palette: {
    type: "dark"
  }
});

const MainDrawer = ({
  appName,
  name,
  email,
  handleLogout,
  handleMenuClick,
  appPath,
  appsUrl,
  uccIconStyle = {},
  showTitle = false,
  loadFromAPI = false,
  isLoading = false,
  ucBeURL,
  userName,
  showLogout = false,
  token = "",
  ucUrl
}) => {
  const [open, setOpen] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(isLoading);

  useEffect(() => {
    if (loadFromAPI) {
      if (userName && ucBeURL) {
        let URL = `${ucBeURL}/user/apps`;
        // This is for making actual api call if user wants to get available apps and does not want to send appsUrls from Parent Application
        setLoading(true);
        fetch(URL, {
          // https://bccucapi.arcserve.com:443/api/user/apps
          // headers: {Authentication: 'Bearer Token'}
        })
          .then(response => response.json())
          .then(responseData => {
            let renderMenu =
              responseData && responseData.data && responseData.data != null
                ? menuItems.filter(item => {
                    return (
                      Array.isArray(responseData.data) &&
                      responseData.data.includes(item.check)
                    );
                  })
                : menuItems;

            setApiData(renderMenu);
            // if(renderMenu.length==1){
            // console.log('renderMenu',renderMenu[0])
            //   lastUsedApplication(renderMenu[0].url)
            // }
            setLoading(false);
          })
          .catch(err => {
            setApiData(menuItems);
            setLoading(false);
          });
      } else {
        setApiData(menuItems);
        setLoading(false);
      }
    } else {
      let allAppURLS = Object.values(appsUrl).reverse();
      let renderData = [];
      for (let i = 0; i < allAppURLS.length; i++) {
        for (let j = 0; j < menuItems.length; j++) {
          if (allAppURLS[i] === menuItems[j].url) {
            renderData.push(menuItems[j]);
          }
        }
      }
      let renderMenu = [...renderData];
      setApiData(renderMenu);
      setLoading(isLoading);
    }
  }, [isLoading, userName]);

  const toggle = () => {
    setOpen(!open);
  };

  const determineSelected = matcher => {
    return matcher === appName;
  };

  const generateListItem = (title, icon, matcher, url, check, productcode) => {
    return {
      title,
      icon,
      selected: determineSelected(matcher),
      url,
      check,
      matcher,
      productcode
    };
  };

  const menuItems = [
    // generateListItem(
    //   "Monitoring & Reporting",
    //   <TimelineIcon />,
    //   appNames.MONITORING,
    //   appsUrl["uc"],
    //   "Monitoring&Reporting"
    // ),
    // generateListItem(
    //   "Cloud Console",
    //   <BackupIcon />,
    //   appNames.CLOUD_BACKUP,
    //   "/cloudconsole"
    // ),
    generateListItem(
      "Arcserve UDP",
      <ShieldOutlinedIcon />,
      appNames.UDP,
      appsUrl["cloud_console"],
      "UDP",
      "udp"
    ),

    // generateListItem(
    //   "Data Center Protection (UDP)",
    //   <SyncAltIcon />,
    //   appNames.UDP_APPLIANCE,
    //   "https://en.wikipedia.org/wiki/User_Datagram_Protocol"
    // ),
    // generateListItem(
    //   "System Protection (ShadowXafe)",
    //   <SecurityIcon />,
    //   appNames.ONEXAFE_APPLIANCE,
    //   "https://www.storagecraft.com/products/onexafe-overview"
    // ),
    generateListItem(
      "Arcserve Cloud Direct",
      <CloudUploadOutlinedIcon />,
      appNames.CLOUD_DIRECT,
      appsUrl["cloud_console"],
      "Cloud Console",
      "cd"
    )
  ];

  const renderSkeleton = () => {
    return (
      <>
        {menuItems.map((item, index) => {
          return (
            <ListItem key={index}>
              <Skelton variant="rectangular" width={222} height={47} />
              <br />
            </ListItem>
          );
        })}
      </>
    );
  };

  //   const lastUsedApplication = (url) => {
  //     console.log(url)
  //     if(url){
  //     let APIURL;
  //     if(url ===  appsUrl["cloud_console"]){
  //       APIURL=`${url}/signin`

  //     }
  //     else {
  //       APIURL = url;
  //     }
  //     let headerObject = {"Content-Type": "application/json",Accept: "application/json"}
  //     if(token){
  //       headerObject.Authorization = `Bearer ${token}`
  //     }

  //   fetch(`${ucBeURL}/user/${userName}/lastviewapp`, {
  //             method: "post",
  //             headers: headerObject,
  //             body: JSON.stringify({'url':APIURL})
  //  }).then(res => {
  //   window.location.href = url;
  //  }).catch((err)=>{
  //   window.location.href = url;
  //  })

  // }
  //   }

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <IconButton onClick={toggle} style={{ ...uccIconStyle }}>
          <MenuIcon
            style={showTitle ? { color: "white" } : {}}
            id={`${UCID}_hamburgericon`}
          />
        </IconButton>
      </ThemeProvider>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        anchor="left"
        sx={{
          "& .MuiPaper-root": {
            width: 250
          }
        }}
        id={`${UCID}_menu_drawer`}
      >
        <UserDetails name={name} email={email} />
        <Divider style={{ height: 2 }} />

        <List style={{ marginTop: 16, marginBottom: 16 }}>
          {!loading
            ? menuItems && menuItems.length > 0
              ? menuItems.map(
                  ({
                    title,
                    icon,
                    selected,
                    url,
                    matcher,
                    check,
                    productcode
                  }) => {
                    return (
                      <ListItem sx={{ width: "100%", padding: 0 }}>
                        <ListItemButton
                          id={`${UCID}_${productcode}`}
                          key={title}
                          selected={selected}
                          // className={selected ? classes.selected : ""}
                          sx={theme => ({
                            color:
                              !!theme && selected
                                ? theme.palette.primary.main
                                : ""
                          })}
                          button
                          onClick={() => {
                            if (handleMenuClick) {
                              handleMenuClick(matcher);
                              window.location.href = url;
                            } else {
                              window.location.href = url;
                            }

                            //lastUsedApplication(url)
                          }}
                        >
                          <ListItemIcon
                            // className={selected ? classes.selected : ""}
                            sx={theme => ({
                              color:
                                !!theme && selected
                                  ? theme.palette.primary.main
                                  : ""
                            })}
                            id={`${UCID}_${productcode}_icon`}
                            style={{ minWidth: "42px" }}
                          >
                            {icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={title}
                            id={`${UCID}_${productcode}_menutitle`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  }
                )
              : ""
            : renderSkeleton()}
        </List>

        {showLogout && (
          <>
            <Divider />
            <UserConfigurations
              handleLogout={handleLogout}
              appPath={appPath}
              ucUrl={ucUrl}
            />
          </>
        )}
      </Drawer>
    </>
  );
};

const appNames = {
  SYSTEM_PROTECTION: "system-protection",
  MONITORING: "monitoring",
  CLOUD_BACKUP: "cloud-backup",
  ONEXAFE_APPLIANCE: "onexafe-appliance",
  UDP: "UDP",
  CLOUD_DIRECT: "CLOUD_DIRECT"
};

export default MainDrawer;

MainDrawer.propTypes = {
  appName: PropTypes.oneOf(Object.values(appNames)).isRequired
};
